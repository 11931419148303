import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EstimateDetailTreeRequest } from 'src/api/estimates-api/estimates-api.interface'
import { DeepCopy, AutoMap } from 'src/app/common/common'
import { AddOptionQuotationSession } from './ura-create-quotation.interface'


@Injectable({
  providedIn: 'root',
})
export class AddOptionQuotationService {

  constructor(
    private router: Router
  ) { }

  private defaultValue: AddOptionQuotationSession = {
    organizationCompanyCd: '',
    contractOrganizationCd: '',
    addMerchandises: []
  }

  private session: AddOptionQuotationSession = DeepCopy(this.defaultValue)

  /**
   * GET：オプション追加見積もり作成用セッション
   */
   get Session(): AddOptionQuotationSession {
    return DeepCopy(this.session)
  }

  /**
   * SET：オプション追加見積もり作成用セッション
   */
  setSession(value: AddOptionQuotationSession): void {
    this.session = DeepCopy(value)
  }

  /**
   * 保存されている内容が一致しているかチェックし、
   * 異なる場合はリセットして初期画面へ遷移
   * @param organizationCompanyCd 契約会社組織
   * @param contractOrganizationCd 契約組織
   */
  checkAndResetSession(organizationCompanyCd: string, contractOrganizationCd: string): void {
    const session = this.session
    // 保存データが無い場合は業態選択へ
    if (!session.contractOrganizationCd) {
      this.router.navigate([`/ura-add-product-business-category/${organizationCompanyCd}/${contractOrganizationCd}/0`])
    }
    // 保存されている契約組織と異なる場合はクリア
    else if (session.contractOrganizationCd !== contractOrganizationCd) {
      this.clearSession()
      this.router.navigate([`/ura-conpany-contracted-organization-list`])
    }
  }

  /**
   * オプション追加見積もり作成用セッション クリア
   */
   clearSession(): void {
    this.session = DeepCopy(this.defaultValue)
  }

  // オプション追加契約用のリクエストを取得
  getAddOptionRequest(): EstimateDetailTreeRequest[] {
    const changeInfos = this.Session.addMerchandises
    const ret = []
    changeInfos.forEach(changeInfo => {
      const convert = AutoMap<EstimateDetailTreeRequest>(changeInfo.merchendiseAfter)
      // 変更後の数量から元の数量をマイナスし、増加数量をセットする
      convert.unitQuantity =
        changeInfo.merchendiseAfter.contractedQuantity - changeInfo.merchendiseBefore.contractedQuantity

      // 子要素もコンバートする
      const childs = []
      let hasChild = false
      changeInfo.merchendiseAfter.merchandiseDetails?.forEach(child => {
        if (child.contractedQuantity > 0) {
          const convertChild = AutoMap<EstimateDetailTreeRequest>(child)
          convertChild.unitQuantity = child.contractedQuantity
          childs.push(convertChild)
          hasChild = true
        }
      })
      // 子要素がある場合は親の数量を元のまま1にする
      if (hasChild) {
        convert.unitQuantity = 1
      }
      convert.estimateMerchandises = childs

      ret.push(convert)
    })
    return ret
  }
}
