import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import {
  EstimateDocumentDetailStatus,
  EstimateDetailTreeRequest,
  EstimatePreviewRequest,
  EstimateDocumentDetail,
  EstimateCreateMacroRequest
} from 'src/api/estimates-api/estimates-api.interface'
import { EstimateApiService } from 'src/api/estimates-api/estimates-api.service'
import { AutoMap } from 'src/app/common/common'
import { FileManagementService } from 'src/app/common/file-management.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { CreateQuotationService } from '../service/create-quotation.service'
import { NewQuotationService } from '../service/new-quotation.service'
import { NewQuotationSession } from '../service/ura-create-quotation.interface'

@Component({
  selector: 'app-ura-quotation-preview-new',
  templateUrl: './ura-quotation-preview-new.component.html',
  styleUrls: ['./ura-quotation-preview-new.component.scss'],
})
export class UraQuotationPreviewNewComponent implements OnInit {
  title = '新規見積もり作成'
  loading = true
  pdfLoading = false
  sending = false
  session: NewQuotationSession
  detailStatus: EstimateDocumentDetailStatus
  contractOrganizationCd: string
  changeMerchandises: EstimateDetailTreeRequest[] = []
  estimateDocumentRequest: EstimatePreviewRequest
  detailList: EstimateDocumentDetail[] = []
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.router.navigate(['/ura-new-quotation-remarks'])
      }
    },
    {
      label: 'サービスを追加する',
      actionFunction: () => {
        this.router.navigate([`/ura-new-quotation-retail/${this.session?.selectedGroups?.length}`])
      }
    },
    {
      label: '見積を保存する',
      actionFunction: () => {
        this.save()
      }
    }
  ]

  constructor(
    private router: Router,
    private authService: AuthService,
    private createQuotationService: CreateQuotationService,
    private newQuotationService: NewQuotationService,
    private estimateApiService: EstimateApiService,
    private messageService: MessageService,
    private fileManagementService: FileManagementService,
  ) { }

  ngOnInit(): void {
    this.newQuotationService.pageInit()
    this.newQuotationService.clearUnfinishedGroupSession()
    this.session = this.newQuotationService.Session
    this.contractOrganizationCd = this.session.targetOrganizationCustomer.organizationCd
    this.detailStatus = AutoMap<EstimateDocumentDetailStatus>(this.session.targetOrganizationCustomer)
    this.detailStatus.remarks = this.session.remarks
    this.detailStatus.contractCustomerName = `${this.session.targetOrganizationCustomer.familyName} ${this.session.targetOrganizationCustomer.firstName}`
    this.detailStatus.companyOrganizationName = this.session.targetOrganizationCustomer.organizationFullName
    this.changeMerchandises = this.createQuotationService.getMerchandisesRequest(
      this.session.selectedGroups
    )
    this.estimateDocumentRequest = {
      remarks: this.session.remarks,
      estimateMerchandises: this.changeMerchandises
    }
    this.getEstimateData()
  }

  async getEstimateData(): Promise<void> {
    this.loading = true
    // const ret = await this.estimateApiService.EstimateDetailsPreview(
    //   this.changeMerchandises
    // ).toPromise()
    const ret$ = this.estimateApiService.EstimateDetailsPreview(this.changeMerchandises)
    let ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.detailList = ret.data
  }

  // 見積内容保存
  async save(): Promise<void> {
    if (this.sending) { return }
    const paylord: EstimateCreateMacroRequest = {
      remarks: this.session.remarks,
      generatorCustomerCd: this.authService.AccountInfo.customerCd,
      estimateMerchandises: this.changeMerchandises
    }
    this.sending = true
    // const ret = await this.estimateApiService.CreateNewEstimates(
    //   paylord,
    //   this.contractOrganizationCd,
    //   this.session.targetOrganizationCustomer.customerCd
    // ).toPromise()
    const ret$ = this.estimateApiService.CreateNewEstimates(
      paylord,
      this.contractOrganizationCd,
      this.session.targetOrganizationCustomer.customerCd)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.newQuotationService.clearSession()
    this.messageService.success('見積を保存しました')
    this.router.navigate(['/ura-estimate-list-new'])
  }

  // PDFダウンロード
  async downloadEstimateDocumentPdfFile(): Promise<void> {
    if (this.pdfLoading) { return }
    this.messageService.info('処理中です。しばらくお待ちください。')
    this.pdfLoading = true

    // const ret = await this.estimateApiService.GetEstimateDocumentPreview(
    //   this.estimateDocumentRequest, this.contractOrganizationCd
    // ).toPromise()
    const ret$ = this.estimateApiService.GetEstimateDocumentPreview( this.estimateDocumentRequest, this.contractOrganizationCd)
    let ret = await lastValueFrom(ret$)

    this.pdfLoading = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('ダウンロードが完了しました。')
    const url = this.estimateApiService.BaseURL + ret.data[0]
    this.fileManagementService.downloadPdfFile(url)
  }
}
