import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { BackendOrganizationCustomer } from 'src/api/customers-api/customers-api.interface'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { MigrationLogin } from 'src/api/easy-authentications-api/easy-authentications-api.interface'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'
import { OrganizationFullName } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { JoinText } from 'src/app/common/common'
import { AuthService } from 'src/app/components/isbeauth/auth.service'

@Component({
  selector: 'app-easy-auth-login',
  templateUrl: './easy-auth-login.component.html',
  styleUrls: ['./easy-auth-login.component.scss']
})
export class EasyAuthLoginComponent implements OnInit {
  authenticationUriCd: string
  showLoginForm = false
  loginError = false
  authenticationCd = ''
  targetUserOrganizationName = ''
  targetUserName = ''
  nextPagePath: string
  sending = false

  constructor(
    private easyAuthenticationsApiService: EasyAuthenticationsApiService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private organizationV2Service: OrganizationV2Service,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.authenticationUriCd = params.get('authenticationUriCd')
      this.nextPagePath = params.get('nextPagePath')
    })
    this.getCustomerCd()
  }
  getCustomerCd(): void {
    this.easyAuthenticationsApiService
      .GetCustomerCd(this.authenticationUriCd).subscribe((ret: ResultInfo<string>) => {
        if (ret?.resultCode !== 0) { return }
        const customerCd = ret.data[0]
        this.getCustomer(customerCd)
        this.getOrganizationName(customerCd)
      })
  }
  async getCustomer(customerCd: string): Promise<void> {
    const ret = await this.customerService.SearchByCd(customerCd)
    if (ret?.resultCode !== 0) { return }
    this.targetUserName = JoinText([ret.data.familyName, ret.data.firstName], ' ')
  }
  getOrganizationName(customerCd: string): void {
    this.organizationV2Service.GetOrganizationFullNameList(customerCd).subscribe((ret: ResultInfo<OrganizationFullName>) => {
      if (ret?.resultCode !== 0) { return }
      this.targetUserOrganizationName = ret.data[0].organizationFullName
    })
  }
  async submit(): Promise<void> {
    if (this.sending || !this.authenticationCd.length) { return }
    this.sending = true
    // const ret = await this.easyAuthenticationsApiService.MigrationLogin(this.authenticationUriCd, this.authenticationCd).toPromise()
    const ret$ = this.easyAuthenticationsApiService.MigrationLogin(this.authenticationUriCd, this.authenticationCd)
    const ret = await lastValueFrom(ret$)

    this.sending = false
    // 認証できなかった場合はdataが[]になる
    if (ret?.resultCode === 0 && ret?.data[0]) {
      this.loginError = false
      this.showLoginForm = false

      const resData = ret.data[0]
      if (resData?.isFormalAccount) {
        // 既にアカウントを持っている場合
        this.authService.setAutoLoginAccountId(resData.accountId)
        if (this.nextPagePath) {
          this.router.navigate([`/${this.nextPagePath}`])
        }
        else {
          this.router.navigate(['/'])
        }
      }
      else {
        // アカウントを持っていない場合
        if (this.nextPagePath) {
          this.router.navigate([`/regist-account`, resData?.customerCd, this.nextPagePath])
        }
        else {
          this.router.navigate(['/regist-account', resData?.customerCd])
        }
      }
    }
    else {
      this.loginError = true
    }
  }
}
