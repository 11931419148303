import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { InputTableComponent } from '../../common-parts/input-table/input-table.component'
import { FormInputInfo } from '../../common-parts/input-table/input-table.model'
import { AutoMap } from 'src/app/common/common'

@Component({
  selector: 'app-invoice-target-view',
  templateUrl: './invoice-target-view.component.html'
})
export class InvoiceTargetViewComponent implements OnInit {
  @Input() selectedCd = ''
  formItemsReadOnly: FormInputInfo[] = [
    {
      key: 'companyName',
      label: '会社名',
      readonly: true
    },
    {
      key: 'branchOffice',
      label: '支店名',
      readonly: true
    },
    {
      key: 'sectionName',
      label: '部署名',
      readonly: true
    },
    {
      key: 'personName',
      label: '宛先氏名',
      readonly: true
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス',
      readonly: true
    },
    {
      key: 'telNo',
      label: '電話番号',
      readonly: true
    },
    {
      key: 'postCode',
      label: '郵便番号',
      readonly: true
    },
    {
      key: 'prefecture',
      label: '都道府県',
      readonly: true
    },
    {
      key: 'city',
      label: '市区町村',
      readonly: true
    },
    {
      key: 'blockName',
      label: '番地',
      readonly: true
    }
  ]
  constructor(
    private invoiceService: InvoiceService,
    private organizationCompanyApiService: OrganizationCompanyApiService,
    private customerService: CustomerService
  ) { }

  @ViewChild('inputTable', { read: InputTableComponent, static: false })
  public inputTable: InputTableComponent

  ngOnInit(): void {
    this.getInvoiceTaget()
  }

  async getInvoiceTaget(): Promise<void> {
    const invoiceTargetRet = await this.invoiceService.searchInvoiceTargets(this.selectedCd)
    if (invoiceTargetRet?.resultCode !== 0) { return }
    const invoiceTarget = invoiceTargetRet.data

    const companyRet = await this.organizationCompanyApiService.GetProfileBySectionCd(invoiceTarget.organizationCd)
    if (companyRet?.resultCode !== 0) { return }
    const organizationFormalName = companyRet.data.organizationFormalName

    const customerRet = await this.customerService.SearchByCd(invoiceTarget.customerCd)
    if (customerRet?.resultCode !== 0) { return }
    const customer = customerRet.data

    const form = AutoMap<any>(invoiceTarget)
    form.companyName = organizationFormalName
    form.mailAddress = customer.mailAddress

    if (form.title) {
      const titleItem = {
        key: 'title',
        label: '請求区分',
        readonly: true
      }
      this.inputTable.setForm(form, [ ...this.formItemsReadOnly, titleItem ])
    }
    else {
      this.inputTable.setForm(form, this.formItemsReadOnly)
    }
  }
}
