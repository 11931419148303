import { Component, OnInit } from '@angular/core'
import moment from 'moment'
import { ContaApiService } from 'src/api/conta-api/conta-api.service'
import { ContractModel } from 'src/api/conta-api/conta-api.interface'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { MessageService } from 'src/app/components/message/message.service'
import { messageList } from 'src/app/components/message/message-data'
import { ContractsV4Service } from 'src/api/contractsV4-api/contractsV4-api.service'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'app-ura-cancel-contract-list',
  templateUrl: './ura-cancel-contract-list.component.html',
  styleUrls: ['./ura-cancel-contract-list.component.scss']
})
export class UraCancelContarctListComponent implements OnInit {
  title = 'サービスの解約・サービス選択'
  data: ContractModel[] = []
  columns: Header[] = [
    {
      key: 'serviceTagName',
      label: 'サービス',
    },
    {
      key: 'retailTagText',
      label: '小売',
    },
    {
      key: 'businessCategoryTagName',
      label: '業態',
    },
    {
      key: 'productInquiryCd',
      label: 'プロダクトコード',
    },
    {
      key: 'contractDetailId',
      label: 'SuppliサービスID'
    },
    {
      key: 'oldInquiryNo',
      label: '旧お問い合わせNo.',
    },
    {
      key: 'merchandiseName',
      label: '商品名',
    },
    {
      key: 'contractName',
      label: '契約名',
    },
    {
      key: 'supplierCd',
      label: 'サプライヤーコード',
    },
    {
      key: 'actuateDatetimeUi',
      label: '稼働日',
    },
    {
      key: 'invoiceCompanyOrganizationName',
      label: '請求先会社',
    },
  ]
  actionButtons: ActionButtonDisp[] = [
    {
      actionCd: 'actionCd1',
      label: '解約を申し込む'
    }
  ]
  rowSelection = RowSelection.MULTIPLE
  loading = false
  sending = false
  companyOrganizationCd: string
  contractCustomerCd: string
  contractCompanyName: string
  contractCustomerName: string
  cancelContractCds: string[] = []
  dialogOpen = false
  cancelDate: Date

  constructor(
    private contaService: ContaApiService,
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private contractsV4Service: ContractsV4Service,
    private organizationV2Service: OrganizationV2Service,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.companyOrganizationCd = params.get('companyOrganizationCd')
      this.contractCustomerCd = params.get('customerCd')
    })
    this.searchContractList()
    this.getCustomerName()
    this.getOrganizationName()
  }

  async searchContractList(): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.contaService.GetContractListSearchByCompanyCd(
      this.companyOrganizationCd,
      this.contractCustomerCd)
    if (ret?.resultCode !== 0) { return }
    this.loading = false
    this.data = this.convertDate(ret.data)
  }

  async getOrganizationName(): Promise<void> {
    // const ret = await this.organizationV2Service.GetOrganizationFullName(this.companyOrganizationCd, false).toPromise()
    const ret$ = this.organizationV2Service.GetOrganizationFullName(this.companyOrganizationCd, false)
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.contractCompanyName = ret.data[0]
  }

  async getCustomerName(): Promise<void> {
    const ret = await this.customerService.SearchByCd(this.contractCustomerCd)
    if (ret?.resultCode !== 0) { return }
    const customer = ret.data
    this.contractCustomerName = `${customer.familyName} ${customer.firstName}`
  }

  get cancelMessage(): string {
    return `${this.cancelContractCds.length}件を本当に解約しますか？`
  }

  convertDate(data: ContractModel[]): ContractModel[] {
    data.forEach(item => {
      item.actionCds = []
      item.actionCds.push('actionCd1')
    })
    return data
  }

  async cancelAction(): Promise<void> {
    if (!this.cancelDate) {
      this.messageService.templeteMessage(messageList.M00022)
      return
    }
    if (this.sending) { return }
    this.sending = true
    // const ret = await this.contractsV4Service
    //   .ContractsCancelActuates(this.cancelContractCds, moment(this.cancelDate).format('YYYY-MM-DD')).toPromise()
    const ret$ = this.contractsV4Service.ContractsCancelActuates(this.cancelContractCds, moment(this.cancelDate).format('YYYY-MM-DD'))
    const ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00030)
    this.router.navigate(['/contract-entry-list'])
  }

  actionCommit(event): void {
    this.cancelContractCds = []
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    if (action.actionCd === 'actionCd1') {
      this.cancelDate = null
      this.dialogOpen = true
      event.rows.forEach(item => {
        this.cancelContractCds.push(item.contractCd)
      })
    }
  }
}
