import { MessageType } from './message.interface'

export const messageList = {
  M00001: {
    message: 'システムエラーが発生しました',
    messageType: MessageType.ERROR,
  },
  M00002: {
    message: '入力内容に不備があります',
    messageType: MessageType.WARNING,
  },
  M00003: {
    message: '法人情報を選択して下さい',
    messageType: MessageType.WARNING,
  },
  M00004: {
    message: 'メールアドレスの入力形式が異なります',
    messageType: MessageType.WARNING,
  },
  M00005: {
    message: '名前・組織種別を入力してください',
    messageType: MessageType.WARNING,
  },
  M00006: {
    message: 'WGの下には部署を作成できません',
    messageType: MessageType.WARNING,
  },
  M00007: {
    message: '役割の下には組織を作成できません',
    messageType: MessageType.WARNING,
  },
  M00008: {
    message: '組織を追加しました',
    messageType: MessageType.SUCCESS,
  },
  M00009: {
    message:
      'この組織または下位組織に紐づく顧客が存在する為、組織種別は役割から変更できません',
    messageType: MessageType.WARNING,
  },
  M00010: {
    message: '変更しました',
    messageType: MessageType.SUCCESS,
  },
  M00011: {
    message: '組織を削除しました',
    messageType: MessageType.SUCCESS,
  },
  M00012: {
    message: 'この組織または下位組織に紐づく顧客が存在する為、移動できません',
    messageType: MessageType.WARNING,
  },
  M00013: {
    message: '削除しました',
    messageType: MessageType.SUCCESS,
  },
  M00014: {
    message: 'スーパーユーザーを一人以上残してください。',
    messageType: MessageType.WARNING,
  },
  M00015: {
    message: '招待先が未選択です',
    messageType: MessageType.WARNING,
  },
  M00016: {
    message: '招待を送信しました',
    messageType: MessageType.SUCCESS,
  },
  M00017: {
    message: '招待権限を持っているサービスがありません',
    messageType: MessageType.INFO,
  },
  M00018: {
    message:
      '1業態のFrimoに対して、出荷Packコネクタ1個目は無料です。出荷Packコネクタは必ず1個残しておいてください。',
    messageType: MessageType.WARNING,
  },
  M00019: {
    message: '申請を送信しました',
    messageType: MessageType.SUCCESS,
  },
  M00020: {
    message: '利用開始日を更新しました',
    messageType: MessageType.SUCCESS,
  },
  M00021: {
    message: '稼働日を更新しました',
    messageType: MessageType.SUCCESS,
  },
  M00022: {
    message: '日付を指定して下さい',
    messageType: MessageType.WARNING,
  },
  M00023: {
    message: '会社を選択してください',
    messageType: MessageType.WARNING,
  },
  M00024: {
    message: 'ワークグループを選択して下さい',
    messageType: MessageType.SUCCESS,
  },
  M00025: {
    message: '紐付けが完了しました',
    messageType: MessageType.SUCCESS,
  },
  M00026: {
    message: '企業を選択して下さい',
    messageType: MessageType.WARNING,
  },
  M00027: {
    message:
      '1業態のFrimoに対して、出荷Packコネクタ1個目は無料です。出荷Packコネクタは必ず1個残しておいてください。',
    messageType: MessageType.SUCCESS,
  },
  M00028: {
    message: '対象を選択して下さい',
    messageType: MessageType.WARNING,
  },
  M00029: {
    message: '解約日付を設定してください',
    messageType: MessageType.WARNING,
  },
  M00030: {
    message: '解約しました',
    messageType: MessageType.SUCCESS,
  },
  M00032: {
    message: '顧客を選択してください',
    messageType: MessageType.WARNING,
  },
  M00033: {
    message: '請求情報マスタを登録しました',
    messageType: MessageType.SUCCESS,
  },
  M00034: {
    message: '対象プロダクトが未選択です',
    messageType: MessageType.WARNING,
  },
  M00035: {
    message: '接続可能なプロダクトがありません。会社を選択しなおしてください。',
    messageType: MessageType.WARNING,
  },
  M00036: {
    message: '過去日は設定できません',
    messageType: MessageType.WARNING,
  },
  M00037: {
    message: '稼働開始日を登録しました',
    messageType: MessageType.SUCCESS,
  },
  M00038: {
    message: '招待対象が未選択です',
    messageType: MessageType.WARNING,
  },
  M00039: {
    message: '変更前と変更後の請求先が同一です',
    messageType: MessageType.WARNING,
  },
  M00040: {
    message: '商品を選択してください',
    messageType: MessageType.WARNING,
  },
  M00041: {
    message: '既に同じ小売・業態で追加しているサービスです',
    messageType: MessageType.WARNING,
  },
  M00042: {
    message: '既に同じ業態を追加済です',
    messageType: MessageType.WARNING,
  },
  M00043: {
    message: 'サービスの稼働設定を更新しました',
    messageType: MessageType.SUCCESS,
  },
  M00044: {
    message: '申込みが完了しました',
    messageType: MessageType.SUCCESS,
  },
  M00045: {
    message: 'ダウンロードが完了しました',
    messageType: MessageType.SUCCESS,
  },
  M00046: {
    message: '再送しました',
    messageType: MessageType.SUCCESS,
  },
  M00047: {
    message: '理由を選択してください',
    messageType: MessageType.WARNING,
  },
  M00048: {
    message: '利用者に追加しました',
    messageType: MessageType.SUCCESS,
  },
  M00049: {
    message: '既に利用されているメールアドレスです',
    messageType: MessageType.WARNING,
  },
  M00050: {
    message: '更新しました',
    messageType: MessageType.SUCCESS,
  },
  M00051: {
    message:
      'My-ideaの利用権限がありません',
    messageType: MessageType.ERROR,
  },
  M00052: {
    message:
      'ログイン処理でエラーが発生しました',
    messageType: MessageType.ERROR,
  },
  M00053: {
    message:
      '特定の契約者様のみアクセスできるページです。',
    messageType: MessageType.ERROR,
  },
  M00054: {
    message: '新メニュー開始日を登録しました',
    messageType: MessageType.SUCCESS,
  },
  M00055: {
    message: 'ラベルオプション利用開始日を登録しました',
    messageType: MessageType.SUCCESS,
  },
  M00056: {
    message: 'ラベルオプション設定を登録しました',
    messageType: MessageType.SUCCESS,
  },
  M00057: {
    message: '登録が完了しました',
    messageType: MessageType.SUCCESS,
  },
  M00058: {
    message: '利用者から除外しました',
    messageType: MessageType.SUCCESS,
  },
  M00059: {
    message: 'このページの参照権限がありません',
    messageType: MessageType.WARNING,
  },
  M00060: {
    message: '継続利用のお申込みをする場合は、契約対象を1つ以上残してください',
    messageType: MessageType.WARNING,
  },
  M00061: {
    message: '対象が選択されていません。全てを契約する場合は戻るボタンで前の画面からお申込みをしてください',
    messageType: MessageType.WARNING,
  },
  M00062: {
    message: '対象件数が多すぎます。検索条件を修正して下さい。',
    messageType: MessageType.WARNING,
  },
  M00063: {
    message: '権限を付与しました',
    messageType: MessageType.SUCCESS,
  },
  M00064: {
    message: '権限を削除しました',
    messageType: MessageType.SUCCESS,
  },
  M00065: {
    message: '名刺交換が完了しました。',
    messageType: MessageType.SUCCESS,
  },
  M00066: {
    message: '認証コードが有効ではありません。',
    messageType: MessageType.WARNING,
  },
  M00070: {
    message: 'お知らせを更新しました',
    messageType: MessageType.SUCCESS,
  }
}
