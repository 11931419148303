<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <app-merchandise-edit-dialog
    [merchandises]="data"
    (addMerchandise)="addMerchandise($event)"
    (updateMerchandise)="updateMerchandise($event)"
  ></app-merchandise-edit-dialog>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>
