import {
  Component,
  OnInit,
  Input
} from '@angular/core'
import { EstimateDocumentDetail } from 'src/api/estimates-api/estimates-api.interface'

@Component({
  selector: 'app-estimate-explanation-text',
  templateUrl: './estimate-explanation-text.component.html',
  styleUrls: ['./estimate-explanation-text.component.scss']
})
export class EstimateExplanationTextComponent implements OnInit {
  @Input() estimateDetailList: EstimateDocumentDetail[] = []

  constructor() { }

  ngOnInit(): void {
  }

  get isVolumeDiscount(): boolean {
    // まだボリュームディスカウントの判断値をAPIから受け取っていないので暫定
    return this.estimateDetailList.find(x => x.merchandiseName.match(/ボリューム割引/)) ? true : false
  }
}
