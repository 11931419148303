<app-estimate-explanation-text
  [estimateDetailList]="detailList"
></app-estimate-explanation-text>
<div class="c-btns__item" style="text-align: right">
  <a class="c-btn" (click)="changeAllViewMode()" style="cursor: pointer"
    ><span>{{ differenceViewMode ? "全体を表示" : "差分行のみ表示" }}</span></a
  >
</div>
<app-action-grid
  [data]="dispList"
  [loading]="loading"
  [rowSelection]="rowSelection"
  [columns]="columns"
  [height]="400"
  [cellClasses]="cellClasses"
></app-action-grid>
