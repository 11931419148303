import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { BackendOrganizationV2, OrganizationFullName } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { ViewChild } from '@angular/core'
import { AutoMap, DeepCopy } from 'src/app/common/common'
import { OrganizationProfileInputComponent } from 'src/app/module/myidea-parts/organization-profile-input/organization-profile-input.component'
import { InputOrganization } from 'src/app/module/myidea-parts/feature-organization.interface'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { formBranchItem, formCompanyItems } from 'src/app/module/myidea-parts/company.const'

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss'],
})
export class CompanyEditV2Component implements OnInit {
  @ViewChild(OrganizationProfileInputComponent, { static: false })
  private input: OrganizationProfileInputComponent
  organizationFullNames: OrganizationFullName[]
  companyCd: string
  campany: BackendOrganizationV2
  branchCd: string
  branch: BackendOrganizationV2
  formValue: InputOrganization = null
  submitForm: InputOrganization = null
  items: FormInputInfo[] = formCompanyItems

  constructor(
    private organizationV2Service: OrganizationV2Service,
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.items = DeepCopy(formCompanyItems)
    this.items.forEach(item => {
      item.readonly = true
      item.valid = { required: false }
      if (item.group?.length) {
        item.group = item.group.filter(x => x.key !== 'organizationCategoryCd' && x.key !== 'displayPositionCd')
        item.group.forEach(groupItem => {
          groupItem.readonly = true
          groupItem.valid = { required: false }
          groupItem.label = `【${groupItem.label}】`
        })
      }
    })
    this.getOrganizationName()
  }

  // 選択中の組織情報を取得
  getOrganizationInfo(): void {
    const cd = this.companyCd || this.authService.AccountInfo.selectedOrganizationCd
    this.organizationV2Service.OrganizationInfo(cd).subscribe(
      (ret: ResultInfo<BackendOrganizationV2>) => {
        this.campany = ret.data[0]
        if (!this.branchCd) {
          this.formValue = AutoMap<InputOrganization>(this.campany)
          return
        }
        // 部署入力欄追加
        const branchInput = formBranchItem
        branchInput.readonly = true
        branchInput.valid = { required: false }
        this.items.splice(3, 0, branchInput)
        this.getBranchOrganizationInfo()
      }
    )
  }

  // 配属部署の組織情報を取得
  getBranchOrganizationInfo(): void {
    this.organizationV2Service.OrganizationInfo(this.branchCd).subscribe(
      (ret: ResultInfo<BackendOrganizationV2>) => {
        this.branch = ret.data[0]
        const input = AutoMap<InputOrganization>(this.branch)
        input.organizationSectionName = this.branch.organizationName
        input.organizationFormalName = this.campany.organizationName
        input.organizationCategoryCd = this.campany.organizationCategoryCd
        input.displayPositionCd = this.campany.displayPositionCd
        input.organizationName = this.campany.organizationName
        input.organizationNameKana = this.campany.organizationNameKana
        this.formValue = input
      }
    )
  }

  submit(): void {
    const submitForm = this.input.submit()
    if (!submitForm) {
      return
    }
    if (this.branchCd) {
      const organizationInfo: BackendOrganizationV2 =
      {
        organizationCd: this.branchCd,
        organizationName: submitForm.organizationSectionName,
        organizationCategoryCd: null,
        displayPositionCd: null,
        organizationTypeCd: this.branch.organizationTypeCd,
        postCode: submitForm.postCode,
        prefecture: submitForm.prefecture,
        city: submitForm.city,
        block: submitForm.block,
        building: submitForm.building,
        telephoneNumber: submitForm.telephoneNumber,
      }
      this.organizationV2Service
        .OrganizationsUpdate(organizationInfo, submitForm.organizationCd)
        .subscribe((ret: any) => {
          this.campanySubmit(submitForm)
        })
    }
    else {
      this.campanySubmit(submitForm)
    }
  }
  campanySubmit(submitForm: BackendOrganizationV2): void {
    const organizationInfo =
    {
      organizationCd: this.companyCd,
      organizationName: submitForm.organizationName,
      organizationCategoryCd: submitForm.organizationCategoryCd,
      displayPositionCd: submitForm.displayPositionCd,
      organizationTypeCd: this.campany.organizationTypeCd,
      postCode: this.campany.postCode,
      prefecture: this.campany.prefecture,
      city: this.campany.city,
      block: this.campany.block,
      building: this.campany.building,
      telephoneNumber: this.campany.telephoneNumber,
    }
    if (!this.branch) {
      organizationInfo.postCode = submitForm.postCode
      organizationInfo.prefecture = submitForm.prefecture
      organizationInfo.city = submitForm.city
      organizationInfo.block = submitForm.block
      organizationInfo.building = submitForm.building
      organizationInfo.telephoneNumber = submitForm.telephoneNumber
    }
    this.organizationV2Service
      .OrganizationsUpdate(organizationInfo, this.companyCd)
      .subscribe((ret: any) => {
        this.messageService.success('組織情報の確認が完了しました')
        this.router.navigate(['/'])
      })
  }

  getOrganizationName(): void {
    const customerCd = this.authService?.AccountInfo?.customerCd
    if (!customerCd) { return }
    this.organizationV2Service
      .GetOrganizationFullNameList(customerCd, false).subscribe((ret: ResultInfo<OrganizationFullName>) => {
        if (ret?.resultCode === 0) {
          this.organizationFullNames
            = ret.data.filter(x => x.organizationNestCds[0] === this.authService?.AccountInfo?.selectedOrganizationCd)
          if (this.organizationFullNames.length) {
            const org = this.organizationFullNames[0]
            if (org.organizationNestCds.length > 1) {
              this.branchCd = org.organizationNestCds[org.organizationNestCds.length - 1]
            }
            this.companyCd = org.organizationNestCds[0]
            this.getOrganizationInfo()
          }
          else {
            this.companyCd = this.authService?.AccountInfo?.selectedOrganizationCd
            this.getOrganizationInfo()
          }
        }
      })
  }
}
