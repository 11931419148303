<div class="back" [class.full-height]="fullHeight">
  <div class="core">
    <igx-circular-bar
      [animate]="false"
      [indeterminate]="true"
      [textVisibility]="false"
      class="custom-size"
    ></igx-circular-bar>
    <div>読み込み中です<br />しばらくお待ち下さい</div>
  </div>
</div>
