import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
@Injectable({
  providedIn: 'root'
})
export class ApiErrorHandlingService {

  constructor(
    private messageService: MessageService
  ) { }

  public handleError<T>(dispMessage = true, result?: T): any {
    return (error: any): Observable<T> => {
      if (dispMessage) {
        this.messageService.templeteMessage(messageList.M00001)
        console.log(`operation failed: ${error.message}`)
      }
      return of(error.error as T)
    }
  }
}
