<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-progress *ngIf="loading"></app-progress>
  <ul class="c-feature">
    <li class="c-feature__unit">
      <h2 class="c-feature__title">
        <i class="c-icon-shukka"></i>{{ csvItemsUra[0].label }}
      </h2>
      <ul class="c-feature__list__ura">
        <li
          *ngFor="let item of csvItemsUra[0].children"
          class="c-feature__item__ura"
        >
          <a (click)="openCsvDialog(item.path)" style="cursor: pointer">
            <span>{{ item.label }}</span>
            <i class="c-icon-arrow-s-right"></i
          ></a>
        </li>
      </ul>
    </li>
    </ul>
</app-main-contents>

<ng-template #menusTemplate let-item="item">
  <h2 class="c-feature__title">
    <i [class]="'c-icon-' + item.icon"></i>{{ item.label }}
  </h2>
  <ul class="c-feature__list__ura">
    <li *ngFor="let child of item.children" class="c-feature__item__ura">
      <a  (click)="openCsvDialog(item.path)"style="cursor: pointer">
        <span>{{ child.label }}</span>
        <i class="c-icon-arrow-s-right"></i
      ></a>
    </li>
  </ul>
</ng-template>

<igx-dialog #csvDialog [closeOnOutsideSelect]="true" message="">
  <div style="text-align: left; width: 800px; max-height: 500px; padding: 2rem">
    <h2 class="c-texts--head1" style="margin-bottom: 0">
      ダウンロード形式と期間を選択してください。
    </h2>

    <div>
      <div>◆DL形式</div>
      <div class="c-form__selectbox">
        <select
          class="c-form__select"
          [(ngModel)]="selectedType"
        >
          <option
            *ngFor="let item of outType"
            [value]="item.value"
          >
            {{ item.label }}
          </option>
        </select>
      </div>
      <div>◆対象期間</div>
      <div
      *ngIf="rangeYm"
       class="c-form__selectbox">
        <select
          class="c-form__select"
          [(ngModel)]="selectedYm"
        >
          <option
            *ngFor="let item of rangeYm"
            [value]="item.value"
          >
            {{ item.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="c-btns">
      <div class="c-btns__item">
        <a
          class="js-rename-close c-btn"
          (click)="csvDialog.close()"
          style="cursor: pointer; margin-right: 1rem"
        >
          <span>キャンセル</span></a
        >
      </div>
      <div class="c-btns__item">
        <a
        class="c-btn c-btn--blue"
        (click)="downloadInvoiveRakuCsvFile()"
        style="cursor: pointer; color: white;"
        ><span>CSVダウンロード</span></a
      >
      </div>
    </div>
  </div>
</igx-dialog>
