<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-sending *ngIf="sending"></app-sending>
  <app-base-grid
    [data]="cancelInfoList"
    [columns]="columns"
    [loading]="loading"
  ></app-base-grid>
  <app-under-btn-disp [actionButtons]="actionButtons"></app-under-btn-disp>
</app-main-contents>

<app-confirm-dialog
  *ngIf="inputDatedialogOpen"
  message="解約日を設定します。"
  (commit)="cancelConect()"
  (cancel)="inputDatedialogOpen = false"
>
  <div class="center">
    <app-date-picker [(ngModel)]="endDatetime"></app-date-picker>
  </div>
</app-confirm-dialog>
