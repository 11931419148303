<div class="c-btns__item" style="text-align: right;">
  <a class="c-btn" (click)="clickDownLoadButton.emit();" style="cursor: pointer"
    ><span>見積書PDFダウンロード</span></a
  >
</div>
<app-action-grid
  [data]="detailList"
  [loading]="loading"
  [rowSelection]="rowSelection"
  [columns]="columns"
  [height]="400"
></app-action-grid>
