import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import moment from 'moment'
import { lastValueFrom } from 'rxjs'
import { BackendTagMaster, Tag } from 'src/api/common/api-common.interface'
import { ChildrenProduct } from 'src/api/conta-api/conta-api.interface'
import { ChildrenMerchandise, Merchandise, MerchandiseDetail, SelectInfoItem } from 'src/api/merchandises-api/merchandises-api.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { Product, ActuateSetting, BackendProductDatail } from 'src/api/products-api/products-api.interface'
import { ProductsApiService } from 'src/api/products-api/products-api.service'
import { DeepCopy } from 'src/app/common/common'
import { MessageService } from 'src/app/components/message/message.service'
import { SelecterInfo } from '../product-edit-dialog/product-edit-dialog.component'

@Component({
  selector: 'app-merchandise-edit-dialog',
  templateUrl: './merchandise-edit-dialog.component.html',
  styleUrls: ['./merchandise-edit-dialog.component.scss']
})
export class MerchandiseEditDialogComponent implements OnInit {
  @Input() merchandises: Merchandise[]
  @Output() addMerchandise = new EventEmitter<Merchandise>()
  @Output() updateMerchandise = new EventEmitter<Merchandise>()
  loading = false
  merchandiseDetail: MerchandiseDetail = null
  selecterSettingEmpty: SelectInfoItem = {
    merchandiseDivisionCd: 'merchandise',
    merchandiseCompositionCd: 'single',
    required: true,
    multiple: true,
    open: true,
    chooseQuantity: 0
  }
  merchandiseDetailEmpty: MerchandiseDetail = {
    merchandiseCd: '',
    merchandiseName: '',
    merchandiseDisplayName: '',
    merchandiseReferencePrice: 0,
    selecterSetting: DeepCopy(this.selecterSettingEmpty),
    actuateSettings: [],
    childrenMerchandise: [],
    products: [],
    tags: []
  }
  tagMaster: BackendTagMaster[] = []
  collectionPurposeTagCd = 'service' // サービスタグ (旧名称：コレクション)
  tagEditMode = false
  childEditMode = false
  productEditMode = false
  products: Product[] = []
  productDetails: BackendProductDatail[] = []
  deleteMode = false
  searchChildInput = ''
  childSelection: Merchandise[] = []
  childSelectionAndMore = false
  productSelection: Product[] = []
  productSelectionAndMore = false
  newEditMode = false
  itemSettingEmpty: ActuateSetting = {
    key: '',
    name: '',
    value: '',
    type: 'Number',
    editable: 1
  }
  selecterInfoList: SelecterInfo[] = [
    {
      key: 'merchandiseDivisionCd',
      label: '商品区分',
      type: 'Selecte',
      selecter: [
        {
          value: 'merchandise',
          label: '商品'
        },
        {
          value: 'comprehensive_merchandise',
          label: '包括商品'
        },
        {
          value: 'syokuchi',
          label: '諸口'
        },
        {
          value: 'others',
          label: 'その他'
        }
      ]
    },
    {
      key: 'merchandiseCompositionCd',
      label: '構成',
      type: 'Selecte',
      selecter: [
        {
          value: 'single',
          label: '単品'
        },
        {
          value: 'set',
          label: 'セット'
        },
        {
          value: 'select',
          label: '選択'
        }
      ]
    },
    {
      key: 'required',
      label: '親商品を購入時に必須',
      type: 'Selecte',
      selecter: [
        {
          value: true,
          label: '必須'
        },
        {
          value: false,
          label: '任意'
        }
      ]
    },
    {
      key: 'multiple',
      label: '複数購入',
      type: 'Selecte',
      selecter: [
        {
          value: true,
          label: '可'
        },
        {
          value: false,
          label: '不可'
        }
      ]
    },
    {
      key: 'open',
      label: '公開',
      type: 'Selecte',
      selecter: [
        {
          value: true,
          label: '公開'
        },
        {
          value: false,
          label: '非公開'
        }
      ]
    },
    {
      key: 'chooseQuantity',
      label: '選択可能数',
      type: 'Number',
      selecter: []
    }
  ]
  dialogOpen = false
  sending = false

  constructor(
    private merchandisesApiService: MerchandisesApiService,
    private productsApiService: ProductsApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getTagMaster()
    this.getProducts()
  }

  get normalTags(): Tag[] {
    if (!this.merchandiseDetail?.tags) {
      return []
    }
    return this.merchandiseDetail.tags.filter(x => x.tagPurposeCd !== this.collectionPurposeTagCd)
  }

  get collectionTags(): Tag[] {
    if (!this.merchandiseDetail?.tags) {
      return []
    }
    return this.merchandiseDetail.tags.filter(x => x.tagCd === this.collectionPurposeTagCd)
  }

  // コレクションのタグだけ抜き出して使う
  get collectionTagMaster(): BackendTagMaster {
    if (!this.tagMaster) {
      return null
    }
    return this.tagMaster.find(x => x.tagPurposeCd === this.collectionPurposeTagCd)
  }

  get normalTagMaster(): BackendTagMaster[] {
    if (!this.tagMaster) {
      return []
    }
    return this.tagMaster.filter(x => x.tagPurposeCd !== this.collectionPurposeTagCd)
  }

  async getTagMaster(): Promise<void> {
    // const ret = await this.merchandisesApiService.GetMerchandisesTagMaster().toPromise()
    const ret$ = this.merchandisesApiService.GetMerchandisesTagMaster()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.tagMaster = ret.data
  }

  async getProducts(): Promise<void>{
    const ret = await this.productsApiService.GetFlatList()
    if (ret?.resultCode !== 0) { return }
    this.products = ret.data
  }

  getProductDetails(products: ChildrenProduct[]): void {
    products.forEach(product => {
      this.setActuateSetting(product.productCd)
    })
  }

  async setActuateSetting(productCd: string): Promise<void> {
    const ret = await this.productsApiService.GetProductDetail(productCd)
    if (ret?.resultCode !== 0) { return }
    this.productDetails.push(ret.data)
    this.setDetailItemSetting()
  }

  removeActuateSetting(productCd: string): void {
    this.productDetails = this.productDetails.filter(x => x.productCd !== productCd)

    const anotherActuateSettings: ActuateSetting[] = []
    this.productDetails.forEach(detail => {
        // 製品の稼働設定項目
        anotherActuateSettings.push(...detail.actuateSettings)
    })

    // その他の設定項目に無ければ削除
    const removeKeys: string[] = []
    this.merchandiseDetail.actuateSettings.forEach(actuateSetting => {
      if (!anotherActuateSettings.find(x => x.key === actuateSetting.key)){
        removeKeys.push(actuateSetting.key)
      }
    })
    removeKeys.forEach(removeKey => {
      this.merchandiseDetail.actuateSettings = this.merchandiseDetail.actuateSettings.filter(x => x.key !== removeKey)
    })
   }

  setDetailItemSetting(): void {
    this.merchandiseDetail.products.forEach(product => {
      const detail = this.productDetails.find(x => x.productCd === product.productCd)
      if (detail?.actuateSettings) {
        // 製品の稼働設定項目
        detail.actuateSettings.forEach(setting => {
          const find = this.merchandiseDetail.actuateSettings.find(x => x.key === setting.key)
          if (!find) {
            // そのまま追加
            this.merchandiseDetail.actuateSettings.push(setting)
          }
        })
      }
    })
  }

  reset(): void {
    this.merchandiseDetail = DeepCopy(this.merchandiseDetailEmpty)
    this.newEditMode = false
    this.tagEditMode = false
    this.childEditMode = false
    this.productEditMode = false
    this.deleteMode = false
    this.searchChildInput = ''
    this.childSelection = []
    this.childSelectionAndMore = false
    this.productSelection = []
    this.productSelectionAndMore = false
    this.productDetails = []
  }

  openUpdateDialog(merchandiseCd: string): void {
    this.reset()
    this.dialogOpen = true
    this.getMerchandiseDetail(merchandiseCd)
  }

  openCreateDialog(): void {
    this.reset()
    this.dialogOpen = true
    this.newEditMode = true
  }

  changeDate(key, value): void {
    this.merchandiseDetail[key + 'String'] = value
    this.merchandiseDetail[key] = new Date(value)
  }

  changeSelecter(key, e): void{
    const value = e.valueAccessor.value
    if (this.merchandiseDetail.selecterSetting[key] === value) {
      return
    }
    this.merchandiseDetail.selecterSetting[key] = value
  }

  selectedLabel(key: string): string {
    const item = this.selecterInfoList.find(x => x.key === key)
    return item.selecter.find(x => x.value === this.merchandiseDetail.selecterSetting[key])?.label
  }

  tagAttachment(tagCd: string): boolean {
    const tags = this.merchandiseDetail?.tags
    if (!tags) {
      return false
    }
    return tags.find(x => x.tagCd === tagCd) ? true : false
  }

  addSetting(): void {
    const empty = DeepCopy(this.itemSettingEmpty)
    this.merchandiseDetail.actuateSettings.push(empty)
  }

  searchChildMerchandise(searchKey: string): void {
    if (searchKey === '') {
      this.childSelection = []
      return
    }
    let filter = this.merchandises.filter(x => x.merchandiseName.indexOf(searchKey) !== -1)
    this.merchandiseDetail.childrenMerchandise.forEach(child => {
      filter = filter.filter(x => x.merchandiseCd !== child.merchandiseCd)
    })
    // 自分自身も除外
    filter = filter.filter(x => x.merchandiseCd !== this.merchandiseDetail.merchandiseCd)
    if (filter.length > 10) {
      filter = filter.slice(0, 10)
      this.childSelectionAndMore = true
    }
    else {
      this.childSelectionAndMore = false
    }
    this.childSelection = filter
  }

  childClick(merchandiseCd: string): void {
    const child = this.merchandiseDetail?.childrenMerchandise
    if (!child) {
      return
    }
    const selectFlg = child.find(x => x.merchandiseCd === merchandiseCd) ? true : false
    if (selectFlg) {
      this.merchandiseDetail.childrenMerchandise = this.merchandiseDetail.childrenMerchandise.filter(x => x.merchandiseCd !== merchandiseCd)
    }
    else {
      const child2 = this.merchandises.find(x => x.merchandiseCd === merchandiseCd)
      const addChild: ChildrenMerchandise = {
        merchandiseCd: child2.merchandiseCd,
        merchandiseName: child2.merchandiseName
      }
      this.merchandiseDetail.childrenMerchandise.push(addChild)
      this.childSelection = this.childSelection.filter(x => x.merchandiseCd !== child2.merchandiseCd)
    }
  }


  searchProducts(searchKey: string): void {
    if (searchKey === '') {
      this.productSelection = []
      return
    }
    let filter = this.products.filter(x => x.productName.indexOf(searchKey) !== -1)
    this.merchandiseDetail.products.forEach(child => {
      filter = filter.filter(x => x.productCd !== child.productCd)
    })
    if (filter.length > 10) {
      filter = filter.slice(0, 10)
      this.productSelectionAndMore = true
    }
    else {
      this.productSelectionAndMore = false
    }
    this.productSelection = filter
  }

  productClick(productCd: string): void {
    const products = this.merchandiseDetail?.products
    if (!products) {
      return
    }
    const selectFlg = products.find(x => x.productCd === productCd) ? true : false
    if (selectFlg) {
      this.merchandiseDetail.products = this.merchandiseDetail.products.filter(x => x.productCd !== productCd)
    }
    else {
      const products2 = this.products.find(x => x.productCd === productCd)
      const add: ChildrenProduct = {
        productCd: products2.productCd,
        productName: products2.productName
      }
      this.merchandiseDetail.products.push(add)
      this.productSelection = this.productSelection.filter(x => x.productCd !== products2.productCd)
      this.setActuateSetting(products2.productCd)
    }
  }

  tagClick(tagCd: string): void {
    const tags = this.merchandiseDetail?.tags
    if (!tags) {
      this.merchandiseDetail.tags = []
    }
    const selectFlg = tags.find(x => x.tagCd === tagCd) ? true : false
    if (selectFlg) {
      this.merchandiseDetail.tags = this.merchandiseDetail.tags.filter(x => x.tagCd !== tagCd)
    }
    else {
      const addTag = this.tagInfo(tagCd)
      if (addTag) {
        this.merchandiseDetail.tags.push(addTag)
      }
    }
  }

  tagInfo(tagCd: string): Tag {
    if (!this.tagMaster) {
      return null
    }
    for (const purpose of this.tagMaster) {
      const findTag = purpose.textTags.find(x => x.tagCd === tagCd)
      if (findTag) {
        const tag: Tag = {
          tagPurposeCd: purpose.tagPurposeCd,
          tagPurposeText: purpose.tagPurposeText,
          tagCd: findTag.tagCd,
          tagText: findTag.tagText
        }
        return tag
      }
    }
    return null
  }

  getMerchandiseDetail(merchandiseCd: string): void {
    this.merchandisesApiService
      .GetMerchandiseDetail(merchandiseCd)
      .subscribe((ret: any) => {
        const data: MerchandiseDetail = ret.data[0]
        data.salesStartDatetimeString = moment(data.salesStartDatetime).format('YYYY-MM-DD')
        data.salesEndDatetimeString = moment(data.salesEndDatetime).format('YYYY-MM-DD')
        this.merchandiseDetail = data
        this.getProductDetails(this.merchandiseDetail.products)
      })
  }

  removeChild(merchandiseCd: string): void {
    this.merchandiseDetail.childrenMerchandise = this.merchandiseDetail.childrenMerchandise.filter(x => x.merchandiseCd !== merchandiseCd)
  }

  removeProduct(productCd: string): void {
    this.merchandiseDetail.products = this.merchandiseDetail.products.filter(x => x.productCd !== productCd)
    this.removeActuateSetting(productCd)
  }

  commit(): void {
    if (this.sending) {
      return
    }
    this.merchandiseDetail.actuateSettings.forEach(setting => {
      switch (setting.type) {
        case 'Number':
          setting.value = Number(setting.value) ? Number(setting.value) : null
          break
        case 'String':
          setting.value = setting.value ? setting.value.toString() : null
      }
    })

    if (this.sending) { return }
    this.sending = true
    this.dialogOpen = false
    if (this.newEditMode) {
      this.merchandisesApiService
        .CreateMerchandise(this.merchandiseDetail)
        .subscribe((ret: any) => {
          this.sending = false
          this.messageService.success('処理完了')
          const data: MerchandiseDetail = ret.data[0]
          const newData: Merchandise = {
            merchandiseCd: data.merchandiseCd,
            merchandiseName: data.merchandiseName,
            merchandiseReferencePrice: data.merchandiseReferencePrice
          }
          this.addMerchandise.emit(newData)
        })
    }
    else {
      this.merchandisesApiService
        .UpdateMerchandise(this.merchandiseDetail)
        .subscribe((ret: any) => {
          this.sending = false
          this.messageService.success('処理完了')
          const data: MerchandiseDetail = ret.data[0]
          const newData: Merchandise = {
            merchandiseCd: data.merchandiseCd,
            merchandiseName: data.merchandiseName,
            merchandiseReferencePrice: data.merchandiseReferencePrice
          }
          this.updateMerchandise.emit(newData)
        })
    }
  }

  deleteComform(): void {
    this.deleteMode = true
    this.childEditMode = false
    this.productEditMode = false
    this.tagEditMode = false
  }
}
