import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'

@Component({
  selector: 'app-easy-auth-login-router-outlet',
  templateUrl: './easy-auth-login-router-outlet.component.html',
  styleUrls: ['./easy-auth-login-router-outlet.component.scss']
})
export class EasyAuthLoginRouterOutletComponent implements OnInit {
  authenticationUriCd: string
  login = false
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.authenticationUriCd = params.get('authenticationUriCd')
    })
  }
}
