<app-main-contents>
  <app-shimura-garage-app-setting #settingDialog (submit)="commit($event)"></app-shimura-garage-app-setting>
  <app-breadcrumbs [label2]="backTitle" [link2]="'micomel-migration-estimate'" [label3]="title"></app-breadcrumbs>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <div *ngIf="fileUrl" class="pdf h-margin--t16" [ngStyle]="{ height: pdfHeight + 'px' }">
    <iframe [src]="fileUrl"> </iframe>
  </div>
  <div class="c-policy">
    <label class="c-form__checkbox2">
      <input type="checkbox" [(ngModel)]="checkedPolicy" (click)="changeCheckPolicy()" /><span>利用規約に同意する</span>
    </label>
  </div>
  <app-under-btn-disp [actionButtons]="actionButtons" [backUrl]="backUrl"></app-under-btn-disp>
</app-main-contents>
<app-dialog *ngIf="dialogOpen" [hideCancelButton]="true" (commit)="goTop()" (cancel)="goTop()">
  <p>
    お試し利用のご登録を承りました。<br />
    受付完了のメールをお送りしておりますので、ご確認ください。<br />
    ※メールが到着するまで、お時間をいただく場合がございます。
  </p>
</app-dialog>
