import { Injectable } from '@angular/core'
import { SelectMaster } from '../input-table/input-table.model'

@Injectable({
  providedIn: 'root'
})
export class InputFormService {

  constructor() { }

  convertForSelectMaster(list: any[], valueKey: string, labelKey: string, ): SelectMaster[] {
    const ret: SelectMaster[] = []
    list.forEach(row => {
      const item: SelectMaster = {
        value: row[valueKey],
        label: row[labelKey]
      }
      ret.push(item)
    })
    return ret
  }
}
