<div class="l-main__contents js-fit-contents">
  <h1 class="c-texts--head1">見積依頼</h1>
  <h2 class="c-texts--head2">以下の概算見積（入力内容）を確認してください。</h2>
  <div class="c-total h-margin--t24">
    <div class="c-total__head">
      <h3>サービス利用料合計</h3>
    </div>
    <div class="c-total__body">
      <h4>月額費用</h4>
      <p>￥{{ 0 | number }}</p>
    </div>
  </div>
  <ul class="c-texts--attention h-margin--t12">
    <li>※実際には月額費用には取引高に応じた従量課金が発生いたします。</li>
    <li>＊金額は全て税込表示です。</li>
  </ul>
  <!-- <ul class="c-links h-margin--t24">
      <li><a (click)="addService()" style="cursor:pointer"><i class="c-icon__add"></i>サービスを追加</a></li>
      <li><a (click)="goToQuotationConfirm3()" style="cursor:pointer" [class.link-disable]="editDisabled"><i class="c-icon__edit"></i>選択した行を修正</a></li>
    </ul> -->
  <div class="c-services h-margin--t16" id="services-list">
    <!-- ヘッダー-->
    <div class="c-services__head">
      <p>サービス名</p>
      <p>業態</p>
      <p>数量</p>
      <p>月額費用</p>
    </div>
    <div class="c-services__body">
      <ul class="c-services__list">
        <li class="c-services__item" *ngFor="let merchandise of merchandisesTree">
          <div class="c-services__line js-toggle-trigger">
            <div class="c-services__name c-services__name--main">
              <span
                ><i class="c-icon__arrow_s_top"></i
                ><i class="c-icon__arrow_s_bottom"></i>{{merchandise.merchandiseName}}</span
              >
            </div>
            <div></div>
            <div>5</div>
            <div>\ 0</div>
            <div>\ 150,000</div>
            <div>-</div>
          </div>
          <ul class="c-services__children">
            <li class="c-services__child" >
              <div class="c-services__line">
                <div class="c-services__name c-services__name--sub">
                  <label
                    class="
                      c-form__checkbox c-form__checkbox--s
                      c-services__check
                    "
                  >
                    <input type="checkbox" /><span>しまむらpack</span>
                  </label>
                </div>
                <div class="c-services__name c-services__name--sub">
                  しまむら
                </div>
                <div>1</div>
                <div>\ 0</div>
                <div>\ 30,000</div>
                <div>-</div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
