<app-main-contents>
    <h2 class="c-texts--head2 c-texts--center h-margin--t48">
      <b>パスワードを忘れたら</b>
    </h2>
    <p class="c-texts--center h-margin--t24">
      ご登録のメールアドレス宛に<br />パスワード再設定用URLが記載されたメールを送信致します。
    </p>
    <div class="c-login__form c-login__form--mim h-margin--t40">
      <div class="c-login__input">
        <label class="c-login__label">ログインID</label>
        <input
          class="c-form__input"
          type="text"
          placeholder="ログインID"
          [(ngModel)]="nickName"
        />
      </div>
      <div class="c-texts--center h-margin--t48">
        <button class="c-btn c-btn--blue" (click)="sendMail()"><span>送信する</span></button>
      </div>
    </div>
  </app-main-contents>
  