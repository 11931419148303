import { Injectable } from '@angular/core'
import { lastValueFrom, Subject } from 'rxjs'
import { InvitationsApiService } from 'src/api/invitations-api/invitations-api.service'
import { AuthService } from '../isbeauth/auth.service'
import { NavMenueItem, NotificationFile, NotificationItem } from './nav-menu.interface'
import { environment } from 'src/environments/environment'
import moment from 'moment'
import { navMenuListUra } from './nav-menu-list'
import { Router } from '@angular/router'
import { ShimuraGarageMigrationsService } from 'src/api/micomel-migrations-api/micomel-migrations-api.service'
import { NotificationApiService } from 'src/api/notification-api/notices-api.service'
import { Wbs } from 'src/api/petabo-api/petabo-api.interface'
import { PetaboApiService } from 'src/api/petabo-api/petabo-api.service'
import { forEach } from 'jszip'

@Injectable({
  providedIn: 'root'
})
export class NavService {
  constructor(
    private invitationsApiService: InvitationsApiService,
    private notificationApiService: NotificationApiService,
    private authService: AuthService,
    private router: Router,
    private micomelMigrationsService: ShimuraGarageMigrationsService,
    private petaboApiService: PetaboApiService
  ) {}

  private personalNotificationList: NotificationItem[] = []
  private overAllNotificationList: NotificationFile[] = []
  private petaboNotificationList: Wbs[] = []

  private personalNotificationListInfo = new Subject<NotificationItem[]>()
  private overAllNotificationListInfo = new Subject<NotificationFile[]>()
  private petaboNotificationListInfo = new Subject<Wbs[]>()

  public personalNotificationListInfoSource$ = this.personalNotificationListInfo.asObservable()
  public overAllNotificationListInfoSource$ = this.overAllNotificationListInfo.asObservable()
  public petaboNotificationListInfoSource$ = this.petaboNotificationListInfo.asObservable()

  public get getPersonalAlertAction(): NotificationItem[] {
    return this.personalNotificationList
  }
  public get getOverAllAlertAction(): NotificationFile[] {
    return this.overAllNotificationList
  }
  public get getPetaboNotificationAction(): Wbs[] {
    return this.petaboNotificationList
  }
  public updatenotificationList(): void {
    this.updatePersonalNotificationList()
    this.updateOverAllNotificationList()
    this.updatePetaboNotificationList()
  }
  public updatePersonalNotificationList(): void {
    this.personalNotificationList = []

    const promise1 = new Promise(async (resolve) => {
      const ret = await this.getInvitationSendList()
      if (ret.length) {
        this.personalNotificationList.push(...ret)
      }
      resolve(ret)
    })
    const promise2 = new Promise(async (resolve) => {
      const ret = await this.getInvitationReceiveList()
      if (ret.length) {
        this.personalNotificationList.push(...ret)
      }
      resolve(ret)
    })
    const promise3 = new Promise(async (resolve) => {
      const ret = await this.getMicomelMessage()
      if (ret.length) {
        this.personalNotificationList.push(...ret)
      }
      resolve(ret)
    })

    Promise.all([promise1, promise2, promise3]).then(async () => {
      this.personalNotificationListInfo.next(this.personalNotificationList)
    })
  }

  // S3の専用ファイルからお知らせ取得 (今後廃止するのかも)
  public async updateOverAllNotificationList(): Promise<void> {
    this.overAllNotificationList = []

    const ret = await this.getFileNotifications()
    if (ret.length) {
      this.overAllNotificationList.push(...ret)
      this.overAllNotificationListInfo.next(this.overAllNotificationList)
    }
  }

  // Petaboからお知らせ取得 (新しい方法)
  public async updatePetaboNotificationList(): Promise<void> {
    this.petaboNotificationList = []

    const petaboFqdn = 'petabo.myidea.notification'
    const ret = await this.petaboApiService.getPetabo(petaboFqdn)
    if (ret?.resultCode !== 0) {
      return
    }
    // this.petaboNotificationList.push(...ret.data)
    this.petaboNotificationList = ret.data
    this.petaboNotificationListInfo.next(this.petaboNotificationList)
  }

  // 招待送信件数取得
  async getInvitationSendList(): Promise<NotificationItem[]> {
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd) {
      return []
    }
    // const ret = await this.invitationsApiService.GetInvitationSendList(customerCd).toPromise()
    const ret$ = this.invitationsApiService.GetInvitationSendList(customerCd)
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) {
      return []
    }
    const filter = ret.data.filter((x) => x.invitationStatusCd === '0')
    const invitationSendCount = filter.length
    if (invitationSendCount !== 0) {
      const message: NotificationItem = {
        title: '相手が未回答の招待が',
        count: invitationSendCount,
        link: '/invitation-send-list'
      }
      return [message]
    }
    return []
  }
  // 招待受信件数取得
  async getInvitationReceiveList(): Promise<NotificationItem[]> {
    const list: NotificationItem[] = []
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd) {
      return list
    }
    // const ret = await this.invitationsApiService.GetInvitationReceiveList(customerCd).toPromise()
    const ret$ = this.invitationsApiService.GetInvitationReceiveList(customerCd)
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) {
      return list
    }
    const filter = ret.data.filter((x) => x.invitationStatusCd === '0')
    const invitationReceiveCount = filter.length

    if (invitationReceiveCount !== 0) {
      const message: NotificationItem = {
        title: '未承認の招待が',
        count: invitationReceiveCount,
        link: '/invitation-receive-list'
      }
      list.push(message)
    }
    return list
  }

  // 招待受信件数取得
  async getMicomelMessage(): Promise<NotificationItem[]> {
    // 2024年9月31日までこの処理を使う。その後はからのリストを返す。
    const limitDate = new Date('2024-10-01T00:00:00')
    const now = new Date()
    if (now > limitDate) {
      return []
    }

    const list: NotificationItem[] = []
    const myContracts = await this.micomelMigrationsService.checkContractCustomer(
      this.authService.AccountInfo.customerCd
    )
    if (myContracts?.resultCode !== 0) {
      return
    }
    // 未承認かつメール送信済みの契約があるかチェック
    const notApproved = myContracts.data.filter((x) => x.approved === false && x.sendMail === true)
    if (notApproved.length === 0) {
      return list
    }

    // const ret2 = await this.micomelMigrationsService.getEstimateData(data.contractCompanyOrganizationCd)
    // if (ret2?.resultCode !== 0) {
    //   return
    // }

    // notApprovedの数分回してデータを取得する
    let promises = []
    notApproved.forEach((item) => {
      promises.push(
        this.micomelMigrationsService.getEstimateData(item.contractCompanyOrganizationId, item.parentContractId)
      )
    })

    const contracts = await Promise.all(promises)
    const messages = contracts.map((item, index) => {
      const firstItem = item.data[0]
      // const estimate = ret2[index].data[0]
      let extraMessage = ''
      // myContracts が2つ以上の場合は、extraMessageにparentActuateNameを追加
      if (myContracts.data.length > 1) {
        extraMessage = `（${item.data[0].parentActuateName}様分）`
      }

      const massage: NotificationItem = {
        title: `【Products Garage 】思夢樂事業アプリ 登録のご案内${extraMessage}`,
        count: 0,
        link: `/shimura-garage-migration-estimate-link/${firstItem.companyOrganizationId}/${firstItem.parentContractId}`
      }
      return massage
    })

    list.push(...messages)
    return list
  }

  // 固定のメッセージをファイルから取得
  async getFileNotifications(): Promise<NotificationFile[]> {
    const json = await this.getNotificationJson()
    const nowDatetime = new Date(new Date().toISOString())

    const activeObjs = json.filter((o: NotificationFile) => {
      return (
        (o.releaseDatetime ? nowDatetime > new Date(new Date(o.releaseDatetime).toISOString()) : true) &&
        (o.endDatetime ? nowDatetime < new Date(new Date(o.endDatetime).toISOString()) : true)
      )
    })

    activeObjs.sort((a: NotificationFile, b: NotificationFile) => {
      const left = a.releaseDatetime ? new Date(a.releaseDatetime) : new Date('2000/01/01')
      const right = b.releaseDatetime ? new Date(b.releaseDatetime) : new Date('2000/01/01')
      return left < right ? 1 : -1
    })

    const ret = []
    activeObjs.forEach((item) => {
      const date = moment(item.releaseDatetime).format('YYYY.MM.DD')

      const message: NotificationFile = {
        releaseDatetime: date,
        description: item.description,
        descriptionLink: item.descriptionLink,
        endDatetime: '',
        highlight: item.highlight
      }
      ret.push(message)
    })
    return ret
  }

  async getNotificationJson(): Promise<NotificationFile[]> {
    try {
      const promis = await fetch(this.notificationApiService.StaticMessageUrl, {
        cache: 'no-store'
      })
      const json = await promis.json()

      if (json && json.length > 0) {
        return json
      }
    } catch {
      return null
    }
  }

  uraMenuList(): NavMenueItem[] {
    navMenuListUra.forEach((parent) => {
      parent.children.forEach((child) => {
        child.disabled = !this.authService.checkAuthorityForView(child.path)
      })
      parent.disabled = true
      if (parent.children.length) {
        const firstChild = parent.children.find((x) => !x.disabled)
        if (firstChild) {
          parent.disabled = false
          parent.path = firstChild.path
        } else {
          parent.disabled = true
          parent.path = null
        }
      } else {
        parent.disabled = false
      }
    })
    return navMenuListUra
  }
}
