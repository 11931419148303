import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input
} from '@angular/core'
import { Router } from '@angular/router'
import { ActionButtonDisp, FooterButtonTextAlign } from './under-btn-disp.interface'

@Component({
  selector: 'app-under-btn-disp',
  templateUrl: './under-btn-disp.component.html',
  styleUrls: ['./under-btn-disp.component.scss'],
})
export class UnderBtnDispComponent implements OnInit {
  @Output() actionCommit = new EventEmitter<string>()
  // ボタンのリスト
  // ・textAlign.rightがデフォルト
  // ・textAlign.rightだと青ボタン(class名「c-btn--blue」)がデフォルト
  // ・textAlign.leftだとグレーボタン(class名「c-btn--sub」)がデフォルト
  @Input() actionButtons: ActionButtonDisp[] = []
  @Input() backUrl: string
  @Input() showGridSelecter = false // Grid用、まだ準備途中

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (this.backUrl) {
      const back =     {
        label: '戻る',
        textAlign: FooterButtonTextAlign.LEFT,
        actionFunction: () => {
          this.router.navigate([`${this.backUrl}`])
        }
      }
      this.actionButtons.push(back)
    }
  }

  // 左寄せボタン
  actionButtonsLeft(): ActionButtonDisp[] {
    return this.actionButtons.filter(x => x.textAlign === FooterButtonTextAlign.LEFT)
  }

  // 右寄せボタン
  actionButtonsRight(): ActionButtonDisp[] {
    return this.actionButtons.filter(x => x.textAlign !== FooterButtonTextAlign.LEFT)
  }

  // アクションボタン押下
  action(actionButton: ActionButtonDisp): void {
    if (actionButton?.actionFunction) {
      actionButton.actionFunction()
    }
    if (actionButton.actionCd) {
      this.actionCommit.emit(actionButton.actionCd)
    }
  }

  // 戻るボタン押下時処理
  pageBack(): void {
    this.router.navigate([this.backUrl])
  }
}
