import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { AutoMap, DeepCopy } from 'src/app/common/common'
import { CustomValidators, MustMatch } from './custom-validator'
import { ConvertFormInputInfo, FormInputInfo } from './input-table.model'
import { ValidatorService } from './validator.service'
import * as historyKana from 'historykana'

@Component({
  selector: 'app-input-table',
  templateUrl: './input-table.component.html',
  styleUrls: ['./input-table.component.scss']
})
export class InputTableComponent implements OnInit {
  @Input() formItems: FormInputInfo[] = []
  @Input() formValue: any = {} // formItemsのkeyと対応するオブジェクト
  @Output() keyUpEvent = new EventEmitter<any>()
  @Output() changeEvent = new EventEmitter<any>()
  convertFormItems: ConvertFormInputInfo[]
  myControl: UntypedFormGroup
  history: string[] = []
  constructor(
    private fb: UntypedFormBuilder,
    private validatorService: ValidatorService,
  ) { }

  ngOnInit(): void {
    this.setForm(this.formValue)
  }

  setConvertFormItems(): void {
    this.convertFormItems = []
    AutoMap<ConvertFormInputInfo[]>(this.formItems).forEach(item => {
      item.secret = item.type === 'password'
      if (item.group?.length) {
        const convertChilds: ConvertFormInputInfo[] = []
        item.group.forEach(child => {
          const convert = AutoMap<ConvertFormInputInfo>(child)
          convert.secret = convert.type === 'password'
          convertChilds.push(convert)
        })
        item.convertGroup = this.divideArrIntoPieces(convertChilds, 2)
      }
      this.convertFormItems.push(item)
    })
  }

  getInptValue(formItem: FormInputInfo): string {
    if (formItem.type === 'select') {
      if (!formItem.master) {
        return ''
      }
      const find = formItem.master.find(x => x.value === this.formValue[formItem.key])
      return find ? find.value : ''
    }
    else {
      return this.formValue[formItem.key]
    }
  }

  // 配列を指定の数(n)で分割する
  // [1,2,3,4,5,6,7,8,9] n = 3 → [[1,2,3],[4,5,6],[7,8,9]]
  // 複数項目を同じ枠に表示する際、2項目ごとに改行させる為に使用
  divideArrIntoPieces(arr: any[], n: number): any[][] {
    const newArr = DeepCopy(arr)
    const arrList = []
    const idx = 0
    while (idx < newArr.length) {
      arrList.push(newArr.splice(idx, idx + n))
    }
    return arrList
  }


  setValue(key: string, value: any): void {
    this.formValue[key] = value
    this.setForm(this.formValue, this.formItems)
  }

  setForm(formValue: any, formItems?: any): void {
    this.formValue = formValue
    if (formItems) {
      this.formItems = formItems
    }
    if (!this.formItems || !formValue) {
      return
    }
    let form = {}
    form = this.setFormLoop(form, this.formItems)
    this.myControl = this.fb.group(form)
    this.setConvertFormItems()
  }

  setFormLoop(form: any, formItems: any[]): any {
    formItems.forEach(item => {
      if (item.group?.length) {
        form = this.setFormLoop(form, item.group)
      }
      const value = this.formValue[item.key] || ''
      form[item.key] = [value]

      // バリデーション設定
      const valid = []
      if (item.valid?.required) {
        valid.push(Validators.required)
      }
      if (item.valid?.notBlank) {
        valid.push(CustomValidators.notBlank)
      }
      if (item.valid?.mailAddress) {
        valid.push(Validators.email)
      }
      if (item.valid?.minLength) {
        valid.push(Validators.minLength(item.valid.minLength))
      }
      if (item.valid?.maxLength) {
        valid.push(Validators.maxLength(item.valid.maxLength))
      }
      if (item.valid?.alphanumeric) {
        valid.push(CustomValidators.alphanumeric)
      }
      if (item.valid?.password) {
        valid.push(CustomValidators.password)
      }
      if (item.valid?.numOnly) {
        valid.push(CustomValidators.numOnly)
      }
      if (item.valid?.hiragana) {
        valid.push(CustomValidators.hiragana)
      }
      if (item.valid?.bankAccountName) {
        valid.push(CustomValidators.bankAccountName)
      }
      if (item.valid?.mustMatchKey) {
        form.validator = MustMatch(item.key, item.valid?.mustMatchKey)
      }
      form[item.key].push(valid)
    })
    return form
  }
  formError(key: string): boolean {
    return this.validatorService.formError(this.myControl, key)
  }
  formErrorMessage(key: string): string {
    return this.validatorService.formErrorMessage(this.myControl, key)
  }
  formGroupError(inputInfos: any[][]): boolean {
    return this.validatorService.formGroupError(this.myControl, this.pickupKeyArray(inputInfos))
  }
  formGroupErrorMessage(inputInfos: any[][]): string {
    return this.validatorService.formGroupErrorMessage(this.myControl, this.pickupKeyArray(inputInfos))
  }
  // Group用の微妙な入れ子配列からkeyの配列を取得する
  pickupKeyArray(inputInfos: ConvertFormInputInfo[][]): string[] {
    const flatInfo = []
    inputInfos.forEach(inputInfo => {
      flatInfo.push(...inputInfo)
    })
    return this.pickupColumnArray(flatInfo, 'key')
  }
  // 連想配列から特定項目のみの配列を取得する
  pickupColumnArray(arr: any[], key: string): any[] {
    const ret: string[] = []
    arr.forEach(item => {
      ret.push(item[key])
    })
    return ret
  }

  // バリデーションチェックを実行し、エラーが無ければフォーム入力値を返す
  submit(): any {
    if (this.validatorService.valid(this.myControl)) { return null }
    return this.myControl.value
  }

  getValue(): any {
    return this.myControl?.value
  }

  // 項目グループ表示(お名前等)の場合、ラベルの幅を設定
  groupDetailLabelWidth(thisItem: ConvertFormInputInfo, group: ConvertFormInputInfo): string {
    let groupItems = this.formItems.find(x => x.key === group.key).group
    const findIndex = groupItems.findIndex(x => x.key === thisItem.key)
    if (findIndex % 2 === 0) {
      // indexが偶数の場合
      groupItems = groupItems.filter((x, i) => i  % 2 === 0)
    }
    else {
      // indexが奇数の場合
      groupItems = groupItems.filter((x, i) => i  % 1 === 0)
    }
    const maxLabelItem = groupItems.reduce((a, b) => a.label.length > b.label.length ? a : b)
    return maxLabelItem.label.length * 1.5 + 2 + 'rem'

  }

  required(form: ConvertFormInputInfo): boolean {
    if (!form.group?.length && form.valid?.required) {
      return true
    }
    else if (!form.group?.length && !form.valid?.required) {
      return false
    }
    const childRequired = form.group?.find(x => x.valid?.required)
    if (childRequired) {
      return true
    }
    return false
  }


  change(form: ConvertFormInputInfo): void {
    if (!form.event?.change) { return }
    return this.changeEvent.emit(this.myControl.value)
  }

  input(form: ConvertFormInputInfo): void {
    if (!form.event?.change) { return }
  }

  keyUp(form: ConvertFormInputInfo): void {
    if (form.event?.keyUp) { 
      return this.keyUpEvent.emit(this.myControl.value)
     }
  }

  updateKana(event, key): void {
    if (key === 'familyName' && this.myControl.controls.familyNameKana !== undefined){
      if (event.target.value === '' || event.target.value === null) {
        this.history = []
      } else {
        this.history.push(event.target.value)
      }

      this.myControl.controls.familyNameKana.setValue(historyKana(this.history))

    }else if (key === 'firstName' && this.myControl.controls.firstNameKana !== undefined){
      if (event.target.value === '' || event.target.value === null) {
        this.history = []
      } else {
        this.history.push(event.target.value)
      }

      this.myControl.controls.firstNameKana.setValue(historyKana(this.history))

    }else{}

  }
}
