import { Injectable } from '@angular/core'
import { DisplayPosition, OrganizationCategory, OrganizationType } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { FormInputInfo, SelectMaster } from '../common-parts/input-table/input-table.model'
import { InputOrganization } from './feature-organization.interface'
import * as historyKana from 'historykana'
import { lastValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class FeatureOrganizationService {

  constructor(
    private organizationV2Service: OrganizationV2Service
  ) { }
  private sheardValues: InputOrganization
  private formItems: FormInputInfo[]
  private organizationCategoryList: OrganizationCategory[]
  private displayPositionList: DisplayPosition[]
  private organizationTypeList: OrganizationType[]
  private organizationCategorySelectList: SelectMaster[]
  private displayPositionSelectList: SelectMaster[]
  private organizationTypeSelectList: SelectMaster[]
  // displayPositionCdBefore = 'ac5050eefe294e3d835488ff450bfb2b' // 株位置：前
  private displayPositionCdBefore = '前'
  private wordHistory: string[] = []

  getInputValues(): InputOrganization {
    return this.sheardValues
  }

  setInputValues(value: InputOrganization): void {
    this.sheardValues = value
  }

  clearValues(): void {
    this.sheardValues = null
  }

  async organizationInputItems(items: FormInputInfo[]): Promise<FormInputInfo[]> {
    if (this.formItems) {
      return this.formItems
    }
    const organizationCategory = await this.organizationCategorySelectMaster()
    const displayPosition = await this.displayPositionSelectMaster()
    const organizationType = await this.organizationTypeSelectMaster()
    items.forEach(item => {
      if (item.key === 'organizationNameInfo') {
        item.group.forEach(groupItem => {
          switch (groupItem.key) {
            case 'organizationCategoryCd':
              groupItem.master = organizationCategory
              break
            case 'displayPositionCd':
              groupItem.master = displayPosition
              break
            case 'organizationTypeCd':
              groupItem.master = organizationType
              break
          }
        })
      }
    })
    return items
  }

  // カテゴリー(株式、有限…) マスタ
  async organizationCategoryMaster(): Promise<OrganizationCategory[]> {
    if (this.organizationCategoryList) {
      return this.organizationCategoryList
    }
    //const ret = await this.organizationV2Service.GetOrganizationCategoryList().toPromise()
    const ret$ = this.organizationV2Service.GetOrganizationCategoryList()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.organizationCategoryList = ret.data
    return this.organizationCategoryList
  }

  // 表記位置マスタ
  async displayPositionMaster(): Promise<DisplayPosition[]> {
    if (this.displayPositionList) {
      return this.displayPositionList
    }
    // const ret = await this.organizationV2Service.GetOrganizationDisplayPositionList().toPromise()
    const ret$ = this.organizationV2Service.GetOrganizationDisplayPositionList()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.displayPositionList = ret.data
    return this.displayPositionList
  }

  // 組織タイプ(会社、部署…) マスタ
  async organizationTypeMaster(): Promise<OrganizationType[]> {
    if (this.organizationTypeList) {
      return this.organizationTypeList
    }
    // const ret = await this.organizationV2Service.GetOrganizationTypeList().toPromise()
    const ret$ = this.organizationV2Service.GetOrganizationTypeList()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.organizationTypeList = ret.data
    return this.organizationTypeList
  }

  // カテゴリーをSelectMasterの形で取得
  async organizationCategorySelectMaster(): Promise<SelectMaster[]> {
    if (this.organizationCategorySelectList) {
      return this.organizationCategorySelectList
    }
    const master: SelectMaster[] = []
    const dataList = await this.organizationCategoryMaster()
    dataList.forEach(data => {
      master.push(
        {
          value: data.organizationCategoryCd,
          label: data.organizationCategoryName
        }
      )
    })
    this.organizationCategorySelectList = master
    return this.organizationCategorySelectList
  }

  // 表記位置をSelectMasterの形で取得
  async displayPositionSelectMaster(): Promise<SelectMaster[]> {
    if (this.displayPositionSelectList) {
      return this.displayPositionSelectList
    }
    const master: SelectMaster[] = []
    const dataList = await this.displayPositionMaster()
    dataList.forEach(data => {
      master.push(
        {
          value: data.displayPositionCd,
          label: data.displayPositionName
        }
      )
    })
    this.displayPositionSelectList = master
    return this.displayPositionSelectList
  }

  // 組織タイプをSelectMasterの形で取得
  async organizationTypeSelectMaster(): Promise<SelectMaster[]> {
    if (this.organizationTypeSelectList) {
      return this.organizationTypeSelectList
    }
    const master: SelectMaster[] = []
    const dataList = await this.organizationTypeMaster()
    dataList.forEach(data => {
      master.push(
        {
          value: data.organizationTypeCd,
          label: data.organizationTypeName
        }
      )
    })
    this.organizationTypeSelectList = master
    return this.organizationTypeSelectList
  }

  // 正式名称を取得
  async fomalName(organizationName: string, organizationCategoryCd: string, displayPositionCd: string): Promise<string> {
    const categories = await this.organizationCategoryMaster()
    const positions = await this.displayPositionMaster()

    if (!organizationCategoryCd || !displayPositionCd || !categories.length || !positions.length) { return '' }
    const categoryName = categories.find(x => x.organizationCategoryCd === organizationCategoryCd)?.organizationCategoryName
    //const displayPositionName = positions.find(x => x.displayPositionCd)?.displayPositionName
    const displayPositionName = positions.find(x => x.displayPositionCd === displayPositionCd)?.displayPositionName

    if (displayPositionName === this.displayPositionCdBefore) {
      return `${categoryName}${organizationName}`
    } else {
      return `${organizationName}${categoryName}`
    }
  }

  // かなに変換
  convertKana(word: string): string {
    if (!word) {
      this.wordHistory = []
    }
    else {
      this.wordHistory.push(word)
    }
    return historyKana(this.wordHistory)
    return ''
  }

  /**
   * 文字列に含まれているすべてのカタカナをひらがなに変換した文字列を作成します。
   * ただし、半角文字は変換しません。
   * @param str カタカナを含む文字列
   */
  kataToHira(str: string): string {
    if (!str) { return }
    return str.replace(/[\u30A1-\u30FA]/g, ch =>
      String.fromCharCode(ch.charCodeAt(0) - 0x60)
    )
  }
}
