export interface InvoiceMigrationContractUi {
  id: string
  contractCd: string
  merchandiseCd: string
  merchandiseName: string
  retailCd: string
  retailName: string
  businessCategoryCd: string
  businessCategoryName: string
  supplierCd: string
  contractPrice: number
  contractPriceUi: string
  contractDatetime: string
  contractDateUi: string
  contractorCompanyOrganizationCd: string
  contractorCompanyOrganizationName: string
  contractorCustomerCd: string
  contractorCustomerName: string
  contractorMailAddress: string
  contractorAccount: boolean
  contractorAccountUi: string
  invoiceSuppliCustomerId: number
  invoiceTargetCd: string
  payerCompanyOrganizationCd: string
  payerCompanyOrganizationName: string
  payerCustomerCd: string
  payerCustomerName: string
  payerMailAddress: string
  payerAccount: boolean
  payerAccountUi: string
  approvalDatetime: string
  approvalDateUi: string
  payerConfirmDatetime: string
  payerConfirmDateUi: string
  statusUi: string
  confirmStatusUi: string
  branchOffice: string
  sectionName: string
  personName: string
  postCode: string
  prefecture: string
  city: string
  blockName: string
  title: string
  telNo: string
  actionCds?: string[]
}

export interface TmpInvoiceTargetRelation {
  companyName: string
  mailAddress: string
  title: string
  personName: string
  branchOffice: string
  sectionName: string
  prefecture: string
  city: string
  blockName: string
  postCode: string
  telNo: string
  faxNo: string
  ids: string[]
}
