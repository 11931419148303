<app-customer-name-disp
  (targetSelected)="selectTargetOrganization($event)"
></app-customer-name-disp>
<app-main-contents *ngIf="selectedTargetOrg?.organizationCd">
  <app-title [title]="title"></app-title>
  <app-service-map-main
    [loading]="loading"
    [serviceMap]="serviceMap"
    (clickService)="clickServiceEvent($event)"
  >
  </app-service-map-main>
  <app-management-user-dialog
    [editable]="true"
    #userListDialog
  ></app-management-user-dialog>
</app-main-contents>
