<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-loading *ngIf="loading" [fullHeight]="true"></app-loading>
  <div class="explanation-text">
    <p>・Suppli側の請求先変更と手動で同期させるための画面</p>
    <p>・将来的な請求先変更画面は別途開発が必要</p>
    <p>
      ・売掛残の請求先情報は現在Ura-idea上で管理していないが、今後検討が必要
    </p>
  </div>
  <div class="clearfix">
    <div class="left-column">
      <ng-container
        *ngTemplateOutlet="
          searchTemplate;
          context: {
            InvoiceTarget: beforeInvoiceTarget,
            ba: 'before',
            loading: loadingBefore,
            list: beforeInvoiceList,
            title: '変更前'
          }
        "
      ></ng-container>
    </div>
    <div class="right-column">
      <ng-container
        *ngTemplateOutlet="
          searchTemplate;
          context: {
            InvoiceTarget: afterInvoiceTarget,
            ba: 'after',
            loading: loadingAfter,
            list: afterInvoiceList,
            title: '変更後'
          }
        "
      ></ng-container>
    </div>
  </div>
  <app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
</app-main-contents>

<ng-template
  #searchTemplate
  let-InvoiceTarget="InvoiceTarget"
  let-ba="ba"
  let-loading="loading"
  let-list="list"
  let-title="title"
>
  <div class="search-disp">
    <span class="search-disp-category"> {{ title }} </span>
    <span *ngIf="!InvoiceTarget">
      <app-input-text
        label="楽々明細ID"
        (changeValue)="changeValue(ba, $event)"
      ></app-input-text>
    </span>
    <span *ngIf="InvoiceTarget">
      <div class="search-disp-select">
        <p>
          {{ InvoiceTarget.suppliBillingCustomerId }}
          {{ InvoiceTarget.companyName }}
        </p>
        <p>{{ InvoiceTarget.customerName }}</p>
        <p>{{ InvoiceTarget.title }}</p>
      </div>
    </span>
    <span>
      <div *ngIf="!InvoiceTarget" class="c-btns__item search-disp-btn">
        <a
          class="js-group-close c-btn"
          style="cursor: pointer"
          (click)="getInvoiceList(ba)"
        >
          検索</a
        >
      </div>
      <div *ngIf="InvoiceTarget" class="c-btns__item search-disp-btn">
        <a
          style="cursor: pointer"
          (click)="clearInvoiceList(ba)"
          class="change-text"
          [class.link-disable]="loading"
        >
          ×変更</a
        >
      </div>
    </span>
  </div>
  <app-action-grid
    [data]="list"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [cellClasses]="cellClasses"
    (selectionChange)="selectionChange($event, ba)"
    [height]="550"
  ></app-action-grid>
</ng-template>
