import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { JoinText } from 'src/app/common/common'

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss']
})
export class SelectCustomerComponent implements OnInit {
  @Input() organizationCd = ''
  @Input() singleSelect = true // 単選択(選択後に顧客名を表示する)
  @Input() exclusionCustomerCds: string[] = [] // (既に選択済などで)除外する顧客CDs
  @Output() submit = new EventEmitter<OrganizationCustomer>() // 単選択でクリアした場合はnull
  searchKeyword = ''
  showCustomerList = false
  customerListLoading = true
  customerList: OrganizationCustomer[] = []
  customerDispList: OrganizationCustomer[] = []
  selectCustomerName = '' // 単選択の際に表示する顧客名

  constructor(
    private organizationV2Service: OrganizationV2Service,
  ) { }

  ngOnInit(): void {
    this.searchKeyword = ''
    this.getCustomerList()
  }

  // 顧客一覧検索
  getCustomerList(): void {
    if (!this.organizationCd) { return }
    this.customerListLoading = true
    this.organizationV2Service.GetOrganizationCustomerList(this.organizationCd)
      .subscribe((ret: ResultInfo<OrganizationCustomer>) => {
        if (ret?.resultCode !== 0) { return }
        // 肩書きが長いやつを優先
        ret.data.forEach(row => {
          if (!this.customerList.find(x => x.customerCd === row.customerCd)) {
            const group = ret.data.filter(x => x.customerCd === row.customerCd)
            const max = Math.max(...group.map((p) => p.organizationFullName.length))
            const maxRow = group.find(x => x.organizationFullName.length === max)
            this.customerList.push(maxRow)
          }
        })
        this.customerListLoading = false
      })
  }

  // 顧客検索結果表示
  showList(): void {
    this.searchKeyword = this.searchKeyword.replace(/[\p{C}\p{Z}]/gu, '') // スペース入れない
    let customers = this.customerList
    // 選択済の対象を除外
    this.exclusionCustomerCds.forEach(customerCd => {
      customers = customers.filter(x => x.customerCd !== customerCd)
    })
    // キーワードに一致するもの以外を除外
    if (this.searchKeyword) {
      customers = customers.filter(x =>
        x.mailAddress?.split('.')?.join('\.')?.match(this.searchKeyword.split('.').join('\.'))
        || x.familyName?.match(this.searchKeyword)
        || x.firstName?.match(this.searchKeyword)
      )
    }
    this.customerDispList = customers
    this.showCustomerList = true
  }

  // 一覧から選択した顧客をsubmit
  selectUser(customer: OrganizationCustomer): void {
    this.submit.emit(customer)
    if (this.singleSelect) {
      this.selectCustomerName = JoinText([customer.familyName, customer.firstName], ' ')
    }
  }

  clearSelect(): void {
    this.selectCustomerName = ''
    this.submit.emit(null)
  }
}
