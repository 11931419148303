<div class="l-main__contents js-fit-contents">
  <h1 class="c-texts--head1">
    {{ title }}：{{ contractCompanyName }} {{ contractCustomerName }}
  </h1>
  <app-sending *ngIf="sending"></app-sending>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    (actionCommit)="actionCommit($event)"
    [columns]="columns"
    [actionButtons]="actionButtons"
  ></app-action-grid>
</div>

<app-dialog
  *ngIf="dialogOpen"
  (commit)="cancelAction()"
  (cancel)="dialogOpen = false"
  [title]="'解約対象確認'"
  widthSize="small"
  commitButtonText="解約"
  [message]="cancelMessage"
>
  <app-search-group-items label="解約日（必須）">
    <igx-date-picker [(ngModel)]="cancelDate" displayFormat="yyyy-MM-dd" inputFormat="yyyy-MM-dd">
    </igx-date-picker>
  </app-search-group-items>
</app-dialog>
