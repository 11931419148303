<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <div style="font-size: 1.2rem; margin-top: -3rem; margin-bottom: 1rem">
    請求先の情報（会社名、住所、部署、担当者様名、連絡先など）をご確認の上、確定をお願いします。
    <br />
  </div>
  <app-action-grid
    *ngIf="showGrid"
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    minusHeight="0"
    [actionButtons]="actionButtons"
    [cellClasses]="cellClasses"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>

<app-dialog
  *ngIf="dialogOpen"
  [title]="'請求先住所更新'"
  widthSize="large"
  commitButtonText="住所更新"
  (commit)="commit()"
  (cancel)="dialogOpen = false"
>
  <app-input-table #inputTable [formItems]="formItems"> </app-input-table>
</app-dialog>
