import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router, ParamMap } from '@angular/router'
import { MerchandiseTreeUi } from 'src/api/merchandises-api/merchandises-api.interface'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { AddProductQuotationService } from '../service/add-product-quotation.service'
import { CreateQuotationService } from '../service/create-quotation.service'
import { QuotationSelectedGroup } from '../service/ura-create-quotation.interface'
@Component({
  selector: 'app-ura-create-add-quotation-merchandise',
  templateUrl: './ura-create-add-quotation-merchandise.component.html',
  styleUrls: ['./ura-create-add-quotation-merchandise.component.scss']
})
export class UraCreateAddQuotationMerchandiseComponent implements OnInit {
  title = '見積もり作成 (追加契約)'
  index: string
  session: QuotationSelectedGroup
  organizationCompanyCd: string
  contractOrganizationCd: string
  merchandisesTree: MerchandiseTreeUi[] = []
  serviceTagCd: string
  retailTagCd: string
  businessCategoryTagCd: string
  loading = false
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        const tags = this.session.businessCategoryTagList
        if (tags.length) {
          this.router.navigate([`/ura-add-product-business-category/${this.organizationCompanyCd}/${this.contractOrganizationCd}/${this.index}`])
        } else {
          this.router.navigate([`/ura-add-product-retail/${this.organizationCompanyCd}/${this.contractOrganizationCd}/${this.index}`])
        }
      },
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private addProductQuotationService: AddProductQuotationService,
    private createQuotationService: CreateQuotationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async (params: ParamMap) => {
      this.organizationCompanyCd = params.get('organizationCompanyCd')
      this.contractOrganizationCd = params.get('contractOrganizationCd')
      this.index = params.get('index')
      const check = this.addProductQuotationService.pageInit(this.organizationCompanyCd, this.contractOrganizationCd, this.index)
      if (!check) { return }

      this.session = this.addProductQuotationService.getSelectedGroupSession(this.index)

      this.serviceTagCd = this.session.service.tagCd
      this.retailTagCd = this.session.retail.tagCd
      this.businessCategoryTagCd = this.session.businessCategory?.tagCd
      if (this.session.merchandiseTree[0]) {
        this.merchandisesTree = this.session.merchandiseTree
      }
      else {
        this.loading = true
        this.merchandisesTree = await this.createQuotationService.getMerchandisesTree(
          this.serviceTagCd,
          this.retailTagCd,
          this.businessCategoryTagCd
        )
        this.loading = false
      }
    })
  }

  pageNext(): void {
    if (!this.merchandisesTree.filter(x => x.unitQuantity > 0).length) {
      this.messageService.templeteMessage(messageList.M00040)
      return
    }
    this.session.merchandiseTree = this.merchandisesTree
    this.addProductQuotationService.setSelectedGroupSession(this.session, this.index)
    this.router.navigate([`/ura-add-product-remarks/${this.organizationCompanyCd}/${this.contractOrganizationCd}`])
  }
}
