import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { lastValueFrom } from 'rxjs'
import { AccountBoxActuate } from 'src/api/actuates-api/actuates-api.interface'
import { ActuatesService } from 'src/api/actuates-api/actuates-api.service'
import { BackendOrganizationV2, OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { AboxActuatesUser, AboxActuatesUserUi, ServiceUserAuthRequest, UpdateAuthRequest } from 'src/api/service-user-api/service-user-api.interface'
import { ServiceUserService } from 'src/api/service-user-api/service-user-api.service'
import { DeepCopy, JoinText } from 'src/app/common/common'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from '../../common-parts/confirm/confirm.service'
import { Radio } from '../../common-parts/radio/radio.component.interface'
import { RowSelection, Header, SelectedRow, UpdateCheckBox } from '../../is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-management-user-dialog',
  templateUrl: './management-user-dialog.component.html',
  styleUrls: ['./management-user-dialog.component.scss']
})
export class ManagementUserDialogComponent implements OnInit {
  @Input() readonly = false
  sending = false
  rowSelection = RowSelection.MULTIPLE
  loading = true
  mainDialogOpen = false
  createDialogOpen = false
  data: AboxActuatesUserUi[] = []
  dataBackup: AboxActuatesUserUi[] = []
  updateAuthRequest: UpdateAuthRequest[] = []
  selectedRows: AboxActuatesUserUi[] = []
  columns: Header[] = [
    {
      key: 'serviceUserCd',
      label: 'serviceUserCd',
      hidden: true
    },
    {
      key: 'customerName',
      label: '名前'
    },
    {
      key: 'companyOrganizationCd',
      label: 'companyOrganizationCd',
      hidden: true
    },
    {
      key: 'companyOrganizationName',
      label: '会社',
    },
    {
      key: 'sectionOrganizationCd',
      label: 'sectionOrganizationCd',
      hidden: true
    },
    {
      key: 'sectionOrganizationName',
      label: '部署',
    },
    {
      key: 'authorityDivisitionName',
      label: '権限',
      hidden: true
    },
    {
      key: 'superUser',
      label: 'スーパーユーザー',
      dataType: this.readonly ? 'check' : 'checkBox',
      width: '20%'
    }
  ]
  actuateInfo: AccountBoxActuate
  aboxActuateCd = ''
  customerList: OrganizationCustomer[]
  selectedCustomerIndex: number
  selectedAuthCd = 'superuser'
  radio: Radio[] = [
    { value: 'superuser', label: 'スーパーユーザー' },
    { value: 'user', label: 'ユーザー' }
  ]

  constructor(
    private messageService: MessageService,
    private serviceUserService: ServiceUserService,
    private organizationV2Service: OrganizationV2Service,
    private actuatesService: ActuatesService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
  }

  async open(aboxActuateCd: string): Promise<void> {
    this.aboxActuateCd = aboxActuateCd
    this.init()
  }

  init(): void {
    this.mainDialogOpen = true
    this.createDialogOpen = false
    this.actuateInfo = null
    this.selectedRows = []
    this.getActuateInfo()
    this.getList()
  }

  get disabledAddUser(): boolean {
    if (this.loading || !this.actuateInfo) { return true }
    if (this.actuateInfo.accountBoxMaxValue === 0) { return false }
    if (this.data.length >= this.actuateInfo.accountBoxMaxValue) {
      return true
    }
    return false
  }

  get actuateName(): string {
    return JoinText([
      this.actuateInfo?.coreActuateName,
      JoinText([JoinText(this.actuateInfo?.supplierCds, '/'),
      this.actuateInfo?.coreBusinessCategoryCd])
    ], ' ')
  }

  // 稼働情報取得
  async getActuateInfo(): Promise<void> {
    // const ret = await this.actuatesService.SearchAccountBoxActuate([this.aboxActuateCd]).toPromise()
    const ret$ = this.actuatesService.SearchAccountBoxActuate([this.aboxActuateCd])
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.actuateInfo = ret.data[0]
  }

  async getList(): Promise<void> {
    this.data = []
    this.dataBackup = []
    this.updateAuthRequest = []
    this.loading = true
    // const ret = await this.serviceUserService.GetAboxActuatesUserList(this.aboxActuateCd).toPromise()
    const ret$ = this.serviceUserService.GetAboxActuatesUserList(this.aboxActuateCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.loading = false
    this.data = ret.data
    this.data.forEach(row => {
      row.superUser = row.authorityDivisionCd === 'superuser'
    })
    this.dataBackup = DeepCopy(this.data)
  }

  // 利用者追加ダイアログ表示
  showAddUserDialog(): void {
    this.customerList = []
    this.createDialogOpen = true
    this.mainDialogOpen = false
  }

  // ✔更新
  updateCheckBox(event: UpdateCheckBox<AboxActuatesUserUi>[]): void {
    event.forEach(eventData => {
      const serviceUserCd = eventData.rowData.serviceUserCd
      const backup = this.dataBackup.find(x => x.serviceUserCd === serviceUserCd)
      // 一旦除外
      this.updateAuthRequest = this.updateAuthRequest.filter(x => x.serviceUserCd !== serviceUserCd)
      if (eventData.value !== backup.superUser) {
        this.updateAuthRequest.push(
          {
            serviceUserCd,
            authorityDivisionCd: eventData.value ? 'superuser' : 'user'
          }
        )
      }
    })
  }

  // 顧客リスト取得
  async updateTargetCompany(company: BackendOrganizationV2): Promise<void> {
    if (company?.organizationCd) {
      // const ret = await this.organizationV2Service.GetOrganizationCustomerList(company.organizationCd).toPromise()
      const ret$ = this.organizationV2Service.GetOrganizationCustomerList(company.organizationCd)
      let ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) { return }
      this.customerList = []
      ret.data.forEach(data => {
        if (!this.data.find(x => x.customerCd === data.customerCd)) {
          this.customerList.push(data)
        }
      })
    }
  }

  // 権限更新確認ダイアログ
  updateAuthConfirm(): void {
    if (!this.updateAuthCheck()) {
      this.messageService.templeteMessage(messageList.M00014)
      return
    }
    this.confirmService.confirm(`本当にスーパーユーザー権限を更新しますか？`, '', () => {
      this.updateAuth()
    })
  }

  // 権限更新チェック
  // true:OK false:NG
  updateAuthCheck(): boolean {
    // 更新しようとしている内容でスーパーユーザーが最低1人残るかどうか
    const superUsers: string[] = []
    this.dataBackup.forEach(row => {
      const update = this.updateAuthRequest.find(x => x.serviceUserCd === row.serviceUserCd)
      const authCd = update ? update.authorityDivisionCd : row.authorityDivisionCd
      if (authCd === 'superuser') {
        superUsers.push(row.serviceUserCd)
      }
    })
    return superUsers.length ? true : false
  }

  // 権限更新実行
  async updateAuth(): Promise<void> {
    if (this.sending) { return }
    this.sending = true
    // const ret = await this.serviceUserService.UpdateAuth(this.updateAuthRequest).toPromise()
    const ret$ = this.serviceUserService.UpdateAuth(this.updateAuthRequest)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00050)
    this.init()
  }

  // 利用者追加実行
  async addUserCommit(): Promise<void> {
    if (this.sending) { return }
    if (!this.selectedCustomerIndex) {
      this.messageService.warning('対象者を選択してください')
      return
    }
    const customer = this.customerList[this.selectedCustomerIndex]
    const req: ServiceUserAuthRequest[] = [{
      actuateCd: this.aboxActuateCd,
      authorityDivisionCd: this.selectedAuthCd,
      customerCd: customer.customerCd,
      organizationCd: customer.organizationCd,
      stopFlg: false
    }]
    this.sending = true
    // const ret = await this.serviceUserService.AddUser(req).toPromise()
    const ret$ = this.serviceUserService.AddUser(req)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00048)
    this.init()
  }

  // 選択行情報受け取り
  selectionChange(event: SelectedRow<AboxActuatesUser>): void {
    this.selectedRows = event.selected
  }

  // 削除確認ダイアログ
  deleteConfirm(): void {
    if (!this.deleteCheck()) {
      this.messageService.templeteMessage(messageList.M00014)
      return
    }
    this.confirmService.confirm(`本当に選択中の利用者を除外しますか？`, '', () => {
      this.delete()
    })
  }

  // 削除チェック
  // true:OK false:NG
  deleteCheck(): boolean {
    // 更新しようとしている内容でスーパーユーザーが最低1人残るかどうか
    const superUsers: string[] = []
    this.dataBackup.forEach(row => {
      const deleteUser = this.selectedRows.find(x => x.serviceUserCd === row.serviceUserCd)
      if (!deleteUser && row.authorityDivisionCd === 'superuser') {
        superUsers.push(row.serviceUserCd)
      }
    })
    return superUsers.length ? true : false
  }

  // 削除実行
  async delete(): Promise<void> {
    if (this.sending) { return }
    this.sending = true
    const req: string[] = []
    this.selectedRows.forEach(row => {
      req.push(row.serviceUserCd)
    })
    const ret = await this.serviceUserService.DeleteAboxActuatesUser(req)

    this.sending = false
    if (ret?.resultCode !== 0) { return }

    this.messageService.templeteMessage(messageList.M00058)
    this.init()
  }
}

