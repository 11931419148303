<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-progress *ngIf="allLoading || pdfLoading"></app-progress>
  <app-sending *ngIf="sending"></app-sending>
  <app-contract-detail-block-address
    [lastSendDatetimeUi]="detailStatus?.lastSendDatetimeUi"
    [companyOrganizationName]="detailStatus?.companyOrganizationName"
    [sectionOrganizationName]="detailStatus?.sectionOrganizationName"
    [contractCustomerName]="detailStatus?.contractCustomerName"
    [mailAddress]="detailStatus?.mailAddress"
    [remarks]="detailStatus?.remarks"
  ></app-contract-detail-block-address>
  <!-- 新規（差分ver） -->
  <app-contract-detail-block-new
    [detailList]="detailList"
    [loading]="loading"
    (clickDownLoadButton)="downloadEstimateDocumentPdfFile()"
  >
  </app-contract-detail-block-new>
  <app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
</app-main-contents>
