<div *ngIf="block" class="c-group__panel c-group__panel--default">
  <a
    (click)="click()"
    [class]="block.classes?.join(' ')"
    class="c-panel c-panel--s"
    style="cursor: pointer"
  >
    <p class="c-texts--s">
      <b class="action-text">{{ block.actionText }}</b>
    </p></a
  >
</div>
