import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'removePeriod'
})
export class RemovePeriodPipe implements PipeTransform {
  transform(value: string): string {
    // return value.replace(/\./g, '')
    return value.replace(/\./g, '_')
  }
}
