import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import {
  ContractStatus, EstimateDocumentStatus, ContractStatusSmall
} from './frimo-migrations-api.model'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { EstimatePreviewRequest } from '../estimates-api/estimates-api.interface'
import { ServiceMapBlock } from '../service-map-api/service-map-api.interface'

@Injectable({
  providedIn: 'root'
})
export class FrimoMigrationsService {
  baseURL = environment.backendMacroBaseUrl
  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'plain/text',
    }),
    responseType: 'blob' as 'json', // We accept plain text as response.
  }
  constructor(
    private httpRequestService: HttpRequestService,
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 契約一覧取得
  async AddContractList(applicationContractStatusCds: string[] = [],
    activeOnly = true,
    companyOrganizationCd?: string): Promise<ResultInfo<ContractStatus>> {
      let url = `${environment.myideaMacroBaseUrl}api/applicationContract/status/list?activeOnly=${activeOnly}`
      if (companyOrganizationCd) {
        url = `${url}&companyOrganizationCd=${companyOrganizationCd}`
      }
    const ret$ = this.httpRequestService.post<ContractStatus>(url, applicationContractStatusCds)
    return await lastValueFrom(ret$)
  }

  /**
   * 対象会社の契約担当者取得
   * @param companyCd 会社CD
   * @returns
   */
   getContractCompanyOrganizationList(companyCd: string): Observable<ResultInfo<ContractStatusSmall>> {
    const url = `${this.baseURL}api/v4/Contracts/CompanyOrganization/${companyCd}/ContractOrganizationList`
    return this.httpRequestService.get(url)
  }

  // 契約追加プレビューサービスマップ
  getPreviewServiceMap(targetCd: string, payload: EstimatePreviewRequest): Observable<ResultInfo<ServiceMapBlock>> {
    const url = `${this.baseURL}api/v2/Estimates/ManagementPlaces/${targetCd}/GetPreviewServiceMap`
    return this.httpRequestService.post(url, payload)
  }
}
