<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-progress *ngIf="loading || pdfLoading"></app-progress>
  <app-sending *ngIf="sending"></app-sending>
  <div>
    <h2 class="c-texts--head2 h-margin--b24">
      見積書の送付先をご確認ください。
    </h2>
    <app-contract-detail-block-address
      [lastSendDatetimeUi]="detailStatus?.lastSendDatetimeUi"
      [companyOrganizationName]="detailStatus?.companyOrganizationName"
      [sectionOrganizationName]="detailStatus?.sectionOrganizationName"
      [contractCustomerName]="detailStatus?.contractCustomerName"
      [mailAddress]="detailStatus?.mailAddress"
      [remarks]="detailStatus?.remarks"
    ></app-contract-detail-block-address>
    <app-contract-detail-block-new
      [detailList]="detailList"
      [loading]="loading"
      (clickDownLoadButton)="downloadEstimateDocumentPdfFile()"
    >
    </app-contract-detail-block-new>
  </div>
  <app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
</app-main-contents>
