import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DeepCopy } from 'src/app/common/common'

@Component({
  selector: 'app-quotation-remarks',
  templateUrl: './quotation-remarks.component.html',
  styleUrls: ['./quotation-remarks.component.scss']
})
export class QuotationRemarksComponent implements OnInit {
  @Input() value = ''
  @Output() changeValue = new EventEmitter<string>()
  maxRowCount = 5
  maxRowLengthCount = 130

  dispAlert = ''
  constructor() { }

  ngOnInit(): void {
  }

  // 入力制限　138byte　5行以内
  keyup(event): void {
    this.dispAlert = ''
    if (!this.value){
      return
    }
    const lines = this.value.split('\n')
    const linesCopy = DeepCopy(lines)
    let remaksCheck = ''
    lines.forEach((x, index) => {
      const remarksLength = encodeURI(x).replace(/%[0-9A-F]{2}/g, '*').length
      if ( remarksLength > this.maxRowLengthCount + 1){
        this.dispAlert = '1行当たりの入力文字数を超えています。'
        // x =  x.substr( 0, x.length - 1)
        let i = 0
        for (i = 0; encodeURI(x).replace(/%[0-9A-F]{2}/g, '*').length > this.maxRowLengthCount + 1 ; i++){
          x = x.substr( 0, x.length - 1)
        }
      }
      if ( index < this.maxRowCount - 1 && x !== '' && index + 1 !== linesCopy.length){
        remaksCheck = remaksCheck + x + '\n'
      } else {
        remaksCheck = remaksCheck + x
      }
      if (index > this.maxRowCount ){
        this.dispAlert = `${this.maxRowCount}行以内で入力してください`
      }

    })
    this.value = remaksCheck
    this.changeValue.emit(this.value)
  }
}
