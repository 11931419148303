import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { EasyAuthentication } from 'src/api/easy-authentications-api/easy-authentications-api.interface'

@Injectable({
  providedIn: 'root'
})
export class EasyAuthenticationLoginService {
  private temporaryAccount: EasyAuthentication
  private temporaryAccountSource = new Subject<EasyAuthentication>()
  temporaryAccountSource$ = this.temporaryAccountSource.asObservable()

  private authenticationUriCd: string
  private authenticationUriCdSource = new Subject<string>()
  authenticationUriCdSource$ = this.authenticationUriCdSource.asObservable()

  get TemporaryAccount(): EasyAuthentication {
    return this.temporaryAccount
  }
  setTemporaryAccount(temporaryAccount: EasyAuthentication): void {
    this.temporaryAccountSource.next(temporaryAccount)
    this.temporaryAccount = temporaryAccount
  }

  get AuthenticationUriCd(): string {
    return this.authenticationUriCd
  }
  setAuthenticationUriCd(authenticationUriCd: string): void {
    this.authenticationUriCdSource.next(authenticationUriCd)
    this.authenticationUriCd = authenticationUriCd
  }
}
