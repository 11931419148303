import { Component, OnInit, ViewChild } from '@angular/core'
import { SendForBankHistory, SendForBankStatus } from 'src/api/invoice-leaf/invoice-leaf-api.interface'
import { InvoiceLeafApiService } from 'src/api/invoice-leaf/invoice-leaf-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { SelectOption } from 'src/app/module/common-parts/select/select.component.interface'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import moment from 'moment'
import { sortedItems } from 'src/app/common/common'

@Component({
  selector: 'app-ura-send-invoice-for-bank',
  templateUrl: './ura-send-invoice-for-bank.component.html',
  styleUrls: ['./ura-send-invoice-for-bank.component.scss']
})
export class UraSendInvoiceForBankComponent implements OnInit {
  title = '口座振替情報送信'
  periods: SelectOption[] = []
  period: string
  data: SendForBankStatus[] = []
  columns: Header[] = [
    {
      key: 'invoiceKeyNumber',
      label: '請求書No.',
      width: '9%'
    },
    {
      key: 'suppliCustomerId',
      label: '請求先ID',
      width: '8%'
    },
    {
      key: 'deadlineDateUi',
      label: '振替日',
      width: '7%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'totalBillUi',
      label: '金額',
      width: '8%',
      style: {
        textAlign: 'rigth'
      }
    },
    {
      key: 'corporateNumber',
      label: '法人番号',
      width: '9%'
    },
    {
      key: 'companyOrganizaionFomalName',
      label: '会社名',
      width: '15%'
    },
    {
      key: 'customerName',
      label: '請求担当者',
      width: '9%'
    },
    {
      key: 'paymentTypeName',
      label: '月・年払い',
      width: '10%'
    },
    {
      key: 'bankStatusName',
      label: 'ステータス',
      width: '10%'
    },
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '処理履歴確認',
      actionCd: 'history'
    },
    {
      label: 'データを送信',
      actionCd: 'send',
      confirmDialog: true
    }
  ]
  loading = true
  sending = false
  cellClasses = {
    color_green: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'bankStatusName' && rowData.bankStatusCd === 2)
    },
    color_red: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'bankStatusName' && rowData.bankStatusCd !== 2)
    },
  }
  dialogOpen = false
  detailDialogOpen = false
  history: SendForBankHistory[] = []
  historyDetail:string = null

  constructor(
    private messageService: MessageService,
    private invoiceLeafApiService: InvoiceLeafApiService
  ) { }

  ngOnInit(): void {
    this.getPeriods()
  }

  async getPeriods(): Promise<void> {
    this.periods = []
    this.loading = true
    const ret = await this.invoiceLeafApiService.GetTargetYearMonth()
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    ret.data.forEach(date => {
      var dateArray = date.split("-")
      const selectOption: SelectOption = {
        label: `${dateArray[0]}年${dateArray[1]}月`,
        value: date
      }
      this.periods.push(selectOption)
    })
    this.period = this.periods[0].value
    this.getList(this.period)
  }

  async getList(period: string): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.invoiceLeafApiService.GetSendForBankStatus(period.replace("-", ""))
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.data.forEach(item => {
      item.totalBillUi = `\\${item.totalBill.toLocaleString()}`
      item.actionCds = ['history']
      if (item.bankStatusCd !== 2) {
        item.actionCds.push('send')
      }
    })
  }

  async send(invoiceDataCds: string[]): Promise<void> {
    this.sending = true
    const ret = await this.invoiceLeafApiService.SendToBank(invoiceDataCds)
    this.sending = false
    this.getList(this.period)
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('送信処理を実行しました')
  }

  async getSendBankHistory(invoiceDataCd: string): Promise<void> {
    this.history = []
    this.loading = true
    const historyRet = await this.invoiceLeafApiService.GetSendBankHistory(invoiceDataCd)
    this.loading = false
    if (historyRet?.resultCode !== 0) { return }
    this.history = sortedItems(historyRet.data, "updatedAt", false)
    this.history.forEach(item => {
      item.updatedDatetimeUi = moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')
    })
  }

  actionCommit(event: ActionTargetInfo): void {
    if (this.sending) { return }
    if (event.actionCd === 'history') {
      if (event.rows.length > 1) {
        this.messageService.warning('1件のみ選択して下さい')
      }
      this.dialogOpen = true
      this.getSendBankHistory(event.rows[0].invoiceDataCd)
    }
    if (event.actionCd === 'send') {
      const invoiceDataCds = []
      event.rows.forEach(row => {
        invoiceDataCds.push(row.invoiceDataCd)
      })
      this.send(invoiceDataCds)
    }
  }
}
