import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ShimuraGarageMigrationsService } from 'src/api/micomel-migrations-api/micomel-migrations-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'

@Component({
  selector: 'app-micomel-migration-link',
  template: ''
})
export class ShimuraGarageMigrationLinkComponent implements OnInit {
  // companyOrganizationCd: string
  parentContractId: string
  url = '/'
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private micomelMigrationsService: ShimuraGarageMigrationsService,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  async ngOnInit(): Promise<void> {
    // routerからidを取得
    const companyOrganizationId = this.route.snapshot.params.companyOrganizationId
    const parentContractId = this.route.snapshot.params.parentContractId

    const ret2 = await this.micomelMigrationsService.getEstimateData(companyOrganizationId, parentContractId)
    if (ret2?.resultCode !== 0) {
      return
    }

    // window.open(this.url, '_blank')
    this.router.navigate([`/shimura-garage-migration-estimate/${companyOrganizationId}/${parentContractId}`])
  }
}
