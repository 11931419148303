import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { InvoiceActuate, InvoiceData, FileProf, SendForBankHistory, PaymentMethodAccount, SendForBankStatus, BankMaster, OrganizationPaymentMethodItems } from './invoice-leaf-api.interface'

@Injectable({
  providedIn: 'root'
})
export class InvoiceLeafApiService {
  baseURL = environment.invoiceLeafBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  async getActuates(customerCd: string): Promise<ResultInfo<InvoiceActuate>> {
    const url = `${this.baseURL}api/actuate/customer/${customerCd}`
    const ret$ = this.httpRequestService.get<InvoiceActuate>(url)
    return await lastValueFrom(ret$)
  }

  /**
   * 請求書インデックス取得
   * @param actuateCd 
   * @returns 
   */
  async GetInvoiceIndex(actuateCd: string): Promise<ResultInfo<FileProf>> {
    const url = `${this.baseURL}api/invoice/actuate/${actuateCd}/fileProf`
    const ret$ = this.httpRequestService.get<FileProf>(url)
    return await lastValueFrom(ret$)
  }

  /**
   * 利用明細インデックス取得
   * @param actuateCd 
   * @returns 
   */
  async GetUsageDetailIndex(actuateCd: string): Promise<ResultInfo<FileProf>> {
    const url = `${this.baseURL}api/usageDetail/actuate/${actuateCd}/fileProf`
    const ret$ = this.httpRequestService.get<FileProf>(url)
    return await lastValueFrom(ret$)
  }

  /**
   * 請求書No.から請求書ファイルプロファイルを取得
   * @param invoiceKeyNumber 
   * @returns 
   */
  async GetInvoiceProf(invoiceKeyNumber: string): Promise<ResultInfoSingle<FileProf>> {
    const url = `${this.baseURL}api/invoice/invoiceKeyNumber/${invoiceKeyNumber}/fileProf`
    const ret$ = this.httpRequestService.getResultSingle<FileProf>(url)
    return await lastValueFrom(ret$)
  }

  /**
   * 請求書No.から利用明細ファイルプロファイルを取得
   * @param invoiceKeyNumber 
   * @returns 
   */
  async GetUsageDetailProf(invoiceKeyNumber: string): Promise<ResultInfoSingle<FileProf>> {
    const url = `${this.baseURL}api/usageDetail/invoiceKeyNumber/${invoiceKeyNumber}/fileProf`
    const ret$ = this.httpRequestService.getResultSingle<FileProf>(url)
    return await lastValueFrom(ret$)
  }

  /**
   * 請求書ファイル取得URL
   * @param fileProf
   * @returns 
   */
  InvoiceFileUrl(fileProf: FileProf): string {
    return `${this.baseURL}api/invoice/${fileProf.year}/${fileProf.month}/${fileProf.actuateId}/${fileProf.fileName}`
  }

  /**
   * 利用明細ファイル取得URL
   * @param fileProf 
   * @returns 
   */
  UsageDetailFileUrl(fileProf: FileProf): string {
    return `${this.baseURL}api/usageDetail/${fileProf.year}/${fileProf.month}/${fileProf.actuateId}/${fileProf.fileName}`
  }

  async GetTargetYearMonth(): Promise<ResultInfo<string>> {
    const url = `${this.baseURL}api/invoiceData/targetYearMonth`
    const ret$ = this.httpRequestService.get<string>(url)
    return await lastValueFrom(ret$)
  }

  // 請求データ確定画面：一覧
  async GetInvoiceData(targetYearMonth: string): Promise<ResultInfo<InvoiceData>> {
    const url = `${this.baseURL}api/invoiceData/targetYearMonth/${targetYearMonth}/invoiceData`
    const ret$ = this.httpRequestService.get<InvoiceData>(url)
    return await lastValueFrom(ret$)
  }

  // 口座振替データ送信画面：一覧
  async GetSendForBankStatus(targetYearMonth: string): Promise<ResultInfo<SendForBankStatus>> {
    const url = `${this.baseURL}api/bank/targetYearMonth/${targetYearMonth}/invoiceData`
    const ret$ = this.httpRequestService.get<SendForBankStatus>(url)
    return await lastValueFrom(ret$)
  }

  // 口座振替処理履歴
  async GetSendBankHistory(invoiceDataCd: string): Promise<ResultInfo<SendForBankHistory>> {
    const url = `${this.baseURL}api/bank/invoiceDataCd/${invoiceDataCd}/history`
    const ret$ = this.httpRequestService.get<SendForBankHistory>(url)
    return await lastValueFrom(ret$)
  }

  // 請求データ確定画面：請求を確定し、お知らせメール送信
  async ConfirmAndSendMail(invoiceDataCds: string[]): Promise<ResultInfoSingle<any>> {
    const url = `${this.baseURL}api/invoiceData/confirmAndSendMail`
    const ret$ = this.httpRequestService.post<any>(url, invoiceDataCds)
    return await lastValueFrom(ret$)
  }

  // 口座振替データ送信画面：銀行にデータ送信
  async SendToBank(invoiceDataCds: string[]): Promise<ResultInfoSingle<any>> {
    const url = `${this.baseURL}api/bank/send`
    const ret$ = this.httpRequestService.post<any>(url, invoiceDataCds)
    return await lastValueFrom(ret$)
  }

  // 振替口座一覧を取得
  async GetOrganizationPaymentMethods(companyOrganizationCd: string = null): Promise<ResultInfo<PaymentMethodAccount>> {
    let url = `${this.baseURL}api/organizationPaymentMethod`
    if (companyOrganizationCd) {
      url = `${url}?organizationCd=${companyOrganizationCd}`
    }
    const ret$ = this.httpRequestService.get<any>(url)
    return await lastValueFrom(ret$)
  }
  
  GetBankMaster(): Promise<ResultInfo<BankMaster>> {
    const url = `${this.baseURL}api/bankMaster/banks`
    return lastValueFrom(this.httpRequestService.get<BankMaster>(url))
  }

  GetBankBranchMaster(bankNo: string): Promise<ResultInfo<BankMaster>> {
    const url = `${this.baseURL}api/bankMaster/banks/${bankNo}/branches`
    return lastValueFrom(this.httpRequestService.get<BankMaster>(url))
  }
  
  /** 
   * 口座情報の更新
   */
  PutOrganizationPaymentMethod(organizationPaymentMethod: any): Promise<ResultInfoSingle<PaymentMethodAccount>> {
    const url = `${this.baseURL}api/organizationPaymentMethod`
    return lastValueFrom(this.httpRequestService.putResultSingle<PaymentMethodAccount>(url, organizationPaymentMethod))
  }

  PostOrganizationPaymentMethod(organizationPaymentMethod: any): Promise<ResultInfoSingle<PaymentMethodAccount>> {
    const url = `${this.baseURL}api/organizationPaymentMethod`
    return lastValueFrom(this.httpRequestService.postResultSingle<PaymentMethodAccount>(url, organizationPaymentMethod))
  }

}