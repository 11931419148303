<div class="l-table h-margin--b48">
  <div class="l-table__item l-table__item--s">
    <div class="l-table__head">
      <p>見積送信日時</p>
    </div>
    <div class="l-table__body">
      <p>
        {{
          lastSendDatetimeUi
            ? lastSendDatetimeUi
            : "未送信"
        }}
      </p>
    </div>
  </div>

  <div class="l-table__item l-table__item--s">
    <div class="l-table__head">
      <p>見積書送付先</p>
    </div>
    <div class="l-table__body">
      <ng-container *ngIf="companyOrganizationName">
        <p>{{ companyOrganizationName }} {{ sectionOrganizationName }}</p>
        <p *ngIf="contractCustomerName" >
          {{ contractCustomerName }}
        </p>
        <p>{{ mailAddress }}</p>
      </ng-container>
    </div>
  </div>

  <div class="l-table__item l-table__item--s">
    <div class="l-table__head">
      <p>備考</p>
    </div>
    <div class="l-table__body">
      <p style="white-space: pre-wrap">{{ remarks }}</p>
    </div>
  </div>
</div>
