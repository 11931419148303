import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { ContractsV4Service } from 'src/api/contractsV4-api/contractsV4-api.service'
import { ServiceMapBlock } from 'src/api/service-map-api/service-map-api.interface'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'

@Component({
  selector: 'app-ura-cancel-page',
  templateUrl: './ura-cancel-page.component.html',
  styleUrls: ['./ura-cancel-page.component.scss']
})
export class UraCancelComponent implements OnInit {
  title = '解約対象確認'
  organizationCompanyCd: string
  contractOrganizationCd: string
  cancelContractCds: string[] = []
  cancelInfoList: ServiceMapBlock[] = []
  columns: Header[] = [
    {
      key: 'actuateCd',
      label: 'CD',
      hidden: true
    },
    {
      key: 'merchandiseName',
      label: 'プロダクト名',
    },
    {
      key: 'productInquiryCd',
      label: 'プロダクトコード',
    },
    {
      key: 'contractCompanyOrganizationName',
      label: '契約会社',
    },
  ]
  rowSelection = RowSelection.NONE
  loading = true
  inputDatedialogOpen = false
  endDatetime: string
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.pageBack()
      }
    },
    {
      label: '解約する',
      actionFunction: () => {
        this.inputDatedialogOpen = true
      }
    }
  ]
  sending = false

  constructor(
    private router: Router,
    private serviceMapViewService: ServiceMapViewService,
    private contractsV4Service: ContractsV4Service,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCompanyCd = params.get('organizationCompanyCd')
      this.contractOrganizationCd = params.get('contractOrganizationCd')
    })
    // 解約対象取得
    this.cancelContractCds = this.serviceMapViewService.getKeepData(this.contractOrganizationCd)?.cancelContractCds
    this.cancelInfoList = this.serviceMapViewService.getKeepCancelBlocks(this.contractOrganizationCd)
    this.loading = false
  }

  pageBack(): void {
    // 後でちゃんと遷移先指定したい
    history.back()
  }

  // 解約
  async cancelConect(): Promise<void> {
    if (!this.endDatetime){
      this.messageService.templeteMessage(messageList.M00029)
    } else {
      if (this.sending) { return }
      this.sending = true
      // const ret = await this.contractsV4Service
      // .ContractsCancelActuates(this.cancelContractCds, this.endDatetime).toPromise()
      const ret$ = this.contractsV4Service.ContractsCancelActuates(this.cancelContractCds, this.endDatetime)
      let ret = await lastValueFrom(ret$)

      this.sending = false
      if (ret?.resultCode !== 0) { return }
      this.serviceMapViewService.clearKeepData(this.contractOrganizationCd )
      this.messageService.templeteMessage(messageList.M00030)
      this.router.navigate([`/contract-entry-list`])
    }
  }
}
