import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { InvoiceTarget, CreateInvoiceTargetRequest } from 'src/api/invoice-api/invoice-api.interface'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { CompanyOrganization } from 'src/api/organization-company-api/organization-company-api.interface'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { AutoMap, JoinText } from 'src/app/common/common'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { InputTableComponent } from '../../common-parts/input-table/input-table.component'
import { FormInputInfo } from '../../common-parts/input-table/input-table.model'
import { Radio } from '../../common-parts/radio/radio.component.interface'
import { SelectOption } from '../../common-parts/select/select.component.interface'
import { prefectureMaster } from '../company.const'
import { MessageService } from 'src/app/components/message/message.service'

@Component({
  selector: 'app-update-invoice-target-form',
  templateUrl: './update-invoice-target-form.component.html',
  styleUrls: ['./update-invoice-target-form.component.scss']
})
export class UpdateInvoiceTargetFormComponent implements OnInit {
  @Input() selectedCd = ''
  @Output() changeTarget = new EventEmitter<CreateInvoiceTargetRequest>() // 選択した請求先
  formItems: FormInputInfo[] = [
    {
      key: 'companyName',
      label: '会社名',
      readonly: true
    },
    {
      key: 'branchOffice',
      label: '支店名',
      placeholder: '例：大阪支店',
    },
    {
      key: 'sectionName',
      label: '部署名',
      placeholder: '例：経理部',
    },
    {
      key: 'personName',
      label: '宛先氏名'
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス'
    },
    {
      key: 'telNo',
      label: '電話番号',
      valid: {
        required: true,
        numOnly: true,
        maxLength: 12
      },
      caption: 'ハイフンなし'
    },
    {
      key: 'postCode',
      label: '郵便番号',
      placeholder: '例：1112222',
      valid: {
        required: true,
        numOnly: true,
        maxLength: 7
      },
      caption: 'ハイフンなし'
    },
    {
      key: 'prefecture',
      label: '都道府県',
      placeholder: '経理部',
      type: 'select',
      master: prefectureMaster,
      valid: {
        required: true
      },
    },
    {
      key: 'city',
      label: '市区町村',
      placeholder: '例：渋谷区恵比寿',
      valid: {
        required: true,
      },
    },
    {
      key: 'blockName',
      label: '番地',
      placeholder: '例：1-1-9',
      valid: {
        required: true,
      },
    }
  ]
  formItemsReadOnly: FormInputInfo[] = [
    {
      key: 'companyName',
      label: '会社名',
      readonly: true
    },
    {
      key: 'branchOffice',
      label: '支店名',
      readonly: true
    },
    {
      key: 'sectionName',
      label: '部署名',
      readonly: true
    },
    {
      key: 'personName',
      label: '宛先氏名',
      readonly: true
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス',
      readonly: true
    },
    {
      key: 'telNo',
      label: '電話番号',
      readonly: true
    },
    {
      key: 'postCode',
      label: '郵便番号',
      readonly: true
    },
    {
      key: 'prefecture',
      label: '都道府県',
      readonly: true
    },
    {
      key: 'city',
      label: '市区町村',
      readonly: true
    },
    {
      key: 'blockName',
      label: '番地',
      readonly: true
    }
  ]

  @ViewChild('inputTable', { read: InputTableComponent, static: false })
  public inputTable: InputTableComponent
  sending = false
  contractCds: string[] = []

  inputMode = false
  selectCustomerCd = ''
  selectCustomerName = ''
  selectCustomerMailAddress = ''
  selectedCompanyDivition = '' // 自社組織CD or 他社
  selectedOrganizationCd = ''
  selectedOtherOrganizationCd = ''
  selectedOrganizationFomalName = ''
  radioOptions: Radio[] = []

  invoiceTargets: InvoiceTarget[] = [] // 会社の請求先全て
  customerInvoiceTargets: SelectOption[] = [] // 該当顧客の請求先リスト
  selectedInvoiceTarget: CreateInvoiceTargetRequest = null

  constructor(
    private invoiceService: InvoiceService,
    private organizationCompanyApiService: OrganizationCompanyApiService,
    private authService: AuthService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    const companies = this.authService.AccountInfo.organizations
    this.selectedCompanyDivition = companies[0].organizationCd
    this.selectOrganizationDivision()
    this.setInvoiceTargets()
    this.radioOptions = []
    companies.forEach(company => {
      this.radioOptions.push({
        value: company.organizationCd,
        label: `自社(${company.organizationName})`
      })
    })
    // this.radioOptions.push({ value: '', label: '他社' })
  }

  clear(): void {
    this.selectCustomerCd = ''
    this.selectCustomerName = ''
    this.selectCustomerMailAddress = ''
    this.selectedCd = ''
    this.selectedInvoiceTarget = null
    this.inputMode = false
  }

  // 自社/他社選択
  selectOrganizationDivision(): void {
    this.clear()
    if (this.selectedCompanyDivition) {
      this.selectedOrganizationCd = this.selectedCompanyDivition
      this.setInvoiceTargets()
    }
    else {
      this.selectedOrganizationCd = ''
    }
  }

  // 会社に紐づく請求先を取得
  async setInvoiceTargets(): Promise<void> {
    if (!this.selectedOrganizationCd) { return }
    const ret = await this.invoiceService.GetInvoiceTargets(this.selectedOrganizationCd)
    if (ret?.resultCode !== 0) { return }
    this.invoiceTargets = ret.data
    this.clear()
  }

  // 他社選択
  async selectOtherCompany($event: CompanyOrganization): Promise<void> {
    this.clear()
    this.selectedOrganizationCd = $event?.organizationCd
    this.setInvoiceTargets()
  }

  // 顧客選択
  async selectCustomer(customer: OrganizationCustomer): Promise<void> {
    if (!customer) {
      this.clear()
      return
    }
    // 他組織の顧客の場合、選択組織を変更
    const ret = await this.organizationCompanyApiService.GetProfileBySectionCd(customer.organizationCd)
    if (ret?.resultCode !== 0) { return }
    this.selectedOrganizationCd = ret.data.organizationCd
    if (this.radioOptions.find(x => x.value === this.selectedOrganizationCd)) {
      this.selectedCompanyDivition = this.selectedOrganizationCd
    }
    else {
      this.selectedCompanyDivition = ''
    }

    this.selectCustomerCd = customer.customerCd
    this.selectCustomerMailAddress = customer.mailAddress
    this.selectCustomerName = JoinText([customer.familyName, customer.firstName], ' ')
    this.customerInvoiceTargets = []
    this.selectedCd = ''
    this.selectedInvoiceTarget = null
    this.invoiceTargets.forEach(target => {
      if (target.customerCd === customer.customerCd) {
        const selectOption: SelectOption = {
          label: `楽楽明細ID：${target.suppliCustomerId}${target.title ? ` (${target.title})` : ''}`,
          value: target.invoiceTargetCd
        }
        this.customerInvoiceTargets.push(selectOption)
      }
    })

    if (this.customerInvoiceTargets.length === 0) {
      return
    }
    // 先頭を選択させる
    this.selectedCd = this.customerInvoiceTargets[0]?.value
    if (!this.selectedCd) {
      this.inputMode = true
      this.changeInputMode()
    } else {
      this.inputMode = false
      this.selectInvoiceTaget()
    }
  }

  async selectInvoiceTaget(): Promise<void> {
    this.inputMode = false
    const invoiceTargetRet = await this.invoiceService.searchInvoiceTargets(this.selectedCd)
    if (invoiceTargetRet?.resultCode !== 0) { return }
    this.selectedInvoiceTarget = invoiceTargetRet.data

    const companyRet = await this.organizationCompanyApiService.GetProfileBySectionCd(this.selectedInvoiceTarget.organizationCd)
    if (companyRet?.resultCode !== 0) { return }
    const organizationFormalName = companyRet.data.organizationFormalName

    const form = AutoMap<any>(this.selectedInvoiceTarget)
    form.companyName = organizationFormalName
    form.mailAddress = this.selectCustomerMailAddress

    if (form.title) {
      const titleItem = {
        key: 'title',
        label: '請求区分',
        readonly: true
      }
      this.inputTable.setForm(form, [ ...this.formItemsReadOnly, titleItem ])
    }
    else {
      this.inputTable.setForm(form, this.formItemsReadOnly)
    }
  }

  async changeInputMode(): Promise<void> {
    this.selectedCd = ''
    const ret = await this.organizationCompanyApiService.GetProfileBySectionCd(this.selectedOrganizationCd)
    if (ret?.resultCode !== 0) { return }
    const organizationFormalName = ret.data.organizationFormalName

    this.formItems.forEach(item => {
      if (item.key === 'personName' || item.key === 'mailAddress') {
        item.readonly = true
      }
    })
    const form = {
      companyName: organizationFormalName,
      personName: this.selectCustomerName,
      mailAddress: this.selectCustomerMailAddress
    }
    this.inputTable.setForm(form, this.formItems)
    this.inputMode = true
  }

  submit(): CreateInvoiceTargetRequest {
    if (this.selectedCd) {
      return this.selectedInvoiceTarget
    }
    else if (this.inputMode) {
      const submitForm = this.inputTable.submit()
      if (!submitForm) {
        return
      }
      var invoiceTarget = AutoMap<CreateInvoiceTargetRequest>(submitForm)
      invoiceTarget.customerCd = this.selectCustomerCd
      invoiceTarget.organizationCd = this.selectedOrganizationCd
      return invoiceTarget
    }
    else {
      this.messageService.warning('担当者が選択されていません')
    }
  }
}
