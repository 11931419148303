<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-loading *ngIf="loading" [fullHeight]="true"></app-loading>
  <app-search-conditions-area (search)="search()" >
    <app-input-selectbox
      label="サービス"
      [selectList]="serviceTagList"
      (selectOption)="changeValue('serviceTagCd', $event)"
    ></app-input-selectbox>
    <app-input-selectbox
      label="小売"
      [selectList]="retailTagList"
      (selectOption)="changeRetail($event)"
    ></app-input-selectbox>
    <app-search-group-items label="業態">
      <igx-combo
        class="combo"
        [data]="bussinesCategoryTagList"
        valueKey="value"
        displayKey="label"
        [(ngModel)]="values.bussinesCategoryTagCds"
      >
      </igx-combo>
    </app-search-group-items>
    <app-search-group-items label="契約会社名 *">
      <app-input-search-company
        notHitMessage=""
        (changeSelect)="changeValue('companyOrganizationCd', $event?.organizationCd)"
      ></app-input-search-company>
    </app-search-group-items>
    <app-search-group-items label="プロダクト">
      <igx-combo
        [data]="productMasterListUI"
        valueKey="chilidProductCd"
        displayKey="childProductName"
        groupKey="parentProductName"
        [(ngModel)]="values.productCds"
      >
      </igx-combo>
    </app-search-group-items>
    <app-search-group-items label="稼働状態">
      <div class="search-group-items-panel">
        <span
          *ngFor="let data of actuateStatusList"
          class="items-panel-content"
          (click)="changeSelectedActuate(data.value)"
          [class.selected]="checkSelectedActuate(data.value)"
          >{{ data.label }}</span
        >
      </div>
    </app-search-group-items>
    <app-input-product-inquiry-cd
      label="プロダクトコード"
      (changeInput)="changeProductInquiryCd($event)"
    ></app-input-product-inquiry-cd>
    <app-input-text
      label="サプライヤーコード(4桁)"
      subText="※カンマ区切りで複数選択可能"
      (changeValue)="changeValue('supplierCds', $event)"
    ></app-input-text>
    <app-search-group-items label="権限">
      <div class="search-group-items-panel">
        <span
          *ngFor="let data of authorityDivisionMaster"
          class="items-panel-content-auth"
          (click)="changeAuthority(data.authorityDivisionCd)"
          [class.selected]="checkSelectedAuthority(data.authorityDivisionCd)"
          >{{ data.authorityDivisionName }}</span
        >
      </div>
    </app-search-group-items>
  </app-search-conditions-area>

  <app-action-grid
    [data]="dataUI"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [exportExcelButton]="true"
  ></app-action-grid>
</app-main-contents>
