import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { EstimateDetailTreeRequest, MerchandiseCompositionCd } from 'src/api/estimates-api/estimates-api.interface'
import { MerchandiseTreeUi, SearchMerchandisesRequest } from 'src/api/merchandises-api/merchandises-api.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { AutoMap, DeepCopy } from 'src/app/common/common'
import { AddOptionQuotationService } from './add-option-quotation.service'
import { AddProductQuotationService } from './add-product-quotation.service'
import { NewQuotationService } from './new-quotation.service'
import { QuotationSelectedGroup } from './ura-create-quotation.interface'

/**
 * 見積もり作成用サービス
 */
@Injectable({
  providedIn: 'root',
})
export class CreateQuotationService {

  constructor(
    private newQuotationService: NewQuotationService,
    private addProductQuotationService: AddProductQuotationService,
    private addOptionQuotationService: AddOptionQuotationService,
    private merchandisesService: MerchandisesApiService
  ) { }

  /**
   * 見積もり関連のセッションを全てクリア
   */
  clearAllSessions(): void {
    this.newQuotationService.clearSession()
    this.addProductQuotationService.clearSession()
    this.addOptionQuotationService.clearSession()
  }

  /**
   * 見積もり用商品取得
   */
  async getMerchandisesTree(
    serviceTagCd: string,
    retailTagCd: string,
    businessCategoryTagCd: string): Promise<MerchandiseTreeUi[]> {
    const payload: SearchMerchandisesRequest = {
      serviceTagCd,
      retailTagCd,
      businessCategoryTagCd
    }
    const ret = await this.merchandisesService.SearchMerchandises(payload)
    if (ret?.resultCode !== 0) { return }
    const data = AutoMap<MerchandiseTreeUi[]>(ret.data)
    return this.defaultValue(data)
  }

  /**
   * 見積もり保存時に必要な項目をセットして返す
   */
  defaultValue(tree: MerchandiseTreeUi[]): MerchandiseTreeUi[] {
    // 見積もり保存時に必要な項目をSET
    const ret = DeepCopy(tree)
    ret.forEach(product => {
      product.unitQuantity = product.required ? 1 : 0
      product.merchandiseDetails?.forEach(option => {
        option.unitQuantity = option.required ? 1 : 0
        option.merchandiseDetails?.forEach(optionDetail => {
          optionDetail.unitQuantity = optionDetail.required ? 1 : 0
        })
        if (this.isRadioSelecter(option)) {
          option.merchandiseDetails[0].unitQuantity = 1
        }
      })
    })
    return ret
  }

  // 子要素選択の形式の商品かどうか
  isSelecter(merchandise: MerchandiseTreeUi): boolean {
    return merchandise?.merchandiseCompositionCd === MerchandiseCompositionCd.SELECT
  }

  // ラジオ選択形式商品かどうか
  isRadioSelecter(merchandise: MerchandiseTreeUi): boolean {
    return this.isSelecter(merchandise) && merchandise.chooseQuantity === 1
  }


  /**
   * セッションの値をAPIリクエスト用にコンバート
   * @param SelectedGroups 元の値
   * @returns 変換後の値
   */
  getMerchandisesRequest(
    SelectedGroups: QuotationSelectedGroup[]
  ): EstimateDetailTreeRequest[] {
    let ret = []
    SelectedGroups.forEach(group => {
      if (group.service?.tagCd
        && group.retail?.tagCd
        && group.merchandiseTree?.length) {
        const convert = this.convertMerchandisesForRequest(
          group.merchandiseTree,
          group.service.tagCd,
          group.retail.tagCd,
          group.businessCategory?.tagCd
        )
        ret = [...ret, ...convert]
      }
    })
    return ret
  }

  /**
   * リクエスト用にコンバートした商品階層を取得
   * @param merchandises 商品階層
   * @param serviceTagCd サービスタグCD
   * @param retailTagCd 小売タグCD
   * @param businessCategoryTagCd 業態タグCD
   * @returns 変換後の商品階層
   */
  convertMerchandisesForRequest(
    merchandises: MerchandiseTreeUi[],
    serviceTagCd: string,
    retailTagCd: string,
    businessCategoryTagCd: string
  ): EstimateDetailTreeRequest[] {
    const ret = []
    merchandises.forEach(merchandise => {
      if (merchandise.unitQuantity > 0) {
        const convert = AutoMap<EstimateDetailTreeRequest>(merchandise)
        // 子要素もコンバートする
        const childs = []
        merchandise.merchandiseDetails?.forEach(child => {
          if (child.unitQuantity > 0) {
            const convertChild = AutoMap<EstimateDetailTreeRequest>(child)
            // 孫要素もコンバートする
            const optionChilds = []
            child.merchandiseDetails?.forEach(optionChild => {
              if (optionChild.unitQuantity > 0) {
                const convertOptionChild = AutoMap<EstimateDetailTreeRequest>(optionChild)
                convertOptionChild.serviceTagCd = serviceTagCd
                convertOptionChild.retailTagCd = retailTagCd
                convertOptionChild.businessCategoryTagCd = businessCategoryTagCd
                optionChilds.push(convertOptionChild)
              }
            })
            convertChild.serviceTagCd = serviceTagCd
            convertChild.retailTagCd = retailTagCd
            convertChild.businessCategoryTagCd = businessCategoryTagCd
            convertChild.estimateMerchandises = optionChilds
            childs.push(convertChild)
          }
        })
        convert.serviceTagCd = serviceTagCd
        convert.retailTagCd = retailTagCd
        convert.businessCategoryTagCd = businessCategoryTagCd
        convert.estimateMerchandises = childs
        ret.push(convert)
      }
    })
    return ret
  }
}
