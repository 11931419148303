<div class="p-body__block p-body__block--f-footer p-control-footer">
  <div class="p-body__content p-control-footer__inner">
    <div class="p-control-footer__action p-control-footer__action--sub">
      <button
        *ngFor="let button of actionButtonsLeft()"
        [class.is-disable]="button.disabled"
        (click)="action(button)"
        class="p-control-footer__action-btn btn c-btn"
      >
        {{ button.label }}
      </button>
      <ng-container *ngIf="showGridSelecter">
        <button class="p-control-footer__action-btn btn btn--subtle">
          <span class="icon icon-check"></span>すべて選択</button
        ><button class="p-control-footer__action-btn btn btn--skeleton">
          <span class="icon icon-check"></span>すべて選択解除
        </button>
      </ng-container>
    </div>
    <div class="p-control-footer__action"></div>
    <div
      *ngFor="let button of actionButtonsRight()"
      class="p-control-footer__action"
    >
      <button
        [class.is-disable]="button.disabled"
        (click)="action(button)"
        class="p-control-footer__action-btn btn c-btn c-btn--blue"
        [class]="button.classes?.join(' ')"
      >
        {{ button.label }}
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</div>
