import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { TargetServiceBlockInfo, ServiceMapViewBlock } from 'src/app/module/myidea-parts/service-map-view.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { UserListDialogComponent } from 'src/app/module/myidea-parts/user-list-dialog/user-list-dialog.component'


@Component({
  selector: 'app-service-map',
  templateUrl: './service-map.component.html',
  styleUrls: ['./service-map.component.scss']
})
export class ServiceMapComponent implements OnInit {
  title = 'サービスマップ'
  companyOrganizationCd: string
  customerCd: string
  serviceMap: ServiceMapViewBlock[] = []
  loading = true
  alertActuateCds: string[] = []
  @ViewChild('userListDialog', { read: UserListDialogComponent, static: false })
  public userListDialog: UserListDialogComponent

  constructor(
    private serviceMapViewService: ServiceMapViewService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.companyOrganizationCd = this.authService.AccountInfo.selectedOrganizationCd
    this.customerCd = this.authService.AccountInfo.customerCd
    if (this.companyOrganizationCd) {
      this.getServiceMap()
    }
  }

  async getServiceMap(): Promise<void> {
    this.loading = true
    // サービスマップ取得
    this.serviceMap = await this.serviceMapViewService.baseServiceMapByCustomer(this.companyOrganizationCd, this.customerCd)
    // メモ編集フラグ付与
    this.serviceMap = this.serviceMapViewService.addColumnCanEditMemoView(this.serviceMap)
    this.loading = false
  }

  clickServiceEvent(value: TargetServiceBlockInfo): void {
    if (value.targetBlock.productCd === 'account_box_product' || value.targetBlock.productCd === 'products-garage_account_box_product') {
      this.userListDialog.open(value.targetBlock.actuateCd)
    }
  }
}
