import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { OrganizationContract, OrganizationContractV4MacroHierarchyRequest } from './contractsV4-api.interface'
import { HttpClient } from '@angular/common/http'
import { BackendTagMaster } from '../common/api-common.interface'

@Injectable({
  providedIn: 'root'
})
export class ContractsV4Service {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
    private httpClient: HttpClient
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 契約登録 (見積から契約データを生成)
  CreateContractEasyAuth(
    estimateDocumentCd: string,
    organizationCd: string,
    customerCd: string
  ): Observable<ResultInfo<OrganizationContract>> {
    const url = `${environment.backendMacroBaseUrl}api/v4/Contracts/EasyContract/Organizations/${organizationCd}/Customers/${customerCd}/Estimates/${estimateDocumentCd}`
    return this.httpRequestService.post(url, null)
  }

  // サービス利用状況
  public ContractsSearch(organizationCd) {
    return this.httpClient.get(
      // `${baseURL}${V2Organizations}/${organizationCd}/Contracts`
      `${this.baseURL}api/v4/Contracts/Organizations/${organizationCd}/Contracts`
    )
  }

  // サービス解約
  public ContractsCancel(end, param) {
    return this.httpClient.post(
      // `${baseURL}${V2Organizations}/${organizationCd}/Cancel?end=${end}`,
      `${this.baseURL}api/v4/Contracts/PartialCancel?endDate=${end}`, param)
  }

  public ContractsCancelActuates(contractCds: string[], endDatetime: string = null): Observable<ResultInfo<any>> {
    let url = `${environment.backendMacroBaseUrl}api/v4/Contracts/Cancel`
    if (endDatetime) {
      url = `${url}?endDatetime=${endDatetime}`
    }
    return this.httpRequestService.post(url, contractCds)
  }

  public CreateContracts(payload: OrganizationContractV4MacroHierarchyRequest[]): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/v4/Contracts/Create`
    return this.httpRequestService.post(url, payload)
  }

  public GetContractsTags(): Observable<ResultInfo<BackendTagMaster>> {
    const url = `${this.baseURL}api/v4/Contracts/Tags`
    return this.httpRequestService.get(url)
  }
}
