import { FormInputInfo } from './input-table.model'

export const passwordForms: FormInputInfo[] = [
  {
    key: 'password',
    label: '新しいパスワード',
    type: 'password',
    valid: {
      required: true,
      password: true,
      notBlank: true,
      minLength: 8,
    },
    caption: '半角英数字記号8文字以上 　【利用可能な記号】! " # $ % \' ( ) * , . / : ; < = > ? @ [ ] ^ _ ` { | } ~'
  },
  {
    key: 'passwordConfirmation',
    label: '新しいパスワード（確認用)',
    type: 'password',
    valid: {
      required: true,
    }
  },
]

export const mailAddressForm: FormInputInfo = {
  key: 'mailAddress',
  label: 'メールアドレス',
  placeholder: '例：myidea@interserve.co.jp',
  readonly: true,
  valid: {
    required: true,
    mailAddress: true
  }
}

export const oldPasswordForm: FormInputInfo = {
  key: 'oldPassword',
  label: '現在のパスワード',
  placeholder: 'Myideaにログインする際のパスワード',
  type: 'password',
  valid: {
    required: true,
  }
}


export const nickNameForm: FormInputInfo = {
  key: 'nickName',
  label: 'ログインID',
  valid: {
    required: true,
    notBlank: true,
    maxLength: 30,
  },
  caption: '30文字以内'
}

// 小文字nickname
export const nickNameForm2: FormInputInfo = {
  key: 'nickname',
  label: 'ログインID',
  valid: {
    required: true,
    notBlank: true,
    maxLength: 30,
  },
  caption: '30文字以内'
}
