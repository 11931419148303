<div class="c-edit-modal__block c-edit-modal__block--user">
  <div class="sub-text">
    参考：国税庁
  </div>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [rowClickAction]="true"
    [cellClasses]="cellClasses"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</div>
