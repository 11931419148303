<div class="l-main__contents js-fit-contents">
  <div class="l-main__contents" style="width: auto">
    <!-- contents-->
    <h2 class="c-texts--head1 c-texts--center h-pc">ログイン</h2>
    <app-progress *ngIf="sending"></app-progress>
    <div class="c-texts--center">{{ targetUserOrganizationName }} {{ targetUserName }}様</div>
    <div class="c-login__form c-login__form--mim h-margin--t48">
      <div class="c-login__input">
        <label class="c-login__label">認証コード</label>
        <input
          class="c-form__input"
          type="text"
          placeholder="認証コード"
          [(ngModel)]="authenticationCd"
        />
      </div>
      <div class="c-login__input">
        <button
          class="c-login__btn c-btn c-btn--block c-btn--blue"
          (click)="submit()"
          style="cursor: pointer"
        >
          <span>ログイン</span>
        </button>
      </div>
      <div class="error" *ngIf="loginError">ログイン認証に失敗しました</div>
    </div>
    <!-- /contents-->
  </div>
</div>
