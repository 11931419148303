import { Injectable } from '@angular/core'
import { AbstractControl, UntypedFormGroup } from '@angular/forms'
import { MessageService } from 'src/app/components/message/message.service'
import moment from 'moment'
import { messageList } from 'src/app/components/message/message-data'

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor(private messageService: MessageService) {}
  /**
    @param value - 適用したいフォーマット例：YYYY-MM-DD
  */
    momentFormatDate(strdate, format: string) {
      const date = strdate
      const m = moment(date)
      strdate = m.format(format)
      return strdate
    }

  valid(myControl: UntypedFormGroup): boolean {
    myControl.markAllAsTouched()
    if (myControl.invalid) {
      this.messageService.templeteMessage(messageList.M00002)
      return true
    }
    return false
  }

  // フォームのバリデーション(単項目)
  formError(myControl: UntypedFormGroup, key: string): boolean {
    const form = myControl.get(key)
    return form.errors && (form.dirty || form.touched)
  }

  // フォームのバリデーション(複数項目)
  formGroupError(myControl: UntypedFormGroup, keys: string[]): boolean {
    let error = false
    for (const key of keys) {
      error = this.formError(myControl, key)
      if (error) {
        break
      }
    }
    return error
  }

  // フォームのバリデーションメッセージ(単項目)
  formErrorMessage(myControl: UntypedFormGroup, key: string): string {
    const form: AbstractControl = myControl.get(key)
    if (form?.errors?.required) {
      return '必須項目です'
    }
    if (form?.errors?.notBlank) {
      return '空白文字が含まれています'
    }
    if (form?.errors?.numOnly) {
      return '半角数字で入力して下さい'
    }
    if (form?.errors?.alphanumeric) {
      return '半角英数字で入力して下さい'
    }
    if (form?.errors?.alphanumericSymbols) {
      return '半角で入力して下さい'
    }
    if (form?.errors?.password) {
      return '半角英数記号で入力し、数字とアルファベットを必ず使用してください'
    }
    if (form?.errors?.hiragana) {
      return 'ひらがなで入力して下さい'
    }
    if (form?.errors?.bankAccountName) {
      return '入力形式が異なります'
    }
    if (form?.errors?.email) {
      return '入力形式が異なります'
    }
    if (form?.errors?.mustMatch?.error) {
      return `入力された${form.errors.mustMatch.label}と異なります`
    }
    if (form?.errors?.minlength) {
      return '桁数が足りません'
    }
    if (form?.errors?.maxlength) {
      return '桁数が多すぎます'
    }
    if (form?.errors?.pattern) {
      return '入力に誤りがあります'
    }
    return ''
  }

  // フォームのバリデーション(複数項目)
  formGroupErrorMessage(myControl: UntypedFormGroup, keys: string[]): string {
    let errorMessage = ''
    for (const key of keys) {
      errorMessage = this.formErrorMessage(myControl, key)
      if (errorMessage) {
        break
      }
    }
    return errorMessage
  }
}
