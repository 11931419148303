import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { PicSendResult, SearchPicSendResultsRequest } from './pic-send-results-api.interface'

@Injectable({
  providedIn: 'root'
})
export class PicSendResultsApiService {
  baseURL = environment.backendMacroBaseUrl
  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  /**
   * 出荷Packの送信結果を取得
   * @param payload -
   * @returns 出荷Packの送信結果
   */
   SearchList(payload: SearchPicSendResultsRequest): Observable<ResultInfo<PicSendResult>> {
    const url = `${this.baseURL}api/PicSendResults/Search`
    return this.httpRequestService.post(url, payload)
  }

  /**
   * 送信内容をダウンロードのURL取得
   * @param serverGroupCd サーバーグループCD
   * @param reservationCd PK項目
   * @returns URL
   */
   DownloadTextUrl(serverGroupCd: string, reservationCd: string): string {
    const url = `${this.baseURL}api/PicSendResults/ServerGroups/${serverGroupCd}/Reservations/${reservationCd}/TextDownload`
    return url
  }

  /**
   * 再送実行
   * @param serverGroupCd サーバーグループCD
   * @param reservationCd PK項目
   * @param sendTypeCd 送信タイプ
   * @returns 正常終了：true
   */
   ReSend(serverGroupCd: string, reservationCd: string, sendTypeCd: string): Observable<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/PicSendResults/ServerGroups/${serverGroupCd}/Reservations/${reservationCd}/SendTypes/${sendTypeCd}/ReSend`
    return this.httpRequestService.delete(url, null)
  }
}
