import { Component, OnInit } from '@angular/core'
import moment from 'moment'
import { lastValueFrom } from 'rxjs'
import { PicSendResult, PicSendResultUi, SearchPicSendResultsRequest } from 'src/api/pic-send-results-api/pic-send-results-api.interface'
import { PicSendResultsApiService } from 'src/api/pic-send-results-api/pic-send-results-api.service'
import { FileManagementService } from 'src/app/common/file-management.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
@Component({
  selector: 'app-ura-monitoring-pack-send-task',
  templateUrl: './ura-monitoring-pack-send-task.component.html',
  styleUrls: ['./ura-monitoring-pack-send-task.component.scss']
})
export class UraMonitoringPackSendTaskComponent implements OnInit {
  title = '検索：出荷Pack 送信タスク結果一覧'
  data: PicSendResult[] = []
  columns: Header[] = [
    {
      key: 'reservationCd',
      label: '',
      hidden: true,
    },
    {
      key: 'sendTypeCd',
      label: '',
      hidden: true,
    },
    {
      key: 'sendTypeName',
      label: '送信タイプ',
      sort: true,
      width: '8%'
    },
    {
      key: 'serverGroupCd',
      label: '',
      hidden: true,
    },
    {
      key: 'serverGroupName',
      label: '号機',
      width: '8%',
      sort: true,
    },
    {
      key: 'bulkTaskId',
      label: 'bulk_task_id',
      width: '10%',
      sort: true,
    },
    {
      key: 'reservationId',
      label: 'reservation_id',
      width: '10%',
      sort: true,
    },
    {
      key: 'apiErrorMessage',
      label: 'エラーメッセージ',
      width: '40%',
      sort: true,
    },
    {
      key: 'createdDatetimeUi',
      label: '送信登録時間',
      sort: true,
      width: '10%',
    },
    {
      key: 'runDatetimeUi',
      label: '送信実行時間',
      sort: true,
      width: '10%',
    },
  ]
  rowSelection = RowSelection.SHINGLE
  loading = false
  sending = false
  actionButtons: GridActionButton[] = [
    {
      label: 'ダウンロード',
      actionCd: 'download'
    },
    {
      label: '再送',
      actionCd: 'resend'
    },
  ]
  startDate = new Date()

  constructor(
    private picSendResultsApiService: PicSendResultsApiService,
    private messageService: MessageService,
    private fileManagementService: FileManagementService
  ) { }

  ngOnInit(): void {
  }

  async searchList(): Promise<void> {
    if (!this.startDate) { return }
    this.data = []
    this.loading = true
    const dateString = moment(this.startDate).format('YYYY-MM-DD') + 'T00:00:00.000'
    const payload: SearchPicSendResultsRequest = {
      createdDatetime: dateString
    }
    // const ret = await this.picSendResultsApiService.SearchList(payload).toPromise()
    const ret$ = this.picSendResultsApiService.SearchList(payload)
    let ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode === 0) {
      const rows: PicSendResultUi[] = []
      ret.data.forEach(row => {
        const rowUi: PicSendResultUi = row
        rowUi.actionCds = ['download', 'resend']
        rows.push(rowUi)
      })
      this.data = rows
    }
  }

  async actionCommit(event: ActionTargetInfo): Promise<void> {
    const row = event?.rows[0]
    if (!row) { return }
    // ダウンロード
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    if (action.actionCd === 'download') {
      this.sending = true
      const url = this.picSendResultsApiService.DownloadTextUrl(row.serverGroupCd, row.reservationCd)
      this.fileManagementService.downloadTextFile(url)
    }
    // 再送
    else if (action.actionCd === 'resend') {
      this.sending = true
      // const ret = await this.picSendResultsApiService.ReSend(row.serverGroupCd, row.reservationCd, row.sendTypeCd).toPromise()
      const ret$ = this.picSendResultsApiService.ReSend(row.serverGroupCd, row.reservationCd, row.sendTypeCd)
      let ret = await lastValueFrom(ret$)

      this.sending = false
      if (ret?.resultCode === 0) {
        if (ret?.data[0]) {
          this.messageService.templeteMessage(messageList.M00046)
        }
        else {
          this.messageService.templeteMessage(messageList.M00001)
        }
      }
    }
  }
}
