import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { InputFormComponent } from 'src/app/module/common-parts/input-form/input-form.component'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'

@Component({
  selector: 'app-shimura-garage-app-setting',
  templateUrl: './shimura-garage-app-setting.component.html',
  styleUrls: ['./shimura-garage-app-setting.component.scss']
})
export class ShimuraGarageAppSettingComponent implements OnInit {
  constructor() {}
  @ViewChildren(InputFormComponent) inputForms!: QueryList<InputFormComponent>
  @ViewChild(InputTableComponent, { static: false }) private inputTable: InputTableComponent

  @Output() submit = new EventEmitter<any>()

  dialogOpen = false
  sending = false
  formItems: FormInputInfo[] = [
    {
      key: 'supplierCd',
      label: '取引先コード',
      type: 'supplierCd',
      placeholder: '例：9001',
      caption: '取引先コードは4桁の数字で入力してください。',
      valid: {
        required: true,
        notBlank: true,
        maxLength: 4,
        minLength: 4,
        numOnly: true
      }
    },
    {
      key: 'webEdiId',
      label: 'WEB-EDI ID',
      type: 'webEdiId',
      placeholder: '例：900103',
      valid: {
        required: true,
        notBlank: true,
        maxLength: 6,
        minLength: 6,
        numOnly: true
      }
    },
    {
      key: 'webEdiPassword',
      label: 'WEB-EDI パスワード',
      type: 'webEdiPassword',
      valid: {
        required: true,
        notBlank: true
      }
    }
  ]
  formValue = {
    supplierCd: '',
    webEdiId: '',
    webEdiPassword: ''
  }

  ngOnInit(): void {}

  openDialog() {
    this.dialogOpen = true
  }

  closeDialog() {
    this.dialogOpen = false
  }

  update() {
    const ret = this.inputTable.submit()
    if (ret) {
      this.submit.emit(ret)
    }
  }
}
