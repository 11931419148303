import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'


@Injectable({
  providedIn: 'root'
})
export class SuppliApiService {
  baseURL = environment.myideaMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  /**
   * 契約をSuppliへ登録
   * @param contractCds 
   * @returns 
   */
  async RegistSuppli(contractCds: string[]): Promise<ResultInfoSingle<boolean>> {
    const url = `${this.baseURL}api/suppli/contract`
    const ret$ = this.httpRequestService.postResultSingle<boolean>(url, contractCds)
    return await lastValueFrom(ret$)
  }

}