import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { InvitaitonTargetCustomer } from "src/api/invoice-api/invoice-api.interface"
import { InvoiceService } from "src/api/invoice-api/invoice-api.service"
import { OrganizationCustomer } from "src/api/organizationsV2-api/organizationV2-api.model"
import { ServiceUserAndAuth } from "src/api/service-user-api/service-user-api.interface"
import { ServiceUserService } from "src/api/service-user-api/service-user-api.service"
import { messageList } from "src/app/components/message/message-data"
import { MessageService } from "src/app/components/message/message.service"
import { ConfirmService } from "src/app/module/common-parts/confirm/confirm.service"
import { RowSelection, Header, SelectedRow } from "src/app/module/is-grid/base-grid/base-grid.component.interface"
import { InputNewCustomerComponent } from "src/app/module/myidea-parts/input-new-customer/input-new-customer.component"


@Component({
  selector: 'app-invoice-user-dialog',
  templateUrl: './invoice-user-dialog.component.html',
  styleUrls: ['./invoice-user-dialog.component.scss']
})
export class InvoiceUserDialogComponent implements OnInit {
  @Input() invoiceId = '' // 楽楽明細ID
  sending = false
  rowSelection = RowSelection.MULTIPLE
  loading = true
  mainDialogOpen = false
  addUserDialogOpen = false
  createDialogOpen = false
  data: ServiceUserAndAuth[] = []
  selectedRows: ServiceUserAndAuth[] = []
  columns: Header[] = [
    {
      key: 'serviceUserCd',
      label: 'serviceUserCd',
      hidden: true
    },
    {
      key: 'customerName',
      label: '名前'
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス'
    }
  ]
  actuateCd = ''
  superUserAuthCd = 'superuser'

  companyCd = ''
  selectCustomers: OrganizationCustomer[] = []
  registedCustomerCds: string[] = [] // 既に権限取得済の顧客
  selectedCustomerCds: string[] = [] // 選択済の顧客

  @ViewChild(InputNewCustomerComponent, { static: false })
  private inputTable: InputNewCustomerComponent

  constructor(
    private messageService: MessageService,
    private serviceUserService: ServiceUserService,
    private invoiceService: InvoiceService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
  }

  async open(aboxActuateCd: string, companyCd: string): Promise<void> {
    this.actuateCd = aboxActuateCd
    this.companyCd = companyCd
    this.init()
  }

  get exclusionCustomerCds(): string[] {
    return [...this.registedCustomerCds, ...this.selectedCustomerCds]
  }

  init(): void {
    this.mainDialogOpen = true
    this.addUserDialogOpen = false
    this.createDialogOpen = false
    this.selectedRows = []
    this.selectCustomers = []
    this.selectedCustomerCds = []
    this.getList()
  }

  async getList(): Promise<void> {
    this.data = []
    this.loading = true
    const usersRet = await this.serviceUserService.getServiceUsers(this.actuateCd)

    if (usersRet?.resultCode !== 0) { return }
    this.loading = false
    this.data = usersRet.data
    this.registedCustomerCds = []
    this.data.forEach(row => {
      row.superUser = row.authorityDivisionCd === this.superUserAuthCd
      this.registedCustomerCds.push(row.customerCd)
    })
    this.data = this.data.filter(x => x.authorityDivisionCd !== this.superUserAuthCd)
  }

  // 選択
  selectCustomer($event: OrganizationCustomer): void {
    this.selectCustomers.push($event)
    this.selectedCustomerCds.push($event.customerCd)
  }

  // 選択解除
  removeSelectCustomer(customer: OrganizationCustomer): void {
    this.selectCustomers = this.selectCustomers.filter(x => x.customerCd !== customer.customerCd)
    this.selectedCustomerCds = this.selectedCustomerCds.filter(x => x !== customer.customerCd)
  }

  // 利用者追加ダイアログ表示
  showAddUserDialog(): void {
    this.addUserDialogOpen = true
    this.createDialogOpen = false
    this.mainDialogOpen = false
  }

  // 手入力した顧客をsubmit
  async submitNewCustomer(): Promise<void> {
    const value = await this.inputTable.submit()
    if (!value) {
      return
    }
    // customerCd無し、新規
    this.selectCustomers.push(value)
    this.addUserDialogOpen = true
    this.createDialogOpen = false
  }

  // 利用者追加実行
  async addUserCommit(): Promise<void> {
    if (this.sending) { return }
    if (!this.selectCustomers.length) {
      this.messageService.warning('対象者を選択してください')
      return
    }

    const req: InvitaitonTargetCustomer[] = []
    this.selectCustomers.forEach(customer => {
      req.push({
        targetOrganizationCd: customer.organizationCd,
        targetCustomerCd: customer.customerCd ?? '',
        mailAddress: customer.mailAddress,
        familyName: customer.familyName,
        firstName: customer.firstName
      })
    })
    this.sending = true
    const addRet = await this.invoiceService.AddUserAndSendMail(this.actuateCd, req)
    this.sending = false
    if (addRet?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00063)
    this.init()
  }

  // 選択行情報受け取り
  selectionChange(event: SelectedRow<ServiceUserAndAuth>): void {
    this.selectedRows = event.selected
  }

  // 削除確認ダイアログ
  deleteConfirm(): void {
    this.confirmService.confirm(`本当に選択中のユーザーの権限を削除しますか？`, '', () => {
      this.delete()
    })
  }

  // 削除実行
  async delete(): Promise<void> {
    if (this.sending) { return }
    this.sending = true
    const req: string[] = []
    this.selectedRows.forEach(row => {
      req.push(row.serviceUserCd)
    })
    const deleteRet = await this.serviceUserService.DeleteAboxActuatesUser(req)
    this.sending = false
    if (deleteRet?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00064)
    this.init()
  }
}

