import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import { AccountInfo } from 'src/app/components/isbeauth/account'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { SelectedChildOrganizationService } from '../organization-tree-view/selected-child-organization.service'
import { messageList } from 'src/app/components/message/message-data'
declare const APP
export class SelectedChildOrganizationInfo {
  organizationCd: string
  organizationName: string
  organizationTypeCd: string
  organizationTypeName: string
  children: SelectedChildOrganizationInfo[]
  childrenCustomer: any[]
}

@Component({
  selector: 'app-organization-customer-list',
  templateUrl: './organization-customer-list.component.html'
})
export class OrganizationCustomerListComponent implements OnInit {
  @Input() companyOrganizationCd: string
  @Input() targetOrganizationCd: string
  @Input() selectedOrganizationName: string
  @Output() jumpTreePage = new EventEmitter<void>()
  sending = false

  constructor(
    private customerService: CustomerService,
    private authService: AuthService,
    private router: Router,
    private selectedChildOrganizationService: SelectedChildOrganizationService,
    public snackBar: MatSnackBar,
    private messageService: MessageService,
    private organizationV2Service: OrganizationV2Service,
  ) { }
  userList: any[]
  customers: any[]
  userinfo: any
  organizationName: string
  organizationCd: string
  authGroupdata: any[] // 権限マスタ(管理者、一般のそれぞれの機能別の使用可・不可のセット)
  authtype: ''
  registeredAuthtype: ''
  personalAuthorities: any[] // 個人設定
  // authoritiesSettings: any[]; // 画面設定
  displist = false

  public selectedCustomerCd: string
  public authmenu: any[]
  public personalauth: any
  public subscription: Subscription
  public EditDisabled: boolean
  public canClose: boolean
  public selectedChildOrganization: SelectedChildOrganizationInfo
  public isChildOrganizationList = false
  public isCompanyMembersListMode = false

  // loadingアニメーション用
  loading: boolean
  afterload: boolean
  nodata: boolean
  users: AccountInfo
  files: any[] = []

  ngOnInit(): void {
    APP.controller.reset()
    // // 組織選択リストの表示状態
    // this.displist = false;

    this.canClose = true
    this.users = this.authService.AccountInfo

    // 組織図から遷移してきた場合
    this.selectedChildOrganization = this.selectedChildOrganizationService.getSelectedChildOrganization()
    if (this.selectedChildOrganization) {
      if (this.targetOrganizationCd !== '-1') {
        // 配属メンバー一覧表示
        this.isChildOrganizationList = true
      }
      else {
        this.targetOrganizationCd = this.companyOrganizationCd
        this.isChildOrganizationList = false
      }
      // 変数に保持したらサービスから消す
      const org = new SelectedChildOrganizationInfo()
      org.organizationCd = ''
      org.organizationName = ''
      org.organizationTypeCd = ''
      org.organizationTypeName = ''
      org.children = []
      this.selectedChildOrganizationService.setSelectedChildOrganization(org)
    }

    // ユーザー一覧
    if (this.users) {
      this.getUserList(this.targetOrganizationCd)
    }
  }

  ngAfterViewInit() {
    if (this.customers && !this.afterload) {
      this.afterload = true
      APP.controller.reset()
    }
  }
  async getUserList(organizationCd) {
    this.loading = true
    this.nodata = false

    // 組織図から来た場合で所属メンバー表示の場合
    if (this.isChildOrganizationList === true &&
      this.targetOrganizationCd === this.users.selectedOrganizationCd) {
      this.isCompanyMembersListMode = true
      this.organizationV2Service
        .CompanyMembersAndRelatedOrganizations(organizationCd)
        .subscribe((ret: any) => {
          this.userList = ret.data
          this.organizationCd = ret.data[0].organizationCd
          this.organizationName = ret.data[0].organizationName
          // this.customers = this.userList[0].customers;
          this.customers = []
          this.userList.forEach(customer => {
            const relatedOrgs = []
            let relatedOrgsFlat = ''
            if (customer.organizations !== null) {
              customer.organizations.forEach(org => {
                const tmporg = {
                  ChildOrganizationCd: org.organizationCd,
                  ChildOrganizationName: org.organizationName,
                  ChildOrganizationTypeCd: org.organizationTypeCd,
                  ChildOrganizationTypeName: org.organizationTypeName
                }
                relatedOrgs.push(tmporg)
                if (relatedOrgsFlat.length > 0) {
                  relatedOrgsFlat = relatedOrgsFlat + ',' + org.organizationName
                }
                else {
                  relatedOrgsFlat = org.organizationName
                }
              })
            }
            const tmpCustomer = {
              customerCd: customer.customerCd,
              familyName: customer.familyName,
              firstName: customer.firstName,
              mailAddress: customer.mailAddress,
              organizationsFlat: relatedOrgsFlat,
              organization: relatedOrgs
            }
            this.customers.push(tmpCustomer)
          })

          this.loading = false
          if (this.userList.length === 0) {
            this.nodata = true
          }
        })
    }
    // 通常のユーザー一覧
    else {
      const ret = await this.customerService.SearchByOrganizationCds([organizationCd])
      this.userList = ret.data
      const first = ret.data[0]
      this.organizationCd = first.organizationCd
      this.organizationName = first.organizationFomalName
      this.customers = first.customers
      this.loading = false
      if (this.userList.length === 0) {
        this.nodata = true
      }
    }
  }

  nextAction(data) {
    APP.controller.reset()
    this.userinfo = data
    return
  }

  async submit(): Promise<void> {
    this.sending = true
    const ret = await this.customerService.Update(this.userinfo)
    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00050)
  }

  // 組織図に戻る
  backToChart(): void {
    this.selectedChildOrganizationService.setSelectedChildOrganization(this.selectedChildOrganization)
    this.jumpTreePage.emit()
    this.router.navigate(['/ura-organization-tree-view'])
  }
}
