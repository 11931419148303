import { RetailTagList, AuthorityDivision } from 'src/api/conta-api/conta-api.interface'

// 0:未稼働、1:稼働中、2:解約済
export const actuateData: any[] = [
    {
        label: '未稼働',
        value: '0'
    },
    {
        label: '稼働中',
        value: '1'
    },
    {
        label: '解約済',
        value: '2'
    }
]

export const retailListMaster: RetailTagList[] = [
    {
        retailTagCd: 'shimamura_retail',
        retailTagText: 'しまむら',
        businessCategoryTags: [
            {
                tagCd: 'shimamura',
                tagText: 'しまむら'
            },
            {
                tagCd: 'avail',
                tagText: 'アベイル'
            },
            {
                tagCd: 'birthday',
                tagText: 'バースデイ'
            },
            {
                tagCd: 'chambre',
                tagText: 'シャンブル'
            },
            {
                tagCd: 'divalo',
                tagText: 'ディバロ'
            },
            {
                tagCd: 'shimura',
                tagText: '思夢楽'
            }
        ]
    }
]

export const authorityDivisionMaster: AuthorityDivision[] = [
    {
        authorityDivisionCd: 'contractor',
        authorityDivisionName: '契約担当'
    },
    {
        authorityDivisionCd: 'invoice',
        authorityDivisionName: '請求書宛先'
    },
    {
        authorityDivisionCd: 'superuser',
        authorityDivisionName: 'スーパーユーザー'
    },
    {
        authorityDivisionCd: 'user',
        authorityDivisionName: 'ユーザー'
    }
]
