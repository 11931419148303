import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MerchandiseTreeUi } from 'src/api/merchandises-api/merchandises-api.interface'
import { DeepCopy } from 'src/app/common/common'
import { CreateQuotationService } from 'src/app/routing-content/uraidea-v2/service/create-quotation.service'


@Component({
  selector: 'app-quotation-merchandise-selecter',
  templateUrl: './quotation-merchandise-selecter.component.html',
  styleUrls: ['./quotation-merchandise-selecter.component.scss']
})
export class QuotationMerchandiseSelecterComponent implements OnInit {
  @Input() merchandisesTree: MerchandiseTreeUi[] = []
  @Input() serviceTagCd: string
  @Input() retailTagCd: string
  @Input() businessCategoryTagCd: string
  @Output() changeValue = new EventEmitter<MerchandiseTreeUi[]>()

  constructor(
    private createQuotationService: CreateQuotationService
  ) { }

  ngOnInit(): void { }

  isDisabledCheckBox(
    thisMerchandise: MerchandiseTreeUi,
    parentMerchandise?: MerchandiseTreeUi
  ): boolean {
    if (thisMerchandise.required) { return true }
    // オプション子階層の場合
    if (parentMerchandise) {
      // ラジオボタンの場合
      if (this.isRadioSelecter(parentMerchandise)) {
        // 選択済の要素をクリックした場合はNG
        if (thisMerchandise.unitQuantity > 0) { return true }
      }
    }
    return false
  }

  // プロダクト数量追加ボタン表示
  isShowAddProductButton(
    index: number
  ): boolean {
    const thisMerchandise = this.merchandisesTree[index]
    if (!thisMerchandise.multiple || thisMerchandise.unitQuantity === 0) { return false }
    const merchandiseCds = []
    this.merchandisesTree.forEach(tree => {
      merchandiseCds.push(tree.merchandiseCd)
    })
    // 同じ商品の中で一番最後の要素だった場合はtrue
    const lastSameMechandiseIndex = merchandiseCds.lastIndexOf(thisMerchandise.merchandiseCd)
    return lastSameMechandiseIndex === index ? true : false
  }

  // プロダクト削除ボタン表示
  isShowRemoveProductButton(
    index: number
  ): boolean {
    const thisMerchandise = this.merchandisesTree[index]
    if (!thisMerchandise.multiple) { return false }
    if (this.merchandisesTree.filter(x => x.merchandiseCd === thisMerchandise.merchandiseCd).length === 1) { return false }
    const merchandiseCds = []
    this.merchandisesTree.forEach(tree => {
      merchandiseCds.push(tree.merchandiseCd)
    })
    // 同じ商品の中で一番最後の要素ではない場合はtrue
    const lastSameMechandiseIndex = merchandiseCds.lastIndexOf(thisMerchandise.merchandiseCd)
    return lastSameMechandiseIndex === index ? false : true
  }

  // プロダクト数量追加ボタン押下
  AddProduct(
    thisMerchandise: MerchandiseTreeUi
  ): void {
    const defaultValue = this.createQuotationService.defaultValue([thisMerchandise])
    this.merchandisesTree.push(defaultValue[0])
  }

  // プロダクト削除ボタン押下
  RemoveProduct(
    index: number
  ): void {
    const ret = []
    for (let i = 0; i < this.merchandisesTree.length; i++) {
      if (i !== index) {
        ret.push(this.merchandisesTree[i])
      }
    }
    this.merchandisesTree = ret
  }

  // 子要素選択の形式の商品かどうか
  isSelecter(merchandise: MerchandiseTreeUi): boolean {
    return this.createQuotationService.isSelecter(merchandise)
  }
  // ラジオ選択形式商品かどうか
  isRadioSelecter(merchandise: MerchandiseTreeUi): boolean {
    return this.createQuotationService.isRadioSelecter(merchandise)
  }

  // オプションに対する説明文言
  optionTextsAttention(merchandise: MerchandiseTreeUi): string {
    // 選択
    if (this.isSelecter(merchandise)) {
      const total = this.getChildTotalQuantity(merchandise)
      return `${total}/${merchandise.chooseQuantity * merchandise.unitQuantity}件選択中`
    }
    return ''
  }

  // 子要素の総数量
  getChildTotalQuantity(merchandise: MerchandiseTreeUi): number {
    let totalQuantity = 0
    merchandise.merchandiseDetails.forEach((detail) => {
      totalQuantity += detail.unitQuantity
    })
    return totalQuantity
  }

  // 選択ON/OFF切り替え
  changeSelected(
    thisMerchandise: MerchandiseTreeUi,
    parentMerchandise?: MerchandiseTreeUi
  ): void {
    const addQuantity = thisMerchandise.unitQuantity ? -thisMerchandise.unitQuantity : 1
    this.changeUnitQuantity(addQuantity, thisMerchandise, parentMerchandise)
  }

  // 数量変更
  changeUnitQuantity(
    addQuantity: number,
    thisMerchandise: MerchandiseTreeUi,
    parentMerchandise?: MerchandiseTreeUi,
  ): void {
    const afterQuantity = thisMerchandise.unitQuantity + addQuantity
    // 必須商品を0以下にしようとした場合はスルー
    if (thisMerchandise.required && afterQuantity <= 0) {
      return
    }
    // オプション子階層の場合
    if (parentMerchandise) {
      // ラジオボタンの場合
      if (this.isRadioSelecter(parentMerchandise)) {
        // 選択済の要素をクリックした場合はスルー
        if (!afterQuantity) { return }

        parentMerchandise.merchandiseDetails.forEach(child => {
          if (child.merchandiseCd === thisMerchandise.merchandiseCd) {
            child.unitQuantity = afterQuantity
          }
          else {
            child.unitQuantity = 0
          }
        })
      }
      // 複数選択の場合
      if (this.isSelecter(parentMerchandise)
        && parentMerchandise.multiple) {
        parentMerchandise.merchandiseDetails.forEach(child => {
          if (child.merchandiseCd === thisMerchandise.merchandiseCd) {
            child.unitQuantity = afterQuantity
          }
        })
      }
      else {
        thisMerchandise.unitQuantity = afterQuantity
      }
    }
    else {
      thisMerchandise.unitQuantity = afterQuantity
    }
    this.changeValue.emit(this.merchandisesTree)
  }
}
