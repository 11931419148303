<app-main-contents *ngIf="showLoginForm">
  <h2 class="c-texts--head1 c-texts--center h-pc">ログイン</h2>
  <div class="c-login__form c-login__form--mim h-margin--t48">
    <div class="c-login__input">
      <label class="c-login__label">認証コード</label>
      <input
        class="c-form__input"
        type="text"
        placeholder="認証コード"
        #input
      />
    </div>
    <div class="c-login__input">
      <button
        class="c-login__btn c-btn c-btn--block c-btn--blue"
        (click)="submit(input.value)"
        style="cursor: pointer"
      >
        <span>ログイン</span>
      </button>
    </div>
  </div>
  <div class="error" *ngIf="loginError">{{errorMessage1}}<br>{{errorMessage2}}</div>
</app-main-contents>
