import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { OrganizationType } from './organization-type.model'
import { HorizontalAlignment, IgxDialogComponent, PositionSettings, VerticalAlignment } from '@infragistics/igniteui-angular'
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms'
import { AccountInfo } from 'src/app/components/isbeauth/account'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { CustomerCheckRequest, CustomerCheckResponse, OrganizationRelationCustomerCheck } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { messageList } from 'src/app/components/message/message-data'
import { lastValueFrom } from 'rxjs'

declare const APP
@Component({
  selector: 'app-organization-tree-edit',
  templateUrl: './organization-tree-edit.component.html',
  styleUrls: ['./organization-tree-edit.component.scss']
})
export class OrganizationTreeEditComponent implements OnInit {

  constructor(
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private organizationV2Service: OrganizationV2Service,
    private customerService: CustomerService,
    private fb: UntypedFormBuilder,
  ) { }
  @Input() companyOrganizationCd: string
  @Input() selectOrganizationCd: string
  @Output() jumpTreeViewPage = new EventEmitter<void>()
  public users: AccountInfo
  public message = false
  public sending = false

  // 組織リストボックス選択用（2階層目の組織）
  public branchlist: any[]
  public selectedBranch: any
  public dummyBranchlist: any

  // 組織図上の選択組織（3階層目以下の組織）
  public selectedSection: any

  public parentSection: any
  public newParentSection: any
  public inputOrganizationName = ''
  public deleteOrganizationName = ''
  public moveOrganizationName = ''

  public inputOrganizationType: string
  public organizationTypeList: OrganizationType[]

  // 配属・参加メンバーリスト
  public memberList: any[] = []
  public selectedMembers: any[] = []
  public companyMembersList: any[] = []
  public parentCompanyCd = ''
  public parentCompanyName = ''
  public selectedCompanyMembers: string[] = []
  public newSection: any[] = []
  public invitationHistory: any[] = []

  // ダイアログ表示位置設定
  public positionSettings: PositionSettings = {
    horizontalDirection: HorizontalAlignment.Center,
    verticalDirection: VerticalAlignment.Middle
    // verticalStartPoint: VerticalAlignment.Middle
  }

  // ツールチップ制御用
  public displist: boolean

  // loadingアニメーション用
  loading: boolean
  afterload: boolean

  // 処理メッセージ
  alertmessage = ''
  movemessage = ''
  checkmessage = ''

  // 組織移動モード
  movemode: boolean

  // 組織図表示切り替え用
  isShowCustomers = true
  // 組織チェック結果 (下位階層に、配属ユーザがいるか)
  checkCustomerExist: OrganizationRelationCustomerCheck[] = []

  @ViewChild('addOrganizationDialog', { read: IgxDialogComponent, static: false })
  public addOrganizationDialog: IgxDialogComponent
  @ViewChild('editOrganizationNameDialog', { read: IgxDialogComponent, static: false })
  public editOrganizationNameDialog: IgxDialogComponent
  @ViewChild('deleteOrganizationDialog', { read: IgxDialogComponent, static: false })
  public deleteOrganizationDialog: IgxDialogComponent
  @ViewChild('memberListDialog', { read: IgxDialogComponent, static: false })
  public memberListDialog: IgxDialogComponent
  @ViewChild('sectionListDialog', { read: IgxDialogComponent, static: false })
  public sectionListDialog: IgxDialogComponent
  @ViewChild('companyMembersListDialog', { read: IgxDialogComponent, static: false })
  public companyMembersListDialog: IgxDialogComponent
  ngOnInit(): void {

    this.movemode = false

    // 組織図ツリーデータ取得
    this.getOrganizationTreeData(this.companyOrganizationCd, this.selectOrganizationCd)

    // 組織種別マスタ取得
    this.getOrganizationTypeList()
  }
  ngAfterViewInit() {
    if (!this.afterload) {
      this.afterload = true
      APP.controller.reset()
    }
  }
  // 組織図ツリーデータ取得
  getOrganizationTreeData(organizationCd, branchCd) {
    this.loading = true
    // 組織図＋メンバーのAPIに切り替え（2021/3/4時点でメンバー部分はスケルトン）
    this.organizationV2Service.OrganizationTreesWithCustomers(organizationCd).subscribe((ret: any) => {
      if (ret?.resultCode === 0) {
        const retdata = ret.data[0]
        // 最上位組織に子組織がない場合nullになっているので[]をセット
        if (retdata.children === null) {
          retdata.children = []
        }
        this.selectedBranch = ret.data[0]

        // 組織が1件もない場合
        if (this.selectedBranch.children.length === 0) {
          this.selectedSection = JSON.parse(JSON.stringify(this.selectedBranch))
          this.selectedSection.organizationCd = ''
          this.selectedSection.organizationName = ''
          this.selectedSection.organizationTypeCd = ''
          this.selectedSection.organizationTypeName = ''
        } else {
          this.selectedSection = this.selectedBranch.children[0]
        }
        this.loading = false
      }
    })
  }
  // 組織のチェック(下位階層に、配属ユーザがいるか)
  async canRemoveOrganization(organizationCd: string): Promise<boolean> {
    // const ret = await this.organizationV2Service.CheckOrganizationCustomerExist([organizationCd]).toPromise()
    const ret$ = this.organizationV2Service.CheckOrganizationCustomerExist([organizationCd])
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    const exist = ret.data.filter(x => x.exists === true)
    if (exist.length) { return false }
    return true
  }
  // 追加ダイアログオープン
  openAddOrganizationDialog(parentSection): void {
    this.parentSection = parentSection
    this.inputOrganizationName = ''
    this.addOrganizationDialog.open()
  }
  // 追加ダイアログオープン 表示判定用
  checkAddOrganizationDialog(parentSection): boolean {
    if (parentSection.organizationTypeCd === 'organization_type-role'){
      return false
    } else {
      return true
    }
  }
  // 組織追加処理
  async addOrganization(): Promise<void> {
    if (this.loading) { return }
    if (!this.inputOrganizationName || !this.inputOrganizationType) {
      this.messageService.templeteMessage(messageList.M00005)
      return
    }
    // 移動先チェック1（WGの下）
    // if (this.parentSection.organizationTypeCd === 'organization_type-work_group' && (this.selectedSection.organizationTypeCd === 'organization_type-section')) {
    if (this.parentSection.organizationTypeCd === 'organization_type-work_group' && (this.inputOrganizationType === 'organization_type-section')) {
      this.messageService.templeteMessage(messageList.M00006)
      return
    }
    // 移動先チェック2（役職の下）
    if (this.parentSection.organizationTypeCd === 'organization_type-role') {
      this.messageService.templeteMessage(messageList.M00007)
      return
    }

    const pre = {
      organizationName: this.inputOrganizationName,
      organizationTypeCd: this.inputOrganizationType
    }
    this.sending = true
    // const ret = await this.organizationV2Service.OrganizationTreesChild(this.parentSection.organizationCd, pre).toPromise()
    const ret$ = this.organizationV2Service.OrganizationTreesChild(this.parentSection.organizationCd, pre)
    const ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00008)
    APP.controller.reset()
    this.getOrganizationTreeData(this.companyOrganizationCd, this.selectedBranch.organizationCd)
    this.addOrganizationDialog.close()
    this.displist = false
  }
  // 名前変更ダイアログオープン
  openEditOrganizationNameDialog(): void {
    this.inputOrganizationName = this.selectedSection.organizationName
    this.inputOrganizationType = this.selectedSection.organizationTypeCd
    this.editOrganizationNameDialog.open()
  }

  // 組織名変更処理
  async updateOrganizationName(): Promise<void> {
    if (this.loading) { return }
    const selectOrg = this.selectedSection
    if (!this.inputOrganizationName || !this.inputOrganizationType) {
      this.messageService.templeteMessage(messageList.M00005)
      return
    }
    if (selectOrg.organizationTypeCd !== this.inputOrganizationType
      &&
      (selectOrg.organizationTypeCd === 'organization_type-role'
        || this.inputOrganizationType === 'organization_type-role')) {
      const canRemove = await this.canRemoveOrganization(selectOrg.organizationCd)
      if (!canRemove) {
        if (selectOrg.organizationTypeCd === 'organization_type-role') {
          this.messageService.templeteMessage(messageList.M00009)
        }
        else {
          this.messageService.templeteMessage(messageList.M00009)
        }
        return
      }
    }

    const pre = {
      organizationName: this.inputOrganizationName,
      organizationTypeCd: this.inputOrganizationType
    }
    this.loading = true
    // const ret = await this.organizationV2Service.PutOrganizationTrees(selectOrg.organizationCd, pre).toPromise()
    const ret$ = this.organizationV2Service.PutOrganizationTrees(selectOrg.organizationCd, pre)
    const ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00010)
    APP.controller.reset()
    this.editOrganizationNameDialog.close()
    this.displist = false
    // 組織図ツリーデータ取得
    this.getOrganizationTreeData(this.companyOrganizationCd, this.selectedSection.organizationCd)
  }
  // 削除ダイアログオープン
  async oepnDeleteOrganization(parentItem, thisItem): Promise<void> {
    const canRemove = await this.canRemoveOrganization(thisItem.organizationCd)
    if (!canRemove) {
      this.messageService.templeteMessage(messageList.M00009)
      return
    }

    this.deleteOrganizationName = this.selectedSection.organizationName
    this.parentSection = parentItem
    this.deleteOrganizationDialog.open()
  }
  // 組織削除処理
  async deleteOrganization(): Promise<void> {
    if (this.loading) { return }
    this.loading = true
    // const ret = await this.organizationV2Service.DeleteChildOrganization(
    //   this.parentSection.organizationCd,
    //   this.selectedSection.organizationCd).toPromise()
    const ret$ = this.organizationV2Service.DeleteChildOrganization(
        this.parentSection.organizationCd,
        this.selectedSection.organizationCd)
    const ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00011)
    APP.controller.reset()
    // 組織図ツリーデータ取得
    this.getOrganizationTreeData(this.companyOrganizationCd, this.selectedSection.organizationCd)
    this.deleteOrganizationDialog.close()
    this.displist = false
  }

  // 組織移動モード
  async moveOrganization(parentItem, thisItem): Promise<void> {
    const canRemove = await this.canRemoveOrganization(thisItem.organizationCd)
    if (!canRemove) {
      this.messageService.templeteMessage(messageList.M00012)
      this.movemode = false
      return
    }
    this.movemessage = this.selectedSection.organizationName + 'の移動先を選択してください'
    this.parentSection = parentItem
    this.movemode = true
    this.displist = false
  }
  // 移動先チェック
  checkDestination(childItem): boolean {
    let ret = true
    // 移動先が自分の子孫組織の場合は移動不可
    if (childItem.findIndex(x => x.organizationCd === this.newParentSection.organizationCd) !== -1) {
      ret = false
      return ret
    }
    childItem.forEach(child => {
      if (child.children.length !== 0) {
        if (!this.checkDestination(child.children)) {
          ret = false
          return ret
        }
      }
    })

    return ret
  }
  // 組織移動処理
  async selectDestination(destinationItem): Promise<void> {
    this.newParentSection = destinationItem
    let warn = false
    // 移動先チェック1（移動先が現在と同じ）
    if (this.parentSection.organizationCd === this.newParentSection.organizationCd) {
      warn = true
      this.movemessage = '現在の親組織と同じ組織が指定されています'
    }
    // 移動先チェック2（移動先が自分自身）
    else if (this.selectedSection.organizationCd === this.newParentSection.organizationCd) {
      this.movemessage = '選択した組織自体を移動先に指定できません'
    }
    // 移動先チェック3（移動先が自分の子孫組織）
    else if (this.selectedSection.children.length !== 0 && !this.checkDestination(this.selectedSection.children)) {
      warn = true
      this.movemessage = '移動先に子組織は指定できません'
    }
    // 移動先チェック4（WGの下）
    else if (this.newParentSection.organizationTypeCd === 'organization_type-work_group' && (this.selectedSection.organizationTypeCd === 'organization_type-section')) {
      warn = true
      this.movemessage = 'WGの下には部署を作成できません'
    }
    // 移動先チェック5（役職の下）
    else if (this.newParentSection.organizationTypeCd === 'organization_type-role') {
      warn = true
      this.movemessage = '役割の下には組織を作成できません'
    }
    if (warn) {
      setTimeout(() => {
        this.movemessage = this.selectedSection.organizationName + 'の移動先を選択してください'
      }, 2000)
      return
    }
    this.movemode = false
    // 紐づけ変更
    // const ret = await this.organizationV2Service.PostChildOrganization(
    //   this.newParentSection.organizationCd,
    //   this.selectedSection.organizationCd, []).toPromise()
    const ret$ = this.organizationV2Service.PostChildOrganization(
      this.newParentSection.organizationCd,
      this.selectedSection.organizationCd, [])
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00013)
    APP.controller.reset()
    // 組織図ツリーデータ取得
    this.getOrganizationTreeData(this.companyOrganizationCd, this.selectedBranch.organizationCd)
    this.displist = false
  }

  // 組織クリック=>ツールチップオープン
  clickOrgBox(targetOrg) {
    this.selectedSection = targetOrg
    this.displist = true
  }
  // ツールチップクローズ
  closeToolTip(event) {
    if (event.target.className !== 'c-panel c-panel--s js-tool-panel-trigger' &&
      event.target.parentElement.parentElement.className !== 'c-panel c-panel--s js-tool-panel-trigger'
    ) {
      if (this.displist) { this.displist = false }
    }
  }
  // 組織種別マスタ取得
  getOrganizationTypeList() {
    this.organizationV2Service.GetOrganizationTypeList().subscribe((ret: any) => {
      if (ret?.resultCode === 0) {
        let tmpList = ret.data
        // 会社と支社は除く
        tmpList = tmpList.filter(x => x.organizationTypeName !== '会社')
        tmpList = tmpList.filter(x => x.organizationTypeName !== '支社')
        // 表示色設定（本当はDBに持たせたい。暫定）
        tmpList.forEach(element => {
          // 部署
          if (element.organizationTypeCd === 'organization_type-section') {
            element.organizationTypeColor = '#E1F5FE'
          }
          // ワークグループ
          if (element.organizationTypeCd === 'organization_type-work_group') {
            element.organizationTypeColor = '#F1F8E9'
          }
          // ロール
          if (element.organizationTypeCd === 'organization_type-role') {
            element.organizationTypeColor = '#ffebee'
          }
        })
        this.organizationTypeList = tmpList
      }
    }, error => {
      console.log(error.message)
    })
  }
  getTypeColor(org): string {
    if (org === undefined) { return }
    // 会社
    if (org.organizationTypeCd === 'organization_type-company') {
      return '#E0F2F1'// '#E3F2FD'
    }
    // 部署
    if (org.organizationTypeCd === 'organization_type-section') {
      return '#e3f8fd'// '#E0F7FA'
    }
    // ワークグループ
    if (org.organizationTypeCd === 'organization_type-work_group') {
      return '#f0fff0'
    }
    // ロール
    if (org.organizationTypeCd === 'organization_type-role') {
      return '#fce6fa' // '#F3E5F5'
    }
  }
  getTypeHeight(org): string {
    if (org === undefined) { return }
    // 会社
    if (org.organizationTypeCd === 'organization_type-company') {
      return '58px'
    }
    // 部署
    if (org.organizationTypeCd === 'organization_type-section') {
      return '58px'
    }
    // ワークグループ
    if (org.organizationTypeCd === 'organization_type-work_group') {
      return '58px'
    }
    // ロール
    if (org.organizationTypeCd === 'organization_type-role') {
      return '38px'
    }
  }

  // 所属メンバーリストダイアログオープン
  async openMemberListDialog() {
    this.selectedMembers = []
    // 対象組織のメンバー取得
    // const menber = await this.organizationV2Service.GetMebmberList(this.selectedSection.organizationCd).toPromise()
    const menber$ = this.organizationV2Service.GetMebmberList(this.selectedSection.organizationCd)
    const menber = await lastValueFrom(menber$)

    if (menber?.resultCode !== 0) { return }
    this.memberList = menber.data
    if (this.memberList) {
      const checkReq: CustomerCheckRequest[] = []
      this.memberList.forEach(element => {
        // チェックボックス用項目追加
        element.selected = true
        element.desabled = true
        checkReq.push({
          organizationCd: element.organizationCd,
          customerCd: element.customerCd
        })
      })
      // const check = await this.organizationV2Service.CheckCustomers(checkReq).toPromise()
      const check$ = this.organizationV2Service.CheckCustomers(checkReq)
      const check = await lastValueFrom(check$)

      if (check?.resultCode !== 0) { return }
      const data: CustomerCheckResponse[] = check.data
      this.memberList.forEach(element => {
        const editable = data.find(x => x.customerCd === element.customerCd && x.editable)
        if (editable) {
          element.desabled = false
        }
      })
    }
    this.memberListDialog.open()
  }

  // 配属・参加メンバー選択
  clickMember(event, customer) {
    if (customer.desabled) { return }
    const target = event.currentTarget
    if (target.classList.value.indexOf('is-current') !== -1) {
      target.classList.remove('is-current')
      this.selectedMembers = this.selectedMembers.filter(x => x !== customer.customerCd)
    }
    else {
      target.classList.add('is-current')
      this.selectedMembers.push(customer.customerCd)
    }
  }
  // 所属メンバー選択（追加用）
  clickCompanyMember(event, customer) {
    // 配属済ユーザーは選択不可
    if (customer.authorityTypeName === '配属済') { return }

    const target = event.currentTarget
    if (target.classList.value.indexOf('is-current') !== -1) {
      target.classList.remove('is-current')
      this.selectedCompanyMembers = this.selectedCompanyMembers.filter(x => x !== customer.customerCd)
    }
    else {
      target.classList.add('is-current')
      this.selectedCompanyMembers.push(customer.customerCd)
    }
  }
  // メンバー追加リストダイアログオープン
  openAddMemberListDialog() {
    this.selectedCompanyMembers = []
    // 対象組織のメンバー取得
    this.organizationV2Service.GetCompanyMebmbersList(this.selectedSection.organizationCd).subscribe((ret: any) => {
      if (ret?.resultCode === 0) {
        this.parentCompanyCd = ret.data[0].organizationCd
        this.parentCompanyName = ret.data[0].organizationName
        this.companyMembersList = ret.data[0].customers
        this.companyMembersList.forEach(element => {
          // チェックボックス用項目追加
          element.selected = false
        })
        this.companyMembersListDialog.open()
      }
    }, error => {
      console.log(error.message)
    })
  }
  // メンバーの追加配属を追加
  assignMembers() {
    this.alertmessage = 'メンバーを追加配属しました'
    this.message = true
    this.organizationV2Service.PostOrganizationAndCustomerRelation(
      this.selectedSection.organizationCd, this.selectedCompanyMembers).subscribe((ret: any) => {
        if (ret?.resultCode === 0) {
          APP.controller.reset()
          this.getOrganizationTreeData(this.companyOrganizationCd, this.selectedBranch.organizationCd)
          setTimeout(() => {
            this.companyMembersListDialog.close()
            this.memberListDialog.close()
            this.alertmessage = ''
            this.message = false
            this.displist = false
          }, 1000)
        } else {
          this.displist = false
          console.log(ret.resultMessage)
        }
      }, error => {
        console.log(error.message)
      })
  }
  // メンバーの配属を解除
  releaseMember() {
    if (this.selectedMembers.length === 0) {
      this.checkmessage = '解除するメンバーが指定されていません。'
      setTimeout(() => {
        this.checkmessage = ''
      }, 3000)
      return
    }
    this.alertmessage = 'メンバーの配属を解除しました'
    this.message = true
    this.organizationV2Service.DeleteOrganizationAndCustomerRelation(
      this.selectedSection.organizationCd, this.selectedMembers).subscribe((ret: any) => {
        if (ret?.resultCode === 0) {
          APP.controller.reset()
          // setTimeout(() => {
          //   this.memberListDialog.close();
          //   this.alertmessage=""
          //   this.message =false;
          //   this.displist = false;
          // }, 1000)
          // 再表示
          this.getOrganizationTreeData(this.companyOrganizationCd, this.selectedBranch.organizationCd)
          setTimeout(() => {
            this.memberListDialog.close()
            this.alertmessage = ''
            this.message = false
            this.displist = false
          }, 1000)
        } else {
          this.displist = false
          console.log(ret.resultMessage)
        }
      }, error => {
        console.log(error.message)
      })
  }
  // 移動先ダイアログオープン
  openSectionListDialog() {
    let chkMember: boolean
    chkMember = true
    if (this.selectedMembers.length === 0) {
      this.checkmessage = '異動するメンバーが指定されていません。'
      chkMember = false
      setTimeout(() => {
        this.checkmessage = ''
      }, 3000)
      return
    }
    this.selectedMembers.forEach(member => {
      const index = this.memberList.findIndex(x => x.customerCd === member)
      if (index !== -1) {
        if (this.memberList[index].relationCategoryName === '参加') {
          this.checkmessage = '参加メンバーを他部署に異動することはできません。'
          chkMember = false
          setTimeout(() => {
            this.checkmessage = ''
          }, 3000)
          return
        }
      }
    })

    if (chkMember === true) {
      this.newSection = []
      this.sectionListDialog.open()
    }
  }

  // 移動先部署行選択
  handleRowSelection(args) {
    this.newSection = args.newSelection
  }

  // メンバーの配属先の変更
  changeSection() {
    if (this.newSection.length === 0) {
      this.checkmessage = '配属先を選択してください'
      setTimeout(() => {
        this.checkmessage = ''
      }, 3000)
      return
    }
    // 配属先変更処理
    this.alertmessage = 'メンバーの配属先を変更しました'
    this.message = true
    this.organizationV2Service.PostChangeOrganizationAndCustomerRelation(
      this.selectedSection.organizationCd,
      this.newSection[0].organizationCd,
      this.selectedMembers).subscribe((ret: any) => {
        if (ret?.resultCode === 0) {
          APP.controller.reset()
          setTimeout(() => {
            this.sectionListDialog.close()
            this.memberListDialog.close()
            this.alertmessage = ''
            this.message = false
            this.displist = false
          }, 1000)
          // 組織図ツリーデータ取得
          this.getOrganizationTreeData(this.companyOrganizationCd, this.selectedSection.organizationCd)
        } else {
          this.displist = false
          console.log(ret.resultMessage)
        }
      }, error => {
        console.log(error.message)
      })


  }

  changeShowOption() {

  }
  endEdit(): void {
    this.jumpTreeViewPage.emit()
  }
}
