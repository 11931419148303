import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { NafcoActuateSetting, UpdatMenuSwitchingDate, UpdateLabelOption } from './nafco-frimo-api.interface'

@Injectable({
  providedIn: 'root'
})
export class NafcoFrimoApiService {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 【B/A移行用】ナフコ サービス稼働設定一覧
  GetNafcoActuateSettingsForMigration(): Observable<ResultInfo<NafcoActuateSetting>> {
    const url = `${this.baseURL}api/NafcoFrimo/NafcoActuateSettingsForMigration`
    return this.httpRequestService.get(url)
  }

  // 【通常用】ナフコ サービス稼働設定一覧
  GetNafcoActuateSettings(): Observable<ResultInfo<NafcoActuateSetting>> {
    const url = `${this.baseURL}api/NafcoFrimo/NafcoActuateSettings`
    return this.httpRequestService.get(url)
  }

  // ナフコ メニュー切替日更新
  UpdateMenuDate(payload: UpdatMenuSwitchingDate[]): Observable<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/NafcoFrimo/NafcoActuateSettings/UpdatMenuSwitchingDate`
    return this.httpRequestService.put(url, payload)
  }

  // ナフコ ラベルオプション更新
  UpdateLabelOption(payload: UpdateLabelOption[]): Observable<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/NafcoFrimo/NafcoActuateSettings/UpdateLabelOption`
    return this.httpRequestService.put(url, payload)
  }
}
