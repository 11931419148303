import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core'
import { EstimateDocumentDetailAll } from 'src/api/estimates-api/estimates-api.interface'
import { EstimateDocumentDetailAllUi } from 'src/app/module/myidea-parts/contract-detail-block/contract-detail-block-all/add-estimate.interface'
import { AutoMap } from 'src/app/common/common'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-contract-detail-block-all',
  templateUrl: './contract-detail-block-all.component.html',
  styleUrls: ['./contract-detail-block-all.component.scss']
})
export class ContractDetailBlockAllComponent implements OnInit, OnChanges {
  @Input() detailList: EstimateDocumentDetailAll[] = []
  @Input() loading = true
  rowSelection = RowSelection.NONE

  columns: Header[] = [
    {
      key: 'rowNo',
      label: 'CD',
      hidden: true
    },
    {
      key: 'merchandiseName',
      label: '商品',
      width: '25%',
    },
    {
      key: 'businessCategoryTagText',
      label: '業態',
      width: '8%',
    },
    {
      key: 'beforeUnitQuantity',
      label: '数量',
      dataType: 'number',
      groupLabel: 'Before',
      width: '8%',
    },
    {
      key: 'beforeUnitPrice',
      label: '単価',
      dataType: 'number',
      groupLabel: 'Before',
      width: '8%',
    },
    {
      key: 'beforeTotalRunningPrice',
      label: '合計金額',
      summary: true,
      dataType: 'number',
      groupLabel: 'Before',
      width: '12%',
    },
    {
      key: 'afterUnitQuantity',
      label: '数量',
      dataType: 'number',
      groupLabel: 'After',
      width: '8%',
    },
    {
      key: 'afterUnitPrice',
      label: '単価',
      dataType: 'number',
      groupLabel: 'After',
      width: '8%',
    },
    {
      key: 'afterTotalRunningPrice',
      label: '合計金額',
      summary: true,
      dataType: 'number',
      groupLabel: 'After',
      width: '12%',
    },
    {
      key: 'comment',
      label: 'コメント',
    },
  ]
  cellClasses = {
    color_green: (rowData: any, columnKey: string): boolean => {
      if (rowData.beforeAfterDifference) { return true }
      return false
    },
  }
  differenceViewMode = false // 全体表示Gridの表示モード(全行表示⇔差分行のみ表示)
  convertList: EstimateDocumentDetailAllUi[] = []
  dispList: EstimateDocumentDetailAllUi[] = []

  constructor(
  ) { }

  ngOnInit(): void {
    this.convert()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailList) {
      this.convert()
      this.dispList = this.convertList
    }
  }

  convert(): void {
    const convertDataList: EstimateDocumentDetailAllUi[] = []
    this.detailList?.forEach(data => {
      const convertData = AutoMap<EstimateDocumentDetailAllUi>(data)
      // Before/Afterの差有り
      if (data.beforeUnitQuantity !== data.afterUnitQuantity) {
        convertData.beforeAfterDifference = true
      }
      convertDataList.push(convertData)
    })
    this.convertList = convertDataList
  }

  // 全体表示Gridの表示切り替え(全行表示⇔差分行のみ表示)
  changeAllViewMode(): void {
    if (this.differenceViewMode) {
      this.dispList = this.convertList
    }
    else {
      this.dispList = this.convertList.filter(x => x.beforeAfterDifference)
    }
    this.differenceViewMode = !this.differenceViewMode
  }
}
