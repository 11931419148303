<div class="l-main__contents js-fit-contents">
    <h1 class="c-texts--head1">{{ title }}</h1>
    <app-progress *ngIf="sending"></app-progress>
    <app-action-grid
      [data]="data"
      [loading]="loading"
      [rowSelection]="rowSelection"
      [columns]="columns"
      [actionButtons]="actionButtons"
      (actionCommit)="actionCommit($event)"
    ></app-action-grid>
    <!-- <lib-is-grid></lib-is-grid> -->
    <div class="c-btns small-heigth">
      <div class="c-btns__item">
        <a class="c-btn" routerLink="/ura-top" style="cursor: pointer"
          ><span>戻る</span></a
        >
      </div>
    </div>
  </div>