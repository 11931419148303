import { Component, OnInit, ViewChild } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { ActuatesService } from 'src/api/actuates-api/actuates-api.service'
import { ContractModel } from 'src/api/conta-api/conta-api.interface'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { ActuateSettingDialogComponent } from '../components/actuate-setting-dialog/actuate-setting-dialog.component'
import { FrimoActuateSettingDialogComponent } from '../components/frimo-actuate-setting-dialog/frimo-actuate-setting-dialog.component'

@Component({
  selector: 'app-ura-update-actuate-setting',
  templateUrl: './ura-update-actuate-setting.component.html',
  styleUrls: ['./ura-update-actuate-setting.component.scss']
})
export class UraUpdateActuateSettingComponent implements OnInit {
  title = '稼働設定'
  selectedTargetOrg: BackendOrganizationCorporateV2
  data: ContractModel[] = []
  columns: Header[] = [
    {
      key: 'serviceTagName',
      label: 'サービス',
      sort: true,
    },
    {
      key: 'retailTagText',
      label: '小売',
      sort: true,
    },
    {
      key: 'businessCategoryTagName',
      label: '業態',
      sort: true,
    },
    {
      key: 'productInquiryCd',
      label: 'プロダクトコード',
      sort: true,
    },
    {
      key: 'oldInquiryNo',
      label: '旧お問い合わせNo.',
      sort: true,
    },
    {
      key: 'productName',
      label: '商品名',
      sort: true,
    },
    {
      key: 'supplierCd',
      label: 'サプライヤーコード',
      sort: true,
    },
    {
      key: 'actuateStartDatetimeUi',
      label: '稼働日',
      sort: true,
    },
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '稼働設定',
      actionCd: 'actuateSetting',
      notRowAction: true,
    },
  ]
  loading = true
  sending = false
  frimoSettingTitle: string
  dialogOpen = false
  dispOrganizationName: string
  editableCds: number[] = [2] // サービスアプリケーション項目のみ
  @ViewChild(ActuateSettingDialogComponent, { static: false })
  private actuateSettingDialog: ActuateSettingDialogComponent
  @ViewChild(FrimoActuateSettingDialogComponent, { static: false })
  private frimoActuateSettingDialog: FrimoActuateSettingDialogComponent

  constructor(
    private actuatesService: ActuatesService,
  ) { }

  ngOnInit(): void {
    if (!this.selectedTargetOrg) { return }
    this.searchList()
  }

  selectTargetOrganization(event: BackendOrganizationCorporateV2): void {
    this.selectedTargetOrg = event
    this.dispOrganizationName = event?.organizationFormalName
    this.ngOnInit()
  }

  async searchList(): Promise<void> {
    this.data = []
    this.loading = true
    // const ret = await this.actuatesService.GetSettingActuateList(
    //   this.selectedTargetOrg.organizationCd).toPromise()
    const ret$ = this.actuatesService.GetSettingActuateList(this.selectedTargetOrg.organizationCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.loading = false
    this.data = this.convertDate(ret.data)
  }

  convertDate(data: ContractModel[]): ContractModel[] {
    data = data.filter(x => x.productCd && x.actuateStatus !== '0')
    data.forEach(item => {
      item.actionCds = []
      item.actionCds.push('actuateSetting')
    })
    return data
  }

  async actionCommit(event: ActionTargetInfo): Promise<void> {
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    const selectRow = event.rows[0]
    if (action.actionCd === 'actuateSetting') {
      if (selectRow.productCd === 'frimo_core_product') {
        // Frimo専用画面
        this.frimoSettingTitle = selectRow.productInquiryCd
        this.frimoActuateSettingDialog.open(selectRow.actuateCd)
      }
      else {
        // サプライヤーCD登録画面(汎用)
        this.actuateSettingDialog.open(selectRow.contractCd)
      }
      return
    }
  }
}
