<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-search-company-key-input
    [searchKey]="searchKey"
    (searchStart)="searchStart()"
    (searchEnd)="setGridData($event)"
  ></app-search-company-key-input>
  <app-search-company-list
    [data]="organizations"
    [loading]="loading"
    (selectRow)="selectCompany($event)"
  ></app-search-company-list>
  <app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
</app-main-contents>
