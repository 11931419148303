import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { AuthService } from 'src/app/components/isbeauth/auth.service'

@Component({
  selector: 'app-ura-organization-tree-edit',
  templateUrl: './ura-organization-tree-edit.component.html',
  styleUrls: ['./ura-organization-tree-edit.component.scss']
})
export class UraOrganizationTreeEditComponent implements OnInit {
  companyOrganizationCd: string
  selectOrganizationCd: string
  loading = true
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const serviceOrganizationCd = this.authService.ChangeCustomer.organizationCd
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.companyOrganizationCd = params.get('companyOrganizationCd')
      const selectOrganizationCd = params.get('selectOrganizationCd')
      this.selectOrganizationCd = selectOrganizationCd === '-1' ? '' : selectOrganizationCd
      this.loading = false
    })
  }

  selectTargetOrganization(event: BackendOrganizationCorporateV2): void {
    // serviceに保存されている選択組織とURLの組織が異なる場合は最初の画面に戻す
    if (this.companyOrganizationCd !== event?.organizationCd) {
      this.router.navigate(['/ura-organization-tree-view'])
    }
  }

  jumpTreeViewPage(): void {
    this.router.navigate(['/ura-organization-tree-view'])
  }
}
