import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { ContaSearchMacroRequest, ContaSearchUserMacroRequest, ContaServiceMap, ContractListItem, ContractModel, ProductCdMaster, SearchServiceMapRequest, SearchUser } from './conta-api.interface'

@Injectable({
  providedIn: 'root'
})
export class ContaApiService {
  baseURL2 = environment.backendMacroBaseUrl
  baseURL = environment.myideaMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
  ) { }

  // プロダクトコードマスタ取得
  GetProductCodeMaster(serviceTagCds: string[]): Observable<ResultInfo<ProductCdMaster>> {
    const url = `${this.baseURL2}api/Conta/ProductCodeMaster/Get`
    return this.httpRequestService.post(url, serviceTagCds)
  }

  // サービスマップ検索
  async SearchServiceMaps(payload: SearchServiceMapRequest): Promise<ResultInfoSingle<ContaServiceMap>> {
    const url = `${this.baseURL}api/conta/serviceMap/search`
    const ret$ = this.httpRequestService.postResultSingle<ContaServiceMap>(url, payload)
    return await lastValueFrom(ret$)
  }

  // 契約一覧取得
  async SearchContractList(payload: ContaSearchMacroRequest): Promise<ResultInfo<ContractListItem>> {
    const url = `${this.baseURL}api/conta/contract/search`
    const ret$ = this.httpRequestService.post<ContractListItem>(url, payload)
    return await lastValueFrom(ret$)
  }
  // 契約一覧　件数取得
  async SearchContractCount(payload: ContaSearchMacroRequest): Promise<ResultInfoSingle<number>> {
    const url = `${this.baseURL}api/conta/contract/searchForCount`
    const ret$ = this.httpRequestService.postResultSingle<number>(url, payload)
    return await lastValueFrom(ret$)
  }

  // 利用者一覧取得
  async SearchUserList(payload: ContaSearchUserMacroRequest): Promise<ResultInfo<SearchUser>> {
    const url = `${this.baseURL}api/conta/user/search`
    const ret$ = this.httpRequestService.post<SearchUser>(url, payload)
    return await lastValueFrom(ret$)
  }

    // 組織Cdで契約一覧を取得
    async GetContractListSearchByCompanyCd(organizationCd: string,
      customerCd?: string): Promise<ResultInfo<ContractModel>> {
      let url = `${this.baseURL}api/conta/contract/organization/${organizationCd}`
      if (customerCd) {
        url = `${url}?customerCd=${customerCd}`
      }
      const ret$ = this.httpRequestService.get<ContractModel>(url)
      return await lastValueFrom(ret$)
    }
}
