import { Component, OnInit } from '@angular/core'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { ContractListItem } from 'src/api/conta-api/conta-api.interface'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { CreateInvoiceTargetRequest, InvoiceContractModel, InvoiceContractModelUI } from 'src/api/invoice-api/invoice-api.interface'
import { MessageService } from 'src/app/components/message/message.service'
import { AutoMap, DeepCopy } from 'src/app/common/common'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { messageList } from 'src/app/components/message/message-data'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { lastValueFrom } from 'rxjs'
@Component({
  selector: 'app-ura-invoice-change',
  templateUrl: './ura-invoice-change.component.html',
  styleUrls: ['./ura-invoice-change.component.scss'],
})
export class UraInvoiceChangeComponent implements OnInit {
  title = '請求先変更'
  data: ContractListItem[] = []
  columns: Header[] = [
    {
      key: 'contractCompanyOrganizationName',
      label: '契約会社',
      sort: true,
      width: '200px',
    },
    {
      key: 'contractSectionOrganizationName',
      label: '契約部署',
      sort: true,
      width: '120px',
    },
    {
      key: 'serviceTagName',
      label: 'サービス',
      sort: true,
      width: '80px',
    },
    {
      key: 'retailTagText',
      label: '小売',
      sort: true,
      width: '80px',
    },
    {
      key: 'businessCategoryTagName',
      label: '業態',
      sort: true,
      width: '110px',
    },
    {
      key: 'productInquiryCd',
      label: 'プロダクトコード',
      sort: true,
      width: '200px',
    },
    {
      key: 'merchandiseDisplayName',
      label: '商品名',
      sort: true,
      width: '220px',
    },
    {
      key: 'supplierCd',
      label: 'サプライヤーコード',
      sort: true,
      width: '80px',
    }
  ]
  cellClasses = {
    color_green: (rowData: any, columnKey: string): boolean => {
      if (rowData.backColor) { return true }
      return false
    },
  }
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '選択契約を移動',
      actionFunction: () => {
        this.moveRows()
      }
    },
    {
      label: '変更を保存',
      actionFunction: () => {
        this.updateInvoiceRelations()
      }
    },
  ]
  rowSelection = RowSelection.MULTIPLE
  loading = false
  loadingBefore = false
  loadingAfter = false
  searchedNumber = '-'

  beforeId = 0
  beforeInvoiceTarget: CreateInvoiceTargetRequest = null
  beforeInvoiceList: InvoiceContractModelUI[] = []
  beforeInvoiceSelected: InvoiceContractModelUI[] = []
  afterId = 0
  afterInvoiceTarget: CreateInvoiceTargetRequest = null
  afterInvoiceList: InvoiceContractModelUI[] = []
  afterInvoiceSelected: InvoiceContractModelUI[] = []

  constructor(
    private invoiceService: InvoiceService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  changeValue(key: string, value: string): void {
    if (key === 'before') { this.beforeId = Number(value) }
    if (key === 'after') { this.afterId = Number(value) }
  }

  async getInvoiceList(key: string): Promise<void> {
    let req = 0
    if (key === 'before') { req = this.beforeId }
    if (key === 'after') { req = this.afterId }
    const invoiceTargetsRet = await this.invoiceService.GetInvoiceTargetBySuppliCustomerId(req.toString())
    if (invoiceTargetsRet?.resultCode !== 0) { return }
    const invoiceTarget = invoiceTargetsRet.data
    
      if (!invoiceTarget) {
        this.messageService.warning('該当の楽々明細IDは存在しません。')
        return
      }
      if (invoiceTarget.invoiceTargetCd === this.beforeInvoiceTarget?.invoiceTargetCd
        || invoiceTarget.invoiceTargetCd === this.afterInvoiceTarget?.invoiceTargetCd) {
        this.messageService.templeteMessage(messageList.M00039)
        return
      }
      if (key === 'before') {
        this.loadingBefore = true
        this.beforeInvoiceTarget = invoiceTarget
        const beforeData = await this.getInvitationReceiveList(this.beforeInvoiceTarget.invoiceTargetCd)
        this.beforeInvoiceList = this.convert(beforeData, key)
        this.loadingBefore = false
      }
      if (key === 'after') {
        this.loadingAfter = true
        this.afterInvoiceTarget = invoiceTarget
        const afterData = await this.getInvitationReceiveList(this.afterInvoiceTarget.invoiceTargetCd)
        this.afterInvoiceList = this.convert(afterData, key)
        this.loadingAfter = false
      }
  }
  async getInvitationReceiveList(invoiceTargetCd: string): Promise<InvoiceContractModel[]> {
    const ret$ = this.invoiceService.SearchContracts([invoiceTargetCd])
    const ret = await lastValueFrom(ret$)

    return ret.data
  }

  clearInvoiceList(key: string): void {
    if (key === 'before') {
      this.beforeInvoiceTarget = null
      this.beforeInvoiceList = []
    }
    if (key === 'after') {
      this.afterInvoiceTarget = null
      this.afterInvoiceList = []
    }
  }

  convert(Data: InvoiceContractModel[], key: string): InvoiceContractModelUI[] {
    const convertDataList: InvoiceContractModelUI[] = []
    Data?.forEach(data => {
      const convertData = AutoMap<InvoiceContractModelUI>(data)
      if (key === 'before') {
        convertData.backColor = true
      }
      if (key === 'after') {
        convertData.backColor = false
      }
      convertDataList.push(convertData)
    })
    return convertDataList
  }
  selectionChange(event: any, key: string): void {
    if (key === 'before') {
      if (event.add) {
        let addItem = event.add
        this.beforeInvoiceSelected.forEach(item => {
          addItem = addItem.filter(x => x.contractCd !== item.contractCd)
        })
        this.beforeInvoiceSelected.push(...addItem)
      }
      if (event.delete) {
        event.delete.forEach(item => {
          this.beforeInvoiceSelected = this.beforeInvoiceSelected.filter(x => x.contractCd !== item.contractCd)
        })
      }
    }
    if (key === 'after') {
      if (event.add) {
        let addItem = event.add
        this.afterInvoiceSelected.forEach(item => {
          addItem = addItem.filter(x => x.contractCd !== item.contractCd)
        })
        this.afterInvoiceSelected.push(...addItem)
      }
      if (event.delete) {
        event.delete.forEach(item => {
          this.afterInvoiceSelected = this.afterInvoiceSelected.filter(x => x.contractCd !== item.contractCd)
        })
      }
    }
  }
  moveRows(): void {
    if (this.beforeInvoiceSelected.length !== 0) {
      const items = DeepCopy(this.afterInvoiceList)
      items.push(...this.beforeInvoiceSelected)
      this.afterInvoiceList = items
      this.beforeInvoiceSelected.forEach(item => {
        this.beforeInvoiceList = this.beforeInvoiceList.filter(x => x.contractCd !== item.contractCd)
      })
    }
    if (this.afterInvoiceSelected.length !== 0) {
      this.afterInvoiceSelected = this.afterInvoiceSelected.filter(x => x.backColor !== false)
      const items = DeepCopy(this.beforeInvoiceList)
      items.push(...this.afterInvoiceSelected)
      this.beforeInvoiceList = items
      this.afterInvoiceSelected.forEach(item => {
        this.afterInvoiceList = this.afterInvoiceList.filter(x => x.contractCd !== item.contractCd)
      })
    }
    this.beforeInvoiceSelected = []
    this.afterInvoiceSelected = []
  }
  async updateInvoiceRelations(): Promise<void> {
    const contractCds = []
    this.afterInvoiceList.forEach(item => {
      if (item.backColor) {
        contractCds.push(item.contractCd)
      }
    })
    if (contractCds.length === 0) {
      this.messageService.success('移行する契約が選択されていません')
    }
    const ret = await this.invoiceService.updateContractInvoiceRelations(this.afterInvoiceTarget.invoiceTargetCd, contractCds)

    // if (ret?.resultCode !== 0) { return }
    this.messageService.success('請求先を更新しました')
    this.beforeInvoiceTarget = null
    this.afterInvoiceTarget = null
    this.beforeInvoiceList = []
    this.afterInvoiceList = []
  }
}
