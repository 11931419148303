<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-search-company-key-input
    [searchKey]="searchKey"
    (searchStart)="searchStart()"
    (searchEnd)="setGridData($event)"
  ></app-search-company-key-input>
  <app-search-company-list
    [data]="organizations"
    [loading]="loading"
    (selectRow)="selectCompany($event)"
  ></app-search-company-list>
  <app-under-btn-disp [actionButtons]="actionButtons"></app-under-btn-disp>
</app-main-contents>
