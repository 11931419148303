import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { TargetServiceBlockInfo, ServiceMapViewBlock } from 'src/app/module/myidea-parts/service-map-view.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'

@Component({
  selector: 'app-ura-cancel-contract-service-map',
  templateUrl: './ura-cancel-contract-service-map.component.html'
})
export class UraCancelContractServiceMapComponent implements OnInit {
  title = 'サービスの解約'
  organizationCompanyCd: string
  contractOrganizationCd: string
  serviceMap: ServiceMapViewBlock[] = []
  loading = true
  alertActuateCds: string[] = []
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.pageBack()
      }
    },
    {
      label: '解約を申し込む',
      actionFunction: () => {
        this.confirm()
      }
    }
  ]

  constructor(
    private serviceMapViewService: ServiceMapViewService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCompanyCd = params.get('organizationCompanyCd')
      this.contractOrganizationCd = params.get('contractOrganizationCd')
    })
    if (this.organizationCompanyCd) {
      this.getServiceMap()
    }
  }

  // サービスマップ取得
  async getServiceMap(): Promise<void> {
    this.loading = true
    const apiMapData = await this.serviceMapViewService.getServiceMapSearchByOrgFromApi(this.contractOrganizationCd)
    let map = await this.serviceMapViewService.convertBaseServiceMap(apiMapData, this.organizationCompanyCd, this.contractOrganizationCd)
    map = this.serviceMapViewService.addColumnForCancel(map, this.organizationCompanyCd)
    this.serviceMap = this.serviceMapViewService.setCancelIcon(map, this.contractOrganizationCd)
    this.loading = false
  }

  clickIconEvent(value: TargetServiceBlockInfo): void {
    const cancelClass = 'cancel-block'
    // 解約対象選択
    if (!value.targetBlock.classes?.find(x => x === cancelClass)) {
      // クリックしたのが出荷Packコネクターの場合
      if (value.targetBlock.merchandiseCd === 'frimo_shipment_connector') {
        // 同階層の解約未選択要素を確認
        const enableConnecter: ServiceMapViewBlock[] = []
        value.parentBlock.children.forEach(child => {
          if (!child.classes.find(x => x === cancelClass)
            && child.merchandiseCd === 'frimo_shipment_connector') {
            enableConnecter.push(child)
          }
        })
        if (enableConnecter.length <= 1) {
          this.messageService.templeteMessage(messageList.M00027)
          return
        }
      }

      value.targetBlock.classes.push(cancelClass)
      value.targetBlock.iconClass = 'add'

      // セッションに保存
      this.serviceMapViewService.addCancelContractCds(value.targetBlock.contractCd, this.contractOrganizationCd)

      // 子要素も選択状態にする
      // クリックしたのがプロダクト(1,3階層)だった場合
      if (value.floorNumber === 1 || value.floorNumber === 3) {
        value.targetBlock.children.forEach(child => {
          child.classes.push(cancelClass)
          child.iconClass = ''

          // セッションに保存
          this.serviceMapViewService.addCancelContractCds(child.contractCd, this.contractOrganizationCd)
        })
      }
    }
    // 解約対象解除
    else {
      value.targetBlock.classes = value.targetBlock.classes.filter(x => x !== cancelClass)
      value.targetBlock.iconClass = 'delete'

      // セッションに保存
      this.serviceMapViewService.removeCancelContractCds(value.targetBlock.contractCd, this.contractOrganizationCd)

      // 子要素も選択解除する
      if (value.floorNumber === 1 || value.floorNumber === 3) {
        value.targetBlock.children.forEach(child => {
          child.classes = child.classes.filter(x => x !== cancelClass)
          if (child.productCd !== 'account_box_product'
            && child.productCd !== 'products-garage_account_box_product'
            && child.productCd !== 'products-garage_product_info_product'
            && child.productCd !== 'products-garage_item_info_product'
            && child.productCd !== 'products-garage_data_box_product'
            && child.productCd !== 'products-garage_file_box_product') {
            child.iconClass = 'delete'
          }

          // セッションに保存
          this.serviceMapViewService.removeCancelContractCds(child.contractCd, this.contractOrganizationCd)
        })
      }
    }
  }

  confirm(): void {
    const cancelContractCds = this.serviceMapViewService.getKeepData(this.contractOrganizationCd)?.cancelContractCds
    if (!cancelContractCds) {
      this.messageService.templeteMessage(messageList.M00028)
      return
    }
    const warning = this.serviceMapViewService.cancelCheckOfNoConnect(cancelContractCds, this.serviceMap)
    if (warning.length) {
      const target: string[] = []
      if (warning.find(x => x.merchandiseCd === 'frimo_shipment_connector')) {
        target.push('出荷Packコネクタ')
      }
      if (warning.find(x => x.merchandiseCd === 'shipment_pack')) {
        target.push('出荷Pack')
      }
      const message = `未接続の${target.join('、')}の契約が残っていますが本当によろしいですか？`
      this.confirmService.confirm(message, '', () => {
        this.pageNext()
      })
      return
    }
    this.pageNext()
  }

  pageNext(): void {
    this.router.navigate([`/ura-cancel-confirm/${this.organizationCompanyCd}/${this.contractOrganizationCd}`])
  }


  pageBack(): void {
    this.router.navigate([`/ura-conpany-contracted-organization-list`])
  }
}
