<app-main-contents>
    <app-title [title]="title"></app-title>
    <app-action-grid
      [data]="data"
      [loading]="loading"
      [rowSelection]="rowSelection"
      [columns]="columns"
      [cellClasses]="cellClasses"
      [actionButtons]="actionButtons"
      (actionCommit)="actionCommit($event)"
    ></app-action-grid>
  </app-main-contents>
  
  <app-dialog
    *ngIf="showDialog"
    [message]="dialogMessage"
    width="small"
    (commit)="daialogCommit()"
    (cancel)="showDialog = false"
  >
    <igx-date-picker
      [(ngModel)]="inputDate"
      displayFormat="yyyy-MM-dd"
      inputFormat="yyyy-MM-dd"
    ></igx-date-picker>
  </app-dialog>