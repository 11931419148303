import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import {
  EstimateCreateMacroRequest,
  EstimateDocument,
  EstimateDocumentDetail,
  EstimateDocumentDetailAll,
  EstimateDocumentDetailStatus,
  EstimateHeader
} from './estimates-api.interface'
import { ServiceMapBlock } from '../service-map-api/service-map-api.interface'
import { EstimateDocumentStatus } from '../frimo-migrations-api/frimo-migrations-api.model'

@Injectable({
  providedIn: 'root'
})
export class EstimateApiService {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 見積一覧(新規)
  async getEstimateDocumentStatusList(): Promise<ResultInfo<EstimateDocumentStatus>> {
    const url = `${environment.myideaMacroBaseUrl}api/estimate/document/status/new`
    const ret$ = this.httpRequestService.get<EstimateDocumentStatus>(url)
    return await lastValueFrom(ret$)
  }

  // 見積一覧(追加)
  async getAddEstimateDocumentStatusList(organizationCd: string): Promise<ResultInfo<EstimateDocumentStatus>> {
    const url = `${environment.myideaMacroBaseUrl}api/estimate/document/status/add?companyOrganizationCd=${organizationCd}`
    const ret$ = this.httpRequestService.get<EstimateDocumentStatus>(url)
    return await lastValueFrom(ret$)
  }

  // 見積参照
  EstimateDocument(organizationContractCd: string): Observable<ResultInfo<EstimateDocument>> {
    const url = `${this.baseURL}api/Estimates/OrganizationContracts/${organizationContractCd}`
    return this.httpRequestService.get(url)
  }

  // 見積PDFパス取得
  EstimateDocumentPath(organizationContractCd: string): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/Estimates/OrganizationContracts/${organizationContractCd}/EstimateDocumentPath`
    return this.httpRequestService.get(url)
  }

  // 見積もりプレビューPDF取得
  GetEstimateDocumentPreview(payload: any, targetCd: string): Observable<ResultInfo<string>> {
    const url = `${this.baseURL}api/v2/Estimates/ManagementPlaces/${targetCd}/GetEstimateDocumentPreview`
    return this.httpRequestService.post(url, payload)
  }

  // 見積PDFパス取得
  ConsentEstimate(
    estimateDocumentCd: string,
    targetCd: string
  ): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/Estimates/Application/EstimateDocuments/${estimateDocumentCd}/ManagementPlaces/${targetCd}`
    return this.httpRequestService.put(url, null)
  }

  // 見積保存
  SaveEstimates(
    targetCd: string,
    payload: EstimateCreateMacroRequest
  ): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/Estimates/ManagementPlaces/${targetCd}/Create`
    return this.httpRequestService.post(url, payload)
  }

  // 【Ura-idea用】追加/変更/解約 見積を元に稼働まで生成
  CreateContractEasyAuth(
    estimateDocumentCd: string
  ): Observable<ResultInfo<any>> {
    const url = `${environment.backendMacroBaseUrl}api/Estimates/EstimateDocuments/${estimateDocumentCd}/CreateActuate`
    return this.httpRequestService.post(url, null)
  }

  // 新規見積保存
  CreateNewEstimates(
    payload: EstimateCreateMacroRequest,
    targetOrganizationCd: string,
    customerCd?: string,
  ): Observable<ResultInfo<any>> {
    let url = `${this.BaseURL}api/Estimates/Organizations/${targetOrganizationCd}/Create`
    if (customerCd) {
      url = `${url}?customerCd=${customerCd}`
    }
    return this.httpRequestService.post(url, payload)
  }

  // 見積もり詳細取得
  getEstimateDocumentDetail(estimateDocumentCd: string): Observable<ResultInfo<EstimateDocumentDetail>> {
    const url = `${this.baseURL}api/Estimates/${estimateDocumentCd}/EstimateDetails`
    return this.httpRequestService.get(url)
  }

  // 見積書情報取得
  getEstimateDocumentStatus(estimateDocumentCd: string): Observable<ResultInfo<EstimateDocumentDetailStatus>> {
    const url = `${this.baseURL}api/ApplicationContract/EstimateDocumentStatus/EstimateDocument/${estimateDocumentCd}`
    return this.httpRequestService.get(url)
  }

  // 見積もり詳細プレビュー
  EstimateDetailsPreview(
    payload
  ): Observable<ResultInfo<EstimateDocumentDetail>> {
    const url = `${this.baseURL}api/v2/Estimates/EstimateDetails`
    return this.httpRequestService.post(url, payload)
  }

  // 見積書Path取得
  EstimateDocumentPathAdd(estimateDocumentCd: string): Observable<ResultInfo<EstimateDocumentDetail>> {
    const url = `${this.baseURL}api/Estimates/${estimateDocumentCd}/EstimateDocumentPath`
    return this.httpRequestService.get(url)
  }

  // 見積もりサービスマップ取得
  getEstimateDocumentServiceMap(estimateDocumentCd: string): Observable<ResultInfo<ServiceMapBlock>> {
    const url = `${this.baseURL}api/Estimates/${estimateDocumentCd}/EstimateServiceMap`
    return this.httpRequestService.get(url)
  }

  // 見積もり全体プレビュー
  postEstimateDetailsWithAllContract(
    organizationCd: string,
    estimateMerchandises
  ): Observable<ResultInfo<EstimateDocumentDetailAll>> {
    const url = `${this.baseURL}api/Estimates/${organizationCd}/EstimateDetailsWithAllContract`
    return this.httpRequestService.post(url, estimateMerchandises)
  }

  // 見積もり全体 保存済
  getEstimateDetailsWithAllContract(organizationCd: string): Observable<ResultInfo<EstimateDocumentDetailAll>> {
    const url = `${this.baseURL}api/Estimates/${organizationCd}/EstimateDetailsWithAllContract`
    return this.httpRequestService.get(url)
  }
}
