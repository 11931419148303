import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { CustomValidators } from '../input-table/custom-validator'
import { SelectMaster } from '../input-table/input-table.model'
import { ValidatorService } from '../input-table/validator.service'
import { FormReturn } from './input-form.interface'

@Component({
  selector: 'app-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss']
})
export class InputFormComponent implements OnInit, OnChanges {
  @Input() formType = 'text'
  @Input() placeholder: string
  @Input() value: any
  @Input() master: SelectMaster[]
  @Input() readonly = false
  @Input() caption: string

  // バリエーション設定
  @Input() required = false
  @Input() mailAddress = false
  @Input() numOnly = false
  @Input() minLength: number
  @Input() maxLength: number
  @Input() alphanumeric = false
  @Input() hiragana = false
  @Input() bankAccountName = false
  @Input() notBlank = false
  @Input() mustMatchKey: string
  @Input() password = false

  @Input() showError = true
  @Input() showCaption = true

  @Output() updateValue = new EventEmitter<FormReturn>()
  @Output() keyUpEvent = new EventEmitter<any>()
  @Output() inputEvent = new EventEmitter<any>()
  @Output() changeEvent = new EventEmitter<any>()

  myControl: UntypedFormGroup
  history: string[] = []
  hidePassword = false

  constructor(
    private fb: UntypedFormBuilder,
    private validatorService: ValidatorService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }

  ngOnInit(): void {
    const form = { item: [this.value] }

    // バリデーション設定
    const valid = []
    if (this.required) {
      valid.push(Validators.required)
    }
    if (this.notBlank) {
      valid.push(CustomValidators.notBlank)
    }
    if (this.mailAddress) {
      valid.push(Validators.email)
    }
    if (this.minLength) {
      valid.push(Validators.minLength(this.minLength))
    }
    if (this.maxLength) {
      valid.push(Validators.maxLength(this.maxLength))
    }
    if (this.alphanumeric) {
      valid.push(CustomValidators.alphanumeric)
    }
    if (this.password) {
      valid.push(CustomValidators.password)
    }
    if (this.numOnly) {
      valid.push(CustomValidators.numOnly)
    }
    if (this.hiragana) {
      valid.push(CustomValidators.hiragana)
    }
    if (this.bankAccountName) {
      valid.push(CustomValidators.bankAccountName)
    }
    form.item.push(valid)
    this.myControl = this.fb.group(form)
  }

  // バリデーションチェックを実行し、エラーが無ければフォーム入力値を返す
  submit(): any {
    if (this.validatorService.valid(this.myControl)) { return null }
    return this.myControl.value.item
  }

  reset(): void {
    this.myControl.reset()
  }

  returnUpdateValue(): void {
    const returnValue: FormReturn = {
      isValid: !this.formError(),
      value: this.myControl.value.item
    }
    this.updateValue.emit(returnValue)
  }

  formError(): boolean {
    return this.validatorService.formError(this.myControl, 'item')
  }

  formErrorMessage(): string {
    return this.validatorService.formErrorMessage(this.myControl, 'item')
  }

  change(): void {
    this.returnUpdateValue()
    return this.changeEvent.emit(this.myControl.value.item)
  }

  input(): void {
    this.returnUpdateValue()
    return this.inputEvent.emit(this.myControl.value.item)
  }

  keyUp(): void {
    this.returnUpdateValue()
    return this.keyUpEvent.emit(this.myControl.value.item)
  }
}
