import { Component, OnInit } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { TrialContract } from 'src/api/trials-api/trials-api.interface'
import { TrialsApiService } from 'src/api/trials-api/trials-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-ura-trial-contract-list',
  templateUrl: './ura-trial-contract-list.component.html',
  styleUrls: ['./ura-trial-contract-list.component.scss']
})
export class UraTrialContractListComponent implements OnInit {
  title = 'お試し利用中一覧'
  data: TrialContract[] = []
  columns: Header[] = [
    {
      key: 'contractCd',
      label: 'CD',
      hidden: true
    },
    // {
    //   key: 'contractCompanyOrganizationName',
    //   label: '契約企業',
    // },
    // {
    //   key: 'contractSectionOrganizationName',
    //   label: '契約部署',
    // },
    // {
    //   key: 'contractCustomerName',
    //   label: '契約者',
    //   width: '8%'
    // },
    // {
    //   key: 'serviceTagName',
    //   label: 'サービス',
    //   width: '8%'
    // },
    // {
    //   key: 'retailTagText',
    //   label: '小売',
    //   width: '8%'
    // },
    // {
    //   key: 'businessCategoryTagName',
    //   label: '業態',
    //   width: '8%'
    // },
    {
      key: 'productName',
      label: '商品名',
      width: '20%'
    },
    {
      key: 'actuateName',
      label: '稼働メモ',
      width: '15%'
    },
    {
      key: 'productInquiryCd',
      label: 'プロダクトコード',
      width: '20%'
    },
    // {
    //   key: 'trialStartDatetimeUi',
    //   label: 'お試し開始日',
    //   width: '10%'
    // },
    // {
    //   key: 'trialEndDatetimeUi',
    //   label: 'お試し終了日',
    //   width: '10%'
    // },
    {
      key: 'authDivisionName',
      label: '権限',
      width: '12%'
    },
    {
      key: 'companyOrganizationName',
      label: '会社',
      width: '12%'
    },
    // {
    //   key: 'customerCd',
    //   label: '顧客CD',
    //   width: '5%'
    // },
    {
      key: 'customerName',
      label: '顧客',
      width: '10%'
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス',
      width: '18%'
    },
    {
      key: 'isFormalAccount',
      label: '正式登録済',
      dataType: 'check',
      width: '10%'
    },
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: 'お申込み依頼を送信',
      actionCd: 'sendMail',
      successMessage: true,
      confirmDialog: true
    },
  ]
  loading = true
  sending = false

  constructor(
    private messageService: MessageService,
    private trialsApiService: TrialsApiService
  ) { }

  ngOnInit(): void {
    this.getContractCompanyList()
  }
  async getContractCompanyList(): Promise<void> {
    this.data = []
    this.loading = true
    // const ret = await this.trialsApiService.SearchTrialContractsForGarage().toPromise()
    const ret$ = this.trialsApiService.SearchTrialContractsForGarage()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.loading = false
    this.data.forEach(item => {
      // item.actionCds = ['sendMail']
    })
  }

  async sendMail(rows: TrialContract[]): Promise<void> {
    const customerCds = []
    rows.forEach(row => {
      customerCds.push(row.customerCd)
    })
    this.sending = true
    // const ret = await this.garageMigrationsApiService.sendMailForTrialContracter(customerCds).toPromise()
    // if (ret?.resultCode !== 0) { return }
    this.messageService.success('メールを送信しました')
    this.sending = false
  }

  actionCommit(event: ActionTargetInfo): void {
    if (this.sending) { return }
    if (event.actionCd === 'sendMail') {
      this.sendMail(event.rows)
    }
  }
}
