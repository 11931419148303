import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { EasyAuthentication } from 'src/api/easy-authentications-api/easy-authentications-api.interface'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'
import { EasyAuthenticationLoginService } from './easy-authentication-login.service'

@Component({
  selector: 'app-easy-authentication-login',
  templateUrl: './easy-authentication-login.component.html',
  styleUrls: ['./easy-authentication-login.component.scss']
})
export class EasyAuthenticationLoginComponent implements OnInit {
  @Input() authenticationUriCd: string
  @Input() errorMessage = ''
  @Output() loginSuccess = new EventEmitter<void>()
  showLoginForm = false
  loginError = false
  errorMessage1 = ''
  errorMessage2 = ''

  constructor(
    private easyAuthenticationLoginService: EasyAuthenticationLoginService,
    private easyAuthenticationsApiService: EasyAuthenticationsApiService
  ) { }

  ngOnInit(): void {
    const login = localStorage.getItem(this.authenticationUriCd)
    if (login) {
      this.submit(login)
    }
    else {
      this.showLoginForm = true
    }
  }
  submit(authenticationCd): void {
    this.easyAuthenticationsApiService.LoginEasyAuthentications(this.authenticationUriCd, authenticationCd)
      .subscribe((ret: ResultInfo<EasyAuthentication>) => {
        if (ret?.resultCode === 0) {
          localStorage.setItem(this.authenticationUriCd, authenticationCd)
          this.loginError = false
          this.showLoginForm = false

          this.easyAuthenticationLoginService.setTemporaryAccount(ret.data[0])
          this.easyAuthenticationLoginService.setAuthenticationUriCd(this.authenticationUriCd)

          this.loginSuccess.emit()
        }
        else {
          this.errorMessage1 = ret.resultMessage || 'ログイン認証に失敗しました'
          this.errorMessage2 = ''
          if (ret?.resultCode === -2) {
            this.errorMessage1 = this.errorMessage1 + '。'
            this.errorMessage2 = this.errorMessage
          }
          localStorage.removeItem(this.authenticationUriCd)
          this.loginError = true
        }
      })
  }
}
