<app-main-contents>
  <app-title [title]="title"></app-title>
  <h2 class="c-edit-modal__head c-texts--head2 h-margin--b16">
    <span>1</span>会社を選択してください
  </h2>
  <div class="h-margin--b16">
    <app-input-search-company
      (changeSelect)="updateTargetCompany($event)"
    ></app-input-search-company>
  </div>
  <h2 class="c-edit-modal__head c-texts--head2 h-margin--b16">
    <span>2</span>顧客が表示されます
  </h2>
  <app-action-grid
    [data]="customers"
    [loading]="customerLoading"
    [rowSelection]="rowSelection"
    [columns]="customerColumns"
    [height]="400"
    [rowClickAction]="true"
  ></app-action-grid>
</app-main-contents>
