import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { ActuateSettingItem } from 'src/api/actuates-api/actuates-api.interface'
import { ActuatesService } from 'src/api/actuates-api/actuates-api.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'

@Component({
  selector: 'app-actuate-setting-dialog',
  templateUrl: './actuate-setting-dialog.component.html'
})
export class ActuateSettingDialogComponent implements OnInit {
  @Input() editableCds: number[] = [0, 1, 2] // 更新対象
  @Output() updateed = new EventEmitter<string>() // 更新が終わったContractCdを渡す
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  contractCd: string
  actuateSettings: ActuateSettingItem[]
  formItems: FormInputInfo[] = []
  settingValue: any = {}
  dialogOpen = false
  sending = false

  constructor(
    private actuatesService: ActuatesService,
    private messageService: MessageService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
  }

  async open(contractCd: string): Promise<void> {
    this.contractCd = contractCd
    const ret$ = this.actuatesService.GetActuateSettings(contractCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.actuateSettings = ret.data
    this.formItems = []
    this.actuateSettings.forEach(item => {
      if (this.editableCds.find(x => x === item.editable)) {
        this.settingValue[item.key] = item.value
        this.formItems.push(
          {
            key: item.key,
            label: item.name,
            type: item.type,
            valid: {
              notBlank: true
            }
          }
        )
      }
    })
    this.dialogOpen = true
    setTimeout(() => {
      this.inputTable?.setForm(this.settingValue)
    }, 100)
  }

  async update(): Promise<void> {
    if (this.sending) { return }
    const submitForm = this.inputTable.submit()
    if (!submitForm) { return }
    let empty = false
    this.actuateSettings.forEach(item => {
      if (this.editableCds.find(x => x === item.editable)) {
        item.value = submitForm[item.key]
        if (!item.value) {
          empty = true
        }
      }
    })

    // 空項目があった場合はアラート
    if (empty) {
      this.confirmService.confirm(`空の項目がありますがよろしいでしょうか？`, '', () => {
        this.submitUpdate()
      })
    }
    else {
      this.submitUpdate()
    }
  }

  async submitUpdate(): Promise<void> {
    this.sending = true
    // const ret = await this.actuatesService.UpdateActuateSettings(this.contractCd, this.actuateSettings).toPromise()
    const ret$ = this.actuatesService.UpdateActuateSettings(this.contractCd, this.actuateSettings)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) {
      this.messageService.error(`更新できませんでした。${ret?.resultMessage ?? ''}`)
      return
    }
    this.messageService.templeteMessage(messageList.M00050)
    this.updateed.emit(this.contractCd)
    this.dialogOpen = false
  }
}
