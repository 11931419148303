<div *ngIf="selected">
  <span class="small-font">{{ selectedOrganization?.corporateNumber }}</span> {{ selectedOrganization?.organizationFormalName }}
  <span *ngIf="!displayOnly" class="c-btns__item" (click)="backInputMode()">
    <a style="cursor: pointer"><span class="small-font">変更する</span></a>
  </span>
</div>
<div
  [class.no-show]="selected"
  (clickOutside)="showSearchCompanyResult = false"
>
  <span class="search-field">
    <input
      class="c-form__input"
      type="text"
      placeholder="会社名を入力してください"
      (keyup)="searchCompany()"
      (click)="searchCompany()"
      autocomplete="off"
      [(ngModel)]="searchKeyword"
    />
  </span>
  <div
    *ngIf="showSearchCompanyResult && !loading"
    class="search-company-result-area"
  >
    <div class="user-list-body">
      <div *ngIf="!organizationList.length">
        <div>該当なし</div>
      </div>
      <div
        *ngFor="let organization of organizationList"
        class="user-list"
        style="cursor: pointer"
        (click)="selectOrganization(organization)"
      >
        <a>{{ organization.corporateNumber }} {{ organization.organizationFormalName }}</a>
      </div>
    </div>
  </div>
</div>
