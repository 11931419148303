<app-main-contents>
  <app-breadcrumbs [label2]="title"></app-breadcrumbs>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <ng-container>
    <app-service-map-main
      [loading]="loading"
      [serviceMap]="serviceMap"
      (clickService)="clickServiceEvent($event)"
    >
    </app-service-map-main>
  </ng-container>
</app-main-contents>
<app-user-list-dialog #userListDialog></app-user-list-dialog>
