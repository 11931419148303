import { Component, OnInit, ViewChild } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { Merchandise } from 'src/api/merchandises-api/merchandises-api.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { ValidatorService } from 'src/app/module/common-parts/input-table/validator.service'
import { ActionTargetInfo, GridActionButton } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { MerchandiseEditDialogComponent } from '../components/merchandise-edit-dialog/merchandise-edit-dialog.component'

@Component({
  selector: 'app-ura-merchandise-list',
  templateUrl: './ura-merchandise-list.component.html',
  styleUrls: ['./ura-merchandise-list.component.scss']
})
export class UraMerchandiseListComponent implements OnInit {
  @ViewChild(MerchandiseEditDialogComponent)
  private dialogComp: MerchandiseEditDialogComponent
  title = '商品一覧'
  data: Merchandise[] = []
  columns: Header[] = [
    {
      key: 'merchandiseCd',
      label: '商品CD',
      sort: true,
    },
    {
      key: 'merchandiseName',
      label: '商品名',
      sort: true,
    },
    {
      key: 'createdDatetime',
      label: '作成日',
      sort: true,
    },
    {
      key: 'updatedDatetime',
      label: '更新日',
      sort: true
    },
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '新規登録',
      actionCd: 'create',
      notRowAction: true
    },
    {
      label: '確認',
      actionCd: 'view',
    },
    {
      label: '削除',
      actionCd: 'remove',
    },
  ]
  loading = true
  sending = false

  constructor(
    private messageService: MessageService,
    private validatorService: ValidatorService,
    private merchandisesService: MerchandisesApiService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
    this.getMerchandiseList()
  }
  async getMerchandiseList(): Promise<void> {
    this.data = []
    this.loading = true
    // const ret = await this.merchandisesService.SearchList().toPromise()
    const ret$ = this.merchandisesService.SearchList()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }

    const data = ret.data
    data.forEach((item) => {
      if (item.createdDatetime) {
        item.createdDatetime =
          this.validatorService.momentFormatDate(
            item.createdDatetime,
            'YYYY/MM/DD'
          )
      }
      if (item.updatedDatetime) {
        item.updatedDatetime =
          this.validatorService.momentFormatDate(
            item.updatedDatetime,
            'YYYY/MM/DD'
          )
      }
      item.actionCds = ['view', 'remove']
    })
    this.data = data
    this.loading = false
  }

  actionCommit(event: ActionTargetInfo): void {
    if (event.actionCd === 'remove') {
      this.confirmService.confirm(`本当に削除しますか？`, '', () => {
        this.removeMerchandises(event.rows)
      })
    }
    if (event.actionCd === 'create') {
      this.openCreateDialog()
    }
    if (event.actionCd === 'view') {
      this.openUpdateDialog(event.rows[0].merchandiseCd)
    }
  }

  async removeMerchandises(rows: Merchandise[]): Promise<void>{
    const merchandiseCds: string[] = []
    rows.forEach(row => {
      merchandiseCds.push(row.merchandiseCd)
    })
    if (this.sending) { return }
    this.sending = true
    // const ret = await this.merchandisesService.RemoveMerchandises(merchandiseCds).toPromise()
    const ret$ = this.merchandisesService.RemoveMerchandises(merchandiseCds)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00013)
    this.getMerchandiseList()
  }

  openUpdateDialog(merchandiseCd: string): void{
    this.dialogComp.openUpdateDialog(merchandiseCd)
  }

  openCreateDialog(): void{
    this.dialogComp.openCreateDialog()
  }

  addMerchandise(addMerchandise: Merchandise): void {
    this.data.unshift(addMerchandise)
  }

  updateMerchandise(updateMerchandise: Merchandise): void {
    this.data.forEach(row => {
      if (row.merchandiseCd === updateMerchandise.merchandiseCd) {
        row.merchandiseName = updateMerchandise.merchandiseName
      }
    })
  }
}
