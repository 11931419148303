import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { ServerGroupMacro, ServerMasterMacro } from './server-groups-api.interface'

@Injectable({
  providedIn: 'root'
})
export class ServerGroupsApiService {
  baseURL = environment.backendMacroBaseUrl
  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  /**
   * 登録済サーバー分け情報取得
   * @param systemCd システムCD(今はfrimo_core_product一択)
   * @param targetCd ターゲットCD(今はサプライヤーCDを使っている)
   * @returns 実行結果
   */
  GetMyServerGroups(systemCd: string, targetCd: string): Observable<ResultInfo<ServerGroupMacro>> {
    const url = `${this.baseURL}api/ServerGroups/TargetSystems/${systemCd}/${targetCd}`
    return this.httpRequestService.get(url)
  }

  /**
   * サーバー分け登録
   * @param payload サーバー分け情報
   * @returns 実行結果
   */
  RegistServerGroups(payload: ServerGroupMacro[]): Observable<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/ServerGroups`
    return this.httpRequestService.post(url, payload)
  }

  /**
   * サーバーマスター取得
   * @returns 実行結果
   */
  GetServerMasters(): Observable<ResultInfo<ServerMasterMacro>> {
    const url = `${this.baseURL}api/ServerGroups/ServerMasters`
    return this.httpRequestService.get(url)
  }
}
