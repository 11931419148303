import { Component, Input, OnInit } from '@angular/core'
import { progressItemMaster } from './progress-item-list'
import { Step } from './signup-step-progress.interface'

@Component({
  selector: 'app-signup-step-progress',
  templateUrl: './signup-step-progress.component.html',
  styleUrls: ['./signup-step-progress.component.scss']
})
export class SignupStepProgressComponent implements OnInit {
  @Input() stepItemKeys: string[] = []
  @Input() thisStepKey: string
  @Input() steps: Step[] = []
  @Input() stepIndex = 1
  @Input() title: string
  constructor() { }

  ngOnInit(): void {
    let count = 0
    // this.stepIndex = this.stepItemKeys.findIndex(x => x === this.thisStepKey)
    this.stepItemKeys.forEach(item => {
      const master = progressItemMaster.find(x => x.key === item)
      const add: Step = {
        index: count,
        key: item,
        label: master?.label,
        icon: master?.icon
      }
      this.steps.push(add)
      count ++
    })
  }

}
