import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { AutoMap } from 'src/app/common/common'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { InputOrganization } from 'src/app/module/myidea-parts/feature-organization.interface'
import { FeatureOrganizationService } from 'src/app/module/myidea-parts/feature-organization.service'

@Component({
  selector: 'app-ura-search-relation-corporate',
  templateUrl: './ura-search-relation-corporate.component.html'
})
export class UraSearchRelationCorporateComponent implements OnInit {
  title = '企業情報検索'
  organizations: BackendOrganizationCorporateV2[] = []
  selectedOrganization: BackendOrganizationCorporateV2
  loading = false
  organizationCd: string
  searchKey: string
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      actionFunction: () => {
        this.pageBack()
      },
      textAlign: FooterButtonTextAlign.LEFT
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.submit()
      }
    }
  ]


  constructor(
    private messageService: MessageService,
    private router: Router,
    private featureOrganizationService: FeatureOrganizationService,
    private activatedRoute: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCd = params.get('organizationCd')
    })
    const service = this.featureOrganizationService.getInputValues()
    this.searchKey = service?.organizationName
  }

  // 検索開始
  searchStart(): void {
    this.loading = true
    this.organizations = []
  }

  // 検索結果
  async setGridData(data: BackendOrganizationCorporateV2[]): Promise<void> {
    this.loading = false
    // BEに未登録の法人に絞る
    this.organizations = data.filter(x => !x.organizationCd)
  }

  // 会社選択時処理
  selectCompany(organization: BackendOrganizationCorporateV2): void {
    this.selectedOrganization = organization
  }

  submit(): void {
    if (!this.selectedOrganization) {
      this.messageService.templeteMessage(messageList.M00026)
    }
    const newModel = AutoMap<InputOrganization>(this.selectedOrganization)
    this.featureOrganizationService.setInputValues(newModel)
    this.router.navigate([`/ura-edit-company-profile/${this.organizationCd}`])
  }

  pageBack(): void {
    this.router.navigate([`/ura-company-check-list`])
  }
}
