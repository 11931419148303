<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <!-- ヘッダー -->
  <app-search-conditions-area (search)="search()" >
    <div class="items-text-attention-coment">*いずれか1項目の入力必須</div>
    <app-input-selectbox
      label="サービス"
      [selectList]="serviceTagList"
      (selectOption)="changeValue('serviceTagCd', $event)"
    ></app-input-selectbox>
    <app-input-selectbox
      label="小売"
      [selectList]="retailTagList"
      (selectOption)="changeRetail($event)"
    ></app-input-selectbox>
    <app-input-selectbox
      label="業態"
      [selectList]="bussinesCategoryTagList"
      (selectOption)="changeValue('bussinesCategoryTagCd', $event)"
    ></app-input-selectbox>
    <app-search-group-items label="契約会社名 *">
      <app-input-search-company
        notHitMessage=""
        (changeSelect)="changeValue('contractCompanyOrganizationCd', $event?.organizationCd)"
      ></app-input-search-company>
    </app-search-group-items>
    <app-input-text
      label="利用拠点名(メモ) *"
      (changeValue)="changeValue('actuateName', $event)"
    ></app-input-text>
    <app-input-product-inquiry-cd
      label="プロダクトコード *"
      (changeInput)="changeProductInquiryCd($event)"
    ></app-input-product-inquiry-cd>
    <app-input-text
      label="サプライヤーコード(4桁) *"
      [maxLength]="4"
      (changeValue)="changeValue('supplierCd', $event)"
    ></app-input-text>
  </app-search-conditions-area>
  <div class="result-area">
    <app-service-map-main
      [loading]="loading"
      [serviceMap]="serviceMap"
    >
    </app-service-map-main>
  </div>
</app-main-contents>

