import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AccountChangedComponent } from './account-changed/account-changed.component'
import { CompanyEditV2Component } from './company-edit/company-edit.component'
import { CompleteCreateAccountComponent } from './complete-create-account/complete-create-account.component'
import { CreateCompanyCustomerComponent } from './create-company-customer/create-company-customer.component'
import { CustomerProfileComponent } from './customer-profile/customer-profile.component'
import { EasyAuthLoginRouterOutletComponent } from './easy-auth-login-router-outlet/easy-auth-login-router-outlet.component'
import { EasyAuthLoginComponent } from './easy-auth-login/easy-auth-login.component'
import { InvitationEditComponent } from './invitation-edit/invitation-edit.component'
import { InvitationReceiveListComponent } from './invitation-receive-list/invitation-receive-list.component'
import { InvitationSendListComponent } from './invitation-send-list/invitation-send-list.component'
import { InvoiceContractConfirmListComponent } from './invoice-contract-confirm-list/invoice-contract-confirm-list.component'
import { InvoiceContractListComponent } from './invoice-contract-list/invoice-contract-list.component'
import { MessageBoxComponent } from './message-box/message-box.component'
import { MyideaTopComponent } from './myidea-top/myidea-top.component'
import { NicknameChangeComponent } from './nickname-change/nickname-change.component'
import { OrganizationComponent } from './organization/organization.component'
import { PasswordChangeComponent } from './password-change/password-change.component'
import { RegistrationAccountComponent } from './registration-account/registration-account.component'
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { ServiceMapComponent } from './service-map/service-map.component'
import { ShimuraGarageMigrationTopComponent } from './micomel-migration-top/micomel-migration-top.component'
import { ShimuraGarageMigrationPolicyComponent } from './micomel-migration-policy/micomel-migration-policy.component'
import { ShimuraGarageMigrationLinkComponent } from './shimura-garage-migration/micomel-migration-link/micomel-migration-top.component'
import { LogoutComponent } from './logout/logout.component'
import { ExchangedNameCardListComponent } from './name-card/exchanged-name-card-list/exchanged-name-card-list.component'
import { ExchangeNameCardComponent } from './name-card/exchange-name-card/exchange-name-card.component'
import { MyNameCardListComponent } from './name-card/my-name-card-list/my-name-card-list.component'
import { ServiceUserAuthorityComponent } from './service-user-authority/service-user-authority.component'
import { ShimuraGarageAppSettingComponent } from './extra-views/shimura-garage-migration/shimura-garage-app-setting/shimura-garage-app-setting.component'
import { ShimuraGarageMigrationLinkEasyAuthComponent } from './shimura-garage-migration/micomel-migration-link/shimura-garage-migration-easy-auth.component'
import { ShimuraGarageEasyAuthLoginComponent } from './shimura-garage-migration/shimura-garage-easy-auth-login/easy-auth-login.component'

const routes: Routes = [
  {
    path: '',
    component: MyideaTopComponent,
    pathMatch: 'full'
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'auth-login/:authenticationUriCd',
    data: {
      isNoLogin: true
    },
    component: EasyAuthLoginComponent
  },
  {
    path: 'auth-login/:authenticationUriCd/:nextPagePath',
    data: {
      isNoLogin: true
    },
    component: EasyAuthLoginComponent
  },
  {
    path: 'shimura-garage-auth-login/:authenticationUriCd',
    data: {
      isNoLogin: true
    },
    component: ShimuraGarageEasyAuthLoginComponent
  },
  {
    path: 'regist-account/:customerCd',
    data: {
      isNoLogin: true
    },
    component: RegistrationAccountComponent
  },
  {
    path: 'regist-account/:customerCd/:nextPagePath',
    data: {
      isNoLogin: true
    },
    component: RegistrationAccountComponent
  },
  {
    path: 'regist-account',
    data: {
      isNoLogin: true
    },
    component: CompleteCreateAccountComponent
  },
  {
    path: 'organization-map',
    component: OrganizationComponent
  },
  {
    path: 'account-change',
    component: AccountChangedComponent
  },
  {
    path: 'create-company-customer/:companyOrganizationCd',
    component: CreateCompanyCustomerComponent
  },
  {
    path: 'customer-profile/:customerCd',
    component: CustomerProfileComponent
  },
  {
    path: 'companyinfo-edit',
    component: CompanyEditV2Component
  },
  // 招待された履歴
  {
    path: 'invitation-receive-list',
    component: InvitationReceiveListComponent
  },
  // 招待送信履歴
  {
    path: 'invitation-send-list',
    component: InvitationSendListComponent
  },
  // 新規招待(スーパーユーザー招待)
  {
    path: 'invitation-edit',
    component: InvitationEditComponent
  },
  {
    path: 'message-box',
    component: MessageBoxComponent
  },
  {
    path: 'service-map',
    component: ServiceMapComponent
  },
  {
    path: 'change-login-id',
    component: NicknameChangeComponent
  },
  {
    path: 'change-password',
    component: PasswordChangeComponent
  },
  // パスワードリセット　アカウントID入力
  {
    path: 'forget-password',
    data: {
      isNoLogin: true
    },
    component: RequestResetPasswordComponent
  },
  {
    path: 'reset-password/:authenticationUriCd',
    data: {
      isNoLogin: true
    },
    component: EasyAuthLoginRouterOutletComponent,
    children: [
      {
        path: '',
        data: {
          isNoLogin: true
        },
        component: ResetPasswordComponent
      }
    ]
  },
  // インボイス対応
  {
    path: 'invoice-contract-confirm-list',
    component: InvoiceContractConfirmListComponent
  },
  {
    path: 'invoice-contract-list',
    component: InvoiceContractListComponent
  },
  // ミコメル・しまむら
  {
    path: 'shimura-garage-migration-estimate/:companyOrganizationId/:parentContractId',
    component: ShimuraGarageMigrationTopComponent
  },
  {
    path: 'shimura-garage-migration-estimate-link/:companyOrganizationId/:parentContractId',
    component: ShimuraGarageMigrationLinkComponent
  },
  {
    path: 'shimura-garage-migration-estimate-link-easy-auth/:companyOrganizationId/:parentContractId',
    component: ShimuraGarageMigrationLinkEasyAuthComponent
  },
  {
    path: 'shimura-garage-migration-policy/:companyOrganizationId/:parentContractId',
    component: ShimuraGarageMigrationPolicyComponent
  },
  {
    path: 'exchange-name-card',
    component: ExchangeNameCardComponent
  },
  {
    path: 'exchanged-name-card-list',
    component: ExchangedNameCardListComponent
  },
  {
    path: 'name-card',
    component: MyNameCardListComponent
  },
  // 利用者権限管理画面 (Garageから利用中)
  {
    path: 'service-user-authority/:topActuateId',
    component: ServiceUserAuthorityComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyideaV2RoutingModule {}
