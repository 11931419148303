import { Injectable } from '@angular/core'
import { Customer } from 'src/api/customers-api/customers-api.interface'
import { DeepCopy } from 'src/app/common/common'

@Injectable({
  providedIn: 'root',
})
export class UraNewCompanyCustomerService {

  constructor() { }
  private customerInput: Customer

  getInputValues(): Customer {
    return this.customerInput
  }

  setInputValues(value?: Customer): void {
    if (value) {
      this.customerInput = DeepCopy(value)
    }
    else {
      this.clearValues()
    }
  }

  clearValues(): void {
    this.customerInput = null
  }
}
