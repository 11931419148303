<div class="l-main__contents">
  <app-progress *ngIf="loading"></app-progress>
  <ul class="c-feature">
    <li *ngIf="menu?.length" class="c-feature__unit">
      <ng-container
        *ngTemplateOutlet="
          menusTemplate;
          context: {
            item: menu[1]
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          menusTemplate;
          context: {
            item: menu[2]
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          menusTemplate;
          context: {
            item: menu[3]
          }
        "
      ></ng-container>
    </li>
    <li class="c-feature__unit">
      <ng-container
        *ngTemplateOutlet="
          menusTemplate;
          context: {
            item: menu[4]
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          menusTemplate;
          context: {
            item: menu[5]
          }
        "
      ></ng-container>
    </li>
    <li class="c-feature__unit">
      <ng-container
        *ngTemplateOutlet="
          menusTemplate;
          context: {
            item: menu[6]
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          menusTemplate;
          context: {
            item: menu[7]
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          menusTemplate;
          context: {
            item: menu[8]
          }
        "
      ></ng-container>
      <ng-container
      *ngTemplateOutlet="
        menusTemplate;
        context: {
          item: menu[9]
        }
      "
    ></ng-container>
    </li>
  </ul>
</div>

<ng-template #menusTemplate let-item="item">
  <h2 class="c-feature__title">
    <i [class]="'c-icon-' + item.icon"></i>{{ item.label }}
  </h2>
  <ul class="c-feature__list__ura">
    <li *ngFor="let child of item.children" class="c-feature__item__ura">
      <a *ngIf="!child?.externalUrl;else externalBlock"  [routerLink]="child.path" [class.link-disable]="child.disabled" style="cursor: pointer">
        <span>{{ child.label }}</span>
        <i class="c-icon-arrow-s-right"></i
      ></a>
      <ng-template #externalBlock>
        <a *ngIf="checkExternalUrlAuth(child);else extraUrlNoAuth" [href]="externalUrl(child) + child.path" style="cursor: pointer">
          <span>{{ child.label }}</span>
          <i class="c-icon-arrow-s-right"></i
        ></a>
        <ng-template #extraUrlNoAuth>
          <a  [class.link-disable]="!checkExternalUrlAuth(child)" >
            <span>{{ child.label }}</span>
          </a>
        </ng-template>
      </ng-template>
    </li>
  </ul>
</ng-template>
