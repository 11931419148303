import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { InvoiceMigrationContractUi, TmpInvoiceTargetRelation } from './invoice-migration-api.interface'

@Injectable({
  providedIn: 'root'
})
export class InvoiceMigrationApiService {
  baseURL = environment.myideaMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  // 契約ステータス一覧取得
  async getContractStatus(contractorCustomerCd?: string): Promise<ResultInfo<InvoiceMigrationContractUi>> {
    let url = `${this.baseURL}api/invoiceMigration/contractStatus`
    if (contractorCustomerCd) {
      url = `${url}?contractorCustomerCd=${contractorCustomerCd}`
    }
    const ret$ = this.httpRequestService.get<InvoiceMigrationContractUi>(url)
    return await lastValueFrom(ret$)
  }

  // 承認しメール送信
  async sendMailforContractor(contractCds: string[]): Promise<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/invoiceMigration/sendMailforContractor`
    const ret$ = this.httpRequestService.post<boolean>(url, contractCds)
    return await lastValueFrom(ret$)
  }

  // 請求先用 請求明細一覧
  async getInvoiceContractStatus(payerCustomerCd: string): Promise<ResultInfo<InvoiceMigrationContractUi>> {
    const url = `${this.baseURL}api/invoiceMigration/invoiceContractStatus?payerCustomerCd=${payerCustomerCd}`
    const ret$ = this.httpRequestService.get<InvoiceMigrationContractUi>(url)
    return await lastValueFrom(ret$)
  }

  // 承認しメール送信
  async approval(contractCds: string[]): Promise<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/invoiceMigration/approval`
    const ret$ = this.httpRequestService.post<boolean>(url, contractCds)
    return await lastValueFrom(ret$)
  }
  
  // 請求担当者が請求明細確認画面を見た時、確認日付更新
  async confirm(contractCds: string[]): Promise<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/invoiceMigration/confirm`
    const ret$ = this.httpRequestService.post<boolean>(url, contractCds)
    return await lastValueFrom(ret$)
  }

  // 請求先設定更新しメール送信
  async updateContractInvoiceRelations(invoiceTargetCd: string, contractCds: string[]): Promise<ResultInfo<any>> {
    const url = `${this.baseURL}api/invoiceMigration/updateInvoiceRelation/${invoiceTargetCd}`
    const ret$ = this.httpRequestService.put<any>(url, contractCds)
    return await lastValueFrom(ret$)
  }
}
