import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { CompanyOrganization } from 'src/api/organization-company-api/organization-company-api.interface'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { BackendOrganizationV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { AutoMap } from 'src/app/common/common'

@Component({
  selector: 'app-input-search-company',
  templateUrl: './input-search-company.component.html',
  styleUrls: ['./input-search-company.component.scss']
})
export class InputSearchCompanyComponent implements OnInit {
  @Input() selected: string = null
  @Output() changeSelect = new EventEmitter<CompanyOrganization>()
  @Input() displayOnly = false
  organizationList: CompanyOrganization[]
  searchKeyword = ''
  showSearchCompanyResult = false
  selectedOrganization: CompanyOrganization // 選択中の組織
  loading = false
  timer = null

  constructor(
    private organizationV2Service: OrganizationV2Service,
    private organizationCompanyApiService: OrganizationCompanyApiService,
  ) { }

  ngOnInit(): void {
    this.searchKeyword = ''
    if (this.selected) {
      this.getSelectedCompanyInfo()
    }
  }

  // 入力モードに戻る
  backInputMode(): void {
    this.selected = ''
    this.selectedOrganization = null
    this.changeSelect.emit(this.selectedOrganization)
  }

  // 会社一覧検索
  async searchCompany(): Promise<void> {
    // 組織のlike検索
    if (this.searchKeyword?.length >= 2) {
      clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        this.organizationList = []
        this.loading = true
        const ret = await this.organizationCompanyApiService.LikeSearch({ organizationName: this.searchKeyword })
        if (ret?.resultCode !== 0) { return }
        this.loading = false
        this.organizationList = ret.data.slice(0, 4)
        this.showSearchCompanyResult = true
      }, 500)
    }
    else {
      this.organizationList = []
      this.showSearchCompanyResult = false
    }
  }

  // 初期表示の為に選択済の会社情報を取得
  getSelectedCompanyInfo(): void {
    this.organizationV2Service.OrganizationInfo(this.selected)
      .subscribe((ret: ResultInfo<BackendOrganizationV2>) => {
        if (ret?.resultCode !== 0) { return }
        this.selectedOrganization = AutoMap(ret.data[0])
      })
  }

  // 会社を選択
  selectOrganization(organization: CompanyOrganization): void {
    this.selectedOrganization = organization
    this.selected = organization.organizationCd
    this.changeSelect.emit(this.selectedOrganization)
  }
}
