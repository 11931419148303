import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { lastValueFrom } from 'rxjs'
import { ContractStatus } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { InvoiceTarget, InvoiceProduct } from 'src/api/invoice-api/invoice-api.interface'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { BackendOrganizationV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { AutoMap } from 'src/app/common/common'
import { MessageService } from 'src/app/components/message/message.service'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { ActuateInvocieInfoUi, OrganizationCustomerUi } from './setting-invoice-dialog.interface'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'

@Component({
  selector: 'app-setting-invoice-dialog',
  templateUrl: './setting-invoice-dialog.component.html',
  styleUrls: ['./setting-invoice-dialog.component.scss']
})
export class SettingInvoiceDialogComponent implements OnInit {
  @Output() setInvoice = new EventEmitter<string[]>() // ContractStatusの対象行CDを渡す
  selectRows: ContractStatus[] = []
  @ViewChild('settingInvoiceDialog', { static: true })
  settingInvoiceDialog: IgxDialogComponent
  editMode = false

  fromActuateCd: string
  sending = false
  customerList: OrganizationCustomerUi[]
  selectedCustomer: OrganizationCustomerUi
  invoiceList: InvoiceTarget[]
  productList: InvoiceProduct[]
  selectedInvoice: InvoiceTarget
  invoiceViewFlag = false
  organizationDispName: string
  actuateInvocieInfoList: ActuateInvocieInfoUi[] = [] // 既存の請求先設定
  canUpdateFlg = true

  companyOrganizationCd: string

  rowSelection = RowSelection.NONE

  loading = true

  columns: Header[] = [
    {
      key: 'actuateCd',
      label: 'CD',
      sort: true,
      hidden: true
    },
    {
      key: 'serviceName',
      label: 'プロダクト名',
      sort: true
    },
    {
      key: 'invoiceCompanyName',
      label: '請求先会社',
      width: '15%'
    },
    {
      key: 'invoiceSectionName',
      label: '請求先部署',
      sort: true,
      width: '15%'
    },
    {
      key: 'customerName',
      label: '請求先顧客',
      sort: true,
      width: '15%'
    },
    {
      key: 'suppliBillingCustomerId',
      label: '楽楽明細ID',
      sort: true,
      width: '10%'
    },
    {
      key: 'title',
      label: '請求区分',
      sort: true,
      width: '15%'
    },
    {
      key: 'editableText',
      label: '更新可否',
      sort: true,
      width: '10%'
    }
  ]
  cellClasses = {}

  constructor(
    private messageService: MessageService,
    private organizationV2Service: OrganizationV2Service,
    private invoiceService: InvoiceService,
    private organizationCompanyApiService: OrganizationCompanyApiService
  ) { }

  ngOnInit(): void {
  }
  open(selectRows: ContractStatus[]): void {
    this.selectRows = selectRows
    this.getActuateInvocieInfoList()
    this.editMode = false
    this.settingInvoiceDialog.open()
  }


  updateTargetCompany(company: BackendOrganizationV2): void {
    if (company?.organizationCd) {
      this.clearCustomer()
      this.companyOrganizationCd = company.organizationCd
      this.getCustomerInfo(company.organizationCd)
    }
  }

  // 顧客リスト取得
  async getCustomerInfo(companyOrganizationCd: string): Promise<void> {
    const ret$ = this.organizationV2Service.GetOrganizationCustomerList(companyOrganizationCd, true)
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.customerList = []
    let count = 0
    ret.data.forEach(data => {
      const copy = AutoMap<OrganizationCustomerUi>(data)
      copy.index = count
      count++
      this.customerList.push(copy)
    })
  }


  // 請求先設定更新
  async conectInvoice(): Promise<void> {
    if (!this.selectedCustomer) {
      this.messageService.warning(`対象の顧客が未選択です`)
      return
    }
    if (!this.selectedInvoice) {
      this.messageService.warning(`対象の請求先が未選択です`)
      return
    }
    const ret = await this.invoiceService.updateContractInvoiceRelations(
        this.selectedInvoice.invoiceTargetCd,
        this.contractCds)
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('請求先を更新しました')

    const cds = []
    this.selectRows.forEach(row => {
      cds.push(row.applicationContractStatusCd)
    })
    this.setInvoice.emit(cds)
    this.settingInvoiceDialog.close()
  }

  changeCustomer(event): void {
    if (!event.target.value) {
      this.clearCustomer()
      return
    }
    this.selectedCustomer = this.customerList.find(x => x.index === Number(event.target.value))
    this.getInvoiceList()
  }

  clearCustomer(): void {
    this.selectedCustomer = null
    this.customerList = []
    this.invoiceList = []
    this.selectedInvoice = null
    this.invoiceViewFlag = false
  }

  // 会社に紐づく請求先一覧取得
  async getInvoiceList(): Promise<void> {
    // 部署から会社CDを取得
    const companyRet = await this.organizationCompanyApiService.GetProfileBySectionCd(this.selectedCustomer.organizationCd)
    if (companyRet?.resultCode !== 0) { return }

    const ret = await this.invoiceService.GetInvoiceTargets(companyRet.data.organizationCd, this.selectedCustomer.customerCd)
    if (ret?.resultCode !== 0) { return }
    this.invoiceList = ret.data
    this.invoiceViewFlag = true
  }

  // 設定済請求先情報の取得
  async getActuateInvocieInfoList(): Promise<void> {
    this.actuateInvocieInfoList = []
    this.loading = true
    // const ret = await this.invoiceService.getActuateInvocieInfoList(this.contractCds).toPromise()
    const ret$ = this.invoiceService.getActuateInvocieInfoList(this.contractCds)
    const ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode !== 0) { return }

    ret.data.forEach(data => {
      const newValue = AutoMap<ActuateInvocieInfoUi>(data)
      newValue.editableText = newValue.editable ? '可' : '不可'
      this.actuateInvocieInfoList.push(newValue)
    })
  }

  get showEditBtn(): boolean {
    return this.actuateInvocieInfoList.find(x => x.editable) ? true : false
  }

  get contractCds(): string[] {
    const ret = []
    this.selectRows.forEach(row => {
      ret.push(row.contractCd)
    })
    return ret
  }

  // 請求先選択変更
  changeInvoiceTarget(event): void {
    if (!event.target.value) {
      this.selectedInvoice = null
      return
    }
    this.invoiceList.forEach(list => {
      if (list.invoiceTargetCd === event.target.value) {
        this.selectedInvoice = list
      }
    })
  }
}
