<app-main-contents>
  <app-sending *ngIf="sending"></app-sending>
  <div class="c-step">
    <div class="c-step__col c-step__col--head">
      <h1 class="c-step__head c-texts--head1">ログイン情報入力</h1>
    </div>
  </div>
  <p class="c-texts--attention h-margin--b16">＊は入力必須項目です</p>
  <app-input-table [formItems]="formItems"> </app-input-table>
  <div class="c-btns">
    <div class="c-btns__item">
      <a (click)="submit()" class="c-btn c-btn--blue" style="cursor: pointer"
        ><span>次へ</span><i class="c-icon__arrow_s_right"></i
      ></a>
    </div>
  </div>
</app-main-contents>
