import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { AuthService } from 'src/app/components/isbeauth/auth.service'

@Component({
  selector: 'app-ura-organization-customer-list',
  templateUrl: './ura-organization-customer-list.component.html',
  styleUrls: ['./ura-organization-customer-list.component.scss']
})
export class UraOrganizationCustomerListComponent implements OnInit {
  companyOrganizationCd: string
  targetOrganizationCd: string
  selectedOrganizationName: string
  disp = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.companyOrganizationCd = params.get('companyOrganizationCd')
      this.targetOrganizationCd = params.get('selectOrganizationCd')
    })
  }

  selectTargetOrganization(event: BackendOrganizationCorporateV2): void {
    // serviceに保存されている選択組織とURLの組織が異なる場合は最初の画面に戻す
    if (this.companyOrganizationCd !== event?.organizationCd) {
      this.router.navigate(['/ura-organization-tree-view'])
    }
    if (event) {
      this.disp = true
    }
    else {
      this.disp = false
    }
  }

  jumpTreePage(): void {
    this.router.navigate(['/ura-organization-tree-view'])
  }
}
