export interface ContractCompany {
  companyOrganizationCd: string
  contractCompanyName: string
  sectionOrganizationName: string
  purchaserName: string
  telephoneNumber: string
  mailAddress: string
  representativeProductName: string
  sendMailDate: string
  approvalDate: string
  migrationStatusCd: string
  migrationStatusName: string
  actionCds?: string[]
}

export interface InvitationUser {
  invitationCd: string
  invitationCompanyOrganizationCd: string
  invitationCompanyOrganizationName: string
  invitationSectionOrganizationCd: string
  invitationSectionOrganizationName: string
  invitationCustomerCd: string
  invitationCustomerName: string
  invitationTagCd: string
  invitationTargetName: string
  targetServiceName: string
  messageText?: string
  invitedDatetimeUi: string
  approvedDatetimeUi: string
  invitationStatusCd: string
  invitationStatusName: string
  actionCds?: string[]
}

export interface InvitationAction {
  invitationCd: string
  customerCd: string
  invitationActionCd: string  // 1：招待を受ける、2：拒否
  migrationFlg: string // 1：移行の場合、0：移行以外
}


export interface InvitedUser {
  invitationCd: string,
  targetCompanyOrganizationCd: string,
  targetCompanyOrganizationName: string,
  targetSectionOrganizationCd: string,
  targetSectionOrganizationName: string,
  targetCustomerCd: string,
  targetCustomerName: string,
  invitationTagCd: string,
  invitationTargetName: string,
  messageText: string,
  targetAuthorityDivisionCd: string,
  targetAuthorityDivisionName: string,
  invitedDatetimeUi: string, // 招待日
  approvedDatetimeUi: string, // 承認日
  invitationStatusCd: string,
  invitationStatusName: string
  actionCds?: string[]
  targetServiceName: string
}
// お知らせ
export interface ReceiveMessage {
  senderOrganizationName: string, // まだAPIからは来ない
  messageTextCd: string,
  messageText: string,
  receiveDatetimeUi: string,
  noticeStatusCd: string,
  noticeStatusName: string,
  actionCds?: string[]
}

export interface Actuate {
  actuateCd: string,
  contractCd: string,
  customerName: string,
  customerCd: string,
  organizationName: string,
  organizationSectionName: string,
  productInquiryCd: string,
  productName: string,
  actuateStartDatetime: string,
  actuateStartDatetimeUi: string,
  useStartDatetime: string,
  useStartDatetimeUi: string,
  status: string,
  statusName: string,
  determinationFlg: boolean,
  determinationFlgUI: string,
  actionCds?: string[]
}
export interface ActuateUserList {
  actuateCd: string
  contractOrganizationName: string
  productName: string
  customerName: string
  organizationCd: string
  organizationName: string
  organizationSectionName: string
  mailAddress: string
}


export interface ServiceUser {
  companyOrganizationCd: string,
  companyOrganizationName: string,
  sectionOrganizationCd: string,
  sectionOrganizationName: string,
  customerName: string,
  productInquiryCd: string
  productName: string,
  authorityDivisionCd: string,
  authorityDivisitionName: string,
  actionCds?: string[]
}

export interface InvitationTarget {
  targetServices: TargetService[]
  targetCustomers: TargetCustomer[]
}

export interface TargetCustomer {
  targetOrganizationCd: string
  targetCustomerCd?: string
  mailAddress: string
  familyName: string
  firstName: string
}

export interface TargetService {
  serviceCd: string
  invitaitonTargetCd: string
  authorityDivisionCd: string
}

export interface TargetServiceInfo {
  serviceCd: string
  serviceName: string
  invitationTargetDetails: InvitationTargetDetail[]
}
export interface InvitationTargetDetail {
  invitaitonTargetCd: string // 招待時に使うactuateCd AccountBox
  invitationTargetName: string
  invitationAuthorityDivisions: InvitationAuthorityDivision[]
  invitationServiceTargetCd: string // TOP階層のactuateCd FRIMOや出荷Pack
  invitationServiceProductInquiryCd: string // FRIMOや出荷Packのプロダクトコード
  selectedAuthorityDivisionCd?: string // UI処理用
}

export interface InvitationAuthorityDivision {
  authorityDivisionCd: string
  authorityDivisionName: string
}

export interface UpdateUseStartDateRequest {
  actuateCd: string
  useStartDatetime: string
  organizationName: string
  customerCd: string
  customerName: string
  productName: string
}
export interface UpdateActuateStartDateRequest {
  actuateCd: string
  contractCd: string
  organizationName: string
  organizationSectionName: string
  customerCd: string
  customerName: string
  productName: string
  actuateStartDatetime: string
  useStartDatetime: string
}

export interface SettledUserActuate {
  serviceActuateCd: string
  serviceDetailName: string
  determinationFlg: boolean // true：稼働確定済、false：稼働確定未
}


