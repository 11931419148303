<!-- <app-shimura-garage-app-setting #settingDialog></app-shimura-garage-app-setting> -->

<app-main-contents>
  <app-breadcrumbs *ngIf="!ura" [label2]="title"></app-breadcrumbs>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <h1 *ngIf="hasContracts">{{ garageName }} 様分</h1>


  <div *ngIf="fileUrl" class="c-pdf h-margin--t16">
    <iframe [src]="fileUrl"> </iframe>
  </div>

  <app-under-btn-disp *ngIf="!ura" [actionButtons]="actionButtons"></app-under-btn-disp>
</app-main-contents>
