<div class="back-area">
  <p class="text-left" * ngIf="targetOrganizationCustomer?.organizationFullName">
    {{ targetOrganizationCustomer?.organizationFullName }}<br />
    {{ targetOrganizationCustomer?.familyName }}
    {{ targetOrganizationCustomer?.firstName }}
  </p>
  <p>{{ indexNumber }}番目</p>
  <ng-container *ngIf="retail?.tagText"
    >
    <p>{{ retail?.tagText }}</p>
  </ng-container>
  <ng-container *ngIf="businessCategory?.tagText"
    >-
    <p>{{ businessCategory?.tagText }}</p>
  </ng-container>
  <ng-container *ngIf="service?.tagText"
    >-
    <p>{{ service?.tagText }}</p>
  </ng-container>
</div>
