import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { ContractCompany, ContractStatusSmall } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { FrimoMigrationsService } from 'src/api/frimo-migrations-api/frimo-migrations-api.service'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { SelectedOrganizationViewService } from 'src/app/module/myidea-parts/selected-organization-view.service'
import { CreateQuotationService } from '../service/create-quotation.service'

@Component({
  selector: 'app-ura-conpany-contracted-organization-list',
  templateUrl: './ura-conpany-contracted-organization-list.component.html',
  styleUrls: ['./ura-conpany-contracted-organization-list.component.scss']
})
export class UraConpanyContractedOrganizationListComponent implements OnInit {
  title = 'サービスの追加・変更'
  selectedTargetOrg: BackendOrganizationCorporateV2
  data: ContractStatusSmall[] = []
  columns: Header[] = [
    {
      key: 'companyOrganizationCd',
      label: 'companyOrganizationCd',
      hidden: true
    },
    {
      key: 'customerCd',
      label: 'customerCd',
      hidden: true
    },
    {
      key: 'contractSectionOrganizationCd',
      label: '',
      hidden: true
    },
    {
      key: 'sectionOrganizationName',
      label: '契約組織',
    },
    {
      key: 'purchaserName',
      label: '契約者',
    },
    {
      key: 'representativeProductName',
      label: '代表プロダクト',
    },
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '追加/変更',
      actionCd: 'actionCd1',
    },
    {
      label: '解約(ｻｰﾋﾞｽﾏｯﾌﾟ)',
      actionCd: 'actionCd2',
      nextPage: ['/ura-cancel-contract-service-map/{companyOrganizationCd}/{contractSectionOrganizationCd}']
    },
    {
      label: '解約(一覧)',
      actionCd: 'actionCd3',
      nextPage: ['/ura-contract-cancel-list/{contractSectionOrganizationCd}/{customerCd}']
    },
    {
      label: 'サービス接続',
      actionCd: 'connect',
      nextPage: ['/ura-connect-service-map/{companyOrganizationCd}/{contractSectionOrganizationCd}']
    }
  ]
  loading = true
  sendingRows: ContractCompany[]

  constructor(
    private router: Router,
    private frimoMigrationsService: FrimoMigrationsService,
    private createQuotationService: CreateQuotationService,
    private selectedOrganizationViewService: SelectedOrganizationViewService
  ) { }

  ngOnInit(): void {
    if (!this.selectedTargetOrg) { return }
    this.getContractCompanyList()
  }

  selectTargetOrganization(event: BackendOrganizationCorporateV2): void {
    this.selectedTargetOrg = event
    this.ngOnInit()
  }

  async getContractCompanyList(): Promise<void> {
    this.data = []
    this.loading = true
    // const ret = await this.frimoMigrationsService.getContractCompanyOrganizationList(this.selectedTargetOrg.organizationCd).toPromise()
    const ret$ = this.frimoMigrationsService.getContractCompanyOrganizationList(this.selectedTargetOrg.organizationCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode === 0) {
      this.data = ret.data
      this.data.forEach(item => {
        item.actionCds = ['actionCd1', 'actionCd2', 'actionCd3', 'connect']
        if (!item.contractSectionOrganizationCd) {
          item.contractSectionOrganizationCd = item.companyOrganizationCd
          item.sectionOrganizationName = item.contractCompanyName
        }
      })
      this.loading = false
    }
  }
  actionCommit(event: ActionTargetInfo): void {
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    const s = event.rows[0]
    if (action.actionCd === 'actionCd1') {
      this.createQuotationService.clearAllSessions()
      this.router.navigate([`/ura-add-contract-service-map/${s.companyOrganizationCd}/${s.contractSectionOrganizationCd}`])
    }
    const setData = {
      organizationName: s.contractCompanyName
    }
    this.selectedOrganizationViewService.setSelectedOrganizationData(setData)

  }
}
