import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { AboxActuatesUser } from 'src/api/service-user-api/service-user-api.interface'
import { ServiceUserService } from 'src/api/service-user-api/service-user-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { messageList } from 'src/app/components/message/message-data'
import { Header, RowSelection, SelectedRow } from '../../is-grid/base-grid/base-grid.component.interface'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'app-user-list-dialog',
  templateUrl: './user-list-dialog.component.html',
  styleUrls: ['./user-list-dialog.component.scss']
})
export class UserListDialogComponent implements OnInit {
  @Input() editable = false
  @Output() updateUser = new EventEmitter<AboxActuatesUser[]>()
  data: AboxActuatesUser[] = []
  @ViewChild('dialog', { static: true })
  dialog: IgxDialogComponent
  sending = false
  rowSelection = RowSelection.NONE
  loading = true
  activeDeleteButton = true
  selectedRows: AboxActuatesUser[] = []

  columns: Header[] = [
    {
      key: 'companyOrganizationCd',
      label: 'CD',
      hidden: true
    },
    {
      key: 'companyOrganizationName',
      label: '会社',
    },
    {
      key: 'sectionOrganizationName',
      label: '組織',
    },
    {
      key: 'customerName',
      label: '名前',
    },
    {
      key: 'authorityDivisitionName',
      label: '権限',
    },
  ]
  rowClickAction = false

  constructor(
    private messageService: MessageService,
    private serviceUserService: ServiceUserService
  ) { }

  ngOnInit(): void {
    if (this.editable) {
      this.rowSelection = RowSelection.MULTIPLE
    }
  }
  async open(actuateCd: string): Promise<void> {
    this.data = []
    this.selectedRows = []
    this.loading = true
    this.dialog.open()
    // const ret = await this.serviceUserService.GetAboxActuatesUserList(actuateCd).toPromise()
    const ret$ = this.serviceUserService.GetAboxActuatesUserList(actuateCd)
    let ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.updateUser.emit(this.data)
  }

  selectionChange(event: SelectedRow<AboxActuatesUser>): void {
    const selectedUsers = event.selected
    this.activeDeleteButton = false
    const allSuperUsers = this.data.filter(x => x.authorityDivisionCd.match('superuser'))
    const selectedSuperUsers = selectedUsers.filter(x => x.authorityDivisionCd.match('superuser'))

    if (allSuperUsers.length === selectedSuperUsers.length) {
      this.selectedRows = []
      this.messageService.templeteMessage(messageList.M00014)
    } else {
      this.selectedRows = selectedUsers
    }
  }

  async deleteAction(): Promise<void> {
    if (this.sending) { return }
    const req: string[] = []
    this.selectedRows.forEach(row => {
      req.push(row.serviceUserCd)
    })
    this.sending = true
    const ret = await this.serviceUserService.DeleteAboxActuatesUser(req)

    this.sending = false
    if (ret?.resultCode !== 0) { return }

    this.messageService.templeteMessage(messageList.M00013)
    this.dialog.close()

    // 残ったユーザーだけ送る
    const resultUserList = []
    this.data.forEach(row => {
      if (this.selectedRows.find(x => x.serviceUserCd !== row.serviceUserCd)) {
        resultUserList.push(row)
      }
    })
    this.updateUser.emit(resultUserList)
  }
}
