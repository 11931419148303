import { Component, OnInit } from '@angular/core'
import moment from 'moment'
import { lastValueFrom } from 'rxjs'
import { NafcoActuateSetting, UpdatMenuSwitchingDate, UpdateLabelOption } from 'src/api/nafco-frimo-api/nafco-frimo-api.interface'
import { NafcoFrimoApiService } from 'src/api/nafco-frimo-api/nafco-frimo-api.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ActionTargetInfo, GridActionButton } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { ActionCd } from '../ura-nafco-actuate-setting/ura-nafco-actuate-setting.component'

@Component({
  selector: 'app-ura-nafco-migration-actuate-setting',
  templateUrl: './ura-nafco-migration-actuate-setting.component.html',
  styleUrls: ['./ura-nafco-migration-actuate-setting.component.scss']
})
export class UraNafcoMigrationActuateSettingComponent implements OnInit {
  title = '【移行契約】ナフコ専用 サービス稼働設定'
  data: NafcoActuateSetting[] = []
  columns: Header[] = [
    {
      key: 'productActuateCd',
      label: 'productActuateCd',
      hidden: true
    },
    {
      key: 'contractCompanyOrganizationName',
      label: '契約会社',
      sort: true
    },
    {
      key: 'productMerchandiseName',
      label: 'プロダクト',
      sort: true
    },
    {
      key: 'status',
      label: 'ステータス',
      hidden: true
    },
    {
      key: 'statusUi',
      label: 'ステータス',
      sort: true
    },
    {
      key: 'productInquiryCd',
      label: 'プロダクトコード',
      sort: true,
    },
    {
      key: 'supplierCd',
      label: '仕入先コード',
      sort: true,
    },
    {
      key: 'suppliContractDetailId',
      label: 'SuppliサービスID',
      sort: true,
    },
    {
      key: 'suppliInquiryNo',
      label: '旧お問い合わせNo.',
      sort: true,
    },
    {
      key: 'frimoCd',
      label: 'FRIMOコード',
      sort: true,
    },
    {
      key: 'shipPlaceName',
      label: '出荷場所名',
      sort: true,
    },
    {
      key: 'labelMerchandiseName',
      label: 'オプション',
      sort: true,
    },
    {
      key: 'menuSwitchingDatetimeUi',
      label: 'メニュー切替日',
      sort: true,
    },
    {
      key: 'labelStartDatetimeUi',
      label: 'OP利用開始日',
      sort: true,
    },
    {
      key: 'packageNumberStartDatetimeUi',
      label: '荷物番号取込オプション利用開始日',
      sort: true,
    },

  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: 'メニュー切替日設定',
      actionCd: ActionCd.SETTING_MENU,
    },
    {
      label: 'OP利用開始日設定',
      actionCd: ActionCd.SETTING_OPTION,
    }
  ]
  loading = true
  sending = false
  showDialog = false
  selectRows: NafcoActuateSetting[] = []
  dialogMessage = ''
  inputDate: Date
  dialogActionCd = ''
  cellClasses = {
    color_red: (rowData: any, columnKey: string): boolean => {
      return columnKey === 'statusUi' && (rowData.status === '0' || rowData.status === '1')
    },
  }

  constructor(
    private nafcoFrimoApiService: NafcoFrimoApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getContractCompanyList()
  }

  async getContractCompanyList(): Promise<void> {
    this.data = []
    this.loading = true
    // const ret = await this.nafcoFrimoApiService.GetNafcoActuateSettingsForMigration().toPromise()
    const ret$ = this.nafcoFrimoApiService.GetNafcoActuateSettingsForMigration()
    let ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.data.forEach(item => {
      if (item.updatedMigrationLabelOption) {
        item.status = '9'
        item.statusUi = '9)OP契約変更済'
      }
      item.labelMerchandiseName = (item.labelMerchandiseName ?? '').replace('個口納品書＋PDラベル ', '')
      if ((item.status === '1' || item.status === '2') && item.shipPlaceName !== '-') {
        item.actionCds = [ ActionCd.SETTING_MENU ]
        if (item.labelMerchandiseCd) {
          item.actionCds.push(ActionCd.SETTING_OPTION)
        }
      }
    })
  }

  actionCommit(event: ActionTargetInfo): void {
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    if (action.actionCd === ActionCd.SETTING_MENU) {
      this.showDialog = true
      this.dialogActionCd = ActionCd.SETTING_MENU
      this.dialogMessage = 'メニュー切替日を設定します'
      this.selectRows = event.rows
      return
    }
    else if (action.actionCd === ActionCd.SETTING_OPTION) {
      this.showDialog = true
      this.dialogActionCd = ActionCd.SETTING_OPTION
      this.dialogMessage = 'ラベルオプション利用開始日を設定します'
      this.selectRows = event.rows
      return
    }
  }

  daialogCommit(): void {
    if (!this.inputDate) {
      this.messageService.templeteMessage(messageList.M00022)
      return
    }
    if (this.dialogActionCd === ActionCd.SETTING_MENU) {
      this.updateMenuDate()
    }
    else if (this.dialogActionCd === ActionCd.SETTING_OPTION) {
      this.updateLabelOption()
    }

    this.showDialog = false
  }

  async updateMenuDate(): Promise<void> {
    const payload: UpdatMenuSwitchingDate[] = []
    this.selectRows.forEach(row => {
      const rowData: UpdatMenuSwitchingDate = {
        productActuateCd: row.productActuateCd,
        menuSwitchingDatetime: moment(this.inputDate).format('YYYY-MM-DD') + 'T00:00:00.000'
      }
      payload.push(rowData)
    })
    if (this.sending) { return }
    this.sending = true
    // const ret = await this.nafcoFrimoApiService.UpdateMenuDate(payload).toPromise()
    const ret$ = this.nafcoFrimoApiService.UpdateMenuDate(payload)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00054)
    this.getContractCompanyList()
  }

  async updateLabelOption(): Promise<void> {
    const payload: UpdateLabelOption[] = []
    this.selectRows.forEach(row => {
      const rowData: UpdateLabelOption = {
        productActuateCd: row.productActuateCd,
        labelStartDatetime: moment(this.inputDate).format('YYYY-MM-DD') + 'T00:00:00.000'
      }
      payload.push(rowData)
    })
    if (this.sending) { return }
    this.sending = true
    // const ret = await this.nafcoFrimoApiService.UpdateLabelOption(payload).toPromise()
    const ret$ = this.nafcoFrimoApiService.UpdateLabelOption(payload)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00055)
    this.getContractCompanyList()
  }
}
