import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { BackendProductDatail, Product } from './products-api.interface'
import { Tag } from '../common/api-common.interface'

@Injectable({
  providedIn: 'root'
})
export class ProductsApiService {
  baseURL = environment.myideaMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 全製品一覧取得
  async GetFlatList(): Promise<ResultInfo<Product>> {
    const url = `${this.baseURL}api/product/list/flat`
    const ret$ = this.httpRequestService.get<Product>(url)
    return await lastValueFrom(ret$)
  }

  // 製品一覧取得 (Conta検索条件用)
  async GetSelecterList(): Promise<ResultInfo<BackendProductDatail>> {
    const url = `${this.baseURL}api/product/list`
    const ret$ = this.httpRequestService.get<BackendProductDatail>(url)
    return await lastValueFrom(ret$)
  }

  // 製品詳細取得
  async GetProductDetail(productCd: string): Promise<ResultInfoSingle<BackendProductDatail>> {
    const url = `${this.baseURL}api/product/${productCd}`
    const ret$ = this.httpRequestService.getResultSingle<BackendProductDatail>(url)
    return await lastValueFrom(ret$)
  }

  // タグマスタ取得
  GetTagMaster(): Observable<ResultInfo<Tag>> {
    const url = `${environment.backendMacroBaseUrl}api/Products/TagMaster`
    return this.httpRequestService.get(url)
  }

  // 製品詳細 登録
  CreateProduct(payload: BackendProductDatail): Observable<ResultInfo<BackendProductDatail>> {
    const url = `${environment.backendMacroBaseUrl}api/Products/Create`
    return this.httpRequestService.post(url, payload)
  }

  // 製品詳細 更新
  UpdateProduct(payload): Observable<ResultInfo<BackendProductDatail>> {
    const url = `${environment.backendMacroBaseUrl}api/Products/Update`
    return this.httpRequestService.put(url, payload)
  }

  // 製品削除(複数)
  RemoveProducts(merchandiseCds: string[]): Observable<ResultInfo<boolean>> {
    const url = `${environment.backendMacroBaseUrl}api/Products/Delete`
    return this.httpRequestService.post(url, merchandiseCds)
  }
}
