import { Component, OnInit } from '@angular/core'
@Component({
  selector: 'app-ura-inclusion-contract',
  templateUrl: './ura-inclusion-contract.component.html',
  styleUrls: ['./ura-inclusion-contract.component.scss'],
})
export class UrainclusionContractComponent implements OnInit {
  constructor() { }
  title = '包括契約登録'
  merchandiseDivisionCd = 'comprehensive_merchandise'
  ngOnInit(): void {}
}
