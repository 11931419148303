<div class="l-main__contents">
  <div class="c-notification">
    <ul class="c-notification__list">
      <div *ngIf="personalNotificationList.length !== 0">
        <li *ngFor="let notification of personalNotificationList" class="c-notification__item">
          <a (click)="notificationLink(notification.link)" style="cursor: pointer"
            ><i class="c-icon__attention_fill"></i>
            <p>{{ notification.title }}</p>
            <p *ngIf="notification.count !== 0">
              <b>{{ notification.count }}件</b>あります。
            </p>
            <i class="c-icon__arrow_s_right"></i>
          </a>
        </li>
      </div>
    </ul>

    <ul class="c-petabo_notification_list">
      <li *ngFor="let notification of petaboNotificationList" class="c-petabo_notification_item">
        <i class="c-icon-hint"></i>
        <div class="text">
          <a
            (click)="notificationLink(notification.profiles.url)"
            style="cursor: pointer"
            ActionTransaction
            [commandCd]="viewCd + '-clickPetabo-' + notification.id"
            >{{ notification.name }}
          </a>
        </div>
      </li>
    </ul>

    <h2 class="c-notification__title" style="margin-top: 2rem"><i class="c-icon-notification"></i>重要なお知らせ</h2>

    <div *ngIf="overAllNotificationList?.length === 0" class="">
      <p>現在重要なお知らせはありません</p>
    </div>
    <ul class="c-overall_notification_list">
      <ng-container *ngIf="overAllNotificationList.length !== 0">
        <li *ngFor="let notification of overAllNotificationList" class="c-overall_notification_item">
          <a (click)="notificationLink(notification.descriptionLink)" style="cursor: pointer">
            <div class="text_item">
              <div class="date">{{ notification.releaseDatetime }}</div>
              <div class="text">{{ notification.description }}</div>
            </div>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="c-service-list">
    <h2 class="c-notification__title"><i class="c-icon-service"></i>サービス一覧</h2>
    <ul class="c-service-list__list">
      <li
        class="c-service-list__item"
        *ngFor="let launcher of launchers"
        [class.c-service-list__item--disable]="!launcher.isUser || !launcher.isRelease"
      >
        <p class="c-service-list__box">
          <a
            (click)="serviceLink(launcher)"
            ActionTransaction
            [commandCd]="viewCd + '-clickMenu-' + launcher.serviceCd | removePeriod"
            ><img [src]="getLauncherLogo(launcher)" [class.-garage]="launcher.serviceCd === 'core.p-garage'" alt=""
          /></a>
        </p>
        <div *ngIf="launcher.isUser && !launcher.isRelease" class="p-dashboard-display__description_service_top">
          <p style="text-align: center">準備中</p>
        </div>
        <!-- <p class="c-service-list__link"><a href="#">サービス紹介サイトへ</a></p> -->
      </li>
    </ul>
  </div>
</div>
