import { Component, Input, OnInit } from '@angular/core'
import { Tag } from 'src/api/common/api-common.interface'
import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'

@Component({
  selector: 'app-estimate-breadcrumbs',
  templateUrl: './estimate-breadcrumbs.component.html',
  styleUrls: ['./estimate-breadcrumbs.component.scss']
})
export class EstimateBreadcrumbsComponent implements OnInit {
  @Input() index = '0'
  @Input() targetOrganizationCustomer: OrganizationCustomer
  @Input() retail: Tag
  @Input() businessCategory: Tag
  @Input() service: Tag

  constructor() { }

  ngOnInit(): void {
  }

  get indexNumber(): number {
    return Number(this.index) + 1
  }

}
