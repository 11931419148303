<app-main-contents>
  <app-title [title]="title"></app-title>
  <div *ngIf="nationalTaxData">
    ※参考　国税庁データ<br />
    {{ nationalTaxData.organizationFormalName }} <br />
    〒 {{ nationalTaxData.postCode }} <br />
    住所： {{ nationalTaxData.prefecture }} {{ nationalTaxData.city }}
    {{ nationalTaxData.block }} {{ nationalTaxData.building }}<br />
  </div>
  <app-organization-profile-input *ngIf="formValue" [items]="items" [organization]="formValue">
  </app-organization-profile-input>
  <app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
</app-main-contents>
