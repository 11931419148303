<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-loading *ngIf="loading" [fullHeight]="true"></app-loading>
  <app-search-conditions-area
    (search)="searchContractList()"
    [dispCountView]="true"
    [countLoading]="countLoading"
    [count]="count"
    [neverCountSearch]="neverCountSearch"
  >
    <app-input-selectbox
      label="サービス"
      [selectList]="serviceTagList"
      (selectOption)="changeValue('serviceTagCd', $event)"
    ></app-input-selectbox>
    <app-input-selectbox
      label="小売"
      [selectList]="retailTagList"
      (selectOption)="changeRetail($event)"
    ></app-input-selectbox>
    <app-input-selectbox
      label="業態"
      [selectList]="bussinesCategoryTagList"
      (selectOption)="changeValue('bussinesCategoryTagCd', $event)"
    ></app-input-selectbox>
    <app-search-group-items label="会社名(契約会社・請求先)">
      <app-input-search-company
        notHitMessage=""
        (changeSelect)="
          changeValue('companyOrganizationCd', $event?.organizationCd)
        "
      ></app-input-search-company>
    </app-search-group-items>
    <app-search-group-items label="プロダクト">
      <igx-combo
        [data]="productMasterListUI"
        valueKey="chilidProductCd"
        displayKey="childProductName"
        groupKey="parentProductName"
        [(ngModel)]="values.productCds"
        (closed)="getCount()"
      >
      </igx-combo>
    </app-search-group-items>
    <app-input-text
      label="利用拠点名(メモ)"
      (changeValue)="changeValue('actuateName', $event)"
    ></app-input-text>
    <app-input-product-inquiry-cd
      label="プロダクトコード"
      (changeInput)="changeProductInquiryCd($event)"
    ></app-input-product-inquiry-cd>
    <app-input-text
      label="サプライヤーコード(4桁)"
      [maxLength]="4"
      (changeValue)="changeValue('supplierCd', $event)"
    ></app-input-text>
    <app-search-group-items label="稼働状態">
      <div class="search-group-items-panel">
        <span
          *ngFor="let data of actuateStatusList"
          class="items-panel-content"
          (click)="changeSelectedActuate(data.value)"
          [class.selected]="checkSelectedActuate(data.value)"
          >{{ data.label }}</span
        >
      </div>
    </app-search-group-items>
    <div class="search-contents">
      <app-search-group-items label="申込み日">
        <igx-date-range-picker
          (onClosing)="getCount()"
          [(ngModel)]="entryDataRange"
          displayFormat="yyyy-MM-dd"
          style="margin-right: 20px;"
        >
        </igx-date-range-picker>
        <div (click)="entryDataRange=null;getCount()" class="date-clear-icon" style="cursor: pointer"></div>
      </app-search-group-items>
      <app-search-group-items label="稼働日">
        <igx-date-range-picker
          (onClosing)="getCount()"
          [(ngModel)]="actuateDataRange"
          displayFormat="yyyy-MM-dd"
          style="margin-right: 20px;"
        >
        </igx-date-range-picker>
        <div (click)="actuateDataRange=null;getCount()" class="date-clear-icon" style="cursor: pointer"></div>
      </app-search-group-items>
    </div>
  </app-search-conditions-area>

  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [exportExcelButton]="true"
  ></app-action-grid>
</app-main-contents>
