import { Component, OnInit, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { SelectedChildOrganizationInfo } from './selected-child-organization.model'
import { AccountInfo } from 'src/app/components/isbeauth/account'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'

declare const APP
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
  companyList: any[]
  data: {
    customerCd: string;
    organizationCd: string;
    organizationName: string;
    branchName: string;
    departmentName: string;
    postCode: string;
    prefecture: string;
    city: string;
    block: string;
    building: string;
    telephoneNumber: string;
    facsimileNumber: string;
    mailAddress: string;
    personInChargeName: string;
  }
  public subscription: Subscription
  public users: AccountInfo
  public message = false
  public editDisabled: boolean
  public personalAuthSubs: Subscription
  public UserListBrowseDisabled: boolean	       // ユーザー一覧の確認権限
  public ServiceContractBrowseDisabled: boolean	 // 利用状況確認権限

  // ヘッダーの会社選択（1番上の組織）
  public selectedOrgSubs: Subscription
  public selectedOrganizationCd: string
  public selectedOrganizationName: string
  public canClose: boolean

  // 組織リストボックス選択用（2階層目の組織）
  public displist = false
  // public branchlist:any[];
  // public selectedBranch : any;
  // public dummyBranchlist:any;
  public branchlist: SelectedChildOrganizationInfo[]
  public selectedBranch: SelectedChildOrganizationInfo
  public dummyBranchlist: any

  // 組織図上の選択組織（3階層目以下の組織）
  public selectedSection: SelectedChildOrganizationInfo
  public updatedOrganizationName = ''

  public selectedSectionFromList: SelectedChildOrganizationInfo

  private organizationCdForAdmin: string // 全組織管理者が選択中の組織
  public organizationForAdminSubs: Subscription

  // loadingアニメーション用
  loading: boolean
  afterload: boolean

  // 組織図表示切り替え用
  isShowCustomers = false

  constructor(
    private authService: AuthService,
    private organizationV2Service: OrganizationV2Service,
  ) {}

  @ViewChild('editNameDialog', { read: IgxDialogComponent, static: false })
  public editNameDialog: IgxDialogComponent

  ngOnInit(): void {
    this.users = this.authService.AccountInfo
    // 選択中の組織
    this.selectedOrganizationCd = this.users.selectedOrganizationCd
    if (this.users) {
      const selectOrg = this.users.organizations.filter(
        (x) => x.organizationCd === this.selectedOrganizationCd
      )
      this.selectedOrganizationName = selectOrg[0].organizationName
    }

    // ダイアログ外側クリックで閉じるかどうか設定
    this.canClose = true
    // 組織選択リストの表示状態設定
    this.displist = false

    // 組織図ツリーデータ取得
    this.getOrganizationTreeData(this.selectedOrganizationCd)

    // 変数に保持したらサービスから消す
    const org = new SelectedChildOrganizationInfo()
    org.organizationCd = ''
    org.organizationName = ''
    org.organizationTypeCd = ''
    org.organizationTypeName = ''

  }
  ngOnDestroy() {
  }

  // 組織図ツリーデータ取得
  getOrganizationTreeData(organiationcd){
    this.loading = true

    this.organizationV2Service.OrganizationTreesWithCustomers(organiationcd).subscribe((ret: any) => {
      if (ret?.resultCode === 0) {

        const retdata = ret.data[0]
        // 最上位組織に子組織がない場合nullになっているので[]をセット
        if (retdata.children === null){
          retdata.children = []
        }

        // 本社・支社の存在確認 ★2021/4/27 本社・支社は組織種別＝部署の一部のため、組織種別コードは持たないため、ここのロジックは一部、不要。
        // 子組織がある場合
        if (retdata.children.length !== 0){
          // organizationTypeCd＝825828892（支社）→"organization_type-branch"
          this.branchlist = retdata.children.filter((y) => y.organizationTypeCd === 'organization_type-branch' )
          // 本社・支社がない場合
          if (this.branchlist.length === 0){
            this.dummyBranchlist = retdata
            this.selectedBranch = this.dummyBranchlist
          }else{
            this.dummyBranchlist = ''
            this.selectedBranch = this.branchlist[0]
          }
        }
        // 子組織が1件もない場合
        else{
          this.dummyBranchlist = ret.data[0]
          this.selectedBranch = this.dummyBranchlist
        }

        // 選択組織の設定
        // 組織が1件もない場合
        if (this.selectedBranch.children.length === 0){
          this.selectedSection = JSON.parse(JSON.stringify(this.selectedBranch))
          this.selectedSection.organizationCd = ''
          this.selectedSection.organizationName = ''
          this.selectedSection.organizationTypeCd = ''
          this.selectedSection.organizationTypeName = ''
          this.selectedSection.children = []
        }else{
          this.selectedSection = this.selectedBranch.children[0]
        }
        // 子組織から戻ってきた場合
        if (this.selectedSectionFromList !== undefined){
          if (this.selectedSectionFromList.organizationCd !== ''){
            // なぜか直接入れると不一致になる・・
            // this.selectedSection = this.selectedSectionFromList;
            this.searchSection(this.selectedBranch.children, this.selectedSectionFromList)
          }
        }
        this.loading = false
      } else {
        alert(ret.resultMessage)
      }
      APP.controller.reset()
    })

  }
  searchSection(items, target) {
    const targetCd = target.organizationCd
    let retval: any
    items.forEach(x => {
      if (x.organizationCd === targetCd){
        this.selectedSection = x
        return
      }
      if (x.children.length > 0){
        this.searchSection(x.children, target)
      }
    })
  }
  // 組織リスト（2階層目の組織）表示
  selectOrg(){
    if (this.displist){
      this.displist = false
    }else{
      this.displist = true
    }
  }
  // 組織リストボックス（2階層目の組織）選択変更
  changeOrg(organization){
    this.displist = false
    this.selectedBranch = organization
  }
  // 組織クリック
  clickOrgBox(targetOrg){
    this.selectedSection = targetOrg
  }
  getStyleClass(org): string{
    // 会社
    if (org?.organizationTypeCd === 'organization_type-company'){
      return 'company'
    }
    // 部署
    if (org?.organizationTypeCd === 'organization_type-section'){
      return 'section'
    }
    // ワークグループ
    if (org?.organizationTypeCd === 'organization_type-work_group'){
      return 'work-group'
    }
    // ロール
    if (org?.organizationTypeCd === 'organization_type-role'){
      return 'role'
    }
    return 'section'
  }
}
