import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { BackendOrganization, LikeSearchOrganizationRequest } from 'src/api/organization-company-api/organization-company-api.interface'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { FeatureOrganizationService } from '../feature-organization.service'
import { SelectListItem } from '../input-selectbox/input-selectbox.interface'


@Component({
  selector: 'app-search-company-key-input',
  templateUrl: './search-company-key-input.component.html',
  styleUrls: ['./search-company-key-input.component.scss']
})
export class SearchCompanyKeyInputComponent implements OnInit, OnChanges {
  @Input() searchKey = ''
  @Output() searchStart = new EventEmitter<void>()
  @Output() searchEnd = new EventEmitter<BackendOrganization[]>()
  loading = false
  categoryNameList: string[] = []
  history: string[] = []
  request: LikeSearchOrganizationRequest = {
    organizationName: '',
    organizationNameKana: '',
    prefectureCd: null,
    corporateNumber: null
  }
  inputCorporateNumber: number = null
  prefectures: SelectListItem[] = []
  constructor(
    private organizationCompanyApiService: OrganizationCompanyApiService,
    private messageService: MessageService,
    private featureOrganizationService: FeatureOrganizationService
  ) { }

  ngOnInit(): void {
    this.getPrefectures()
    this.setCategory()
  }

  async getPrefectures(): Promise<void> {
    const ret = await this.organizationCompanyApiService.GetPrefectures()
    this.prefectures = []
    ret.forEach(row => {
      const selectOption: SelectListItem = {
        label: row.prefName,
        value: row.prefCode.toString()
      }
      this.prefectures.push(selectOption)
    })
  }

  async setCategory(): Promise<void> {
    const list = await this.featureOrganizationService.organizationCategoryMaster()
    this.categoryNameList = []
    list.forEach(category => {
      this.categoryNameList.push(category.organizationCategoryName)
    })
  }

  changeValue(key: string, value?: string): void {
    this.request[key] = value
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchKey) {
      this.updateSearchValue(this.searchKey)
      this.searchCompany()
    }
  }

  // 組織のLIKE検索
  async searchCompany(): Promise<void> {
    if (this.loading) { return }
    if (!this.request.organizationName && !this.inputCorporateNumber) {
      return
    }
    const regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/)
    this.request.corporateNumber = null
    if (this.inputCorporateNumber) {
      if (regexp.test(this.inputCorporateNumber.toString())) {
        this.request.corporateNumber = Number(this.inputCorporateNumber)
      }
    }
    if (!this.request.organizationName && !this.request.corporateNumber) {
      this.searchStart.emit()
      this.searchEnd.emit([])
      return
    }

    // 予測変換を使った場合、中途半端なkanaがセットされてしまうので、
    // 3文字未満、Nameとの文字数差が3文字以上の場合、除外(暫定対応)
    const name = this.request.organizationName
    const kana = this.request.organizationNameKana
    this.request.organizationNameKana = ''
    if (kana.length > 3 && (name.length - kana.length) < 3) {
      this.request.organizationNameKana = kana
    }

    this.loading = true
    this.searchStart.emit()
    const ret = await this.organizationCompanyApiService.LikeSearchCorporates(this.request)
    this.loading = false
    if (ret?.resultCode !== 0) {
      this.messageService.error(ret.resultMessage ?? messageList.M00001.message)
      this.searchEnd.emit([])
      return
    }
    this.searchEnd.emit(ret.data)
  }

  inputValue(event): void {
    this.updateSearchValue(event.target.value)
  }

  updateSearchValue(key: string): void {
    // 正式名称SET
    this.request.organizationName = key

    // 名称SET
    let organizationBaseName: string = key
    this.categoryNameList.forEach(name => {
      organizationBaseName = organizationBaseName.split(name).join('')
    })

    // かなSET
    this.request.organizationNameKana = this.featureOrganizationService.convertKana(organizationBaseName)
  }
}
