<app-customer-name-disp (targetSelected)="selectTargetOrganization($event)"></app-customer-name-disp>
<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-action-grid
    [data]="customers"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="customerColumns"
    [actionButtons]="actionButtons"
    (actionCommit)="actionCommit($event)"
    [height]="430"
    [cellClasses]="cellClasses"
  ></app-action-grid>
  <app-create-account-url-dialog></app-create-account-url-dialog>
  <app-ura-account-link-url-send-mail-preview-dialog
    (sendMailEmit)="sendMailEmit($event)"
  ></app-ura-account-link-url-send-mail-preview-dialog>
</app-main-contents>
