<app-customer-name-disp
  (targetSelected)="selectTargetOrganization($event)"
></app-customer-name-disp>
<app-organization-customer-list
  *ngIf="targetOrganizationCd && disp"
  [companyOrganizationCd]="companyOrganizationCd"
  [targetOrganizationCd]="targetOrganizationCd"
  [selectedOrganizationName]="selectedOrganizationName"
  (jumpTreePage)="jumpTreePage()"
></app-organization-customer-list>
