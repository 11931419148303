<app-sending *ngIf="sending"></app-sending>
<app-dialog
  *ngIf="dialogOpen"
  widthSize="large"
  (commit)="commit()"
  (cancel)="dialogOpen = false"
  [title]="newEditMode ? '登録' : '変更'"
  [commitButtonText]="newEditMode ? '登録' : '変更'"
>
<form autocomplete="off">
    <div class="l-table">
      <div class="l-table__item">
        <div class="l-table__head">
          <p>商品CD</p>
        </div>
        <div class="l-table__body">
          <input
            *ngIf="newEditMode"
            class="c-form__input"
            type="text"
            id="merchandiseCd"
            name="merchandiseCd"
            [(ngModel)]="merchandiseDetail.merchandiseCd"
          />
          <span *ngIf="!newEditMode">
            {{ merchandiseDetail.merchandiseCd }}
          </span>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>商品名</p>
        </div>
        <div class="l-table__body">
          <input
            class="c-form__input"
            type="text"
            id="merchandiseName"
            name="merchandiseName"
            [(ngModel)]="merchandiseDetail.merchandiseName"
          />
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>表示名<br><p class="sub-header-text">※My-ideaサービスマップなどに利用します</p></p>
        </div>
        <div class="l-table__body">
          <input
            class="c-form__input"
            type="text"
            id="merchandiseDisplayName"
            name="merchandiseDisplayName"
            [(ngModel)]="merchandiseDetail.merchandiseDisplayName"
          />
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>金額</p>
        </div>
        <div class="l-table__body">
          <input
            class="c-form__input"
            type="text"
            id="merchandiseReferencePrice"
            name="merchandiseReferencePrice"
            [(ngModel)]="merchandiseDetail.merchandiseReferencePrice"
          />
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>サービス</p>
        </div>
        <div class="l-table__body">
          <a
            class="tag tag-select"
            *ngFor="let textTag of collectionTagMaster.textTags"
            (click)="tagClick(textTag.tagCd)"
            [class.tag-select]="tagAttachment(textTag.tagCd)"
            style="cursor: pointer"
          >
            {{ textTag.tagText }}</a
          >
        </div>
      </div>
      <div
        class="l-table__item"
        *ngFor="let selecterInfo of selecterInfoList"
      >
        <div class="l-table__head">
          <p>{{ selecterInfo.label }}</p>
        </div>
        <div class="l-table__body">
          <div class="l-table__btns">
            <ng-container *ngIf="selecterInfo.type == 'Number'">
              <input
                class="c-form__input"
                type="number"
                [id]="selecterInfo.key"
                [name]="selecterInfo.key"
                [(ngModel)]="merchandiseDetail.selecterSetting[selecterInfo.key]"
              />
            </ng-container>
            <ng-container *ngIf="selecterInfo.type == 'Selecte'">
              <div
                class="l-table__btn l-table__btn--auto"
                *ngFor="let selecter of selecterInfo.selecter; let i = index"
              >
                <label class="c-form__radio">
                  <input
                    [value]="selecter.value"
                    type="radio"
                    [id]="selecterInfo.key + i"
                    [name]="selecterInfo.key + i"
                    #selectsetting="ngModel"
                    [(ngModel)]="merchandiseDetail.selecterSetting[selecterInfo.key]"
                    (click)="changeSelecter(selecterInfo.key, selectsetting)"
                  /><span style="cursor: pointer">{{ selecter.label }}</span>
                </label>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>製品</p>
        </div>
        <div class="l-table__body">
          <a
            (click)="productEditMode = !productEditMode"
            class="c-btn--s edit-tag-btn"
            style="cursor: pointer"
            ><span>{{ productEditMode ? "追加完了" : "追加" }}</span></a
          >
          <div>
            <div *ngFor="let product of merchandiseDetail.products">
              {{ product.productName }}
              <a
                style="cursor: pointer"
                (click)="removeProduct(product.productCd)"
                >×
              </a>
            </div>
          </div>
          <ng-container *ngIf="productEditMode">
            <hr style="margin-top: 43px" />
            <div>追加製品選択</div>
            <input
              class="c-form__input"
              type="text"
              #searchproduct
              ngModel
              name="searchproduct"
              autocomplete="off"
              placeholder="製品名で検索"
              (keyup)="searchProducts(searchproduct.value)"
              (change)="searchProducts(searchproduct.value)"
            />
            <a
              class="tag"
              (click)="productClick(productSelecter.productCd)"
              *ngFor="let productSelecter of productSelection"
              style="cursor: pointer"
            >
              {{ productSelecter.productName }}
            </a>
            <span *ngIf="productSelectionAndMore">…</span>
          </ng-container>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>子商品</p>
        </div>
        <div class="l-table__body">
          <a
            (click)="childEditMode = !childEditMode"
            class="c-btn--s edit-tag-btn"
            style="cursor: pointer"
            ><span>{{ childEditMode ? "追加完了" : "追加" }}</span></a
          >
          <div>
            <div
              *ngFor="let children of merchandiseDetail.childrenMerchandise"
            >
              {{ children.merchandiseName }}
              <a
                style="cursor: pointer"
                (click)="removeChild(children.merchandiseCd)"
                >×
              </a>
            </div>
          </div>
          <ng-container *ngIf="childEditMode">
            <hr style="margin-top: 43px" />
            <div>追加商品選択</div>
            <input
              class="c-form__input"
              type="text"
              #searchchild
              ngModel
              name="searchchild"
              autocomplete="off"
              placeholder="商品名で検索"
              (keyup)="searchChildMerchandise(searchchild.value)"
              (change)="searchChildMerchandise(searchchild.value)"
            />
            <a
              class="tag"
              (click)="childClick(childSelecter.merchandiseCd)"
              *ngFor="let childSelecter of childSelection"
              style="cursor: pointer"
            >
              {{ childSelecter.merchandiseName }}
            </a>
            <span *ngIf="childSelectionAndMore">…</span>
          </ng-container>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>タグ</p>
        </div>
        <div class="l-table__body">
          <a
            (click)="tagEditMode = !tagEditMode"
            class="c-btn--s edit-tag-btn"
            style="cursor: pointer"
            ><span>{{ tagEditMode ? "編集モードを終了" : "編集" }}</span></a
          >
          <ng-container *ngIf="!tagEditMode">
            <a class="tag tag-select" *ngFor="let tag of normalTags">
              {{ tag.tagText }}
            </a>
          </ng-container>
          <ng-container *ngIf="tagEditMode">
            <div class="" *ngFor="let tagItem of normalTagMaster">
              <div class="purpose-tag-label">
                <p>{{ tagItem.tagPurposeText }}</p>
              </div>
              <div class="">
                <a
                  class="tag"
                  (click)="tagClick(textTag.tagCd)"
                  style="cursor: pointer"
                  [class.tag-select]="tagAttachment(textTag.tagCd)"
                  *ngFor="let textTag of tagItem.textTags"
                >
                  {{ textTag.tagText }}
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>稼働設定項目</p>
        </div>
        <div class="l-table__body">
          <div *ngFor="let setting of merchandiseDetail.actuateSettings; let i = index">
            <div>
              <div
                style="
                  display: flex;
                  align-items: center;
                "
              >
                <div style="width: 3rem">{{ i + 1 }}:</div>
                <div style="width: 20rem">Key:{{ setting.key }}</div>
                <div style="width: 20rem">項目名:{{ setting.name }}</div>
                <input
                  class="c-form__input setting-input"
                  type="text"
                  [id]="'value' + i"
                  [name]="'value' + i"
                  placeholder="値"
                  [(ngModel)]="setting.value"
                  style="width: 5rem"
                />
              </div>
              <div style="margin-left: 2rem; margin-bottom: 0.1rem">
                タイプ：{{ setting.type == "Number" ? "数値" : "文字" }}
              </div>
              <div style="margin-left: 2rem; margin-bottom: 1rem">
                設定タイミング：
                <span *ngIf="setting.editable === 0">見積もり時</span>
                <span *ngIf="setting.editable === 1">契約時</span>
                <span *ngIf="setting.editable === 2">サービスログイン時</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>販売期間</p>
        </div>
        <div class="l-table__body" style="display: flex; align-items: center">
          <!-- <nz-range-picker nzFormat="yyyy/MM/dd"></nz-range-picker> -->
          <input
            style="width: 15rem"
            class="c-form__input"
            type="date"
            id="salesStartDatetime"
            name="salesStartDatetime"
            [value]="merchandiseDetail.salesStartDatetimeString"
            #salesStartDatetime
            ngModel
            (keyup)="
              changeDate('salesStartDatetime', salesStartDatetime.value)
            "
            (change)="
              changeDate('salesStartDatetime', salesStartDatetime.value)
            "
          />
          ～
          <input
            style="width: 15rem"
            class="c-form__input"
            type="date"
            id="salesEndDatetime"
            name="salesEndDatetime"
            [value]="merchandiseDetail.salesEndDatetimeString"
            #salesEndDatetime
            ngModel
            (keyup)="changeDate('salesEndDatetime', salesEndDatetime.value)"
            (change)="changeDate('salesEndDatetime', salesEndDatetime.value)"
          />
        </div>
      </div>
    </div>
</form>
</app-dialog>
