import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IgxColumnComponent, IgxGridModule } from '@infragistics/igniteui-angular'
import { CommonPartsModule } from '../common-parts/common-parts.module'
import { BaseGridComponent } from './base-grid/base-grid.component'
import { ActionGridComponent } from './action-grid/action-grid.component'
@NgModule({
  declarations: [
    ActionGridComponent,
    BaseGridComponent,
  ],
  imports: [
    CommonModule,
    IgxGridModule,
    CommonPartsModule
  ],
  exports: [
    ActionGridComponent,
    BaseGridComponent
  ]
})
export class IsGridModule { }
