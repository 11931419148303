<app-customer-name-disp
  (targetSelected)="selectTargetOrganization($event)"
></app-customer-name-disp>
<app-main-contents *ngIf="selectedTargetOrg?.organizationCd">
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    [height]="430"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>

<app-frimo-actuate-setting-dialog
  [title]="frimoSettingTitle"
  (updateed)="searchList()"
></app-frimo-actuate-setting-dialog>

<app-actuate-setting-dialog
  [editableCds]="editableCds"
  (updateed)="searchList()"
></app-actuate-setting-dialog>