import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { AuthService } from './auth.service'
import { environment } from '../../../environments/environment'
import { AccountInfo, Organization } from './account'
import { lastValueFrom, Subscription } from 'rxjs'
import { MessageService } from '../message/message.service'
import { messageList } from '../message/message-data'
import { ActuatesService } from 'src/api/actuates-api/actuates-api.service'
declare const window
@Component({
  selector: 'app-isbeauth',
  templateUrl: './isbeauth.component.html',
  styleUrls: ['./isbeauth.component.scss'],
})
export class IsbeauthComponent implements OnInit {
  @Input() headerType = 1
  @Output() endLogin = new EventEmitter<boolean>()
  loginUrl: string
  baseUrl: string
  isAppCd: string
  organizationDistinctionApp: boolean
  core: any
  appLogo: string
  organizationDistinctionBrowser: boolean
  accountId: string // ログインID、パスワードを初期表示する場合に設定
  subscription: Subscription
  organizationForAdminSubs: Subscription
  spoofingTargetAccountId: string

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private actuatesService: ActuatesService
  ) { }
  ngOnInit(): void {
    this.accountId = this.authService.AutoLoginAccountId
    if (this.accountId) {
      this.authService.resetAutoLoginAccountId()
    }
    const that = this
    const protocol = window.location.protocol
    const isbeLoginPath = protocol + environment.isbeLoginBaseUrl
    const isbeLoginScriptPath = isbeLoginPath + environment.isbeLoginScript
    const isbeLoginScriptElem = document.createElement('script')
    const baseUrl = document.baseURI
    isbeLoginScriptElem.setAttribute('src', isbeLoginScriptPath)
    document.head.appendChild(isbeLoginScriptElem)
    isbeLoginScriptElem.onload = function() {
      that.loginUrl = isbeLoginPath
      that.baseUrl = window.location.origin
      that.isAppCd = 'myidea.ui'
      that.organizationDistinctionApp = false
      that.organizationDistinctionBrowser = false
      that.appLogo = `${baseUrl}assets/images/common/logo_my-idea_y_posi.png`
      // Ura-ideaページだった場合
      if (that.headerType === 9) {
        that.appLogo = `${baseUrl}assets/images/common/logo_Ura-idea_y_kana_posi.png`
      }
      that.core = window.BeLoginCore
      that.core.initialize(that)
      isbeLoginScriptElem.onload = null
    }

    const s = this
    // 管理者モードでのなりすまし設定・解除Requestをキャッチする
    s.subscription = s.authService.spoofingRequestSource$.subscribe(spoofingRequestAccountId => {
      if (spoofingRequestAccountId) {
        s.spoofingForTargetUser(spoofingRequestAccountId)
      }
      else {
        s.spoofingReset()
      }
    })
  }

  // ログイン後処理
  onEndLogin = async (accountInfo: string): Promise<void> => {
    try {
      let showSelectOrganizationDialog = false
      const account: AccountInfo = this.convertCamel(accountInfo)

      // const ret = await this.actuatesService.GetServiceActuates(account.customerCd).toPromise()
      const ret$ = this.actuatesService.GetServiceActuates(account.customerCd)
      let ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) { return }
      const myIdeaActuates = ret.data

      // 会社かつMy-idea契約がある会社に絞る
      const organizations: Organization[] = []
      account?.organizations?.forEach(organization => {
        if (organization.organizationTypeCd === 'organization_type-company'
        && myIdeaActuates.find(x => x.organizationCd === organization.organizationCd)) {
          organizations.push(organization)
        }
      })
      account.organizations = organizations

      if (!account.organizations.length) {
        this.messageService.templeteMessage(messageList.M00051)
        setTimeout(() => {
          this.authService.logout()
        }, 5000)
        return
      }

      // 選択済組織の所属ではなくなっていた場合
      const find = account.organizations.find(x => x.organizationCd === account.selectedOrganizationCd)
      if (!find) {
        account.selectedOrganizationCd = null
      }

      // 選択済の組織が無い場合は先頭組織をSET
      if (!account.selectedOrganizationCd) {
        account.selectedOrganizationCd = account.organizations[0].organizationCd
        // 組織が一つ以上ある場合は選択モーダルを出す (ただしDefault値は先頭で既にSETしておく)
        if (account.organizations.length > 1) {
          showSelectOrganizationDialog = true
        }
      }
      this.authService.setAccountInit(account)
      this.endLogin.emit(showSelectOrganizationDialog)
    } catch (error) {
      this.messageService.templeteMessage(messageList.M00052)
      setTimeout(() => {
        this.authService.logout()
      }, 5000)
    }
  }

  convertCamel = (stringJson) => {
    // jsonKeyを小文字始まりに変換
    const json = JSON.parse(stringJson, function(key, value) {
      if (value && typeof value === 'object') {
        for (const k in value) {
          if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
            value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k]
            delete value[k]
          }
        }
      }
      return value
    })
    return json
  }

  onLogout(): void {
    window.BeLoginCore.login()
  }

  // 対象顧客でなりすましログイン
  spoofingForTargetUser(spoofingTargetAccountId: string): void {
    if (!spoofingTargetAccountId) { return }
    const that = this
    const isbeLoginPath = environment.isbeLoginBaseUrl
    const isbeLoginScriptPath = environment.isbeLoginBaseUrl + environment.isbeSpoofingScript
    const isbeLoginScriptElem = document.createElement('script')
    isbeLoginScriptElem.setAttribute('src', isbeLoginScriptPath)
    document.head.appendChild(isbeLoginScriptElem)
    isbeLoginScriptElem.onload = function() {
      that.loginUrl = isbeLoginPath
      that.spoofingTargetAccountId = spoofingTargetAccountId
      that.core = window.SpoofingCore
      that.core.spoofing(that)
      isbeLoginScriptElem.onload = null
      // ログインUIからレスポンスを受け取れるようにするのが面倒なのでsetTimeout
      setTimeout(() => {
        location.reload()
      }, 2000)
    }
  }

  // なりすましログイン解除
  spoofingReset(): void {
    const that = this
    const isbeLoginPath = environment.isbeLoginBaseUrl
    const isbeLoginScriptPath = environment.isbeLoginBaseUrl + environment.isbeSpoofingScript
    const isbeLoginScriptElem = document.createElement('script')
    isbeLoginScriptElem.setAttribute('src', isbeLoginScriptPath)
    document.head.appendChild(isbeLoginScriptElem)
    isbeLoginScriptElem.onload = function() {
      that.loginUrl = isbeLoginPath
      that.core = window.SpoofingCore
      that.core.reset(that)
      isbeLoginScriptElem.onload = null
      // ログインUIからレスポンスを受け取れるようにするのが面倒なのでsetTimeout
      setTimeout(() => {
        location.reload()
      }, 2000)
    }
  }
}
