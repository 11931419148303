import { Component, OnInit } from '@angular/core'
import { NameCardDetail } from 'src/api/namecard-api/namecard-api.interface'
import { NameCardApiService } from 'src/api/namecard-api/namecard-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'

@Component({
  selector: 'app-my-name-card-list',
  templateUrl: './my-name-card-list.component.html',
  styleUrls: ['./my-name-card-list.component.scss']
})
export class MyNameCardListComponent implements OnInit {
  title = '自身のデジタル名刺'
  customerId = this.authService?.AccountInfo?.customerId
  loading = true
  cardList: NameCardDetail[] = []

  constructor(
    private authService: AuthService,
    private nameCardApiService: NameCardApiService
  ) { }

  ngOnInit(): void {
    this.getList()
  }

  async getList(): Promise<void> {
    this.loading = true
    const selectedCompanyCd = this.authService?.AccountInfo.selectedOrganizationCd
    const selectedCompany = this.authService?.AccountInfo.organizations.find(x => x.organizationCd === selectedCompanyCd)
    // const ret = await this.nameCardApiService.DetailCardList("08d723e8-5542-4603-afc7-831decd213d4","cf612ad9-2a92-4d27-9d89-894de05ce4a4")
    const ret = await this.nameCardApiService.DetailCardList(selectedCompany.organizationId, this.customerId)
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.cardList = ret.data
  }
}
