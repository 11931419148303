<div class="search">
  <!-- ヘッダー -->
  <div class="search-headetr">
    <b>検索条件</b>
    <div class="bar-right" (click)="open = !open" style="cursor: pointer">
      <span *ngIf="!open" class="material-icons">expand_more</span>
      <span *ngIf="open" class="material-icons">keyboard_arrow_up</span>
    </div>
  </div>

  <div *ngIf="open" class="search-group">
    <ng-content></ng-content>
    <!-- 検索実行のボタン -->
    <div class="c-btns search-btn">
      <div class="c-btns__item">
        <a
          class="js-group-close c-btn"
          style="cursor: pointer"
          (click)="search.emit()"
        >
          <span>検索結果を見る <ng-container *ngIf="dispCountView"><span *ngIf="countLoading" >(Loading...)</span><span *ngIf="!countLoading">({{ neverCountSearch ? '-' : count }}件)</span></ng-container></span></a
        >
      </div>
    </div>
  </div>
</div>
