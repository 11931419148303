<div class="l-main__contents js-fit-contents">
  <app-breadcrumbs [label2]="title"></app-breadcrumbs>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-sending *ngIf="sending"></app-sending>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    [rowClickAction]="rowClickAction"
    [cellClasses]="cellClasses"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</div>
