import { Injectable } from '@angular/core'
import { InvoiceLeafApiService } from 'src/api/invoice-leaf/invoice-leaf-api.service'
import { JoinText, sortedItems } from 'src/app/common/common'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { SelectOption } from 'src/app/module/common-parts/select/select.component.interface'


@Injectable({
  providedIn: 'root',
})
export class InvoiceLeafService {

  constructor(
    private authService: AuthService,
    private invoiceLeafApiService: InvoiceLeafApiService
  ) { }

  private actuateOptions: SelectOption[]

  async getActuateOptions(): Promise<SelectOption[]> {
    if (this.actuateOptions != null) return this.actuateOptions

    const invoiceActuateRet = await this.invoiceLeafApiService.getActuates(this.authService.AccountInfo.customerCd)
    if (invoiceActuateRet?.resultCode !== 0) { return }

    const actuateOptions = []
    invoiceActuateRet.data.forEach(actuate => {
      const selectOption: SelectOption = {
        label: JoinText([actuate.suppliCustomerId.toString(), actuate.personName + "様", actuate.title], ' '),
        value: actuate.actuateCd
      }
      if (actuate.paymentTypeCd === 'annually') {
        selectOption.label = `${selectOption.label} (年払い)`
      }
      actuateOptions.push(selectOption)
    })
    
    this.actuateOptions = sortedItems(actuateOptions, "label")
    return actuateOptions
  }
}
