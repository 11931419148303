import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InvitationReceiveListComponent } from './invitation-receive-list/invitation-receive-list.component'
import { InvitationSendListComponent } from './invitation-send-list/invitation-send-list.component'
import { IsGridModule } from 'src/app/module/is-grid/is-grid.module'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonPartsModule } from 'src/app/module/common-parts/common-parts.module'
import { InvitationEditComponent } from './invitation-edit/invitation-edit.component'
import { MyideaV2RoutingModule } from './myidea-v2-routing.module'
import { MessageBoxComponent } from './message-box/message-box.component'
import { ServiceMapComponent } from './service-map/service-map.component'
import { CompanyEditV2Component } from './company-edit/company-edit.component'
import { AccountChangedComponent } from './account-changed/account-changed.component'
import { MyideaTopComponent } from './myidea-top/myidea-top.component'
import { ClickOutsideModule } from 'ng-click-outside'
import { IgxModules } from 'src/app/module/igx.module'
import { IgxProgressBarModule } from '@infragistics/igniteui-angular'
import { EasyAuthLoginComponent } from './easy-auth-login/easy-auth-login.component'
import { OrganizationComponent } from './organization/organization.component'
import { RegistrationAccountComponent } from './registration-account/registration-account.component'
import { NicknameChangeComponent } from './nickname-change/nickname-change.component'
import { PasswordChangeComponent } from './password-change/password-change.component'
import { MyideaPartsModule } from 'src/app/module/myidea-parts/myidea-parts.module'
import { EasyAuthLoginRouterOutletComponent } from './easy-auth-login-router-outlet/easy-auth-login-router-outlet.component'
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { ContractDispModule } from 'src/app/module/myidea-parts/contract-detail-block/contract-disp.module'
import { CustomerProfileComponent } from './customer-profile/customer-profile.component'
import { CompleteCreateAccountComponent } from './complete-create-account/complete-create-account.component'
import { CreateCompanyCustomerComponent } from './create-company-customer/create-company-customer.component'
import { InvoiceContractConfirmListComponent } from './invoice-contract-confirm-list/invoice-contract-confirm-list.component'
import { InvoiceContractListComponent } from './invoice-contract-list/invoice-contract-list.component'
import { ShimuraGarageMigrationTopComponent } from './micomel-migration-top/micomel-migration-top.component'
import { ShimuraGarageMigrationPolicyComponent } from './micomel-migration-policy/micomel-migration-policy.component'
import { ShimuraGarageMigrationLinkComponent } from './shimura-garage-migration/micomel-migration-link/micomel-migration-top.component'
import { LogoutComponent } from './logout/logout.component'
import { ExchangedNameCardListComponent } from './name-card/exchanged-name-card-list/exchanged-name-card-list.component'
import { ExchangeNameCardComponent } from './name-card/exchange-name-card/exchange-name-card.component'
import { NameCardItemComponent } from './name-card/name-card-item/name-card-list.component'
import { NameCardDetailComponent } from './name-card/name-card-detail/name-card-detail.component'
import { MyNameCardListComponent } from './name-card/my-name-card-list/my-name-card-list.component'
import { ServiceUserAuthorityComponent } from './service-user-authority/service-user-authority.component'
import { ShimuraGarageAppSettingComponent } from './extra-views/shimura-garage-migration/shimura-garage-app-setting/shimura-garage-app-setting.component'
import { ShimuraGarageEasyAuthLoginComponent } from './shimura-garage-migration/shimura-garage-easy-auth-login/easy-auth-login.component'
import { ActionTransactionDirective } from 'src/app/directive/action-transaction.directive'
import { RemovePeriodPipe } from 'src/app/directive/remove-period.pipe'
const SHIMURA_GARAGE_MIGRATION_COMPONENTS = [
  ShimuraGarageMigrationLinkComponent,
  ShimuraGarageMigrationTopComponent,
  ShimuraGarageMigrationPolicyComponent,
  ShimuraGarageAppSettingComponent,
  ShimuraGarageEasyAuthLoginComponent
]
@NgModule({
  declarations: [
    InvitationReceiveListComponent,
    InvitationSendListComponent,
    InvitationEditComponent,
    MessageBoxComponent,
    ServiceMapComponent,
    CompanyEditV2Component,
    AccountChangedComponent,
    CreateCompanyCustomerComponent,
    MyideaTopComponent,
    EasyAuthLoginComponent,
    OrganizationComponent,
    RegistrationAccountComponent,
    NicknameChangeComponent,
    PasswordChangeComponent,
    EasyAuthLoginRouterOutletComponent,
    RequestResetPasswordComponent,
    ResetPasswordComponent,
    CustomerProfileComponent,
    CompleteCreateAccountComponent,
    InvoiceContractConfirmListComponent,
    InvoiceContractListComponent,
    LogoutComponent,
    ExchangeNameCardComponent,
    ExchangedNameCardListComponent,
    NameCardItemComponent,
    NameCardDetailComponent,
    MyNameCardListComponent,
    ServiceUserAuthorityComponent,
    ActionTransactionDirective,
    RemovePeriodPipe,
    ...SHIMURA_GARAGE_MIGRATION_COMPONENTS
  ],
  imports: [
    CommonModule,
    IsGridModule,
    IgxModules,
    CommonPartsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    MyideaV2RoutingModule,
    IgxProgressBarModule,
    MyideaPartsModule,
    ContractDispModule
  ]
})
export class MyideaV2Module {}
