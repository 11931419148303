import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router, ParamMap } from '@angular/router'
import { TextTag } from 'src/api/common/api-common.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { AddProductQuotationService } from '../service/add-product-quotation.service'
import { QuotationSelectedGroup } from '../service/ura-create-quotation.interface'

@Component({
  selector: 'app-ura-create-add-quotation-business-category',
  templateUrl: './ura-create-add-quotation-business-category.component.html',
  styleUrls: ['./ura-create-add-quotation-business-category.component.scss']
})
export class UraCreateAddQuotationBusinessCategoryComponent implements OnInit {
  title = '見積もり作成 (追加契約)'
  index: string
  session: QuotationSelectedGroup
  organizationCompanyCd: string
  contractOrganizationCd: string
  tags: TextTag[] = []
  selectedTagCd: string
  loading = true
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.router.navigate([`/ura-add-product-retail/${this.organizationCompanyCd}/${this.contractOrganizationCd}/${this.index}`])
      },
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]
  constructor(
    private activatedRoute: ActivatedRoute,
    private merchandisesService: MerchandisesApiService,
    private router: Router,
    private addProductQuotationService: AddProductQuotationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCompanyCd = params.get('organizationCompanyCd')
      this.contractOrganizationCd = params.get('contractOrganizationCd')
      this.index = params.get('index')
      const check = this.addProductQuotationService.pageInit(this.organizationCompanyCd, this.contractOrganizationCd, this.index)
      if (!check) { return }

      this.session = this.addProductQuotationService.getSelectedGroupSession(this.index)
      this.selectedTagCd = this.session.businessCategory?.tagCd
      this.getBusinessCategoryTags()
    })
  }

  // 業態情報取得
  async getBusinessCategoryTags(): Promise<void> {
    const tagList = this.session.businessCategoryTagList
    this.tags = tagList
    if (!this.selectedTagCd) {
      this.selectedTagCd = this.tags[0].tagCd
    }
    this.loading = false
  }

  pageNext(): void {
    if (!this.selectedTagCd) { return }
    const sessionGroups = this.addProductQuotationService.Session.selectedGroups
    // 今のグループ以降は除外
    sessionGroups.splice(Number(this.index))
    if (sessionGroups?.filter(
      x => x.businessCategory?.tagCd === this.selectedTagCd)?.length) {
      this.messageService.templeteMessage(messageList.M00042)
      return
    }
    this.session.businessCategory = this.tags.find(x => x.tagCd === this.selectedTagCd)
    this.addProductQuotationService.setSelectedGroupSession(this.session, this.index)
    this.router.navigate([`/ura-add-product-merchandise/${this.organizationCompanyCd}/${this.contractOrganizationCd}/${this.index}`])
  }
}
