import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { BeAccount, SendResetPasswordMailRequest, UpdatePasswordRequest, UpdatePasswordResponse } from './accountsV3-api.interface'

@Injectable({
  providedIn: 'root'
})
export class AccountsV3Service {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // ニックネーム検索
  NicknameSearch(nickname: string): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/v3/Accounts/Nickname/${nickname}`
    return this.httpRequestService.get(url)
  }

  // 正式アカウントに更新
  UpdateForFomal(customerCd: string, payload: BeAccount): Observable<ResultInfo<BeAccount>> {
    const url = `${this.baseURL}api/v3/Customers/${customerCd}/UpdateForFomal`
    return this.httpRequestService.post(url, payload)
  }

  // ニックネーム変更
  NicknameUpdate(payload: any): Observable<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/v3/Accounts/NickName`
    return this.httpRequestService.put(url, payload)
  }

  // パスワード変更
  PasswordUpdate(payload: any): Observable<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/v3/Accounts/Password`
    return this.httpRequestService.put(url, payload)
  }

  // パスワード再設定メール送信
  SendResetPasswordMail(nickName: string): Observable<ResultInfo<boolean>> {
    const payload: SendResetPasswordMailRequest = { nickName }
    const url = `${this.baseURL}api/v3/Accounts/SendResetPasswordMail`
    return this.httpRequestService.post(url, payload, false)
  }

  // パスワードリセット
  ResetPassword(easyAuthenticationCd: string, password: string): Observable<ResultInfo<UpdatePasswordResponse>> {
    const payload: UpdatePasswordRequest = { easyAuthenticationCd, password }
    const url = `${this.baseURL}api/v3/Accounts/ResetPassword`
    return this.httpRequestService.post(url, payload)
  }
}
