import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { SearchMerchandisesRequest, MerchandiseTree, MerchandiseForAddContract, Merchandise, MerchandiseDetail, BackendMerchandiseSimple } from './merchandises-api.interface'
import { BackendTagMaster, Tag } from '../common/api-common.interface'

@Injectable({
  providedIn: 'root'
})
export class MerchandisesApiService {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 商品詳細取得
  GetMerchandiseDetail(merchandiseCd: string): Observable<ResultInfo<MerchandiseDetail>> {
    const url = `${this.baseURL}api/Merchandises/SearchByCd/${merchandiseCd}`
    return this.httpRequestService.get(url)
  }

  // 商品詳細 登録
  CreateMerchandise(payload: MerchandiseDetail): Observable<ResultInfo<MerchandiseDetail>> {
    const url = `${this.baseURL}api/Merchandises/Create`
    return this.httpRequestService.post(url, payload)
  }

  // 商品詳細 更新
  UpdateMerchandise(payload: MerchandiseDetail): Observable<ResultInfo<MerchandiseDetail>> {
    const url = `${this.baseURL}api/Merchandises/Update`
    return this.httpRequestService.put(url, payload)
  }

  // 小売タグ取得
  RetailTags(): Observable<ResultInfo<Tag>> {
    const url = `${this.baseURL}api/Merchandises/RetailTags`
    return this.httpRequestService.get(url)
  }

  // 業態タグ取得
  SearchBusinessCategoryTags(retailTagCd: string): Observable<ResultInfo<Tag>> {
    const url = `${this.baseURL}api/Merchandises/RetailTags/${retailTagCd}/BusinessCategoryTags`
    return this.httpRequestService.get(url)
  }

  // 小売で商品取得
  SearchRetailService(retailTagCd: string): Observable<ResultInfo<Tag>> {
    const url = `${this.baseURL}api/Merchandises/RetailTags/${retailTagCd}/ServiceSearch`
    return this.httpRequestService.get(url)
  }

  // 小売と業態で商品取得
  async SearchMerchandises(payload: SearchMerchandisesRequest): Promise<ResultInfo<MerchandiseTree>> {
    const url = `${environment.myideaMacroBaseUrl}api/merchandise/retailSearch`
    const ret$ = this.httpRequestService.post<MerchandiseTree>(url, payload)
    return await lastValueFrom(ret$)
  }
  
  // オプション追加・変更選択肢表示
  SearchOptionMerchandises(parentActuateCd: string): Observable<ResultInfo<MerchandiseForAddContract>> {
    const url = `${this.baseURL}api/Merchandises/Options/ParentActuateCd/${parentActuateCd}`
    return this.httpRequestService.get(url)
  }

  // タグマスター取得
  GetMerchandisesTagMaster(): Observable<ResultInfo<BackendTagMaster>> {
    const url = `${this.baseURL}api/Merchandises/TagMaster`
    return this.httpRequestService.get(url)
  }

  // 全商品一覧取得
  SearchList(): Observable<ResultInfo<Merchandise>> {
    const url = `${this.baseURL}api/Merchandises/SearchList`
    return this.httpRequestService.get(url)
  }

  // 商品削除
  RemoveMerchandises(merchandiseCds: string[]): Observable<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/Merchandises/Delete`
    return this.httpRequestService.post(url, merchandiseCds)
  }

  // 全商品一覧取得
  GetMerchandiseList(merchandiseDivisionCd): Observable<ResultInfo<BackendMerchandiseSimple>> {
    const url = `${this.baseURL}api/Merchandises/SearchByDivisionCd/${merchandiseDivisionCd}`
    return this.httpRequestService.get(url)
  }

  // 付与タグ選択肢取得
  GetSelecterTags(merchandiseCd): Observable<ResultInfo<BackendTagMaster>> {
    const url = `${this.baseURL}api/v4/Contracts/SelecterTags/SearchByMerchandiseCd/${merchandiseCd}`
    return this.httpRequestService.get(url)
  }
}
