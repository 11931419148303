<ng-container *ngIf="isNoLogin || login">
  <!-- ログインが必要な場合 -->
  <header class="c-header" *ngIf="!isNoLogin" [class.ura]="ura">
    <div class="c-header__inner">
      <div class="c-header__box">
        <button class="c-header__btn js-menu-trigger" (click)="menuControl()">
          <i class="c-icon__navigation"></i>
          <i class="c-icon__close"></i>
        </button>
        <h1 class="c-header__logo" [class.c-header__logo--beta]="ura">
          <a [routerLink]="!ura ? '/' : '/ura-top'" style="cursor: pointer">
            <img
              [src]="
                '/assets/images/common/' +
                (!ura ? 'header_myidea.png' : 'header_uraidea_beta.png')
              "
              [alt]="!ura ? 'My-idea' : 'Ura-idea'"
          /></a>
        </h1>
      </div>
      <div class="c-header__box">
        <div
          *ngIf="!ura"
          class="c-header__help js-panel-trigger h-pc"
          data-target=".c-header__panel-help"
        >
          <i class="c-icon__help_fill"></i>
        </div>
        <div
          class="c-header__admin js-panel-trigger"
          data-target=".c-header__panel-admin"
        >
          <p class="c-header__name h-pc">
            ようこそ
            <span id="admin-name"
              >{{ users?.familyName }} {{ users?.firstName }}</span
            >さん
          </p>
          <div
            class="c-avator"
            style="
              background-image: url(/assets/images/common/avator_default.png);
            "
          ></div>
        </div>
      </div>
    </div>
    <div
      class="c-header__panel-help c-panel-type2 js-panel-trigger h-pc"
      data-target=".c-header__panel-help"
    >
      <ul>
        <li class="c-header__panel-help__item">
          <a
            class="menu__content"
            href="https://help.idea-place.jp/"
            target="_blank"
            rel="noopener"
            >よくあるご質問</a
          >
        </li>
        <li class="c-header__panel-help__item">
          <a
            class="menu__content"
            href="http://idea-place.jp/contact/"
            target="_blank"
            rel="noopener"
            >お問い合わせ
          </a>
        </li>
      </ul>
    </div>
    <div
      class="c-header__panel-admin c-panel-type2 js-panel-trigger"
      data-target=".c-header__panel-admin"
      style="display: none; opacity: 0"
    >
      <div class="c-header__panel-admin__cell">
        <div class="c-header__panel-admin__user">
          <div
            class="c-header__panel-admin__avator c-avator"
            style="
              background-image: url(/assets/images/common/avator_default.png);
            "
          ></div>
          <div class="c-header__panel-admin__name">
            <p>{{ organizationName }}</p>
            <p>{{ users.familyName }} {{ users.firstName }}</p>
            <p class="c-texts-nickname">{{ users.nickName }}</p>
          </div>
        </div>
      </div>
      <div class="c-header__panel-admin__cell">
        <div *ngIf="superAdminMode" class="c-header__panel-admin__link">
          <a (click)="dialog2.open()" style="cursor: pointer"
            ><span>管理者モード終了</span></a
          >
        </div>
        <div class="c-header__panel-admin__link">
          <a routerLink="account-change" style="cursor: pointer"
            ><span>個人情報の変更</span></a
          >
        </div>
        <div class="c-header__panel-admin__link">
          <a (click)="dialog.open()" style="cursor: pointer"
            ><span>会社選択</span></a
          >
        </div>
        <div class="c-header__panel-admin__link">
          <a (click)="logout()" style="cursor: pointer"
            ><span>ログアウト</span></a
          >
        </div>
      </div>
    </div>
  </header>
  <!-- ログインが不要な場合 -->
  <!-- header-->
  <ng-container *ngIf="isNoLogin">
    <header class="c-header c-header--nonlogin">
      <div class="c-header__inner">
        <h1 routerLink="/" class="c-header__logo">
          <img src="/assets/images/common/interserve.png" alt="INTERSERVE" />
        </h1>
      </div>
    </header>
  </ng-container>
  <!-- /header-->
</ng-container>

<igx-dialog #dialog2 [closeOnOutsideSelect]="true" message="">
  <div class="dialog-style">
    <h2 class="c-texts--head1">管理者機能</h2>
    <p class="c-texts--head2">管理者向け組織表示を終了しますか？</p>
    <div class="l-table">
      <div class="l-table__item">
        <div class="l-table__head" style="width: 150px; min-width: 150px">
          <p>ログイン中のアカウント</p>
        </div>
        <div class="l-table__body">
          <p>{{ loginAccount?.familyName }} {{ loginAccount?.firstName }}</p>
        </div>
      </div>
    </div>
    <div class="c-btns">
      <div class="c-btns__item">
        <a
          class="js-group-close c-btn"
          (click)="dialog2.close()"
          style="cursor: pointer"
        >
          <span>キャンセル</span></a
        >
      </div>
      <div class="c-btns__item">
        <a
          class="js-group-close c-btn c-btn--blue"
          (click)="finishAdminMode()"
          style="cursor: pointer"
        >
          <span>終了</span></a
        >
      </div>
    </div>
  </div>
</igx-dialog>

<igx-dialog #dialog [closeOnOutsideSelect]="true" message="">
  <h2
    class="c-edit-modal__head c-texts--head2 h-margin--b16"
    style="padding: 20px"
  >
    会社選択
  </h2>
  <!-- <form class="signInForm orgModal"> -->
  <div class="signInForm orgModal">
    <igx-select [(ngModel)]="selectedOrganization">
      <igx-select-item
        *ngFor="let organization of organizationlist"
        [value]="organization.organizationCd"
      >
        {{ organization.organizationName }}
      </igx-select-item>
    </igx-select>
  </div>
  <!-- </form> -->
  <div igxDialogActions>
    <button
      class="c-btn c-btn--blue"
      igxButton
      (click)="onDialogOKSelected(dialog)"
    >
      選択
    </button>
  </div>
</igx-dialog>