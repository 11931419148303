import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import {
  InvitationTarget, TargetServiceInfo
} from './frimo-switch-api.model'

@Injectable({
  providedIn: 'root'
})
export class FrimoSwitchService {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 招待対象サービス取得
  GetInvitationTargetService(
    customerCd: string
  ): Observable<ResultInfo<TargetServiceInfo>> {
    const url = `${this.baseURL}api/Invitations/Customers/${customerCd}/GerServices`
    return this.httpRequestService.get(url)
  }
}
