import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CompletePageComponent } from './complete-page/complete-page.component'
import { LoadingComponent } from './loading/loading.component'
import { IgxModules } from '../igx.module'
import { InputTableComponent } from './input-table/input-table.component'
import { ReactiveFormsModule } from '@angular/forms'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { ProgressComponent } from './progress/progress.component'
import { MainContentsComponent } from './main-contents/main-contents.component'
import { UnderBtnDispComponent } from './under-btn-disp/under-btn-disp.component'
import { SendingComponent } from './sending/sending.component'
import { InputFormComponent } from './input-form/input-form.component'
import { DialogComponent } from './dialog/dialog.component'
import { TitleComponent } from './title/title.component'
import { SignupStepProgressComponent } from './signup-step-progress/signup-step-progress.component'
import { RadioComponent } from './radio/radio.component'
import { DatePickerComponent } from './date-picker/date-picker.component'
import { SelectComponent } from './select/select.component'

@NgModule({
  declarations: [
    LoadingComponent,
    CompletePageComponent,
    InputTableComponent,
    ConfirmDialogComponent,
    DialogComponent,
    ProgressComponent,
    MainContentsComponent,
    UnderBtnDispComponent,
    SendingComponent,
    InputFormComponent,
    TitleComponent,
    SignupStepProgressComponent,
    RadioComponent,
    DatePickerComponent,
    SelectComponent
  ],
  imports: [
    CommonModule,
    IgxModules,
    ReactiveFormsModule
  ],
  exports: [
    LoadingComponent,
    CompletePageComponent,
    InputTableComponent,
    ConfirmDialogComponent,
    DialogComponent,
    ProgressComponent,
    MainContentsComponent,
    UnderBtnDispComponent,
    SendingComponent,
    InputFormComponent,
    TitleComponent,
    SignupStepProgressComponent,
    RadioComponent,
    DatePickerComponent,
    SelectComponent
  ]
})
export class CommonPartsModule { }
