<ng-container *ngIf="selectedBranch">
  <app-sending *ngIf="sending"></app-sending>
  <div class="c-parent" id="parentPanel">
    <div class="c-parent__main c-parent__main--pulldown" href="#">
      <span class="c-parent__icon c-parent__icon--font"
        ><i class="c-icon__company"></i
      ></span>
      <h2 class="c-texts--head2">{{ selectedBranch?.organizationName }}</h2>
    </div>
    <!-- ↓　位置調整のためにとりあえず入れる -->
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <label class="c-form__checkbox2">
      <input
        type="checkbox"
        [(ngModel)]="isShowCustomers"
        (change)="changeShowOption()"
      /><span>ユーザーを表示</span>
    </label>
    <a class="c-btn c-btn--s" (click)="endEdit()" style="cursor: pointer"
      ><span>編集を終了</span></a
    >
  </div>
  <div class="l-main__contents js-fit-contents" (click)="closeToolTip($event)">
    <app-progress *ngIf="loading"></app-progress>

    <!-- contents-->
    <div class="c-group">
      <div class="c-group__wrap">
        <div class="c-group__head"></div>
        <div class="c-group__body">
          <!-- 左側に表示される1階層目の組織 -->
          <div class="c-group__parent">
            <div
              class="
                c-group__panel c-group__panel--default
                js-tool-panel-parent
              "
            >
              <a
                class="c-panel c-panel--s js-tool-panel-trigger"
                [style.background-color]="getTypeColor(selectedBranch)"
                [style.height]="getTypeHeight(selectedBranch)"
                (click)="clickOrgBox(selectedBranch)"
                style="cursor: pointer"
              >
                <p class="c-texts--s">
                  <b>{{ selectedBranch.organizationName }}</b>
                </p></a
              >
            </div>
          </div>
          <div
            *ngIf="
              isShowCustomers === false && selectedBranch.children.length === 0
            "
            class="c-group__children"
          >
            <div class="c-group__line">
              <div class="c-group__panel c-group__panel--add">
                <a
                  class="c-panel c-panel--s c-panel--add js-add-open"
                  (click)="openAddOrganizationDialog(selectedBranch)"
                  style="cursor: pointer; height: 38px; width: 80px"
                >
                  <i class="c-icon__add"></i><span>追加</span></a
                >
              </div>
            </div>
          </div>
          <!-- 右側に表示される2階層目以下（再帰処理のためテンプレート呼び出し） -->
          <!-- ※追加時に親組織情報が必要なのでitemを渡す -->

          <div
            *ngIf="
              selectedBranch.children.length > 0 ||
              (isShowCustomers === true &&
                selectedBranch.childrenCustomer.length > 0)
            "
          >
            <ng-container
              *ngTemplateOutlet="
                rowsTemplate;
                context: {
                  list: selectedBranch,
                  custlist: selectedBranch.childrenCustomer
                }
              "
            ></ng-container>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>

    <!-- 2階層目以下のテンプレート -->
    <ng-template #rowsTemplate let-list="list" let-custlist="custlist">
      <div class="c-group__children">
        <ng-container *ngIf="isShowCustomers === true">
          <div class="c-group__line" *ngFor="let customer of custlist">
            <div
              class="c-group__panel c-group__panel--default"
              style="height: 58px"
            >
              <a
                class="c-panel c-panel--s"
                style="height: 38px; background-color: #fff5ee; cursor: pointer"
              >
                <p class="c-texts--s">
                  <b
                    >{{ customer.familyName }} &ensp;
                    {{ customer.firstName }}</b
                  >
                </p></a
              >
            </div>
          </div>
        </ng-container>
        <div
          class="c-group__line"
          *ngFor="let item of list.children; index as i"
        >
          <div
            class="c-group__panel c-group__panel--default js-tool-panel-parent"
            style="height: 58px"
          >
            <a
              class="c-panel c-panel--s js-tool-panel-trigger"
              [style.background-color]="getTypeColor(item)"
              [style.height]="getTypeHeight(item)"
              (click)="clickOrgBox(item)"
              style="cursor: pointer"
            >
              <p class="c-texts--s">
                <b>{{ item.organizationName }}</b>
              </p></a
            >
            <div
              class="c-group__tooltip c-tool-panel__box js-tool-panel-panel"
              [class.displist]="item === selectedSection && displist"
            >
              <ul>
                <li>
                  <a
                    class="is-black js-rename-open"
                    (click)="openEditOrganizationNameDialog()"
                    style="cursor: pointer"
                  >
                    <i class="c-icon__edit is-l"></i>名前を変更する</a
                  >
                </li>
                <li *ngIf="!isShowCustomers">
                  <a
                    class="is-black"
                    (click)="moveOrganization(list,item)"
                    style="cursor: pointer"
                  >
                    <i class="c-icon__move is-l"></i>移動する</a
                  >
                </li>
                <li>
                  <a
                    class="is-remove"
                    (click)="oepnDeleteOrganization(list,item)"
                    style="cursor: pointer"
                  >
                    <i class="c-icon__delete is-l"></i>削除</a
                  >
                </li>
                <li>
                  <a
                    class="is-black"
                    (click)="openMemberListDialog()"
                    style="cursor: pointer"
                  >
                    <i class="c-icon__list is-l" style="color: #1387f3"></i
                    >メンバーを見る</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div
            *ngIf="
              item.children.length > 0 ||
              (isShowCustomers === true && item.childrenCustomer.length > 0)
            "
          >
            <ng-container
              *ngTemplateOutlet="
                rowsTemplate;
                context: { list: item, custlist: item.childrenCustomer }
              "
            ></ng-container>
          </div>

          <div *ngIf="isShowCustomers === false && item.children.length === 0 && checkAddOrganizationDialog(item)">
            <div *ngIf="!movemode">
              <div
               class="c-group__panel c-group__panel--add">
                <a
                  class="c-panel c-panel--s c-panel--add js-add-open"
                  (click)="openAddOrganizationDialog(item)"
                  style="cursor: pointer; height: 38px; width: 80px"
                >
                  <i class="c-icon__add"></i><span>追加</span></a
                >
              </div>
            </div>
            <div *ngIf="movemode">
              <div class="c-group__panel c-group__panel--move">
                <div
                  class="c-panel c-panel--s c-panel--add"
                  style="cursor: pointer; height: 38px; width: 80px"
                >
                  <i class="c-icon__add"></i><span>追加</span>
                </div>
                <a
                  class="c-panel c-panel--s c-panel--move"
                  style="cursor: pointer; height: 38px; width: 80px"
                  (click)="selectDestination(item)"
                >
                  <i class="c-icon__move"></i><span>移動</span></a
                >
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="isShowCustomers === false"
          class="c-group__line"
          style="height: 58px"
        >
          <div *ngIf="!movemode">
            <div class="c-group__panel c-group__panel--add">
              <a
                class="c-panel c-panel--s c-panel--add js-add-open"
                (click)="openAddOrganizationDialog(list)"
                style="cursor: pointer; height: 38px; width: 80px"
              >
                <i class="c-icon__add"></i><span>追加</span></a
              >
            </div>
          </div>
          <div *ngIf="movemode">
            <div class="c-group__panel c-group__panel--move">
              <div
                class="c-panel c-panel--s c-panel--add"
                style="cursor: pointer; height: 38px; width: 80px"
              >
                <i class="c-icon__add"></i><span>追加</span>
              </div>
              <a
                class="c-panel c-panel--s c-panel--move"
                (click)="selectDestination(list)"
                style="cursor: pointer; height: 38px; width: 80px"
              >
                <i class="c-icon__move"></i><span>移動</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <igx-dialog
      #editOrganizationNameDialog
      [closeOnOutsideSelect]="true"
      message=""
    >
      <div class="dialog-style">
        <h2 class="c-texts--head1">名前・種別を変更する</h2>
        <div class="l-table">
          <div class="l-table__item">
            <div class="l-table__head" style="width: 100px; min-width: 100px">
              <p>名前<span class="c-label--required">*</span></p>
            </div>
            <div class="l-table__body">
              <input
                class="c-form__input is-not-blank"
                type="text"
                [(ngModel)]="inputOrganizationName"
                required="required"
              />
            </div>
          </div>
          <div class="l-table__item">
            <div class="l-table__head" style="width: 100px; min-width: 100px">
              <p>組織種別<span class="c-label--required">*</span></p>
            </div>
            <div class="l-table__body">
              <div class="c-form__selectbox c-form__small">
                <select
                  class="c-form__select"
                  name="UpdateOrganizationType"
                  [(ngModel)]="inputOrganizationType"
                  required="required"
                >
                  <option
                    *ngFor="let item of organizationTypeList"
                    [value]="item.organizationTypeCd"
                  >
                    {{ item.organizationTypeName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="c-btns">
          <div class="c-btns__item">
            <a
              class="js-rename-close c-btn"
              (click)="editOrganizationNameDialog.close()"
              style="cursor: pointer"
            >
              <span>キャンセル</span></a
            >
          </div>
          <div class="c-btns__item">
            <a
              class="js-rename-close c-btn c-btn--blue"
              (click)="updateOrganizationName()"
              style="cursor: pointer"
            >
              <span>変更する</span></a
            >
          </div>
        </div>
      </div>
    </igx-dialog>

    <igx-dialog #addOrganizationDialog [closeOnOutsideSelect]="true" message="">
      <div class="dialog-style">
        <h2 class="c-texts--head1">組織を追加する</h2>
        <div class="l-table">
          <div class="l-table__item">
            <div class="l-table__head" style="width: 100px; min-width: 100px">
              <p>名前<span class="c-label--required">*</span></p>
            </div>
            <div class="l-table__body">
              <input
                class="c-form__input"
                type="text"
                [(ngModel)]="inputOrganizationName"
                required="required"
              />
            </div>
          </div>
          <div class="l-table__item">
            <div class="l-table__head" style="width: 100px; min-width: 100px">
              <p>組織種別<span class="c-label--required">*</span></p>
            </div>
            <div class="l-table__body">
              <div class="c-form__selectbox c-form__small">
                <select
                  class="c-form__select"
                  name="selectOrganizationType"
                  [(ngModel)]="inputOrganizationType"
                  required="required"
                >
                  <option value="" disabled="disabled" selected="selected">
                    選択してください
                  </option>
                  <option
                    *ngFor="let item of organizationTypeList"
                    [value]="item.organizationTypeCd"
                  >
                    {{ item.organizationTypeName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="c-btns">
          <div class="c-btns__item">
            <a
              class="js-add-close c-btn"
              (click)="addOrganizationDialog.close()"
              style="cursor: pointer"
            >
              <span>キャンセル</span></a
            >
          </div>
          <div class="c-btns__item">
            <a
              class="js-add-close c-btn c-btn--blue"
              (click)="addOrganization()"
              style="cursor: pointer"
            >
              <span>追加する</span></a
            >
          </div>
        </div>
      </div>
    </igx-dialog>

    <igx-dialog
      #deleteOrganizationDialog
      [closeOnOutsideSelect]="true"
      message=""
    >
      <div class="dialog-style">
        <h2 class="c-texts--head1">組織の削除</h2>
        <p class="c-texts--head2">組織を削除しますか？</p>
        <p class="c-texts--head3">※この組織の子組織も削除されます。</p>
        <div class="l-table">
          <div class="l-table__item">
            <div class="l-table__head" style="width: 100px; min-width: 100px">
              <p>名前</p>
            </div>
            <div class="l-table__body">
              <p>{{ deleteOrganizationName }}</p>
            </div>
          </div>
        </div>
        <div class="c-btns">
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn"
              (click)="deleteOrganizationDialog.close()"
              style="cursor: pointer"
            >
              <span>キャンセル</span></a
            >
          </div>
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn c-btn--blue"
              (click)="deleteOrganization()"
              style="cursor: pointer"
            >
              <span>削除</span></a
            >
          </div>
        </div>
      </div>
    </igx-dialog>

    <igx-dialog
      #memberListDialog
      [closeOnOutsideSelect]="true"
      [positionSettings]="positionSettings"
      message=""
    >
      <div class="dialog-style">
        <h2 class="c-texts--head1">
          <div>{{ selectedSection["organizationName"] }}</div>
          配属・参加メンバー一覧
        </h2>
        <div class="c-edit-modal__list c-list">
          <div class="c-list__head">
            <div
              class="c-edit-modal__cell"
              style="width: 20%; flex: none"
            ></div>
            <div
              class="c-edit-modal__cell"
              style="width: 20%; flex: none; text-align: left"
            >
              ステータス
            </div>
            <div
              class="c-edit-modal__cell"
              style="width: 60%; flex: none; text-align: left"
            >
              名前
            </div>
          </div>
          <div class="c-list__body" style="height: 357px; overflow: auto">
            <ul>
              <ng-container *ngFor="let item of memberList">
                <li class="c-list__item c-edit-modal__user">
                  <a class="c-list__line" (click)="clickMember($event, item)">
                    <div
                      class="c-edit-modal__cell c-edit-modal__check"
                      style="width: 20%; flex: none"
                    >
                      <i class="c-icon__complete"></i>
                    </div>
                    <div
                      class="c-edit-modal__cell"
                      style="width: 20%; flex: none; text-align: left"
                    >
                      {{ item.relationCategoryName }}
                    </div>
                    <div
                      class="c-edit-modal__cell"
                      style="width: 60%; flex: none; text-align: left"
                    >
                      <span>{{ item.familyName }}</span
                      ><span>{{ item.firstName }}</span>
                      <span *ngIf="item.desabled"> (選択不可)</span>
                    </div>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="c-btns">
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn"
              (click)="memberListDialog.close()"
              style="cursor: pointer"
            >
              <span>キャンセル</span></a
            >
          </div>
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn c-btn--blue"
              (click)="openAddMemberListDialog()"
              style="cursor: pointer"
            >
              <span>メンバーを追加配属</span></a
            >
          </div>
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn c-btn--blue"
              (click)="releaseMember()"
              style="cursor: pointer"
            >
              <span>配属を解除</span></a
            >
          </div>
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn c-btn--blue"
              (click)="openSectionListDialog()"
              style="cursor: pointer"
            >
              <span>他部署に異動</span></a
            >
          </div>
        </div>
      </div>
    </igx-dialog>

    <igx-dialog
      #companyMembersListDialog
      [closeOnOutsideSelect]="true"
      [positionSettings]="positionSettings"
      message=""
    >
      <div class="dialog-style dialog-expantion-style">
        <h2 class="c-texts--head1">
          <div>{{ parentCompanyName }}</div>
          所属メンバー一覧
        </h2>
        <div class="c-edit-modal__list c-list">
          <div class="c-list__head">
            <div
              class="c-edit-modal__cell"
              style="width: 20%; flex: none"
            ></div>
            <div
              class="c-edit-modal__cell"
              style="width: 15%; flex: none; text-align: left"
            >
              ステータス
            </div>
            <div
              class="c-edit-modal__cell"
              style="width: 25%; flex: none; text-align: left"
            >
              名前
            </div>
            <div
              class="c-edit-modal__cell"
              style="width: 40%; flex: none; text-align: left"
            >
              メールアドレス
            </div>
          </div>
          <div class="c-list__body" style="height: 357px; overflow: auto">
            <ul>
              <ng-container *ngFor="let item of companyMembersList">
                <li class="c-list__item c-edit-modal__user">
                  <a
                    class="c-list__line"
                    (click)="clickCompanyMember($event, item)"
                  >
                    <div
                      class="c-edit-modal__cell c-edit-modal__check"
                      style="width: 20%; flex: none"
                    >
                      <i class="c-icon__complete"></i>
                    </div>
                    <div
                      class="c-edit-modal__cell"
                      style="width: 15%; flex: none; text-align: left"
                    >
                      {{ item.authorityTypeName }}
                    </div>
                    <div
                      class="c-edit-modal__cell"
                      style="width: 25%; flex: none; text-align: left"
                    >
                      <span>{{ item.familyName }}</span
                      ><span>{{ item.firstName }}</span>
                    </div>
                    <div
                      class="c-edit-modal__cell"
                      style="width: 40%; flex: none; text-align: left"
                    >
                      {{ item.mailAddress }}
                    </div>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="c-btns">
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn"
              (click)="companyMembersListDialog.close()"
              style="cursor: pointer"
            >
              <span>キャンセル</span></a
            >
          </div>
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn c-btn--blue"
              (click)="assignMembers()"
              style="cursor: pointer"
            >
              <span>メンバーを追加</span></a
            >
          </div>
        </div>
      </div>
    </igx-dialog>

    <igx-dialog
      #sectionListDialog
      [closeOnOutsideSelect]="true"
      [positionSettings]="positionSettings"
      message=""
    >
      <div class="dialog-style">
        <h2 class="c-texts--head1">異動先の部署を選択してください</h2>
        <!-- ツリービュー -->
        <igx-tree-grid
          #treeGrid1
          [data]="selectedBranch.children"
          childDataKey="children"
          [autoGenerate]="false"
          [paging]="true"
          [perPage]="100"
          [displayDensity]="'compact'"
          [rowSelection]="'single'"
          [hideRowSelectors]="false"
          (onRowSelectionChange)="handleRowSelection($event)"
          width="550px"
          height="450px"
        >
          <igx-column
            field="organizationName"
            header="部署・WG・役割"
            [dataType]="'string'"
            [resizable]="true"
            width="500px"
          >
          </igx-column>
        </igx-tree-grid>

        <div class="c-btns">
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn"
              (click)="sectionListDialog.close()"
              style="cursor: pointer"
            >
              <span>キャンセル</span></a
            >
          </div>
          <div class="c-btns__item">
            <a
              class="js-group-close c-btn c-btn--blue"
              (click)="changeSection()"
              style="cursor: pointer"
            >
              <span>異動</span></a
            >
          </div>
        </div>
      </div>
    </igx-dialog>

    <div class="c-alert c-alert--black is-show" data-out="-1" *ngIf="movemode">
      <span>{{ movemessage }}</span>
    </div>

    <div class="c-alert" *ngIf="message">
      <i class="c-icon__check"></i><span>{{ alertmessage }}</span>
    </div>
    <div class="c-alert c-alert--black is-show" *ngIf="checkmessage">
      <span>{{ checkmessage }}</span>
    </div>
    <!-- /contents-->
  </div>
</ng-container>
