import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AutoMap } from 'src/app/common/common'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { InputOrganization } from 'src/app/module/myidea-parts/feature-organization.interface'
import { FeatureOrganizationService } from 'src/app/module/myidea-parts/feature-organization.service'
import { OrganizationProfileInputComponent } from 'src/app/module/myidea-parts/organization-profile-input/organization-profile-input.component'
import { DeepCopy } from 'src/app/common/common'
import { formCompanyItems, organizationNameInfoNoEdit } from 'src/app/module/myidea-parts/company.const'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'

@Component({
  selector: 'app-ura-new-company-profile',
  templateUrl: './ura-new-company-profile.component.html',
  styleUrls: ['./ura-new-company-profile.component.scss']
})
export class UraNewCompanyProfileComponent implements OnInit {
  @ViewChild(OrganizationProfileInputComponent, { static: false })
  private inputTable: OrganizationProfileInputComponent
  formValue: InputOrganization
  items: FormInputInfo[]
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.pageBack()
      }
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.submitOrganization()
      }
    }
  ]

  constructor(
    private router: Router,
    private featureOrganizationService: FeatureOrganizationService
  ) { }

  ngOnInit(): void {
    const serviceValue = this.featureOrganizationService.getInputValues()
    if (!serviceValue) { return }
    const value = AutoMap<InputOrganization>(serviceValue)
    value.organizationTypeCd = 'organization_type-company'
    this.formValue = value
    this.items = DeepCopy(formCompanyItems)
    const noeditOrganizationInfo = DeepCopy(organizationNameInfoNoEdit)
    this.items[2] = noeditOrganizationInfo
  }

  pageBack(): void {
    this.router.navigate([`/ura-company-add`])
  }

  async submitOrganization(): Promise<void> {
    const submitForm = this.inputTable.submit()
    if (!submitForm) {
      return
    }
    this.featureOrganizationService.setInputValues(submitForm)
    this.router.navigate([`/ura-new-company-customer`])
  }
}
