import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  @Input() title: string
  @Input() message: string
  @Input() hideCommitButton: boolean
  @Input() commitButtonText = 'OK'
  @Input() hideCancelButton = false
  @Input() cancelButtonText = 'キャンセル'
  @Input() widthSize = 'small'
  @Input() hideCloseButton = false
  @Input() subTitle: string = ''
  // 要素の外側をクリックしても、アクティブなままにする
  @Input() persistent = false
  @Output() commit = new EventEmitter<void>()
  @Output() cancel = new EventEmitter<void>()
  backAreaClass = 'js-modal-back'

  constructor() {}

  ngOnInit(): void {}

  onSubmit(): void {
    this.commit.emit()
  }

  onCancel(): void {
    this.cancel.emit()
  }

  onClick(event): void {
    if (this.persistent) {
      return
    }
    if (event.target.className.split(' ').find((x) => x === this.backAreaClass)) {
      this.onCancel()
    }
  }
}
