<div *ngIf="myControl" [(formGroup)]="myControl"
  class="width-100" [class.c-form__password]="hidePassword"
  [class.is-password-show]="hidePassword && formType === 'text'" >
  <input
    *ngIf="formType !== 'select'"
    class="c-form__input width-100"
    [formType]="formType ? formType : 'text'"
    [placeholder]="placeholder || ''"
    [required]="required"
    [maxlength]="maxLength"
    [value]="item"
    formControlName="item"
    autocomplete="off"
    [class.is-error]="formError()"
    (keyup)="keyUp()"
    (change)="change()"
    (input)="input()"
  />
  <p
    class="c-form__showpass"
    (click)="
      formType === 'password' ? (formType = 'text') : (formType = 'password')
    "
  ></p>
</div>
<div *ngIf="formType === 'select'" class="c-form__selectbox c-form__small">
  <select
    class="c-form__select"
    [required]="required"
    [value]="item"
    formControlName="item"
    [class.is-error]="formError()"
    (change)="change()"
  >
  </select>
</div>
<span *ngIf="showError && formError()" class="c-form__error">
  {{ formErrorMessage() }}
</span>
<p *ngIf="showCaption && caption" class="c-texts--caption">
  {{ caption }}
</p>
