import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-search-conditions-area',
  templateUrl: './search-conditions-area.component.html',
  styleUrls: ['./search-conditions-area.component.scss']
})
export class SearchConditionsAreaComponent implements OnInit {
  @Input() open = true
  @Output() search = new EventEmitter<void>()
  @Input() dispCountView = false
  @Input() countLoading = false
  @Input() neverCountSearch = true
  @Input() count: number

  constructor(

  ) { }

  ngOnInit(): void {

  }



}
