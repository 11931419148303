import { Component, OnInit, ViewChild } from '@angular/core'
import { CreateInvoiceTargetRequest } from 'src/api/invoice-api/invoice-api.interface'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { InvoiceMigrationContractUi } from 'src/api/invoice-migration-api/invoice-migration-api.interface'
import { InvoiceMigrationApiService } from 'src/api/invoice-migration-api/invoice-migration-api.service'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { AutoMap, DeepCopy } from 'src/app/common/common'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, TextAlignValue, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { prefectureMaster } from 'src/app/module/myidea-parts/company.const'

@Component({
  selector: 'app-invoice-contract-list',
  templateUrl: './invoice-contract-list.component.html'
})
export class InvoiceContractListComponent implements OnInit {
  title = '請求対象確認'
  data: InvoiceMigrationContractUi[] = []
  columns: Header[] = [
    {
      key: 'id',
      label: 'ID',
      hidden: true
    },
    {
      key: 'invoiceTargetCd',
      label: 'invoiceTargetCd',
      hidden: true
    },
    {
      key: 'contractorCompanyOrganizationName',
      label: '会社名',
    },
    {
      key: 'merchandiseName',
      label: 'ご利用中のサービス名',
      width: '25%'
    },
    {
      key: 'retailName',
      label: '小売',
      width: '8%'
    },
    {
      key: 'businessCategoryName',
      label: '事業',
      width: '8%'
    },
    {
      key: 'supplierCd',
      label: 'SPCD',
      width: '9%'
    },
    {
      key: 'contractPriceUi',
      label: '金額(税抜き)',
      style: {
        textAlign: TextAlignValue.rigth
      },
      width: '12%'
    },
    {
      key: 'contractDateUi',
      label: 'ご契約日',
      width: '10%'
    },
    {
      key: 'statusUi',
      label: '状態',
      width: '8%'
    },
    {
      key: 'payerConfirmDateUi',
      label: '確認日',
      width: '9%'
    },
    {
      key: 'invoiceSuppliCustomerId',
      label: '楽楽明細ID',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '11%'
    },
    {
      key: 'branchOffice',
      label: '支店名',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '10%'
    },
    {
      key: 'sectionName',
      label: '部署名',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '10%'
    },
    {
      key: 'personName',
      label: '宛先氏名',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '10%'
    },
    {
      key: 'postCode',
      label: '郵便番号',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '10%'
    },
    {
      key: 'prefecture',
      label: '都道府県',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '10%'
    },
    {
      key: 'city',
      label: '市区町村',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '10%'
    },
    {
      key: 'blockName',
      label: '番地',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '12%'
    },
    {
      key: 'telNo',
      label: '電話番号',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '10%'
    }
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '確定',
      actionCd: 'approval'
    },
    {
      label: '請求先住所更新',
      actionCd: 'change',
    }
  ]
  formItems: FormInputInfo[] = [
    {
      key: 'companyName',
      label: '会社名',
      readonly: true
    },
    {
      key: 'branchOffice',
      label: '支店名',
      placeholder: '例：大阪支店',
    },
    {
      key: 'sectionName',
      label: '部署名',
      placeholder: '例：経理部',
    },
    {
      key: 'personName',
      label: '宛先氏名',
      placeholder: '例：山田 太郎',
      valid: {
        required: true
      }
    },
    // {
    //   key: 'mailAddress',
    //   label: 'メールアドレス',
    //   placeholder: '例：myidea@interserve.co.jp',
    //   valid: {
    //     required: true,
    //     mailAddress: true
    //   }
    // },
    {
      key: 'telNo',
      label: '電話番号',
      valid: {
        required: true,
        numOnly: true,
        maxLength: 12
      },
      caption: 'ハイフンなし'
    },
    {
      key: 'postCode',
      label: '郵便番号',
      placeholder: '例：1112222',
      valid: {
        required: true,
        numOnly: true,
        maxLength: 7
      },
      caption: 'ハイフンなし'
    },
    {
      key: 'prefecture',
      label: '都道府県',
      placeholder: '経理部',
      type: 'select',
      master: prefectureMaster,
      valid: {
        required: true
      },
    },
    {
      key: 'city',
      label: '市区町村',
      placeholder: '例：渋谷区恵比寿',
      valid: {
        required: true,
      },
    },
    {
      key: 'blockName',
      label: '番地',
      placeholder: '例：1-1-9',
      valid: {
        required: true,
      },
    }
  ]
  loading = true
  sending = false
  showGrid = true
  cellClasses = {
    color_green: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'statusUi' && rowData.payerConfirmDatetime)
    },
    color_red: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'statusUi' && !rowData.payerConfirmDatetime)
    },
  }
  dialogOpen = false
  contractCds: string[] = []
  invoiceTargetCd = ''
  @ViewChild('inputTable', { read: InputTableComponent, static: false })
  public inputTable: InputTableComponent
  selectedInvoiceTarget: CreateInvoiceTargetRequest

  constructor(
    private authService: AuthService,
    private invoiceMigrationApiService: InvoiceMigrationApiService,
    private invoiceService: InvoiceService,
    private organizationCompanyApiService: OrganizationCompanyApiService,
    private confirmService: ConfirmService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getContractCompanyList()
  }
  async getContractCompanyList(): Promise<void> {
    this.data = []
    this.loading = true
    const customerCd = this.authService.AccountInfo.customerCd
    const ret = await this.invoiceMigrationApiService.getInvoiceContractStatus(customerCd)
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    const tmpData = ret.data.filter(x => x.contractPrice !== 0) // 0円商品除外
    tmpData.forEach(item => {
      if (item.payerConfirmDatetime) {
        item.statusUi = '1) 確認済'
        item.actionCds = ['change']
      } else {
        item.statusUi = '0) 未確認'
        item.actionCds = ['change', 'approval']
      }
      item.contractPriceUi = item.contractPrice === null ? '従量課金' : item.contractPrice.toLocaleString()

      item.postCode = item.postCode?.replace(/-/g, "")
      item.telNo = item.telNo?.replace(/-/g, "")
    })

    if (tmpData.find(x => !!x.title) && !this.columns.find(x => x.key === 'title')) { //請求区分があるもの
      this.columns.push(
        {
          key: 'title',
          label: '請求区分',
          groupLabel: '現在ご登録頂いている請求先の情報',
          width: '12%'
        }
      )
      this.showGrid = false
      setTimeout(() => {
        this.showGrid = true
      }, 100)
    }
    this.data = tmpData
  }

  // 確認日付更新
  async confirm(contractCds: string[]): Promise<void> {
    this.sending = true
    const confirmRet = await this.invoiceMigrationApiService.confirm(contractCds)
    this.sending = false
    if (confirmRet?.resultCode !== 0) { return }
    this.messageService.success('確定しました')
    this.getContractCompanyList()
  }

  actionCommit(event: ActionTargetInfo): void {
    if (this.sending) { return }
    if (event.actionCd === 'approval') {
      this.confirmService.confirm(`請求先を確定します。よろしいですか？
      今後はMyideaから請求内容をご確認いただけます。
      ブックマークをお願いします。`, '', async () => {
        this.contractCds = []
        event.rows.forEach(row => {
          this.contractCds.push(row.contractCd)
        })
        this.confirm(this.contractCds)
      })
    }
    if (event.actionCd === 'change') {
      this.dialogOpen = true
      this.invoiceTargetCd = event.rows[0].invoiceTargetCd
      this.showInvoiceTaget()
    }
  }

  async showInvoiceTaget(): Promise<void> {
    const formData = await this.getInvoiceTaraget(this.invoiceTargetCd)
    if (formData.title) {
      const titleItem = {
        key: 'title',
        label: '請求区分'
      }
      this.inputTable.setForm(formData, [ ...this.formItems, titleItem ])
    }
    else {
      this.inputTable.setForm(formData, this.formItems)
    }
  }

  async getInvoiceTaraget(invoiceTargetCd: string): Promise<any> {
    const invoiceTargetRet = await this.invoiceService.searchInvoiceTargets(invoiceTargetCd)
    if (invoiceTargetRet?.resultCode !== 0) { return }
    this.selectedInvoiceTarget = invoiceTargetRet.data

    const companyRet = await this.organizationCompanyApiService.GetProfileBySectionCd(this.selectedInvoiceTarget.organizationCd)
    if (companyRet?.resultCode !== 0) { return }
    const organizationFormalName = companyRet.data.organizationFormalName
    const mailAddress = companyRet.data.organizationFormalName

    const form = AutoMap<any>(this.selectedInvoiceTarget)
    form.postCode = form.postCode?.replaceAll("-", "")
    form.telNo = form.telNo?.replaceAll("-", "")
    form.companyName = organizationFormalName
    form.mailAddress = mailAddress
    return form
  }


  commit(): void {
    const invoiceTarget = this.inputTable.submit()
    if (!invoiceTarget) {
      return
    }

    this.confirmService.confirm(`請求先を更新します。よろしいですか？
    今後はMyideaから請求内容をご確認いただけます。
    ブックマークをお願いします。`, '', async () => {
      if (this.sending) { return }
      this.sending = true

      const request = DeepCopy(this.selectedInvoiceTarget)
      request.branchOffice = invoiceTarget.branchOffice
      request.sectionName = invoiceTarget.sectionName
      request.personName = invoiceTarget.personName
      request.postCode = invoiceTarget.postCode?.replaceAll("-", "")
      request.prefecture = invoiceTarget.prefecture
      request.city = invoiceTarget.city
      request.blockName = invoiceTarget.blockName
      request.title = invoiceTarget.title
      request.telNo = invoiceTarget.telNo?.replaceAll("-", "")

      const create = await this.invoiceService.invoiceTargetsCreate(request)
      this.sending = false
      if (create?.resultCode !== 0) { return }
      this.messageService.success('変更が完了しました')
      this.getContractCompanyList()
      this.dialogOpen = false
    })
  }
}
