<div class="c-parent" id="parentPanel">
  <div class="c-parent__main c-parent__main--pulldown" href="#">
    <span class="c-parent__icon c-parent__icon--font"
      ><i class="c-icon__company"></i
    ></span>
    <h2 class="c-texts--head2">
      {{ selectedChildOrganization?.organizationName }}
    </h2>
  </div>
</div>
<div class="l-main__contents js-fit-contents">
  <!-- contents-->
  <div *ngIf="isChildOrganizationList" class="l-has-btn l-has-btn--block">
    <h1 class="c-texts--head1 c-texts--nospace">配属・参加メンバー一覧</h1>
    <a class="c-btn c-btn--s" (click)="backToChart()" style="cursor: pointer">
      <span>組織情報へ戻る</span><i class="c-icon__arrow_s_right"></i>
    </a>
  </div>
  <div *ngIf="!isChildOrganizationList">
    <h1 class="c-texts--head1">所属ユーザー一覧</h1>
    <div class="l-has-btn">
      <h2 class="c-texts--head2"></h2>
      <div class="h-pc">
      </div>
    </div>
  </div>
  <div class="p-user h-margin--t24 c-texts--s">
    <div class="h-pc">
      <div *ngIf="!isChildOrganizationList" class="p-user__head">
        <p><a>名前</a></p>
        <p><a>メールアドレス</a></p>
        <p><a>企業名</a></p>
      </div>
      <div
        *ngIf="isChildOrganizationList && !isCompanyMembersListMode"
        class="p-user__head"
      >
        <p><a>名前</a></p>
        <p><a>メールアドレス</a></p>
      </div>
      <div
        *ngIf="isChildOrganizationList && isCompanyMembersListMode"
        class="p-user__head"
      >
        <p><a>名前</a></p>
        <p><a>メールアドレス</a></p>
        <p><a>配属先部署・参加中WG</a></p>
      </div>
    </div>
    <!-- <div>読み込み中</div> -->
    <app-loading *ngIf="loading"></app-loading>
    <div style="text-align: center; margin-top: 5%" *ngIf="nodata">
      <div>データがありません</div>
    </div>
    <div class="p-user__list">
      <div class="p-user__item" *ngFor="let customer of customers">
        <div class="p-user__cell p-user__cell--name">
          <div
            class="c-avator"
            style="
              background-image: url(/assets/images/common/avator_default.png);
            "
          ></div>
          <div>{{ customer.familyName }} {{ customer.firstName }}</div>
        </div>
        <div class="p-user__cell p-user__cell--mail">
          <div>{{ customer.mailAddress }}</div>
        </div>
        <div
          *ngIf="!isChildOrganizationList"
          class="p-user__cell p-user__cell--company"
          [ngClass]="organizationCd"
        >
          <div>{{ organizationName }}</div>
        </div>
        <div
          *ngIf="!isChildOrganizationList"
          class="p-user__cell p-user__cell--type"
        >
          <div>
            <a
              class="js-edit-trigger p-user__menu"
              href="#"
              [class.icon-disable]="EditDisabled"
              ><i class="c-icon__menu"></i
            ></a>
            <div class="p-user__panel js-user-panel">
              <ul>
                <li>
                  <a
                    class="is-edit js-user-edit-open"
                    (click)="nextAction(customer)"
                    style="cursor: pointer"
                    ><i class="c-icon__edit"></i>個人情報の編集</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 組織図から所属メンバ―一覧を呼び出された時 -->
        <div
          *ngIf="isChildOrganizationList && isCompanyMembersListMode"
          class="p-user__cell p-user__cell--company"
        >
          <div>{{ customer.organizationsFlat }}</div>
        </div>
      </div>
      <!-- 個人情報編集ダイアログ -->
      <div id="user-edit">
        <div class="p-user__modal js-user-edit">
          <form *ngIf="userinfo">
            <p class="c-texts--head1">
              {{ userinfo.familyName }}
              {{ userinfo.firstName }}さんの個人情報を編集
            </p>
            <div class="l-table">
              <div class="l-table__item">
                <div class="l-table__head">
                  <p>お名前</p>
                </div>
                <div class="l-table__body">
                  <div class="l-table__names">
                    <div class="l-table__name">
                      <label>姓</label>
                      <input
                        class="c-form__input"
                        type="text"
                        name="familyName"
                        [(ngModel)]="userinfo.familyName"
                      />
                    </div>
                    <div class="l-table__name">
                      <label>名</label>
                      <input
                        class="c-form__input"
                        type="text"
                        name="firstName"
                        [(ngModel)]="userinfo.firstName"
                      />
                    </div>
                  </div>
                  <div class="l-table__names">
                    <div class="l-table__name">
                      <label>セイ</label>
                      <input
                        class="c-form__input"
                        type="text"
                        name="familyNameKana"
                        [(ngModel)]="userinfo.familyNameKana"
                      />
                    </div>
                    <div class="l-table__name">
                      <label>メイ</label>
                      <input
                        class="c-form__input"
                        type="text"
                        name="firstNameKana"
                        [(ngModel)]="userinfo.firstNameKana"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="l-table__item">
                <div class="l-table__head">
                  <p>メールアドレス</p>
                </div>
                <div class="l-table__body">
                  <input
                    class="c-form__input"
                    type="text"
                    name="mailAddress"
                    [(ngModel)]="userinfo.mailAddress"
                  />
                </div>
              </div>
            </div>
            <div class="c-btns">
              <div class="c-btns__item">
                <a
                  class="c-btn js-user-edit-close"
                  href="#"
                  style="cursor: pointer"
                  ><span>キャンセル</span></a
                >
              </div>
              <div class="c-btns__item">
                <a
                  class="c-btn c-btn--blue js-user-edit-close"
                  href="#"
                  (click)="submit()"
                  style="cursor: pointer"
                  ><span>変更する</span></a
                >
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- /contents-->
    </div>
  </div>
</div>

