import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { NewApplicationStatusList } from 'src/api/easy-authentications-api/easy-authentications-api.interface'
import { ValidatorService } from 'src/app/module/common-parts/input-table/validator.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
@Component({
  selector: 'app-ura-contact-user-list',
  templateUrl: './ura-contact-user-list.component.html',
  styleUrls: ['./ura-contact-user-list.component.scss']
})
export class UraContactUserListComponent implements OnInit {
  title = '見積申込一覧'
  data: NewApplicationStatusList[] = []
  columns: Header[] = [
    {
      key: 'easyAuthenticationCd',
      label: 'easyAuthenticationCd',
      hidden: true,
    },
    {
      key: 'contractCompanyName',
      label: '契約企業',
    },
    {
      key: 'customerName',
      label: '契約者',
    },
    {
      key: 'createEstiamteDatetimeUi',
      label: '見積作成',
    },
    {
      key: 'sendEstiamteDatetimeUi',
      label: '見積送信',
    }
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '見積一覧へ',
      actionCd: 'actionCd1',
      nextPage: ['/admin-quotation/{easyAuthenticationCd}']
    }
  ]
  loading = true
  sending = false
  sendingRowKeys: string[][]

  constructor(
    private messageService: MessageService,
    private router: Router,
    private validatorService: ValidatorService,
    private easyAuthenticationsApiService: EasyAuthenticationsApiService
  ) { }

  ngOnInit(): void {
    this.getContractCompanyList()
  }
  getContractCompanyList(): void {
    this.data = []
    this.loading = true
    this.easyAuthenticationsApiService
      .GetNewApplicationStatusList()
      .subscribe((ret: ResultInfo<NewApplicationStatusList>) => {
        if (ret?.resultCode === 0) {
          const data = ret.data
          data.forEach((item) => {
            if (item.createEasyAuthenticationDatetimeUi) {
              item.createEasyAuthenticationDatetimeUi =
                this.validatorService.momentFormatDate(
                  item.createEasyAuthenticationDatetimeUi,
                  'YYYY/MM/DD'
                )
            }
            if (item.createEstiamteDatetimeUi) {
              item.createEstiamteDatetimeUi =
                this.validatorService.momentFormatDate(
                  item.createEstiamteDatetimeUi,
                  'YYYY/MM/DD'
                )
            }
            if (item.sendEstiamteDatetimeUi) {
              item.sendEstiamteDatetimeUi =
                this.validatorService.momentFormatDate(
                  item.sendEstiamteDatetimeUi,
                  'YYYY/MM/DD'
                )
            }
            item.actionCds = ['actionCd1']
          })
          this.data = data
          this.loading = false
        }
      })
  }

  actionCommit(event: ActionTargetInfo): void {

  }
}
