<div class="c-parent" id="parentPanel">
  <div class="c-parent__main c-parent__main--pulldown" style="opacity: 0;">
    <span class="c-parent__icon c-parent__icon--font"
      ><i class="c-icon__company"></i
    ></span>
    <h2 class="c-texts--head2">{{ selectedOrganizationName }}</h2>
  </div>

  <p class="h-margin--t16"></p>
</div>

<div class="l-main__contents js-fit-contents">
  <app-breadcrumbs
    label2="企業情報の確認"
    link2="/companyinfo-edit"
    label3="組織図"
  ></app-breadcrumbs>
  <app-loading *ngIf="loading"></app-loading>

  <!-- contents-->
  <div class="c-group" *ngIf="selectedBranch">
    <div class="l-has-btn">
      <div *ngIf="dummyBranchlist?.length === 0">
        <div class="c-name" id="name">
          <div class="c-name__main">
            <a
              class="c-name__select js-name-list-trigger"
              (click)="selectOrg()"
            >
              <h2 class="c-texts--head2">
                {{ selectedBranch.organizationName }}
              </h2>
              <span class="c-name__arrow"
                ><i class="c-icon__arrow_s_bottom"></i></span
            ></a>
            <div
              class="c-name__namelist js-name-list"
              [class.displist]="displist"
            >
              <ul>
                <div *ngFor="let item of branchlist; index as i">
                  <li>
                    <a (click)="changeOrg(item)" style="cursor: pointer">{{
                      item.organizationName
                    }}</a>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ul class="c-links">
        <label class="c-form__checkbox2">
          <input type="checkbox" [(ngModel)]="isShowCustomers" /><span
            >ユーザーを表示</span
          >
        </label>
      </ul>
    </div>

    <div class="c-group__wrap">
      <!-- 一番上の会社組織も組織図に入れる -->
      <div class="c-group__block">
        <div class="c-group__body">
          <!-- 左側に表示される1階層目の組織 -->
          <div class="c-group__parent">
            <div
              [class]="getStyleClass(selectedBranch)"
              class="c-group__panel c-group__panel--default"
            >
              <a
                class="c-panel c-panel--s"
                [class.currentorg]="selectedBranch === selectedSection"
                (click)="clickOrgBox(selectedBranch)"
                style="cursor: pointer"
              >
                <p class="c-texts--s">
                  <b>{{ selectedBranch?.organizationName }}</b>
                </p></a
              >
            </div>
          </div>

          <!-- 右側に表示される2階層目以下（再帰処理のためテンプレート呼び出し） -->
          <div
            *ngIf="
              selectedBranch?.children?.length > 0 ||
              (isShowCustomers === true &&
                selectedBranch?.childrenCustomer?.length > 0)
            "
          >
            <ng-container
              *ngTemplateOutlet="
                rowsTemplate;
                context: {
                  list: selectedBranch.children,
                  custlist: selectedBranch.childrenCustomer
                }
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 2階層目以下のテンプレート -->
  <ng-template #rowsTemplate let-list="list" let-custlist="custlist">
    <div class="c-group__children">
      <ng-container *ngIf="isShowCustomers === true">
        <div class="c-group__line" *ngFor="let customer of custlist">
          <div
            class="c-group__panel c-group__panel--default"
            style="height: 58px; padding-top: 10px"
          >
            <a
              class="c-panel c-panel--s"
              style="height: 38px; background-color: #fff5ee; cursor: pointer"
            >
              <p class="c-texts--s">
                <b>{{ customer.familyName }} &ensp; {{ customer.firstName }}</b>
              </p></a
            >
          </div>
        </div>
      </ng-container>
      <div class="c-group__line" *ngFor="let item of list; index as i">
        <div
          class="c-group__panel c-group__panel--default"
          [class]="getStyleClass(item)"
        >
          <a
            class="c-panel c-panel--s"
            (click)="clickOrgBox(item)"
            [class.currentorg]="item === selectedSection"
            style="cursor: pointer"
          >
            <p class="c-texts--s">
              <b>{{ item.organizationName }}</b>
            </p></a
          >
        </div>
        <div
          *ngIf="
            item.children.length > 0 ||
            (isShowCustomers === true && item.childrenCustomer.length > 0)
          "
        >
          <ng-container
            *ngTemplateOutlet="
              rowsTemplate;
              context: { list: item.children, custlist: item.childrenCustomer }
            "
          ></ng-container>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="c-btns">
    <div class="c-btns__item">
      <a class="c-btn" routerLink="/companyinfo-edit" style="cursor: pointer"
        ><span>戻る</span></a
      >
    </div>
  </div>
  <!-- /contents-->
</div>
