<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [cellClasses]="cellClasses"
    [actionButtons]="actionButtons"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>

<app-dialog
  *ngIf="showDialog"
  [message]="dialogMessage"
  width="small"
  (commit)="daialogCommit()"
  (cancel)="showDialog = false"
>
  <div class="dialog-input-area">
    <input
      [(ngModel)]="inputDate"
      style="width: 15rem"
      class="c-form__input"
      type="date"
      id="date1"
      name="date1"
    />
  </div>
</app-dialog>
