import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { lastValueFrom } from 'rxjs'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { InvitationAuthorityDivision, InvitationTarget, InvitationTargetDetail, TargetCustomer, TargetService } from 'src/api/frimo-switch-api/frimo-switch-api.model'
import { FrimoSwitchService } from 'src/api/frimo-switch-api/frimo-switch-api.service'
import { OrganizationCustomer, OrganizationFullName } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { NavService } from 'src/app/components/nav/nav.service'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { SelectedOrganizationViewService } from 'src/app/module/myidea-parts/selected-organization-view.service'
import { TargetServiceBlockInfo, ServiceMapViewBlock } from 'src/app/module/myidea-parts/service-map-view.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { MailInputComponent } from '../../../module/myidea-parts/mail-input/mail-input.component'
import { InvitationsApiService } from 'src/api/invitations-api/invitations-api.service'

@Component({
  selector: 'app-invitation-edit',
  templateUrl: './invitation-edit.component.html'
})
export class InvitationEditComponent implements OnInit {
  @ViewChild('mailInput', { read: MailInputComponent, static: false })
  public mailInput: MailInputComponent
  title = 'ユーザー招待'
  selectedServiceCd: string
  selectedServiceDetails: InvitationTargetDetail[] = []
  selectedAuthorityDivision: InvitationAuthorityDivision
  senderUserOrganizationList: OrganizationFullName[] = []
  selectedSenderOrganizationCd: string
  targetUserList: OrganizationCustomer[] = []
  invitationTargetDetails: InvitationTargetDetail[] // 対象プロダクト選択肢
  serviceMapMode = false　// サービスマップ表示
  serviceMaploading = true
  serviceMap: ServiceMapViewBlock[] = []
  selectedTopActuateCdsTemp: string[] = [] // 選択中CD(最上層)
  selectedAccountActuateCdsTemp: string[] = [] // 選択中CD(AccountBox)
  sending = false
  loading = true
  companyOrganizationCd: string
  customerCd: string
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '招待する',
      actionFunction: () => {
        this.sendInvitation()
      }
    }
  ]
  mapActionButtonsDisp: ActionButtonDisp[] = [
    {
      label: 'キャンセル',
      classes: ['c-btn-sub'],
      actionFunction: () => {
        this.pageBack()
      }
    },
    {
      label: '選択確定',
      actionFunction: () => {
        this.selectCommit()
      }
    }
  ]

  @ViewChild('cancelDialog', { static: true })
  cancelDialog: IgxDialogComponent

  constructor(
    private router: Router,
    private organizationV2Service: OrganizationV2Service,
    private authService: AuthService,
    private invitationsApiService: InvitationsApiService,
    private frimoSwitchService: FrimoSwitchService,
    private messageService: MessageService,
    private serviceMapViewService: ServiceMapViewService,
    private navService: NavService,
    private selectedOrganizationViewService: SelectedOrganizationViewService
  ) { }

  ngOnInit(): void {
    this.companyOrganizationCd = this.authService.AccountInfo.selectedOrganizationCd
    this.customerCd = this.authService.AccountInfo.customerCd
    if (this.companyOrganizationCd && this.customerCd) {
      this.serviceMaploading = true
      // サービスマップ取得
      const promise1 = new Promise(async (resolve) => {
        await this.getServiceMap()
        resolve(null)
      })
      // 招待対象サービス取得
      const promise2 = new Promise(async (resolve) => {
        await this.getInvitationTargetService()
        resolve(null)
      })
      Promise.all([promise1, promise2])
        .then(() => {
          this.serviceMaploading = false
        })

      // 招待をするときの自分の状態取得
      this.getOrganizationName()
    }
  }

  // 招待をするときの自分の状態取得
  getOrganizationName(): void {
    this.organizationV2Service.GetOrganizationFullNameList(this.customerCd).subscribe((ret: ResultInfo<OrganizationFullName>) => {
      if (ret?.resultCode === 0) {
        this.senderUserOrganizationList = ret.data
        this.selectedSenderOrganizationCd = this.senderUserOrganizationList[0].organizationCd
        const setData = {
          organizationName: this.senderUserOrganizationList[0].organizationFullName
        }
        this.selectedOrganizationViewService.setSelectedOrganizationData(setData)
        // 宛先検索用には会社のCDを渡す
        const searchOrganizationCd = this.senderUserOrganizationList[0].organizationNestCds[0]
        this.mailInput.setOrganizationCd(searchOrganizationCd)
        this.loading = false
      }
    })
  }

  changeAuthorityDivision(authorityDivision: InvitationAuthorityDivision): void {
    this.selectedAuthorityDivision = authorityDivision
  }

  updateTargetUser(userList: OrganizationCustomer[]): void {
    this.targetUserList = userList
  }

  // 招待対象サービス取得
  async getInvitationTargetService(): Promise<void> {
    // const ret = await this.frimoSwitchService.GetInvitationTargetService(this.customerCd).toPromise()
    const ret$ = this.frimoSwitchService.GetInvitationTargetService(this.customerCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.invitationTargetDetails = ret?.data[0]?.invitationTargetDetails || []
    if (!this.invitationTargetDetails?.length) {
      this.messageService.templeteMessage(messageList.M00017)
      return
    }
  }

  // サービスマップ取得
  async getServiceMap(): Promise<void> {
    const serviceMap = await this.serviceMapViewService.baseServiceMapByCustomer(this.companyOrganizationCd, this.customerCd)
    this.serviceMap = serviceMap.filter(x => x.productCd === 'frimo_core_product' || x.productCd === 'micomel_shimamura_product')
  }

  // サービスマップに選択可能情報セット
  setServiceMapSelecter(): void {
    this.serviceMap.forEach(service1 => {
      if (this.invitationTargetDetails.find(x => x.invitationServiceTargetCd === service1.actuateCd)) {
        service1.iconClass = 'add'
      }
      service1.children.forEach(service2 => {
        service2.children.forEach(service3 => {
          if (this.invitationTargetDetails.find(x => x.invitationServiceTargetCd === service3.actuateCd)) {
            service3.iconClass = 'add'
          }
        })
      })
    })
  }

  // サービスマップに選択状態反映
  setServiceMapSelected(): void {
    this.serviceMap.forEach(service1 => {
      if (this.selectedServiceDetails.find(x => x.invitationServiceTargetCd === service1.actuateCd)) {
        this.updateSelectBlock(service1, 1)
      }
      service1.children.forEach(service2 => {
        service2.children.forEach(service3 => {
          if (this.selectedServiceDetails.find(x => x.invitationServiceTargetCd === service3.actuateCd)) {
            this.updateSelectBlock(service3, 3)
          }
        })
      })
    })
  }

  // サービスマップ表示初期化(選択済状況も反映)
  async showServiceMap(): Promise<void> {
    await this.getServiceMap()
    this.setServiceMapSelecter()
    this.setServiceMapSelected()
    this.serviceMapMode = true
  }

  pageBack(): void {
    this.serviceMapMode = false
  }

  selectCommit(): void {
    this.setSelectTargetService()
    this.serviceMapMode = false
  }

  // サービスマップ上で使うactuateCdから
  // 招待用のactuateCdを取得しSET
  setSelectTargetService(): void {
    this.selectedServiceDetails = []
    this.invitationTargetDetails.forEach(detail => {
      if (this.selectedTopActuateCdsTemp.find(x => x === detail.invitationServiceTargetCd)) {
        detail.selectedAuthorityDivisionCd = detail.invitationAuthorityDivisions[0].authorityDivisionCd
        this.selectedServiceDetails.push(detail)
      }
    })
  }

  // ブロッククリック時
  clickIconEvent(value: TargetServiceBlockInfo): void {
    this.updateSelectBlock(value.targetBlock, value.floorNumber)
  }

  // ブロックの選択状態を更新
  updateSelectBlock(targetBlock: ServiceMapViewBlock, floorNumber: number): void {
    const selectedClass = 'back-blue-select'
    // 対象選択
    if (!targetBlock.classes?.find(x => x === selectedClass)) {
      targetBlock.classes.push(selectedClass)
      targetBlock.iconClass = 'delete'

      // 選択対象に保存
      this.selectedTopActuateCdsTemp.push(targetBlock.actuateCd)

      // 子要素も選択状態にする
      // クリックしたのがプロダクト(1,3階層)だった場合
      if (floorNumber === 1 || floorNumber === 3) {
        targetBlock.children.forEach(child => {
          child.classes.push(selectedClass)
          if (child.productCd === 'account_box_product') {
            this.selectedAccountActuateCdsTemp.push(targetBlock.actuateCd)
          }
        })
      }
    }
    // 対象解除
    else {
      targetBlock.classes = targetBlock.classes.filter(x => x !== selectedClass)
      targetBlock.iconClass = 'add'

      // 選択対象から除外
      this.selectedTopActuateCdsTemp = this.selectedTopActuateCdsTemp.filter(x => x !== targetBlock.actuateCd)

      // 子要素も選択解除する
      if (floorNumber === 1 || floorNumber === 3) {
        targetBlock.children.forEach(child => {
          child.classes = child.classes.filter(x => x !== selectedClass)
          if (child.productCd === 'account_box_product') {
            this.selectedAccountActuateCdsTemp = this.selectedAccountActuateCdsTemp.filter(x => x === child.productCd)
          }
        })
      }
    }
  }

  async sendInvitation(): Promise<void> {
    if (!this.invitationTargetDetails.length) {
      this.messageService.templeteMessage(messageList.M00017)
      return
    }
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd || !this.selectedSenderOrganizationCd) { return }
    if (!this.targetUserList?.length) {
      this.messageService.templeteMessage(messageList.M00015)
      return
    }
    if (!this.selectedServiceDetails?.length) {
      this.messageService.templeteMessage(messageList.M00038)
      return
    }
    if (this.sending) { return }
    this.sending = true
    const ret = await this.invitationsApiService.userInvitation(
      this.selectedSenderOrganizationCd,
      customerCd,
      this.createSendParameter()
    )
    if (ret?.resultCode !== 0) { return }
    // 　通知更新
    this.navService.updatenotificationList()
    this.messageService.templeteMessage(messageList.M00016)
    this.router.navigate(['/invitation-send-list'])
  }

  createSendParameter(): InvitationTarget {
    const payload: InvitationTarget = {
      targetServices: [],
      targetCustomers: []
    }
    this.targetUserList.forEach(user => {
      const convert: TargetCustomer = {
        targetOrganizationCd: user.organizationCd,
        targetCustomerCd: user.customerCd,
        mailAddress: user.mailAddress,
        firstName: user.firstName,
        familyName: user.familyName
      }
      payload.targetCustomers.push(convert)
    })
    this.selectedServiceDetails.forEach(detail => {
      const item: TargetService = {
        serviceCd: '', // FRIMO固定のAPIなのでセットしなくて良い
        invitaitonTargetCd: detail.invitaitonTargetCd,
        authorityDivisionCd: detail.selectedAuthorityDivisionCd
      }
      payload.targetServices.push(item)
    })
    return payload
  }
}
