import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { EstimateApiService } from 'src/api/estimates-api/estimates-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { EstimateDocumentDetail, EstimateDocumentDetailAll, EstimateDocumentDetailStatus } from 'src/api/estimates-api/estimates-api.interface'
import { ServiceMapViewBlock } from 'src/app/module/myidea-parts/service-map-view.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { FileManagementService } from 'src/app/common/file-management.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { messageList } from 'src/app/components/message/message-data'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'app-ura-add-contract-detail',
  templateUrl: './ura-add-contract-detail.component.html'
})
export class UraAddContractDetailComponent implements OnInit {
  title = '見積確認【追加契約】'
  estimateDocumentCd: string
  sending = false
  allLoading = true
  loadingAdd = true
  loadingAll = true
  loadingMap = true
  detailListAll: EstimateDocumentDetailAll[] = []
  detailListAdd: EstimateDocumentDetail[] = []
  detailStatus: EstimateDocumentDetailStatus
  serviceMap: ServiceMapViewBlock[] = []
  showTabKey = ''
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.pageBack()
      }
    }
  ]
  applicationButton: ActionButtonDisp = {
    label: '申し込みへ進む',
    actionFunction: () => {
      this.pageNext()
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private estimateApiService: EstimateApiService,
    private messageService: MessageService,
    private fileManagementService: FileManagementService,
    private serviceMapViewService: ServiceMapViewService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.estimateDocumentCd = params.get('estimateDocumentCd')
      this.getEstimateData()
    })
  }

  getEstimateData(): void {
    this.allLoading = true
    // 差分
    const promise1 = new Promise(async (resolve) => {
      this.detailListAdd = []
      this.loadingAdd = true
      // const ret = await this.estimateApiService.getEstimateDocumentDetail(this.estimateDocumentCd).toPromise()
      const ret$ = this.estimateApiService.getEstimateDocumentDetail(this.estimateDocumentCd)
      let ret = await lastValueFrom(ret$)
      
      this.loadingAdd = false
      if (ret?.resultCode !== 0) { return }
      this.detailListAdd = ret.data
      resolve(true)
    })
    // 見積書ステータス
    const promise2 = new Promise(async (resolve) => {
      this.detailStatus = null
      // const ret = await this.estimateApiService.getEstimateDocumentStatus(this.estimateDocumentCd).toPromise()
      const ret$ = this.estimateApiService.getEstimateDocumentStatus(this.estimateDocumentCd)
      let ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) { return }
      this.detailStatus = ret.data[0]
      resolve(true)
    })
    // 全体
    const promise3 = new Promise(async (resolve) => {
      this.detailListAll = []
      this.loadingAll = true
      // const ret = await this.estimateApiService.getEstimateDetailsWithAllContract(this.estimateDocumentCd).toPromise()
      const ret$ = this.estimateApiService.getEstimateDetailsWithAllContract(this.estimateDocumentCd)
      let ret = await lastValueFrom(ret$)

      this.loadingAll = false
      if (ret?.resultCode !== 0) { return }
      this.detailListAll = ret.data
      resolve(true)
    })
    // サービスマップ
    const promise4 = new Promise(async (resolve) => {
      this.serviceMap = []
      this.loadingMap = true
      // const ret = await this.estimateApiService.getEstimateDocumentServiceMap(this.estimateDocumentCd).toPromise()
      const ret$ = this.estimateApiService.getEstimateDocumentServiceMap(this.estimateDocumentCd)
      let ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) { return }
      this.serviceMap = this.serviceMapViewService.addColumnForAddServiceView(
        this.serviceMapViewService.convertBaseServiceMap(
          ret.data,
          this.detailStatus.companyOrganizationCd,
          this.detailStatus.sectionOrganizationCd)
      )
      this.loadingMap = false
      resolve(true)
    })
    Promise.all([promise1, promise2, promise3, promise4])
      .then(() => {
        if (!this.detailStatus.applicationDatetime) {
          // 申込みボタンを表示
          this.actionButtonsDisp.push(this.applicationButton)
        }
        this.allLoading = false
      })
  }

  pageNext(): void {
    if (this.sending) { return }
    this.sending = true
    this.estimateApiService.CreateContractEasyAuth(
      this.estimateDocumentCd
    ).subscribe((ret) => {
      if (ret?.resultCode === 0) {
        // セッション削除
        this.serviceMapViewService.clearKeepData(this.detailStatus.sectionOrganizationCd)
        this.sending = false
        this.messageService.templeteMessage(messageList.M00044)
        this.router.navigate(['/contract-entry-list'])
      }
    })
  }

  // PDFダウンロード
  async downloadEstimateDocumentPdfFile(): Promise<void> {
    if (this.allLoading) { return }
    this.messageService.info('処理中です。しばらくお待ちください。')
    this.allLoading = true

    // const ret = await this.estimateApiService.EstimateDocumentPathAdd(this.estimateDocumentCd).toPromise()
    const ret$ = this.estimateApiService.EstimateDocumentPathAdd(this.estimateDocumentCd)
    let ret = await lastValueFrom(ret$)

    this.allLoading = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('ダウンロードが完了しました。')
    const url = this.estimateApiService.BaseURL + ret.data[0]
    this.fileManagementService.downloadPdfFile(url)
  }

  pageBack(): void {
    this.router.navigate([`/ura-estimate-list`])
  }

}
