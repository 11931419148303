<app-sending *ngIf="sending"></app-sending>
<app-dialog
  *ngIf="dialogOpen"
  widthSize="large"
  (commit)="commit()"
  (cancel)="dialogOpen = false"
  [title]="newEditMode ? '登録' : '変更'"
  [commitButtonText]="newEditMode ? '登録' : '変更'"
>
  <form autocomplete="off">
    <div class="l-table">
      <div class="l-table__item">
        <div class="l-table__head">
          <p>製品CD</p>
        </div>
        <div class="l-table__body">
          <input
            *ngIf="newEditMode"
            class="c-form__input"
            type="text"
            id="productCd"
            name="productCd"
            [(ngModel)]="productDetail.productCd"
          />
          <span *ngIf="!newEditMode">
            {{ productDetail.productCd }}
          </span>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <div>短縮CD</div>
          <p class="sub-header-text">※プロダクトコードに利用されます</p>
        </div>
        <div class="l-table__body">
          <input
            class="c-form__input"
            type="text"
            id="productAbbreviatedCd"
            name="productAbbreviatedCd"
            [(ngModel)]="productDetail.productAbbreviatedCd"
          />
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>製品名</p>
        </div>
        <div class="l-table__body">
          <input
            class="c-form__input"
            type="text"
            id="productName"
            name="productName"
            [(ngModel)]="productDetail.productName"
          />
        </div>
      </div>
      <div class="l-table__item" *ngFor="let selecterInfo of selecterInfoList">
        <div class="l-table__head">
          <p>{{ selecterInfo.label }}</p>
        </div>
        <div class="l-table__body">
          <div class="l-table__btns">
            <div
              class="l-table__btn l-table__btn--auto"
              *ngFor="let selecter of selecterInfo.selecter; let i = index"
            >
              <label class="c-form__radio">
                <input
                  [value]="selecter.value"
                  type="radio"
                  [id]="selecterInfo.key + i"
                  [name]="selecterInfo.key + i"
                  #selectsetting="ngModel"
                  [(ngModel)]="productDetail.selecterSetting[selecterInfo.key]"
                  (click)="changeSelecter(selecterInfo.key, selectsetting)"
                /><span style="cursor: pointer">{{ selecter.label }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>子製品</p>
        </div>
        <div class="l-table__body">
          <a
            (click)="childEditMode = !childEditMode"
            class="c-btn--s edit-tag-btn"
            style="cursor: pointer"
            ><span>{{ childEditMode ? "追加完了" : "追加" }}</span></a
          >
          <div>
            <div *ngFor="let children of productDetail.childrenProduct">
              {{ children.productName }}
              <a
                style="cursor: pointer"
                (click)="removeChild(children.productCd)"
                >×
              </a>
            </div>
          </div>
          <ng-container *ngIf="childEditMode">
            <hr style="margin-top: 43px" />
            <div>追加製品選択</div>
            <input
              class="c-form__input"
              type="text"
              #searchchild
              ngModel
              name="searchchild"
              autocomplete="off"
              placeholder="製品名で検索"
              (keyup)="searchChildProduct(searchchild.value)"
              (change)="searchChildProduct(searchchild.value)"
            />
            <a
              class="tag"
              (click)="childClick(childSelecter.productCd)"
              *ngFor="let childSelecter of childSelection"
              style="cursor: pointer"
            >
              {{ childSelecter.productName }}
            </a>
            <span *ngIf="childSelectionAndMore">…</span>
          </ng-container>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>タグ</p>
        </div>
        <div class="l-table__body">
          <a
            (click)="tagEditMode = !tagEditMode"
            class="c-btn--s edit-tag-btn"
            style="cursor: pointer"
            ><span>{{ tagEditMode ? "編集モードを終了" : "編集" }}</span></a
          >
          <ng-container *ngIf="!tagEditMode">
            <a class="tag tag-select" *ngFor="let tag of productDetail.tags">
              {{ tag.tagText }}
            </a>
          </ng-container>
          <ng-container *ngIf="tagEditMode">
            <div class="" *ngFor="let tagItem of tagMaster">
              <div class="purpose-tag-label">
                <p>{{ tagItem.tagPurposeText }}</p>
              </div>
              <div class="">
                <a
                  class="tag"
                  (click)="tagClick(textTag.tagCd)"
                  style="cursor: pointer"
                  [class.tag-select]="tagAttachment(textTag.tagCd)"
                  *ngFor="let textTag of tagItem.textTags"
                >
                  {{ textTag.tagText }}
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>稼働設定項目</p>
        </div>
        <div class="l-table__body">
          <a
            (click)="addActuateSetting()"
            class="c-btn--s edit-tag-btn"
            style="cursor: pointer"
            ><span> 行追加 </span></a
          >
          <div *ngFor="let setting of productDetail.actuateSettings; let i = index">
            {{ i + 1 }}:
            <input
              class="c-form__input setting-input"
              type="text"
              [id]="'key' + i"
              [name]="'key' + i"
              placeholder="Key 例)XxCd"
              [(ngModel)]="setting.key"
              style="width: 15rem"
            />
            <input
              class="c-form__input setting-input"
              type="text"
              [id]="'name' + i"
              [name]="'name' + i"
              placeholder="項目名"
              [(ngModel)]="setting.name"
              style="width: 15rem"
            />
            <input
              class="c-form__input setting-input"
              type="text"
              [id]="'value' + i"
              [name]="'value' + i"
              placeholder="値"
              [(ngModel)]="setting.value"
              style="width: 5rem"
            />
            <a style="cursor: pointer" (click)="removeActuateSetting(i)">×</a
            ><br />
            <div style="margin-left: 2rem; margin-bottom: 0.1rem">
              タイプ：
              <select
                class="selecter-input"
                style="width: 10rem; margin-left: 8.9rem"
                [name]="'type' + i"
                [(ngModel)]="setting.type"
              >
                <option [selected]="setting.type == 'Number'" value="Number">
                  数値
                </option>
                <option [selected]="setting.type == 'String'" value="String">
                  文字
                </option>
              </select>
            </div>
            <div style="margin-left: 2rem; margin-bottom: 1rem">
              設定タイミング：
              <select
                class="selecter-input"
                style="width: 20rem; margin-left: 2.9rem"
                [name]="'editable' + i"
                [(ngModel)]="setting.editable"
              >
                <option [selected]="setting.editable == 0" value="0">
                  見積もり時
                </option>
                <option [selected]="setting.editable == 1" value="1">契約時</option>
                <option [selected]="setting.editable == 2" value="2">
                  サービスログイン時
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-dialog>
