import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { SelectOption } from './select.component.interface'

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    }
  ]
})
export class SelectComponent implements OnInit {
  @Input() options: SelectOption[] = []
  @Input() emptyOption = false
  @Input() emptyOptionLabel = '選択してください'

  _value: string
  constructor() { }

  ngOnInit(): void {
  }
  private onTouchedCallback: () => void = () => {}
  private onChangeCallback: (_: any) => void = () => {}
  
  get value(): string {
    return this._value
  }

  @Input('value')
  set value(text: string) {
    if (this._value !== text) {
      this._value = text
      this.onChangeCallback(text)
    }
  }

  writeValue(text: string): void {
    if (text !== this.value) {
      this.value = text
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
