import { Component, Input, OnInit } from '@angular/core'
import { NameCardListModel } from 'src/api/namecard-api/namecard-api.interface'

@Component({
  selector: 'app-name-card-item',
  templateUrl: './name-card-item.component.html',
  styleUrls: ['./name-card-item.component.scss']
})
export class NameCardItemComponent implements OnInit {
  @Input() card: NameCardListModel
  constructor(
  ) { }
  ngOnInit(): void {}
}
