<app-dialog
  [title]="title"
  [message]="message"
  [hideCommitButton]="hideCommitButton"
  [commitButtonText]="commitButtonText"
  [hideCancelButton]="hideCancelButton"
  [cancelButtonText]="cancelButtonText"
  [widthSize]="widthSize"
  [hideCloseButton]="hideCloseButton"
  [persistent]="persistent"
  (commit)="onSubmit()"
  (cancel)="onCancel()"
>
  <ng-content></ng-content>
</app-dialog>
