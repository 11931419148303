import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { AuthorityTable } from 'src/api/authorities-api/authorities-api.interface'
import { SearchServiceMapRequest } from 'src/api/conta-api/conta-api.interface'
import { ContaApiService } from 'src/api/conta-api/conta-api.service'
import { ServiceMapMacro } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { ActuateRelationRuleForUi, ProductLevelMerchandise, ServiceMapBlock } from 'src/api/service-map-api/service-map-api.interface'
import { ServiceMapApiService } from 'src/api/service-map-api/service-map-api.service'
import { AutoMap, DeepCopy, GroupBy, JoinText } from 'src/app/common/common'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { ServiceMapKeepData, ServiceMapViewBlock, TargetActionBlockInfo, TargetServiceBlockInfo } from './service-map-view.interface'

@Injectable({
  providedIn: 'root'
})
export class ServiceMapViewService {
  // サービスマップのAPIレスポンス
  private serviceMapFromApi: ServiceMapBlock[]
  private keepData: ServiceMapKeepData[] = []
  private productLevelMerchandises: ProductLevelMerchandise[]
  private actuateRelationRules: ActuateRelationRuleForUi[]

  constructor(
    private authService: AuthService,
    private contaApiService: ContaApiService,
    private serviceMapApiService: ServiceMapApiService
  ) { }

  getKeepData(organizationCd: string): ServiceMapKeepData {
    const target = this.keepData.find(x => x.organizationCd === organizationCd)
    return !target ? null : DeepCopy(target)
  }

  // 保存済情報をクリア
  clearKeepData(organizationCd?: string): void {
    if (organizationCd) {
      this.keepData = this.keepData.filter(x => x.organizationCd !== organizationCd)
    }
    // CDが渡ってこなかった場合は念のため全削除
    else {
      this.keepData = []
    }
  }

  // 保存済情報をクリア (顧客単位)
  clearCustomerMap(): void {
    this.serviceMapFromApi = null
  }

  async loadMaster(): Promise<void> {
    await this.loadActuateRelationRules()
    await this.loadProductLevelMerchandises()
  }

  async loadActuateRelationRules(): Promise<void> {
    if (this.actuateRelationRules) { return }
    const key = 'actuateRelationRules'
    const strage = sessionStorage.getItem(key)
    if (strage) {
      this.actuateRelationRules = JSON.parse(strage)
      return
    }
    // const ret = await this.serviceMapApiService.GetActuateRelationRules().toPromise()
    const ret$ = this.serviceMapApiService.GetActuateRelationRules()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    sessionStorage.setItem(key, JSON.stringify(ret.data))
    this.actuateRelationRules = ret.data
  }

  get ActuateRelationRules(): ActuateRelationRuleForUi[] {
    return this.actuateRelationRules
  }

  async loadProductLevelMerchandises(): Promise<void> {
    if (this.productLevelMerchandises) { return }
    const key = 'productLevelMerchandises'
    const strage = sessionStorage.getItem(key)
    if (strage) {
      this.productLevelMerchandises = JSON.parse(strage)
      return
    }
    const ret$ = this.serviceMapApiService.GetProductLevelMerchandises()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    sessionStorage.setItem(key, JSON.stringify(ret.data))
    this.productLevelMerchandises = ret.data
  }

  get productLevelMerchandiseCds(): string[] {
    const ret: string[] = []
    this.productLevelMerchandises.forEach(merchandise => {
      ret.push(merchandise.merchandiseCd)
    })
    return ret
  }

  get topMerchandiseCds(): string[] {
    const ret: string[] = []
    this.productLevelMerchandises.forEach(merchandise => {
      if (merchandise.isTop) {
        ret.push(merchandise.merchandiseCd)
      }
    })
    return ret
  }

  // ▼▼▼▼▼▼▼▼▼▼▼▼▼▼ APIからマップデータ取得 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼
  // APIからサービスマップ取得(customerCd検索)
  async getServiceMapFromApi(customerCd: string): Promise<ServiceMapBlock[]> {
    this.loadMaster() // マスタ取得
    if (this.serviceMapFromApi) {
      return DeepCopy(this.serviceMapFromApi)
    }
    const ret = await this.serviceMapApiService.SearchByCustomerCd(customerCd)
    if (ret?.resultCode !== 0) { return [] }
    this.serviceMapFromApi = ret.data
    return DeepCopy(ret.data)
  }

  // APIからサービスマップ取得(organizationCd検索)
  async getServiceMapSearchByOrgFromApi(organizationCd: string): Promise<ServiceMapBlock[]> {
    this.loadMaster() // マスタ取得
    const targetOrgMap = this.keepData.find(x => x.organizationCd === organizationCd)
    if (targetOrgMap?.apiServiceMap) {
      return DeepCopy(targetOrgMap.apiServiceMap)
    }
    const ret = await this.serviceMapApiService.SearchByOrganizationCd(organizationCd)
    if (ret?.resultCode !== 0) { return [] }
    const keep = this.keepData.find(x => x.organizationCd === organizationCd)
    if (keep) {
      keep.apiServiceMap = ret.data
    }
    else {
      this.keepData.push(
        {
          organizationCd,
          apiServiceMap: ret.data
        }
      )
    }
    return DeepCopy(ret.data)
  }
  // APIからサービスマップ取得(Conta検索)+変換
  async getContaServiceMapFromApi(
    req: SearchServiceMapRequest,
    companyOrganizationCd?: string
  ): Promise<[ServiceMapViewBlock[], string[]]> {
    this.loadMaster() // マスタ取得
    const ret = await this.contaApiService.SearchServiceMaps(req)
    if (ret?.resultCode !== 0) { return null }
    let map = this.convert4Lebel(this.convertBaseMapV2(ret.data.mapBlocks))
    map = this.addBaseInfo(map, companyOrganizationCd)
    return [map, ret.data.targetActuateCds]
  }
  // ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲

  // 接続済に絞る
  filterConnected(map: ServiceMapViewBlock[]): ServiceMapViewBlock[] {
    const ret: ServiceMapViewBlock[] = []
    map.forEach(block => {
      if (this.topMerchandiseCds.find(x => x === block.merchandiseCd) || block.parentActuateCds?.length) {
        ret.push(block)
      }
    })
    return ret
  }

  // 未接続に絞る
  filterUnconnected(map: ServiceMapViewBlock[]): ServiceMapViewBlock[] {
    const ret: ServiceMapViewBlock[] = []
    map.forEach(block => {
      if (!this.topMerchandiseCds.find(x => x === block.merchandiseCd) && !block.parentActuateCds?.length) {
        ret.push(block)
      }
    })
    return ret
  }

  // 全マップ(顧客で検索)
  async baseServiceMapByCustomer(companyOrganizationCd: string, customerCd: string): Promise<ServiceMapViewBlock[]> {
    const apiMapData = await this.getServiceMapFromApi(customerCd)
    const auth = this.authService.AuthorityTable.filter(x => x.authorityDivisionCd !== 'user')
    let map = this.convertBaseMapV2(apiMapData)
    map = this.authFilter(map, auth)
    map = this.convert4Lebel(map)
    map = this.addBaseInfo(map, companyOrganizationCd)
    return map
  }

  convertBaseServiceMap(
    apiMapData: ServiceMapBlock[],
    companyOrganizationCd: string,
    sectionOrganizationCd: string): ServiceMapViewBlock[] {
    let map = this.convertBaseMapV2(apiMapData)
    // 契約部署の組織を権限として追加
    const auth: AuthorityTable = {
      targetCd: sectionOrganizationCd ?? companyOrganizationCd,
      targetName: null,
      authorityDivisionCd: ''
    }
    map = this.authFilter(map, [auth])
    map = this.convert4Lebel(map)
    map = this.addBaseInfo(map, companyOrganizationCd)
    return map
  }

  addBaseInfo(
    map: ServiceMapViewBlock[], companyOrganizationCd?: string): ServiceMapViewBlock[] {
    const func = (value: TargetServiceBlockInfo) => {
      const service = value.targetBlock
      const floorNumber = value.floorNumber
      const parentService = value.parentBlock
      service.classes = []
      service.showSubInfo = true

      // 表示テキストに取引先6桁コードを入れる
      if (this.topMerchandiseCds.find(x => x == service.merchandiseCd)) {
        const businessCategoryCdText = service?.businessCategoryCd ? ('000' + service.businessCategoryCd).slice(-2) : ''
        let cd = JoinText([service?.supplierCd, businessCategoryCdText], '')
        service.blockText = cd
      }

      if (!companyOrganizationCd) { return }
      if (floorNumber === 1 || floorNumber === 3 || floorNumber === 5) {
        service.blockText = JoinText([service.blockText, this.showSubName(service, companyOrganizationCd) ?? ''], ' ')
      }
      this.getBasicClasses(service, companyOrganizationCd, floorNumber)
      if (floorNumber === 2 || floorNumber === 4) {
        if (parentService?.notContractCompany) {
          service.notContractCompany = true
          service.classes.push('back-gray')
        }
      }
    }
    this.runForAllServiceFloor(map, func)
    return map
  }

  authFilter(
    map: ServiceMapViewBlock[],
    auth: AuthorityTable[]): ServiceMapViewBlock[] {
    let ret = this.filterChildBlockRecursive(map, auth)
    ret = ret.filter(x => x.isShow)
    return ret
  }

  filterChildBlockRecursive(
    map: ServiceMapViewBlock[],
    auth: AuthorityTable[],
    parent?: ServiceMapViewBlock
  ): ServiceMapViewBlock[] {
    map.forEach(block => {
      if (auth.find(x => x.targetCd === parent?.actuateCd) // プロダクト階層のActuateCd権限がある→スーパーユーザー
        || auth.find(x => x.targetCd === block?.actuateCd)
        || auth.find(x => x.targetCd === block.contractSectionOrganizationCd) // この階層の契約者権限がある
      ) {
        block.isShow = true
        block.hasAuth = true
      }
      if (parent && parent.hasAuth) {
        block.isShow = true
      }
      block.children = this.filterChildBlockRecursive(block.children, auth, block)
      block.children = block.children.filter(x => x.isShow)
      if (block.children.find(x => x.isShow && (x.serviceCd === block.serviceCd || x.hasAuth))) {
        block.isShow = true
      }
    })
    return map
  }

  // APIデータ→基本表示 変換 (6階層)
  convertBaseMapV2(apiBlocks: ServiceMapBlock[]): ServiceMapViewBlock[] {
    let ret: ServiceMapViewBlock[] = []
    this.topMerchandiseCds.forEach(topMerchandiseCd => {
      const topBlocks = apiBlocks.filter(x => x.merchandiseCd === topMerchandiseCd)
      const maps = this.convertFlatForHierarchyRecursive(apiBlocks, topBlocks, 0)
      if (maps.length) {
        ret = [...ret, ...maps]
      }
    })
    const remains = this.remainBlocks(apiBlocks, ret, 0)
    this.productLevelMerchandiseCds.forEach(merchandiseCd => {
      const topBlocks = remains.filter(x => x.merchandiseCd === merchandiseCd)
      const maps = this.convertFlatForHierarchyRecursive(apiBlocks, topBlocks, 0)
      if (maps.length) {
        ret = [...ret, ...maps]
      }
    })
    return ret
  }

  // 6階層→4階層 変換
  convert4Lebel(map: ServiceMapViewBlock[]): ServiceMapViewBlock[] {
    map = this.removeOtherServiceBlock(map)
    const remain = this.convert4LebelRecursive(map, 0)
    map.push(...remain)
    // 同一ランチャーが複数登場するので重複削除
    const ret: ServiceMapViewBlock[] = []
    map.forEach(topBlock => {
      if (!ret.find(x => x.actuateCd === topBlock.actuateCd)) {
        ret.push(topBlock)
      }
    })
    return ret
  }
  // 6階層→4階層 変換 再起
  convert4LebelRecursive(map: ServiceMapViewBlock[], floorNumber: number): ServiceMapViewBlock[] {
    floorNumber += 1
    const remain = []
    map.forEach(block => {
      if (floorNumber > 3) {
        remain.push(...block.children)
        block.children = []
      }
      else {
        remain.push(...this.convert4LebelRecursive(block.children, floorNumber))
      }
    })
    return remain
  }

  // 他サービスの子要素をカット
  removeOtherServiceBlock(map: ServiceMapViewBlock[], parentServiceCd?: string, diff? :boolean): ServiceMapViewBlock[] {
    map.forEach(block => {
      if (diff) {
        block.children = []
      }
      else {
        if (parentServiceCd && block.serviceCd !== parentServiceCd) {
          diff = true
        }
        block.children = this.removeOtherServiceBlock(block.children, block.serviceCd, diff)
      }
    })
    return map
  }

  // フラット→階層変換 再起
  convertFlatForHierarchyRecursive(apiData: ServiceMapBlock[], retBlocks: ServiceMapBlock[], count: number): ServiceMapViewBlock[] {
    if (count > 8) { return } // 無限ループを防ぐ 念のため
    count += 1
    const ret: ServiceMapViewBlock[] = []
    retBlocks.forEach(thisBlock => {
      const childs = apiData.filter(x => x.parentActuateCds && x.parentActuateCds.find(x => x === thisBlock.actuateCd))
      let hierarchyChilds = this.convertFlatForHierarchyRecursive(apiData, childs, count)
      const thisServiceCd = this.productLevelMerchandises.find(x => x.merchandiseCd == thisBlock.merchandiseCd)?.serviceTagCd
      if (thisServiceCd) {
        hierarchyChilds.forEach(child => {
          if (!child.serviceCd) {
            child.serviceCd = thisServiceCd
          }
        })
      }
      const newThisBlock: ServiceMapViewBlock = {
        actuateCd: thisBlock.actuateCd,
        parentActuateCds: thisBlock.parentActuateCds,
        serviceCd: this.productLevelMerchandises.find(x => x.merchandiseCd == thisBlock.merchandiseCd)?.serviceTagCd,
        isLauncher: !!(this.ActuateRelationRules.find(x => x.toProductCd == thisBlock.productCd && x.fromMultiple)),
        actuateName: thisBlock.actuateName,
        supplierCd: thisBlock.supplierCd,
        businessCategoryCd: this.businessCategoryNumber(thisBlock.businessCategoryCd),
        contractOrganizationCd: thisBlock.contractCompanyOrganizationCd,
        contractCompanyOrganizationName: thisBlock.contractCompanyOrganizationName,
        contractSectionOrganizationCd: thisBlock.contractSectionOrganizationCd,
        contractSectionCompanyOrganizationName: thisBlock.contractSectionOrganizationName,
        productCd: thisBlock.productCd,
        productInquiryCd: thisBlock.productInquiryCd,
        merchandiseCd: thisBlock.merchandiseCd,
        merchandiseName: thisBlock.merchandiseName,
        merchandiseDisplayName: thisBlock.merchandiseDisplayName,
        contractCd: thisBlock.contractCd,
        purchaserCd: thisBlock.purchaserCd,
        purchaserName: thisBlock.purchaserName,
        children: hierarchyChilds
      }
      ret.push(newThisBlock)
    })
    return ret
  }

  // 余ったブロックを取得 (未接続表示用)
  remainBlocks(apiBlocks: ServiceMapBlock[], blocks: ServiceMapViewBlock[], count: number): ServiceMapBlock[] {
    if (count > 8) { return } // 無限ループを防ぐ 念のため
    count += 1
    blocks.forEach(block => {
      apiBlocks = apiBlocks.filter(x => x.actuateCd !== block.actuateCd)
      if (block.children?.length) {
        apiBlocks = this.remainBlocks(apiBlocks, block.children, count)
      }
    })
    return apiBlocks
  }

  businessCategoryNumber(businessCategoryCd: string): string {
    switch (businessCategoryCd) {
      case 'shimamura': return '01'
      case 'avail': return '02'
      case 'shimura': return '03'
      case 'birthday': return '04'
      case 'chambre': return '05'
      case 'divalo': return '06'
      default: return ''
    }
  }
  businessCategoryName(businessCategoryCd: string): string {
    switch (businessCategoryCd) {
      case '01': return 'しまむら'
      case '02': return 'アベイル'
      case '03': return '思夢楽'
      case '04': return 'バースデイ'
      case '05': return 'シャンブル'
      case '06': return 'ディバロ'
      default: return ''
    }
  }

  // 業態名＋6桁コード
  divisionName(service: ServiceMapMacro): string {
    const businessCategoryNameText = this.businessCategoryName(service.businessCategoryCd)
    return `${businessCategoryNameText} ${this.shimamuraSupplierCd(service)}`
  }

  // 取引先コード(6桁)
  shimamuraSupplierCd(service: ServiceMapMacro): string {
    const supplierCdText = service?.supplierCd ?? ''
    const businessCategoryCdText = service?.businessCategoryCd ? ('000' + service.businessCategoryCd).slice(-2) : ''
    return `${supplierCdText}${businessCategoryCdText}`
  }

  // 表示用モデルに専用値追加 (Ura サービスマップ検索画面用)
  addColumnForSearchServiceMapView(serviceMap: ServiceMapViewBlock[], targetActuateCds: string[]): ServiceMapViewBlock[] {
    const func = (value: TargetServiceBlockInfo) => {
      const targetBlock = value.targetBlock
      targetBlock.showSubInfo = true
      if (targetActuateCds.find(x => x === targetBlock.actuateCd)) {
        targetBlock.classes = ['back-green']
      }
    }
    this.runForAllServiceFloor(serviceMap, func)
    return serviceMap
  }

  // 表示用モデルに専用値追加 (Ura 利用者メンテナンス画面用)
  addColumnForUserMaintenanceView(serviceMap: ServiceMapViewBlock[]): ServiceMapViewBlock[] {
    const func = (value: TargetServiceBlockInfo) => {
      const targetBlock = value.targetBlock
      targetBlock.showSubInfo = true
      if (targetBlock.productCd === 'account_box_product' || targetBlock.productCd === 'products-garage_account_box_product') {
        targetBlock.classes = ['back-green']
      }
    }
    this.runForAllServiceFloor(serviceMap, func)
    return serviceMap
  }

  // 表示用モデルに専用値追加 (Ura 追加見積もり結果表示用)
  addColumnForAddServiceView(serviceMap: ServiceMapViewBlock[]): ServiceMapViewBlock[] {
    const func = (value: TargetServiceBlockInfo) => {
      const targetBlock = value.targetBlock
      if (!targetBlock.contractCd) {
        targetBlock.classes = ['back-green']
      }
      else {
        targetBlock.showSubInfo = true
      }
    }
    this.runForAllServiceFloor(serviceMap, func)
    return serviceMap
  }

  // メモ編集のフラグを追加
  addColumnCanEditMemoView(serviceMap: ServiceMapViewBlock[]): ServiceMapViewBlock[] {
    serviceMap.forEach(service1 => {
      if (service1.productCd === 'products-garage_core_product') {
        service1.canEditMemo = true
        service1.memoLabel = '名称'
      }
      service1.children.forEach(service2 => {
        service2.children.forEach(service3 => {
          if (service3.hasAuth) {
            service3.canEditMemo = true
            service3.memoLabel = '出荷拠点名'
            service3.memoExampleText = '入力例：○○物流倉庫、○○センター、○○支店'
          }
        })
      })
    })
    return serviceMap
  }

  // 専用値追加 解約サービスマップ用
  addColumnForCancel(serviceMap: ServiceMapViewBlock[], organizationCompanyCd: string): ServiceMapViewBlock[] {
    const func = (value: TargetServiceBlockInfo) => {
      const targetBlock = value.targetBlock
      if (value.floorNumber === 2 || value.floorNumber === 4) {
        // Abox
        if (targetBlock.productCd === 'account_box_product'
          || targetBlock.productCd === 'products-garage_account_box_product'
          || targetBlock.productCd === 'products-garage_product_info_product'
          || targetBlock.productCd === 'products-garage_item_info_product'
          || targetBlock.productCd === 'products-garage_data_box_product'
          || targetBlock.productCd === 'products-garage_file_box_product') {
          return
        }
      }
      if (this.showActive(targetBlock, organizationCompanyCd)) {
        targetBlock.iconClass = 'delete'
      }
    }
    this.runForAllServiceFloor(serviceMap, func)
    return serviceMap
  }

  // 専用値追加 サービス接続サービスマップ用
  async addColumnForConnect(serviceMap: ServiceMapViewBlock[], organizationCompanyCd: string): Promise<ServiceMapViewBlock[]> {
    const rules = this.ActuateRelationRules
    const mapAll = serviceMap
    const func = (value: TargetServiceBlockInfo) => {
      if (value.targetBlock.hasAuth) {
        // From側の場合
        if (rules.find(x => x.fromProductCd === value.targetBlock.productCd)) {
          if (!value.targetBlock.children?.length && !mapAll.find(x => x.parentActuateCds.find(y => y === value.targetBlock.actuateCd))) {
            // 未接続のコネクタをハイライト
            value.targetBlock.classes.push('unconnected-block')
          }
          else {
            // 接続済のコネクタをハイライト
            value.targetBlock.classes.push('connected-block')
          }
        }
        else {
          const toRule = rules.find(x => x.toProductCd === value.targetBlock.productCd)
          if (!toRule) { return }
          if (value.parentBlock) {
            if (!toRule.fromMultiple) { return }
            // 接続済の接続先(ランチャー)をハイライト
            value.targetBlock.classes.push('connected-block')
          }
          else {
            // 未接続の接続先(Pack、ランチャー)をハイライト
            value.targetBlock.classes.push('unconnected-block')
          }
        }
      }
    }
    this.runForAllServiceFloor(serviceMap, func)
    return serviceMap
  }

  // 基本クラス付与 (参照画面の基本クラス)
  getBasicClasses(service: ServiceMapViewBlock, organizationCompanyCd: string, floorNumber: number): void {
    if (!this.showActive(service, organizationCompanyCd)) {
      service.notContractCompany = true
      service.classes.push('back-gray')
    }

    // クリック無効クラス付与
    if (!service.hasAuth) {
      service.classes.push('no-click')
    }
    return
  }

  // 全サービス階層に対して処理実行
  runForAllServiceFloor(serviceMap: ServiceMapViewBlock[], func: (param: TargetServiceBlockInfo) => void): void {
    serviceMap?.forEach(block1 => {
      const par1: TargetServiceBlockInfo = {
        targetBlock: block1, parentBlock: null, floorNumber: 1
      }
      func(par1)
      block1.children?.forEach(block2 => {
        const par2: TargetServiceBlockInfo = {
          targetBlock: block2, parentBlock: block1, floorNumber: 2
        }
        func(par2)
        block2.children?.forEach(block3 => {
          const par3: TargetServiceBlockInfo = {
            targetBlock: block3, parentBlock: block2, floorNumber: 3
          }
          func(par3)
          block3.children?.forEach(block4 => {
            const par4: TargetServiceBlockInfo = {
              targetBlock: block4, parentBlock: block3, floorNumber: 4
            }
            func(par4)
          })
        })
      })
    })
  }

  showActive(service: ServiceMapViewBlock, organizationCompanyCd?: string): boolean {
    if (service.contractOrganizationCd === organizationCompanyCd) {
      return true
    }
    return false
  }

  // 自分の権限があるブロックかどうか (他社の場合はfalse)
  isAuthBlock(service: ServiceMapViewBlock, organizationCompanyCd?: string): boolean {
    if (service.contractOrganizationCd === organizationCompanyCd) {
      return true
    }
    return false
  }

  showSubName(service: ServiceMapViewBlock, organizationCompanyCd?: string): string {
    if (!organizationCompanyCd) {
      return ''
    }
    if (!this.showActive(service, organizationCompanyCd)) {
      return service.contractCompanyOrganizationName ?? ''
    }
  }

  // 解約対象追加
  addCancelContractCds(contractCd: string, organizationCd: string): void {
    let keepData = this.keepData.find(x => x.organizationCd === organizationCd)
    // 保存情報が無い場合は要素追加
    if (!keepData) {
      this.keepData.push({
        organizationCd,
      })
      keepData = this.keepData.find(x => x.organizationCd === organizationCd)
    }
    // cancelContractCdsに含まれていない場合は追加
    keepData.cancelContractCds = keepData.cancelContractCds || []
    if (!keepData.cancelContractCds.find(x => x === contractCd)) {
      keepData.cancelContractCds.push(contractCd)
    }
  }

  // 解約対象削除
  removeCancelContractCds(contractCd: string, organizationCd: string): void {
    let keepData = this.keepData.find(x => x.organizationCd === organizationCd)
    // 保存情報が無い場合は要素追加
    if (!keepData) {
      this.keepData.push({
        organizationCd
      })
      keepData = this.keepData.find(x => x.organizationCd === organizationCd)
    }
    keepData.cancelContractCds = keepData.cancelContractCds || []
    keepData.cancelContractCds = keepData.cancelContractCds.filter(x => x !== contractCd)
  }

  // 保存している解約対象のブロックを取得する
  getKeepCancelBlocks(organizationCd: string): ServiceMapBlock[] {
    const target = this.keepData.find(x => x.organizationCd === organizationCd)
    if (!target?.apiServiceMap || !target?.cancelContractCds) { return [] }
    return this.getCancelBlocks(target.apiServiceMap, target.cancelContractCds)
  }

  // 解約対象のブロックを集める
  private getCancelBlocks(blocks: ServiceMapBlock[], cancelContractCds: string[]): ServiceMapBlock[] {
    const ret: ServiceMapBlock[] = []
    blocks.forEach(block => {
      if (cancelContractCds.find(x => x === block.contractCd)) {
        ret.push(block)
      }
    })
    return ret
  }

  // 未接続出荷Packコネクタor出荷Packが解約されているかチェック
  // レスポンス：アラート対象ブロック
  cancelCheckOfNoConnect(cancelContractCds: string[], map: ServiceMapViewBlock[]): ServiceMapViewBlock[] {
    const ret: ServiceMapViewBlock[] = []
    const warningClass = 'back-warning'
    // 本体サービスマップ
    map.forEach(block1 => {
      block1.classes.filter(x => x !== warningClass) // 一旦クリア
      block1.children.forEach(block2 => {
        block2.classes.filter(x => x !== warningClass)// 一旦クリア

        // 自社契約の出荷Packコネクタの場合
        if (!block2.notContractCompany && block2.merchandiseCd === 'frimo_shipment_connector') {

          // 未解約の子要素があるかどうか判定
          const enableChilds = []
          block2.children.forEach(child => {
            if (!this.isCancelTarget(cancelContractCds, child)) {
              enableChilds.push(child)
            }
          })

          // 解約選択しておらず接続先が無いもしくは解約選択中の場合
          if (!this.isCancelTarget(cancelContractCds, block2) && !enableChilds.length) {
            block2.classes.push(warningClass)
            ret.push(block2)
          }

          // コネクタが解約選択中で、子要素(出荷Pack)が生きている場合
          if (this.isCancelTarget(cancelContractCds, block2) && enableChilds.filter(x => !x.notContractCompany).length) {
            enableChilds.forEach(child => {
              child.classes.push(warningClass)
              ret.push(child)
            })
          }

        }
      })
    })
    return ret
  }

  // 解約選択されているかどうか
  private isCancelTarget(cancelContractCds: string[], block: ServiceMapViewBlock): boolean {
    return cancelContractCds.find(x => x === block.contractCd) ? true : false
  }

  // 解約機能 初期表示
  setCancelIcon(serviceMap: ServiceMapViewBlock[], organizationCd): ServiceMapViewBlock[] {
    const cancelClass = 'cancel-block'
    const cancelBlocks: ServiceMapBlock[] = this.getKeepCancelBlocks(organizationCd)

    const func = (value: TargetServiceBlockInfo) => {
      const targetBlock = value.targetBlock
      if (cancelBlocks.find(x => x.actuateCd === targetBlock.actuateCd)) {
        targetBlock.classes.push(cancelClass)
        targetBlock.iconClass = 'add'
      }
    }
    this.runForAllServiceFloor(serviceMap, func)
    return serviceMap
  }

  isCancelActuatedCd(actuatedCd, cancelInfoList: ServiceMapBlock[]): boolean {
    if (cancelInfoList.find(x => x.actuateCd === actuatedCd)) {
      return true
    } else {
      return false
    }
  }

}
