<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-estimate-breadcrumbs
    [index]="index"
    [targetOrganizationCustomer]="targetOrganizationCustomer"
    [retail]="session?.retail"
  ></app-estimate-breadcrumbs>
  <h2 class="c-texts--head2 h-margin--b24">
    対象となる業態を1つ選択してください。
  </h2>
  <app-loading *ngIf="loading"></app-loading>
  <app-quotation-selecter
    [tags]="tags"
    [tagCd]="selectedTagCd"
    (changeCd)="selectedTagCd = $event"
  ></app-quotation-selecter>
  <app-under-btn-disp [actionButtons]="actionButtons"></app-under-btn-disp>
</app-main-contents>
