<app-customer-name-disp
  (targetSelected)="selectTargetOrganization($event)"
></app-customer-name-disp>
<app-organization-tree-view
  *ngIf="companyOrganizationCd"
  [companyOrganizationCd]="companyOrganizationCd"
  [companyOrganizationName]="companyOrganizationName"
  [treeEdit]="true"
  [sideMenu]="true"
  [showMenber]="true"
  (jumpEditTreePage)="jumpEditTreePage($event)"
  (jumpUserListPage)="jumpUserListPage($event)"
></app-organization-tree-view>
