import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { EstimateApiService } from 'src/api/estimates-api/estimates-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { EstimateDocumentDetail, EstimateDocumentDetailStatus } from 'src/api/estimates-api/estimates-api.interface'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { FileManagementService } from 'src/app/common/file-management.service'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { messageList } from 'src/app/components/message/message-data'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'app-ura-add-new-contract-detail',
  templateUrl: './ura-add-new-contract-detail.component.html',
  styleUrls: ['./ura-add-new-contract-detail.component.scss']
})
export class UraAddNewContractDetailComponent implements OnInit {
  title = '見積確認【新規】'
  estimateDocumentCd: string
  loading = true
  allLoading = true
  pdfLoading = false
  sending = false
  detailList: EstimateDocumentDetail[] = []
  detailStatus: EstimateDocumentDetailStatus
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.router.navigate(['/ura-estimate-list-new'])
      }
    }
  ]
  applicationButton: ActionButtonDisp = {
    label: '申し込みへ進む',
    actionFunction: () => {
      this.pageNext()
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private estimateApiService: EstimateApiService,
    private messageService: MessageService,
    private fileManagementService: FileManagementService,
    private serviceMapViewService: ServiceMapViewService
  ) { }

  ngOnInit(): void {
    this.loading = true
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.estimateDocumentCd = params.get('estimateDocumentCd')
      this.getEstimateData()
    })
  }

  getEstimateData(): void {
    this.allLoading = true
    // 明細取得
    const promise1 = new Promise(async (resolve) => {
      this.detailList = []
      this.loading = true
      // const ret = await this.estimateApiService.getEstimateDocumentDetail(this.estimateDocumentCd).toPromise()
      const ret$ = this.estimateApiService.getEstimateDocumentDetail(this.estimateDocumentCd)
      let ret = await lastValueFrom(ret$)

      this.loading = false
      if (ret?.resultCode !== 0) { return }
      this.detailList = ret.data
      resolve(true)
    })
    // 見積書ステータス取得
    const promise2 = new Promise(async (resolve) => {
      this.detailList = []
      // const ret = await this.estimateApiService.getEstimateDocumentStatus(this.estimateDocumentCd).toPromise()
      const ret$ = this.estimateApiService.getEstimateDocumentStatus(this.estimateDocumentCd)
      let ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) { return }
      this.detailStatus = ret.data[0]
      resolve(ret)
    })
    Promise.all([promise1, promise2])
      .then(() => {
        if (!this.detailStatus.applicationDatetime) {
          // 申込みボタンを表示
          this.actionButtonsDisp.push(this.applicationButton)
        }
        this.allLoading = false
      })
  }

  // 申し込み
  async pageNext(): Promise<void> {
    if (this.sending) {return}
    this.sending = true
    // const ret = await this.estimateApiService.CreateContractEasyAuth(this.estimateDocumentCd).toPromise()
    const ret$ = this.estimateApiService.CreateContractEasyAuth(this.estimateDocumentCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.sending = false
    this.serviceMapViewService.clearKeepData(this.detailStatus.sectionOrganizationCd)
    this.messageService.templeteMessage(messageList.M00044)
    this.router.navigate(['/contract-entry-list'])
  }

  // PDFダウンロード
  async downloadEstimateDocumentPdfFile(): Promise<void> {
    if (this.pdfLoading) { return }
    this.messageService.info('処理中です。しばらくお待ちください。')
    this.pdfLoading = true
    // const ret = await this.estimateApiService.EstimateDocumentPath(this.estimateDocumentCd).toPromise()
    const ret$ = this.estimateApiService.EstimateDocumentPath(this.estimateDocumentCd)
    let ret = await lastValueFrom(ret$)

    this.pdfLoading = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('ダウンロードが完了しました。')
    const url = this.estimateApiService.BaseURL + ret.data[0]
    this.fileManagementService.downloadPdfFile(url)
  }
}
