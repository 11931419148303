<div class="l-main__contents js-fit-contents">
  <div class="page card">
    <section class="card text-center">
      <div class="card-block">
        <div class="error-code">404</div>
        <h3 class="font-bold">ページが見つかりません</h3>
      </div>
    </section>
  </div>
</div>
