<ng-content select="top"></ng-content>
<app-loading *ngIf="loading" [fullHeight]="true"></app-loading>
<div *ngIf="!loading" class="c-group">
  <div class="l-has-btn">
    <div class="c-name" id="name">
      <div class="c-name__main">
        <a
          class="c-name__select"
          (click)="clickAllArrowButton()"
          style="cursor: pointer"
        >
          <h2 class="c-texts--head2">
            {{ arrowAllOpen ? "全て格納する" : "全て展開する" }}
          </h2>
        </a>
      </div>
      <span>取引先コード：</span>
      <div class="c-name__main">
        <a
          class="c-name__select"
          (click)="showDivisionPanel = true"
          (clickOutside)="showDivisionPanel = false"
          style="cursor: pointer"
        >
          <h2 class="c-texts--head2">{{ selectDivisionName }}</h2>
          <span class="c-name__arrow"
            ><i class="c-icon__arrow_s_bottom"></i></span
        ></a>
        <div class="c-name__namelist" [class.is-show]="showDivisionPanel">
          <ul style="display: block">
            <li (click)="changeDivision('')" style="cursor: pointer">
              <a>全て</a>
            </li>
            <li
              *ngFor="let division of serviceDivisions"
              (click)="changeDivision(division)"
              style="cursor: pointer"
            >
              <a>{{ division }}</a>
            </li>
            <li
              *ngIf="backConnectedMoreMap?.length"
              (click)="changeDivision('connectedMore')"
              style="cursor: pointer"
            >
              <a>ランチャー系</a>
            </li>
            <li
              *ngIf="backUnconectedMap?.length"
              (click)="changeDivision('unconnected')"
              style="cursor: pointer"
            >
              <a>未接続</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="c-group__wrap">
    <ng-container *ngFor="let service1 of dispServiceMap">
      <div class="arrow">
        <span
          class="c-nav__arrow"
          [class.is-open]="!service1.childHidden"
          (click)="service1.childHidden = !service1.childHidden"
          ><i class="c-icon__arrow_s_bottom"></i
        ></span>
      </div>
      <div class="c-group__block">
        <div class="c-group__body">
          <!-- 一階層目 -->
          <div class="c-group__parent">
            <app-service-map-block
              [service]="service1"
              (clickService)="clickServiceEvent(service1, null, 1)"
              (clickIcon)="clickIconEvent(service1, null, 1)"
            ></app-service-map-block>
          </div>
          <!-- 二階層目 -->
          <div
            *ngIf="!service1.childHidden && service1.children?.length"
            class="c-group__children"
          >
            <ng-container *ngFor="let service2 of service1.children">
              <div class="c-group__line">
                <app-service-map-block
                  [service]="service2"
                  (clickService)="clickServiceEvent(service2, service1, 2)"
                  (clickIcon)="clickIconEvent(service2, service1, 2)"
                >
                </app-service-map-block>
                <div
                  *ngIf="connectedOtherService(service2)"
                  class="c-group__panel c-group__panel--default connect-more"
                >
                  <a class="c-panel c-panel--s">
                    <p class="c-texts--s">
                      <b class="product-name">{{
                        connectedOtherService(service2).merchandiseName
                      }}</b>
                      {{ connectedOtherService(service2).productInquiryCd }}
                      {{ connectedOtherService(service2).blockText }}
                    </p>
                  </a>
                </div>
                <ng-container *ngIf="!connectedOtherService(service2)">
                  <!-- 3階層目 -->
                  <div
                    class="c-group__body"
                    *ngFor="let service3 of service2.children"
                  >
                    <app-service-map-block
                      [service]="service3"
                      togglePosition="left"
                      (clickService)="clickServiceEvent(service3, service2, 3)"
                      (clickIcon)="clickIconEvent(service3, service2, 3)"
                    ></app-service-map-block>
                    <!-- 4階層目 -->
                    <div
                      *ngIf="service3.children.length"
                      class="c-group__children"
                    >
                      <ng-container *ngFor="let service4 of service3.children">
                        <div class="c-group__line">
                          <app-service-map-block
                            [service]="service4"
                            togglePosition="left"
                            (clickService)="
                              clickServiceEvent(service4, service3, 4)
                            "
                            (clickIcon)="clickIconEvent(service4, service3, 4)"
                          ></app-service-map-block>
                          <div
                            *ngIf="connectedChild(service4)"
                            class="c-group__panel c-group__panel--default connect-more"
                          >
                            <a class="c-panel c-panel--s">
                              <p class="c-texts--s">
                                <b class="product-name">{{
                                  merchandiseName(connectedChild(service4))
                                }}</b>
                              </p>
                            </a>
                          </div>
                        </div>
                      </ng-container>

                      <!-- 4階層目アクションブロック ∟　にしたい　-->
                      <ng-container
                        *ngFor="let actionBlock of service3.actionBlocks"
                      >
                        <div class="c-group__line">
                          <app-service-map-action-block
                            [block]="actionBlock"
                            (clickBlock)="
                              clickActionBlockEvent(actionBlock, service3, 4)
                            "
                          ></app-service-map-action-block>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <!-- 3階層目アクションブロック -->
                  <div
                    class="c-group__body"
                    *ngFor="let actionBlock of service2.actionBlocks"
                  >
                    <app-service-map-action-block
                      [block]="actionBlock"
                      (clickBlock)="
                        clickActionBlockEvent(actionBlock, service2, 3)
                      "
                    ></app-service-map-action-block>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <!-- 2階層目アクションブロック　-->
            <ng-container *ngFor="let actionBlock of service1.actionBlocks">
              <div class="c-group__line">
                <app-service-map-action-block
                  [block]="actionBlock"
                  (clickBlock)="clickActionBlockEvent(actionBlock, service1, 2)"
                ></app-service-map-action-block>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="dispConnectedMoreMap.length">
      <div class="arrow">
        <span
          class="c-nav__arrow"
          [class.is-open]="openConectedMoreArea"
          (click)="openConectedMoreArea = !openConectedMoreArea"
          ><i class="c-icon__arrow_s_bottom"></i
        ></span>
      </div>
      <div class="c-group__block">
        <div class="c-group__body">
          <!-- 一階層目 -->
          <div class="c-group__parent">
            <div class="c-group__panel c-group__panel--default">
              <a class="c-panel c-panel--s no-click" style="cursor: pointer">
                <p class="c-texts--s">
                  <b class="product-name">ランチャー系</b>
                </p></a
              >
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="openConectedMoreArea">
        <ng-container *ngFor="let service1 of dispConnectedMoreMap">
          <div class="c-group__block" style="margin-left: 623px">
            <div class="c-group__body">
              <!-- 一階層目 -->
              <div class="c-group__parent">
                <app-service-map-block
                  [service]="service1"
                  togglePosition="left"
                  (clickService)="clickServiceEvent(service1, null, 1)"
                  (clickIcon)="clickIconEvent(service1, null, 1)"
                ></app-service-map-block>
              </div>
              <!-- 二階層目 -->
              <div
                *ngIf="!service1.childHidden && service1.children?.length"
                class="c-group__children"
              >
                <ng-container *ngFor="let service2 of service1.children">
                  <div class="c-group__line">
                    <app-service-map-block
                      [service]="service2"
                      togglePosition="left"
                      (clickService)="clickServiceEvent(service2, service1, 2)"
                      (clickIcon)="clickIconEvent(service2, service1, 2)"
                    ></app-service-map-block>
                  </div>
                </ng-container>
                <!-- 2階層目アクションブロック　-->
                <ng-container *ngFor="let actionBlock of service1.actionBlocks">
                  <div class="c-group__line">
                    <app-service-map-action-block
                      [block]="actionBlock"
                      (clickBlock)="
                        clickActionBlockEvent(actionBlock, service1, 2)
                      "
                    ></app-service-map-action-block>
                  </div>
                </ng-container>
              </div>
            </div></div
        ></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="dispUnconectedMap.length">
      <div class="arrow">
        <span
          class="c-nav__arrow"
          [class.is-open]="openPackArea"
          (click)="openPackArea = !openPackArea"
          ><i class="c-icon__arrow_s_bottom"></i
        ></span>
      </div>
      <div class="c-group__block">
        <div class="c-group__body">
          <!-- 一階層目 -->
          <div class="c-group__parent">
            <div class="c-group__panel c-group__panel--default">
              <a class="c-panel c-panel--s no-click" style="cursor: pointer">
                <p class="c-texts--s">
                  <b class="product-name">未接続</b>
                </p></a
              >
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="openPackArea">
        <ng-container *ngFor="let service1 of dispUnconectedMap">
          <div class="c-group__block" style="margin-left: 623px">
            <div class="c-group__body">
              <!-- 一階層目 -->
              <div class="c-group__parent">
                <app-service-map-block
                  [service]="service1"
                  togglePosition="left"
                  (clickService)="clickServiceEvent(service1, null, 1)"
                  (clickIcon)="clickIconEvent(service1, null, 1)"
                ></app-service-map-block>
              </div>
              <!-- 二階層目 -->
              <div
                *ngIf="!service1.childHidden && service1.children?.length"
                class="c-group__children"
              >
                <ng-container *ngFor="let service2 of service1.children">
                  <div class="c-group__line">
                    <app-service-map-block
                      [service]="service2"
                      togglePosition="left"
                      (clickService)="clickServiceEvent(service2, service1, 2)"
                      (clickIcon)="clickIconEvent(service2, service1, 2)"
                    ></app-service-map-block>
                    <div
                      *ngIf="connectedChild(service2)"
                      class="c-group__panel c-group__panel--default connect-more"
                    >
                      <a class="c-panel c-panel--s">
                        <p class="c-texts--s">
                          <b class="product-name">{{
                            merchandiseName(connectedChild(service2))
                          }}</b>
                        </p>
                      </a>
                    </div>
                  </div>
                </ng-container>
                <!-- 2階層目アクションブロック　-->
                <ng-container *ngFor="let actionBlock of service1.actionBlocks">
                  <div class="c-group__line">
                    <app-service-map-action-block
                      [block]="actionBlock"
                      (clickBlock)="
                        clickActionBlockEvent(actionBlock, service1, 2)
                      "
                    ></app-service-map-action-block>
                  </div>
                </ng-container>
              </div>
            </div></div
        ></ng-container>
      </ng-container>
    </ng-container>
    <div style="width: 280px">
      <ng-content select="bottom"></ng-content>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</div>
