import {
  AfterContentChecked,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { NavService } from './nav.service'
import { navMenuList } from './nav-menu-list'
import { NavMenueItem, NotificationFile, NotificationItem } from './nav-menu.interface'
import { DeepCopy, sortedItems } from 'src/app/common/common'
import { LaunchersService } from 'src/api/launcher-api/launcher-api.service'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { InvoiceLeafApiService } from 'src/api/invoice-leaf/invoice-leaf-api.service'

declare const APP

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, AfterContentChecked {
  @Input() headerType = 1
  public subscription: Subscription
  navMenuItems: NavMenueItem[]
  viewAuthMenuItems: NavMenueItem[] = [] // 権限により追加で表示されるメニュー
  viewAuthMenuSettingItems: NavMenueItem[] = [] // 権限により追加で表示されるメニュー
  openItemPath = ''
  breakPointWide = 1193
  personalNotificationList: NotificationItem[]
  overAllNotificationList: NotificationFile[]

  constructor(
    private router: Router,
    private navService: NavService,
    private authService: AuthService,
    private invoiceLeafApiService: InvoiceLeafApiService,
    private invoiceService: InvoiceService
  ) {
    router.events.pipe(
      filter(ev => (ev instanceof NavigationEnd))
    ).subscribe((ev: NavigationEnd) => {
      this.openItemPath = ev.url
    })
  }

  async ngOnInit(): Promise<void> {
    this.openItemPath = this.router.url
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    const s = this
    s.subscription = s.navService.personalNotificationListInfoSource$.subscribe(
      (list) => {
        s.personalNotificationList = list
        APP.controller.reset()
      }
    )
    s.subscription = s.navService.overAllNotificationListInfoSource$.subscribe(
      (list) => {
        s.overAllNotificationList = list
        APP.controller.reset()
      }
    )
    await this.getViewAuthMenu()
  }

  // 権限により追加で表示されるメニューを取得
  async getViewAuthMenu(): Promise<void> {
    this.viewAuthMenuItems = []
    // 利用者になっている製品取得(My-ideaの権限制御用)
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd) {
      return
    }
    const invoiceActuateRet = await this.invoiceLeafApiService.getActuates(this.authService.AccountInfo.customerCd)
    if (invoiceActuateRet?.resultCode !== 0) { return }
    const isInvoiceUser = invoiceActuateRet.data?.length > 0

    // 自身が請求先となっているマスタを取得
    const invoiceTargetsRet = await this.invoiceService.GetInvoiceTargetsByCutomer(customerCd)
    if (invoiceTargetsRet?.resultCode !== 0) { return }
    const isInvoiceTargetCustomer = invoiceTargetsRet.data.length > 0

    if (isInvoiceUser) {
      this.viewAuthMenuItems = [
        {
          label: '請求書確認',
          path: '/invoice',
          icon: 'payment',
          children: [
            {
              label: '請求書確認',
              path: '/invoice',
              icon: 'payment',
              children: [],
            },
            {
              label: '利用明細確認',
              path: '/usage-detail',
              icon: 'payment',
              children: [],
            },
          ]
        }
      ]
    }
    const settingItem =
    {
      label: '設定',
      path: 'https://idea-place.jp/contact/change/',
      icon: 'setting',
      children:
        [
          {
            label: '登録担当者変更依頼',
            path: 'https://idea-place.jp/contact/change/',
            icon: 'setting',
            children: []
          }
        ]
    }
    // 請求先担っている場合のみ子メニュー追加
    if (isInvoiceTargetCustomer) {
      settingItem.children.unshift(
        {
          label: '請求書参照権限管理',
          path: '/invoice-user-management',
          icon: 'setting',
          children: [],
        }
      )
      settingItem.path = '/invoice-user-management'
    }
    this.viewAuthMenuItems.push(settingItem)
  }

  isCurrent(item): boolean {
    return this.currentParent(this.openItemPath) === item.path
  }

  ngAfterContentChecked(): void {
    APP.controller.reset()
  }

  menuDisp(item): boolean {
    return true
  }

  isMenuDisabled(path: string): boolean {
    return !this.authService.checkAuthorityForView(path)
  }
  
  isOuterLink(path: string): boolean {
    return path?.startsWith("http")
  }

  currentParent(url: string): string {
    let parentPath = ''
    this.menu.forEach(navItem => {
      const find = navItem.children?.find(x => x.path === url)
      if (navItem.path === url || find) {
        parentPath = navItem.path
      }
    })
    return parentPath
  }

  clickLink(): void {
    // リンククリック時にメニューを閉じる
    if (window.innerWidth < this.breakPointWide) {
      APP.controller.common.menu.close()
    }
  }

  jumpOuterPage(path: string): void {
    window.open(path, '_blank')
  }

  // アイコンバッチ
  notification(item): boolean {
    if (!this.personalNotificationList || !item) {
      return false
    }
    let flag = false
    if (item.children?.length === 0) {
      // 親かつ子がない場合
      // this.notificationList.forEach( list => {
      //   if( list.link === item.path ){
      //     flag = true
      //   }
      // })
    } else {
      // 親かつ子がある場合
      item.children?.forEach((items) => {
        this.personalNotificationList.forEach((list) => {
          if (list.link === items.path) {
            flag = true
          }
        })
      })
    }
    return flag
  }

  // 個別通知
  attention(item: NavMenueItem): boolean {
    if (!this.personalNotificationList?.length || !item) {
      return false
    }
    let flag = false
    if (!item.children?.length) {
      // 子がない場合 (子がある場合は親への表示不要)
      this.personalNotificationList?.forEach((list) => {
        if (list.link === item.path) {
          flag = true
        }
      })
    }
    return flag
  }

  get alertAction(): NotificationFile[] {
    return this.navService.getOverAllAlertAction
  }

  get ura(): boolean {
    return this.headerType === 9
  }

  get menu(): NavMenueItem[] {
    if (this.ura) {
      return this.navService.uraMenuList()
    }
    return [...navMenuList, ...this.viewAuthMenuItems]
  }
}
