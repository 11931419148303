<app-search-group-items [label]="label">
  <div class="c-form__selectbox">
    <select class="c-form__input" [(ngModel)]="selected" (change)="change($event)">
      <option *ngIf="emptyOption" value="">
        {{ emptyOptionLabel }}
      </option>
      <option *ngFor="let select of selectList" [value]="select.value">
        {{ select.label }}
      </option>
    </select>
  </div>
</app-search-group-items>
