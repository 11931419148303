<app-main-contents>
  <app-title [title]="title"></app-title>
  <div>
    <div class="c-tab h-margin--b24">
      <div class="c-tab__item">
        <a (click)="showTabKey = ''" [class.is-current]="!showTabKey">コード発行</a>
      </div>
      <div class="c-tab__item">
        <a (click)="showTabKey = 'inputMode'" [class.is-current]="showTabKey === 'inputMode'">コード入力</a>
      </div>
    </div>
  </div>
  <div *ngIf="!showTabKey">
    <div class="l-table">
      <div class="l-table__item">
        <div class="l-table__head">
          <p>認証コード</p>
        </div>
        <div class="l-table__body">
          {{ generatedCode }}
          <a class="c-btn" [class.disabled]="isProcessing" (click)="generateCode()" style="cursor: pointer; margin-left: 2rem"><span>コード発行</span></a>
        </div>
      </div>
    </div>
    <br>
    <div><a routerLink="/name-card">自身の名刺を確認する</a></div>
  </div>
  <div *ngIf="showTabKey === 'inputMode'">
    <app-input-table [formItems]="formItems"></app-input-table>
    <div class="c-btns" style="margin-top: 1rem">
      <div class="c-btns__item">
        <a class="c-btn" [class.disabled]="isProcessing" (click)="submit()" style="cursor: pointer"><span>認証</span></a>
      </div>
    </div>
  </div>
</app-main-contents>