import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SearchCompanyKeyInputComponent } from './search-company-key-input/search-company-key-input.component'
import { SearchCompanyListComponent } from './search-company-list/search-company-list.component'
import { IsGridModule } from '../is-grid/is-grid.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { OrganizationProfileInputComponent } from './organization-profile-input/organization-profile-input.component'
import { CommonPartsModule } from '../common-parts/common-parts.module'
import { IgxModules } from '../igx.module'
import { CustomerNameDispComponent } from './customer-name-disp/customer-name-disp.component'
import { EasyAuthenticationLoginComponent } from './easy-authentication-login/easy-authentication-login.component'
import { ServiceMapMainComponent } from './service-map-main/service-map-main.component'
import { OrganizationCustomerListComponent } from './organization-customer-list/organization-customer-list.component'
import { OrganizationTreeEditComponent } from './organization-tree-edit/organization-tree-edit.component'
import { OrganizationTreeViewComponent } from './organization-tree-view/organization-tree-view.component'
import { ServiceMapBlockComponent } from './service-map-block/service-map-block.component'
import { ClickOutsideModule } from 'ng-click-outside'
import { ServicePolicyComponent } from './service-policy/service-policy.component'
import { ServiceMapSubInfoDialogComponent } from './service-map-sub-info-dialog/service-map-sub-info-dialog.component'
import { MailInputComponent } from './mail-input/mail-input.component'
import { ServiceMapActionBlockComponent } from './service-map-action-block/service-map-action-block.component'
import { QuotationRemarksComponent } from './quotation-remarks/quotation-remarks.component'
import { QuotationSelecterComponent } from './quotation-selecter/quotation-selecter.component'
import { QuotationMerchandiseSelecterComponent } from './quotation-merchandise-selecter/quotation-merchandise-selecter.component'
import { UserListDialogComponent } from './user-list-dialog/user-list-dialog.component'
import { CreateCustomerFormComponent } from './create-customer-form/create-customer-form.component'
import { UpdateCustomerFormComponent } from './update-customer-form/update-customer-form.component'
import { ManagementUserDialogComponent } from './management-user-dialog/management-user-dialog.component'
import { InputSelectboxComponent } from './input-selectbox/input-selectbox.component'
import { SearchGroupItemsComponent } from './search-group-items/search-group-items.component'
import { InputTextComponent } from './input-text/input-text.component'
import { InputSearchCustomerComponent } from './input-search-customer/input-search-customer.component'
import { InputSearchCompanyComponent } from './input-search-company/input-search-company.component'
import { UpdateInvoiceTargetFormComponent } from './update-invoice-target-form/update-invoice-target-form.component'
import { InvoiceTargetViewComponent } from './invoice-target-view/invoice-target-view.component'
import { SelectCustomerComponent } from './select-customer/select-customer.component'
import { InputNewCustomerComponent } from './input-new-customer/input-new-customer.component'
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component'

@NgModule({
  declarations: [
    SearchCompanyKeyInputComponent,
    SearchCompanyListComponent,
    OrganizationProfileInputComponent,
    OrganizationTreeViewComponent,
    OrganizationTreeEditComponent,
    OrganizationCustomerListComponent,
    CustomerNameDispComponent,
    EasyAuthenticationLoginComponent,
    ServiceMapMainComponent,
    ServiceMapBlockComponent,
    ServiceMapActionBlockComponent,
    ServicePolicyComponent,
    ServiceMapSubInfoDialogComponent,
    MailInputComponent,
    QuotationRemarksComponent,
    QuotationSelecterComponent,
    QuotationMerchandiseSelecterComponent,
    UserListDialogComponent,
    CreateCustomerFormComponent,
    UpdateCustomerFormComponent,
    ManagementUserDialogComponent,
    InputSelectboxComponent,
    SearchGroupItemsComponent,
    InputTextComponent,
    InputSearchCustomerComponent,
    InputSearchCompanyComponent,
    UpdateInvoiceTargetFormComponent,
    InvoiceTargetViewComponent,
    SelectCustomerComponent,
    InputNewCustomerComponent,
    BreadcrumbsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IgxModules,
    IsGridModule,
    CommonPartsModule,
    ClickOutsideModule
  ],
  exports: [
    SearchCompanyKeyInputComponent,
    SearchCompanyListComponent,
    OrganizationProfileInputComponent,
    OrganizationTreeViewComponent,
    OrganizationTreeEditComponent,
    OrganizationCustomerListComponent,
    CustomerNameDispComponent,
    EasyAuthenticationLoginComponent,
    ServiceMapMainComponent,
    ServiceMapBlockComponent,
    ServiceMapActionBlockComponent,
    ServicePolicyComponent,
    ServiceMapSubInfoDialogComponent,
    MailInputComponent,
    QuotationRemarksComponent,
    QuotationSelecterComponent,
    QuotationMerchandiseSelecterComponent,
    UserListDialogComponent,
    CreateCustomerFormComponent,
    UpdateCustomerFormComponent,
    ManagementUserDialogComponent,
    InputSelectboxComponent,
    SearchGroupItemsComponent,
    InputTextComponent,
    InputSearchCustomerComponent,
    InputSearchCompanyComponent,
    UpdateInvoiceTargetFormComponent,
    InvoiceTargetViewComponent,
    SelectCustomerComponent,
    InputNewCustomerComponent,
    BreadcrumbsComponent
  ]
})
export class MyideaPartsModule { }
