import { Component, OnInit } from '@angular/core'
import { BackendOrganization } from 'src/api/organization-company-api/organization-company-api.interface'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { Header } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-ura-company-list',
  templateUrl: './ura-company-list.component.html',
  styleUrls: ['./ura-company-list.component.scss']
})
export class UraCompanyListComponent implements OnInit {
  title = '会社一覧'
  data: BackendOrganization[] = []
  columns: Header[] = [
    {
      key: 'organizationCd',
      label: 'CD',
      width: '20%'
    },
    {
      key: 'organizationName',
      label: '会社名'
    },
    {
      key: 'organizationFormalName',
      label: '会社正式名称',
      width: '15%'
    },
    {
      key: 'organizationNameKana',
      label: '会社名カナ'
    },
    {
      key: 'organizationCategoryName',
      label: '会社種別',
      width: '8%'
    },
    {
      key: 'displayPositionName',
      label: '表記位置',
      width: '8%'
    },
    {
      key: 'postCode',
      label: '郵便番号',
      width: '8%'
    },
    {
      key: 'prefecture',
      label: '都道府県',
      width: '8%'
    },
    {
      key: 'city',
      label: '市区町村',
      width: '10%'
    },
    {
      key: 'block',
      label: '番地',
      width: '15%'
    },
    {
      key: 'building',
      label: '建物名'
    },
    {
      key: 'telephoneNumber',
      label: '電話番号',
      width: '12%'
    },
    {
      key: 'corporateNumber',
      label: '法人番号'
    }
  ]
  rowSelection = 'none'
  loading = true

  constructor(
    private organizationCompanyApiService: OrganizationCompanyApiService
  ) { }

  ngOnInit(): void {
    this.getCompanyList()
  }

  async getCompanyList(): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.organizationCompanyApiService.GetProfile()
    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.loading = false
  }
}
