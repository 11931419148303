<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <!-- <app-loading *ngIf="loading"></app-loading> -->
  <ng-container *ngIf="!serviceMapMode">
    <app-breadcrumbs [label2]="title"></app-breadcrumbs>
    <app-progress *ngIf="sending"></app-progress>
    <p class="c-texts h-margin--b16">
      <!-- 対象者が自分の会社に所属している場合は、招待せずそのまま利用者として登録されます
      (招待履歴には表示されません) -->
    </p>
    <p class="c-texts--attention h-margin--b16">＊は入力必須項目です</p>
    <div class="l-table h-margin--b16" >
      <div class="l-table__item">
        <div class="l-table__head">
          <p>
            招待をするときの自分の状態<span class="c-label--required">*</span>
          </p>
        </div>
        <div class="l-table__body">
          <div class="l-table__names">
            <div class="l-table__name">
              <div class="c-form__selectbox">
                <select
                  class="c-form__select"
                  [(ngModel)]="selectedSenderOrganizationCd"
                  (change)="
                    mailInput.setOrganizationCd(selectedSenderOrganizationCd)
                  "
                >
                  <option
                    *ngFor="let org of senderUserOrganizationList"
                    [value]="org.organizationCd"
                  >
                    {{ org.organizationFullName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="l-table__name"></div>
          </div>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>招待先<span class="c-label--required">*</span></p>
        </div>
        <div class="l-table__body">
          <app-mail-input
            #mailInput
            [addUserListDefault]="targetUserList"
            (changeSelect)="updateTargetUser($event)"
          ></app-mail-input>
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>招待対象サービス<span class="c-label--required">*</span></p>
        </div>
        <div class="l-table__body">
          FRIMO、ミコメル・しまむら
          <a
            *ngIf="!serviceMaploading"
            class="c-btn"
            style="cursor: pointer; margin-left: 2rem"
            (click)="showServiceMap()"
          >
            <span>プロダクト選択</span></a
          >
        </div>
      </div>
      <div class="l-table__item">
        <div class="l-table__head">
          <p>
            対象プロダクトと招待権限<span class="c-label--required">*</span>
          </p>
        </div>
        <div class="l-table__body">
          <div *ngFor="let detail of selectedServiceDetails">
            <ul class="btns btns--radio gap-xs">
              <div style="margin: 1rem">
                {{ detail.invitationTargetName }} ({{detail.invitationServiceProductInquiryCd}})
              </div>
              <li *ngFor="let auth of detail.invitationAuthorityDivisions">
                <input
                  type="radio"
                  [name]="detail.invitaitonTargetCd"
                  [id]="
                    detail.invitaitonTargetCd + '-' + auth.authorityDivisionCd
                  "
                  [(ngModel)]="detail.selectedAuthorityDivisionCd"
                  [value]="auth.authorityDivisionCd"
                  [checked]="
                    detail.selectedAuthorityDivisionCd ===
                    auth.authorityDivisionCd
                  "
                /><label
                  [for]="
                    detail.invitaitonTargetCd + '-' + auth.authorityDivisionCd
                  "
                  class="btns__btn"
                  >{{ auth.authorityDivisionName }}</label
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <app-under-btn-disp
      [actionButtons]="actionButtonsDisp"
    ></app-under-btn-disp>
  </ng-container>
  <ng-container *ngIf="serviceMapMode">
    <app-breadcrumbs
      label2="新規招待"
      [linkAction2]="true"
      (click2)="pageBack()"
      label3="サービスマップ"
    ></app-breadcrumbs>
    <app-service-map-main
      *ngIf="!serviceMaploading"
      [serviceMap]="serviceMap"
      (clickIcon)="clickIconEvent($event)"
    >
    </app-service-map-main>
    <app-under-btn-disp
      [actionButtons]="mapActionButtonsDisp"
    ></app-under-btn-disp>
  </ng-container>
</app-main-contents>
