import { Component, OnInit, ViewChild } from '@angular/core'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { lastValueFrom } from 'rxjs'
import { BillinYm } from 'src/api/invoice-api/invoice-api.interface'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { FileManagementService } from 'src/app/common/file-management.service'
import { SelectMaster } from 'src/app/module/common-parts/input-table/input-table.model'
import { csvMenuList } from './csv-menu-list'

@Component({
  selector: 'app-ura-csv-download',
  templateUrl: './ura-csv-download.component.html',
  styleUrls: ['./ura-csv-download.component.scss']
})
export class UraCsvDownloadComponent implements OnInit {
  title = '各種ファイルダウンロード'
  csvItemsUra = csvMenuList
  loading = false
  outType = [
    {
      label: 'Shift_JIS',
      value: 'sjis'
    }, {
      label: 'UTF-8',
      value: 'utf8'
    }
  ]
  rangeYm: any[]
  selectedType: any
  selectedYm: any
  selectedPath: any

  @ViewChild('csvDialog', { static: true })
  csvDialog: IgxDialogComponent
  constructor(
    private invoiceService: InvoiceService,
    private fileManagementService: FileManagementService,
  ) { }

  ngOnInit(): void {

  }
  // CSVダウンロード
  async downloadInvoiveRakuCsvFile(): Promise<void> {
    const selectedYm = this.selectedYm
    const selectedType = this.selectedType
    switch (this.selectedPath) {
      case '1':
        const url = this.invoiceService.GetSuppliInvoiceCustomerCsvListURL(selectedYm, selectedType)
        this.fileManagementService.downloadCsvFile(url)
        break
    }
  }

  async openCsvDialog(path): Promise<void> {
    if (path === '') {
      return
    }
    this.csvDialog.open()
    this.selectedPath = path
    let ret = null
    let data: BillinYm[] = []
    switch (path) {
      case '1':
        // ret = await this.invoiceService.GetBillingYearAndMonth().toPromise()
        const ret$ = this.invoiceService.GetBillingYearAndMonth()
        let ret = await lastValueFrom(ret$)

        data = ret.data
        break
    }
    const masterData: SelectMaster[] = []
    data.forEach(x => {
      const item: SelectMaster = {
        label: x.billingYMName,
        value: x.billingYM.toString()
      }
      masterData.push(item)
    })
    this.rangeYm = masterData
    this.selectedType = this.outType[0].value
    this.selectedYm = this.rangeYm[0].value
  }
}
