<app-sending *ngIf="sending"></app-sending>
<app-dialog
  *ngIf="mainDialogOpen"
  (cancel)="mainDialogOpen = false"
  [title]="'利用者一覧'"
  [hideCommitButton]="true"
  [hideCancelButton]="true"
  widthSize="large"
>
  <div class="sub-text">
    <p>
      {{ actuateInfo?.coreProductName
      }}<ng-container *ngIf="actuateName"> ({{ actuateName }})</ng-container>
    </p>
    <p>契約会社：{{ actuateInfo?.coreContractCompanyName }}</p>
    <p>
      登録済みユーザー：<span [class.red]="disabledAddUser && !loading">{{
        data.length
      }}</span
      >人 / 契約の上限：<span [class.red]="disabledAddUser && !loading">{{
        actuateInfo?.accountBoxMaxValue
      }}</span
      >人
    </p>
  </div>
  <app-base-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [height]="400"
    (updateCheckBox)="updateCheckBox($event)"
    (selectionChange)="selectionChange($event)"
  ></app-base-grid>
  <div class="c-btns">
    <div class="c-btns__item">
      <a
        (click)="showAddUserDialog()"
        class="c-btn c-btn--blue"
        style="cursor: pointer"
        [class.is-disable]="disabledAddUser"
        ><span>利用者を追加する</span></a
      >
    </div>
    <div class="c-btns__item">
      <a
        (click)="updateAuthConfirm()"
        class="c-btn c-btn--blue"
        style="cursor: pointer"
        [class.is-disable]="!updateAuthRequest.length"
        ><span>権限を変更する</span></a
      >
    </div>
    <div class="c-btns__item">
      <a
        (click)="deleteConfirm()"
        class="c-btn c-btn--blue"
        style="cursor: pointer"
        [class.is-disable]="!selectedRows.length || updateAuthRequest.length"
        ><span>利用者を除外する</span></a
      >
    </div>
  </div>
</app-dialog>

<app-dialog
  *ngIf="createDialogOpen"
  (cancel)="createDialogOpen = false; mainDialogOpen = true"
  (commit)="addUserCommit()"
  [title]="'対象者を選択して下さい'"
  widthSize="large"
>
  <div class="l-table">
    <div class="l-table__item">
      <div class="l-table__head">
        <p>会社<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <app-input-search-company
          (changeSelect)="updateTargetCompany($event)"
        ></app-input-search-company>
      </div>
    </div>
    <div class="l-table__item">
      <div class="l-table__head">
        <p>対象者<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <div class="c-form__selectbox" style="width: 463px">
          <select class="c-form__input" [(ngModel)]="selectedCustomerIndex">
            <option [selected]="true" value="">選択してください</option>
            <option
              *ngFor="let list of customerList; let i = index"
              [value]="i"
            >
              {{ list.organizationFullName }} {{ list.familyName
              }}{{ list.firstName }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="l-table__item">
      <div class="l-table__head">
        <p>権限<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <app-radio [values]="radio" [(ngModel)]="selectedAuthCd"></app-radio>
      </div>
    </div>
  </div>
</app-dialog>
