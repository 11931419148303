import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'
import { FileProf } from 'src/api/invoice-leaf/invoice-leaf-api.interface'
import { InvoiceLeafApiService } from 'src/api/invoice-leaf/invoice-leaf-api.service'
import { FileManagementService } from 'src/app/common/file-management.service'
import { SelectOption } from 'src/app/module/common-parts/select/select.component.interface'
import { InvoiceLeafService } from '../invoice-leaf.service'
import { sortedItems } from 'src/app/common/common'

@Component({
  selector: 'app-usage-detail',
  templateUrl: './usage-detail.component.html',
  styleUrls: ['./usage-detail.component.scss']
})
export class UsageDetailComponent implements OnInit {
  title = '利用明細確認'
  loading = false
  sending = false
  fileUrl: SafeResourceUrl = null
  selectedFilePath = ''
  periodIndexList: FileProf[] = []
  periods: SelectOption[] = []
  actuateOptions: SelectOption[] = []
  selectedActuateCd = ''
  scroll = 0
  @ViewChild('scrollableContent', { static: false }) scrollableContent: ElementRef

  constructor(
    private domSanitizer: DomSanitizer,
    private invoiceLeafApiService: InvoiceLeafApiService,
    private invoiceLeafService: InvoiceLeafService,
    private fileManagementService: FileManagementService
  ) { }

  ngOnInit(): void {
    this.getActuates()
  }

  // 請求先稼働取得
  async getActuates(): Promise<void> {
    this.actuateOptions = await this.invoiceLeafService.getActuateOptions()
    this.selectActuate(this.actuateOptions[0].value)
  }

  // 期間取得
  async getPeriods(): Promise<void> {
    if (!this.selectedActuateCd) { return }
    this.loading = true
    const ret = await this.invoiceLeafApiService.GetUsageDetailIndex(this.selectedActuateCd)
    this.loading = false
    if (ret?.resultCode !== 0 && !ret?.data.length) { return }
    this.periodIndexList = ret.data
    this.periods = []
    ret.data.forEach(date => {
      const selectOption: SelectOption = {
        label: `${date.year}年${date.month}月`,
        value: date.fullPath
      }
      this.periods.push(selectOption)
    })
    this.periods = sortedItems(this.periods, "value", false)
    if (this.periods.length) {
      this.selectPeriod(this.periods[0].value)
    }
  }

  // 稼働選択
  selectActuate(actuateCd: string): void {
    this.selectedFilePath = null
    this.fileUrl = null
    this.selectedActuateCd = actuateCd
    this.getPeriods()
  }

  // 期間選択
  async selectPeriod(fullPath: string): Promise<void> {
    if (!fullPath) { return }
    //this.scrollPeriod(fullPath)
    this.selectedFilePath = fullPath
    const url = this.invoiceLeafApiService.UsageDetailFileUrl(this.selectedTarget)
    this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url)
  }

  get selectedTarget(): FileProf {
    return this.periodIndexList.find(x => x.fullPath === this.selectedFilePath)
  }

  scrollPeriod(fullPath: string): void {
    if (!this.scrollableContent) {
      setTimeout(() => {
        this.scrollPeriod(fullPath)
      }, 500)
      return
    }
    const itemRow = 65
    const index = this.periods.findIndex(x => x.value === fullPath)
    const targetPoint = index * itemRow
    const viewWidth = this.scrollableContent.nativeElement.clientWidth
    if (targetPoint >= this.scroll + viewWidth) {
      this.scrollableContent.nativeElement.scrollTo(targetPoint - itemRow * 2, 0)
      this.scroll = targetPoint - itemRow * 2
    }
    else if (targetPoint <= this.scroll) {
      this.scrollableContent.nativeElement.scrollTo(targetPoint - itemRow, 0)
      this.scroll = targetPoint - itemRow
    }
  }

  download(): void {
    const target = this.periodIndexList.find(x => x.fullPath === this.selectedFilePath)
    this.fileManagementService.downloadPdfFile(this.invoiceLeafApiService.UsageDetailFileUrl(this.selectedTarget), target.fileName)
  }
}
