<div class="l-main__contents js-fit-contents">
  <app-progress *ngIf="loading"></app-progress>
  <app-breadcrumbs [label2]="title"></app-breadcrumbs>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <p class="c-texts--attention h-margin--b16">＊は入力必須項目です</p>

  <app-input-table [formItems]="formItems">
  </app-input-table>
  <br>
  <app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
</div>
