<div class="l-main__contents js-fit-contents">
    <!-- contents-->
    <div class="p-complete">
      <div class="p-complete__icon"><i class="c-icon__check"></i></div>
      <h2 class="p-complete__head h-margin--t24">{{title}}</h2>
      <p class="p-complete__lead h-margin--t48">
        {{message}}
      </p>
      <ng-content></ng-content>
      <!-- <div class="c-btns">
        <div class="c-btns__item">
          <a class="c-btn c-btn--blue" (click)="nextAction.emit()"
          style="cursor: pointer"
            ><span>インターサーブアカウントを
              <br>持っていない</span><i class="c-icon__arrow_s_right"></i
          ></a>
        </div>
        <div class="c-btns__item">
          <a class="c-btn c-btn--blue" (click)="nextAction.emit()"
          style="cursor: pointer"
            ><span>インターサーブアカウントを
              <br>持っている</span><i class="c-icon__arrow_s_right"></i
          ></a>
        </div>
      </div> -->
    </div>
    <!-- /contents-->
  </div>
  