import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { EstimateDocumentDetail } from 'src/api/estimates-api/estimates-api.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-contract-detail-block-new',
  templateUrl: './contract-detail-block-new.component.html',
  styleUrls: ['./contract-detail-block-new.component.scss']
})
export class ContractDetailBlockNewComponent implements OnInit {
  @Input()  detailList: EstimateDocumentDetail[] = []
  @Input()  loading = true
  @Output() clickDownLoadButton = new EventEmitter<void>()
  rowSelection = RowSelection.NONE

  columns: Header[] = [
    {
      key: 'estimateDetailsEvidenceCd',
      label: 'CD',
      hidden: true
    },
    {
      key: 'merchandiseName',
      label: '商品',
      width: '25%'
    },
    {
      key: 'retailTagText',
      label: '小売',
      width: '7%'
    },
    {
      key: 'businessCategoryTagText',
      label: '業態',
      width: '8%'
    },
    {
      key: 'merchandiseSalesTypeName',
      label: '料金タイプ',
      width: '10%'
    },
    {
      key: 'unitPrice',
      label: '単価',
      dataType: 'number',
      width: '7%'
    },
    {
      key: 'unitQuantity',
      label: '数量',
      dataType: 'number',
      width: '7%'
    },
    {
      key: 'totalInitialPrice',
      label: '初期費用合計',
      summary: true,
      dataType: 'number',
      width: '10%'
    },
    {
      key: 'totalRunningPrice',
      label: '月額費用合計',
      summary: true,
      dataType: 'number',
      width: '10%'
    },
    {
      key: 'comment',
      label: 'コメント',
    },
  ]

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
