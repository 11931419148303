import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { SupplierMasterMacro } from './supplier-masters-api.interface'

@Injectable({
  providedIn: 'root'
})
export class SupplierMastersApiService {
  baseURL = environment.myideaMacroBaseUrl
  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  /**
   * サプライヤーマスタ登録or更新
   * @param payload SupplierMasterMacro[]
   * @returns 実行結果
   */
  async UpsertSupplierMasters(payload: SupplierMasterMacro[]): Promise<ResultInfoSingle<boolean>> {
    const url = `${this.baseURL}api/supplierMaster/upsert`
    const ret$ = this.httpRequestService.postResultSingle<boolean>(url, payload)
    return await lastValueFrom(ret$)
  }

  /**
   * サプライヤーマスタ取得
   * @param retailCd 小売CD
   * @param supplierCds サプライヤーCDs
   * @returns 実行結果
   */
  async RetailsSupplierMasters(retailCd: string, supplierCds: string[]): Promise<ResultInfo<SupplierMasterMacro>> {
    const url = `${this.baseURL}api/supplierMaster/retails/${retailCd}`
    const ret$ = this.httpRequestService.post<SupplierMasterMacro>(url, supplierCds)
    return await lastValueFrom(ret$)
  }
}
