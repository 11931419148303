<div class="p-table-display">
  <div id="interactive-table-1" class="int-table text-sm">
    <div class="int-table__inner">
      <igx-grid
        [locale]="'ja-JP'"
        igxPreventDocumentScroll
        #grid
        [igxGridState]="options"
        [data]="dataList"
        [primaryKey]="indexColumnKey"
        [displayDensity]="'compact'"
        [isLoading]="loading"
        [autoGenerate]="false"
        [emptyGridMessage]="'対象がありません'"
        [emptyFilteredGridMessage]="'対象がありません'"
        [height]="gridHeight"
        width="100%"
        [rowSelection]="rowSelection"
        (rowSelectionChanging)="handleRowSelection($event)"
        (cellClick)="cellClick($event)"
        [filterMode]="'excelStyleFilter'"
        [allowFiltering]="true"
        [clipboardOptions]="clipboardOptions"
      >
        <igx-grid-toolbar>
          <div class="grid-count">
            <span>{{ dataList.length | number }}件</span>
            <span *ngIf="showSelectedCount"
              >選択中：{{ selectedRows?.length | number }}件</span
            >
          </div>
        </igx-grid-toolbar>

        <ng-container *ngFor="let c of convertColumns">
          <igx-column-group *ngIf="c.group" [header]="c.groupLabel">
            <igx-column
              #col
              *ngFor="let c2 of c.group"
              [field]="c2.key"
              [header]="c2.label"
              [width]="c2.width"
              [dataType]="c2.dataType"
              [sortable]="!c.notSort"
              [movable]="false"
              [hidden]="c2.hidden"
              [resizable]="true"
              [cellClasses]="MergeCellClasses"
              [hasSummary]="c2.summary"
              [summaries]="sumSummary"
            >
              <ng-template igxCell let-cell="cell" *ngIf="c2.summary">
                <div class="cell__inner igx-grid__td-text">
                  <div>
                    {{
                      cell.row.data[c2.key] || cell.row.data[c2.key] === 0
                        ? cell.row.data[c2.key].toLocaleString()
                        : ""
                    }}
                  </div>
                </div>
              </ng-template>
            </igx-column>
          </igx-column-group>
           <ng-container *ngIf="!c.group">
            <igx-column
              #col
              [field]="c.key"
              [header]="c.label"
              [width]="c.width"
              [dataType]="c.dataType"
              [sortable]="!c.notSort"
              [movable]="false"
              [hidden]="c.hidden"
              [resizable]="true"
              [cellClasses]="MergeCellClasses"
              [hasSummary]="c.summary"
              [summaries]="sumSummary"
            >
              <ng-template igxCell let-cell="cell" *ngIf="c.summary">
                <div class="cell__inner igx-grid__td-text">
                  <div>
                    {{
                      cell.row.data[c.key] || cell.row.data[c.key] === 0
                        ? cell.row.data[c.key].toLocaleString()
                        : ""
                    }}
                  </div>
                </div>
              </ng-template>
              <ng-template igxCell let-cell="cell" *ngIf="c.dataType==='check' || c.dataType==='checkBox'">
                <div *ngIf="cell.value" class="cell__inner check-cell">
                  <i class="c-icon-check"></i>
                </div>
                <div *ngIf="!cell.value" class="cell__inner check-cell"></div>
              </ng-template>
            </igx-column>
        </ng-container>
        </ng-container>
      </igx-grid>
    </div>
  </div>
</div>
