import { Component, OnInit, ViewChild } from '@angular/core'
import { lastValueFrom } from 'rxjs'

import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { MessageService } from 'src/app/components/message/message.service'
import { messageList } from 'src/app/components/message/message-data';

import { GenerateRequest, ExchangeRequest } from 'src/api/namecard-api/namecard-api.interface';
import { NameCardApiService } from 'src/api/namecard-api/namecard-api.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-exchange-name-card',
  templateUrl: './exchange-name-card.component.html',
  styleUrls: ['./exchange-name-card.component.scss']
})
export class ExchangeNameCardComponent implements OnInit {
  title = 'デジタル名刺交換'
  customerId = this.authService?.AccountInfo?.customerId
  showTabKey = ''
  generatedCode: string = 'xxxx-yyyy'
  isProcessing: boolean = false
  formItems: FormInputInfo[] = [
    {
      key: 'code',
      label: '相手の招待コード',
      placeholder: '例：1234-1234',
      valid: {
        required: true,
        minLength: 9,
        maxLength: 9,
        notBlank: true,
      }
    }
  ]
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent

  constructor(
    private authService: AuthService,
    private nameCardApiService: NameCardApiService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(): void { }

  // 認証コードを生成する
  async generateCode(): Promise<void> {
    if (this.isProcessing) {
      return;
    }
    let payload: GenerateRequest = {
      key: this.customerId
    }

    // http通信
    this.isProcessing = true;
    try {
      const ret$ = this.nameCardApiService.GenerateCode(payload);
      let ret = await lastValueFrom(ret$);
      if (ret.resultCode == 0) {
        this.generatedCode = ret.data.code;
      }
    } finally {
      this.isProcessing = false;
    }
  }

  // 名刺を交換する
  async submit(): Promise<void> {
    if (this.isProcessing) {
      return;
    }
    const submitForm = this.inputTable.submit()
    if (!submitForm) {
      return
    }
    // http通信
    let payload: ExchangeRequest = {
      code: submitForm.code
    }

    this.isProcessing = true;
    try {
      const ret$ = this.nameCardApiService.ExchangeNameCard(this.customerId, payload);
      let ret = await lastValueFrom(ret$);
      if (ret.resultCode == 0) {
        this.messageService.templeteMessage(messageList.M00065);
        this.router.navigate(['/exchanged-name-card-list'])
      }
      else if (ret.resultCode == -1) {
        this.messageService.templeteMessage(messageList.M00066);
      }
    } finally {
      this.isProcessing = false;
    }
  }
}
