import { Injectable } from '@angular/core'
import { messageList } from './message-data'
import { MessageType } from './message.interface'

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private messageShow = false
  private messageText = ''
  private messageType = MessageType.INFO
  timerId: any

  constructor(
  ) { }

  get show(): boolean {
    return this.messageShow
  }

  get message(): string {
    return this.messageText
  }

  get type(): string {
    return this.messageType
  }

  info(message: string): void {
    this.setShowMessage(message, MessageType.INFO)
  }

  success(message: string): void {
    this.setShowMessage(message, MessageType.SUCCESS)
  }

  warning(message: string): void {
    this.setShowMessage(message, MessageType.WARNING)
  }

  error(message: string): void {
    this.setShowMessage(message, MessageType.ERROR)
  }

  templeteMessage(item: any): void {
    this.setShowMessage(item.message, item.messageType)
  }

  setShowMessage(message: string, type: MessageType): void {
    this.messageType = type
    this.messageText = message
    this.messageShow = true
    this.removeSet()
  }

  // 一定時間後にメッセージ消去
  removeSet(): void {
    const that = this
    if (this.timerId) {
      clearTimeout(this.timerId)
      this.timerId = null
    }
    const alertmsg = function() {
      that.messageShow = false
    }
    this.timerId = setTimeout(alertmsg, 5000)
  }
}
