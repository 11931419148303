import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { DeepCopy, AutoMap } from 'src/app/common/common'
import { NewQuotationSession, QuotationSelectedGroup } from './ura-create-quotation.interface'

/**
 * 新規見積もり作成用サービス
 */
@Injectable({
  providedIn: 'root',
})
export class NewQuotationService {

  constructor(
    private router: Router
  ) { }

  private defaultValue: NewQuotationSession = {
    targetOrganizationCustomer: null,
    selectedGroups: []
  }
  private session: NewQuotationSession = DeepCopy(this.defaultValue)

  /**
   * GET：新規見積もり作成用セッション
   */
  get Session(): NewQuotationSession {
    return DeepCopy(this.session)
  }

  /**
   * SET：新規見積もり作成用セッション
   */
  setSession(value: NewQuotationSession): void {
    this.session = DeepCopy(value)
  }

  /**
   * GET：新規見積もり作成用セッション(indexごとの選択情報)
   */
  getSelectedGroupSession(indexString?: string): QuotationSelectedGroup {
    const session = this.Session.selectedGroups[Number(indexString)]
    return DeepCopy(session)
  }

  /**
   * SET：新規見積もり作成用セッション(indexごとの選択情報)
   */
  setSelectedGroupSession(value: QuotationSelectedGroup, indexString: string): void {
    const session = this.Session
    session.selectedGroups[indexString] = DeepCopy(value)
    this.session = session
  }

  /**
   * 新規見積もり作成用
   * 初期化処理
   * @param indexString pathで指定されるindex(指定が無いページでは空で渡す)
   * @param groupFirstPage ループ画面の最初(小売選択)のページの場合はtrue
   * @returns void
   */
  pageInit(indexString?: string, groupFirstPage?: boolean): boolean {
    const session = this.Session
    // 保存データが無い場合は会社選択へ
    if (!session.targetOrganizationCustomer) {
      this.router.navigate([`/ura-estimate-new`])
      return false
    }
    // インデックスの指定が無い場合
    if (!indexString) { return true }
    // 対象のindex情報が無かった場合
    if (!session.selectedGroups[Number(indexString)]) {
      // 最初のページかつ、前indexが存在する場合は要素作成
      if (groupFirstPage && Number(indexString) === 0 || session.selectedGroups[Number(indexString) - 1]) {
        session.selectedGroups.push(
          {
            merchandiseTree: []
          }
        )
        this.setSession(session)
        return true
      }
      else {
        this.clearSession()
        this.router.navigate([`/ura-estimate-new`])
        return false
      }
    }
    return true
  }

  /**
   * 新規見積もり作成用セッション クリア
   */
  clearSession(): void {
    this.session = DeepCopy(this.defaultValue)
  }

  /**
   * 新規見積もり作成用セッション クリアインデックス指定
   */
  clearIndexSession(indexString: string): void {
    const session = this.Session
    session.selectedGroups[Number(indexString)] = { merchandiseTree: [] }
    this.session = session
  }

  /**
   * プロダクト追加見積もり作成用セッション 中途半端な情報をクリア
   */
  clearUnfinishedGroupSession(): void {
    const session = this.Session
    session.selectedGroups = session.selectedGroups.filter(x =>
      x.retail?.tagCd
      && x.service?.tagCd
      && x.merchandiseTree.length)
    this.setSession(session)
  }


}
