import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const nextPath = `/${next.routeConfig.path}`
    // リロード時、ログイン処理より先にガード機能が呼ばれるため、画面情報を保持しておく
    // ==>app.componentのonEndLoginで権限を再チェック
    if (!this.authService.isLoadedAuth()) {
      this.authService.setRelaodInfo(nextPath)
      this.router.navigate([`/ura-top`])
      return
    }

    return this.authService.checkAuthorityForView(nextPath)
  }
}
