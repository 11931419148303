import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { HttpRequestService } from '../common/http-request.service'
import { ResultInfo } from '../common/api-common.interface'
import { InvitationUser, InvitedUser, InvitationAction, InvitationTarget } from './invitations-api.model'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class InvitationsApiService {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
    private httpClient: HttpClient
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  
  // 新規招待
  async userInvitation(
    organizationCd: string,
    customerCd: string,
    payload: InvitationTarget
    ): Promise<ResultInfo<boolean>> {
    const url = `${environment.myideaMacroBaseUrl}api/invitation/organization/${organizationCd}/Customer/${customerCd}/userInvitation`
    const ret$ = this.httpRequestService.post<boolean>(url, payload)
    return await lastValueFrom(ret$)
  }

    // 23
  // 送信履歴一覧取得
  // /api/Invitations/Customers/{invitationCustomerCd}
  GetInvitationSendList(customerCd: string): Observable<ResultInfo<InvitedUser>>{ // model
    const url = `${this.baseURL}api/Invitations/Customers/${customerCd}`
    return this.httpRequestService.get(url)
  }

    // 7
  // 招待受信一覧　取得 get
  GetInvitationReceiveList(customerCd: string): Observable<ResultInfo<InvitationUser>> {
    const url = `${this.baseURL}api/ReceiveInvitations/Customers/${customerCd}`
    return this.httpRequestService.get(url)
  }

  // 招待承認
  InvitationAction(payload: InvitationAction[]): Observable<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/Invitations/Action`
    return this.httpRequestService.post(url, payload)
  }
}
