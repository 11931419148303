import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { ActuatesService } from 'src/api/actuates-api/actuates-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { TargetServiceBlockInfo, ServiceMapViewBlock } from 'src/app/module/myidea-parts/service-map-view.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { UraConactorLinkComponent } from '../ura-conector-link/ura-conector-link.component'

@Component({
  selector: 'app-ura-connect-service-map',
  templateUrl: './ura-connect-service-map.component.html'
})
export class UraConnectServiceMapComponent implements OnInit {
  title = 'サービスの接続変更'
  @ViewChild('connectSearch', { read: UraConactorLinkComponent, static: false })
  public connectSearch: UraConactorLinkComponent
  organizationCompanyCd: string
  contractOrganizationCd: string
  serviceMap: ServiceMapViewBlock[] = []
  fromActuateCd: string // コネクタ側
  toActuateCd: string // Pack,ランチャー側
  targetProductCd: string
  loading = true
  sending = false
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.pageBack()
      }
    },
    {
      label: 'キャンセル',
      classes: ['c-btn-sub'],
      actionFunction: () => {
        this.selectCancel()
      }
    },
  ]
  actionButtonsDispForOtherCompany: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.pageBack()
      }
    },
    {
      label: 'キャンセル',
      classes: ['c-btn-sub'],
      actionFunction: () => {
        this.selectCancel()
      }
    },
    {
      label: '接続する',
      actionFunction: () => {
        const actuateCd = this.connectSearch.conectActuates()
        if (actuateCd) {
          if (this.fromActuateCd) {
            this.connect(this.fromActuateCd, actuateCd)
          }
          else if (this.toActuateCd) {
            this.connect(actuateCd, this.toActuateCd)
          }
        }
      }
    }
  ]
  selectTargetMode = false
  showTabKey = ''

  constructor(
    private serviceMapViewService: ServiceMapViewService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private actuatesService: ActuatesService,
    private messageService: MessageService,
    private confirmService: ConfirmService
  ) { }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCompanyCd = params.get('organizationCompanyCd')
      this.contractOrganizationCd = params.get('contractOrganizationCd')
    })
    if (this.contractOrganizationCd) {
      await this.getServiceMap()
    }
  }

  async getServiceMap(): Promise<void> {
    this.loading = true
    // サービスマップ取得
    const apiMapData = await this.serviceMapViewService.getServiceMapSearchByOrgFromApi(this.contractOrganizationCd)
    const map = await this.serviceMapViewService.convertBaseServiceMap(apiMapData, this.organizationCompanyCd, this.contractOrganizationCd)
    this.serviceMap = await this.serviceMapViewService.addColumnForConnect(map, this.organizationCompanyCd)
    this.serviceMap = this.serviceMapViewService.addColumnCanEditMemoView(this.serviceMap)
    this.loading = false
  }

  // サービスブロッククリック時処理
  async clickServiceEvent(value: TargetServiceBlockInfo): Promise<void> {
    // 接続元選択
    if (!this.fromActuateCd && !this.toActuateCd) {
      if (value.targetBlock.classes.find(x => x === 'connected-block')) {
        const fromActuateCd = value.targetBlock.actuateCd
        const toActuateCds: string[] = []
        // 出荷Pack
        if (value.targetBlock.children.length) {
          toActuateCds.push(value.targetBlock.children[0].actuateCd)
        }
        // ランチャー
        this.serviceMap.forEach(block => {
          if (block.parentActuateCds?.find(x => x === fromActuateCd)) {
            toActuateCds.push(block.actuateCd)
          }
        })
        // 今のところはTOが複数のケースは無い
        this.disconnect(value.targetBlock.actuateCd, toActuateCds[0])
      }
      if (value.targetBlock.classes.find(x => x === 'unconnected-block')) {
        value.targetBlock.classes.push('back-blink-select')
        this.selectTargetMode = true
        const rule = this.serviceMapViewService.ActuateRelationRules
        let targetProductCd = ''
        if (value.parentBlock) { // コネクタ側を選択した場合
          this.fromActuateCd = value.targetBlock.actuateCd
          this.targetProductCd = value.targetBlock.productCd
          targetProductCd = rule.find(x => x.fromProductCd === value.targetBlock.productCd).toProductCd
        }
        else { // Pack,ランチャー側を選択した場合
          this.toActuateCd = value.targetBlock.actuateCd
          this.targetProductCd = value.targetBlock.productCd
          targetProductCd = rule.find(x => x.toProductCd === value.targetBlock.productCd).fromProductCd
        }
        // 選択可能クラス削除
        const func = (val: TargetServiceBlockInfo) => {
          val.targetBlock.classes = val.targetBlock.classes.filter(x => x !== 'connected-block')
          if (val.targetBlock.productCd !== targetProductCd) {
            val.targetBlock.classes = val.targetBlock.classes.filter(x => x !== 'unconnected-block')
          }
        }
        this.serviceMapViewService.runForAllServiceFloor(this.serviceMap, func)
      }
    }
    else {
      if (value.targetBlock.classes.find(x => x === 'unconnected-block')) {
        if (this.fromActuateCd) {
          this.connect(this.fromActuateCd, value.targetBlock.actuateCd)
        }
        else {
          this.connect(value.targetBlock.actuateCd, this.toActuateCd)
        }
      }
    }
  }

  // キャンセルボタン押下時
  async selectCancel(): Promise<void> {
    this.selectTargetMode = false
    this.showTabKey = ''
    this.fromActuateCd = ''
    this.targetProductCd = ''
    this.toActuateCd = ''

    // 選択状態クラス(back-blink-select)削除
    const func = (value: TargetServiceBlockInfo) => {
      value.targetBlock.classes = value.targetBlock.classes.filter(x => x !== 'back-blink-select')
    }
    this.serviceMapViewService.runForAllServiceFloor(this.serviceMap, func)

    this.serviceMap = await this.serviceMapViewService.addColumnForConnect(this.serviceMap, this.organizationCompanyCd)
  }

  // サービスマップ再取得
  async reloadMap(): Promise<void> {
    this.serviceMapViewService.clearKeepData(this.contractOrganizationCd)
    await this.getServiceMap()
    this.selectCancel()
  }

  pageBack(): void {
    this.router.navigate(['/contract-entry-list'])
  }

  // 切断
  async disconnect(fromActuateCd: string, toActuateCd: string): Promise<void> {
    if (fromActuateCd === toActuateCd) { return }
    const message = `切断しますか？`
    this.confirmService.confirm(message, '', async () => {
      if (this.sending) { return }
      const ret = await this.actuatesService.actuatesDisconnect(fromActuateCd, toActuateCd)
      this.sending = false
      if (ret?.resultCode !== 0 && ret?.data) { return }
      this.messageService.success('サービスの接続を切断しました')
      await this.reloadMap()
    })
  }

  // 接続
  async connect(fromActuateCd: string, toActuateCd: string): Promise<void> {
    if (fromActuateCd === toActuateCd) { return }
    const message = `接続しますか？`
    this.confirmService.confirm(message, '', async () => {
      if (this.sending) { return }
      this.sending = true
      const ret = await this.actuatesService.actuatesConnect(fromActuateCd, toActuateCd)
      this.sending = false
      if (ret?.resultCode !== 0 && ret?.data) { return }
      this.messageService.success('サービスを接続しました')
      await this.reloadMap()
    })
  }
}
