import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'

@Injectable({
  providedIn: 'root'
})
export class IndexCoreMyideaApiService {
  baseURL = environment.indexCoreMyideaUrl

  constructor(private httpRequestService: HttpRequestService) {}

  async getFrontendSettings(frontendCd: string): Promise<ResultInfoSingle<any>> {
    const url = `${this.baseURL}/api/appSchemas/myidea/frontends/${frontendCd}/items`
    const ret$ = this.httpRequestService.get(url)
    return await lastValueFrom(ret$)
  }
  async postFrontendSettings(frontendCd: string, payload: any): Promise<ResultInfoSingle<any>> {
    const url = `${this.baseURL}/api/appSchemas/myidea/frontends/${frontendCd}/items`
    const ret$ = this.httpRequestService.post(url, payload)
    return await lastValueFrom(ret$)
  }
}
