import { Component, OnInit } from '@angular/core'
import { NameCardListModel } from 'src/api/namecard-api/namecard-api.interface'
import { NameCardApiService } from 'src/api/namecard-api/namecard-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'

@Component({
  selector: 'app-exchanged-name-card-list',
  templateUrl: './exchanged-name-card-list.component.html',
  styleUrls: ['./exchanged-name-card-list.component.scss']
})
export class ExchangedNameCardListComponent implements OnInit {
  title = 'デジタル名刺リスト'
  customerId = this.authService?.AccountInfo?.customerId
  loading = true
  cardList: NameCardListModel[] = []

  constructor(
    private authService: AuthService,
    private nameCardApiService: NameCardApiService
  ) { }

  ngOnInit(): void {
    this.getList()
  }

  async getList(): Promise<void> {
    this.loading = true
    //    const ret = await this.nameCardApiService.ExchangedCardList("cf612ad9-2a92-4d27-9d89-894de05ce4a4")
    const ret = await this.nameCardApiService.ExchangedCardList(this.customerId)
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.cardList = ret.data
  }
}
