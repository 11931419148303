import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { GridActionButton, ActionTargetInfo } from '../../is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from '../../is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-search-company-list',
  templateUrl: './search-company-list.component.html',
  styleUrls: ['./search-company-list.component.scss']
})
export class SearchCompanyListComponent implements OnInit {
  @Input() data: BackendOrganizationCorporateV2[] = []
  @Input() loading = false
  @Output() nextAction = new EventEmitter<BackendOrganizationCorporateV2>()
  @Output() selectRow = new EventEmitter<BackendOrganizationCorporateV2>()
  columns: Header[] = [
    {
      key: 'organizationCd',
      label: '企業コード',
      hidden: true,
    },
    {
      key: 'corporateNumber',
      label: '法人番号',
      width: '10%',
    },
    {
      key: 'organizationFormalName',
      label: '商号または名称',
    },
    {
      key: 'postCode',
      label: '郵便番号',
      width: '10%',
    },
    {
      key: 'prefecture',
      label: '都道府県',
      width: '10%',
    },
    {
      key: 'city',
      label: '市区町村',
      width: '10%',
    },
    {
      key: 'block',
      label: '番地以降',
    }
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = []
  cellClasses = {
    color_gray: (rowData: any, columnKey: string): boolean => {
      return (rowData.organizationCd !== null)
    },
  }
  constructor(
  ) {}

  ngOnInit(): void {
  }

  actionCommit(action: ActionTargetInfo): void {
    this.selectRow.emit(action.rows[0])
  }
}
