import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { BackendOrganizationV2, OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { NewQuotationService } from '../service/new-quotation.service'
@Component({
  selector: 'app-ura-estimate-new',
  templateUrl: './ura-estimate-new.component.html',
  styleUrls: ['./ura-estimate-new.component.scss']
})
export class UraEstimateNewComponent implements OnInit {
  title = '新規見積もり作成'
  customerList: OrganizationCustomer[]
  selectedCustomerIndex: number
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '見積作成に進む',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]

  constructor(
    private router: Router,
    private organizationV2Service: OrganizationV2Service,
    private messageService: MessageService,
    private newQuotationService: NewQuotationService
  ) { }

  ngOnInit(): void {
  }

  pageNext(): void {
    if (!this.selectedCustomerIndex) {
      this.messageService.warning('送信先担当者を選択してください')
      return
    }
    const customer = this.customerList[this.selectedCustomerIndex]
    this.newQuotationService.clearSession()
    const session = this.newQuotationService.Session
    session.targetOrganizationCustomer = customer
    this.newQuotationService.setSession(session)

    this.router.navigate(['/ura-new-quotation-retail/0'])
  }

  async updateTargetCompany(company: BackendOrganizationV2): Promise<void> {
    if (company?.organizationCd) {
      // const ret = await this.organizationV2Service.GetOrganizationCustomerList(company.organizationCd).toPromise()
      const ret$ = this.organizationV2Service.GetOrganizationCustomerList(company.organizationCd)
      let ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) { return }
      this.customerList = ret.data
    }
  }
}

