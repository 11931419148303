import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { TrialContract } from './trials-api.interface'

@Injectable({
  providedIn: 'root'
})
export class TrialsApiService {
  baseURL = environment.backendMacroBaseUrl
  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  // お試し稼働一覧取得
  SearchTrialContractsByCutomer(contractorOrSuperuserCustomerCd: string): Observable<ResultInfo<TrialContract>> {
    const url = `${this.baseURL}api/Trials/TrialContracts/Customer/${contractorOrSuperuserCustomerCd}/SearchForGarage`
    return this.httpRequestService.post(url, null)
  }

  // お試し稼働一覧取得 「商談支援はスーパーユーザーにお申込みさせる」用 暫定対応
  SearchTrialContractsForGarage(): Observable<ResultInfo<TrialContract>> {
    const url = `${this.baseURL}api/Trials/TrialContractsForGarage/Search`
    return this.httpRequestService.post(url, null)
  }

  // お試し稼働取得
  SearchTrialContracts(contractCustomerCds?: string[]): Observable<ResultInfo<TrialContract>> {
    const url = `${this.baseURL}api/Trials/TrialContracts/Search`
    return this.httpRequestService.post(url, contractCustomerCds ?? [])
  }
}
