<div class="l-main__contents js-fit-contents">
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-service-map-main
    [loading]="loading"
    [serviceMap]="serviceMap"
    (clickActionBlock)="openDialog($event)"
  >
    <bottom>
      <app-service-map-action-block
        [block]="addProductActionBlock"
        (click)="adminquotation()"
      ></app-service-map-action-block>
    </bottom>
  </app-service-map-main>
  <app-under-btn-disp
    [actionButtons]="mapActionButtonsDisp"
  ></app-under-btn-disp>
</div>



<igx-dialog #addContractDialog [closeOnOutsideSelect]="true" message="">
  <div
    class="
      modal__content
      width-100%
      max-width-md
      bg
      radius-lg
      shadow-md
      flex flex-column
    "
    style="
      text-align: left;
      max-width: 800px;
      max-height: 500px;
      overflow-y: scroll;
    "
    role="alertdialog"
    aria-labelledby="modal-10-07-title"
    aria-describedby="modal-10-07-description"
  >
    <h2 class="c-texts--head1" style="margin-bottom: 0">
      サービス・オプション追加
    </h2>
    <div class="p-order-summary">
      <div class="margin-right-auto text-sm padding-top-xs@sm">
        <p>追加するサービス・オプションを選択してください。（複数選択可）</p>
      </div>
    </div>
    <div class="c-form h-margin--36" id="choice-list">
      <div class="l-col--1">
        <div class="l-col__item" *ngFor="let option of selecterOptions">
          <div
            class="c-form__checkbox2"
            [class.is-checked]="option.contractedQuantity > 0"
          >
            <label>
              <input
                type="checkbox"
                [checked]="option.contractedQuantity > 0"
                [disabled]="isDisabledCheckBox(option)"
                (change)="changeSelected(option)"
              />
              <span
                ><b class="c-counter_area">{{ option.merchandiseDisplayName }}</b>
              </span>
            </label>
            <!-- オプションが複数選択可の場合、数量入力枠を表示する -->
            <div
              class="c-form__checkbox2__child h-padding--side16 h-padding--b16"
              *ngIf="option.multiple"
              [ngStyle]="option.contractedQuantity > 0 && { display: 'block' }"
            >
              <p class="c-texts--attention">数量を指定してください。</p>
              <div class="l-col--3 h-margin--t16">
                <div class="l-col__item">
                  <div
                    class="c-form__checkbox2"
                    [class.is-checked]="option.contractedQuantity > 0"
                  >
                    <input type="checkbox" checked="true" />
                    <div class="h-padding--side16">
                      <div
                        class="h-padding--t16 h-padding--b16 c-texts--center"
                      >
                        <div class="c-counter c-counter_area">
                          <span class="c-counter__label">数量</span>
                          <a
                            class=""
                            (click)="changeUnitQuantity(-1, option)"
                            style="cursor: pointer"
                          >
                            <i class="c-icon__delete"></i>
                          </a>
                          <input
                            class="c-counter__area"
                            type="text"
                            [value]="option.contractedQuantity"
                            readonly="readonly"
                          />
                          <a
                            class=""
                            (click)="changeUnitQuantity(1, option)"
                            style="cursor: pointer"
                          >
                            <i class="c-icon__add"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- チェックされている際に表示がされるように変更 -->
            <div
              class="c-form__checkbox2__child h-padding--side16 h-padding--b16"
              *ngIf="
              option.contractedQuantity > 0 && option.merchandiseDetails?.length > 0
              "
              [ngStyle]="option.contractedQuantity > 0 && { display: 'block' }"
            >
              <p class="c-texts--attention">
                下のメニューから該当するオプションを選択、数量を指定してください。
              </p>
              <p class="c-texts--attention" *ngIf="optionTextsAttention(option)">
                {{ optionTextsAttention(option) }}
              </p>
              <div
                class="l-col__item"
                *ngFor="let optionChild of option.merchandiseDetails"
              >
                <div
                  class="c-form__checkbox2"
                  [class.is-checked]="optionChild.contractedQuantity > 0"
                >
                  <label>
                    <input
                      type="checkbox"
                      [checked]="optionChild.contractedQuantity > 0"
                      [disabled]="isDisabledCheckBox(optionChild, option)"
                      (change)="changeSelected(optionChild, option)"
                    />
                    <span
                      ><b class="c-counter_area">{{
                        optionChild.merchandiseDisplayName
                      }}</b>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-btns">
      <div class="c-btns__item">
        <a
          class="js-rename-close c-btn"
          (click)="addContractDialog.close()"
          style="cursor: pointer"
        >
          <span>キャンセル</span></a
        >
      </div>
      <div class="c-btns__item">
        <a
          (click)="changeMap()"
          class="js-rename-close c-btn c-btn--blue"
          style="cursor: pointer"
        >
          <span>追加する</span></a
        >
      </div>
    </div>
  </div>
</igx-dialog>
