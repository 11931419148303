import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { NavMenueItem } from 'src/app/components/nav/nav-menu.interface'


export const csvMenuList: NavMenueItem[] = [
  {
    label: '請求',
    path: '',
    icon: 'setting',
    children: [
      {
        label: '楽楽明細 顧客一覧DL',
        path: '1',
      }
    ]
  },
]
