<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-loading *ngIf="loading" [fullHeight]="true"></app-loading>
  <app-search-conditions-area (search)="searchList()">
    <div class="search-contents">
      <app-search-group-items label="対象データの期間条件(開始日)*">
        <igx-date-picker [(ngModel)]="startDate" displayFormat="yyyy-MM-dd" [inputFormat]="'yyyy-MM-dd'">
        </igx-date-picker>
      </app-search-group-items>
    </div>
  </app-search-conditions-area>

  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [exportExcelButton]="false"
    [actionButtons]="actionButtons"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>
