<app-main-contents>
    <h1 class="c-texts--head1">{{ title }}</h1>
    <app-sending *ngIf="sending"></app-sending>
    <app-progress *ngIf="allLoading || pdfLoading"></app-progress>
    <div>
      <div class="c-tab h-margin--b24">
        <div class="c-tab__item">
          <a (click)="showTabKey = ''" [class.is-current]="!showTabKey"
            >見積書（差分）</a
          >
        </div>
        <div class="c-tab__item">
          <a
            (click)="showTabKey = 'showAll'"
            [class.is-current]="showTabKey === 'showAll'"
            >自社契約全体</a
          >
        </div>
        <div class="c-tab__item">
          <a
            (click)="showTabKey = 'showMap'"
            [class.is-current]="showTabKey === 'showMap'"
            >サービスマップ</a
          >
        </div>
      </div>
    </div>
    <!-- 追加差分 -->
    <app-contract-detail-block-new
      *ngIf="!showTabKey"
      [detailList]="detailListAdd"
      [loading]="loadingAdd"
      (clickDownLoadButton)="downloadEstimateDocumentPdfFile()"
    >
    </app-contract-detail-block-new>
    <!-- 全体 -->
    <app-contract-detail-block-all
      *ngIf="showTabKey === 'showAll'"
      [detailList]="detailListAll"
      [loading]="loadingAll"
    >
    </app-contract-detail-block-all>
    <!-- サービスマップ -->
    <app-service-map-main
      *ngIf="showTabKey === 'showMap'"
      [loading]="loadingMap"
      [serviceMap]="serviceMap"
    >
    </app-service-map-main>
    <app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
  </app-main-contents>
  