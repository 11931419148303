<p class="c-texts--attention h-margin--b16">＊は入力必須項目です</p>
<div class="l-table">
  <div class="l-table__item">
    <div class="l-table__head">
      <p>
        接続対象プロダクト名
      </p>
    </div>
    <div class="l-table__body">
      {{ targetProductName }}
    </div>
  </div>

  <div class="l-table__item">
    <div class="l-table__head">
      <p>
        接続対象の所有会社<span class="c-label--required"
          >*</span
        >
      </p>
    </div>
    <div class="l-table__body">
      <app-input-search-company
        (changeSelect)="updateTargetCompany($event)"
      ></app-input-search-company>
    </div>
  </div>

  <div [class.no-show]="!productViewFlag" class="l-table__item">
    <div class="l-table__head">
      <p>接続対象プロダクトコード<span class="c-label--required">*</span></p>
    </div>
    <div class="l-table__body">
      <div class="c-form__selectbox c-form__small">
        <select class="c-form__select" [(ngModel)]="selectedToActuatedCd">
          <option [selected]="true" value="">選択してください</option>
          <option
            *ngFor="let prod of selectedProductList"
            [value]="prod.actuateCd"
          >
            {{ prod.productInquiryCd }} {{ prod.actuateName }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
