<div class="c-parent disp-customer" style="height: 67px;">
  <ng-container *ngIf="!selectMode">
    <span>
      <a
        class="c-parent__main c-parent__main--pulldown js-parent-panel-trigger"
      >
        <h2 class="c-texts--head2 disp-customer-name">
          [顧客] {{ selectedCompany?.corporateNumber }} {{ selectedCompany?.organizationFormalName }}
        </h2>
      </a>
    </span>
    <span>
      <div class="c-btns__item disp-customer-btn">
        <a class="c-btn" (click)="dispSelectContents()" style="cursor: pointer"
          ><span>顧客を変更する</span></a
        >
      </div>
    </span>
  </ng-container>
  <ng-container *ngIf="selectMode">
    <span class="flex">
      <a
        class="c-parent__main c-parent__main--pulldown js-parent-panel-trigger"
      >
        <h2 class="c-texts--head2 disp-customer-name" style="width: 60px;">[顧客]</h2>
      </a>
      <div style="min-width: 400px;">
        <app-input-search-company
          (changeSelect)="selectCompany($event)"
        ></app-input-search-company>
      </div>
    </span>
  </ng-container>
  <ng-content></ng-content>
</div>
