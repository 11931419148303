import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { ApiErrorHandlingService } from './api-error-handling.service'
import { ResultInfo, ResultInfoSingle } from './api-common.interface'

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {

  constructor(
    private httpClient: HttpClient,
    private apiErrorHandlingService: ApiErrorHandlingService
  ) { }

  get<T>(url: string, dispMessage = true): Observable<ResultInfo<T>> {
    return this.httpClient.get<ResultInfo<T>>(url).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfo<T>>(dispMessage)))
  }

  getResultSingle<T>(url: string, dispMessage = true): Observable<ResultInfoSingle<T>> {
    return this.httpClient.get<ResultInfoSingle<T>>(url).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfoSingle<T>>(dispMessage)))
  }

  post<T>(url: string, payload: any, dispMessage = true): Observable<ResultInfo<T>> {
    return this.httpClient.post<ResultInfo<T>>(url, payload).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfo<T>>(dispMessage)))
  }

  postResultSingle<T>(url: string, payload: any, dispMessage = true): Observable<ResultInfoSingle<T>> {
    return this.httpClient.post<ResultInfoSingle<T>>(url, payload).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfo<T>>(dispMessage)))
  }

  put<T>(url: string, payload: any, dispMessage = true): Observable<ResultInfo<T>> {
    return this.httpClient.put<ResultInfo<T>>(url, payload).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfo<T>>(dispMessage)))
  }

  putResultSingle<T>(url: string, payload: any, dispMessage = true): Observable<ResultInfoSingle<T>> {
    return this.httpClient.put<ResultInfoSingle<T>>(url, payload).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfo<T>>(dispMessage)))
  }

  delete<T>(url: string, payload: any, dispMessage = true): Observable<ResultInfo<T>> {
    if (payload) {
      return this.httpClient.request('delete', url, { body: payload }).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfo<T>>(dispMessage)))
    }
    else {
      return this.httpClient.delete<ResultInfo<T>>(url).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfo<T>>(dispMessage)))
    }
  }

  deleteResultSingle<T>(url: string, payload: any, dispMessage = true): Observable<ResultInfoSingle<T>> {
    if (payload) {
      return this.httpClient.request('delete', url, { body: payload }).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfoSingle<T>>(dispMessage)))
    }
    else {
      return this.httpClient.delete<ResultInfoSingle<T>>(url).
      pipe(catchError(this.apiErrorHandlingService.handleError<ResultInfoSingle<T>>(dispMessage)))
    }
  }
}
