import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { DeepCopy } from 'src/app/common/common'
import { MessageService } from 'src/app/components/message/message.service'
// import { SignupService } from 'src/app/components/signup/signup.service'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { formCompanyItems } from 'src/app/module/myidea-parts/company.const'
import { InputOrganization } from 'src/app/module/myidea-parts/feature-organization.interface'
import { FeatureOrganizationService } from 'src/app/module/myidea-parts/feature-organization.service'
import { OrganizationProfileInputComponent } from 'src/app/module/myidea-parts/organization-profile-input/organization-profile-input.component'

@Component({
  selector: 'app-ura-edit-company-profile',
  templateUrl: './ura-edit-company-profile.component.html',
  styleUrls: ['./ura-edit-company-profile.component.scss']
})
export class UraEditCompanyProfileComponent implements OnInit {
  title = '企業情報入力'
  @ViewChild(OrganizationProfileInputComponent, { static: false })
  private input: OrganizationProfileInputComponent
  organizationCd: string
  formValue: InputOrganization
  sending = false
  nationalTaxData: InputOrganization
  items: FormInputInfo[]
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      actionFunction: () => {
        this.pageBack()
      },
      textAlign: FooterButtonTextAlign.LEFT
    },
    {
      label: '更新',
      actionFunction: () => {
        this.submit()
      },
    }
  ]


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private organizationV2Service: OrganizationV2Service,
    private featureOrganizationService: FeatureOrganizationService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCd = params.get('organizationCd')
    })
    this.items = DeepCopy(formCompanyItems)
    this.items = this.items.filter(x => x.key === 'organizationCd' || x.key === 'organizationTypeName' || x.key === 'organizationNameInfo')
    this.initInputData()
  }

  async initInputData(): Promise<void> {
    this.nationalTaxData = this.featureOrganizationService.getInputValues()
    // const ret = await this.organizationV2Service.OrganizationInfo(this.organizationCd)
    const ret$ = this.organizationV2Service.OrganizationInfo(this.organizationCd)
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }

    // API値にもserviceにも法人番号が無いのはセッション切れなのでページを戻す
    if (!ret.data[0].corporateNumber && !this.nationalTaxData?.corporateNumber) {
      this.router.navigate([`/ura-company-check-list`])
    }

    const value = ret.data[0]
    this.formValue = value
  }

  pageBack(): void {
    if (this.nationalTaxData) {
      this.router.navigate([`/ura-search-relation-corporate/${this.organizationCd}`])
    } else {
      this.router.navigate([`/ura-company-check-list`])
    }
  }

  async submit(): Promise<void> {
    const submitForm = this.input.submit()
    if (!submitForm) {
      return
    }

    // const ret = await this.organizationV2Service.OrganizationInfo(this.organizationCd).toPromise()
    const ret$ = this.organizationV2Service.OrganizationInfo(this.organizationCd)
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    const value = ret.data[0]

    // 紐づけがまだ無かったらセット 2回目以降更新不可
    if (!value.corporateNumber) {
      value.corporateNumber = this.nationalTaxData.corporateNumber
    }
    value.organizationName = submitForm.organizationName
    value.organizationFormalName = submitForm.organizationFormalName
    value.organizationNameKana = submitForm.organizationNameKana
    value.organizationCategoryCd = submitForm.organizationCategoryCd
    value.displayPositionCd = submitForm.displayPositionCd

    // 登録
    if (this.sending) {return}
    this.sending = true
    // const regist = await this.organizationV2Service.OrganizationsUpdate(value, value.organizationCd).toPromise()
    const regist$ = this.organizationV2Service.OrganizationsUpdate(value, value.organizationCd)
    const regist = await lastValueFrom(regist$)

    this.sending = false
    if (regist?.resultCode !== 0) { return }
    this.messageService.success('完了しました')
    this.router.navigate([`/ura-company-check-list`])
  }
}
