import { Component, OnInit } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { CreateAccountPath } from './create-account-url-dialog.interface'

@Component({
  selector: 'app-create-account-url-dialog',
  templateUrl: './create-account-url-dialog.component.html',
  styleUrls: ['./create-account-url-dialog.component.scss']
})
export class CreateAccountUrlDialogComponent implements OnInit {
  title = '以下のURL・認証コードをユーザーにお伝え下さい'
  dialogOpen = false
  formValue: CreateAccountPath
  formItems: FormInputInfo[] = [
    {
      key: 'organizationName',
      label: '組織',
      readonly: true
    },
    {
      key: 'customerName',
      label: '名前',
      readonly: true
    },
    {
      key: 'authenticationUriCd',
      label: '受付ページ',
      readonly: true
    },
    {
      key: 'authenticationCd',
      label: 'お客様の認証コード',
      readonly: true
    },

  ]
  constructor(
    private easyAuthenticationsApiService: EasyAuthenticationsApiService
    ) { }

  ngOnInit(): void {
  }

  async getAccountPath(customerCd: string, customerName: string, organizationName: string): Promise<void>{
    // const ret = await this.easyAuthenticationsApiService.CustomerAccountPath(customerCd).toPromise()
    const ret$ = this.easyAuthenticationsApiService.CustomerAccountPath(customerCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    const URL = `${location.protocol}//${location.host}/auth-login/${ret.data[0].authenticationUriCd}`
    this.formValue = {
      organizationName,
      customerName,
      authenticationUriCd: URL,
      authenticationCd: ret.data[0].authenticationCd,
    }
    this.dialogOpen = true
  }
}
