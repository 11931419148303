import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { Radio } from './radio.component.interface'

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioComponent),
    }
  ]
})
export class RadioComponent implements OnInit {
  @Input() values: Radio[] = []
  _value: string
  constructor() { }

  ngOnInit(): void {
  }
  private onTouchedCallback: () => void = () => {}
  private onChangeCallback: (_: any) => void = () => {}

  get value(): string {
    return this._value
  }
  @Input('value')
  set value(text: string) {
    if (this._value !== text) {
      this._value = text
      this.onChangeCallback(text)
    }
  }

  writeValue(text: string): void {
    if (text !== this.value) {
      this.value = text
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
