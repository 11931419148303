import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { ConnecterProduct } from 'src/api/actuates-api/actuates-api.interface'
import { ActuatesService } from 'src/api/actuates-api/actuates-api.service'
import { BackendOrganizationV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'

@Component({
  selector: 'app-ura-conector-link',
  templateUrl: './ura-conector-link.component.html',
  styleUrls: ['./ura-conector-link.component.scss']
})
export class UraConactorLinkComponent implements OnInit {
  @Input() productCd: string // 接続元(自分)の製品CD
  @Output() selectTarget = new EventEmitter<string>() // 対象actuateCd
  targetProductCd = ''
  targetProductName = ''
  targetOrganizationCd: string
  selectedProductList: ConnecterProduct[] = []
  productViewFlag = false
  selectedToActuatedCd: string

  constructor(
    private messageService: MessageService,
    private serviceMapViewService: ServiceMapViewService,
    private actuatesService: ActuatesService
  ) { }

  async ngOnInit(): Promise<void> {
    // 接続対象の製品情報を取得する
    const rules = await this.serviceMapViewService.ActuateRelationRules
    this.targetProductName = rules.find(x => x.toProductCd === this.productCd)?.fromProductName
      ?? rules.find(x => x.fromProductCd === this.productCd)?.toProductName
    this.targetProductCd = rules.find(x => x.toProductCd === this.productCd)?.fromProductCd
      ?? rules.find(x => x.fromProductCd === this.productCd)?.toProductCd
  }

  async updateTargetCompany(company: BackendOrganizationV2): Promise<void> {
    this.targetOrganizationCd = company?.organizationCd

    if (!this.targetOrganizationCd) { return }
    // const ret = await this.actuatesService.GetConnecterProducts(this.targetOrganizationCd, this.productCd).toPromise()
    const ret$ = this.actuatesService.GetConnecterProducts(this.targetOrganizationCd, this.productCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.selectedProductList = ret.data
    if (this.selectedProductList.length === 0) {
      this.messageService.templeteMessage(messageList.M00035)
      this.productViewFlag = false
    } else {
      this.productViewFlag = true
    }
  }

  conectActuates(): string {
    if (!this.targetOrganizationCd) {
      this.messageService.warning(`接続対象の会社が未選択です`)
      return null
    }
    if (!this.selectedToActuatedCd) {
      this.messageService.templeteMessage(messageList.M00034)
      return null
    }
    return this.selectedToActuatedCd
  }
}
