import { Component, OnInit, ViewChild } from '@angular/core'
import { RegistPaymentDateRequest } from 'src/api/actuates-api/actuates-api.interface'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { ContractStatus } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { FrimoMigrationsService } from 'src/api/frimo-migrations-api/frimo-migrations-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import moment from 'moment'
import { SettingInvoiceDialogComponent } from './setting-invoice-dialog/setting-invoice-dialog.component'
import { messageList } from 'src/app/components/message/message-data'
import { ActuatesService } from 'src/api/actuates-api/actuates-api.service'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { ActuateSettingDialogComponent } from '../components/actuate-setting-dialog/actuate-setting-dialog.component'
import { FrimoActuateSettingDialogComponent } from '../components/frimo-actuate-setting-dialog/frimo-actuate-setting-dialog.component'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, TextAlignValue, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { ContractsV4Service } from 'src/api/contractsV4-api/contractsV4-api.service'
import { BackendOrganizationV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { lastValueFrom } from 'rxjs'
import { SuppliApiService } from 'src/api/suppli-api/suppli-api.service'

@Component({
  selector: 'app-ura-ura-contract-entry-list',
  templateUrl: './ura-contract-entry-list.component.html',
  styleUrls: ['./ura-contract-entry-list.component.scss']
})
export class UraContractEntryListComponent implements OnInit {

  constructor(
    private frimoMigrationsService: FrimoMigrationsService,
    private messageService: MessageService,
    private actuatesService: ActuatesService,
    private serviceMapViewService: ServiceMapViewService,
    private suppliApiService: SuppliApiService
  ) { }
  title = '設定未登録契約'
  data: ContractStatus[] = []
  columns: Header[] = [
    {
      key: 'companyOrganizationCd',
      label: 'CD',
      hidden: true
    },
    {
      key: 'organizationContractCd',
      label: 'organizationContractCd',
      hidden: true
    },
    {
      key: 'actuateDateSettingStatusCd',
      label: 'actuateDateSettingStatusCd',
      hidden: true
    },
    {
      key: 'topContractCd',
      label: 'topContractCd',
      hidden: true
    },
    {
      key: 'contractCd',
      label: 'contractCd',
      hidden: true
    },
    {
      key: 'topActuateCd',
      label: 'topActuateCd',
      hidden: true
    },
    {
      key: 'actuateCd',
      label: 'actuateCd',
      hidden: true
    },
    {
      key: 'companyOrganizationName',
      label: '契約企業',
    },
    {
      key: 'sectionOrganizationCd',
      label: '契約部署CD',
      hidden: true
    },
    // {
    //   key: 'sectionOrganizationName',
    //   label: '契約部署',
    //   sort: true,
    // },
    {
      key: 'contractDivitionName',
      label: '区分',
      sort: true,
      width: '5%',
      style: {
        textAlign: TextAlignValue.center
      }
    },
    {
      key: 'merchandiseName',
      label: 'プロダクト',
      width: '20%',
    },
    {
      key: 'supplierCd',
      label: 'SPCD',
      width: '7%',
    },
    {
      key: 'productInquiryCd',
      label: 'プロダクトコード',
      width: '15%',
    },
    {
      key: 'applicationDatetimeUi',
      label: '申込日',
      width: '8%',
      style: {
        textAlign: TextAlignValue.center
      }
    },
    {
      key: 'connecterSettingStatusName',
      label: 'コネクタ接続',
      width: '8%',
      style: {
        textAlign: TextAlignValue.center
      }
    },
    {
      key: 'serviceActuateSettingStatusName',
      label: 'サービス稼働設定',
      width: '8%',
      style: {
        textAlign: TextAlignValue.center
      }
    },
    {
      key: 'actuateStartOrCancelDatetimeUi',
      label: '稼働日or解約日',
      width: '8%',
      style: {
        textAlign: TextAlignValue.center
      }
    },
    {
      key: 'paymentStartOrEndDatetimeUi',
      label: '課金開始日or終了日',
      width: '8%',
      style: {
        textAlign: TextAlignValue.center
      }
    },
    {
      key: 'invoiceSettingStatusName',
      label: '請求先',
      width: '8%',
      style: {
        textAlign: TextAlignValue.center
      }
    },
    {
      key: 'registSuppliDatetimeUi',
      label: 'Suppli',
      width: '8%',
      style: {
        textAlign: TextAlignValue.center
      }
    },
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: 'コネクタ接続先の変更',
      actionCd: 'connecterSetting',
      nextPage: ['/ura-connect-service-map/{companyOrganizationCd}/{sectionOrganizationCd}'],
      singleSelect: true
    },
    {
      label: 'サービス稼働設定',
      actionCd: 'serviceActuateSetting',
      singleSelect: true
    },
    {
      label: '稼働日設定',
      actionCd: 'actuateDateSetting',
    },
    {
      label: '解約日設定',
      actionCd: 'cancelDateSetting',
    },
    {
      label: '請求先設定',
      actionCd: 'invoiceSetting',
    },
    {
      label: 'Suppli登録',
      actionCd: 'registSuppli',
      confirmDialog: true,
    }
  ]
  loading = true
  sending = false
  frimoSettingTitle: string
  selectRows: ContractStatus[] = []
  showDialog = false
  showOrganizationDialog = false
  message: string
  inputDate: Date
  activeOnlyList = true
  action: GridActionButton
  selectedCompany: BackendOrganizationV2
  editableCds: number[] = [1, 2] // 手動登録できるもの全て

  @ViewChild(ActuateSettingDialogComponent, { static: false })
  private actuateSettingDialog: ActuateSettingDialogComponent
  @ViewChild(FrimoActuateSettingDialogComponent, { static: false })
  private frimoActuateSettingDialog: FrimoActuateSettingDialogComponent
  @ViewChild(SettingInvoiceDialogComponent, { static: false })
  private invoiceDialog: SettingInvoiceDialogComponent

  cellClasses = {
    color_green: (rowData: any, columnKey: string): boolean => {
      if (columnKey !== 'contractDivitionName') { return false }
      if (!rowData.contractDivitionName) {
        return false
      }
      return rowData.contractDivitionCd.indexOf('contract') !== -1
    },
    color_gray: (rowData: any, columnKey: string): boolean => {
      if (columnKey !== 'contractDivitionName') { return false }
      if (!rowData.contractDivitionName) {
        return false
      }
      return rowData.contractDivitionCd.indexOf('cancellation') !== -1
    },
    color_red: (rowData: any, columnKey: string): boolean => {
      if (columnKey === 'registSuppliDatetimeUi' && !rowData[columnKey]) { return true }
      if (columnKey === 'actuateStartOrCancelDatetimeUi' && !rowData[columnKey]) { return true }
      if (columnKey === 'actuateStartOrCancelDatetimeUi' && rowData.actuateDateSettingStatusCd === '0') { return true }
      if (columnKey === 'paymentStartOrEndDatetimeUi' && !rowData[columnKey]) { return true }
      if (columnKey === 'paymentStartOrEndDatetimeUi' && rowData.actuateDateSettingStatusCd === '0') { return true }
      if (!rowData[columnKey]) {
        return false
      }
      return rowData[columnKey].indexOf('0)') !== -1
    },
  }

  ngOnInit(): void {
    this.getContractCompanyList()
  }

  async getContractCompanyList(companyOrganizationCd?: string): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.frimoMigrationsService.AddContractList([], this.activeOnlyList, companyOrganizationCd)
    if (ret?.resultCode !== 0) { return }
    this.loading = false
    this.data = this.convertDate(ret.data)
  }

  // 対象行だけ更新する
  async getContractCompanyListTargetRows(applicationContractStatusCds: string[]): Promise<void> {
    const ret = await this.frimoMigrationsService.AddContractList(applicationContractStatusCds, false)
    if (ret?.resultCode !== 0) { return }
    const updateData = this.convertDate(ret.data)
    this.data.forEach(row => {
      const find = updateData.find(x => x.applicationContractStatusCd === row.applicationContractStatusCd)
      if (find) {
        row.productInquiryCd = find.productInquiryCd
        row.invoiceSettingStatusCd = find.invoiceSettingStatusCd
        row.invoiceSettingStatusName = find.invoiceSettingStatusName
        row.connecterSettingStatusCd = find.connecterSettingStatusCd
        row.connecterSettingStatusName = find.connecterSettingStatusName
        row.actuateStartOrCancelDatetimeUi = find.actuateStartOrCancelDatetimeUi
        row.paymentStartOrEndDatetimeUi = find.paymentStartOrEndDatetimeUi
        row.serviceActuateSettingStatusCd = find.serviceActuateSettingStatusCd
        row.serviceActuateSettingStatusName = find.serviceActuateSettingStatusName
        row.registSuppliDatetime = find.registSuppliDatetime
        row.registSuppliDatetimeUi = find.registSuppliDatetimeUi
        row.actionCds = find.actionCds
        row.registableSuppli = find.registableSuppli
      }
    })
    if (this.activeOnlyList) {
      this.data = this.data.filter(x => !x.registSuppliDatetimeUi)
    }
  }

  // contractCdを元に対象行だけ更新する
  async updateRowsByContractCd(contractCd: string): Promise<void> {
    const targetRow = this.selectRows.find(x => x.contractCd === contractCd)
    this.getContractCompanyListTargetRows([targetRow.applicationContractStatusCd])
  }

  // actuateCdを元に対象行だけ更新する
  async updateRowsByActuateCd(actuateCd: string): Promise<void> {
    const targetRow = this.selectRows.find(x => x.actuateCd === actuateCd)
    this.getContractCompanyListTargetRows([targetRow.applicationContractStatusCd])
  }

  // 対象行を削除する
  removeRows(applicationContractStatusCds: string[]): void {
    applicationContractStatusCds.forEach(cd => {
      this.data = this.data.filter(x => x.applicationContractStatusCd !== cd)
    })
  }

  convertDate(data: ContractStatus[]): ContractStatus[] {
    data.forEach(item => {
      item.actionCds = []
      if (!item.actuateCd && item.topActuateCd && item.contractDivitionCd === 'contract') {
        // コネクタ1個目無料を想定
        item.actuateStartOrCancelDatetimeUi = '-'
        item.paymentStartOrEndDatetimeUi = '-' 
      }
      if (item.contractDivitionCd === 'cancellation') {
        if (!item.registSuppliDatetime && item.registableSuppli) {
          item.actionCds.push('registSuppli')
          item.actionCds.push('cancelDateSetting')
        }
      }
      else {
        item.actionCds.push('invoiceSetting')
        item.actionCds.push('connecterSetting')
        if (item.serviceActuateSettingStatusCd !== '9') {
          item.actionCds.push('serviceActuateSetting')
        }
        if (!item.registSuppliDatetime) {
          if (!(!item.actuateCd && item.topActuateCd)) { // コネクタ1個目無料は除外
            item.actionCds.push('actuateDateSetting')
          }
          // サービス稼働設定　稼働開始日　請求先　が未設定でなければsuppli登録ができる
          if (item.actuateStartOrCancelDatetimeUi
            && item.invoiceSettingStatusCd !== '0'
            && item.registableSuppli) {
            item.actionCds.push('registSuppli')
          }
        }
      }
      // Suppli登録設定不要
      if (!item.registableSuppli && !item.registSuppliDatetimeUi) {
        item.registSuppliDatetimeUi = '-'
      }
    })
    return data
  }

  actionCommit(event: ActionTargetInfo): void {
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    this.selectRows = event.rows
    if (action.actionCd === 'serviceActuateSetting') {
      if (this.selectRows[0].merchandiseCd === 'frimo_core') {
        // Frimo専用画面
        this.frimoSettingTitle = this.selectRows[0].companyOrganizationName + ' ' + this.selectRows[0].productInquiryCd
        this.frimoActuateSettingDialog.open(this.selectRows[0].topActuateCd)
      }
      else {
        // サプライヤーCD登録画面(汎用)
        this.actuateSettingDialog.open(this.selectRows[0].contractCd)
      }
      return
    }
    if (action.actionCd === 'invoiceSetting') {
      this.invoiceDialog.open(this.selectRows)
      return
    }
    if (action.actionCd === 'registSuppli') {
      const req = []
      this.selectRows.forEach(list => {
        req.push(list.contractCd)
      })
      this.registSuppli(req)
      return
    }
    if (action.actionCd === 'actuateDateSetting' || action.actionCd === 'cancelDateSetting') {
      this.action = action
      this.showDialog = true
      return
    }
  }

  async registSuppli(contractCds: string[]): Promise<void> {
    if (this.sending) { return }
    this.sending = true
    const ret = await this.suppliApiService.RegistSuppli(contractCds)
    this.sending = false
    if (ret?.resultCode !== 0 && ret.data) { return }
    this.messageService.success('Suppli登録が完了しました')

    const cds: string[] = []
    this.selectRows.forEach(row => {
      cds.push(row.applicationContractStatusCd)
    })
    this.getContractCompanyListTargetRows(cds)
  }

  daialogCommit(): void {
    if (!this.inputDate) {
      this.messageService.templeteMessage(messageList.M00022)
      return
    }
    if (this.action.actionCd === 'actuateDateSetting'
      && moment(this.inputDate).format('YYYYMMDD') < moment(new Date()).format('YYYYMMDD')) {
      this.messageService.templeteMessage(messageList.M00036)
      return
    }
    if (this.action.actionCd === 'actuateDateSetting') {
      this.updateActuateStartDate()
    }
    if (this.action.actionCd === 'cancelDateSetting') {
      this.updateCancelDate()
    }
    this.showDialog = false
  }

  updateActuateStartDate(): void {
    const payload: RegistPaymentDateRequest[] = []
    this.selectRows.forEach(row => {
      const rowData: RegistPaymentDateRequest = {
        contractCd: row.contractCd,
        paymentStartDatetime: moment(this.inputDate).format('YYYY-MM-DD') + 'T00:00:00.000'
      }
      payload.push(rowData)
    })
    if (this.sending) { return }
    this.sending = true
    this.actuatesService.setPaymentStartDate(payload).subscribe((ret: ResultInfo<any>) => {
      this.sending = false
      if (ret?.resultCode !== 0) { return }
      this.messageService.templeteMessage(messageList.M00037)
      this.serviceMapViewService.clearKeepData()
      const cds: string[] = []
      this.selectRows.forEach(row => {
        cds.push(row.applicationContractStatusCd)
      })
      this.getContractCompanyListTargetRows(cds)
    })
  }

  async updateCancelDate(): Promise<void> {
    const contractCds: string[] = []
    this.selectRows.forEach(row => {
      contractCds.push(row.contractCd)
    })
    if (this.sending) { return }
    this.sending = true
    // const ret = await this.actuatesService.UpdateCancelDate(
    //   contractCds,
    //   moment(this.inputDate).format('YYYY-MM-DD') + 'T00:00:00.000').toPromise()
    const ret$ = this.actuatesService.UpdateCancelDate(contractCds,moment(this.inputDate).format('YYYY-MM-DD') + 'T00:00:00.000')
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00037)
    this.serviceMapViewService.clearKeepData()
    const cds: string[] = []
    this.selectRows.forEach(row => {
      cds.push(row.applicationContractStatusCd)
    })
    this.getContractCompanyListTargetRows(cds)
  }
}
