import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { InputProductInquiryCd } from 'src/api/conta-api/conta-api.interface'
import { UraSearchService } from '../ura-search.service'

@Component({
  selector: 'app-input-product-inquiry-cd',
  templateUrl: './input-product-inquiry-cd.component.html',
  styleUrls: ['./input-product-inquiry-cd.component.scss']
})
export class InputProductInquiryCdComponent implements OnInit {
  @Input() label = 'プロダクトコード'
  @Input() value = ''
  @Output() changeInput = new EventEmitter<InputProductInquiryCd>()
  errorMessage = ''

  constructor(
    private uraSearchService: UraSearchService
  ) { }

  ngOnInit(): void {
    this.uraSearchService.productCdMaster()
  }

  async checkProductCd(event): Promise<void> {
    this.errorMessage = ''
    const eventValue: InputProductInquiryCd = {
      isError: true
    }
    const productCd = await this.uraSearchService.getProductCdFromInquiryCd(event?.target?.value || '')
    if (event?.target?.value && !productCd) {
      this.errorMessage = '有効なプロダクトコードではありません'
      eventValue.isError = true
      this.changeInput.emit(eventValue)
      return
    }
    const productNumber = await this.uraSearchService.getProductNumberFromInquiryCd(event.target.value)
    eventValue.isError = false
    eventValue.productCdForInquiry = productCd || null
    eventValue.productNumber = productNumber || null
    this.changeInput.emit(eventValue)
  }
}
