<app-base-grid
  #grid
  [data]="data"
  [loading]="loading"
  [rowSelection]="rowSelection"
  [columns]="columns"
  [height]="height"
  [minusHeight]="minusHeight"
  [cellClasses]="cellClasses"
  (selectRows)="setActionTargetInfo($event)"
  (selectionChange)="sendSelectionChange($event)"
  (updateCheckBox)="onUpdateCheckBox($event)"
></app-base-grid>
<app-under-btn-disp
  *ngIf="actionButtonsDisp?.length"
  [actionButtons]="actionButtonsDisp"
  (actionCommit)="buttonActionCommit($event)"
></app-under-btn-disp>
