// IgxExcelExporterService no longer need to be manually provided and can be safely removed.
import { NgModule } from '@angular/core'

import {
  IgxDialogModule,
  IgxCalendarModule,
  IgxProgressBarModule,
  IgxAutocompleteModule,
  IgxDropDownModule,
  IgxInputGroupModule,
  IgxTooltipModule,
  IgxIconModule,
  IgxListModule,
  IgxSelectModule,
  IgxDatePickerModule,
  IgxSnackbarModule,
  IgxTreeGridModule,
  IgxGridModule,
  IgxChipsModule,
  IgxExcelExporterService,
  IgxToggleModule,
  IgxDateRangePickerModule,
  IgxComboModule,
  IgxButtonModule
} from '@infragistics/igniteui-angular'
const igx = [
  IgxDialogModule,
  IgxCalendarModule,
  IgxProgressBarModule,
  IgxAutocompleteModule,
  IgxDropDownModule,
  IgxInputGroupModule,
  IgxTooltipModule,
  IgxIconModule,
  IgxListModule,
  IgxSelectModule,
  IgxDatePickerModule,
  IgxSnackbarModule,
  IgxTreeGridModule,
  IgxGridModule,
  IgxChipsModule,
  IgxToggleModule,
  IgxDateRangePickerModule,
  IgxComboModule,
  IgxButtonModule
]

@NgModule({
  imports: [igx],
  providers: [ IgxExcelExporterService ],
  exports: [igx]
})
// IgniteUI系のモジュールをひとまとめに
export class IgxModules {}
