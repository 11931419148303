<app-main-contents>
  <app-breadcrumbs [label2]="title"></app-breadcrumbs>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <div class="flex">
    <app-select
      style="width: 800px"
      *ngIf="actuateOptions.length > 1"
      [options]="actuateOptions"
      [(ngModel)]="selectedActuateCd"
      (change)="selectActuate(selectedActuateCd)"
    ></app-select>
    <div *ngIf="periods.length">
      <div class="period_select">
        <app-select
          [options]="periods"
          [(ngModel)]="selectedFilePath"
          (change)="selectPeriod(selectedFilePath)"
        ></app-select>
      </div>
    </div>
    <div class="download-btn">
      <div class="sub-text">
        登録担当者や支払い方法の変更を希望される方は<a
          href="https://idea-place.jp/contact/change/"
          target="_blank"
          style="cursor: pointer"
          >こちら</a
        >からお問い合わせください
      </div>
      <div class="c-btns__item" style="text-align: right" *ngIf="selectedFilePath">
        <a (click)="download()" class="c-btn c-btn--s" style="cursor: pointer"
          ><span>PDFダウンロード</span></a
        >
      </div>
    </div>
  </div>

  <div *ngIf="fileUrl" class="c-pdf h-margin--t16">
    <iframe [src]="fileUrl"> </iframe>
  </div>

  <div *ngIf="!periods.length && selectedActuateCd && !loading">
    まだ登録がありません
  </div>
</app-main-contents>
