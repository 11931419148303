import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { ActuateRelationRuleForUi, ProductLevelMerchandise, ServiceMapBlock, ServiceMapSubInfo, UpdateMemoRequest } from './service-map-api.interface'

@Injectable({
  providedIn: 'root'
})
export class ServiceMapApiService {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
  ) { }

  // サービスマップ取得 (顧客CD)
  async SearchByCustomerCd(customerCd: string): Promise<ResultInfo<ServiceMapBlock>> {
    const url = `${environment.myideaMacroBaseUrl}api/serviceMap/customer/${customerCd}/block`
    const ret$ = this.httpRequestService.get<ServiceMapBlock>(url)
    return await lastValueFrom(ret$)
  }

  // サービスマップ取得 (組織CD)
  async SearchByOrganizationCd(contractOrganizationCd: string): Promise<ResultInfo<ServiceMapBlock>> {
    const url = `${environment.myideaMacroBaseUrl}api/serviceMap/organization/${contractOrganizationCd}/block`
    const ret$ = this.httpRequestService.get<ServiceMapBlock>(url)
    return await lastValueFrom(ret$)
  }

  // サービスマップ詳細情報取得
  GetServiceMapSubInfo(actuateCd: string): Observable<ResultInfo<ServiceMapSubInfo>> {
    const url = `${this.baseURL}api/ServiceMaps/SubInfo/Get`
    return this.httpRequestService.post(url, [actuateCd])
  }

  // 稼働メモ登録
  UpdateMemo(req: UpdateMemoRequest): Observable<ResultInfo<UpdateMemoRequest>> {
    const url = `${this.baseURL}api/ServiceMaps/MemoEdit`
    return this.httpRequestService.put(url, [req])
  }

  // TOP階層の商品を取得
  GetProductLevelMerchandises(): Observable<ResultInfo<ProductLevelMerchandise>> {
    const url = `${environment.myideaMacroBaseUrl}api/serviceMap/productLevel`
    return this.httpRequestService.get(url)
  }

  // 稼働紐づけルールを取得
  GetActuateRelationRules(): Observable<ResultInfo<ActuateRelationRuleForUi>> {
    const url = `${this.baseURL}api/ServiceMaps/ActuateRelationRules`
    return this.httpRequestService.get(url)
  }

}
