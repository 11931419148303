<app-search-group-items [label]="label" [subText]="subText" [errorMessage]="errorMessage">
  <input
    class="c-form__input large"
    type="text"
    placeholder=""
    [maxlength]="maxLength"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (keyup)="change()"
    (change)="change()"
  />
</app-search-group-items>
