<app-main-contents>
  <app-breadcrumbs [label2]="title"></app-breadcrumbs>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <div style="font-size: 1.2rem; margin-top: -2rem; margin-bottom: 1rem">
    各請求書の参照権限を変更したい請求書を選んでください
    <br />
  </div>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    minusHeight="20"
    [actionButtons]="actionButtons"
    [cellClasses]="cellClasses"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>

<app-invoice-user-dialog
  #userListDialog
  [invoiceId]="invoiceId"
></app-invoice-user-dialog>
