import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SelectListItem } from './input-selectbox.interface'

@Component({
  selector: 'app-input-selectbox',
  templateUrl: './input-selectbox.component.html'
})
export class InputSelectboxComponent implements OnInit {
  @Input() label = ''
  @Input() selected = ''
  @Input() emptyOption = false
  @Input() emptyOptionLabel = ''
  @Input() selectList: SelectListItem[] = []
  @Output() selectOption = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {
  }

  change(event): void {
    const value = event.target.value !== '' ? event.target.value : null
    this.selectOption.emit(value)
  }

}
