<div class="l-main__contents js-fit-contents">
  <app-title [title]="title"></app-title>
  <a
    *ngIf="!loading && activeOnlyList"
    (click)="showOrganizationDialog = true"
    class="grid-option-link"
    style="cursor: pointer"
    >設定済データも表示する</a
  >
  <a
    *ngIf="!loading && !activeOnlyList"
    (click)="activeOnlyList = true; getContractCompanyList()"
    class="grid-option-link"
    style="cursor: pointer"
    >設定済データを非表示にする</a
  >
  <app-sending *ngIf="sending"></app-sending>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    [cellClasses]="cellClasses"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</div>

<app-dialog
  *ngIf="showDialog"
  [message]="action.label + 'をします。'"
  width="small"
  (commit)="daialogCommit()"
  (cancel)="showDialog = false"
>
  <igx-date-picker
    [(ngModel)]="inputDate"
    displayFormat="yyyy-MM-dd"
    inputFormat="yyyy-MM-dd"
  ></igx-date-picker>
</app-dialog>

<app-dialog
  *ngIf="showOrganizationDialog"
  [title]="'絞り込み'"
  [message]="'会社を選択'"
  width="small"
  [commitButtonText]="'直近100件を取得'"
  [hideCancelButton]="true"
  (commit)="
    activeOnlyList = false;
    getContractCompanyList();
    showOrganizationDialog = false
  "
  (cancel)="showOrganizationDialog = false"
>
  <app-input-search-company
    (changeSelect)="
      selectedCompany = $event;
      activeOnlyList = false;
      getContractCompanyList($event.organizationCd);
      showOrganizationDialog = false
    "
  ></app-input-search-company>
  <div class="select-org-text">もしくは</div>
</app-dialog>

<app-frimo-actuate-setting-dialog
  [title]="frimoSettingTitle"
  (updateed)="updateRowsByActuateCd($event)"
></app-frimo-actuate-setting-dialog>

<app-actuate-setting-dialog
  [editableCds]="editableCds"
  (updateed)="updateRowsByContractCd($event)"
></app-actuate-setting-dialog>

<app-setting-invoice-dialog
  #invoiceDialog
  (setInvoice)="getContractCompanyListTargetRows($event)"
></app-setting-invoice-dialog>
