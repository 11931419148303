import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import {
  EstimateCreateMacroRequest,
  EstimateDocumentDetail,
  EstimateDocumentDetailAll,
  EstimateDetailTreeRequest,
  EstimatePreviewRequest
} from 'src/api/estimates-api/estimates-api.interface'
import { EstimateApiService } from 'src/api/estimates-api/estimates-api.service'
import { FrimoMigrationsService } from 'src/api/frimo-migrations-api/frimo-migrations-api.service'
import { FileManagementService } from 'src/app/common/file-management.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import {
  ActionButtonDisp,
  FooterButtonTextAlign
} from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { ServiceMapViewBlock } from 'src/app/module/myidea-parts/service-map-view.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { AddOptionQuotationService } from '../service/add-option-quotation.service'

@Component({
  selector: 'app-ura-quotation-preview-add-option',
  templateUrl: './ura-quotation-preview-add-option.component.html'
})
export class UraQuotationPreviewAddOptionComponent implements OnInit {
  title = '見積確認【追加契約】'
  pdfLoading = false
  organizationCompanyCd: string
  contractOrganizationCd: string
  changeMerchandises: EstimateDetailTreeRequest[] = []
  estimateDocumentRequest: EstimatePreviewRequest
  detailListAdd: EstimateDocumentDetail[] = []
  detailListAll: EstimateDocumentDetailAll[] = []
  serviceMap: ServiceMapViewBlock[] = []
  sending = false
  allLoading = true
  loadingAdd = true
  loadingAll = true
  loadingMap = true
  showTabKey = ''
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.router.navigate([
          `ura-add-contract-service-map/${this.organizationCompanyCd}/${this.contractOrganizationCd}`
        ])
      }
    },
    {
      label: '見積を保存する',
      actionFunction: () => {
        this.save()
      }
    }
  ]

  constructor(
    private addOptionQuotationService: AddOptionQuotationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private estimateApiService: EstimateApiService,
    private serviceMapViewService: ServiceMapViewService,
    private messageService: MessageService,
    private frimoMigrationsService: FrimoMigrationsService,
    private fileManagementService: FileManagementService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCompanyCd = params.get('organizationCompanyCd')
      this.contractOrganizationCd = params.get('contractOrganizationCd')
      const session = this.addOptionQuotationService.Session
      // 保存されている契約組織と異なる場合はクリア
      if (session.contractOrganizationCd !== this.contractOrganizationCd) {
        this.addOptionQuotationService.clearSession()
        this.router.navigate([
          `/ura-add-contract-service-map/${this.organizationCompanyCd}/${this.contractOrganizationCd}`
        ])
      }
      this.changeMerchandises = this.addOptionQuotationService.getAddOptionRequest()
      this.estimateDocumentRequest = {
        remarks: '',
        estimateMerchandises: this.changeMerchandises
      }
      this.getEstimateData()
    })
  }

  // 既存追加
  async save(): Promise<void> {
    if (this.sending) {
      return
    }
    this.sending = true
    // 見積内容保存
    const paylord: EstimateCreateMacroRequest = {
      generatorCustomerCd: this.authService.AccountInfo.customerCd,
      estimateMerchandises: this.changeMerchandises
    }
    // const ret = await this.estimateApiService.SaveEstimates(this.contractOrganizationCd, paylord).toPromise()
    const ret$ = this.estimateApiService.SaveEstimates(this.contractOrganizationCd, paylord)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) {
      return
    }
    this.addOptionQuotationService.clearSession()
    this.messageService.success('見積の保存が完了しました')
    this.router.navigate(['/ura-estimate-list'])
  }

  getEstimateData(): void {
    this.allLoading = true
    // 差分
    const promise1 = new Promise(async (resolve) => {
      this.detailListAdd = []
      this.loadingAdd = true
      // const ret = await this.estimateApiService.EstimateDetailsPreview(this.changeMerchandises).toPromise()
      const ret$ = this.estimateApiService.EstimateDetailsPreview(this.changeMerchandises)
      let ret = await lastValueFrom(ret$)

      this.loadingAdd = false
      if (ret?.resultCode !== 0) {
        return
      }
      this.detailListAdd = ret.data
      resolve(true)
    })
    // 全体
    const promise3 = new Promise(async (resolve) => {
      this.detailListAll = []
      this.loadingAll = true
      // const ret = await this.estimateApiService.postEstimateDetailsWithAllContract(
      //   this.contractOrganizationCd, this.changeMerchandises
      // ).toPromise()
      const ret$ = this.estimateApiService.postEstimateDetailsWithAllContract(
        this.contractOrganizationCd,
        this.changeMerchandises
      )
      let ret = await lastValueFrom(ret$)

      this.loadingAll = false
      if (ret?.resultCode !== 0) {
        return
      }
      this.detailListAll = ret.data
      resolve(true)
    })
    // サービスマップ
    const promise4 = new Promise(async (resolve) => {
      this.serviceMap = []
      this.loadingMap = true
      // const ret = await this.frimoMigrationsService.getPreviewServiceMap(
      //   this.contractOrganizationCd, this.estimateDocumentRequest
      // ).toPromise()
      const ret$ = this.frimoMigrationsService.getPreviewServiceMap(
        this.contractOrganizationCd,
        this.estimateDocumentRequest
      )
      let ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) {
        return
      }
      this.serviceMap = this.serviceMapViewService.addColumnForAddServiceView(
        this.serviceMapViewService.convertBaseServiceMap(
          ret.data,
          this.organizationCompanyCd,
          this.contractOrganizationCd
        )
      )
      this.loadingMap = false
      resolve(true)
    })
    Promise.all([promise1, promise3, promise4]).then(() => {
      this.allLoading = false
    })
  }

  // PDFダウンロード
  async downloadEstimateDocumentPdfFile(): Promise<void> {
    if (this.pdfLoading) {
      return
    }
    this.messageService.info('処理中です。しばらくお待ちください。')
    this.pdfLoading = true

    // const ret = await this.estimateApiService.GetEstimateDocumentPreview(
    //   this.estimateDocumentRequest, this.contractOrganizationCd
    // ).toPromise()
    const ret$ = this.estimateApiService.GetEstimateDocumentPreview(
      this.estimateDocumentRequest,
      this.contractOrganizationCd
    )
    let ret = await lastValueFrom(ret$)

    this.pdfLoading = false
    if (ret?.resultCode !== 0) {
      return
    }
    this.messageService.success('ダウンロードが完了しました。')
    const url = this.estimateApiService.BaseURL + ret.data[0]
    this.fileManagementService.downloadPdfFile(url)
  }
}
