import { Component, OnInit, ViewChild } from '@angular/core'
import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { CreateInvoiceTargetRequest, InvoiceTarget } from 'src/api/invoice-api/invoice-api.interface'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { messageList } from 'src/app/components/message/message-data'
import { prefectureMaster } from 'src/app/module/myidea-parts/company.const'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { lastValueFrom } from 'rxjs'
import { CompanyOrganization } from 'src/api/organization-company-api/organization-company-api.interface'
import { InvoiceLeafApiService } from 'src/api/invoice-leaf/invoice-leaf-api.service'
import { PaymentMethodAccount } from 'src/api/invoice-leaf/invoice-leaf-api.interface'
import { ResultInfo } from 'src/api/common/api-common.interface'
@Component({
  selector: 'app-ura-company-invoice',
  templateUrl: './ura-company-invoice.component.html',
  styleUrls: ['./ura-company-invoice.component.scss']
})
export class UraCompanyInvoiceComponent implements OnInit {
  title = '請求情報マスタ確認'
  selectedTargetOrg: CompanyOrganization
  invoceTargets: InvoiceTarget[] = []
  columns: Header[] = [
    {
      key: 'customerName',
      label: '氏名',
    },
    {
      key: 'companyOrganizationName',
      label: '会社名',
    },
    {
      key: 'sectionOrganizationName',
      label: '部署・役職',
    },
    {
      key: 'title',
      label: '請求区分',
    },
    {
      key: 'suppliCustomerId',
      label: '楽々明細ID',
    }
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '請求情報マスタ登録',
      actionCd: 'actionCd1',
      notRowAction: true,
    },
    {
      label: '更新',
      actionCd: 'actionCd2',
      notRowAction: false,
    }
  ]
  loading = true
  sending = false
  customers: OrganizationCustomer[]
  dialogOpen = false
  dispOrganizationName: string

  @ViewChild('inputTable2', { read: InputTableComponent, static: false })
  public inputTable2: InputTableComponent
  @ViewChild('inputTable3', { read: InputTableComponent, static: false })
  public inputTable3: InputTableComponent
  @ViewChild('inputTable4', { read: InputTableComponent, static: false })
  public inputTable4: InputTableComponent
  
  formItems1: FormInputInfo[] = [
    {
      key: 'invoiceTitle',
      label: '顧客',
      type: 'text'
    },
  ]

  formItems2: FormInputInfo[] = [
    {
      key: 'branchOffice',
      label: '支店名',
      placeholder: '例：大阪支店',
    },
    {
      key: 'sectionName',
      label: '部署名',
      placeholder: '例：経理部',
    },
    {
      key: 'personName',
      label: '宛先氏名',
      placeholder: '例：山田 太郎',
    },
  ]
  formItems3: FormInputInfo[] = [
    {
      key: 'postCode',
      label: '郵便番号',
      placeholder: '例：1112222',
      valid: {
        required: true,
        numOnly: true,
        maxLength: 7
      },
      caption: 'ハイフンなし'
    },
    {
      key: 'prefecture',
      label: '都道府県',
      placeholder: '経理部',
      type: 'select',
      master: prefectureMaster,
      valid: {
        required: true
      },
    },
    {
      key: 'city',
      label: '市区町村',
      placeholder: '例：渋谷区恵比寿',
      valid: {
        required: true,
      },
    },
    {
      key: 'blockName',
      label: '番地',
      placeholder: '例：1-1-9',
      valid: {
        required: true,
      },
    }
  ]
  formItems4: FormInputInfo[] = [
    {
      key: 'invoiceTitle',
      label: '請求区分',
      type: 'text'
    },
  ]

 
  selectedInvoiceTarget: CreateInvoiceTargetRequest = {}
  /**
   * @description
   * 組織の支払い口座情報
   */
  organizationPaymentMethods: PaymentMethodAccount[]
  /**
   * @description
   * モーダルで選択した支払い口座CD
   */
  selectedPaymentMethodCd: string
  constructor(
    private authService: AuthService,
    private organizationV2Service: OrganizationV2Service,
    private messageService: MessageService,
    private invoiceService: InvoiceService,
    private invoiceLeafApiService: InvoiceLeafApiService,
  ) { }

  ngOnInit(): void {
    if (!this.selectedTargetOrg) { return }
    this.getCompanyInvoiceList()
    this.getCustomerList()
  }

  selectTargetOrganization(event: CompanyOrganization): void {
    this.selectedTargetOrg = event
    this.dispOrganizationName = event?.organizationFormalName
    this.ngOnInit()
  }

  async getCompanyInvoiceList(): Promise<void> {
    this.loading = true
    const organizationCd = this.authService.ChangeCustomer.organizationCd
    const ret = await this.invoiceService.GetInvoiceTargets(organizationCd)
    if (ret?.resultCode !== 0) { return }
    this.invoceTargets = this.convertDate(ret.data)
    this.loading = false
  }

  convertDate(data: InvoiceTarget[]): InvoiceTarget[] {
    data.forEach(item => {
      item.actionCds = []
      item.actionCds.push('actionCd2')
    })
    return data
  }

  async actionCommit(event: ActionTargetInfo): Promise<void> {
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    // 口座情報を初期化する (ないとバグる)
    this.selectedPaymentMethodCd = null
    // 口座一覧を取得する
    const organizationPaymentMethod = await this.getOrganizationPaymentMethod(this.selectedTargetOrg.organizationCd)

    this.dialogOpen = true
    if (action.actionCd === 'actionCd1') {
      // 新規登録
      this.selectedInvoiceTarget = {}
      this.setOrganizationPaymentMethod(organizationPaymentMethod.data)
    } else if (action.actionCd === 'actionCd2') {
      // 既存更新
      const ret = await this.invoiceService.searchInvoiceTargets(event.rows[0].invoiceTargetCd)
      if (ret?.resultCode !== 0) { return }
      this.selectedInvoiceTarget = ret.data
      const form2 = {
        branchOffice: this.selectedInvoiceTarget.branchOffice,
        sectionName: this.selectedInvoiceTarget.sectionName,
        personName: this.selectedInvoiceTarget.personName,
      }
      const form3 = {
        postCode: this.selectedInvoiceTarget.postCode,
        prefecture: this.selectedInvoiceTarget.prefecture,
        city: this.selectedInvoiceTarget.city,
        blockName: this.selectedInvoiceTarget.blockName,
      }
      const form4 = {
        invoiceTitle: this.selectedInvoiceTarget.title
      }

      setTimeout(() => {
        this.inputTable2.setForm(form2, this.formItems2)
        this.inputTable3.setForm(form3, this.formItems3)
        this.inputTable4.setForm(form4, this.formItems4)
        this.setOrganizationPaymentMethod(organizationPaymentMethod.data,this.selectedInvoiceTarget.organizationPaymentMethodCd)
        }, 500)
    }
  }

  async getCustomerList(): Promise<void> {
    // 指定組織の顧客取得
    const org = this.authService.ChangeCustomer?.organizationCd
    if (org) {
      // const ret = await this.organizationV2Service.GetOrganizationCustomerList(org).toPromise()
      const ret$ = this.organizationV2Service.GetOrganizationCustomerList(org)
      const ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) { return }
      this.customers = ret.data
    }
  }

  // 新規登録
  async regist(): Promise<void> {
    if (this.sending) { return }
    const submitForm2 = this.inputTable2.submit()
    const submitForm3 = this.inputTable3.submit()
    const submitForm4 = this.inputTable4.submit()
    if (!submitForm2 || !submitForm3 || !submitForm4) { return }
    const customer = this.customers.find(x => x.customerCd === this.selectedInvoiceTarget.customerCd)
    if (!customer) {
      this.messageService.templeteMessage(messageList.M00032)
      return
    }
    // 選択した請求区分を取得、請求区分が選択されていない場合はnullを送信する
    const organizationPaymentMethodCd = this.selectedPaymentMethodCd || null

    const req: CreateInvoiceTargetRequest = {
      invoiceTargetCd: this.selectedInvoiceTarget?.invoiceTargetCd,
      suppliBillingCustomerId: this.selectedInvoiceTarget?.suppliBillingCustomerId,
      customerCd: customer.customerCd,
      organizationCd: customer.organizationCd,
      branchOffice: submitForm2.branchOffice,
      sectionName: submitForm2.sectionName,
      personName: submitForm2.personName,
      postCode: submitForm3.postCode,
      prefecture: submitForm3.prefecture,
      city: submitForm3.city,
      blockName: submitForm3.blockName,
      title: submitForm4.invoiceTitle,
      telNo: this.selectedInvoiceTarget?.telNo,
      faxNo: this.selectedInvoiceTarget?.faxNo,
      organizationPaymentMethodCd
    }
    this.sending = true
    const ret = await this.invoiceService.invoiceTargetsCreate(req)
    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00033)
    this.dialogOpen = false
    this.getCompanyInvoiceList()
  }

  /**
   * 
   * @param organizationCd 
   * @returns 
   * @description
   * 請求区分を組織で取得する
   */
 async getOrganizationPaymentMethod(organizationCd: string):Promise<ResultInfo<PaymentMethodAccount>>{
    try {
      const data = await this.invoiceLeafApiService.GetOrganizationPaymentMethods(
        organizationCd
      );
      return data;
    } 
    catch (error) {
      return null;
    }
  }
  
  /**
   * 
   * 
   * @param paymentMethod
   * @param paymentMethodCd
   * @description
   * 口座情報をセットする
   **/
  setOrganizationPaymentMethod(paymentMethod:PaymentMethodAccount[],paymentMethodCd:string = null): void {
    this.organizationPaymentMethods = paymentMethod 
    this.selectedPaymentMethodCd = paymentMethodCd
  }

  paymentMethodNameForDisplay(organizationPaymentMethod:PaymentMethodAccount): string {
    const paymentMethodCd = organizationPaymentMethod.paymentMethodItems
    return `${paymentMethodCd.bankName} : ${paymentMethodCd.branchName} : ${paymentMethodCd.accountNo} : ${paymentMethodCd.accountName}`
  }
}
