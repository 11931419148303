import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() label2: string
  @Input() linkAction2 = false // 遷移ではなく独自処理を行いたい場合
  @Input() link2: string
  @Output() click2 = new EventEmitter<void>()
  @Input() label3: string
  @Input() linkAction3 = false // 遷移ではなく独自処理を行いたい場合
  @Input() link3: string
  @Output() click3 = new EventEmitter<void>()
  @Input() label4: string
  @Input() linkAction4 = false // 遷移ではなく独自処理を行いたい場合
  @Input() link4: string
  @Output() click4 = new EventEmitter<void>()
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  linkClick1(): void {
    this.router.navigate(["/"])
  }
  
  linkClick2(): void {
    if (this.linkAction2) {
      this.click2.emit()
    }
    else if (this.link2) {
      this.router.navigate([this.link2])
    }
  }

  linkClick3(): void {
    if (this.linkAction3) {
      this.click3.emit()
    }
    else if (this.link3) {
      this.router.navigate([this.link3])
    }
  }

  linkClick4(): void {
    if (this.linkAction4) {
      this.click4.emit()
    }
    else if (this.link4) {
      this.router.navigate([this.link4])
    }
  }

}
