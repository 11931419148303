<div class="breadcrumbs-nav">
  <a (click)="linkClick1()" style="cursor: pointer">TOP</a
  >
  <ng-container *ngIf="label2">
    >
    <a (click)="linkClick2()" style="cursor: pointer">{{ label2 }}</a>
  </ng-container>
  <ng-container *ngIf="label3">
    >
    <a (click)="linkClick3()" style="cursor: pointer">{{ label3 }}</a>
  </ng-container>
  <ng-container *ngIf="label4">
    >
    <a (click)="linkClick4()" style="cursor: pointer">{{ label4 }}</a>
  </ng-container>
</div>
