<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-sending *ngIf="sending"></app-sending>
  <!-- タブ -->
  <div *ngIf="selectTargetMode" class="c-tab h-margin--b24">
    <div class="c-tab__item">
      <a (click)="showTabKey = ''" [class.is-current]="!showTabKey"
        >自社に接続</a
      >
    </div>
    <div class="c-tab__item">
      <a
        (click)="showTabKey = 'otherCompany'"
        [class.is-current]="showTabKey === 'otherCompany'"
        >他社に接続</a
      >
    </div>
  </div>

  <!-- 追加差分 -->
  <ng-container *ngIf="!showTabKey">
    <app-service-map-main
      [loading]="loading"
      [serviceMap]="serviceMap"
      (clickService)="clickServiceEvent($event)"
      (clickActionBlock)="clickActionBlockEvent($event)"
    >
    </app-service-map-main>
    <app-under-btn-disp
      [actionButtons]="actionButtonsDisp"
    ></app-under-btn-disp>
  </ng-container>
  <ng-container *ngIf="showTabKey === 'otherCompany'">
    <app-ura-conector-link
      #connectSearch
      [productCd]="targetProductCd"
      (commit)="connect($event, fromActuateCd)"
    ></app-ura-conector-link>
    <app-under-btn-disp
      [actionButtons]="actionButtonsDispForOtherCompany"
    ></app-under-btn-disp>
  </ng-container>
</app-main-contents>
