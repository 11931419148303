<aside class="c-nav js-navigation" [class.ura]="ura">
  <div class="c-nav__wrap">
    <nav class="c-nav__main">
      <ul class="c-nav__list">
        <ng-container *ngFor="let item of menu">
          <li *ngIf="menuDisp(item)" class="c-nav__item">
            <p class="c-nav__name">
              <a
                *ngIf="!isOuterLink(item.path)"
                [routerLink]="item.path"
                routerLinkActive="is-current"
                [routerLinkActiveOptions]="{ exact: true }"
                [class.link-disable]="item.disabled"
                (click)="clickLink()"
              >
                <!-- アイコンバッチ -->
                <i
                  [class]="'c-icon__' + item.icon"
                  [class.-notification]="notification(item)"
                ></i>
                <span
                  >{{ item.label }}
                  <!-- 個別通知 -->
                  <i
                    *ngIf="attention(item)"
                    class="c-icon__attention_fill"
                  ></i> </span
              ></a>
              <a
                *ngIf="isOuterLink(item.path)"
                [href]="item.path"
                target="_blank"
              >
                <i
                  [class]="'c-icon__' + item.icon"
                  [class.-notification]="notification(item)"
                ></i>
                <span>{{ item.label }}</span></a
              >
              <span
                *ngIf="item.children?.length"
                [class.is-open]="isCurrent(item)"
                class="c-nav__arrow"
                ><i class="c-icon__arrow_s_bottom"></i
              ></span>
            </p>
            <ng-container *ngFor="let child of item.children">
              <ul
                *ngIf="menuDisp(child)"
                [class.is-open]="isCurrent(item)"
                class="c-nav__children"
              >
                <li class="c-nav__sub">
                  <a
                    *ngIf="!isOuterLink(child.path)"
                    [routerLink]="child.path"
                    routerLinkActive="is-current"
                    [class.link-disable]="child.disabled"
                    (click)="clickLink()"
                  >
                    <span
                      >{{ child.label }}
                      <!-- 個別通知 -->
                      <i
                        *ngIf="attention(child)"
                        class="c-icon__attention_fill"
                      ></i> </span
                  ></a>
                  <a
                    *ngIf="isOuterLink(child.path)"
                    [href]="child.path"
                    target="_blank"
                  >
                    <span>{{ child.label }}</span></a
                  >
                </li>
              </ul></ng-container
            >
          </li>
        </ng-container>
      </ul>
      <small class="c-nav__copyright">©︎ INTERSERVE Corporation.</small>
    </nav>
  </div>
</aside>
