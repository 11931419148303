import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { AboxActuatesUser, ServiceUserAndAuth, ServiceUserAuthRequest, UpdateAuthRequest } from './service-user-api.interface'

@Injectable({
  providedIn: 'root'
})
export class ServiceUserService {
  baseURL = environment.backendMacroBaseUrl
  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  async getServiceUsers(userTargetActuateCd: string): Promise<ResultInfo<ServiceUserAndAuth>> {
    const url = `${environment.myideaMacroBaseUrl}api/serviceUser/userTargetActuate/${userTargetActuateCd}`
    const ret$ = this.httpRequestService.get<ServiceUserAndAuth>(url)
    return await lastValueFrom(ret$)
  }

  // 対象のAboxのユーザリストを返す
  GetAboxActuatesUserList(aboxActuateCd: string): Observable<ResultInfo<AboxActuatesUser>> {
    const url = `${this.BaseURL}api/AboxActuates/${aboxActuateCd}/UserList`
    return this.httpRequestService.get(url)
  }

  // 対象のAboxからユーザー削除
  async DeleteAboxActuatesUser(serviceUserCds: string[]): Promise<ResultInfo<AboxActuatesUser>> {
    const url = `${environment.myideaMacroBaseUrl}api/serviceUser/cascade`
    const ret$ = this.httpRequestService.delete<AboxActuatesUser>(url, serviceUserCds)
    return await lastValueFrom(ret$)
  }

  // 利用者追加
  AddUser(payload: ServiceUserAuthRequest[]): Observable<ResultInfo<boolean>> {
    const url = `${this.BaseURL}api/ServiceUserAuthority/CreateAndSendMail`
    return this.httpRequestService.post(url, payload)
  }

  // 利用者権限更新
  UpdateAuth(payload: UpdateAuthRequest[]): Observable<ResultInfo<AboxActuatesUser>> {
    const url = `${this.BaseURL}api/ServiceUserAuthority`
    return this.httpRequestService.put(url, payload)
  }
}
