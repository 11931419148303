<app-main-contents>
    <app-progress *ngIf="sending"></app-progress>
    <h2 class="c-texts--head2 c-texts--center h-margin--t48">
      <b>パスワード再設定</b>
    </h2>
    <p class="c-texts--attention h-margin--b16">＊は入力必須項目です</p>
  
    <app-input-table [formItems]="formItems"></app-input-table>
    <div class="c-btns">
      <div class="c-btns__item">
        <a class="c-btn c-btn--blue" (click)="submit()" style="cursor: pointer"
          ><span>設定する</span>
        </a>
      </div>
    </div>
  </app-main-contents>
  