import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { Wbs } from './petabo-api.interface'

@Injectable({
  providedIn: 'root'
})
export class PetaboApiService {
  baseURL = environment.petaboBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  async getPetabo(fqdn: string): Promise<ResultInfo<Wbs>> {
    const url = `${this.baseURL}api/petabo?fqdn=${fqdn}`
    const ret$ = this.httpRequestService.get<Wbs>(url)
    return await lastValueFrom(ret$)
  }

  async putPetaboIndexWbs(fqdn: string, wbs: Wbs[]): Promise<ResultInfo<Wbs>> {
    const url = `${this.baseURL}api/petabo/indexWbs?fqdn=${fqdn}`
    const ret$ = this.httpRequestService.put<Wbs>(url, wbs)
    return await lastValueFrom(ret$)
  }

}