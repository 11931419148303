import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/components/isbeauth/auth.service'

@Component({
  selector: 'app-account-changed',
  templateUrl: './account-changed.component.html',
  styleUrls: ['./account-changed.component.scss'],
})
export class AccountChangedComponent implements OnInit {
  title = '個人情報の変更'
  customerCd = this.authService?.AccountInfo?.customerCd

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {}
}
