<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    [exportExcelButton]="true"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>
