import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonPartsModule } from 'src/app/module/common-parts/common-parts.module'
import { UraideaV2RoutingModule } from './uraidea-v2-routing.module'
import { UraTopComponent } from './ura-top/ura-top.component'
import { IsGridModule } from 'src/app/module/is-grid/is-grid.module'
// import { InterserveuiAngularModule } from '@interserve/interserveui-angular'
import { IgxModules } from 'src/app/module/igx.module'
import { UraCompanyAddV2Component } from './ura-company-add/ura-company-add.component'
import { UraContractEntryListComponent } from './ura-contract-entry-list/ura-contract-entry-list.component'
import { UraConactorLinkComponent } from './ura-conector-link/ura-conector-link.component'
import { UraAddContractServiceMapComponent } from './ura-add-contract-service-map/ura-add-contract-service-map.component'
import { UraContractNewListComponent } from './ura-contract-new-list/ura-contract-new-list.component'
import { UraContactUserListComponent } from './ura-contact-user-list/ura-contact-user-list.component'
import { UraConnectServiceMapComponent } from './ura-connect-service-map/ura-connect-service-map.component'
import { UraCancelContractServiceMapComponent } from './ura-cancel-contract-service-map/ura-cancel-contract-service-map.component'
import { UraCancelComponent } from './ura-cancel-page/ura-cancel-page.component'
import { UraNewCompanyProfileComponent } from './ura-new-company-profile/ura-new-company-profile.component'
import { UraSuperAdminComponent } from './ura-super-admin/ura-super-admin.component'
import { MyideaPartsModule } from 'src/app/module/myidea-parts/myidea-parts.module'
import { UraOrganizationTreeViewComponent } from './ura-organization-tree-view/ura-organization-tree-view.component'
import { UraOrganizationCustomerListComponent } from './ura-organization-customer-list/ura-organization-customer-list.component'
import { UraOrganizationTreeEditComponent } from './ura-organization-tree-edit/ura-organization-tree-edit.component'
import { UraCompanyInvoiceComponent } from './ura-company-invoice/ura-company-invoice.component'
import { UraCompanyUserComponent } from './ura-company-user/ura-company-user.component'
import { UraEstimateListComponent } from './ura-estimate-list/ura-estimate-list.component'
import { UraEstimateNewComponent } from './ura-estimate-new/ura-estimate-new.component'
import { UraAddNewContractDetailComponent } from './ura-add-new-contract-detail/ura-add-new-contract-detail.component'
import { UraQuotationPreviewNewComponent } from './ura-quotation-preview-new/ura-quotation-preview-new.component'
import { UraEstimateListNewComponent } from './ura-estimate-list-new/ura-estimate-list-new.component'
import { UraAddContractDetailComponent } from './ura-add-contract-detail/ura-add-contract-detail.component'
import { IgxProgressBarModule } from '@infragistics/igniteui-angular'
import { UraSupportSearchAccountComponent } from './ura-support-search-account/ura-support-search-account.component'
import { SettingInvoiceDialogComponent } from './ura-contract-entry-list/setting-invoice-dialog/setting-invoice-dialog.component'
import { ContractDispModule } from 'src/app/module/myidea-parts/contract-detail-block/contract-disp.module'
import { UraContractSearchListComponent } from './ura-contract-search-list/ura-contract-search-list.component'
import { UraServiceMapSearchComponent } from './ura-service-map-search/ura-service-map-search.component'
import { UraUserSearchListComponent } from './ura-user-search-list/ura-user-search-list.component'
import { SearchConditionsAreaComponent } from './components/search-conditions-area/search-conditions-area.component'
import { InputProductInquiryCdComponent } from './components/input-product-inquiry-cd/input-product-inquiry-cd.component'
import { UraCsvDownloadComponent } from './ura-csv-download/ura-csv-download.component'
import { UraQuotationPreviewAddOptionComponent } from './ura-quotation-preview-add-option/ura-quotation-preview-add-option.component'
import { UraCreateAddQuotationBusinessCategoryComponent } from './ura-create-add-quotation-business-category/ura-create-add-quotation-business-category.component'
import { UraCreateAddQuotationMerchandiseComponent } from './ura-create-add-quotation-merchandise/ura-create-add-quotation-merchandise.component'
import { UraCreateAddQuotationConfirmComponent } from './ura-create-add-quotation-confirm/ura-create-add-quotation-confirm.component'
import { UraCreateAddQuotationRemarksComponent } from './ura-create-add-quotation-remarks/ura-create-add-quotation-remarks.component'
import { UraQuotationPreviewAddProductComponent } from './ura-quotation-preview-add-product/ura-quotation-preview-add-product.component'
import { UraNewQuotationRetailComponent } from './ura-new-quotation-retail/ura-new-quotation-retail.component'
import { UraNewQuotationBusinessCategoryComponent } from './ura-new-quotation-business-category/ura-new-quotation-business-category.component'
import { UraNewQuotationServiceComponent } from './ura-new-quotation-service/ura-new-quotation-service.component'
import { UraNewQuotationMerchandiseComponent } from './ura-new-quotation-merchandise/ura-new-quotation-merchandise.component'
import { UraNewQuotationRemarksComponent } from './ura-new-quotation-remarks/ura-new-quotation-remarks.component'
import { UraInvoiceChangeComponent } from './ura-invoice-change/ura-invoice-change.component'
import { EstimateBreadcrumbsComponent } from './components/estimate-breadcrumbs/estimate-breadcrumbs.component'
import { UraMonitoringPackSendTaskComponent } from './ura-monitoring-pack-send-task/ura-monitoring-pack-send-task.component'
import { UraCancelContarctListComponent } from './ura-cancel-contract-list/ura-cancel-contract-list.component'
import { UraMerchandiseListComponent } from './ura-merchandise-list/ura-merchandise-list.component'
import { UraProductListComponent } from './ura-product-list/ura-product-list.component'
import { ProductEditDialogComponent } from './components/product-edit-dialog/product-edit-dialog.component'
import { ActuateSettingDialogComponent } from './components/actuate-setting-dialog/actuate-setting-dialog.component'
import { FrimoActuateSettingDialogComponent } from './components/frimo-actuate-setting-dialog/frimo-actuate-setting-dialog.component'
import { MerchandiseEditDialogComponent } from './components/merchandise-edit-dialog/merchandise-edit-dialog.component'
import { UraAccountLinkComponent } from './ura-account-link/ura-account-link.component'
import { CreateAccountUrlDialogComponent } from './components/create-account-url-dialog/create-account-url-dialog.component'
import { UraCompanyCheckListComponent } from './ura-company-check-list/ura-company-check-list.component'
import { UraEditCompanyProfileComponent } from './ura-edit-company-profile/ura-edit-company-profile.component'
import { UraSearchRelationCorporateComponent } from './ura-search-relation-corporate/ura-search-relation-corporate.component'
import { UraNafcoActuateSettingComponent } from './ura-nafco-actuate-setting/ura-nafco-actuate-setting.component'
import { UraCreateAddQuotationRetailComponent } from './ura-create-add-quotation-retail/ura-create-add-quotation-retail.component'
import { UraNafcoMigrationActuateSettingComponent } from './ura-nafco-migration-actuate-setting/ura-nafco-migration-actuate-setting.component'
import { UraUpdateActuateSettingComponent } from './ura-update-actuate-setting/ura-update-actuate-setting.component'
import { UraSundryContractComponent } from './ura-sundry-contract/ura-sundry-contract.component'
import { TagSelectComponent } from './components/tag-select-area/tag-select-area.component'
import { UrainclusionContractComponent } from './ura-inclusion-contract/ura-inclusion-contract.component'
import { UraContractIndividualComponent } from './ura-contract-individual/ura-contract-individual.component'
import { UraNewCompanyCustomerComponent } from './ura-new-company-customer/ura-new-company-customer.component'
import { UraConpanyContractedOrganizationListComponent } from './ura-conpany-contracted-organization-list/ura-conpany-contracted-organization-list.component'
import { UraManagementUserComponent } from './ura-management-user/ura-management-user.component'
import { UraTrialContractListComponent } from './ura-trial-contract-list/ura-trial-contract-list.component'
import { UraCompanyListComponent } from './ura-company-list/ura-company-list.component'
import { UraInvoicePjStatusListComponent } from './ura-invoice-pj-status-list/ura-invoice-pj-status-list.component'
import { UraHandoverCustomerAuthComponent } from './ura-handover-customer-auth/ura-handover-customer-auth.component'
import { UraConfirmInvoiceListComponent } from './ura-confirm-invoice-list/ura-confirm-invoice-list.component'
import { UraSendInvoiceForBankComponent } from './ura-send-invoice-for-bank/ura-send-invoice-for-bank.component'
import { UraPaymentMethodAccountListComponent } from './ura-payment-method-account-list/ura-payment-method-account-list.component'
import { UraMigrationSendListComponent } from './ura-migration-send-list/ura-migration-send-list.component'
import { UraPetaboEditComponent } from './ura-petabo-edit/ura-petabo-edit.component'
import { UraAccountLinkUrlSendMailPreviewDialogComponent } from './ura-account-link/components/ura-account-link-url-send-mail-preview'

@NgModule({
  declarations: [
    UraTopComponent,
    UraCompanyAddV2Component,
    UraContractEntryListComponent,
    UraContractNewListComponent,
    UraContactUserListComponent,
    UraAddContractServiceMapComponent,
    UraConnectServiceMapComponent,
    UraCancelContractServiceMapComponent,
    UraConactorLinkComponent,
    UraCancelComponent,
    UraNewCompanyProfileComponent,
    UraSuperAdminComponent,
    UraOrganizationTreeViewComponent,
    UraOrganizationTreeEditComponent,
    UraOrganizationCustomerListComponent,
    UraCompanyInvoiceComponent,
    UraCompanyUserComponent,
    UraEstimateListComponent,
    UraEstimateNewComponent,
    UraAddNewContractDetailComponent,
    UraQuotationPreviewNewComponent,
    UraEstimateListNewComponent,
    UraAddContractDetailComponent,
    UraSupportSearchAccountComponent,
    SettingInvoiceDialogComponent,
    UraContractSearchListComponent,
    UraServiceMapSearchComponent,
    UraUserSearchListComponent,
    SearchConditionsAreaComponent,
    InputProductInquiryCdComponent,
    UraCsvDownloadComponent,
    UraQuotationPreviewAddOptionComponent,
    UraCreateAddQuotationBusinessCategoryComponent,
    UraCreateAddQuotationMerchandiseComponent,
    UraCreateAddQuotationConfirmComponent,
    UraCreateAddQuotationRemarksComponent,
    UraQuotationPreviewAddProductComponent,
    UraNewQuotationRetailComponent,
    UraNewQuotationBusinessCategoryComponent,
    UraNewQuotationServiceComponent,
    UraNewQuotationMerchandiseComponent,
    UraNewQuotationRemarksComponent,
    UraInvoiceChangeComponent,
    EstimateBreadcrumbsComponent,
    UraMonitoringPackSendTaskComponent,
    UraCancelContarctListComponent,
    UraMerchandiseListComponent,
    UraProductListComponent,
    ProductEditDialogComponent,
    ActuateSettingDialogComponent,
    FrimoActuateSettingDialogComponent,
    MerchandiseEditDialogComponent,
    UraCompanyCheckListComponent,
    UraSearchRelationCorporateComponent,
    UraEditCompanyProfileComponent,
    UraAccountLinkComponent,
    UraSearchRelationCorporateComponent,
    UraEditCompanyProfileComponent,
    CreateAccountUrlDialogComponent,
    UraNafcoActuateSettingComponent,
    UraNafcoMigrationActuateSettingComponent,
    UraCreateAddQuotationRetailComponent,
    UraUpdateActuateSettingComponent,
    UraSundryContractComponent,
    TagSelectComponent,
    UrainclusionContractComponent,
    UraContractIndividualComponent,
    UraNewCompanyCustomerComponent,
    UraConpanyContractedOrganizationListComponent,
    UraManagementUserComponent,
    UraTrialContractListComponent,
    UraCompanyListComponent,
    UraInvoicePjStatusListComponent,
    UraHandoverCustomerAuthComponent,
    UraConfirmInvoiceListComponent,
    UraSendInvoiceForBankComponent,
    UraPaymentMethodAccountListComponent,
    UraMigrationSendListComponent,
    UraPetaboEditComponent,
    UraAccountLinkUrlSendMailPreviewDialogComponent
  ],
  imports: [
    CommonModule,
    IgxModules,
    IsGridModule,
    CommonPartsModule,
    ReactiveFormsModule,
    MyideaPartsModule,
    UraideaV2RoutingModule, // Ura-ideaページを非公開に
    IgxProgressBarModule,
    ContractDispModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class UraideaV2Module {}
