<app-main-contents>
  <app-title [title]="'管理者情報入力'"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <app-create-customer-form
    [hideBackButton]="false"
    [regist]="false"
    [formValue]="customerInput"
    (commit)="commit($event)"
    (back)="pageBack($event)"
  ></app-create-customer-form>
</app-main-contents>
