<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>

<app-dialog
  *ngIf="createDialogOpen"
  [title]="'新規追加'"
  widthSize="large"
  commitButtonText="登録"
  (commit)="createRow()"
  (cancel)="createDialogOpen = false"
>
  <app-input-table #inputTableForCreate [formItems]="formItems"> </app-input-table>
</app-dialog>

<app-dialog
  *ngIf="updateDialogOpen"
  [title]="'内容変更'"
  widthSize="large"
  commitButtonText="変更"
  (commit)="updateRow()"
  (cancel)="updateDialogOpen = false"
>
  <app-input-table #inputTableForUpdate [formItems]="formItems" [formValue]="updateValue"> </app-input-table>
</app-dialog>
