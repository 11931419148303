import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class FileManagementService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getFile(url: string, type: string): Observable<Blob> {
    let headers = new HttpHeaders()
    headers = headers.set('Accept', type)
    return this.httpClient.get(url, { headers, responseType: 'blob' })
  }

  // ファイルダウンロード
  async downloadFile(url: string, type: string, fileName: string = null): Promise<void> {
    const link = document.createElement('a')
    if (type === 'application/pdf') {
      this.getFile(url, type)
        .subscribe((data: any) => {
          const blob = new Blob([data], { type })
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName ?? url.split('/').pop().split('\\').pop()
          link.click()
          URL.revokeObjectURL(link.href);
        })
    }
    else {
      link.href = url
      link.click()
      URL.revokeObjectURL(link.href);
    }    
  }

  // PDFファイルダウンロード
  async downloadPdfFile(url: string, fileName: string = null): Promise<void> {
    await this.downloadFile(url, 'application/pdf', fileName)
    return
  }
  // CSVファイルダウンロード
  async downloadCsvFile(url: string): Promise<void> {
    await this.downloadFile(url, 'text/csv')
    return
  }
  // テキストファイルダウンロード
  async downloadTextFile(url: string): Promise<void> {
    await this.downloadFile(url, 'text/plain')
    return
  }
}
