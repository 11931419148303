<div class="c-parent" id="parentPanel">
    <div class="c-parent__main c-parent__main--pulldown">
      <span class="c-parent__icon c-parent__icon--font"
        ><i class="c-icon__company"></i
      ></span>
      <h2 class="c-texts--head2">{{ companyOrganizationName }}</h2>
    </div>
  
    <p class="h-margin--t16" style="padding-left: 270px" *ngIf="showMenber">
      <a
        class="c-btn c-btn--block c-btn--grey"
        [class.link-disable]="UserListBrowseDisabled"
        (click)="gotoCompanyMemberList()"
        style="cursor: pointer"
      >
        <i class="c-icon__user"></i><span>所属メンバーを見る</span></a
      >
    </p>
    <p class="h-margin--t16"></p>
  </div>
  
  <div class="l-main__contents js-fit-contents">
    <app-progress *ngIf="loading"></app-progress>
  
    <!-- contents-->
    <div class="c-group">
      <div class="l-has-btn">
        <ul class="c-links">
          <label class="c-form__checkbox2">
            <input type="checkbox" [(ngModel)]="isShowCustomers" /><span
              >ユーザーを表示</span
            >
          </label>
          <li *ngIf="treeEdit">
            <a
              (click)="editOrganization()"
              style="cursor: pointer"
              [class.link-disable]="editDisabled"
              ><i class="c-icon__edit"></i>組織図を編集する</a
            >
          </li>
        </ul>
      </div>
  
      <div class="c-group__wrap">
        <div class="c-group__block">
          <div class="c-group__body">
  
            <!-- 左側に表示される1階層目の組織 -->
            <div class="c-group__parent">
              <div class="c-group__panel c-group__panel--default">
                <a
                  class="c-panel c-panel--s"
                  [style.background-color]="getTypeColor(selectedBranch)"
                  [style.height]="getTypeHeight(selectedBranch)"
                  [class.currentorg]="selectedBranch === selectedOrganization"
                  (click)="clickOrgBox(selectedBranch)"
                  style="cursor: pointer"
                >
                  <p class="c-texts--s">
                    <b>{{ selectedBranch?.organizationName }}</b>
                  </p></a
                >
              </div>
            </div>
            <div
              *ngIf="
                selectedBranch?.children?.length > 0 ||
                (isShowCustomers === true &&
                  selectedBranch?.childrenCustomer?.length > 0)
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  rowsTemplate;
                  context: {
                    list: selectedBranch.children,
                    custlist: selectedBranch.childrenCustomer
                  }
                "
              ></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- 2階層目以下のテンプレート -->
    <ng-template #rowsTemplate let-list="list" let-custlist="custlist">
      <div class="c-group__children">
        <ng-container *ngIf="isShowCustomers === true">
          <div class="c-group__line" *ngFor="let customer of custlist">
            <div
              class="c-group__panel c-group__panel--default"
              style="height: 58px"
            >
              <a
                class="c-panel c-panel--s"
                style="height: 38px; background-color: #fff5ee; cursor: pointer"
              >
                <p class="c-texts--s">
                  <b>{{ customer.familyName }} &ensp; {{ customer.firstName }}</b>
                </p></a
              >
            </div>
          </div>
        </ng-container>
        <div class="c-group__line" *ngFor="let item of list; index as i">
          <div
            class="c-group__panel c-group__panel--default"
            style="height: 58px"
          >
            <a
              class="c-panel c-panel--s"
              [style.background-color]="getTypeColor(item)"
              [style.height]="getTypeHeight(item)"
              [class.currentorg]="item === selectedOrganization"
              (click)="clickOrgBox(item)"
              style="cursor: pointer"
            >
              <p class="c-texts--s">
                <b>{{ item.organizationName }}</b>
              </p></a
            >
          </div>
          <div
            *ngIf="
              item.children.length > 0 ||
              (isShowCustomers === true && item.childrenCustomer.length > 0)
            "
          >
            <ng-container
              *ngTemplateOutlet="
                rowsTemplate;
                context: { list: item.children, custlist: item.childrenCustomer }
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  
    <igx-dialog #editNameDialog [closeOnOutsideSelect]="true" message="">
      <div class="editnamedialogstyle">
        <h2 class="c-texts--head1">名前を変更する</h2>
        <div class="l-table">
          <div class="l-table__item">
            <div class="l-table__head" style="width: 100px; min-width: 100px">
              <p>名前<span class="c-label--required">*</span></p>
            </div>
            <div class="l-table__body">
              <input
                class="c-form__input is-not-blank"
                type="text"
                [(ngModel)]="inputOrganizationName"
                required="required"
              />
            </div>
          </div>
        </div>
        <div class="c-btns">
          <div class="c-btns__item">
            <a
              class="js-rename-close c-btn"
              (click)="editNameDialog.close()"
              style="cursor: pointer"
            >
              <span>キャンセル</span></a
            >
          </div>
          <div class="c-btns__item">
            <a
              class="js-rename-close c-btn c-btn--blue"
              (click)="updateOrganizationName()"
              style="cursor: pointer"
            >
              <span>変更する</span></a
            >
          </div>
        </div>
      </div>
    </igx-dialog>
    <!-- /contents-->
  </div>
  
  <div class="l-aside" id="aside" *ngIf="sideMenu">
    <div class="l-aside__inner">
      <h3 class="c-texts--head2 h-margin--b24">
        {{ selectedOrganization?.organizationName }}
      </h3>
      <p class="h-margin--t16">
        <a
          class="c-btn c-btn--block c-btn--grey"
          [class.link-disable]="UserListBrowseDisabled"
          (click)="gotoMemberList()"
          style="cursor: pointer"
        >
          <i class="c-icon__user"></i><span>配属メンバーを見る</span></a
        >
      </p>
      <!-- <p class="h-margin--t16">
        <a
          class="c-btn c-btn--block c-btn--grey"
          routerLink="/servicemap"
          [class.link-disable]="ServiceContractBrowseDisabled"
        >
          <i class="c-icon__document"></i><span>契約情報を見る</span></a
        >
      </p>
      <p class="h-margin--t16">
        <a
          class="c-btn c-btn--block c-btn--grey"
          routerLink="/servicemap"
          [class.link-disable]="ServiceContractBrowseDisabled"
        >
          <i class="c-icon__service"></i><span>利用情報を見る</span></a
        >
      </p> -->
      <hr class="h-margin--24" />
      <ul class="c-links c-links--center h-margin--24">
        <li>
          <a
            class="js-rename-open"
            [class.link-disable]="companyOrganizationCd === selectedOrganization?.organizationCd"
            (click)="openEditOrganizationNameDialog()"
            style="cursor: pointer"
          >
            <i class="c-icon__edit"></i>名前を変更する</a
          >
        </li>
      </ul>
    </div>
  </div>
  