<div class="c-form">
  <div class="l-col--3">
    <div class="l-col__item" *ngFor="let tag of tags">
      <label class="c-form__checkbox2">
        <input
          type="radio"
          [(ngModel)]="tagCd"
          [value]="tag.tagCd"
          [checked]="tag.tagCd === tagCd"
          (change)="changeCd.emit(tag.tagCd)"
        /><span
          ><b>{{ tag.tagText }}</b></span
        >
      </label>
    </div>
  </div>
</div>
