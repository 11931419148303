import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { HttpRequestService } from '../common/http-request.service'
import { lastValueFrom } from 'rxjs'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import {
  BeforeAfterMicomelModel,
  MicomelContractJudgeModel,
  MicomelUserAndStatusUi
} from './micomel-migrations-api.model'

@Injectable({
  providedIn: 'root'
})
export class ShimuraGarageMigrationsService {
  baseURL = environment.backendMacroBaseUrl
  constructor(private httpRequestService: HttpRequestService) {}
  controllerName = 'ShimuraGarageMigrations'

  get BaseURL(): string {
    return this.baseURL
  }

  async getContractCompanyList(): Promise<ResultInfo<MicomelUserAndStatusUi>> {
    const url = `${this.baseURL}api/${this.controllerName}/ContractCompanyList`
    const ret$ = this.httpRequestService.get<MicomelUserAndStatusUi>(url)
    return await lastValueFrom(ret$)
  }

  async checkContractCustomer(customerCd: string): Promise<ResultInfo<MicomelContractJudgeModel>> {
    const url = `${this.baseURL}api/${this.controllerName}/Check/ContractCustomers/${customerCd}`
    const ret$ = this.httpRequestService.get<MicomelContractJudgeModel>(url)
    return await lastValueFrom(ret$)
  }

  async sendMail(organizationCds: string[]): Promise<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/${this.controllerName}/SendMail`
    const ret$ = this.httpRequestService.post<boolean>(url, organizationCds)
    return await lastValueFrom(ret$)
  }

  async getEstimateData(
    organizationId: string,
    parentContractId: string
  ): Promise<ResultInfo<BeforeAfterMicomelModel>> {
    const url = `${this.baseURL}api/${this.controllerName}/Organizations/${organizationId}/${parentContractId}/AddContract/Search`
    const ret$ = this.httpRequestService.get<BeforeAfterMicomelModel>(url)
    return await lastValueFrom(ret$)
  }

  async approval(payload: any): Promise<ResultInfo<boolean>> {
    const url = `${this.baseURL}api/${this.controllerName}/Approval`
    const ret$ = this.httpRequestService.post<boolean>(url, payload)
    return await lastValueFrom(ret$)
  }

  encodeGuids(guid1: string, guid2: string): string {
    const combinedGuid = `${guid1}-${guid2}`
    return btoa(combinedGuid) // btoa を使用
  }

  decodeGuid(encodedString: string): { guid1: string; guid2: string } {
    const combinedGuid = atob(encodedString) // atob を使用
    const [guid1, guid2] = combinedGuid.split('-')
    return { guid1, guid2 }
  }
}
