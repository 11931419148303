import { Component, OnInit } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { ContractAuthority, UserAuthority } from 'src/api/authorities-api/authorities-api.interface'
import { AuthoritiesApiService } from 'src/api/authorities-api/authorities-api.service'
import { BackendOrganizationCorporateV2, OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { JoinText } from 'src/app/common/common'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { SelectOption } from 'src/app/module/common-parts/select/select.component.interface'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-ura-handover-customer-auth',
  templateUrl: './ura-handover-customer-auth.component.html',
  styleUrls: ['./ura-handover-customer-auth.component.scss']
})
export class UraHandoverCustomerAuthComponent implements OnInit {
  title = '担当者変更'
  selectedCompany: BackendOrganizationCorporateV2
  customers: SelectOption[] = []
  selectedCustomerCd = '' // 変更元
  toCustomerCd = '' // 変更先

  contracts: ContractAuthority[] = []
  userAuth: UserAuthority[] = []
  suppliCutsomerIds: number[] = []
  columns: Header[] = [
    {
      key: 'merchandiseName',
      label: '商品名',
      groupLabel: '契約',
      width: '70%'
    },
    {
      key: 'contractDateUi',
      label: '稼働日',
      groupLabel: '契約',
      width: '30%'
    }
  ]
  userColumns: Header[] = [
    {
      key: 'authorityDivisionName',
      label: '権限',
      groupLabel: '権限',
      width: '20%'
    },
    {
      key: 'targetNameUi',
      label: '対象',
      groupLabel: '権限',
      width: '60%'
    },
    {
      key: 'createDateUi',
      label: '登録日',
      groupLabel: '権限',
      width: '20%'
    }
  ]
  rowSelection = RowSelection.NONE
  actionButtons: ActionButtonDisp[] = [
    {
      label: '変更後担当者を選択',
      actionFunction: () => {
        this.dialogOpen = true
      }
    }
  ]
  loading = true
  sending = false
  dialogOpen = false

  constructor(
    private organizationV2Service: OrganizationV2Service,
    private authoritiesApiService: AuthoritiesApiService,
    private messageService: MessageService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
  }

  selectTargetOrganization(event: BackendOrganizationCorporateV2): void {
    this.selectedCompany = event
    this.clear()
    this.selectedCustomerCd = ''
    this.searchCustomer()
  }

  async searchCustomer(): Promise<void> {
    if (!this.selectedCompany) { return }
    const ret$ = this.organizationV2Service.GetOrganizationCustomerList(this.selectedCompany.organizationCd)
    const ret = await lastValueFrom(ret$)
    if (ret?.resultCode !== 0) { return }
    this.customers = []
    ret.data.forEach(customer => {
      if (!this.customers.find(x => x.value === customer.customerCd)) {
        const selectOption: SelectOption = {
          label: JoinText([customer.familyName, customer.firstName], ' '),
          value: customer.customerCd
        }
        this.customers.push(selectOption)
      }
    })
  }

  async selectCustomer(): Promise<void> {
    this.loading = true
    if (!this.selectedCompany || !this.selectedCustomerCd) { return }
    this.clear()
    const ret = await this.authoritiesApiService.GetUserAuthority(this.selectedCompany.organizationCd, this.selectedCustomerCd)
    if (ret?.resultCode !== 0) { return }
    this.loading = false
    this.contracts = ret.data.contracts
    this.userAuth = ret.data.users
    this.userAuth.forEach(row => {
      if (row.productInquiryCd) {
        row.targetNameUi = `${row.targetName} (${row.productInquiryCd})`
      }
      else {
        row.targetNameUi = row.targetName
      }
    })
    this.suppliCutsomerIds = ret.data.suppliCustomerIds
  }

  clear(): void {
    this.contracts = []
    this.userAuth = []
    this.suppliCutsomerIds = []
  }

  commit(): void {
    if (this.sending) { return }
    if (!this.toCustomerCd) {
      this.messageService.warning('担当者が未選択です')
      return
    }
    if (this.selectedCustomerCd === this.toCustomerCd) {
      this.messageService.warning('変更前と同じ担当者です')
      return
    }
    this.confirmService.confirm(`本当に変更しますか？`, '', async () => {
      if (!this.selectedCompany || !this.selectedCustomerCd || !this.toCustomerCd) { return }
      this.sending = true
      this.clear()
      const ret = await this.authoritiesApiService.ChangeUserAuthority(
        this.selectedCompany.organizationCd, this.selectedCustomerCd, this.toCustomerCd
      )
      this.sending = false
      if (ret?.resultCode !== 0) { return }
      this.messageService.templeteMessage(messageList.M00050)
      this.dialogOpen = false
      this.clear()
      this.selectedCustomerCd = ''
    })
  }
}
