export interface BackendOrganizationCorporateV2 {
  corporateNumber: string
  organizationCd: string
  organizationName: string
  organizationFormalName: string
  organizationCategoryCd: string
  organizationCategoryName: string
  displayPositionCd: string
  displayPositionName: string
  postCode: string
  prefecture: string
  city: string
  block: string
  building: string
  // 一意のキー
  key?: string
}

export interface BackendOrganizationV2 {
  organizationCd: string
  organizationName: string
  organizationFormalName?: string
  organizationNameKana?: string
  organizationNameHebon?: string
  organizationCategoryCd: string
  organizationCategoryName?: string
  displayPositionCd: string
  displayPositionName?: string
  organizationTypeCd: string
  organizationTypeName?: string
  postCode: string
  prefecture: string
  city: string
  block: string
  building?: string
  telephoneNumber: string
  corporateNumber?: string
}

export interface OrganizationFullName {
  organizationCd: string
  organizationNestCds: string[]
  organizationFullName: string
  organizationTypeCd: string
}

export interface OrganizationCustomer {
  organizationCd: string
  organizationFullName: string
  mailAddress: string
  customerCd?: string
  familyName: string
  firstName: string
  isTempMigration?: boolean
}

export interface BackendOrganizationCustomerAccount {
  nickName: string
  customerCd: string
  organizationCd: string
  organizationFullName: string
  familyName: string
  firstName: string
  mailAddress: string,
  isFormalAccount: boolean
}

export interface OrganizationEstimate {
  organizationContractName: string,
  estimateNo: string,
  actuateCd: string,
  remarks: string,
  organizationContractStartDatetime: string,
  organizationContractEndDatetime: string,
  estimateMerchandises: estimateMerchandise[]
}

export interface estimateMerchandise {
  merchandiseCd: string,
  merchandiseName: string,
  contractCd: string,
  contractName: string,
  merchandiseSalesTypeCd: string,
  merchandiseSalesTypeName: string,
  price: 0,
  payPerUseFlg: true,
  unitQuantity: 0,
  chargeTargetAmount: 0,
  chargeAmount: 0,
  contractStartDatetime: string,
  contractEndDatetime: string,
  contractDetails: estimateMerchandise[]
}

export interface OrganizationType {
  organizationTypeCd: string
  organizationTypeName: string
}
export interface OrganizationCategory {
  organizationCategoryCd: string
  organizationCategoryName: string
}
export interface DisplayPosition {
  displayPositionCd: string
  displayPositionName: string
}

export interface OrganizationTreeWithCustomersModel {
  organizationCd: string
  organizationName: string
  organizationFormalName: string
  organizationCategoryCd: string
  organizationCategoryName: string
  displayPositionCd: string
  displayPositionName: string
  organizationTypeCd: string
  organizationTypeName: string
  children: OrganizationTreeWithCustomersModel[]
  childrenCustomer: ChildrenCustomer[]
}

export interface ChildrenCustomer {
  customerId: string
  customerCd: string
  familyName: string
  firstName: string
  firstNameKana: string
  familyNameKana: string
  mailAddress: string
  createdDatetime: string
  updatedDatetime: string
  lastUpdaterId: string
}

export interface OrganizationRelationCustomerCheck {
  organizationCd: string
  organizationName: string
  organizationTypeCd: string
  organizationTypeName: string
  exists: boolean
}

export interface CustomerCheckRequest {
  organizationCd: string
  customerCd: string
}

export interface CustomerCheckResponse {
  organizationCd: string
  organizationName: string
  customerCd: string
  editable: boolean
}
