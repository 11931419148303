import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { CustomerUi } from 'src/api/customers-api/customers-api.interface'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { CompanyOrganization } from 'src/api/organization-company-api/organization-company-api.interface'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { OrganizationCustomer, OrganizationFullName } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { JoinText } from 'src/app/common/common'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { InputTableComponent } from '../../common-parts/input-table/input-table.component'
export interface LoadeadCustomerData {
  organizationCd: string
  cutomers: OrganizationCustomer[]
}

@Component({
  selector: 'app-input-search-customer',
  templateUrl: './input-search-customer.component.html',
  styleUrls: ['./input-search-customer.component.scss']
})
export class InputSearchCustomerComponent implements OnInit, OnChanges {
  @Input() organizationCd = ''
  @Input() singleSelect = true // 単選択(選択後に顧客名を表示する)
  @Input() suggestOtherCompanyUser = true // 他の会社に該当顧客が存在する場合にサジェストする
  @Input() canSelectOtherCompanyUser = true // サジェストされた他の会社の顧客を選択可能にする
  @Input() add = false // 顧客が居ない場合に追加できる
  @Input() exclusionCustomerCds: string[] = [] // (既に選択済などで)除外する顧客CDs
  @Output() submit = new EventEmitter<OrganizationCustomer>() // 単選択でクリアした場合はnull
  organizationFormalName: string // 会社正式名称
  userList: OrganizationCustomer[] = []
  searchKeyword = ''
  addMode = false
  submitUserCompany = false
  selectedOrganization: CompanyOrganization // 選択中の他社組織
  formItems = [
    {
      key: 'mailAddress',
      label: 'メールアドレス',
      readonly: true
    },
    {
      key: 'customerName',
      label: 'お名前',
      group: [
        {
          key: 'familyName',
          label: '姓',
          placeholder: '例：山田',
          valid: {
            required: true
          }
        },
        {
          key: 'firstName',
          label: '名',
          placeholder: '例：太郎',
          valid: {
            required: true
          }
        }
      ]
    },
    {
      key: 'organizationFullName',
      label: '会社名',
      readonly: true
    }
  ]
  formValue = null

  showCustomerList = false
  customerListLoading = true
  customerList: OrganizationCustomer[] = []
  customerDispList: OrganizationCustomer[] = []

  // 別組織に同一顧客を見つけた場合
  showSuggestionCustomer = false
  suggestionCustomerInfo: CustomerUi
  suggestionCustomerCompany: OrganizationFullName
  isOtherCompanyUser = false // 別会社に該当のメールアドレスの顧客が居た場合
  selectedUser: OrganizationCustomer

  selectCustomerName = '' // 単選択の際に表示する顧客名

  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent


  constructor(
    private organizationCompanyApiService: OrganizationCompanyApiService,
    private organizationV2Service: OrganizationV2Service,
    private customerService: CustomerService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.searchKeyword = ''
    this.getCustomerList()
    this.setCompanyName()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organizationCd) {
      this.ngOnInit()
    }
  }

  // 顧客一覧検索
  getCustomerList(): void {
    if (!this.organizationCd) { return }
    this.customerListLoading = true
    this.organizationV2Service.GetOrganizationCustomerList(this.organizationCd)
      .subscribe((ret: ResultInfo<OrganizationCustomer>) => {
        if (ret?.resultCode !== 0) { return }
        // 肩書きが長いやつを優先表示
        ret.data.forEach(row => {
          if (!this.customerList.find(x => x.customerCd === row.customerCd))  {
            const group = ret.data.filter(x => x.customerCd === row.customerCd)
            const max = Math.max(...group.map((p) => p.organizationFullName.length))
            const maxRow = group.find(x => x.organizationFullName.length === max)
            this.customerList.push(maxRow)
          }
        });
        this.customerListLoading = false
      })
  }

  // 会社名称取得
  async setCompanyName(): Promise<void> {
    if (!this.organizationCd) { return }
    const ret = await this.organizationCompanyApiService.GetProfileBySectionCd(this.organizationCd)
    if (ret?.resultCode !== 0) { return }
    this.organizationFormalName = ret.data.organizationFormalName
  }

  // 顧客検索結果表示
  showList(): void {
    this.searchKeyword = this.searchKeyword.replace(/[\p{C}\p{Z}]/gu, '') // スペース入れない
    // 入力無し、無効な入力の場合は対象無しにする
    if (this.searchKeyword.replace('@', '').replace('.', '').length <= 1) {
      this.customerDispList = []
      return
    }
    let customers = this.customerList
    // 選択済の対象を除外
    this.exclusionCustomerCds.forEach(customerCd => {
      customers = customers.filter(x => x.customerCd !== customerCd)
    })
    // キーワードに一致するもの以外を除外
    if (this.searchKeyword) {
      customers = customers.filter(x =>
        x.mailAddress?.split('.')?.join('\.')?.match(this.searchKeyword.split('.').join('\.'))
        || x.familyName?.match(this.searchKeyword)
        || x.firstName?.match(this.searchKeyword)
      )
    }
    this.customerDispList = customers.slice(0, 4)
    this.showCustomerList = true
  }

  // 一覧から選択した顧客をsubmit
  selectUser(customer: OrganizationCustomer): void {
    this.submit.emit(customer)
    if (this.singleSelect) {
      this.selectCustomerName = JoinText([customer.familyName, customer.firstName], ' ')
    }
  }

  // 入力されたアドレスを判定
  async submitSearchKeyword(): Promise<void> {
    if (this.searchKeyword.replace('@', '').replace('.', '').length <= 1) {
      this.customerDispList = []
      return
    }
    this.showSuggestionCustomer = false
    // ヒットなしの場合は入力欄表示
    if (this.customerDispList.length) { return }

    const ret = await this.customerService.SearchByMailAddress(this.searchKeyword)
    if (ret?.resultCode !== 0) { return }
    if (!ret.data.length && this.add) {
      this.formValue = {
        mailAddress: this.searchKeyword,
        organizationFullName: this.organizationFormalName
      }
      this.addMode = true
      this.isOtherCompanyUser = false
      this.inputTable.setForm(this.formValue, this.formItems)
    }
    else if (this.suggestOtherCompanyUser) {
      // 別組織に同一顧客がいるのでサジェスチョン表示
      this.suggestionCustomerInfo = ret.data[0]
      this.setSuggestionCustomerCompany(this.suggestionCustomerInfo.customerCd)
      this.showSuggestionCustomer = true
    }
  }

  // 手入力から戻る
  returnForm(): void {
    this.addMode = false
  }

  // 手入力した顧客をsubmit
  submitInput(): void {
    const value = this.inputTable.submit()
    if (!value) {
      return
    }
    if (this.isOtherCompanyUser) {
      // customerCdあり、メールアドレス変更
      const customer: OrganizationCustomer = {
        organizationCd: this.selectedUser.organizationCd,
        organizationFullName: this.selectedUser.organizationFullName,
        customerCd: this.selectedUser.customerCd,
        mailAddress: value.mailAddress,
        familyName: this.selectedUser.familyName,
        firstName: this.selectedUser.firstName,
      }
      this.submit.emit(customer)
    }
    else {
      // customerCd無し、ド新規
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      if (!reg.test(this.searchKeyword)) {
        this.messageService.templeteMessage(messageList.M00004)
        return
      }
      const customer: OrganizationCustomer = {
        organizationCd: this.organizationCd,
        organizationFullName: this.organizationFormalName,
        mailAddress: this.searchKeyword,
        familyName: value.familyName,
        firstName: value.firstName,
      }
      this.submit.emit(customer)
    }
    // 入力欄を戻す
    this.addMode = false
    this.searchKeyword = ''
  }

  // サジェスチョンされた顧客をリストに追加
  submitSuggestion(): void {
    const customer: OrganizationCustomer = {
      organizationCd: this.suggestionCustomerCompany.organizationCd,
      organizationFullName: this.suggestionCustomerCompany?.organizationFullName,
      customerCd: this.suggestionCustomerInfo.customerCd,
      mailAddress: this.searchKeyword,
      familyName: this.suggestionCustomerInfo.familyName,
      firstName: this.suggestionCustomerInfo.firstName,
    }
    this.submit.emit(customer)
    this.showSuggestionCustomer = false
    this.searchKeyword = ''
    if (this.singleSelect) {
      this.selectCustomerName = JoinText([customer.familyName, customer.firstName], ' ')
    }
  }

  // サジェスチョン顧客の会社取得
  setSuggestionCustomerCompany(customerCd: string): void {
    this.organizationV2Service
      .GetOrganizationFullNameList(customerCd, false).subscribe((ret: ResultInfo<OrganizationFullName>) => {
        if (ret?.resultCode !== 0 || !ret?.data[0]) { return }
        this.suggestionCustomerCompany = ret.data[0]
      })
  }

  clearSelect(): void {
    this.selectCustomerName = ''
    this.submit.emit(null)
  }
}
