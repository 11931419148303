import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core'
import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { InputTableComponent } from '../../common-parts/input-table/input-table.component'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { messageList } from 'src/app/components/message/message-data'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { customerNameNotKanaFormItem, mailAddressFormItem } from '../customer.const'

@Component({
  selector: 'app-input-new-customer',
  templateUrl: './input-new-customer.component.html'
})
export class InputNewCustomerComponent implements OnInit, AfterViewInit {
  @Input() organizationCd = ''
  formItems = [
    {
      key: 'organizationFullName',
      label: '会社名',
      readonly: true
    },
    customerNameNotKanaFormItem,
    mailAddressFormItem
  ]
  formValue = null

  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent

  constructor(
    private customerService: CustomerService,
    private organizationCompanyApiService: OrganizationCompanyApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit(): Promise<void> {
    const organizationFullName = await this.getCompanyName()
    this.formValue = {
      organizationFullName
    }
    this.inputTable.setForm(this.formValue, this.formItems)
  }

  // 会社名称取得
  async getCompanyName(): Promise<string> {
    if (!this.organizationCd) { return "" }
    const ret = await this.organizationCompanyApiService.GetProfileBySectionCd(this.organizationCd)
    if (ret?.resultCode !== 0) { return "" }
    return ret.data.organizationFormalName
  }

  async submit(): Promise<OrganizationCustomer> {
    const value = this.inputTable.submit()
    if (!value) {
      return
    }
    const ret = await this.customerService.SearchByMailAddress(value.mailAddress)
    if (ret?.resultCode !== 0) { return }
    if (ret.data.length) {
      this.messageService.templeteMessage(messageList.M00049)
      return
    }
    value.organizationCd = this.organizationCd
    return value
  }
}
