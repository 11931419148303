export const progressItemKey = {
  mail: 'mail',
  login: 'login',
  user: 'user',
  company: 'company',
  confirm: 'confirm'
}

export const progressItemMaster = [
  { key: progressItemKey.mail, icon: 'mail', label: 'メールアドレス' },
  { key: progressItemKey.login, icon: 'key', label: 'ログイン情報' },
  { key: progressItemKey.user, icon: 'user', label: '個人情報' },
  { key: progressItemKey.company, icon: 'company', label: '企業情報' },
  { key: progressItemKey.confirm, icon: 'list', label: '入力内容確認' }
]
