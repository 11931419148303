import { Component, OnInit, ViewChild } from '@angular/core'
import { Subscription, Observable } from 'rxjs'
import { AuthService } from './components/isbeauth/auth.service'
import {
  Data,
  ActivatedRoute,
  Router,
  NavigationEnd,
} from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AccountInfo } from './components/isbeauth/account'
import { HeaderComponent } from './components/header/header.component'
import { Title } from '@angular/platform-browser'
import { NavService } from './components/nav/nav.service'
declare const APP
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Title]
})
export class AppComponent implements OnInit {
  subscription: Subscription
  users: AccountInfo
  activatedRouteData: Observable<Data>
  login: boolean
  loding: boolean
  selectedOrgSubs: any
  accountAuthSubs: any
  personalAuthSubs: any
  dispContent = false
  routeData: any
  @ViewChild(HeaderComponent, { static: false })
  private header: HeaderComponent
  firstLoad = true

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private title: Title,
    private navService: NavService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activatedRouteData = this.getChildData(this.activatedRoute.firstChild)
        this.activatedRouteData.subscribe((data) => {
          // 権限判定
          this.routeData = data
          if (this.firstLoad) {
            this.firstLoad = false
          }
          else {
            this.authService.authGuard(this.routeData)
          }

          // titleの設定
          let pageTitle = data.isNoLogin ? 'INTERSERVE' : 'My-idea'
          if (data.title) {
            pageTitle = `${pageTitle} - ${data.title}`
          }
          this.title.setTitle(pageTitle)
        })
      }
    })
  }
  ngOnInit(): void {
    this.login = false
    // アカウント情報の変更検知
    this.personalAuthSubs = this.authService.authorityTableSource$.subscribe(
      (data) => {
        if (this.authService.AuthorityTable && this.authService.isLoadedAuth) {
          this.RefreshMainContent()
        }
      }
    )
    this.accountAuthSubs = this.authService.accountInfoSource$.subscribe(
      (data) => {
        if (this.authService.AuthorityTable && this.authService.isLoadedAuth) {
          this.RefreshMainContent()
        }
      }
    )
  }
  async endLogin(showSelectOrganizationDialog: boolean): Promise<void> {
    await this.authService.setAuthViews()
    this.login = true
    if (showSelectOrganizationDialog) {
      this.header.dialogOpen()
    }
    this.checkReloadPage()
  }
  RefreshMainContent(): void {
    // 権限判定
    this.authService.authGuard(this.routeData)
    this.dispContent = false
    const that = this
    const reView = function () {
      that.dispContent = true
      APP.controller.reset()
      that.navService.updatenotificationList()
    }
    setTimeout(reView, 1000)
  }
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 0,
      verticalPosition: 'top',
    })
  }
  getChildData(child): any {
    if (child.firstChild) {
      return this.getChildData(child.firstChild)
    }
    else {
      return child.data
    }
  }

  private checkReloadPage() {
    //リロード時に画面権限チェック
    const reloadPage = this.authService.ReloadInfo
    if (reloadPage !== null) {
      this.authService.setRelaodInfo(null)
      if (!this.authService.checkAuthorityForView(reloadPage)) {
      }
      else {
        this.router.navigate([reloadPage])
        this.RefreshMainContent()
      }
    }
  }

}
