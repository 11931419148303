import { Component, OnInit } from '@angular/core'
import { EstimateApiService } from 'src/api/estimates-api/estimates-api.service'
import { EstimateDocumentStatus } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { GridActionButton } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
@Component({
  selector: 'app-ura-estimate-list',
  templateUrl: './ura-estimate-list.component.html',
  styleUrls: ['./ura-estimate-list.component.scss']
})
export class UraEstimateListComponent implements OnInit {
  title = '見積一覧【追加】'
  data: EstimateDocumentStatus[] = []
  columns: Header[] = [
    {
      key: 'estimateDocumentCd',
      label: 'CD',
      hidden: true
    },
    {
      key: 'companyFomalName',
      label: '会社',
      width: '16%'
    },
    {
      key: 'sectionOrganizationName',
      label: '部署',
      width: '14%'
    },
    {
      key: 'contractCustomerName',
      label: '送付先',
      width: '10%'
    },
    {
      key: 'estimateDocumentNo',
      label: '見積No.',
      width: '10%'
    },
    {
      key: 'totalRunningPrice',
      label: '月額費用',
      dataType: 'number',
      width: '10%'
    },
    {
      key: 'lastSendDatetimeUi',
      label: '見積送信日時',
      width: '10%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'applicationDatetimeUi',
      label: '申込み日',
      width: '10%'
    }
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '見積確認',
      actionCd: 'actionCd1',
      nextPage: ['/ura-add-contract-detail/{estimateDocumentCd}']
    },
  ]
  loading = true
  selectedTargetOrg: BackendOrganizationCorporateV2

  constructor(
    private estimateApiService: EstimateApiService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getList()
  }

  selectTargetOrganization(event: BackendOrganizationCorporateV2): void {
    this.selectedTargetOrg = event
    this.ngOnInit()
  }

  async getList(): Promise<void> {
    if (!this.selectedTargetOrg) { return }
    this.data = []
    this.loading = true
    if (this.authService.ChangeCustomer) {
      this.loading = true
      const ret = await this.estimateApiService.getAddEstimateDocumentStatusList(this.authService.ChangeCustomer.organizationCd)
      this.loading = false
      if (ret?.resultCode !== 0) { return }
      this.data = ret.data
      this.data.forEach(item => {
        item.actionCds = ['actionCd1']
      })
    }
  }
}
