export interface ActionButtonDisp {
  label: string
  actionCd?: string
  disabled?: boolean
  // textAlign?: FooterButtonTextAlign
  textAlign?: string
  classes?: string[]
  actionFunction?: () => void
}

export enum FooterButtonTextAlign {
  RIGTH = 'rigth',
  LEFT = 'left',
}

// ↓に変更したい
// type FooterButtonTextAlign = 'rigth' | 'left'
