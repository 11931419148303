import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { BeAccount } from 'src/api/accountsV3-api/accountsV3-api.interface'
import { AccountsV3Service } from 'src/api/accountsV3-api/accountsV3-api.service'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import { FromInputService } from 'src/app/module/common-parts/input-table/form-input.service'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
@Component({
  selector: 'app-registration-account',
  templateUrl: './registration-account.component.html',
  styleUrls: ['./registration-account.component.scss']
})
export class RegistrationAccountComponent implements OnInit, AfterViewInit {
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  customerCd = ''

  formItems: FormInputInfo[] = []
  formValue = null
  sending = false
  nextPagePath: string

  constructor(
    private messageService: MessageService,
    private router: Router,
    private accountsV3Service: AccountsV3Service,
    private fromInputService: FromInputService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const nickNameForm = this.fromInputService.nickNameForm()
    nickNameForm.label = '任意のログインID'
    const passwordForms = this.fromInputService.changePasswordForm()
    passwordForms[0].label = '任意のパスワード'
    passwordForms[1].label = '任意のパスワード（確認用) '
    this.formItems = [nickNameForm, ...passwordForms]
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.customerCd = params.get('customerCd')
      this.nextPagePath = params.get('nextPagePath')
    })
  }

  ngAfterViewInit(): void {
    this.setFormDefaultValue()
  }

  setFormDefaultValue(): void {
    this.inputTable.setForm(this.formValue)
  }
  submit(): void {
    const submitForm = this.inputTable.submit()
    if (!submitForm) {
      return
    }
    // 自前でチェック
    if (submitForm.password !== submitForm.passwordConfirmation) {
      this.messageService.error('パスワードとパスワード（確認用）が一致しません')
      return
    }
    this.formValue = submitForm
    // ログインIDチェック
    if (this.sending) { return }
    this.sending = true
    this.accountsV3Service.NicknameSearch(this.formValue.nickName).subscribe((ret: any) => {
      this.sending = false
      if (ret.count > 0) {
        this.messageService.error('このログインIDはすでに別のユーザーが使用しているため、設定できません')
        return
      }
      else {
        this.updateAccount()
      }
    })
  }

  async updateAccount(): Promise<void> {
    const payload: BeAccount = {
      nickName: this.formValue.nickName,
      password: this.formValue.password
    }
    if (this.sending) { return }
    this.sending = true
    const ret$ = this.accountsV3Service.UpdateForFomal(this.customerCd, payload)
    let ret = await lastValueFrom(ret$)
    this.sending = false

    if (ret?.resultCode !== 0) { return }
    if (!ret?.data[0]) {
      this.messageService.error('既にログイン情報が登録されています')
      return
    }
    this.authService.setAutoLoginAccountId(ret.data[0].accountId)
    this.messageService.success('ログイン情報を登録しました')
    if (this.nextPagePath) {
      this.router.navigate([`/${this.nextPagePath}`])
    }
    else {
      this.router.navigate(['/'])
    }
  }
}
