import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BackendTagMaster, TextTag } from 'src/api/common/api-common.interface'

@Component({
  selector: 'app-tag-select-area',
  templateUrl: './tag-select-area.component.html',
  styleUrls: ['./tag-select-area.component.scss']
})
export class TagSelectComponent implements OnInit {
  @Input() tags: BackendTagMaster[] = []
  @Input() selectedTags: TextTag[] = []
  @Input() tagEditMode = false
  @Output() update = new EventEmitter<TextTag[]>()
  constructor(
  ) { }

  ngOnInit(): void {
  }

  tagClick(tag: TextTag): void {
    const selected = this.selectedTags.find(x => x.tagCd === tag.tagCd) ? true : false
    if (selected) {
      this.selectedTags = this.selectedTags.filter(x => x.tagCd !== tag.tagCd)
    }
    else {
      this.selectedTags.push(tag)
    }
    this.update.emit(this.selectedTags)
  }

  selected(tagCd: string): boolean {
    return this.selectedTags.find(x => x.tagCd === tagCd) ? true : false
  }
}
