import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import { DeepCopy } from 'src/app/common/common'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { Router } from '@angular/router'
import { FromInputService } from 'src/app/module/common-parts/input-table/form-input.service'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { AccountsV3Service } from 'src/api/accountsV3-api/accountsV3-api.service'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { lastValueFrom } from 'rxjs'
export interface PasswordChange {
  oldPassword: string,
  password: string,
  passwordConfirmation: string
}

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit, AfterViewInit {
  formInput: PasswordChange
  loading = false
  title = 'パスワード変更'
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  formItems: FormInputInfo[] = []
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '変更する',
      actionFunction: () => {
        this.submit()
      }
    }
  ]

  constructor(
    private authService: AuthService,
    private accountsV3Service: AccountsV3Service,
    private messageService: MessageService,
    private router: Router,
    private fromInputService: FromInputService
  ) { }

  ngOnInit(): void {
    const passwordForms = this.fromInputService.changePasswordForm()
    const oldPasswordForm = this.fromInputService.oldPasswordForm()
    this.formInput = {
      oldPassword: '',
      password: '',
      passwordConfirmation: ''
    }
    this.formItems = [oldPasswordForm, ...passwordForms]
  }

  ngAfterViewInit(): void {
    this.inputTable.setForm(this.formInput)
  }

  async submit(): Promise<void> {
    if (this.loading) { return }
    const submitForm = this.inputTable.submit()
    // ログインIDチェック
    const payload = DeepCopy(submitForm)

    if (payload.password !== payload.passwordConfirmation) {
      this.messageService.error('新しいパスワードと新しいパスワード（確認用）が一致しません')
      return
    }

    this.loading = true
    payload.accountId = this.authService.AccountInfo.accountId
    // const ret = await this.accountsV3Service.PasswordUpdate(payload).toPromise()
    const ret$ = this.accountsV3Service.PasswordUpdate(payload)
    let ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode !== 0) {
      this.messageService.error(ret.resultMessage)
      return
    }
    this.messageService.success('パスワードを変更しました')
    this.router.navigate(['/'])
  }
}
