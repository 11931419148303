import { Injectable } from '@angular/core'
import { DeepCopy } from 'src/app/common/common'
import { mailAddressForm, nickNameForm, nickNameForm2, oldPasswordForm, passwordForms } from './form-input.const'
import { FormInputInfo } from './input-table.model'

@Injectable({
  providedIn: 'root',
})
export class FromInputService {
  constructor() { }

  changePasswordForm(): FormInputInfo[] {
    return passwordForms
  }

  mailAddressForm(): FormInputInfo {
    return mailAddressForm
  }

  oldPasswordForm(): FormInputInfo {
    return oldPasswordForm
  }

  nickNameForm(): FormInputInfo {
    return nickNameForm
  }

  nickNameForm2(): FormInputInfo {
    return nickNameForm2
  }

  mailAddressFormReadOnly(): FormInputInfo {
    const value = DeepCopy(mailAddressForm)
    value.valid = null
    value.readonly = true
    return value
  }
}
