import { Component, OnInit, ViewChild } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { MessageService } from 'src/app/components/message/message.service'
import { ShimuraGarageMigrationsService } from 'src/api/micomel-migrations-api/micomel-migrations-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { messageList } from 'src/app/components/message/message-data'
import { NavService } from 'src/app/components/nav/nav.service'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { ShimuraGarageAppSettingComponent } from '../extra-views/shimura-garage-migration/shimura-garage-app-setting/shimura-garage-app-setting.component'
@Component({
  selector: 'app-micomel-migration-policy',
  templateUrl: './micomel-migration-policy.component.html',
  styleUrls: ['./micomel-migration-policy.component.scss']
})
export class ShimuraGarageMigrationPolicyComponent implements OnInit {
  title = '利用規約の確認'
  sending = false
  hasBefore = false
  approved = true
  fileUrl: SafeResourceUrl = null
  checkedPolicy = false
  backUrl = 'shimura-garage-migration-estimate'
  actionButtons: ActionButtonDisp[] = [
    {
      actionCd: 'next',
      label: '同意して次へ',
      actionFunction: () => {
        // 稼働設定ダイアログを開く
        this.settingDialog.openDialog()
      },
      disabled: true
    }
  ]
  dialogOpen = false
  companyOrganizationId: any
  parentContractId: any
  @ViewChild('settingDialog', { static: false })
  public settingDialog: ShimuraGarageAppSettingComponent
  constructor(
    private router: Router,
    private domSanitizer: DomSanitizer,
    private messageService: MessageService,
    private confirmService: ConfirmService,
    private micomelMigrationsService: ShimuraGarageMigrationsService,
    private authService: AuthService,
    private navService: NavService,
    private serviceMapViewService: ServiceMapViewService,
    private activatedRoute: ActivatedRoute
  ) {}

  get backTitle(): string {
    return `【Products Garage 】思夢樂事業アプリ 登録のご案内`
  }

  get pdfHeight(): number {
    return window.innerHeight - 250
  }

  async ngOnInit(): Promise<void> {
    // setTimeout(() => {
    //   this.settingDialog.openDialog()
    // }, 0)
    this.companyOrganizationId = this.activatedRoute.snapshot.params.companyOrganizationId
    this.parentContractId = this.activatedRoute.snapshot.params.parentContractId
    this.backUrl = `shimura-garage-migration-estimate/${this.companyOrganizationId}/${this.parentContractId}`

    this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.riyoukiyakuUrl)

    const ret = await this.micomelMigrationsService.checkContractCustomer(this.authService.AccountInfo.customerCd)
    if (ret?.resultCode !== 0) {
      return
    }

    const findData = ret.data.find(
      (x) =>
        x.contractCompanyOrganizationId === this.companyOrganizationId && x.parentContractId === this.parentContractId
    )
    // const data = ret.data[0]
    this.approved = findData.approved
    // if (!data.contractCompanyOrganizationCd) {
    //   this.messageService.templeteMessage(messageList.M00053)
    //   this.router.navigate(['/'])
    // }
    const ret2 = await this.micomelMigrationsService.getEstimateData(this.companyOrganizationId, this.parentContractId)
    if (ret2?.resultCode !== 0) {
      return
    }
    // this.hasBefore = ret2.data[0].beforeEstimates.length > 0
  }
  changeCheckPolicy() {
    this.actionButtons[0].disabled = this.checkedPolicy
  }

  async submit(payload: any): Promise<void> {
    if (this.sending) {
      return
    }
    this.sending = true
    const ret = await this.micomelMigrationsService.approval(payload)
    this.sending = false
    if (ret?.resultCode !== 0) {
      return
    }
    this.navService.updatenotificationList()
    this.dialogOpen = true
  }
  goTop() {
    // 権限、マップ再取得
    this.authService.setAuthorityTable()
    this.serviceMapViewService.clearCustomerMap()
    this.router.navigate(['/'])
  }
  commit(ret) {
    // ここでEDIパスワードがあっているかのチェックを行うかも
    this.settingDialog.closeDialog()
    const payload = {
      data: [
        {
          contractCompanyOrganizationId: this.companyOrganizationId,
          customerCd: this.authService.AccountInfo.customerCd,
          parentContractId: this.parentContractId
        }
      ],
      item: {
        supplierCd: ret.supplierCd,
        webEdiId: ret.webEdiId,
        webEdiPassword: ret.webEdiPassword
      }
    }
    if (this.approved) {
      this.messageService.warning('既に登録済です')
      return
    }
    this.confirmService.confirm(`確定してよろしいですか？`, '', () => {
      this.submit(payload)
    })
  }
}
