<!-- リスト表示 -->
<div *ngFor="let user of addUserList" class="add-user-list">
  <div>
    <div class="list-user-name">
      <div>
        {{ user.familyName }} {{ user.firstName }}
      </div>
      <div>
        <span class="c-btns__item" (click)="removeFromList(user)">
          <a style="cursor: pointer"><span>　×</span> </a>
        </span>
      </div>
    </div>
    <div>{{ user.organizationFullName }}</div>
  </div>
  <!-- 一覧から選択してから新規追加の時 -->
</div>

<div [class.no-show]="inputMode">
  <div class="delete-customer-space">
    <p class="delete-customer">
      ※赤文字で表示されるユーザーとは？
      <a class="delete-customer-link" (click)="openDetailModal()"
        >>> 詳細</a
      >
    </p>
  </div>
  <div *ngIf="dispMyCampany">
    検索対象
    <ul class="btns btns--radio gap-xs">
      <li>
        <input
          type="radio"
          name="org-area"
          id="1"
          [(ngModel)]="searchMyOrganization"
          [value]="true"
          [checked]="searchMyOrganization"
          (click)="selectedSearchOrganizationCd = organizationCd"
        /><label for="1" class="btns__btn">自社内</label>
      </li>
      <li>
        <input
          type="radio"
          name="org-area"
          id="2"
          [(ngModel)]="searchMyOrganization"
          [value]="false"
          [checked]="!searchMyOrganization"
        /><label for="2" class="btns__btn">他社</label>
      </li>
    </ul>
  </div>

  <div *ngIf="!searchMyOrganization" style="margin-top: 1rem">
    <div *ngIf="selectedOrganization">
      会社名：{{ selectedOrganization.organizationFormalName }}
      <span
        class="c-btns__item"
        (click)="selectedOrganization = null; showSearchResult = false"
      >
        <a style="cursor: pointer"><span>　×</span></a>
      </span>
    </div>
    <div
      [class.no-show]="selectedOrganization"
      (clickOutside)="showSearchCompanyResult = false"
    >
      <input
        class="c-form__input"
        style="max-width: 463px"
        type="text"
        placeholder="会社名を入力してください"
        (keyup)="searchCompany()"
        (change)="searchCompany()"
        (click)="searchCompany()"
        autocomplete="off"
        [(ngModel)]="searchCompanyName"
      />
      <div *ngIf="showSearchCompanyResult" class="search-company-result-area">
        <div class="user-list-body">
          <div *ngIf="!organizationList.length">
            <div>該当なし</div>
            <span class="search-sub-message"
              >対象の会社はまだ登録されていません。インターサーブへお問い合わせ下さい。
            </span>
          </div>
          <div
            *ngFor="let organization of organizationList"
            class="user-list"
            style="cursor: pointer"
            (click)="selectOrganization(organization)"
          >
            <a>{{ organization.organizationFormalName }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- メールアドレス入力 -->
  <span
    *ngIf="searchMyOrganization || selectedOrganization"
    class="search-field"
  >
    <input
      (clickOutside)="showSearchResult = false"
      class="c-form__input"
      type="text"
      placeholder="お名前かメールアドレスを入力してください"
      autocomplete="off"
      (keyup)="searchUserList()"
      (change)="searchUserList()"
      (click)="searchUserList()"
      (keyup.enter)="submitSearchKeyword()"
      [(ngModel)]="searchKeyword"
      style="margin-top: 1rem"
    />
    <a
      class="c-btn search-btn"
      style="cursor: pointer"
      (click)="submitSearchKeyword()"
    >
      <span>決定</span></a
    >
  </span>
  <div
    *ngIf="showSearchResult && (customerListLoading || customerList.length)"
    class="search-user-result-area"
  >
    <div class="user-list-body">
      <div
        *ngIf="customerListLoading"
        class="user-list"
        style="text-align: center"
      >
        loading...
      </div>
      <div *ngFor="let user of customerList" class="user-list">
        <a
          (click)="selectUser(user)"
          style="cursor: pointer"
          [class.delete-customer]="user.isTempMigration"
        >
          <div class="list-user-name">
            <span>{{ user.familyName }} {{ user.firstName }}</span>
            <!-- <div>{{ user.mailAddress }}</div> -->
          </div>
          <div>{{ user.organizationFullName }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- 追加入力欄 -->
<div [class.no-show]="!inputMode">
  <app-input-table [formItems]="formItems"> </app-input-table>
  <div class="c-btns">
    <div class="c-btns__item">
      <a class="c-btn" (click)="returnForm()" style="cursor: pointer">
        <span>キャンセル</span></a
      >
    </div>
    <div class="c-btns__item">
      <a *ngIf="addMailAddressFlag"
        class="c-btn c-btn--blue"
        (click)="addMigrationUser()"
        style="cursor: pointer"
        ><span>追加する</span></a
      >
      <a *ngIf="!addMailAddressFlag"
      class="c-btn c-btn--blue"
      (click)="addNewUser()"
      style="cursor: pointer"
      ><span>追加する</span></a
    >
    </div>
  </div>
</div>
<!-- 追加入力欄 -->
<div *ngIf="suggestionCustomer">
  <p class="c-texts--attention h-margin--b16 h-margin--t16">
    別の会社にアカウントが存在しています
  </p>
  <div class="add-user-list" style="border-bottom: none">
    <div class="list-user-name">
      <div>
        {{ suggestionCustomerInfo.familyName }}
        {{ suggestionCustomerInfo.firstName }}
      </div>
      <div>{{ searchKeyword }}</div>
    </div>
    <div>会社名： {{ suggestionCustomerCompany?.organizationFullName }}</div>
  </div>
  <div class="c-btns">
    <div class="c-btns__item">
      <a
        class="c-btn"
        (click)="suggestionCustomer = false"
        style="cursor: pointer"
      >
        <span>キャンセル</span></a
      >
    </div>
    <div class="c-btns__item">
      <a
        class="c-btn c-btn--blue"
        (click)="addSuggestionUser()"
        style="cursor: pointer"
        ><span>追加する</span></a
      >
    </div>
  </div>
</div>

<igx-dialog #cancelDialog [closeOnOutsideSelect]="true" message="">
  <div style="text-align: left; width: 700px; max-height: 700px; padding: 2rem">
    <div class="cancel-dialog">
      <h2 class="c-texts--head1" style="margin-bottom: 15px">
        赤文字で表示されるユーザーとは？
      </h2>
      <p>
        過去に登録されたデータを自動的に表示しているため、古い情報が混在している可能性があります。
      </p>
      <p>
        画面の手順に従って招待を行い、ユーザー自身（ご本人）が承認すると赤文字は解除されます。
      </p>
      <p></p>
    </div>

    <div class="c-btns">
      <div class="c-btns__item">
        <a
          class="js-rename-close c-btn"
          (click)="cancelDialog.close()"
          style="cursor: pointer; margin-right: 1rem"
        >
          <span>閉じる</span></a
        >
      </div>
    </div>
  </div>
</igx-dialog>
