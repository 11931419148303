import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { Tag } from 'src/api/common/api-common.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { NewQuotationService } from '../service/new-quotation.service'
import { QuotationSelectedGroup } from '../service/ura-create-quotation.interface'

@Component({
  selector: 'app-ura-new-quotation-service',
  templateUrl: './ura-new-quotation-service.component.html',
  styleUrls: ['./ura-new-quotation-service.component.scss']
})
export class UraNewQuotationServiceComponent implements OnInit {
  title = '新規見積もり作成'
  index: string
  targetOrganizationCustomer: OrganizationCustomer = this.newQuotationService.Session?.targetOrganizationCustomer
  session: QuotationSelectedGroup
  tags: Tag[] = []
  selectedTagCd: string
  loading = true
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.backPage()
      },
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]
  constructor(
    private activatedRoute: ActivatedRoute,
    private merchandisesService: MerchandisesApiService,
    private router: Router,
    private newQuotationService: NewQuotationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.index = params.get('index')
      const check = this.newQuotationService.pageInit(this.index)
      if (!check) { return }

      this.session = this.newQuotationService.getSelectedGroupSession(this.index)
      this.selectedTagCd = this.session.service?.tagCd
      this.getServiceTags()
    })
  }

  // サービスタグ情報取得
  async getServiceTags(): Promise<void> {
    const retailTagCd = this.session.retail.tagCd
    // const ret = await this.merchandisesService.SearchRetailService(retailTagCd).toPromise()
    const ret$ = this.merchandisesService.SearchRetailService(retailTagCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.tags = ret.data
    if (!this.selectedTagCd) {
      this.selectedTagCd = this.tags[0]?.tagCd
    }
    this.loading = false
  }

  pageNext(): void {
    if (!this.selectedTagCd) { return }
    const sessionGroups = this.newQuotationService.Session.selectedGroups
    // 今のグループ以降は除外
    sessionGroups.splice(Number(this.index))
    if (sessionGroups?.filter(
      x => x.service?.tagCd === this.selectedTagCd
        && x.retail?.tagCd === this.session.retail?.tagCd
        && x.businessCategory?.tagCd === this.session.businessCategory?.tagCd)?.length) {
      this.messageService.templeteMessage(messageList.M00041)
      return
    }
    this.session.service = this.tags.find(x => x.tagCd === this.selectedTagCd)
    this.newQuotationService.setSelectedGroupSession(this.session, this.index)
    this.router.navigate([`/ura-new-quotation-merchandise/${this.index}`])
  }

  async backPage(): Promise<void> {
    const tags = this.session.businessCategoryTagList
    if (tags.length) {
      this.router.navigate([`/ura-new-quotation-business-category/${this.index}`])
    } else {
      this.router.navigate([`/ura-new-quotation-retail/${this.index}`])
    }
  }
}
