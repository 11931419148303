import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { DeepCopy } from 'src/app/common/common'
import { AddProductQuotationSession, QuotationSelectedGroup } from './ura-create-quotation.interface'

/**
 * プロダクト追加見積もり作成用サービス
 */
@Injectable({
  providedIn: 'root',
})
export class AddProductQuotationService {

  constructor(
    private router: Router
  ) { }

  private defaultValue: AddProductQuotationSession = {
    organizationCompanyCd: '',
    contractOrganizationCd: '',
    selectedGroups: []
  }

  private session: AddProductQuotationSession = DeepCopy(this.defaultValue)

  /**
   * GET：プロダクト追加見積もり作成用セッション
   */
  get Session(): AddProductQuotationSession {
    return DeepCopy(this.session)
  }

  /**
   * SET：プロダクト追加見積もり作成用セッション
   */
  setSession(value: AddProductQuotationSession): void {
    this.session = DeepCopy(value)
  }

  /**
   * GET：プロダクト追加見積もり作成用セッション(indexごとの選択情報)
   */
  getSelectedGroupSession(indexString?: string): QuotationSelectedGroup {
    const session = this.Session.selectedGroups[Number(indexString)]
    return DeepCopy(session)
  }

  /**
   * SET：新規見積もり作成用セッション(indexごとの選択情報)
   */
  setSelectedGroupSession(value: QuotationSelectedGroup, indexString: string): void {
    const session = this.Session
    session.selectedGroups[indexString] = DeepCopy(value)
    this.session = session
  }

  /**
   * 保存されている内容が一致しているかチェックし、
   * 異なる場合はリセットして初期画面へ遷移
   * @param organizationCompanyCd 契約会社組織
   * @param contractOrganizationCd 契約組織
   */
  checkAndResetSession(organizationCompanyCd: string, contractOrganizationCd: string): boolean {
    const session = this.session
    // 保存データが無い場合は業態選択へ
    if (!session.contractOrganizationCd) {
      this.router.navigate([`/ura-add-product-business-category/${organizationCompanyCd}/${contractOrganizationCd}/0`])
      return false
    }
    // 保存されている契約組織と異なる場合はクリア
    else if (session.contractOrganizationCd !== contractOrganizationCd) {
      this.clearSession()
      this.router.navigate([`/ura-conpany-contracted-organization-list`])
      return false
    }
    return true
  }

  /**
   * プロダクト追加見積もり作成用
   * 初期化処理
   * @param organizationCompanyCd 契約会社組織
   * @param contractOrganizationCd 契約組織
   * @param indexString pathで指定されるindex(指定が無いページでは空で渡す)
   * @param groupFirstPage ループ画面の最初(小売選択)のページの場合はtrue
   * @returns void
   */
  pageInit(
    organizationCompanyCd: string,
    contractOrganizationCd: string,
    indexString?: string,
    groupFirstPage?: boolean): boolean {
    const session = this.session
    // 保存データが無い場合
    if (!session.contractOrganizationCd) {
      this.router.navigate([`/ura-add-contract-service-map/${organizationCompanyCd}/${contractOrganizationCd}`])
      return false
    }
    // 保存されている契約組織と異なる場合はクリア
    else if (session.contractOrganizationCd !== contractOrganizationCd) {
      this.clearSession()
      this.router.navigate([`/ura-conpany-contracted-organization-list`])
      return false
    }
    // インデックスの指定が無い場合
    if (!indexString) { return true }
    // 対象のindex情報が無かった場合
    if (!session.selectedGroups[Number(indexString)]) {
      // 最初のページかつ、前indexが存在する場合は要素作成
      if (groupFirstPage && Number(indexString) === 0 || session.selectedGroups[Number(indexString) - 1]) {
        session.selectedGroups.push(
          {
            merchandiseTree: []
          }
        )
        this.setSession(session)
        return true
      }
      else {
        this.clearSession()
        this.router.navigate([`/ura-add-product-business-category/${organizationCompanyCd}/${contractOrganizationCd}/0`])
        return false
      }
    }
    return true
  }

  /**
   * プロダクト追加見積もり作成用セッション クリア
   */
   clearSession(): void {
    this.session = DeepCopy(this.defaultValue)
  }

  /**
   * 新規見積もり作成用セッション クリアインデックス指定
   */
   clearIndexSession(indexString: string): void {
    const session = this.Session
    session.selectedGroups[Number(indexString)] = { merchandiseTree: [] }
    this.session = session
  }

  /**
   * プロダクト追加見積もり作成用セッション 中途半端な情報をクリア
   */
   clearUnfinishedGroupSession(): void {
    const session = this.Session
    session.selectedGroups = session.selectedGroups.filter(x =>
      x.retail?.tagCd
      && x.service?.tagCd
      && x.merchandiseTree.length)
    this.setSession(session)
  }
}
