import { Component, OnInit } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { BackendOrganizationV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { AutoMap, JoinText } from 'src/app/common/common'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { OrganizationAccountUi } from './ura-support-search-account.interface'

@Component({
  selector: 'app-ura-support-search-account',
  templateUrl: './ura-support-search-account.component.html',
  styleUrls: ['./ura-support-search-account.component.scss']
})
export class UraSupportSearchAccountComponent implements OnInit {
  title = 'サポート用 アカウント検索'
  loading = false
  customers: OrganizationAccountUi[] = []
  customerLoading = false
  customerColumns: Header[] = [
    {
      key: 'organizationFullName',
      label: '組織名'
    },
    {
      key: 'customerName',
      label: '名前',
      width: '12%'
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス'
    },
    {
      key: 'nickName',
      label: 'ログインID'
    },
    {
      key: 'isFormalAccount',
      label: '正式登録済',
      dataType: 'check',
      width: '12%'
    },
  ]
  rowSelection = RowSelection.NONE

  constructor(
    private organizationV2Service: OrganizationV2Service
  ) { }

  ngOnInit(): void {
  }

  // 会社選択時処理
  updateTargetCompany(company: BackendOrganizationV2): void {
    this.getCustomerList(company.organizationCd)
  }

  // 顧客一覧検索
  async getCustomerList(organizationCd: string): Promise<void> {
    if (!organizationCd) { return }
    this.customerLoading = true
    this.customers = []
    // const ret = await this.organizationV2Service.GetCustomerAccountInfos(organizationCd, true).toPromise()
    const ret$ = this.organizationV2Service.GetCustomerAccountInfos(organizationCd, true)
    let ret = await lastValueFrom(ret$)

    this.customerLoading = false
    if (ret?.resultCode !== 0) { return }
    const customers: OrganizationAccountUi[] = []
    ret.data.forEach(i => {
      const row = AutoMap<OrganizationAccountUi>(i)
      row.customerName = JoinText([row.familyName, row.firstName], ' ')
      customers.push(row)
    })
    this.customers = customers
  }
}
