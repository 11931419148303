import { Component, OnInit } from '@angular/core'
import { InvoiceData } from 'src/api/invoice-leaf/invoice-leaf-api.interface'
import { InvoiceLeafApiService } from 'src/api/invoice-leaf/invoice-leaf-api.service'
import { FileManagementService } from 'src/app/common/file-management.service'
import { MessageService } from 'src/app/components/message/message.service'
import { SelectOption } from 'src/app/module/common-parts/select/select.component.interface'
import { ActionTargetInfo, GridActionButton } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-ura-confirm-invoice-list',
  templateUrl: './ura-confirm-invoice-list.component.html',
  styleUrls: ['./ura-confirm-invoice-list.component.scss']
})
export class UraConfirmInvoiceListComponent implements OnInit {
  title = '請求データ確認'
  periods: SelectOption[] = []
  period: string
  data: InvoiceData[] = []
  columns: Header[] = [
    {
      key: 'invoiceKeyNumber',
      label: '請求書No.',
      width: '8%',
    },
    {
      key: 'suppliCustomerId',
      label: '請求先ID',
      width: '8%'
    },
    {
      key: 'totalBillUi',
      label: '金額',
      width: '8%',
      style: {
        textAlign: 'rigth'
      }
    },
    {
      key: 'corporateNumber',
      label: '法人番号',
      width: '9%'
    },
    {
      key: 'companyOrganizaionFomalName',
      label: '会社名',
      width: '14%'
    },
    {
      key: 'customerName',
      label: '請求担当者',
      width: '9%'
    },
    {
      key: 'personName',
      label: '宛先氏名',
      width: '9%'
    },
    {
      key: 'paymentTypeName',
      label: '月・年払い',
      width: '9%'
    },
    {
      key: 'paymentMethodTypeName',
      label: '支払方法',
      width: '9%'
    },
    {
      key: 'status',
      label: '送信ステータスCD',
      hidden: true
    },
    {
      key: 'statusUi',
      label: 'ステータス',
      width: '9%'
    },
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '利用明細DL',
      actionCd: 'downloadUsageDetail'
    },
    {
      label: '請求書DL',
      actionCd: 'downloadInvoice'
    },
    {
      label: 'データを確定しメール送信',
      actionCd: 'send',
      confirmDialog: true
    }
  ]
  loading = true
  sending = false
  cellClasses = {
    color_green: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'statusUi' && rowData.status === 1)
    },
    color_red: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'statusUi' && rowData.status === 0)
    },
  }
  dialogOpen = false
  invoiceTargetCd = ''

  constructor(
    private messageService: MessageService,
    private invoiceLeafApiService: InvoiceLeafApiService,
    private fileManagementService: FileManagementService
  ) { }

  ngOnInit(): void {
    this.getPeriods()
  }

  async getPeriods(): Promise<void> {
    this.periods = []
    this.loading = true
    const ret = await this.invoiceLeafApiService.GetTargetYearMonth()
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    ret.data.forEach(date => {
      var dateArray = date.split("-")
      const selectOption: SelectOption = {
        label: `${dateArray[0]}年${dateArray[1]}月`,
        value: date
      }
      this.periods.push(selectOption)
    })
    this.period = this.periods[0].value
    this.getList(this.period)
  }

  async getList(period: string): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.invoiceLeafApiService.GetInvoiceData(period.replace("-", ""))
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.data.forEach(item => {
      item.totalBillUi = `\\${item.totalBill.toLocaleString()}`
      item.actionCds = ['downloadUsageDetail', 'downloadInvoice']
      if (item.status === 0) {
        item.actionCds.push('send')
      }
    })
  }

  async send(invoiceDataCds: string[]): Promise<void> {
    this.sending = true
    const ret = await this.invoiceLeafApiService.ConfirmAndSendMail(invoiceDataCds)
    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('確定処理を実行しました')
    this.getList(this.period)
  }

  async actionCommit(event: ActionTargetInfo): Promise<void> {
    if (this.sending) { return }
    if (event.actionCd === 'downloadUsageDetail' || event.actionCd === 'downloadInvoice') {
      if (event.rows.length > 1) {
        this.messageService.warning('1件のみ選択して下さい')
      }
      let url = ""
      let fileName = ""
      if (event.actionCd === 'downloadUsageDetail') {
        const profRet = await this.invoiceLeafApiService.GetUsageDetailProf(event.rows[0].invoiceKeyNumber)
        if (profRet?.resultCode !== 0) { return }
        const prof = profRet.data
        url = this.invoiceLeafApiService.UsageDetailFileUrl(prof)
        fileName = prof.fileName
      }
      else if (event.actionCd === 'downloadInvoice') {
        const profRet = await this.invoiceLeafApiService.GetInvoiceProf(event.rows[0].invoiceKeyNumber)
        if (profRet?.resultCode !== 0) { return }
        const prof = profRet.data
        url = this.invoiceLeafApiService.InvoiceFileUrl(prof)
        fileName = prof.fileName
      }
      // ファイルDL
      this.fileManagementService.downloadPdfFile(url, fileName)
    }
    if (event.actionCd === 'send') {
      const invoiceDataCds = []
      event.rows.forEach(row => {
        invoiceDataCds.push(row.invoiceDataCd)
      })
      this.send(invoiceDataCds)
    }
  }
}
