import { Component, OnInit } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { AccountsV3Service } from 'src/api/accountsV3-api/accountsV3-api.service'
import { MessageService } from 'src/app/components/message/message.service'

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss']
})
export class RequestResetPasswordComponent implements OnInit {
  nickName = ''
  sending = false

  constructor(
    private accountsV3Service: AccountsV3Service,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }
  async sendMail(): Promise<void> {
    if (this.sending) { return }
    this.sending = true
    // const ret = await this.accountsV3Service.SendResetPasswordMail(this.nickName).toPromise()
    const ret$ = this.accountsV3Service.SendResetPasswordMail(this.nickName)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) {
      this.messageService.error('対象のログインIDが見つかりません')
      return
    }
    this.nickName = ''
    this.messageService.success('メールを送信しました')
  }
}
