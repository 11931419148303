import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { MerchandiseTreeUi } from 'src/api/merchandises-api/merchandises-api.interface';
import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model';
import { messageList } from 'src/app/components/message/message-data';
import { MessageService } from 'src/app/components/message/message.service';
import {
  ActionButtonDisp,
  FooterButtonTextAlign,
} from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface';
import { CreateQuotationService } from '../service/create-quotation.service';
import { NewQuotationService } from '../service/new-quotation.service';
import { QuotationSelectedGroup } from '../service/ura-create-quotation.interface';

@Component({
  selector: 'app-ura-new-quotation-merchandise',
  templateUrl: './ura-new-quotation-merchandise.component.html',
  styleUrls: ['./ura-new-quotation-merchandise.component.scss'],
})
export class UraNewQuotationMerchandiseComponent implements OnInit {
  title = '新規見積もり作成';
  index: string;
  targetOrganizationCustomer: OrganizationCustomer =
    this.newQuotationService.Session?.targetOrganizationCustomer;
  session: QuotationSelectedGroup;
  merchandisesTree: MerchandiseTreeUi[] = [];
  serviceTagCd: string;
  retailTagCd: string;
  businessCategoryTagCd: string;
  loading = false;
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.router.navigate([`/ura-new-quotation-service/${this.index}`]);
      },
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.pageNext();
      },
    },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private createQuotationService: CreateQuotationService,
    private router: Router,
    private newQuotationService: NewQuotationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async (params: ParamMap) => {
      this.index = params.get('index');
      const check = this.newQuotationService.pageInit(this.index);
      if (!check) {
        return;
      }

      this.session = this.newQuotationService.getSelectedGroupSession(
        this.index
      );

      this.serviceTagCd = this.session.service.tagCd;
      this.retailTagCd = this.session.retail.tagCd;
      this.businessCategoryTagCd = this.session.businessCategory?.tagCd;
      if (this.session.merchandiseTree[0]) {
        this.merchandisesTree = this.session.merchandiseTree;
      } else {
        this.loading = true;
        this.merchandisesTree =
          await this.createQuotationService.getMerchandisesTree(
            this.serviceTagCd,
            this.retailTagCd,
            this.businessCategoryTagCd
          );
        this.loading = false;
      }
    });
  }

  pageNext(): void {
    if (!this.merchandisesTree.filter((x) => x.unitQuantity > 0).length) {
      this.messageService.templeteMessage(messageList.M00040);
      return;
    }
    this.session.merchandiseTree = this.merchandisesTree;
    this.newQuotationService.setSelectedGroupSession(this.session, this.index);
    this.router.navigate([`/ura-new-quotation-remarks`]);
  }
}
