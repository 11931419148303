import { EstimateDocumentStatus } from '../frimo-migrations-api/frimo-migrations-api.model'

export interface EstimateHeader {
  organizationContractCd: string
  organizationContractName: string
  estimateNo: string
  initialCharge: number
  runningCharge: number
  payPerCharge: number
  createDatetime: Date
  expirationDate: Date
}

export interface EstimateDocument {
  organizationCd: string
  organizationName: string
  organizationContractCd: string
  organizationContractName: string
  estimateNo: string
  remarks: string
  organizationContractStartDatetime: Date
  organizationContractEndDatetime: Date
  contractMerchandises: ContractMerchandise[]
}

export interface ContractMerchandise {
  merchandiseCd: string
  merchandiseName: string
  contractCd: string
  contractName: string
  merchandiseSalesTypeCd: string
  merchandiseSalesTypeName: string
  price: number
  payPerUseFlg: boolean
  unitQuantity: number
  chargeTargetAmount: number
  chargeAmount: number
  contractPrice: number
  sellerCd: string
  sellerName: string
  purchaserCd: string
  purchaserName: string
  contractStartDatetime: Date
  contractEndDatetime: Date
  contractDetails: null[]
}

export interface EstimateDocumentDetail {
  estimateDetailsEvidenceCd: string
  merchandiseCd: string
  merchandiseName: string
  merchandiseSalesTypeCd: string
  merchandiseSalesTypeName: string
  unitQuantity: number
  unitPrice: number
  totalInitialPrice: number
  totalRunningPrice: number
  retailTagCd: string
  retailTagText: string
  businessCategoryTagCd: string
  businessCategoryTagText: string
  comment: string
}
export interface EstimateDocumentDetailAll {
  rowNo: number,
  merchandiseCd: string,
  merchandiseName: string,
  merchandiseSalesTypeCd: string,
  payPerUseFlg: boolean,
  merchandiseSalesTypeName: string,
  beforeUnitQuantity: number,
  beforeUnitPrice: number,
  beforeTotalRunningPrice: number,
  afterUnitQuantity: number,
  afterUnitPrice: number,
  afterTotalRunningPrice: number,
  retailTagCd: string,
  retailTagText: string,
  businessCategoryTagCd: string,
  businessCategoryTagText: string,
  comment: string,
  change: boolean
}


export interface EstimateDocumentDetailStatus extends EstimateDocumentStatus {
    remarks?: string
    mailAddress?: string
}

export interface EstimateCreateMacroRequest {
  organizationContractName?: string
  generatorCustomerCd: string
  remarks?: string
  estimateMerchandises: EstimateDetailTreeRequest[]
}

export interface EstimatePreviewRequest {
  organizationContractName?: string
  estimateNo?: string
  organizationContractStartDatetime?: Date
  organizationContractEndDatetime?: Date
  remarks?: string
  estimateMerchandises: EstimateDetailTreeRequest[]
}

export interface EstimateDetailTreeRequest {
  merchandiseCd: string
  merchandiseSalesTypeCd: string
  price: number
  payPerUseFlg: boolean
  unitQuantity: number
  chargeTargetAmount: number
  chargeAmount: number
  contractPrice: number
  contractStartDatetime: Date
  contractEndDatetime: Date
  serviceTagCd: string
  retailTagCd: string
  businessCategoryTagCd: string
  parentContractCd: string
  baseContractCd: string
  estimateMerchandises: EstimateDetailTreeRequest[]
}

export enum MerchandiseCompositionCd {
  SINGLE = 'single',
  SET = 'set',
  SELECT = 'select',
}
