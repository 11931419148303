
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { MessageService } from 'src/app/components/message/message.service'
import { InvitedUser } from 'src/api/frimo-switch-api/frimo-switch-api.model'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { InvitationsApiService } from 'src/api/invitations-api/invitations-api.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-invitation-send-list',
  templateUrl: './invitation-send-list.component.html',
  styleUrls: ['./invitation-send-list.component.scss']
})
export class InvitationSendListComponent implements OnInit {
  title = '招待送信履歴'
  data: InvitedUser[] = []
  columns: Header[] = [
    {
      key: 'invitationCd',
      label: 'CD',
      hidden: true,
    },
    {
      key: 'targetCompanyOrganizationName',
      label: '招待者会社',
      width: '18%'
    },
    // {
    //   key: 'targetSectionOrganizationName',
    //   label: '招待者組織',
    //   width: '12%'
    // },
    {
      key: 'targetCustomerName',
      label: '招待者',
      width: '12%'
    },
    {
      key: 'invitationTargetName',
      label: '招待種別',
      width: '8%'
    },
    {
      key: 'targetServiceName',
      label: '招待対象',
      width: '14%'
    },
    {
      key: 'targetProductInquiryCd',
      label: 'プロダクトコード',
      width: '10%'
    },
    {
      key: 'invitedDatetimeUi',
      label: '招待日',
      width: '10%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'approvedDatetimeUi',
      label: '承認日',
      width: '10%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'invitationStatusName',
      label: '状態',
      width: '8%'
    },
  ]
  rowClickAction = false
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '新規招待',
      actionCd: 'actionCd1',
      nextPage: ['/invitation-edit'],
      // default: true
      notRowAction: true
    },
    // {
    //   label: '招待を再送',
    //   actionCd: 'actionCd2'
    // }
  ]
  loading = true

  constructor(
    private authService: AuthService,
    private invitationsApiService: InvitationsApiService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getInvitationList()
  }

  // 招待送信一覧　取得
  getInvitationList(): void {
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd) { return }
    this.invitationsApiService.GetInvitationSendList(customerCd).subscribe((ret: ResultInfo<InvitedUser>) => {
      this.data = ret.data
      this.setData()
    })
  }

  actionCommit(event: ActionTargetInfo): void {
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    // 再送
    if (action.actionCd === 'actionCd2') {
      this.submitResend(event.rows)
    }
    // 成功メッセージ表示
    if (action.successMessage) {
      const actionText = action?.label || '処理成功'
      this.messageService.success(`${actionText}しました`)
    }
    // ページ遷移(新規招待)
    if (action.nextPage) {
      this.router.navigate(action.nextPage)
    }
  }

  // 招待再送
  submitResend(rows: InvitedUser[]): void {
  }

  setData(): void {

    const data = this.data
    data.forEach(item => {
      item.actionCds = []
      if (item.invitationStatusCd === '0') {
        // 未承認のとき
        item.actionCds.push('actionCd2')
      } else if ( item.invitationStatusCd === '1'){
        // 承認済みのとき
        item.actionCds.push('actionCd1')
      }
    })
    this.data = data
    this.loading = false
  }
}
