<igx-dialog #dialog [closeOnOutsideSelect]="true" message="">
  <div
    style="text-align: left; width: 1000px; max-height: 700px; padding: 2rem"
  >
    <h2 class="c-texts--head1" style="margin-bottom: 0">利用者一覧</h2>
    <app-action-grid
      [data]="data"
      [loading]="loading"
      [rowSelection]="rowSelection"
      [columns]="columns"
      [height]="450"
      (selectionChange)="selectionChange($event)"
    ></app-action-grid>
    <div class="c-btns">
      <div class="c-btns__item">
        <a
          class="js-rename-close c-btn"
          (click)="dialog.close()"
          style="cursor: pointer; margin-right: 1rem"
        >
          <span>キャンセル</span></a
        >
      </div>
      <div class="c-btns__item" *ngIf="editable" >
        <a (click)="deleteAction()" class="c-btn c-btn--blue" style="cursor: pointer"
        [class.is-disable]="!selectedRows.length"
          ><span>削除する</span></a
        >
      </div>
    </div>
  </div>
</igx-dialog>
