<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-estimate-breadcrumbs
    [index]="index"
    [targetOrganizationCustomer]="targetOrganizationCustomer"
    [retail]="session?.retail"
    [businessCategory]="session?.businessCategory"
    [service]="session?.service"
  ></app-estimate-breadcrumbs>
  <h2 class="c-texts--head2 h-margin--b24">
    以下のメニューから該当するプロダクトを選択、数量を指定してください。
  </h2>
  <app-loading *ngIf="loading"></app-loading>
  <app-quotation-merchandise-selecter
    [merchandisesTree]="merchandisesTree"
    (changeValue)="merchandisesTree = $event"
  ></app-quotation-merchandise-selecter>
  <app-under-btn-disp [actionButtons]="actionButtons"></app-under-btn-disp>
</app-main-contents>
