import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { OrganizationTreeWithCustomersModel } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { SelectedChildOrganizationInfo } from './selected-child-organization.model'
@Injectable({
  providedIn: 'root',
})
export class SelectedChildOrganizationService {
  constructor(
    private organizationV2Service: OrganizationV2Service
  ) { }
  // 組織図・ユーザー一覧間　受け渡し用オブジェクト
  private selectedChildOrganization: SelectedChildOrganizationInfo
  private organizationTree: OrganizationTreeWithCustomersModel[]

  public setSelectedChildOrganization(selectedChildOrganization: SelectedChildOrganizationInfo): void {
    this.selectedChildOrganization = selectedChildOrganization
  }
  public getSelectedChildOrganization(): SelectedChildOrganizationInfo {
    return this.selectedChildOrganization
  }

  async getOrganizationTree(companyOrganizationCd: string): Promise<OrganizationTreeWithCustomersModel> {
    // const ret = await this.organizationV2Service.OrganizationTreesWithCustomers(companyOrganizationCd).toPromise()
    const ret$ = this.organizationV2Service.OrganizationTreesWithCustomers(companyOrganizationCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    const retdata = ret.data[0]
    // 最上位組織に子組織がない場合nullになっているので[]をセット
    if (retdata.children === null) {
      retdata.children = []
    }
    return retdata
  }
}
