import { BrowserModule, HammerModule } from '@angular/platform-browser'
import { LOCALE_ID, NgModule } from '@angular/core'
import { ClickOutsideModule } from 'ng-click-outside'
import { IgxModules } from './module/igx.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { IsbeauthComponent } from './components/isbeauth/isbeauth.component'
import { HeaderComponent } from './components/header/header.component'
import { NavComponent } from './components/nav/nav.component'
import { FooterComponent } from './components/footer/footer.component'
import { MatIconModule } from '@angular/material/icon'
import { MatDialogModule } from '@angular/material/dialog'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { DatePipe, registerLocaleData } from '@angular/common'
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component'
import { MessageComponent } from './components/message/message.component'
import ja from '@angular/common/locales/ja'
import { MyideaV2Module } from './routing-content/myidea-v2/myidea-v2.module'
import { UraideaV2Module } from './routing-content/uraidea-v2/uraidea-v2.module'
import { CheckServerUpdateComponent } from './components/check-server-update/check-server-update.component'
import { ConfirmComponent } from './module/common-parts/confirm/confirm.component'
import { CommonPartsModule } from './module/common-parts/common-parts.module'
import { SignupStepProgressComponent } from './module/common-parts/signup-step-progress/signup-step-progress.component'
registerLocaleData(ja)

import localeJa from '@angular/common/locales/ja'
import { InvoiceLeafModule } from './routing-content/invoice-leaf/invoice-leaf.module'
registerLocaleData(localeJa)

@NgModule({
  declarations: [
    AppComponent,
    IsbeauthComponent,
    HeaderComponent,
    NavComponent,
    FooterComponent,
    PageNotFoundComponent,
    MessageComponent,
    CheckServerUpdateComponent,
    ConfirmComponent
  ],
  imports: [
    IgxModules,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MyideaV2Module,
    UraideaV2Module,
    InvoiceLeafModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule,
    HammerModule,
    MatSnackBarModule,
    ClickOutsideModule,
    CommonPartsModule
  ],
  providers: [
    DatePipe,
    // LOCALE_ID のセット
    { provide: LOCALE_ID, useValue: 'ja-JP' }
  ],
  // entryComponents: [AddOptionDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
