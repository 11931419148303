<div class="l-main__contents js-fit-contents">
  <app-breadcrumbs label2="企業情報の確認"></app-breadcrumbs>
  <!-- contents-->
  <h1 class="c-texts--head1">企業情報の確認</h1>
  <app-organization-profile-input *ngIf="formValue" [organization]="formValue" [items]="items">
    <div
      *ngFor="let nameInfo of organizationFullNames; let i = index"
      class="l-table__item"
    >
      <div class="l-table__head">
        <p>所属組織</p>
      </div>
      <div class="l-table__body">
        {{ nameInfo.organizationFullName }}
        <a
          class="c-btn"
          style="cursor: pointer; margin-left: 2rem"
          routerLinkActive="is-current"
          routerLink="/organization-map"
        >
          <span>組織図参照</span></a
        >
      </div>
    </div>
  </app-organization-profile-input>

    <!-- <div class="c-btns__item" style="cursor: pointer">
      <a class="c-btn c-btn--blue" (click)="submit()" style="cursor: pointer"
        ><span>登録する</span></a
      >
    </div> -->
  <!-- /contents-->
</div>
