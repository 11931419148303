
  <h3 class="c-text-1" style="margin-bottom: 48px">請求担当者選択</h3>
  <div style="font-size: 1.2rem; margin-top: -3rem; margin-bottom: 1rem">
    請求担当者としてお名前が選択できない方を招待したい場合は、<a
      href="https://idea-place.jp/contact/"
      style="color: #2fa8ff; text-decoration: underline"
      target="_blank"
      >お問い合わせフォーム</a
    >に以下をご入力の上、送信してください。弊社で対応完了後、改めてご連絡いたします。<br />
  </div>
  <div style="font-size: 1.2rem; margin-top: 0.5rem; margin-bottom: 1rem">
    【入力内容】<br />
    <div style="display: flex">
      <div style="width: 10rem">サービス種別</div>
      <div>：Myidea</div>
    </div>
    <div style="display: flex">
      <div style="width: 10rem">お問い合せ種別</div>

      <div>：登録情報変更</div>
    </div>
    <div style="display: flex">
      <div style="width: 10rem">変更内容を選択</div>
      <div>：請求先</div>
    </div>
    <div style="display: flex">
      <div style="width: 10rem">変更後の内容</div>
      <div>：請求担当者追加依頼</div>
    </div>
    <div style="display: flex">
      <div style="width: 10rem">お問い合せ内容</div>
      <div>
        ：招待したい方の［会社名］［氏名（フルネーム）］［メールアドレス］
      </div>
    </div>
  </div>


<div>
  <div class="l-table">
    <div *ngIf="radioOptions.length > 1" class="l-table__item l-table__item--s">
      <div class="l-table__head">
        <p>会社</p>
      </div>
      <div class="l-table__body">
        <app-radio
          [values]="radioOptions"
          [(ngModel)]="selectedCompanyDivition"
          (change)="selectOrganizationDivision()"
        ></app-radio>
        <!-- <div *ngIf="!selectedCompanyDivition" style="margin-top: 1rem">
          <app-input-search-company
            [selected]="selectedOrganizationCd"
            (changeSelect)="selectOtherCompany($event)"
          ></app-input-search-company>
        </div> -->
      </div>
    </div>
    <div
      *ngIf="selectedCompanyDivition !== '' || selectedOrganizationCd"
      class="l-table__item l-table__item--s"
    >
      <div class="l-table__head">
        <p>担当者</p>
      </div>
      <div class="l-table__body">
        <app-input-search-customer
          *ngIf="selectedOrganizationCd"
          [organizationCd]="selectedOrganizationCd"
          [singleSelect]="true"
          (submit)="selectCustomer($event)"
        ></app-input-search-customer>
      </div>
    </div>

    <div *ngIf="selectCustomerName" class="l-table__item l-table__item--s">
      <div class="l-table__head">
        <p>請求区分</p>
      </div>
      <div class="l-table__body flex">
        <app-select
          class="mergin-right"
          *ngIf="customerInvoiceTargets.length"
          [options]="customerInvoiceTargets"
          [(ngModel)]="selectedCd"
          (change)="selectInvoiceTaget()"
        ></app-select>
        <a *ngIf="!customerInvoiceTargets.length" (click)="changeInputMode()"
          >新しい請求先情報を追加する</a
        >
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="selectCustomerName && selectedCd">
  <div style="font-size: 1.2rem; margin-top: 3rem; margin-bottom: 1rem">
    以下の項目は請求担当者がMyideaで確認・更新を行います。
  </div>
</ng-container>
<app-input-table
  [class.no-show]="!selectedInvoiceTarget && !inputMode"
  #inputTable
  [formItems]="formItemsReadOnly"
>
</app-input-table>
