<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <div style="font-size: 1.2rem; margin-top: -3rem; margin-bottom: 1rem">
    請求先担当者の［会社名］［お名前］［メールアドレス］をご確認いただき、請求先担当者へ確認メールを送信してください。
    <br />
  </div>
  <app-action-grid
    *ngIf="showGrid"
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    minusHeight="20"
    [actionButtons]="actionButtons"
    [cellClasses]="cellClasses"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>

<app-dialog
  *ngIf="dialogOpen"
  widthSize="large"
  [hideCommitButton]="!changeMode"
  commitButtonText="確定して請求先担当者にメール送信"
  [persistent]="true"
  (commit)="submit()"
  (cancel)="dialogOpen = false"
>
  <ng-container *ngIf="!changeMode">
    <h3 class="c-text-1" style="margin-bottom: 48px;">請求先情報</h3>
    <div style="text-align: right; margin-top: -3rem">
      <a
        (click)="switchChangeMode()"
        class="c-btn"
        style="padding: 0.2em 1em; margin-bottom: 0.5rem; cursor: pointer"
        ><span>請求先担当者変更</span></a
      >
    </div>
    <div style="font-size: 1.2rem; margin-top: -3rem; margin-bottom: 1rem">
      請求先情報は、請求担当者がMyideaで確認・更新を行います。
    </div>
    <app-invoice-target-view
      [selectedCd]="invoiceTargetCd"
    ></app-invoice-target-view>
  </ng-container>
  <ng-container *ngIf="changeMode">
    <app-update-invoice-target-form #form></app-update-invoice-target-form>
  </ng-container>
</app-dialog>
