import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { Tag, TextTag } from 'src/api/common/api-common.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { NewQuotationService } from '../service/new-quotation.service'
import { QuotationSelectedGroup } from '../service/ura-create-quotation.interface'

@Component({
  selector: 'app-ura-new-quotation-business-category',
  templateUrl: './ura-new-quotation-business-category.component.html',
  styleUrls: ['./ura-new-quotation-business-category.component.scss']
})
export class UraNewQuotationBusinessCategoryComponent implements OnInit {
  title = '新規見積もり作成'
  index: string
  targetOrganizationCustomer: OrganizationCustomer = this.newQuotationService.Session?.targetOrganizationCustomer
  session: QuotationSelectedGroup
  tags: TextTag[] = []
  selectedTagCd: string
  loading = true
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.router.navigate([`/ura-new-quotation-retail/${this.index}`])
      },
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]
  constructor(
    private activatedRoute: ActivatedRoute,
    private merchandisesService: MerchandisesApiService,
    private router: Router,
    private newQuotationService: NewQuotationService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.index = params.get('index')
      const check = this.newQuotationService.pageInit(this.index)
      if (!check) { return }

      this.session = this.newQuotationService.getSelectedGroupSession(this.index)
      this.selectedTagCd = this.session.businessCategory?.tagCd
      this.getBusinessCategoryTags()
    })
  }

  // 業態情報取得
  async getBusinessCategoryTags(): Promise<void> {
    const tagList = this.session.businessCategoryTagList
    this.tags = tagList
    if (!this.selectedTagCd) {
      this.selectedTagCd = this.tags[0].tagCd
    }
    this.loading = false
  }

  pageNext(): void {
    if (!this.selectedTagCd) { return }
    this.session.businessCategory = this.tags.find(x => x.tagCd === this.selectedTagCd)
    this.newQuotationService.setSelectedGroupSession(this.session, this.index)
    this.router.navigate([`/ura-new-quotation-service/${this.index}`])
  }
}
