import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { AfterViewInit } from '@angular/core'
import { Input } from '@angular/core'
import { Output } from '@angular/core'
import { InputOrganization } from '../feature-organization.interface'
import { FeatureOrganizationService } from '../feature-organization.service'
import { formCompanyItems } from '../company.const'

@Component({
  selector: 'app-organization-profile-input',
  templateUrl: './organization-profile-input.component.html',
  styleUrls: ['./organization-profile-input.component.scss']
})
export class OrganizationProfileInputComponent implements OnInit, AfterViewInit {
  @Input() organization: InputOrganization
  @Input() items: FormInputInfo[] = formCompanyItems
  @Output() commit = new EventEmitter<InputOrganization>()
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  formItems: FormInputInfo[] = []
  formValue: InputOrganization

  constructor(
    private featureOrganizationService: FeatureOrganizationService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (!this.organization) { return }
    this.inputInit()
  }

  async inputInit(): Promise<void> {
    this.formItems = await this.featureOrganizationService.organizationInputItems(this.items)
    this.formValue = this.convertData(this.organization)
    this.updataFomalName()
    await this.removeCategoryName()
    await this.updateTypeName()
    this.inputTable.setForm(this.formValue, this.formItems)
  }

  convertData(formValue: InputOrganization): InputOrganization {
    formValue.telephoneNumber = (formValue.telephoneNumber || '').split('-').join('')
    formValue.organizationNameKana = this.featureOrganizationService.kataToHira(formValue.organizationNameKana)
    return formValue
  }

  submit(): InputOrganization {
    const submitForm = this.inputTable.submit()
    if (!submitForm) {
      return
    }

    // submitFormに存在する項目だけ上書く
    const baseValue = this.organization
    for (const key in submitForm) {
      if (submitForm.hasOwnProperty(key)) {
        baseValue[key] = submitForm[key]
      }
    }
    return baseValue
  }

  updateValue(input: InputOrganization): void {
    this.formValue = input
    this.updataFomalName()
    this.submit()
  }

  async updataFomalName(): Promise<void> {
    this.formValue.organizationFormalName = await this.featureOrganizationService.fomalName(
      this.formValue.organizationName,
      this.formValue.organizationCategoryCd,
      this.formValue.displayPositionCd)
  }

  async updateTypeName(): Promise<void> {
    const type = await this.featureOrganizationService.organizationTypeMaster()
    this.formValue.organizationTypeName = type.find(x => x.organizationTypeCd === this.formValue.organizationTypeCd)?.organizationTypeName
  }

  // 株式会社などの名称が入っていたら除く
  // そこからカテゴリとポジションも自動設定
  async removeCategoryName(): Promise<void> {
    const category = await this.featureOrganizationService.organizationCategoryMaster()
    category.forEach(data => {
      if (this.formValue.organizationName.indexOf(data.organizationCategoryName) !== -1) {
        this.formValue.organizationName = this.formValue.organizationName.replace(data.organizationCategoryName, '')
        this.formValue.organizationCategoryCd = data.organizationCategoryCd
      }
    })
  }
}
