<app-main-contents>
  <app-sending *ngIf="loading"></app-sending>
  <app-title [title]="title"></app-title>
  <div class="c-edit-modal__block c-edit-modal__block--user h-margin--t16">
    <h2 class="c-edit-modal__head c-texts--head2 h-margin--b16">
      <span>1</span>会社を選択してください
    </h2>
    <div class="h-margin--b16">
      <app-input-search-company
        (changeSelect)="updateTargetCompany($event)"
      ></app-input-search-company>
    </div>
    <h2 class="c-edit-modal__head c-texts--head2 h-margin--b16">
      <span>2</span>顧客を選択してください
    </h2>
    <app-action-grid
      [data]="customers"
      [loading]="customerLoading"
      [rowSelection]="rowSelection"
      [columns]="customerColumns"
      [rowClickAction]="true"
      (actionCommit)="actionCommit($event)"
    ></app-action-grid>
  </div>
</app-main-contents>