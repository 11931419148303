import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { CashService } from '../common/cash.service'
import { HttpRequestService } from '../common/http-request.service'
import { BackendOrganization, CompanyOrganization, LikeSearchCompanyRequest,
  LikeSearchOrganizationRequest, Prefecture, RegisterStatus } from './organization-company-api.interface'

@Injectable({
  providedIn: 'root'
})
export class OrganizationCompanyApiService {
  baseURL = environment.myideaMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
    private cashService: CashService
  ) { }

  // 都道府県マスタ取得 (キャッシュ有)
  async GetPrefectures(): Promise<Prefecture[]> {
    const key = 'prefectures'
    const cash = this.cashService.GetData<Prefecture[]>(key)
    if (cash) { return cash }
    const url = `${this.baseURL}api/organizationCompany/prefectures`
    const ret$ = this.httpRequestService.get<Prefecture>(url, false)
    const ret = await lastValueFrom(ret$)
    const value = ret?.data ?? []
    this.cashService.SetData(key, value)
    return value
  }

  // 会社プロファイル一覧取得
  async GetProfile(organizationCds?: string[]): Promise<ResultInfo<BackendOrganization>> {
    const url = `${this.baseURL}api/organizationCompany/profile`
    const ret$ = this.httpRequestService.post<BackendOrganization>(url, organizationCds ?? [])
    return await lastValueFrom(ret$)
  }

  // 部署から会社を取得
  async GetProfileBySectionCd(organizationCd: string): Promise<ResultInfoSingle<CompanyOrganization>> {
    const url = `${this.baseURL}api/organizationCompany/section/${organizationCd}/profile`
    const ret$ = this.httpRequestService.getResultSingle<CompanyOrganization>(url)
    return await lastValueFrom(ret$)
  }

  // 新規会社登録
  async Create(payload: BackendOrganization, customerCd: string): Promise<ResultInfo<BackendOrganization>> {
    const url = `${this.baseURL}api/organizationCompany/managerCustomerCd/${customerCd}/create`
    const ret$ = this.httpRequestService.post<BackendOrganization>(url, payload)
    return await lastValueFrom(ret$)
  }

  // 会社検索
  async LikeSearch(payload: LikeSearchCompanyRequest): Promise<ResultInfo<CompanyOrganization>> {
    const url = `${this.baseURL}api/organizationCompany/likeSearch`
    const ret$ = this.httpRequestService.post<CompanyOrganization>(url, payload)
    return await lastValueFrom(ret$)
  }

  // 法人検索
  async LikeSearchCorporates(payload: LikeSearchOrganizationRequest): Promise<ResultInfo<BackendOrganization>> {
    const url = `${this.baseURL}api/organizationCompany/likeSearchAllCorporate`
    const ret$ = this.httpRequestService.post<BackendOrganization>(url, payload)
    return await lastValueFrom(ret$)
  }

  // 新規会社登録
  async GetRegisterStatus(): Promise<ResultInfo<RegisterStatus>> {
    const url = `${this.baseURL}api/organizationCompany/registerStatus`
    const ret$ = this.httpRequestService.get<RegisterStatus>(url)
    return await lastValueFrom(ret$)
  }
}
