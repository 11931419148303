import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { AuthorityDivision, ProductMaster, ProductMasterUI } from 'src/api/conta-api/conta-api.interface'
import { ProductsApiService } from 'src/api/products-api/products-api.service'
import { actuateData, authorityDivisionMaster } from '../ura-contract-search-list/data'

@Injectable({
  providedIn: 'root'
})
export class UraContractEntryListService {
  private selectActuate = '1' // 稼働中
  private authorityDivisionMaster: AuthorityDivision[]
  private selectAuthority: any[]
  private productMasterListUI: ProductMasterUI[] = []

  constructor(
    private productsApiService: ProductsApiService
  ) { }

  // ◆稼働状態 パネル
  getActuateStatus(): any[] {
    return actuateData
  }
  // ◆選択された時
  selectActuateStatus(status): void {
    this.selectActuate = status
  }
  // ◆選択されている稼働状態
  getSelectedActuateStatus(): any {
    return this.selectActuate
  }

  // ◆権限　パネル
  getAuthorityList(): AuthorityDivision[] {
    // API 実行
    // this.contractsV4Service.ContractsSearch2()
    // .subscribe((ret: ResultInfo<tagMaster>) => {
    //   if (ret?.resultCode !== 0) { return }
    // })
    this.authorityDivisionMaster = authorityDivisionMaster
    const list = []
    this.authorityDivisionMaster.forEach(auth => {
      list.push(auth.authorityDivisionCd)
    })
    this.selectAuthority = list
    return this.authorityDivisionMaster
  }
  // 選択された時
  selectAuthorityList(selectedCd): void {
    const s = this
    if (this.checkSelectedAuthority(selectedCd)) {
      // 削除
      const result = s.selectAuthority.filter(function(item) {
        return item !== selectedCd
      })
      this.selectAuthority = result
    } else {
      // 追加
      this.selectAuthority.push(selectedCd)
    }
  }
  // 選択されている権限 リクエスト
  getSelectedAuthority(): any[] {
    return this.selectAuthority
  }
  // 　選択されている権限　チェック
  checkSelectedAuthority(selectedCd): boolean {
    const selectedAuth = this.selectAuthority.find(x => x == selectedCd)
    return selectedAuth ? true : false
  }

  // ◆製品
  async getProductList(): Promise<ProductMasterUI[]> {
    const products = await this.productsApiService.GetSelecterList()
    if (products?.resultCode !== 0) { return }
    const productMasterList = products.data

    this.productMasterListUI = []
    productMasterList.forEach(parent => {
      const product: ProductMasterUI = {
        parentProductCd: parent.productCd,
        parentProductName: parent.productName,
        chilidProductCd: parent.productCd,
        childProductName: parent.productName
      }
      this.productMasterListUI.push(product)
      parent.childrenProduct.forEach(child => {
        const childs: ProductMasterUI = {
          parentProductCd: parent.productCd,
          parentProductName: parent.productName,
          chilidProductCd: child.productCd,
          childProductName: child.productName.replace(`${parent.productName} `, '')
        }
        this.productMasterListUI.push(childs)
      })
    })
    return this.productMasterListUI
  }
}
