import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Subscription } from 'rxjs'
import { ConfirmService } from './confirm.service'


@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  @Output() commit = new EventEmitter<void>()
  @Output() cancel = new EventEmitter<void>()
  showDialog = false
  title = ''
  message = ''
  subscription: Subscription

  constructor(
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
    this.subscription = this.confirmService.messageShowSource$.subscribe(
      (show) => {
        this.setConfirm()
      }
    )
  }

  setConfirm(): void {
    this.showDialog = this.confirmService.show
    this.title = this.confirmService.title
    this.message = this.confirmService.message
  }

  ngOnDestroy(): void {
    //  リソースリーク防止のためsubcribeしたオブジェクトを破棄する
    this.subscription?.unsubscribe()
  }

  onSubmit(): void {
    this.confirmService.commitAction()
  }

  onCancel(): void {
    this.confirmService.setDisp(false)
  }
}
