import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InvoiceComponent } from './invoice/invoice.component'
import { InvoiceLeafRoutingModule } from './invoice-leaf-routing.module'
import { MyideaPartsModule } from 'src/app/module/myidea-parts/myidea-parts.module'
import { CommonPartsModule } from 'src/app/module/common-parts/common-parts.module'
import { IgxModules } from 'src/app/module/igx.module';
import { UserManagementComponent } from './user-management/user-management.component'
import { IsGridModule } from 'src/app/module/is-grid/is-grid.module'
import { InvoiceUserDialogComponent } from './user-management/invoice-user-dialog/invoice-user-dialog.component';
import { UsageDetailComponent } from './usage-detail/usage-detail.component'

@NgModule({
  declarations: [
    InvoiceComponent,
    UserManagementComponent,
    InvoiceUserDialogComponent,
    UsageDetailComponent
  ],
  imports: [
    CommonModule,
    IgxModules,
    IsGridModule,
    InvoiceLeafRoutingModule,
    CommonPartsModule,
    MyideaPartsModule
  ]
})
export class InvoiceLeafModule { }
