<app-customer-name-disp
  (targetSelected)="selectTargetOrganization($event)"
></app-customer-name-disp>

<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>

  <div class="l-table">
    <div class="l-table__item">
      <div class="l-table__head">
        <p>担当者</p>
      </div>
      <div class="l-table__body">
        <app-select
          *ngIf="selectedCompany"
          [options]="customers"
          [emptyOption]="true"
          [(ngModel)]="selectedCustomerCd"
          (change)="selectCustomer()"
        ></app-select>
      </div>
    </div>
  </div>

  <div *ngIf="selectedCustomerCd">
    <div class="l-table suppli-customer">
      <div class="l-table__item">
        <div class="l-table__head">
          <p>楽楽明細ID</p>
        </div>
        <div class="l-table__body">
          <p>
            <span *ngFor="let id of suppliCutsomerIds">{{ id }}</span>
          </p>
          <p *ngIf="!suppliCutsomerIds.length">なし</p>
        </div>
      </div>
    </div>

    <ul class="c-service-list__list">
      <li class="c-service-list__item">
        <app-action-grid
          [data]="contracts"
          [loading]="loading"
          [rowSelection]="rowSelection"
          [columns]="columns"
          [minusHeight]="260"
        ></app-action-grid>
      </li>
      <li class="c-service-list__item">
        <app-action-grid
          [data]="userAuth"
          [loading]="loading"
          [rowSelection]="rowSelection"
          [columns]="userColumns"
          [minusHeight]="260"
        ></app-action-grid>
      </li>
    </ul>

    <app-under-btn-disp [actionButtons]="actionButtons"></app-under-btn-disp>
  </div>
</app-main-contents>

<app-dialog
  *ngIf="dialogOpen"
  [title]="'変更後担当者'"
  (commit)="commit()"
  (cancel)="dialogOpen = false"
>
  <app-select
    [options]="customers"
    [emptyOption]="true"
    [(ngModel)]="toCustomerCd"
  ></app-select>
</app-dialog>
