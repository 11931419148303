import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import { InvitationAction, InvitationUser } from 'src/api/frimo-switch-api/frimo-switch-api.model'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { InvitationsApiService } from 'src/api/invitations-api/invitations-api.service'
import { Router } from '@angular/router'
import { NavService } from 'src/app/components/nav/nav.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'

@Component({
  selector: 'app-invitation-receive-list',
  templateUrl: './invitation-receive-list.component.html',
  styleUrls: ['./invitation-receive-list.component.scss']
})
export class InvitationReceiveListComponent implements OnInit {
  title = '招待された履歴'
  data: InvitationUser[] = []
  columns: Header[] = [
    {
      key: 'invitationCd',
      label: 'CD',
      hidden: true,
    },
    {
      key: 'invitationCompanyOrganizationName',
      label: '招待者会社',
      width: '14%'
    },
    // {
    //   key: 'invitationSectionOrganizationName',
    //   label: '招待者組織',
    //   width: '10%'
    // },
    {
      key: 'invitationCustomerName',
      label: '招待者',
      width: '8%'
    },
    {
      key: 'invitationTargetName',
      label: '招待種別',
      width: '8%'
    },
    {
      key: 'targetServiceName',
      label: '招待対象',
      width: '14%'
    },
    {
      key: 'targetProductInquiryCd',
      label: 'プロダクトコード',
      width: '10%'
    },
    {
      key: 'targetAuthorityDivisionName',
      label: '権限',
      width: '10%'
    },
    {
      key: 'invitedDatetimeUi',
      label: '招待日',
      width: '10%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'approvedDatetimeUi',
      label: '承認日',
      width: '10%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'invitationStatusName',
      label: '状態',
      width: '8%'
    },
    {
      key: 'actionCds',
      label: 'actionCds',
      hidden: true
    }
  ]
  rowClickAction = false
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '招待を承認',
      actionCd: 'actionCd1',
      successMessage: true
    },
    // {
    //   label: '招待内容を確認',
    //   actionCd: 'actionCd2',
    //   // successMessage: true,
    //   nextPage: ['/service-link-approval'],
    //   // default: true
    //   // notRowAction: true
    // }
  ]
  customerCd: string
  selectedInvitation: InvitationUser
  loading = true
  sending = false

  cellClasses = {
    color_red: (rowData: InvitationUser, columnKey: string): boolean => {
      if (!rowData[columnKey]) {
        return false
      }
      return columnKey === 'invitationStatusName' && rowData.invitationStatusCd === '0'
    },
  }

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private invitationsApiService: InvitationsApiService,
    private navService: NavService,
    private serviceMapViewService: ServiceMapViewService
  ) {
  }

  ngOnInit(): void {
    this.getInvitationList()
  }

  actionCommit(event: ActionTargetInfo): void {
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    if (action.actionCd === 'actionCd1') {
      this.submitApproval(event.rows)
    }
  }

  submitApproval(rowKeys: InvitationUser[]): void {
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd) { return }
    const approvals: InvitationAction[] = []
    rowKeys.forEach(rowKey => {
      const approval: InvitationAction = {
        invitationCd: rowKey.invitationCd,
        customerCd,
        invitationActionCd: '1', // 承認
        migrationFlg: '1' // 移行
      }
      approvals.push(approval)
    })
    if (this.sending) { return }
    this.sending = true
    this.invitationsApiService.InvitationAction(approvals).subscribe((ret: ResultInfo<boolean>) => {
      this.sending = false
      if (ret?.resultCode === 0) {
        if (ret?.data[0]) {
          // 　通知更新
          this.navService.updatenotificationList()
          this.messageService.success('承認しました')
          this.getInvitationList()
          // 権限、マップ再取得
          this.authService.setAuthorityTable()
          this.serviceMapViewService.clearCustomerMap()
        }
      }
    })
  }

  // 招待受信一覧　取得
  getInvitationList(): void {
    this.loading = true
    this.data = []
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd) { return }
    this.invitationsApiService.GetInvitationReceiveList(customerCd).subscribe((ret: ResultInfo<InvitationUser>) => {
      if (ret?.resultCode === 0) {
        this.setData(ret?.data)
        this.loading = false
      }
    })
  }

  // 仮データ取得
  setData(data: InvitationUser[]): void {
    // アクションCd入れる処理 そのうちいらなくなる
    data.forEach(item => {
      item.actionCds = []
      if (item.invitationStatusCd === '0') {
        item.actionCds.push('actionCd1')
      }
      if (item.invitationTagCd === 'service_connect_invitation') {
        item.actionCds.push('actionCd2')
      }
    })
    this.data = data
    return
  }

}
