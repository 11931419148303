import { Component, OnInit, ViewChild } from '@angular/core'
import { SearchServiceMapRequest } from 'src/api/conta-api/conta-api.interface'
import { ContaApiService } from 'src/api/conta-api/conta-api.service'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { ManagementUserDialogComponent } from 'src/app/module/myidea-parts/management-user-dialog/management-user-dialog.component'
import { ServiceMapViewBlock, TargetServiceBlockInfo } from 'src/app/module/myidea-parts/service-map-view.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'

@Component({
  selector: 'app-ura-management-user',
  templateUrl: './ura-management-user.component.html'
})
export class UraManagementUserComponent implements OnInit {
  title = 'サービス利用者管理'
  loading = false
  serviceMap: ServiceMapViewBlock[] = []
  selectedTargetOrg: BackendOrganizationCorporateV2
  @ViewChild('userListDialog', { read: ManagementUserDialogComponent, static: false })
  public userListDialog: ManagementUserDialogComponent

  constructor(
    private serviceMapViewService: ServiceMapViewService
  ) { }

  ngOnInit(): void {
  }

  selectTargetOrganization(event: BackendOrganizationCorporateV2): void {
    this.selectedTargetOrg = event
    if (this.selectedTargetOrg) {
      this.getServiceMap()
    }
  }

  async getServiceMap(): Promise<void> {
    const req: SearchServiceMapRequest = this.setRequest()
    this.loading = true
    const [mapBlocks] = await this.serviceMapViewService.getContaServiceMapFromApi(req)
    this.serviceMap = this.serviceMapViewService.addColumnForUserMaintenanceView(mapBlocks)
    this.serviceMap = this.serviceMapViewService.filterConnected(this.serviceMap)
    this.loading = false
  }

  setRequest(): SearchServiceMapRequest {
    const tagCds: string[] = []
    const req: SearchServiceMapRequest = {
      tagCds,
      contractCompanyOrganizationCd: this.selectedTargetOrg.organizationCd,
      actuateName: null,
      productCdForInquiry: null,
      supplierCd: null,
      productNumber: null
    }
    return req
  }

  clickServiceEvent(value: TargetServiceBlockInfo): void {
    if (value.targetBlock.productCd === 'account_box_product'
      || value.targetBlock.productCd === 'products-garage_account_box_product') {
      this.userListDialog.open(value.targetBlock.actuateCd)
    }
  }
}
