import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { AccountsV3Service } from 'src/api/accountsV3-api/accountsV3-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { DeepCopy } from 'src/app/common/common'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { Router } from '@angular/router'
import { FromInputService } from 'src/app/module/common-parts/input-table/form-input.service'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { lastValueFrom } from 'rxjs'
export interface NicknameChange {
  oldNickname: string
  nickname: string
  nicknameConfirmation: string
}
@Component({
  selector: 'app-nickname-change',
  templateUrl: './nickname-change.component.html',
  styleUrls: ['./nickname-change.component.scss'],
})
export class NicknameChangeComponent implements OnInit, AfterViewInit {
  formInput: NicknameChange
  oldNickName = ''
  loading = false
  title = 'ログインID変更'
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  formItems: FormInputInfo[] = []
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '変更する',
      actionFunction: () => {
        this.submit()
      }
    }
  ]

  constructor(
    private authService: AuthService,
    private accountsV3Service: AccountsV3Service,
    private messageService: MessageService,
    private router: Router,
    private fromInputService: FromInputService
  ) { }

  ngOnInit(): void {
    this.oldNickName = this.authService.AccountInfo?.nickName

    const nickNameForm = this.fromInputService.nickNameForm2()
    this.formInput = {
      oldNickname: this.oldNickName,
      nickname: '',
      nicknameConfirmation: ''
    }
    nickNameForm.label = '新しいログインID'
    this.formItems = [
      {
        key: 'oldNickname',
        label: '現在のログインID',
        readonly: true
      },
      nickNameForm,
      {
        key: 'nicknameConfirmation',
        label: '新しいログインID（確認用）',
        valid: {
          required: true,
        }
      }
    ]
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputTable.setForm(this.formInput)
    }, 100)
  }

  async submit(): Promise<void> {
    const submitForm = this.inputTable.submit()

    // ログインIDチェック
    const payload = DeepCopy(submitForm)

    if (payload.nickname !== payload.nicknameConfirmation) {
      this.messageService.error('新しいログインIDと新しいログインID（確認用）が一致しません')
      return
    }
    if (this.oldNickName === payload.nickname) {
      this.messageService.error('現在のログインIDと新しいログインIDが同じです')
      return
    }
    if (this.loading) { return }
    this.loading = true
    // const checkRet = await this.accountsV3Service.NicknameSearch(payload.nickname).toPromise()
    const checkRet$ = this.accountsV3Service.NicknameSearch(payload.nickname)
    const checkRet = await lastValueFrom(checkRet$)
    if (checkRet?.data.length > 0) {
      this.loading = false
      this.messageService.error('このログインIDはすでに別のユーザーが使用しているため、設定できません')
      return
    }
    payload.accountId = this.authService.AccountInfo.accountId
    // const ret = await this.accountsV3Service.NicknameUpdate(payload).toPromise()
    const ret$ = this.accountsV3Service.NicknameUpdate(payload)
    const ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('ログインIDを変更しました')
    const newData = this.authService.AccountInfo
    newData.nickName = payload.nickname
    this.authService.setAccountInfo(newData)
    this.router.navigate(['/'])
  }
}
