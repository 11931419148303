import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { AccountBoxActuate } from 'src/api/actuates-api/actuates-api.interface'
import { ActuatesService } from 'src/api/actuates-api/actuates-api.service'
import { AboxActuatesUserUi, UpdateAuthRequest } from 'src/api/service-user-api/service-user-api.interface'
import { ServiceUserService } from 'src/api/service-user-api/service-user-api.service'
import { DeepCopy, JoinText } from 'src/app/common/common'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { ActionTargetInfo, GridActionButton } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection, UpdateCheckBox } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-service-user-authority',
  templateUrl: './service-user-authority.component.html',
  styleUrls: ['./service-user-authority.component.scss']
})
export class ServiceUserAuthorityComponent implements OnInit {
  title = '利用者権限管理'
  showPage = false // 対象ユーザーでない場合は非公開にする
  data: AboxActuatesUserUi[] = []
  dataBackup: AboxActuatesUserUi[] = []
  updateAuthRequest: UpdateAuthRequest[] = []
  columnsBackup: Header[] = [
    {
      key: 'serviceUserCd',
      label: 'serviceUserCd',
      hidden: true
    },
    {
      key: 'customerName',
      label: '名前'
    },
    {
      key: 'companyOrganizationCd',
      label: 'companyOrganizationCd',
      hidden: true
    },
    {
      key: 'companyOrganizationName',
      label: '会社',
    },
    {
      key: 'sectionOrganizationCd',
      label: 'sectionOrganizationCd',
      hidden: true
    },
    {
      key: 'sectionOrganizationName',
      label: '部署',
    },
    {
      key: 'authorityDivisitionName',
      label: '権限',
      hidden: true
    },
    {
      key: 'superUser',
      label: 'スーパーユーザー',
      dataType: 'checkBox',
      width: '20%'
    }
  ]
  columns: Header[] = DeepCopy(this.columnsBackup)
  rowSelection = RowSelection.NONE
  actionButtons: GridActionButton[] = [this.updateButton]
  loading = true
  sending = false
  topActuateId = ''
  actuateInfo: AccountBoxActuate
  constructor(
    private activatedRoute: ActivatedRoute,
    private serviceUserService: ServiceUserService,
    private messageService: MessageService,
    private authService: AuthService,
    private actuatesService: ActuatesService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.topActuateId = params.get('topActuateId')
    })
    if (this.topActuateId) {
      this.getActuateInfo()
    }
  }

  actionCommit(event: ActionTargetInfo): void {
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    if (action.actionCd === 'update') {
      this.updateAuthConfirm()
      return
    }
  }

  get updateButton(): GridActionButton {
    return {
      label: 'スーパーユーザー権限を更新',
      actionCd: 'update',
      disabled: !this.updateAuthRequest.length,
      notRowAction: true
    }
  }

  // 稼働情報取得
  async getActuateInfo(): Promise<void> {
    // const ret = await this.actuatesService.GetAccountBoxActuateSearchByTopIds([this.topActuateId]).toPromise()
    const ret$ = this.actuatesService.GetAccountBoxActuateSearchByTopIds([this.topActuateId])
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.actuateInfo = ret.data[0]
    this.getList()
  }

  get actuateName(): string {
    return JoinText([
      this.actuateInfo?.coreActuateName,
      JoinText([JoinText(this.actuateInfo?.supplierCds, '/'),
      this.actuateInfo?.coreBusinessCategoryCd])
    ], ' ')
  }

  async getList(): Promise<void> {
    this.data = []
    this.dataBackup = []
    this.updateAuthRequest = []
    this.loading = true
    // const ret = await this.serviceUserService.GetAboxActuatesUserList(this.actuateInfo.accountBoxActuateCd).toPromise()
    const ret$ = this.serviceUserService.GetAboxActuatesUserList(this.actuateInfo.accountBoxActuateCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.loading = false
    this.data = ret.data
    this.data.forEach(row => {
      row.superUser = row.superUser = row.authorityDivisionCd === 'superuser'
    })
    this.dataBackup = DeepCopy(this.data)
    this.setAction()
  }

  // ✔更新
  updateCheckBox(event: UpdateCheckBox<AboxActuatesUserUi>[]): void {
    event.forEach(eventData => {
      const serviceUserCd = eventData.rowData.serviceUserCd
      const backup = this.dataBackup.find(x => x.serviceUserCd === serviceUserCd)
      // 一旦除外
      this.updateAuthRequest = this.updateAuthRequest.filter(x => x.serviceUserCd !== serviceUserCd)
      if (eventData.value !== backup.superUser) {
        this.updateAuthRequest.push(
          {
            serviceUserCd,
            authorityDivisionCd: eventData.value ? 'superuser' : 'user'
          }
        )
      }
    })
    this.setAction()
  }

  // 権限更新確認ダイアログ
  updateAuthConfirm(): void {
    if (!this.updateAuthCheck()) {
      this.messageService.templeteMessage(messageList.M00014)
      return
    }
    this.confirmService.confirm(`本当にスーパーユーザー権限を更新しますか？`, '', () => {
      this.updateAuth()
    })
  }

  // 権限更新チェック
  // true:OK false:NG
  updateAuthCheck(): boolean {
    // 更新しようとしている内容でスーパーユーザーが最低1人残るかどうか
    const superUsers: string[] = []
    this.dataBackup.forEach(row => {
      const update = this.updateAuthRequest.find(x => x.serviceUserCd === row.serviceUserCd)
      const authCd = update ? update.authorityDivisionCd : row.authorityDivisionCd
      if (authCd === 'superuser') {
        superUsers.push(row.serviceUserCd)
      }
    })
    return superUsers.length ? true : false
  }

  // 権限更新実行
  async updateAuth(): Promise<void> {
    if (this.sending) { return }
    this.sending = true
    // const ret = await this.serviceUserService.UpdateAuth(this.updateAuthRequest).toPromise()
    const ret$ = this.serviceUserService.UpdateAuth(this.updateAuthRequest)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.sending = false
    this.messageService.templeteMessage(messageList.M00050)
    this.getList()
  }

  setAction(): void {
    const customerCd = this.authService?.AccountInfo?.customerCd
    this.columns = DeepCopy(this.columnsBackup)
    const loginUser = this.dataBackup.find(x => x.customerCd === customerCd)
    if (!loginUser) {
      this.messageService.templeteMessage(messageList.M00059)
      return
    }
    else {
      this.showPage = true
    }
    if (loginUser.superUser) {
      this.actionButtons = [this.updateButton]
    }
    else {
      this.actionButtons = []
      this.columns.forEach(column => {
        if (column.key === 'superUser') {
          column.dataType = 'check' // 編集不可にする
        }
      })
    }
  }
}
