import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-search-group-items',
  templateUrl: './search-group-items.component.html',
  styleUrls: ['./search-group-items.component.scss']
})
export class SearchGroupItemsComponent implements OnInit {
  @Input() label = ''
  @Input() subText = ''
  @Input() errorMessage = ''

  constructor() { }

  ngOnInit(): void {
  }

}
