import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { FeatureOrganizationService } from 'src/app/module/myidea-parts/feature-organization.service'
import { MessageService } from 'src/app/components/message/message.service'
import { AutoMap } from 'src/app/common/common'
import { InputOrganization } from 'src/app/module/myidea-parts/feature-organization.interface'
import { messageList } from 'src/app/components/message/message-data'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'

@Component({
  selector: 'app-ura-company-add',
  templateUrl: './ura-company-add.component.html'
})
export class UraCompanyAddV2Component implements OnInit {
  title = '追加企業の法人情報検索'
  organizations: BackendOrganizationCorporateV2[] = []
  selectedOrganization: BackendOrganizationCorporateV2
  loading = false
  searchKey: string
  actionButtons: ActionButtonDisp[] = [
    {
      label: '次へ',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]

  constructor(
    private router: Router,
    private messageService: MessageService,
    private featureOrganizationService: FeatureOrganizationService
  ) { }

  ngOnInit(): void {
    const service = this.featureOrganizationService.getInputValues()
    this.searchKey = service?.organizationName
  }

  // 検索開始
  searchStart(): void {
    this.loading = true
    this.organizations = []
  }

  // 検索結果
  async setGridData(data: BackendOrganizationCorporateV2[]): Promise<void> {
    this.loading = false
    // BEに未登録の法人に絞る
    this.organizations = data
  }

  // 会社選択時処理
  selectCompany(organization: BackendOrganizationCorporateV2): void {
    this.selectedOrganization = organization
  }

  // 次へボタン押下時処理
  pageNext(): void {
    if (!this.selectedOrganization) {
      this.messageService.templeteMessage(messageList.M00003)
    }
    this.featureOrganizationService.setInputValues(AutoMap<InputOrganization>(this.selectedOrganization))
    this.router.navigate([`/ura-new-company-profile`])
  }

  // 戻るボタン押下時処理
  pageBack(): void {
    this.router.navigate([`/ura-top`])
  }
}
