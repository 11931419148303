import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { InvoiceComponent } from './invoice/invoice.component'
import { UserManagementComponent } from './user-management/user-management.component'
import { UsageDetailComponent } from './usage-detail/usage-detail.component'


const routes: Routes = [
  {
    path: 'invoice',
    component: InvoiceComponent
  },
  {
    path: 'usage-detail',
    component: UsageDetailComponent
  },
  {
    path: 'invoice-user-management',
    component: UserManagementComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoiceLeafRoutingModule { }
