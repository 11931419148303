<div class="remodal-overlay remodal-is-opened" style="display: block"></div>
<div
  class="remodal-wrapper remodal-is-opened"
  [class]="backAreaClass"
  style="display: block"
  (click)="onClick($event)"
>
  <div
    class="c-modal remodal remodal-is-initialized remodal-is-opened"
    [class]="widthSize"
    tabindex="-1"
    style="display: inline-block"
  >
    <div *ngIf="!hideCloseButton" class="c-modal__close">
      <a class="js-modal-close" (click)="onCancel()" style="cursor: pointer"
        ><i class="c-icon-close"></i
      ></a>
    </div>
    <h3 *ngIf="title" class="c-text-1">{{ title }}</h3>
    <h2 *ngIf="subTitle" class="c-sub-title">{{subTitle}}</h2>
    <p *ngIf="message" class="c-text-center">{{ message }}</p>
    <ng-content></ng-content>
    <div class="c-btns" *ngIf="!hideCancelButton || !hideCommitButton">
      <div *ngIf="!hideCancelButton" class="c-btns__item">
        <a class="c-btn" (click)="onCancel()" style="cursor: pointer"
          ><span>{{ cancelButtonText }}</span></a
        >
      </div>
      <div *ngIf="!hideCommitButton" class="c-btns__item">
        <a
          (click)="onSubmit()"
          class="c-btn c-btn--blue"
          style="cursor: pointer"
          ><span>{{ commitButtonText }}</span></a
        >
      </div>
    </div>
  </div>
</div>
