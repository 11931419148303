import { Component, OnInit } from '@angular/core'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
@Component({
  selector: 'app-ura-company-user',
  templateUrl: './ura-company-user.component.html',
  styleUrls: ['./ura-company-user.component.scss']
})
export class UraCompanyUserComponent implements OnInit {
  constructor(private easyAuthenticationsApiService: EasyAuthenticationsApiService) {}
  title = '所属顧客追加'
  organizationCd: string

  ngOnInit(): void {}

  selectTargetOrganization(event: BackendOrganizationCorporateV2): void {
    this.organizationCd = event?.organizationCd
  }

  commit(event) {
    this.easyAuthenticationsApiService.CreateTemporaryAccountEmailNotSendFile(event.customerCd).subscribe((res) => {})
  }
}
