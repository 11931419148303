import { Injectable } from '@angular/core'
export interface ActionTransactionSettings {
  enable: boolean // 出力オン・オフ切替（デフォルト＝無効）
  apiInfo: string // トランザクション登録エンドポイント情報
  data: any //アクションごとの設定
}
@Injectable({
  providedIn: 'root'
})
export class ActionTransactionService {
  constructor() {}
  private settings: ActionTransactionSettings | undefined //アクションCdごとの設定をセットする

  setSettings(settings: ActionTransactionSettings): void {
    this.settings = settings
  }
  getSettings() {
    return this.settings
  }
  //現在の出力設定が有効かどうかをチェック
  getActionTransactionSetting(commandCd: string) {
    let ret = false
    if (!(this.settings?.enable && this.settings?.data)) return ret
    let setting = this.settings.data[commandCd]
    if (setting) {
      if (setting.enable) {
        const currentDate = new Date()
        const start = new Date(setting.startAt)
        const end = new Date(setting.endAt)
        end.setDate(end.getDate() + 1) // endDate に 1 日足す
        if (currentDate >= start && currentDate < end) {
          ret = true
        }
      }
    }
    return ret
  }
  getCommandName(commandCd: string) {
    let ret = ''
    if (this.settings) {
      let setting = this.settings.data[commandCd]
      ret = setting?.name || ''
    }
    return ret
  }
}
