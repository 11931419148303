import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router, ParamMap } from '@angular/router'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { AddProductQuotationService } from '../service/add-product-quotation.service'
import { AddProductQuotationSession } from '../service/ura-create-quotation.interface'
@Component({
  selector: 'app-ura-create-add-quotation-remarks',
  templateUrl: './ura-create-add-quotation-remarks.component.html',
  styleUrls: ['./ura-create-add-quotation-remarks.component.scss']
})
export class UraCreateAddQuotationRemarksComponent implements OnInit {
  title = '見積もり作成 (追加契約)'
  organizationCompanyCd: string
  contractOrganizationCd: string
  session: AddProductQuotationSession
  remarks = ''
  loading = true
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.router.navigate([`/ura-add-product-merchandise/${this.organizationCompanyCd}/${this.contractOrganizationCd}/${this.session?.selectedGroups?.length - 1}`])
      },
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private addProductQuotationService: AddProductQuotationService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCompanyCd = params.get('organizationCompanyCd')
      this.contractOrganizationCd = params.get('contractOrganizationCd')
      const check = this.addProductQuotationService.pageInit(this.organizationCompanyCd, this.contractOrganizationCd)
      if (!check) { return }

      this.session = this.addProductQuotationService.Session
      this.remarks = this.addProductQuotationService.Session.remarks
    })
  }

  pageNext(): void {
    this.session.remarks = this.remarks
    this.addProductQuotationService.setSession(this.session)
    this.router.navigate([`/ura-quotation-preview-add-product/${this.organizationCompanyCd}/${this.contractOrganizationCd}`])
  }
}
