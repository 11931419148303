<div
  *ngIf="service"
  (mouseenter)="mouseenter()"
  (mouseleave)="mouseLeave()"
  (click)="click()"
  class="c-group__panel c-group__panel--default"
>
  <i
    *ngIf="service.iconClass"
    (click)="clickIcon.emit()"
    class="service-icon"
    [class]="'c-icon__' + service.iconClass"
    style="cursor: pointer"
  ></i>
  <a
    [class]="service.classes?.join(' ')"
    class="c-panel c-panel--s"
    style="cursor: pointer"
  >
    <p class="c-texts--s">
      <b class="product-name">{{ merchandiseName(service) }}</b>
      <span class="product-no"> {{ service.productInquiryCd }}</span
      ><br />
      <span class="sub-text"
        ><span>{{ service.blockText }} </span
        ><span class="sub-text memo" [title]="service.actuateName">
          {{ service.actuateName }}</span
        ></span
      >
    </p>
  </a>
  <div *ngIf="show" (click)="openSubInfo()" class="hover-area" [class]="togglePosition">
    <app-service-map-sub-info-dialog
      *ngIf="service.showSubInfo"
      [service]="subInfo"
      (updateSubInfo)="updateSubInfo($event)"
    ></app-service-map-sub-info-dialog>
  </div>
</div>
