import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { GenerateRequest, GenerateResponse, ExchangeRequest, NameCardListModel, NameCardDetail } from './namecard-api.interface'

@Injectable({
    providedIn: 'root'
})
export class NameCardApiService {
    baseURL = environment.nameCardBaseUrl
    constructor(
        private httpRequestService: HttpRequestService
    ) { }

    get BaseURL(): string {
        return this.baseURL
    }

    // 認証コードを発行
    GenerateCode(payload: GenerateRequest): Observable<ResultInfoSingle<GenerateResponse>> {
        const url = `${this.baseURL}api/namecards/generateCode`
        return this.httpRequestService.postResultSingle(url, payload)
    }

    // 名刺を交換する
    ExchangeNameCard(customerId: string, payload: ExchangeRequest): Observable<ResultInfoSingle<string>> {
        const url = `${this.baseURL}api/namecards/share/${customerId}`
        return this.httpRequestService.postResultSingle(url, payload)
    }

    async ExchangedCardList(customerId: string): Promise<ResultInfo<NameCardListModel>> {
        const url = `${this.baseURL}api/namecards/list/${customerId}`
        const ret$ = this.httpRequestService.get<NameCardListModel>(url)
        return await lastValueFrom(ret$)
    }

    async DetailCardList(companyId: string, customerId: string): Promise<ResultInfo<NameCardDetail>> {
        const url = `${this.baseURL}api/namecards/detail/${companyId}/${customerId}`
        const ret$ = this.httpRequestService.get<NameCardDetail>(url)
        return await lastValueFrom(ret$)
    }
}