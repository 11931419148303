import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import moment from 'moment'

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    }
  ]
})
export class DatePickerComponent implements OnInit {
  _value: string
  fomat = 'YYYY-MM-DD'
  constructor() { }

  ngOnInit(): void {
  }
  private onTouchedCallback: () => void = () => {}
  private onChangeCallback: (_: any) => void = () => {}

  get value(): string {
    if (this._value) {
      const ret = moment(this._value).format(this.fomat)
      return ret
      }
    return ''
  }
  @Input('value')
  set value(text: string) {
    if (this._value !== text) {
      this._value = moment(text).format(this.fomat)
      this.onChangeCallback(text)
    }
  }

  writeValue(text: string): void {
    if (text !== this.value && this.value) {
      this.value = moment(text).format(this.fomat)
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
