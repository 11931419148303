<ul class="btns btns--radio gap-xs">
  <li *ngFor="let val of values; let i = index">
    <input
      [(ngModel)]="value"
      type="radio"
      name="org-area"
      [id]="i"
      [value]="val.value"
    /><label [for]="i" class="btns__btn">{{val.label}}</label>
  </li>
</ul>
