import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router, ParamMap } from '@angular/router'
import { MerchandiseTreeUi } from 'src/api/merchandises-api/merchandises-api.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { AddProductQuotationService } from '../service/add-product-quotation.service'

declare const APP

// 調整未済 まだ未使用
@Component({
  selector: 'app-ura-create-add-quotation-confirm',
  templateUrl: './ura-create-add-quotation-confirm.component.html',
  styleUrls: ['./ura-create-add-quotation-confirm.component.scss']
})
export class UraCreateAddQuotationConfirmComponent implements OnInit {
  title = '見積もり作成 (追加契約)'
  contractOrganizationCd: string
  merchandisesTree: MerchandiseTreeUi[] = []
  loading = true
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.router.navigate([`/ura-add-contract-service-map/${this.contractOrganizationCd}`])
      },
    },
    {
      label: '次へ',
      actionFunction: () => {
        // this.pageNext()
      }
    }
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private addProductQuotationService: AddProductQuotationService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.contractOrganizationCd = params.get('contractOrganizationCd')
      const session = this.addProductQuotationService.Session
      // 保存されている契約組織と異なる場合はクリア
      if (session.contractOrganizationCd !== this.contractOrganizationCd
      ) {
        this.addProductQuotationService.clearSession()
        this.router.navigate([`/ura-create-add-quotation-business-category/${this.contractOrganizationCd}`])
      }
      // this.merchandisesTree = this.addProductQuotationService.Session.merchandiseTree
    })
  }

  ngAfterViewInit() {
    APP.controller.reset()
  }

}
