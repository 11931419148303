import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import {
  AccountBoxActuate, ActuateLoginResponse, ActuateSettingItem,
  ConnecterProduct, FrimoActuates, OrganizationProductDetails, RegistPaymentDateRequest
} from './actuates-api.interface'
import { ContractModel } from '../conta-api/conta-api.interface'

@Injectable({
  providedIn: 'root'
})
export class ActuatesService {
  baseURL = environment.backendMacroBaseUrl
  macroURL = environment.myideaMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 契約書コードから稼働データ一覧を取得(プロダクト単位)
  public GetOrganizationProductDetails(
    contractCd: string
  ): Observable<ResultInfo<OrganizationProductDetails>> {
    const url = `${this.baseURL}api/v2/Actuates/Contracts/${contractCd}`
    return this.httpRequestService.get(url)
  }

  // 課金開始日(稼働日)登録
  public setPaymentStartDate(payload: RegistPaymentDateRequest[]): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/v2/Actuates/SetPaymentStartDate`
    return this.httpRequestService.put(url, payload)
  }

  // 停止日更新
  public UpdateCancelDate(contractCds: string[], endDatetime: string): Observable<ResultInfo<boolean>> {
    const url = `${environment.backendMacroBaseUrl}api/v2/Actuates/SetCancelDate/${endDatetime}`
    return this.httpRequestService.put(url, contractCds)
  }


  /**
  * サービス間紐づけ
  * @param fromActuateCd 接続元サービスの稼働CD
  * @param toActuateCd 接続先サービスの稼働CD
  * @returns 
  */
  public async actuatesConnect(fromActuateCd: string, toActuateCd: string): Promise<ResultInfoSingle<boolean>> {
    const url = `${environment.myideaMacroBaseUrl}api/actuate/${fromActuateCd}/${toActuateCd}/connect`
    const ret$ = this.httpRequestService.postResultSingle<boolean>(url, null)
    return await lastValueFrom(ret$)
  }

  /**
  * サービス間切り離し
  * @param fromActuateCd 接続元サービスの稼働CD
  * @param toActuateCd 接続先サービスの稼働CD
  * @returns 
  */
  public async actuatesDisconnect(fromActuateCd: string, toActuateCd: string): Promise<ResultInfoSingle<boolean>> {
    const url = `${environment.myideaMacroBaseUrl}api/actuate/${fromActuateCd}/${toActuateCd}/disconnect`
    const ret$ = this.httpRequestService.deleteResultSingle<boolean>(url, null)
    return await lastValueFrom(ret$)
  }

  // 未接続プロダクト取得
  public GetConnecterProducts(
    companyOrganizationCd: string,
    productCd: string
  ): Observable<ResultInfo<ConnecterProduct>> {
    const url = `${this.baseURL}api/v2/Actuates/Organizations/${companyOrganizationCd}/Products/${productCd}/GetConnecterProducts`
    return this.httpRequestService.get(url)
  }

  // Frimo稼働情報取得
  public GetFrimoActuates(
    actuateCd: string
  ): Observable<ResultInfoSingle<FrimoActuates>> {
    const url = `${this.macroURL}api/actuate/frimoActuateSettings/${actuateCd}`
    return this.httpRequestService.getResultSingle(url)
  }

  // Frimo Web-Edi情報更新
  UpdateWebEdi(req: FrimoActuates): Observable<ResultInfo<boolean>> {
    const url = `${this.macroURL}api/actuate/frimoActuateSettings/Update`
    return this.httpRequestService.put(url, req)
  }

  // 稼働設定情報取得
  GetActuateSettings(
    contractCd: string
  ): Observable<ResultInfo<ActuateSettingItem>> {
    const url = `${this.macroURL}api/actuate/actuateSettings/${contractCd}`
    return this.httpRequestService.get(url)
  }

  // 稼働設定情報更新
  UpdateActuateSettings(contractCd: string, req: ActuateSettingItem[]): Observable<ResultInfo<boolean>> {
    const url = `${this.macroURL}api/actuate/actuateSettings/${contractCd}/Update`
    return this.httpRequestService.put(url, req)
  }

  // 稼働設定対象取得
  GetSettingActuateList(
    companyOrganizationCd: string
  ): Observable<ResultInfo<ContractModel>> {
    const url = `${this.baseURL}api/v2/SettingActuates/${companyOrganizationCd}`
    return this.httpRequestService.get(url)
  }

  // ログイン時My-idea稼働取得
  GetServiceActuates(
    customerCd: string
  ): Observable<ResultInfo<ActuateLoginResponse>> {
    const url = `${this.baseURL}api/v2/Customers/${customerCd}/MyideaActuates`
    return this.httpRequestService.get(url)
  }

  // AccountBox稼働情報取得
  SearchAccountBoxActuate(accountBoxActuateCds: string[]): Observable<ResultInfo<AccountBoxActuate>> {
    const url = `${this.BaseURL}api/v2/AccountBoxActuate/Search`
    return this.httpRequestService.post(url, accountBoxActuateCds)
  }

  // AccountBox稼働情報取得
  GetAccountBoxActuateSearchByTopIds(topActuateIds: string[]): Observable<ResultInfo<AccountBoxActuate>> {
    const url = `${this.BaseURL}api/v2/AccountBoxActuate/SearchByTopIds`
    return this.httpRequestService.post(url, topActuateIds)
  }
}
