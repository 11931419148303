import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import {
  IGridCellEventArgs,
  IGridStateOptions, IgxExcelExporterOptions, IgxExcelExporterService,
  IgxGridComponent, IgxNumberSummaryOperand, IgxSummaryResult
} from '@infragistics/igniteui-angular'
import { AutoMap, DeepCopy } from 'src/app/common/common'
import { Header, PrivateHeader, RowSelection, SelectedRow, UpdateCheckBox } from './base-grid.component.interface'
class SumSummary {
  public operate(data?: any[]): IgxSummaryResult[] {
    const result = []
    result.push({
      summaryResult: IgxNumberSummaryOperand.sum(data).toLocaleString()
    })
    return result
  }
}
@Component({
  selector: 'app-base-grid',
  templateUrl: './base-grid.component.html',
  styleUrls: ['./base-grid.component.scss']
})
export class BaseGridComponent implements OnInit, OnChanges {

  constructor(
    private excelExportService: IgxExcelExporterService,
  ) { }

  get MergeCellClasses(): any {
    // @Inputでの指定とデフォルト設定をマージ
    return { ...this.cellClasses, ...this.defaultCellClasses }
  }

  get showSelectedCount(): boolean {
    return this.selectedRows?.length && this.rowSelection === RowSelection.MULTIPLE
  }

  @ViewChild('grid', { static: true }) public grid: IgxGridComponent
  // データ
  @Input() data: any[]
  @Input() rowSelection = RowSelection.NONE
  @Input() loading = false
  @Input() columns: Header[] = []
  @Input() height: number
  @Input() minusHeight = 0 // マイナスしたい高さをピクセル指定
  @Output() selectRows = new EventEmitter<any[]>()
  @Output() selectionChange = new EventEmitter<SelectedRow<any>>()
  @Output() updateCheckBox = new EventEmitter<UpdateCheckBox<any>[]>()
  selectedRows = []
  dataList = [] // 変換後のデータ(Grid表示はこちらを利用)
  convertColumns: PrivateHeader[] = [] // field値採番後のデータ(Grid表示はこちらを利用)
  indexColumnKey = 'pkIndex'
  indexCount = 0
  sumSummary = SumSummary

  options: IGridStateOptions = {
    cellSelection: false,
    rowSelection: false,
    filtering: false,
    advancedFiltering: false,
    paging: true,
    sorting: true,
    groupBy: false,
    columns: true,
    expansion: false,
    rowPinning: false,
    columnSelection: false,
  }

  clipboardOptions = {
    enabled: true,
    copyHeaders: false,
    copyFormatters: false,
    separator: '\t'
  }

  // セルの条件付き書式(外から自由に条件指定)
  @Input() cellClasses = {
    // 例
    color_red: (rowData: any, columnKey: string): boolean => {
      if (!rowData[columnKey] || typeof rowData[columnKey] !== 'string') {
        return false
      }
      return rowData[columnKey].indexOf('0)') !== -1
    },
  }

  // セルの条件付き書式(外からパラメータで指定する系)
  defaultCellClasses = {
    align_rigth: (rowData: any, columnKey: string): boolean => {
      if (!this.columns) { return false }
      const col = this.columns.find(x => x.key === columnKey)
      return col?.style?.textAlign === 'rigth'
    },
    align_left: (rowData: any, columnKey: string): boolean => {
      if (!this.columns) { return false }
      const col = this.columns.find(x => x.key === columnKey)
      return col?.style?.textAlign === 'left'
    },
    align_center: (rowData: any, columnKey: string): boolean => {
      if (!this.columns) { return false }
      const col = this.columns.find(x => x.key === columnKey)
      return col?.style?.textAlign === 'center'
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.dataList = this.convertData(this.data)
      this.selectedRows = []
    }
    if (changes.columns) {
      this.convertColumns = this.convertColumn(this.columns)
    }
  }

  get gridHeight(): string {
    if (this.height) {
      return this.height + 'px'
    }
    return (window.innerHeight - 210 - this.minusHeight) + 'px'
  }

  // データ部変換
  convertData(data: any[]): any[] {
    data.forEach(row => {
      row.pkIndex = this.indexCount
      this.indexCount++
    })
    return data
  }

  // カラム設定のfield値を採番
  convertColumn(columns: Header[]): PrivateHeader[] {
    const convertList: PrivateHeader[] = []
    columns.forEach(column => {
      if (!column.groupLabel) {
        // Groupではないカラム
        convertList.push(AutoMap<PrivateHeader>(column))
      }
      else {
        // Groupカラム
        if (!convertList.find(x => x.groupLabel === column.groupLabel)) {
          const groupParent: PrivateHeader =  {
            groupLabel: column.groupLabel,
            key: column.groupLabel,
            label: column.groupLabel,
            group: [column]
          }
          convertList.push(groupParent)
        }
        else {
          convertList.forEach(convertColumn => {
            if (convertColumn.groupLabel === column.groupLabel) {
              convertColumn.group.push(column)
            }
          })
        }
      }
    })
    convertList.unshift({
      key: this.indexColumnKey,
      label: this.indexColumnKey,
      hidden: true
    })
    return convertList
  }

  // 行選択
  handleRowSelection(event): void {
    const addItem = []
    const deleteItem = []
    event.added?.forEach(addedIndexKey => {
      const targetRow = this.dataList.find(x => x.pkIndex === addedIndexKey)
      if (targetRow) {
        if (!this.selectedRows.find(x => x.pkIndex === addedIndexKey)) {
          this.selectedRows.push(targetRow)
        }
        addItem.push(targetRow)
      }
    })
    event.removed?.forEach(removedIndexKey => {
      const targetRow = this.dataList.find(x => x.pkIndex === removedIndexKey)
      if (targetRow) {
        this.selectedRows = this.selectedRows.filter(x => x.pkIndex !== removedIndexKey)
        deleteItem.push(targetRow)
      }
    })
    const selectedRows: SelectedRow<any> = {
      add: DeepCopy(addItem),
      delete: DeepCopy(deleteItem),
      selected: DeepCopy(this.selectedRows)
    }
    this.selectRows.emit(DeepCopy(this.selectedRows))
    this.selectionChange.emit(selectedRows)
  }

  // セルクリック
  cellClick(event: IGridCellEventArgs): void {
    const key = event.cell.column.field
    const column = this.columns.find(x => x.key === key)
    if (column.dataType !== 'checkBox') {
      return
    }
    event.cell.row.data[key]  = !event.cell.row.data[key]
    const updateRow: UpdateCheckBox<any> = {
      field: key,
      value: event.cell.row.data[key],
      rowData: event.cell.row.data
    }
    this.updateCheckBox.emit([updateRow])
   }

  exportButtonHandler(): void {
    this.excelExportService.export(this.grid, new IgxExcelExporterOptions('ExportedDataFile'))
  }
}
