<div class="c-step">
<div class="c-step__col c-step__col--progress">
  <ul class="c-step__list">
    <li
      *ngFor="let step of steps"
      class="c-step__item"
      [class.is-current]="stepIndex === step.index"
      [class.is-fin]="stepIndex > step.index"
    >
      <p class="c-step__icon"><i [class]="'c-icon__' + step.icon"></i></p>
      <p class="c-step__text">{{ step.label }}</p>
    </li>
  </ul>
</div>
<div class="c-step__col c-step__col--head">
  <h1 class="c-step__head c-texts--head1">{{title}}</h1>
</div>
</div>