import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core'
import { CompanyOrganization } from 'src/api/organization-company-api/organization-company-api.interface'
import { AuthService } from 'src/app/components/isbeauth/auth.service'

@Component({
  selector: 'app-customer-name-disp',
  templateUrl: './customer-name-disp.component.html',
  styleUrls: ['./customer-name-disp.component.scss'],
})
export class CustomerNameDispComponent implements OnInit {
  @Output() targetSelected = new EventEmitter<CompanyOrganization>()
  title = '編集対象の顧客を検索'
  dispCustomerName: string
  selectedCompany: CompanyOrganization
  selectMode = false

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.dispCustomerName = ''
    this.selectedCompany = this.authService.ChangeCustomer
    if (!this.authService.ChangeCustomer) {
      this.selectMode = true
    } else {
      this.selectMode = false
    }
    this.targetSelected.emit(this.selectedCompany)
  }

  dispSelectContents(): void {
    this.authService.setSelectedChangeCustomer(null)
    this.ngOnInit()
  }

  // 会社選択時処理
  selectCompany(company: CompanyOrganization): void {
    this.selectedCompany = company
    // サービスに入れる
    this.authService.setSelectedChangeCustomer(this.selectedCompany)
    this.selectMode = false
    this.ngOnInit()

  }
}
