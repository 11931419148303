<igx-dialog #settingInvoiceDialog [closeOnOutsideSelect]="true" message="">
  <div
    style="text-align: left; width: 1100px; max-height: 700px; padding: 2rem"
  >
    <h2 class="c-texts--head1" style="margin-bottom: 0">請求先設定</h2>

    <div>
      <div *ngIf="showEditBtn" style="text-align: right">
        <div class="c-btns__item">
          <a
            class="c-btn c-btn--s"
            (click)="editMode = !editMode"
            style="cursor: pointer"
          >
            <span>{{ editMode ? "参照" : "更新" }}</span></a
          >
        </div>
      </div>

      <ng-container *ngIf="editMode">
        <app-progress *ngIf="sending"></app-progress>
        <p class="c-texts--attention h-margin--b16">＊は入力必須項目です</p>
        <div class="l-table">
          <div class="l-table__item">
            <div class="l-table__head">
              <p>請求先会社<span class="c-label--required">*</span></p>
            </div>
            <div class="l-table__body">
              <app-input-search-company
                [selectedSearchOrganizationCd]="companyOrganizationCd"
                (changeSelect)="updateTargetCompany($event)"
              ></app-input-search-company>
            </div>
          </div>
          <div class="l-table__item">
            <div class="l-table__head">
              <p>請求先顧客<span class="c-label--required">*</span></p>
            </div>
            <div class="l-table__body">
              <div class="c-form__selectbox">
                <select class="c-form__input" (change)="changeCustomer($event)">
                  <option [selected]="!selectedCustomer" value="">
                    選択してください
                  </option>
                  <option
                    *ngFor="let list of customerList"
                    [selected]="
                      list.customerCd === selectedCustomer?.customerCd &&
                      list.organizationCd === selectedCustomer?.organizationCd
                    "
                    [value]="list.index"
                  >
                    {{ list.organizationFullName }} {{ list.familyName
                    }}{{ list.firstName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div [class.no-show]="!invoiceViewFlag" class="l-table__item">
            <div class="l-table__head">
              <p>請求先No.<span class="c-label--required">*</span></p>
            </div>
            <div class="l-table__body">
              <div class="c-form__selectbox c-form__small">
                <select
                  class="c-form__input"
                  (change)="changeInvoiceTarget($event)"
                >
                  <option [selected]="!selectedInvoice" value="">
                    選択してください
                  </option>
                  <option
                    *ngFor="let list of invoiceList"
                    [selected]="
                      list.invoiceTargetCd === selectedInvoice?.invoiceTargetCd
                    "
                    [value]="list.invoiceTargetCd"
                  >
                    {{ list.suppliCustomerId }}
                    {{ list.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <app-action-grid
        *ngIf="!editMode"
        [data]="actuateInvocieInfoList"
        [loading]="loading"
        [rowSelection]="rowSelection"
        [columns]="columns"
        [height]="450"
        [cellClasses]="cellClasses"
        (actionCommit)="actionCommit($event)"
      ></app-action-grid>
    </div>
    <div class="c-btns">
      <div class="c-btns__item">
        <a
          class="js-rename-close c-btn"
          (click)="settingInvoiceDialog.close()"
          style="cursor: pointer; margin-right: 1rem"
        >
          <span>キャンセル</span></a
        >
        <a
          *ngIf="editMode"
          [class.is-disable]="!canUpdateFlg"
          class="c-btn c-btn--blue"
          (click)="conectInvoice()"
          style="cursor: pointer"
        >
          <span>更新</span></a
        >
      </div>
    </div>
  </div>
</igx-dialog>
