import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ContractDetailAddressComponent } from './contract-detail-block-address/contract-detail-block-address.component'
import { FormsModule } from '@angular/forms'
import { IgxDialogModule } from '@infragistics/igniteui-angular'
import { ClickOutsideModule } from 'ng-click-outside'
import { CommonPartsModule } from '../../common-parts/common-parts.module'
import { ContractDetailBlockNewComponent } from './contract-detail-block-new/contract-detail-block-new.component'
import { IsGridModule } from '../../is-grid/is-grid.module'
import { ContractDetailBlockAllComponent } from './contract-detail-block-all/contract-detail-block-all.component'
import { EstimateExplanationTextComponent } from './estimate-explanation-text/estimate-explanation-text.component'

@NgModule({
  declarations: [
    ContractDetailAddressComponent,
    ContractDetailBlockNewComponent,
    ContractDetailBlockAllComponent,
    EstimateExplanationTextComponent
  ],
  imports: [
    CommonModule,
    CommonPartsModule,
    ClickOutsideModule,
    IgxDialogModule,
    FormsModule,
    IsGridModule
  ],
  exports: [
    ContractDetailAddressComponent,
    ContractDetailBlockNewComponent,
    ContractDetailBlockAllComponent,
    EstimateExplanationTextComponent
  ]
})
export class ContractDispModule { }
