import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { ReceiveMessage } from './notification-api.model'

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
  ) { }
  
  get StaticMessageUrl(): string {
    return `${environment.myideaMacroBaseUrl}api/notification/staticMessage`
  }

  // 16
  // お知らせ一覧取得 get /api/Notices/Customers/{receiveCustomerCd}
  GetNotificationList(customerCd: string): Observable<ResultInfo<ReceiveMessage>> {
    const url = `${this.baseURL}api/Notices/Customers/${customerCd}`
    return this.httpRequestService.get(url)
  }

  // お知らせを既読に
  AlreadyReading(receiveCustomerCd: string): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/Notices/Customers/${receiveCustomerCd}/AlreadyReading`
    return this.httpRequestService.get(url)
  }

}
