import { Component, OnInit } from '@angular/core'
import { ServiceMapViewBlock } from 'src/app/module/myidea-parts/service-map-view.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { UraSearchService } from '../components/ura-search.service'
import { SelectListItem } from '../../../module/myidea-parts/input-selectbox/input-selectbox.interface'
import { MessageService } from 'src/app/components/message/message.service'
import { ContaApiService } from 'src/api/conta-api/conta-api.service'
import { ContractListItem, InputProductInquiryCd, SearchServiceMapRequest } from 'src/api/conta-api/conta-api.interface'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'app-ura-service-map-search',
  templateUrl: './ura-service-map-search.component.html',
  styleUrls: ['./ura-service-map-search.component.scss'],
})
export class UraServiceMapSearchComponent implements OnInit {
  title = '検索：サービスマップ'
  loading = false
  companyOrganizationCd: string
  customerCd: string
  serviceMap: ServiceMapViewBlock[] = []

  values: any = {
    serviceTagCd: null,
    retailTagCd: null,
    bussinesCategoryTagCd: null,
    actuateName: '',
    productCdForInquiry: '',
    productNumber: '',
    supplierCd: '',
    contractCompanyOrganizationCd: '',
  }

  serviceTagList: SelectListItem[] = []
  retailTagList: SelectListItem[] = []
  bussinesCategoryTagList: SelectListItem[] = []

  searchedNumber = '-'
  errorRequest = false

  constructor(
    private serviceMapViewService: ServiceMapViewService,
    private uraSearchService: UraSearchService,
    private messageService: MessageService,
    private contaService: ContaApiService,
    private merchandisesService: MerchandisesApiService
  ) { }

  ngOnInit(): void {
    this.setTagList()
    this.changeRetail(null)
  }

  // タグ情報取得
  async setTagList(): Promise<void> {
    // const ret = await this.merchandisesService.GetMerchandisesTagMaster().toPromise()
    const ret$ = this.merchandisesService.GetMerchandisesTagMaster()
    let ret = await lastValueFrom(ret$)

    this.serviceTagList = this.uraSearchService.getServiceList(ret.data)
    this.retailTagList = this.uraSearchService.getRetailList(ret.data)
  }

  changeValue(key: string, value: string): void {
    this.values[key] = value
    // this.searchNumber()
  }

  changeProductInquiryCd(event: InputProductInquiryCd): void {
    this.errorRequest = event.isError
    this.values.productCdForInquiry = event.productCdForInquiry
    this.values.productNumber = event.productNumber
  }

  changeRetail(retailTagCd: string): void {
    this.bussinesCategoryTagList = this.uraSearchService.getBusinessCategoryList(retailTagCd)
    this.changeValue('retailTagCd', retailTagCd)
    this.changeValue('bussinesCategoryTagCd', null)
  }

  async getServiceMap(req: SearchServiceMapRequest): Promise<void> {
    this.loading = true
    const [mapBlocks, targetActuateCds] = await this.serviceMapViewService.getContaServiceMapFromApi(req)
    this.serviceMap = this.serviceMapViewService.addColumnForSearchServiceMapView(mapBlocks, targetActuateCds)
    this.loading = false
  }

  search(): void {
    if (this.errorRequest) {
      this.serviceMap = []
      return
    }
    const req = this.setRequest()
    if (!req.contractCompanyOrganizationCd
      && !req.actuateName
      && !req.productCdForInquiry
      && !req.supplierCd) {
      this.messageService.warning('契約会社名、利用拠点名（メモ）プロダクトコード、サプライヤーコードのいずれかを必ず指定してください')
      return
    }
    this.getServiceMap(req)
  }

  searchNumber(): void {
    this.searchedNumber = '-'
    const req = this.setRequest()
    // 全件検索回避
    if (req.contractCompanyOrganizationCd || req.actuateName || req.productNumber || req.productCdForInquiry || req.supplierCd) {
      // this.contaService.GetMapNumber(req).subscribe((ret: ResultInfo<ContractListItem>) => {
      //   this.loading = false
      //   if (ret?.resultCode === 0) {
      //     this.searchedNumber = String(ret.data[0])
      //   }
      // })
    }
  }

  setRequest(): SearchServiceMapRequest {
    const tagCds: string[] = []
    if (this.values.serviceTagCd) { tagCds.push(this.values.serviceTagCd) }
    if (this.values.retailTagCd) { tagCds.push(this.values.retailTagCd) }
    if (this.values.bussinesCategoryTagCd) { tagCds.push(this.values.bussinesCategoryTagCd) }
    const req: SearchServiceMapRequest = {
      tagCds,
      contractCompanyOrganizationCd: this.values.contractCompanyOrganizationCd || null,
      actuateName: this.values.actuateName || null,
      productCdForInquiry: this.values.productCdForInquiry || null,
      supplierCd: this.values.supplierCd || null,
      productNumber: this.values.productNumber || null
    }
    return req
  }
}
