<span class="search-field">
  <input
    (clickOutside)="showCustomerList = false"
    class="c-form__input"
    type="text"
    placeholder="お名前かメールアドレスを入力してください"
    autocomplete="off"
    (keyup)="showList()"
    (click)="showList()"
    [(ngModel)]="searchKeyword"
  />
</span>
<div
  *ngIf="showCustomerList && customerDispList.length"
  class="search-user-result-area"
>
  <div class="user-list-body">
    <div
      *ngIf="customerListLoading"
      class="user-list"
      style="text-align: center"
    >
      loading...
    </div>
    <div *ngFor="let user of customerDispList" class="user-list">
      <a (click)="selectUser(user)" style="cursor: pointer">
        <div class="list-user-name">
          <div
            >{{ user.familyName }} {{ user.firstName }}</div>
           <div>{{ user.mailAddress }}</div
          >
        </div>
      </a>
    </div>
  </div>
</div>

<p *ngIf="selectCustomerName">
  {{ selectCustomerName }}
  <a class="clear-btn" (click)="clearSelect()" style="cursor: pointer">×</a>
</p>