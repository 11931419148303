import { Component, OnInit } from '@angular/core'
import { MessageType } from './message.interface'
import { MessageService } from './message.service'

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(
    private messageService: MessageService
  ) { }

  get message(): string {
    return this.messageService.message
  }

  get show(): boolean {
    return this.messageService.show
  }

  get basicDesign(): boolean {
    return this.type !== MessageType.SUCCESS
  }

  get type(): string {
    return this.messageService.type
  }

  ngOnInit(): void {
  }

}
