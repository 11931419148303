import { Component, EventEmitter, Output } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'
import { MessageService } from 'src/app/components/message/message.service'

@Component({
  selector: 'app-ura-account-link-url-send-mail-preview-dialog',
  template: `
    <app-dialog
      *ngIf="dialogOpen"
      (cancel)="closeDialog()"
      [title]="title"
      widthSize="large"
      (commit)="sendMail()"
      [commitButtonText]="commitButtonText"
    >
      <div>
        <div class="mail-address">
          {{ mailAddress }}
        </div>
        <div class="mail-title">
          {{ mailTitle }}
        </div>
        <div class="mail-content">
          <div>{{ mailContent }}</div>
        </div>
      </div>
    </app-dialog>
  `,
  styles: [
    `
      .mail-address {
        font-weight: bold;
      }
      .mail-title {
        font-weight: bold;
        font-size: 1.2em;
      }
      .mail-content {
        white-space: pre-wrap;
      }
    `
  ]
})
export class UraAccountLinkUrlSendMailPreviewDialogComponent {
  public dialogOpen = false
  public title = 'メールプレビュー'
  public commitButtonText = 'メール送信'
  public mailAddress = ''
  public mailContent = ''
  public mailTitle = ''
  public sendMailAddress = ''
  public authenticationUriCd = ''
  public customerCd = ''
  // 前回表示したcustomerCd
  private lastCustomerCd = ''
  // 送信したemit
  @Output() sendMailEmit = new EventEmitter<string>()
  constructor(
    private easyAuthenticationsApiService: EasyAuthenticationsApiService,
    private messageService: MessageService
  ) {}

  async getPreviewData(customerCd: string): Promise<void> {
    if (!customerCd) {
      return
    }

    // 前回表示したcustomerCdと同じ場合は、APIを呼ばずに表示する
    if (this.lastCustomerCd === customerCd) {
      this.dialogOpen = true
      this.customerCd = customerCd
    } else {
      // 初期化
      this.mailAddress = ''
      this.mailContent = ''
      this.mailTitle = ''
      this.authenticationUriCd = ''

      // 前回表示したcustomerCdと異なる場合は、APIを呼び出して表示する
      this.customerCd = customerCd
      const ret$ = this.easyAuthenticationsApiService.PostGetPreviewData(customerCd)
      let ret = await lastValueFrom(ret$)
      this.dialogOpen = true
      console.log(ret)

      if (ret?.resultCode !== 0) {
        return
      }

      this.mailAddress = ret.data.destAddress
      this.mailContent = ret.data.content
      this.mailTitle = ret.data.title
      this.authenticationUriCd = ret.data.authenticationUriCd
    }
  }

  closeDialog(): void {
    this.dialogOpen = false
    this.lastCustomerCd = this.customerCd
    this.customerCd = ''
    console.log('closeDialog')
  }

  async sendMail(): Promise<void> {
    // authUriCdを使ってメール送信APIを呼び出す
    const ret$ = this.easyAuthenticationsApiService.SendCreateAccountRequestMail(this.authenticationUriCd)
    let ret = await lastValueFrom(ret$)
    if (ret?.resultCode !== 0) {
      return
    }
    this.messageService.success('メールを送信しました')
    this.sendMailEmit.emit(this.customerCd)
    this.closeDialog()
  }
}
