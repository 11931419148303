import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { NavMenueItem } from 'src/app/components/nav/nav-menu.interface'
import { NavService } from 'src/app/components/nav/nav.service'
import { environment } from 'src/environments/environment'
@Component({
  selector: 'app-ura-top',
  templateUrl: './ura-top.component.html',
  styleUrls: ['./ura-top.component.scss']
})
export class UraTopComponent implements OnInit {
  loading = false
  constructor(private navService: NavService, private authService: AuthService) {}
  ngOnInit(): void {}

  get menu(): NavMenueItem[] {
    return this.navService.uraMenuList()
  }

  externalUrl(url: NavMenueItem): string {
    const token = this.authService.AccountInfo.token
    let findUrl

    switch (url.methodType) {
      case 1:
        const fragment = `/(token:token-receiver)#t=${token}&r=`
        findUrl = environment.uiLinkPath.organization + fragment
        break
      default:
        const defaultFragment = `/?t=${token}`
        findUrl = environment.uiLinkPath.devTools + defaultFragment
    }

    return findUrl
  }

  checkExternalUrlAuth(naviItem: NavMenueItem): boolean {
    const functionKey = naviItem.functionKey
    const auth = this.authService.AuthViews.find((x) => x.cellId === functionKey)
    // authがある場合はtrueない場合はfalse
    return auth ? true : false
  }
}
