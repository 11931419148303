<div class="search-group">
  <app-search-group-items label="会社名">
    <input
      class="c-form__input"
      type="text"
      [(ngModel)]="searchKey"
      (keyup.enter)="searchCompany()"
      (input)="inputValue($event)"
    />
  </app-search-group-items>
  <app-input-selectbox
    label="都道府県"
    [emptyOption]="true"
    [selectList]="prefectures"
    (selectOption)="changeValue('prefectureCd', $event)"
  ></app-input-selectbox>
  <app-input-text
    label="法人番号(13桁)"
    [maxLength]="13"
    (changeValue)="inputCorporateNumber=$event"
  ></app-input-text>
  <div class="c-btns__item search-disp-btn">
    <a class="js-group-close c-btn" style="cursor: pointer" (click)="searchCompany()">
      検索</a
    >
  </div>
</div>
