<div class="c-form h-margin--36">
  <div class="l-col--1">
    <!-- 1階層目 -->
    <div class="l-col__item" *ngFor="let app of merchandisesTree; let i = index">
      <div class="c-form__checkbox2" [class.is-checked]="app.unitQuantity > 0">
        <a
          *ngIf="isShowAddProductButton(i)"
          (click)="AddProduct(app)"
          class="product-button"
          >＋</a
        >
        <a
        *ngIf="isShowRemoveProductButton(i)"
        (click)="RemoveProduct(i)"
        class="product-button"
        >×</a
      >
        <label>
          <input
            type="checkbox"
            [checked]="app.unitQuantity > 0"
            [disabled]="isDisabledCheckBox(app)"
            (change)="changeSelected(app)"
          />
          <span
            ><b class="c-counter_area">{{ app.merchandiseDisplayName }}</b>
          </span>
        </label>
        <div
          class="c-form__checkbox2__child h-padding--side16 h-padding--b16"
          *ngIf="app.unitQuantity > 0 && app.merchandiseDetails?.length > 0"
        >
          <p class="c-texts--attention">
            下のメニューから該当するオプションを選択、数量を指定してください。
          </p>
          <p class="c-texts--attention" *ngIf="optionTextsAttention(app)">
            {{ optionTextsAttention(app) }}
          </p>
          <!-- 2階層目 -->
          <div
            class="l-col__item"
            *ngFor="let option of app.merchandiseDetails"
          >
            <div
              class="c-form__checkbox2"
              [class.is-checked]="option.unitQuantity > 0"
            >
              <ng-container
                *ngTemplateOutlet="
                  inputTemplate;
                  context: {
                    merchandise: option,
                    parent: app
                  }
                "
              ></ng-container>
              <div
                class="
                  c-form__checkbox2__child
                  h-padding--side16 h-padding--b16
                "
                *ngIf="
                  option.unitQuantity > 0 &&
                  option.merchandiseDetails?.length > 0
                "
              >
                <p
                  class="c-texts--attention"
                  *ngIf="optionTextsAttention(option)"
                >
                  {{ optionTextsAttention(option) }}
                </p>
                <!-- 3階層目 -->
                <div
                  class="l-col__item"
                  *ngFor="let optionChild of option.merchandiseDetails"
                >
                  <div
                    class="c-form__checkbox2"
                    [class.is-checked]="optionChild.unitQuantity > 0"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        inputTemplate;
                        context: {
                          merchandise: optionChild,
                          parent: option
                        }
                      "
                    ></ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #inputTemplate let-merchandise="merchandise" let-parent="parent">
  <label>
    <input
      type="checkbox"
      [checked]="merchandise.unitQuantity > 0"
      [disabled]="isDisabledCheckBox(merchandise, parent)"
      (change)="changeSelected(merchandise, parent)"
    />
    <span
      ><b class="c-counter_area">{{ merchandise.merchandiseDisplayName }}</b>
    </span>
  </label>
  <div
    class="c-form__checkbox2__child h-padding--side16 h-padding--b16"
    *ngIf="merchandise.multiple && merchandise.unitQuantity > 0"
  >
    <p class="c-texts--attention">数量を指定してください。</p>
    <div class="l-col--3 h-margin--t16">
      <div class="l-col__item">
        <div
          class="c-form__checkbox2"
          [class.is-checked]="merchandise.unitQuantity > 0"
        >
          <input type="checkbox" checked="true" />
          <div class="h-padding--side16">
            <div class="h-padding--t16 h-padding--b16 c-texts--center">
              <div class="c-counter c-counter_area">
                <span class="c-counter__label">数量</span>
                <a
                  class=""
                  (click)="changeUnitQuantity(-1, merchandise)"
                  style="cursor: pointer"
                >
                  <i class="c-icon__delete"></i>
                </a>
                <input
                  class="c-counter__area"
                  type="text"
                  [value]="merchandise.unitQuantity"
                  readonly="readonly"
                />
                <a
                  class=""
                  (click)="changeUnitQuantity(1, merchandise)"
                  style="cursor: pointer"
                >
                  <i class="c-icon__add"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
