import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { BackendTagMaster } from 'src/api/common/api-common.interface'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { ProductCdMaster, RetailTagList } from 'src/api/conta-api/conta-api.interface'
import { ContaApiService } from 'src/api/conta-api/conta-api.service'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { retailListMaster } from '../ura-contract-search-list/data'
import { SelectListItem } from '../../../module/myidea-parts/input-selectbox/input-selectbox.interface'

@Injectable({
  providedIn: 'root'
})
export class UraSearchService {
  private TagMaster: BackendTagMaster[]
  private retailListMaster: RetailTagList[]
  private productMaster: ProductCdMaster[]

  constructor(
    private contaApiService: ContaApiService,
    private merchandisesService: MerchandisesApiService
  ) { }

  // プロダクトコードマスター取得
  async productCdMaster(): Promise<ProductCdMaster[]> {
    if (!this.productMaster) {
      const req = [] // 全サービス
      // const ret = await this.contaApiService.GetProductCodeMaster(req).toPromise()
      const ret$ = this.contaApiService.GetProductCodeMaster(req)
      let ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) { return }
      this.productMaster = ret.data
    }
    return this.productMaster
  }

  // プロダクトコードマスター取得
  merchandisesTagMaster(): BackendTagMaster[] {
    let tagMaster: BackendTagMaster[] = []
    this.merchandisesService.GetMerchandisesTagMaster().subscribe((ret: ResultInfo<BackendTagMaster>) => {
      if (ret?.resultCode === 0) {
        tagMaster = ret.data
      }
    })
    this.retailListMaster = retailListMaster
    return tagMaster

  }

  // ◆サービス リスト
  getServiceList(tagMaster): SelectListItem[] {
      const list = this.getSelectListBase()
      this.retailListMaster = retailListMaster
      const purpose = tagMaster.find(x => x.tagPurposeCd === 'service')
      purpose.textTags.forEach(tag => {
        const selectOption: SelectListItem = {
          label: tag.tagText,
          value: tag.tagCd
        }
        list.push(selectOption)
      })
      return list
  }

  // ◆小売 リスト
  getRetailList(tagMaster): SelectListItem[] {
    const list = this.getSelectListBase()
    const purpose = tagMaster.find(x => x.tagPurposeCd === 'retail')
    purpose.textTags.forEach(tag => {
      const selectOption: SelectListItem = {
        label: tag.tagText,
        value: tag.tagCd
      }
      list.push(selectOption)
    })
    return list
  }

  // ◆業態 リスト
  getBusinessCategoryList(retailTagCd: string): SelectListItem[] {
    this.retailListMaster = retailListMaster
    const ret = []
    const targetGroup = this.retailListMaster.find(x => x.retailTagCd === retailTagCd)
    targetGroup?.businessCategoryTags?.forEach(tag => {
      const selectOption: SelectListItem = {
        label: tag.tagText,
        value: tag.tagCd
      }
      ret.push(selectOption)
    })
    return ret
  }

  // プルダウン初期値取得
  getSelectListBase(): SelectListItem[] {
    const ret = []
    const defaultOption: SelectListItem = {
      label: 'すべて',
      value: ''
    }
    ret.push(defaultOption)
    return ret
  }

  // ◆プロダクトコードから製品CD取得 完全一致のみ
  async getProductCdFromInquiryCd(inputValue: string): Promise<string> {
    const productCdMaster = await this.productCdMaster()
    // 先頭の「数字(4文字以上).」を除外
    const cd = inputValue.replace(/^[0-9]{4,}\./, '')
    return productCdMaster?.find(x => x.productInquiryText === cd)?.productCd
  }

  // ◆プロダクトコードからプロダクトナンバー取得 完全一致のみ
  async getProductNumberFromInquiryCd(inputValue: string): Promise<number> {
    const check = await this.getProductCdFromInquiryCd(inputValue)
    if (!check) {
      return null
    }
    return Number(inputValue.split('.')[0])
  }
}
