<app-sending *ngIf="sending"></app-sending>
<app-dialog
  *ngIf="dialogOpen"
  (commit)="update()"
  (cancel)="dialogOpen = false"
  subTitle="※思夢樂事業のコード、ID、パスワードを記載ください"
  title="サービス登録設定"
  widthSize="large"
  commitButtonText="サービス登録確定"
  persistent="false"
  hideCloseButton="true"
>
  <app-input-table [formItems]="formItems"> </app-input-table>
</app-dialog>
