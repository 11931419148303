import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { SelectedChildOrganizationInfo } from './selected-child-organization.model'
import { SelectedChildOrganizationService } from './selected-child-organization.service'
import { MessageService } from 'src/app/components/message/message.service'
import { lastValueFrom } from 'rxjs'

declare const APP
@Component({
  selector: 'app-organization-tree-view',
  templateUrl: './organization-tree-view.component.html',
  styleUrls: ['./organization-tree-view.component.scss']
})
export class OrganizationTreeViewComponent implements OnInit {
  @Input() companyOrganizationCd: string
  @Input() companyOrganizationName: string
  @Input() treeEdit = true
  @Input() sideMenu = true
  @Input() showMenber = true
  @Output() jumpEditTreePage = new EventEmitter<SelectedChildOrganizationInfo>()
  @Output() jumpUserListPage = new EventEmitter<SelectedChildOrganizationInfo>()

  // 組織リストボックス選択用（2階層目の組織）
  public branchlist: SelectedChildOrganizationInfo[]
  public selectedBranch: SelectedChildOrganizationInfo

  // 組織図上の選択組織（3階層目以下の組織）
  public selectedOrganization: SelectedChildOrganizationInfo
  public selectedOrganizationFromList: SelectedChildOrganizationInfo

  inputOrganizationName = ''
  loading: boolean

  // 組織図表示切り替え用
  isShowCustomers = false

  constructor(
    private selectedChildOrganizationService: SelectedChildOrganizationService,
    private router: Router,
    private messageService: MessageService,
    private organizationV2Service: OrganizationV2Service,
  ) { }

  @ViewChild('editNameDialog', { read: IgxDialogComponent, static: false })
  public editNameDialog: IgxDialogComponent

  ngOnInit(): void {
    // 組織図ツリーデータ取得
    this.getOrganizationTreeData(this.companyOrganizationCd)

    // 子組織から戻ってきた場合
    this.selectedOrganizationFromList = this.selectedChildOrganizationService.getSelectedChildOrganization()

    // 変数に保持したらサービスから消す
    const org = new SelectedChildOrganizationInfo()
    org.organizationCd = ''
    org.organizationName = ''
    org.organizationTypeCd = ''
    org.organizationTypeName = ''
    this.selectedChildOrganizationService.setSelectedChildOrganization(org)
  }

  // 組織図ツリーデータ取得
  getOrganizationTreeData(organiationcd) {
    this.loading = true
    this.organizationV2Service.OrganizationTreesWithCustomers(organiationcd).subscribe((ret: any) => {
      if (ret?.resultCode === 0) {

        const retdata = ret.data[0]
        // 最上位組織に子組織がない場合nullになっているので[]をセット
        if (retdata.children === null) {
          retdata.children = []
        }

        this.selectedBranch = retdata
        // 子組織が1件もない場合

        // 選択組織の設定
        // 組織が1件もない場合
        if (this.selectedBranch.children.length === 0) {
          this.selectedOrganization = JSON.parse(JSON.stringify(this.selectedBranch))
          this.selectedOrganization.organizationCd = ''
          this.selectedOrganization.organizationName = ''
          this.selectedOrganization.organizationTypeCd = ''
          this.selectedOrganization.organizationTypeName = ''
          this.selectedOrganization.children = []
        } else {
          this.selectedOrganization = this.selectedBranch.children[0]
        }
        // 子組織から戻ってきた場合
        if (this.selectedOrganizationFromList !== undefined) {
          if (this.selectedOrganizationFromList.organizationCd !== '') {
            // なぜか直接入れると不一致になる・・
            // this.selectedOrganization = this.selectedOrganizationFromList;
            this.searchSection(this.selectedBranch.children, this.selectedOrganizationFromList)
          }
        }
        this.loading = false
      }
      APP.controller.reset()
    })
  }
  searchSection(items, target) {
    const targetCd = target.organizationCd
    items.forEach(x => {
      if (x.organizationCd === targetCd) {
        this.selectedOrganization = x
        return
      }
      if (x.children.length > 0) {
        this.searchSection(x.children, target)
      }
    })
  }
  // 名前変更ダイアログオープン
  openEditOrganizationNameDialog(): void {
    this.inputOrganizationName = this.selectedOrganization.organizationName
    this.editNameDialog.open()
  }

  // 組織名変更
  async updateOrganizationName(): Promise<void> {
    const pre = {
      organizationName: this.inputOrganizationName,
      organizationTypeCd: this.selectedOrganization.organizationTypeCd
    }
    if (this.loading) { return }
    this.loading = true
    // const ret = await this.organizationV2Service.PutOrganizationTrees(this.selectedOrganization.organizationCd, pre).toPromise()
    const ret$ = this.organizationV2Service.PutOrganizationTrees(this.selectedOrganization.organizationCd, pre)
    let ret = await lastValueFrom(ret$)

    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('名前を変更しました')
    this.selectedOrganization.organizationName = this.inputOrganizationName
    APP.controller.reset()
    this.editNameDialog.close()
  }

  // 組織クリック
  clickOrgBox(targetOrg): void {
    this.selectedOrganization = targetOrg
  }

  editOrganization(): void {
    this.selectedChildOrganizationService.setSelectedChildOrganization(this.selectedOrganization)
    this.jumpEditTreePage.emit(this.selectedOrganization)
  }

  // 配属メンバーを見る
  gotoMemberList(): void {
    // 子組織が一つもなく選択がない場合
    if (this.selectedOrganization.organizationCd === '') {
      this.selectedOrganization.organizationCd = '-1'
    }
    this.selectedChildOrganizationService.setSelectedChildOrganization(this.selectedOrganization)
    this.jumpUserListPage.emit(this.selectedOrganization)
  }
  // 所属メンバーを見る
  gotoCompanyMemberList() {
    this.selectedOrganization.organizationCd = '-1'
    this.selectedChildOrganizationService.setSelectedChildOrganization(this.selectedOrganization)
    this.jumpUserListPage.emit(this.selectedOrganization)
  }
  getTypeColor(org): string {
    if (org === undefined) { return }
    // 会社
    if (org.organizationTypeCd === 'organization_type-company') {
      return '#E0F2F1'// '#E3F2FD'
    }
    // 部署
    if (org.organizationTypeCd === 'organization_type-section') {
      return '#e3f8fd'// '#E0F7FA'
    }
    // ワークグループ
    if (org.organizationTypeCd === 'organization_type-work_group') {
      return '#f0fff0'
    }
    // ロール
    if (org.organizationTypeCd === 'organization_type-role') {
      return '#fce6fa' // '#F3E5F5'
    }
  }
  getTypeHeight(org): string {
    if (org === undefined) { return }
    // 会社
    if (org.organizationTypeCd === 'organization_type-company') {
      return '58px'
    }
    // 部署
    if (org.organizationTypeCd === 'organization_type-section') {
      return '58px'
    }
    // ワークグループ
    if (org.organizationTypeCd === 'organization_type-work_group') {
      return '58px'
    }
    // ロール
    if (org.organizationTypeCd === 'organization_type-role') {
      return '38px'
    }
  }
  getTypeLineHeight(org): string {
    if (org === undefined) { return }
    // 会社
    if (org.organizationTypeCd === 'organization_type-company') {
      return 'calc(100% - 58px);'
    }
    // 部署
    if (org.organizationTypeCd === 'organization_type-section') {
      return 'calc(100% - 58px);'
    }
    // ワークグループ
    if (org.organizationTypeCd === 'organization_type-work_group') {
      return 'calc(100% - 58px);'
    }
    // ロール
    if (org.organizationTypeCd === 'organization_type-role') {
      return 'calc(100% - 38px);'
    }
  }
}
