import { Component, OnInit, ViewChild } from '@angular/core'
import { ContractCompany, EstimateDocumentStatus } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { CreateAccountUrlDialogComponent } from '../components/create-account-url-dialog/create-account-url-dialog.component'
import { EstimateApiService } from 'src/api/estimates-api/estimates-api.service'

@Component({
  selector: 'app-ura-estimate-list-new',
  templateUrl: './ura-estimate-list-new.component.html',
  styleUrls: ['./ura-estimate-list-new.component.scss']
})
export class UraEstimateListNewComponent implements OnInit {
  title = '見積一覧【新規】'
  data: EstimateDocumentStatus[] = []
  columns: Header[] = [
    {
      key: 'estimateDocumentCd',
      label: 'CD',
      hidden: true
    },
    {
      key: 'companyCorporateNumber',
      label: '法人番号',
      width: '10%'
    },
    {
      key: 'companyFomalName',
      label: '会社',
      width: '16%'
    },
    {
      key: 'sectionOrganizationName',
      label: '部署',
      width: '13%'
    },
    {
      key: 'contractCustomerName',
      label: '送付先',
      width: '10%',
    },
    {
      key: 'estimateDocumentNo',
      label: '見積No.',
      width: '10%'
    },
    {
      key: 'totalRunningPrice',
      label: '月額費用',
      width: '10%',
      dataType: 'number'
    },
    {
      key: 'lastSendDatetimeUi',
      label: '見積送信日時',
      width: '10%',
    },
    {
      key: 'applicationDatetimeUi',
      label: '申込み日',
      width: '10%',
      style: {
        textAlign: "center"
      }
    },
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '見積確認',
      actionCd: 'actionCd1',
      nextPage: ['/ura-add-new-contract-detail/{estimateDocumentCd}']
    },
    {
      label: 'アカウント登録URL発行',
      actionCd: 'actionCd2',
    },
  ]
  loading = true
  sending = false
  sendingRows: ContractCompany[]
  @ViewChild(CreateAccountUrlDialogComponent, { static: false })
  private dialog: CreateAccountUrlDialogComponent

  constructor(
    private estimateApiService: EstimateApiService
  ) { }

  ngOnInit(): void {
    this.getList()
  }

  actionCommit(event: ActionTargetInfo): void {
    if (event.actionCd === 'actionCd2') {
      const row = event.rows[0]
      this.dialog.getAccountPath(row.contractCustomerCd, row.contractCustomerName, row.companyOrganizationName)
    }
  }

  async getList(): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.estimateApiService.getEstimateDocumentStatusList()
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.data.forEach(item => {
      item.actionCds = ['actionCd1']
      if (!item.isRegistedAccount) {
        item.actionCds.push('actionCd2')
      }
    })
  }
}
