<app-main-contents>
    <app-title [title]="title"></app-title>
    <app-estimate-breadcrumbs
      [index]="index"
    ></app-estimate-breadcrumbs>
    <h2 class="c-texts--head2 h-margin--b24">
      対象となる小売名を1つ選択してください。
    </h2>
    <app-loading *ngIf="loading"></app-loading>
    <app-quotation-selecter
      [tags]="tags"
      [tagCd]="selectedTagCd"
      (changeCd)="selectedTagCd = $event"
    ></app-quotation-selecter>
    <app-under-btn-disp [actionButtons]="actionButtons"></app-under-btn-disp>
  </app-main-contents>
  