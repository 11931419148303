import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-service-policy',
  templateUrl: './service-policy.component.html',
  styleUrls: ['./service-policy.component.scss']
})
export class ServicePolicyComponent implements OnInit {
  @Output() checkAction = new EventEmitter<boolean>()
  riyoukiyaku: SafeResourceUrl
  checkedPolicy = false

  constructor(
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    // this.riyoukiyaku = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.riyoukiyakuUrl)
    this.riyoukiyaku = this.domSanitizer.bypassSecurityTrustResourceUrl(`${location.protocol}//${location.host}/assets/pdf/riyoukiyaku.pdf`)

  }
  changeCheckPolicy(){
    if (this.checkedPolicy){
      this.checkAction.emit(false)
    } else {
      this.checkAction.emit(true)
    }
  }
}
