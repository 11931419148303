import { Component, OnInit, ViewChild } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { Product } from 'src/api/products-api/products-api.interface'
import { ProductsApiService } from 'src/api/products-api/products-api.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { ValidatorService } from 'src/app/module/common-parts/input-table/validator.service'
import { ActionTargetInfo, GridActionButton } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { ProductEditDialogComponent } from '../components/product-edit-dialog/product-edit-dialog.component'

@Component({
  selector: 'app-ura-product-list',
  templateUrl: './ura-product-list.component.html',
  styleUrls: ['./ura-product-list.component.scss']
})
export class UraProductListComponent implements OnInit {
  @ViewChild(ProductEditDialogComponent)
  private dialogComp: ProductEditDialogComponent
  title = '製品一覧'
  data: Product[] = []
  columns: Header[] = [
    {
      key: 'productCd',
      label: '製品CD',
      sort: true,
    },
    {
      key: 'productName',
      label: '製品名',
      sort: true,
    },
    {
      key: 'createdDatetime',
      label: '作成日',
      sort: true,
    },
    {
      key: 'updatedDatetime',
      label: '更新日',
      sort: true
    },
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '新規登録',
      actionCd: 'create',
      notRowAction: true
    },
    {
      label: '確認',
      actionCd: 'view',
    },
    {
      label: '削除',
      actionCd: 'remove',
    },
  ]
  loading = true
  sending = false

  constructor(
    private messageService: MessageService,
    private validatorService: ValidatorService,
    private productsApiService: ProductsApiService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
    this.getProductList()
  }
  async getProductList(): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.productsApiService.GetFlatList()
    if (ret?.resultCode !== 0) { return }

    const data = ret.data
    data.forEach((item) => {
      if (item.createdDatetime) {
        item.createdDatetime =
          this.validatorService.momentFormatDate(
            item.createdDatetime,
            'YYYY/MM/DD'
          )
      }
      if (item.updatedDatetime) {
        item.updatedDatetime =
          this.validatorService.momentFormatDate(
            item.updatedDatetime,
            'YYYY/MM/DD'
          )
      }
      item.actionCds = ['view', 'remove']
    })
    this.data = data
    this.loading = false
  }

  actionCommit(event: ActionTargetInfo): void {
    if (event.actionCd === 'remove') {
      this.confirmService.confirm(`本当に削除しますか？`, '', () => {
        this.removeProducts(event.rows)
      })
    }
    if (event.actionCd === 'create') {
      this.openCreateDialog()
    }
    if (event.actionCd === 'view') {
      this.openUpdateDialog(event.rows[0].productCd)
    }
  }

  async removeProducts(rows: Product[]): Promise<void>{
    const productCds: string[] = []
    rows.forEach(row => {
      productCds.push(row.productCd)
    })
    if (this.sending) { return }
    this.sending = true
    // const ret = await this.productsApiService.RemoveProducts(productCds).toPromise()
    const ret$ = this.productsApiService.RemoveProducts(productCds)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00013)
    this.getProductList()
  }

  openUpdateDialog(productCd: string): void{
    this.dialogComp.openUpdateDialog(productCd)
  }

  openCreateDialog(): void{
    this.dialogComp.openCreateDialog()
  }

  addProduct(newProduct: Product): void {
    this.data.unshift(newProduct)
  }

  updateProduct(updateProduct: Product): void {
    this.data.forEach(product => {
      if (product.productCd === updateProduct.productCd) {
        product.productName = updateProduct.productName
      }
    })
  }
}
