import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit
} from '@angular/core'
import { Subscription } from 'rxjs'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { environment } from 'src/environments/environment'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { AccountInfo, Organization } from 'src/app/components/isbeauth/account'
import { AuthoritiesApiService } from 'src/api/authorities-api/authorities-api.service'

declare const APP
declare const window
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public users: any
  public subscription: Subscription
  public organizationForAdminSubs: Subscription
  organizationlist: Organization[] = []
  selectedOrganization: any
  organizationName: string
  isNoHeader = false
  loginUrl: string
  isAppCd: string
  core: any
  organizationCd: any
  accountId: any
  activatedRouteData: any
  title: string
  login = false
  breakPointWide = 1193
  firstLoad = true
  superAdminMode = false

  constructor(
    private authService: AuthService,
    private authoritiesApiService: AuthoritiesApiService,
  ) {
  }

  @Input() isNoLogin = false
  @Input() headerType = 1
  @ViewChild('dialog', { read: IgxDialogComponent, static: false })
  public dialog: IgxDialogComponent
  @ViewChild('dialog2', { read: IgxDialogComponent, static: false })
  public dialog2: IgxDialogComponent

  ngOnInit(): void {
    const s = this
    // 既に取得済のアカウント情報を初期表示
    s.users = s.authService.AccountInfo
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    s.subscription = s.authService.accountInfoSource$.subscribe(msg => {
      s.users = msg
      s.login = true
      s.organizationlist = s.users.organizations
      // 組織選択情報を取得
      s.selectedOrganization = s.users.selectedOrganizationCd
      if (s.selectedOrganization) {
        s.organizationName = s.organizationlist.find(x => x.organizationCd === s.selectedOrganization).organizationName
      }
      APP.controller.reset()
      this.superAdminMode = msg.adminAcoountInfo?.accountId ? true : false
    })
  }

  get ura(): boolean {
    return this.headerType === 9
  }

  get loginAccount(): AccountInfo {
    return this.authService.AccountInfo
  }

  ngOnDestroy() {
    // サブスクライブ解除
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  dialogOpen(): void {
    this.dialog.open()
    this.sendSelectedOrganization()
  }
  sendSelectedOrganization(): void {
    // 保存組織選択情報を保存
    const that = this
    const isbeLoginPath = environment.isbeLoginBaseUrl
    const isbeLoginScriptPath = environment.isbeLoginBaseUrl + environment.isbeSelectOrganizationScript
    const isbeLoginScriptElem = document.createElement('script')
    isbeLoginScriptElem.setAttribute('src', isbeLoginScriptPath)
    document.head.appendChild(isbeLoginScriptElem)
    isbeLoginScriptElem.onload = function() {
      that.loginUrl = isbeLoginPath
      that.isAppCd = 'myidea.ui'
      that.organizationCd = that.selectedOrganization
      that.accountId = that.users.accountId
      that.core = window.BeSelectOrganizationCore
      that.core.save(that)
      isbeLoginScriptElem.onload = null
    }
  }
  onDialogOKSelected(dialog) {
    // serviceに保存
    this.authService.updateSelectedOrganizationCd(
      this.selectedOrganization
    )
    this.sendSelectedOrganization()

    // this.organizationName = this.selectedOrganization.organizationName
    this.organizationName = this.organizationlist.find(
      x => x.organizationCd == this.selectedOrganization
    ).organizationName

    dialog.close()
  }

  ngAfterViewInit(): void {
    APP.controller.reset()
  }

  logout(): void {
    this.authService.logout()
  }

  // 管理者向け表示終了、通常表示に切り替え
  finishAdminMode(): void {
    this.authService.resetSuperAdminMode()
  }

  /**
   * 小さい画面時のメニューを閉じる。
   *
   * 閉じない理由がわからないので暫定対応。
   */
  menuControl() {
    if (document.getElementsByClassName('js-menu-trigger is-open').length) {
      APP.controller.common.menu.close()
    } else if (window.innerWidth > this.breakPointWide && this.firstLoad) {
      APP.controller.common.menu.close()
    }
    else {
      APP.controller.common.menu.open()
    }
    this.firstLoad = false
  }
}
