import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { CompanyCustomer, User } from './mail-input.model'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { BackendOrganizationV2, OrganizationCustomer, OrganizationFullName } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { BackendOrganizationCustomer, CustomerUi } from 'src/api/customers-api/customers-api.interface'
import { MessageService } from 'src/app/components/message/message.service'
import { messageList } from 'src/app/components/message/message-data'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { Router } from '@angular/router'
import { DeepCopy } from 'src/app/common/common'
import { CompanyOrganization } from 'src/api/organization-company-api/organization-company-api.interface'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'

@Component({
  selector: 'app-mail-input',
  templateUrl: './mail-input.component.html',
  styleUrls: ['./mail-input.component.scss']
})
export class MailInputComponent implements OnInit {
  @Input() addUserListDefault: OrganizationCustomer[] = []
  @Input() dispMyCampany = true
  @Output() changeSelect = new EventEmitter<OrganizationCustomer[]>()
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  organizationCd: string // 自社CD
  organizationName: string // 自社組織名称
  userList: OrganizationCustomer[] = []
  addUserList: OrganizationCustomer[] = []
  organizationList: CompanyOrganization[]
  searchKeyword = ''
  searchCompanyName = ''
  inputMode = false
  showSearchResult = false
  showSearchCompanyResult = false
  submitUserCompany = false
  selectedOrganization: CompanyOrganization // 選択中の他社組織
  formItems = [
    {
      key: 'mailAddress',
      label: 'メールアドレス',
      readonly: true
    },
    {
      key: 'customerName',
      label: 'お名前',
      group: [
        {
          key: 'familyName',
          label: '姓',
          placeholder: '例：山田',
          valid: {
            required: true
          }
        },
        {
          key: 'firstName',
          label: '名',
          placeholder: '例：太郎',
          valid: {
            required: true
          }
        }
      ]
    },
    {
      key: 'organizationFullName',
      label: '会社名',
      readonly: true
    }
  ]
  formItemTemp = [
    {
      key: 'mailAddress',
      label: 'メールアドレス',
      placeholder: '例：myidea@interserve.co.jp',
      readonly: false,
      valid: {
        required: true,
        mailAddress: true
      }
    },
    {
      key: 'customerName',
      label: 'お名前',
      readonly: true
    },
    {
      key: 'organizationFullName',
      label: '会社名',
      readonly: true
    }
  ]
  formValue = null
  searchOrganizationArea = []
  searchMyOrganization = true // 自社内検索：true、他社検索：false
  selectedSearchOrganizationCd = null
  organizationCustomerList: CompanyCustomer[] = [] // 組織ごとの顧客一覧
  customerList: OrganizationCustomer[] = []

  // 別組織に同一顧客を見つけた場合
  suggestionCustomer = false
  suggestionCustomerInfo: CustomerUi
  suggestionCustomerCompany: OrganizationFullName
  addMailAddressFlag = false
  selectedUser: OrganizationCustomer
  timer = null

  @ViewChild('cancelDialog', { static: true })
  cancelDialog: IgxDialogComponent



  constructor(
    private router: Router,
    private organizationCompanyApiService: OrganizationCompanyApiService,
    private organizationV2Service: OrganizationV2Service,
    private authService: AuthService,
    private customerService: CustomerService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.searchKeyword = ''
    this.addUserList = this.addUserListDefault
    if (!this.dispMyCampany) {
      this.searchMyOrganization = false
      return
    }
    this.setOrganizationCd(this.authService.AccountInfo?.selectedOrganizationCd)
  }

  // 顧客一覧の取得が終わっているか
  get customerListLoading(): boolean {
    if (this.organizationCustomerList.find(x => x.organizationCd === this.selectedSearchOrganizationCd)?.customers) { return false }
    return true
  }

  // 自社組織CDを更新
  setOrganizationCd(organizationCd: string): void {
    this.organizationCd = organizationCd
    this.getCustomerList(organizationCd)
    this.setMyCompanyName()
    // 自社内を選択中の場合は検索結果を更新する
    if (this.searchMyOrganization) {
      this.selectedSearchOrganizationCd = organizationCd
    }
  }

  searchCustomerList(): void {
    let selectOrgCustomers = this.organizationCustomerList.find(x => x.organizationCd === this.selectedSearchOrganizationCd)?.customers
    if (!selectOrgCustomers) {
      this.customerList = []
      return
    }
    // 選択済の対象を除外
    this.addUserList.forEach(addUser => {
      selectOrgCustomers = selectOrgCustomers.filter(x =>
        !(x.customerCd === addUser.customerCd && x.organizationCd === addUser.organizationCd))
    })
    // キーワードに一致するもの以外を除外
    if (this.searchKeyword) {
      selectOrgCustomers = selectOrgCustomers.filter(x =>
        x.mailAddress?.split('.')?.join('\.')?.match(this.searchKeyword.split('.').join('\.'))
        || x.familyName?.match(this.searchKeyword)
        || x.firstName?.match(this.searchKeyword)
      )
    }
    this.customerList = selectOrgCustomers.slice(0, 4)
    return
  }

  searchUserList(): void {
    this.searchKeyword = this.searchKeyword.replace(/[\p{C}\p{Z}]/gu, '') // スペース入れない

    if (this.searchKeyword.replace('@', '').replace('@', '.').length <= 1) {
      this.customerList = []
      return
    }
    this.searchCustomerList()
    this.showSearchResult = true
  }

  // 一覧から選択したユーザーをリストに追加
  selectUser(user: OrganizationCustomer): void {
    if (user.isTempMigration) {
      const userData = DeepCopy(user)
      this.selectedUser = userData
      this.formValue = {
        customerName: userData.familyName + userData.firstName,
        organizationFullName: userData.organizationFullName
      }
      this.addMailAddressFlag = true
      this.inputMode = true
      this.searchCompanyName = ''
      this.inputTable.setForm(this.formValue, this.formItemTemp)
    } else {
      // 重複チェック customerCd
      const find = this.addUserList.find(x => x.customerCd === user.customerCd && x.organizationCd === user.organizationCd)
      if (!find) {
        // 配列に加える
        this.addUserList.push(user)
        this.changeSelect.emit(this.addUserList)
        // 選択済アドレスを一覧から削除
        this.userList = this.userList.filter(x => !(x.customerCd === user.customerCd && x.organizationCd === user.organizationCd))
      }
      this.searchUserList()
    }
  }

  // 指定した要素をリストから削除
  removeFromList(user: OrganizationCustomer): void {
    this.addUserList = this.addUserList.filter(x => !(x.customerCd === user.customerCd && x.organizationCd === user.organizationCd))
    this.changeSelect.emit(this.addUserList)
    this.searchCustomerList()
  }

  // 入力されたアドレスを判定
  async submitSearchKeyword(): Promise<void> {
    if (this.searchKeyword === '.') {
      this.customerList = []
      return
    }
    this.suggestionCustomer = false
    // ヒットなしの場合は入力欄表示
    if (this.customerList.length || this.searchKeyword.length <= 1) { return }

    const ret = await this.customerService.SearchByMailAddress(this.searchKeyword)
    if (ret?.resultCode !== 0) { return }
    if (!ret.data.length) {
      this.formValue = {
        mailAddress: this.searchKeyword,
        organizationFullName: !this.searchMyOrganization ? this.selectedOrganization?.organizationName : this.organizationName
      }
      this.inputMode = true
      this.searchCompanyName = ''
      this.addMailAddressFlag = false
      this.inputTable.setForm(this.formValue, this.formItems)
    }
    else {
      // 別組織に同一顧客がいるのでサジェスチョン表示
      this.suggestionCustomerInfo = ret.data[0]
      this.setSuggestionCustomerCompany(this.suggestionCustomerInfo.customerCd)
      this.suggestionCustomer = true
    }
  }

  // 手入力から戻る
  returnForm(): void {
    this.inputMode = false
  }

  // 手入力したユーザーをリストに追加　customerCdなし
  addNewUser(): void {
    const value = this.inputTable.submit()
    if (!value) {
      return
    }
    const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
    if (!reg.test(this.searchKeyword)) {
      this.messageService.templeteMessage(messageList.M00004)
      return
    }
    const newUser: OrganizationCustomer = {
      organizationCd: this.selectedSearchOrganizationCd,
      organizationFullName: !this.searchMyOrganization ? this.selectedOrganization.organizationFormalName : this.organizationName,
      mailAddress: this.searchKeyword,
      familyName: value.familyName,
      firstName: value.firstName,
    }
    this.addUserList.push(newUser)
    this.changeSelect.emit(this.addUserList)
    // 入力欄を戻す
    this.inputMode = false
    this.searchKeyword = ''
  }

  // 手入力したユーザーをリストに追加　customerCdあり、メールアドレス変更
  addMigrationUser(): void {
    const value = this.inputTable.submit()
    if (!value) {
      return
    }
    const newUser: OrganizationCustomer = {
      organizationCd: this.selectedUser.organizationCd,
      organizationFullName: this.selectedUser.organizationFullName,
      customerCd: this.selectedUser.customerCd,
      mailAddress: value.mailAddress,
      familyName: this.selectedUser.familyName,
      firstName: this.selectedUser.firstName,
    }
    this.addUserList.push(newUser)
    this.changeSelect.emit(this.addUserList)
    // 入力欄を戻す
    this.inputMode = false
    this.searchKeyword = ''
  }

  // 手入力したユーザーをリストに追加
  addSuggestionUser(): void {
    const newUser: OrganizationCustomer = {
      organizationCd: this.suggestionCustomerCompany.organizationCd,
      organizationFullName: this.suggestionCustomerCompany?.organizationFullName,
      customerCd: this.suggestionCustomerInfo.customerCd,
      mailAddress: this.searchKeyword,
      familyName: this.suggestionCustomerInfo.familyName,
      firstName: this.suggestionCustomerInfo.firstName,
    }
    this.addUserList.push(newUser)
    this.changeSelect.emit(this.addUserList)
    this.searchKeyword = ''
    this.suggestionCustomer = false
  }

  // 顧客一覧検索
  getCustomerList(organizationCd: string): void {
    if (!organizationCd) { return }
    // 既に顧客一覧取得済の組織の場合はスルー
    if (this.organizationCustomerList.find(x => x.organizationCd === organizationCd)?.customers?.length) { return }
    this.organizationV2Service.GetOrganizationCustomerList(organizationCd)
      .subscribe((ret: ResultInfo<OrganizationCustomer>) => {
        if (ret?.resultCode !== 0) { return }
        this.organizationCustomerList = this.organizationCustomerList.filter(x => x.organizationCd !== organizationCd)
        const newItem: CompanyCustomer = {
          organizationCd,
          customers: ret.data
        }
        this.organizationCustomerList.push(newItem)

        // 既に顧客選択肢のパネルを開いていた時は、リストを表示
        if (this.showSearchResult) {
          this.searchUserList()
        }
      })
  }

  // 会社一覧検索
  async searchCompany(): Promise<void> {
    // 組織のlike検索
    if (this.searchCompanyName?.length >= 2) {
      // タイマーをクリア
      clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        this.organizationList = []
        const ret = await this.organizationCompanyApiService.LikeSearch({ organizationName: this.searchCompanyName })
        if (ret?.resultCode !== 0) { return }
        // 自社を除外
        ret.data = ret.data.filter(x => x.organizationCd !== this.organizationCd)
        this.organizationList = ret.data.slice(0, 4)
        this.showSearchCompanyResult = true
      }, 500)
    }
    else {
      this.organizationList = []
      this.showSearchCompanyResult = false
    }
  }

  // 自会社名称取得
  setMyCompanyName(): void {
    const customerCd = this.authService.AccountInfo.customerCd
    // 末端組織から親組織を取得する為、まず自分の所属組織一覧を取得
    this.organizationV2Service
      .GetOrganizationFullNameList(customerCd, false).subscribe((ret: ResultInfo<OrganizationFullName>) => {
        if (ret?.resultCode !== 0) { return }
        ret.data.forEach(data => {
          if (data.organizationNestCds.find(x => x === this.organizationCd)) {
            // 対象の親組織CDから名称を取得
            this.organizationV2Service.OrganizationInfo(data.organizationNestCds[0])
              .subscribe((ret2: ResultInfo<BackendOrganizationV2>) => {
                if (ret2?.resultCode !== 0) { return }
                this.organizationName = ret2.data[0].organizationFormalName
              })
          }
        })
      })
  }

  // サジェスチョン顧客の会社取得
  setSuggestionCustomerCompany(customerCd: string): void {
    this.organizationV2Service
      .GetOrganizationFullNameList(customerCd, false).subscribe((ret: ResultInfo<OrganizationFullName>) => {
        if (ret?.resultCode !== 0 || !ret?.data[0]) { return }
        this.suggestionCustomerCompany = ret.data[0]
      })
  }

  // 会社を選択
  selectOrganization(organization: CompanyOrganization): void {
    this.selectedOrganization = organization
    this.selectedSearchOrganizationCd = organization.organizationCd
    this.getCustomerList(organization.organizationCd)
  }
  openDetailModal(): void {
    this.cancelDialog.open()
  }
}
