import { JsonPipe } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { ResultInfoSingle } from 'src/api/common/api-common.interface'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { BankMaster, InvoiceData, PaymentMethodAccount } from 'src/api/invoice-leaf/invoice-leaf-api.interface'
import { InvoiceLeafApiService } from 'src/api/invoice-leaf/invoice-leaf-api.service'
import { JoinText,DeepCopy } from 'src/app/common/common'
import { FileManagementService } from 'src/app/common/file-management.service'
import { MessageService } from 'src/app/components/message/message.service'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo, SelectMaster } from 'src/app/module/common-parts/input-table/input-table.model'
import { SelectOption } from 'src/app/module/common-parts/select/select.component.interface'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { InputSearchCompanyComponent } from 'src/app/module/myidea-parts/input-search-company/input-search-company.component'

@Component({
  selector: 'app-ura-payment-method-account-list',
  templateUrl: './ura-payment-method-account-list.component.html',
  styleUrls: ['./ura-payment-method-account-list.component.scss']
})
export class UraPaymentMethodAccountListComponent implements OnInit {
  title = '振替口座一覧'
  data: PaymentMethodAccount[] = []
  columns: Header[] = [
    {
      key: 'organizationPaymentMethodCd',
      label: '口座CD',
      width: '7%'
    },
    {
      key: 'corporateNumber',
      label: '法人番号',
      width: '9%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'organizationFormalName',
      label: '会社名',
      width: '13%'
    },
    {
      key: 'bankNo',
      label: '番号',
      width: '5%',
      groupLabel: '銀行',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'bankName',
      label: '名称',
      width: '8%',
      groupLabel: '銀行'
    },
    {
      key: 'branchNo',
      label: '番号',
      width: '5%',
      groupLabel: '支店',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'branchName',
      label: '名称',
      width: '8%',
      groupLabel: '支店'
    },
    {
      key: 'accountTypeUi',
      label: '預金種目',
      width: '8%',
      groupLabel: '口座',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'accountNo',
      label: '番号',
      width: '6%',
      groupLabel: '口座',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'accountName',
      label: '預金者名',
      width: '10%',
      groupLabel: '口座'
    },
    {
      key: 'suppliCustomerIdsUi',
      label: '請求先No.',
      width: '10%'
    },
    {
      key: 'createDateUi',
      label: '作成日',
      width: '7%',
      style: {
        textAlign: 'center'
      }
    }
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '更新',
      actionCd: 'showDetail',  
    },
    {
      label: '新規',
      actionCd: 'create',
      notRowAction: true
    }
  ]
  loading = true
  sending = false
  cellClasses = {}
  selectedRow: PaymentMethodAccount = null
  dialogOpen = false
  accountTypeMaster: SelectMaster[] = [
    { value: '1', label: '普通' },
    { value: '2', label: '当座' },
    { value: '9', label: 'その他' }
  ]
  @ViewChild('inputTable', { read: InputTableComponent, static: false })
  public inputTable: InputTableComponent
  @ViewChild('inputSearchCompany', {read:InputSearchCompanyComponent, static: false })
  public inputSearchCompany: InputSearchCompanyComponent

  formItems: FormInputInfo[] = [
    {
      key: 'bankNo',
      label: '銀行番号',
      placeholder: '例：0001',
      caption: "数字4桁(ゼロ埋め)",
      valid: {
        required: true,
        maxLength: 4,
        minLength: 4,
        numOnly: true
      },
      event: {
        keyUp: true
      }
    },
    // {
    //   key: 'bankName',
    //   label: '銀行名',
    //   readonly: true,
    // },
    {
      key: 'branchNo',
      label: '支店番号',
      placeholder: '例：001',
      caption: "数字3桁",
      valid: {
        required: true,
        maxLength: 3,
        minLength: 3,
        numOnly: true
      },
      event: {
        keyUp: true
      }
    },
    {
      key: 'accountType',
      label: '預金種目',
      type: 'select',
      master: this.accountTypeMaster
    },
    {
      key: 'accountNo',
      label: '口座番号',
      placeholder: '例：0000001',
      caption: "数字7桁",
      valid: {
        required: true,
        maxLength: 7,
        minLength: 7,
        numOnly: true
      }
    },
    {
      key: 'accountName',
      label: '預金者名',
      placeholder: '例：ｶ)ｲﾝﾀ-ｻ-ﾌﾞ',
      caption: "半角カナ、大文字英数字 【利用可能な記号】( ) . -(ハイフン)",
      valid: {
        required: true,
        maxLength: 30,
        bankAccountName: true
      }
    }
  ]
  eventExecuted: boolean = false;
  bankMaster: any[] = []
  selectedOrganizationCd: string = ''
  constructor(
    private messageService: MessageService,
    private invoiceLeafApiService: InvoiceLeafApiService,
    private fileManagementService: FileManagementService,
    private invoiceApiService: InvoiceService
  ) { }

  ngOnInit(): void {
    this.getList()
    this.invoiceLeafApiService.GetBankMaster().then(ret => {
      this.bankMaster = ret.data
    })
    this.getInvoiceTarget()
  }

  ngAfterViewChecked() {
    if (this.dialogOpen && !this.eventExecuted) {
      setTimeout(() => {
        this.eventExecuted = true
        this.inputTable?.setForm(this.selectedRow.paymentMethodItems)
        this.setBankData(this.selectedRow.paymentMethodItems)
        this.selectedOrganizationCd = this.selectedRow.organizationCd
        console.log('inputSearchCompany', this.inputSearchCompany)
        this.inputSearchCompany.ngOnInit()
      })
    }

    if(!this.dialogOpen && this.eventExecuted){
      this.eventExecuted = false
    }
  }

  async getList(): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.invoiceLeafApiService.GetOrganizationPaymentMethods()
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.data.forEach(item => {
      item.bankNo = item.paymentMethodItems.bankNo
      item.bankName = item.paymentMethodItems.bankName
      item.branchNo = item.paymentMethodItems.branchNo
      item.branchName = item.paymentMethodItems.branchName
      item.accountNo = item.paymentMethodItems.accountNo
      item.accountName = item.paymentMethodItems.accountName
      item.accountType = item.paymentMethodItems.accountType
      item.accountTypeUi = this.accountTypeName(item.accountType)
      item.suppliCustomerIdsUi = JoinText(item.suppliCustomerIds, ', ')
      item.actionCds = ['showDetail']
    })

  }

  accountTypeName(accountType: string): string {
    if (accountType === "1") {
      return "普通"
    }
    else if (accountType === "2") {
      return "当座"
    }
    else if (accountType === "9") {
      return "その他"
    }
    else {
      return ""
    }
  }

  async actionCommit(event: ActionTargetInfo): Promise<void> {
    if (this.sending) { return }
    if (event.actionCd === 'showDetail') {
      this.selectedRow = event.rows[0]
      this.showDialog()
    } else if(event.actionCd === 'create') {
      this.selectedRow = this.newPaymentMethod() 
      this.showDialog()
    }
  }

  async showDialog(): Promise<void> {
    if (!this.selectedRow) { return }
    this.dialogOpen = true
  }
  
  bankNoInput: string = ''
  async changeBankNo(minlengh=4): Promise<void> {
    let prevInput = this.bankNoInput
    setTimeout(() => {
      if (prevInput === this.bankNoInput && this.bankNoInput.length >= minlengh) {
        // ユーザーの入力が止まり、minlengthに達した場合に処理を実行する
        // 入力された値からgetしたbankInfoFromInputが空の場合は、取得しない。
        if(Object.keys(this.bankInfoFromInput).length === 0){
          return
        }
        this.getBankBranchMaster(this.bankNoInput)
        console.log('bankNoInput',this.bankNoInput )
      
      }
    }, 500) // 500ミリ秒待機する
  }

  /**
   * @returns
   * @memberof UraPaymentMethodAccountListComponent
   * @description
   * inputで入力された値からbankMaterを検索して、データを取得する
   */
  get bankInfoFromInput() : BankMaster | {} {
     if (!this.bankNoInput) { return {}}
     const find = this.bankMaster.find(item => item.code === this.bankNoInput)
     return find ? find : {}
  }

   /**
   * @returns
   * @memberof UraPaymentMethodAccountListComponent
   * @description
   * inputで入力された値からbranchMaterを検索して、データを取得する
   */
  get branchInfoFromInput()  : BankMaster | {} {
    // 入力がない場合は、空のオブジェクトを返す
    if (!this.branchNoInput) { return {}}
    const find = this.bankBranchMaster[this.bankNoInput]?.find(item => item.code === this.branchNoInput)
    // 支店情報が見つからない場合は、空のオブジェクトを返す
    return find ? find : {}
  }

  branchNoInput: string = ''
  async changeBranchNo(minlengh=3): Promise<void> {
    let prevInput = this.branchNoInput
    setTimeout(() => {
      if (prevInput === this.branchNoInput && this.branchNoInput.length >= minlengh) {
        // ユーザーの入力が止まり、minlengthに達した場合に処理を実行する
        // console.log('branchNoInput',this.branchNoInput )
      }
    }, 500) // 500ミリ秒待機する
  }
  

  bankBranchMaster:  {
    [bankNo:string]: BankMaster[]
  } = {}
  
  /**
   * 銀行の支店Masterを取得する
   * @param bankCd 銀行コード
   * @returns
   */
  getBankBranchMaster (bankCd: string): void {
    // すでに取得済みの場合は、取得しない
    if (this.bankBranchMaster[bankCd]) {
      return
    }
    this.invoiceLeafApiService.GetBankBranchMaster(bankCd).then(ret => {
      // 取得したデータを保存する
      // dataが空の場合は、空の配列が入る
      if(ret && ret.data)
      this.bankBranchMaster[bankCd] = ret.data
    }).catch(()=> {
      
    })
  }

  /**
   * 
   * @param paymentMethodItems 
   * @description
   * 入力フォームのデータから銀行の情報をセットして
   * 支店の情報を取得する
   */
  setBankData(paymentMethodItems: any): void {
    this.bankNoInput = paymentMethodItems.bankNo
    this.branchNoInput = paymentMethodItems.branchNo
    // 銀行番号変更処理
    this.changeBankNo()
  }
  
 
  async commit(event:any){

    const formValue = this.inputTable.submit();
    const exValid = this.exValidationCheck(this.bankInfoFromInput, this.branchInfoFromInput, this.selectedOrganizationCd);
    if (!formValue || !exValid) {
      return;
    }

    const selectedRow = this.selectedRow;
    const paymentMethodItems = {
      ...DeepCopy(selectedRow.paymentMethodItems),
      bankName: this.bankInfoFromInput['halfWidthKana'],
      branchName: this.branchInfoFromInput['halfWidthKana'],
      ...formValue,
    }
    const payload = {
      organizationPaymentMethodCd: selectedRow.organizationPaymentMethodCd,
      organizationCd:this.selectedOrganizationCd,
      paymentMethodItems,
    }
    
    const actionCd = this.selectedRow['actionCds']?.[0];

    let ret  = null as ResultInfoSingle<PaymentMethodAccount> || null
    if (actionCd === 'showDetail') {
      ret = await this.invoiceLeafApiService.PutOrganizationPaymentMethod(payload);
    } else if (actionCd === 'create') {
      ret = await this.invoiceLeafApiService.PostOrganizationPaymentMethod(payload);
    } else {
      throw new Error('Invalid action code');
    }

    if (ret.resultCode !== 0) {
      this.messageService.error(this.getErrorMessage(actionCd));
      return;
    }

    this.messageService.success(this.getSuccessMessage(actionCd));
    this.dialogOpen = false;
    this.getList();
  } 


  public _bankCdList: string[] = []
  
  
  set bankCdList(value: PaymentMethodAccount[]) {
   const allList = value.map(item => {
      return item.paymentMethodItems.bankNo
    })
    // 重複は省く
    const uniqueList = allList.filter((x, i, self) => {
      return self.indexOf(x) === i
    })
    this._bankCdList = uniqueList
  }

  changeCompanySelect(event: any): void {
    this.selectedOrganizationCd = event?.organizationCd || null
  }

  invoiceTargets: any[] = []
  getInvoiceTarget(){
   this.invoiceApiService.GetInvoiceTargets('1').then(ret => {
     this.invoiceTargets = ret.data
     console.log('invoiceTargets', this.invoiceTargets)
   }) 
  }

  newPaymentMethod(): PaymentMethodAccount{
    return {
      "organizationPaymentMethodCd": null,
      "organizationCd": null,
      "organizationName": "",
      "accountName": "",
      "accountNo": '',
      "accountType": '',
      "accountTypeUi": "",
      "bankName": "",
      "bankNo": "",
      "branchName": "",
      "branchNo": "",
      suppliCustomerIds: [],
      suppliCustomerIdsUi: "",
      actionCds: ["create"],
      "paymentMethodItems": {
        "bankNo": "",
        "bankName": "",
        "branchNo": "",
        "accountNo": "",
        "branchName": "",
        "accountName": "",
        "accountType": '1'
      }
    }
  }

  // TODO: 型定義を修正する
  /** 
  * @description
  * モーダル表示されている、ものが詳細か新規かを判定する
  */
  get showDetail(){
    if(!this.selectedRow) return false
    return this.selectedRow?.actionCds[0] === 'showDetail'
  }

  exValidationCheck(bankInfoFromInput,branchInfoFromInput,organizationCd): boolean {
    // 会社が選択されていない場合は、エラー
    if(!organizationCd){
      this.messageService.error('会社を選択してください。')
      return false
    }
    // 存在しない銀行または支店名の場合は、エラー
   if(!bankInfoFromInput.name || !branchInfoFromInput.name){
      this.messageService.error('存在しない銀行または支店です。')
      return false
    }
    return true
  }

  getErrorMessage(actionCd: 'showDetail' | 'create') {
    switch (actionCd) {
      case 'showDetail':
        return '口座情報の更新に失敗しました。';
      case 'create':
        return '口座情報の登録に失敗しました。';
      default:
        return '未知のエラーが発生しました。';
    }
  }

  getSuccessMessage(actionCd: 'showDetail' | 'create') {
    switch (actionCd) {
      case 'showDetail':
        return '口座情報を更新しました。';
      case 'create':
        return '口座情報を登録しました。';
      default:
        return '操作が成功しました。';
    }
  }
}
