import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { NewApplicationStatusList } from 'src/api/easy-authentications-api/easy-authentications-api.interface'
import { ValidatorService } from 'src/app/module/common-parts/input-table/validator.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
@Component({
  selector: 'app-ura-contract-new-list',
  templateUrl: './ura-contract-new-list.component.html',
  styleUrls: ['./ura-contract-new-list.component.scss'],
})
export class UraContractNewListComponent implements OnInit {
  title = '新規契約一覧'
  data: NewApplicationStatusList[] = []
  columns: Header[] = [
    {
      key: 'easyAuthenticationCd',
      label: 'easyAuthenticationCd',
      sort: false,
      hidden: true,
    },
    {
      key: 'contractCompanyName',
      label: '契約企業',
      sort: true,
    },
    {
      key: 'contractSectionName',
      label: '契約部署',
      sort: true,
    },
    {
      key: 'customerName',
      label: '契約者',
      sort: true,
    },
    {
      key: 'applicationDatetimeUi',
      label: '申込日',
      // label: '申込',
      sort: true,
    },
    {
      key: 'actuateAuthSettingStatusName',
      label: '請求先',
      sort: true,
    },
    {
      key: 'actuateSettingStatusName',
      label: 'サービス稼働設定',
      sort: true,
    },
    {
      key: 'registSuppliStatusName',
      label: 'Suppli登録',
      sort: true,
    },
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '見積一覧へ',
      actionCd: 'actionCd1',
      nextPage: ['/admin-quotation/{easyAuthenticationCd}']
    },
    {
      label: '確定',
      actionCd: 'actionCd2',
      successMessage: true,
      confirmDialog: true,
    },
  ]
  loading = true
  sending = false
  sendingRowKeys: string[][]

  constructor(
    private messageService: MessageService,
    private router: Router,
    private validatorService: ValidatorService,
    private easyAuthenticationsApiService: EasyAuthenticationsApiService
  ) { }

  ngOnInit(): void {
    this.getContractCompanyList()
  }
  getContractCompanyList(): void {
    this.data = []
    this.loading = true
    this.easyAuthenticationsApiService
      .GetNewApplicationStatusList()
      .subscribe((ret: ResultInfo<NewApplicationStatusList>) => {
        if (ret?.resultCode === 0) {
          const data = ret.data
          data.forEach((item) => {
            if (item.createEasyAuthenticationDatetimeUi) {
              item.createEasyAuthenticationDatetimeUi =
                this.validatorService.momentFormatDate(
                  item.createEasyAuthenticationDatetimeUi,
                  'YYYY/MM/DD'
                )
            }
            if (item.createEstiamteDatetimeUi) {
              item.createEstiamteDatetimeUi =
                this.validatorService.momentFormatDate(
                  item.createEstiamteDatetimeUi,
                  'YYYY/MM/DD'
                )
            }
            if (item.sendEstiamteDatetimeUi) {
              item.sendEstiamteDatetimeUi =
                this.validatorService.momentFormatDate(
                  item.sendEstiamteDatetimeUi,
                  'YYYY/MM/DD'
                )
            }
            item.actionCds = ['actionCd1']
          })
          this.data = data
          this.loading = false
        }
      })
  }

  actionCommit(event: ActionTargetInfo): void {

  }
  // ボツ
  send(companyOrganizationCd: string): void {
    this.sending = true
    // this.frimoMigrationsService.SendMail(companyOrganizationCd)
    //   .subscribe((ret: ResultInfo<boolean>) => {
    //     if (ret?.resultCode === 0) {
    //       this.sendingRowKeys = this.sendingRowKeys.filter(x => x[0] !== companyOrganizationCd)
    //       if (!this.sendingRowKeys.length) {
    //         this.messageService.success('Before/After確認依頼を送信しました')
    //         this.sending = false
    //         this.ngOnInit()
    //       }
    //     }
    //   })
  }
  // 確定
  resend(companyOrganizationCd: string): void {
    this.sending = true
    // this.frimoMigrationsService.SendMail(companyOrganizationCd, '1')
    //   .subscribe((ret: ResultInfo<boolean>) => {
    //     if (ret?.resultCode === 0) {
    //       this.sendingRowKeys = this.sendingRowKeys.filter(x => x[0] !== companyOrganizationCd)
    //       if (!this.sendingRowKeys.length) {
    //         this.messageService.success('Before/After確認依頼を再送信しました')
    //         this.sending = false
    //         this.ngOnInit()
    //       }
    //     }
    //   })
  }
}
