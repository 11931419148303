<div class="c-pdf h-margin--t16">
  <iframe [src]="riyoukiyaku">
    <p></p>
  </iframe>
</div>
<div class="c-policy">
<label class="c-form__checkbox2">
  <input type="checkbox" [(ngModel)]="checkedPolicy" (click)="changeCheckPolicy()"/><span
    >利用規約に同意する</span
  >
</label>
</div>