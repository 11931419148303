import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Customer } from 'src/api/customers-api/customers-api.interface'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { InputOrganization } from 'src/app/module/myidea-parts/feature-organization.interface'
import { FeatureOrganizationService } from 'src/app/module/myidea-parts/feature-organization.service'
import { UraNewCompanyCustomerService } from './ura-new-company-customer.service'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'

@Component({
  selector: 'app-ura-new-company-customer',
  templateUrl: './ura-new-company-customer.component.html',
  styleUrls: ['./ura-new-company-customer.component.scss']
})
export class UraNewCompanyCustomerComponent implements OnInit {
  organization: InputOrganization
  customerInput: Customer
  sending = false

  constructor(
    private router: Router,
    private messageService: MessageService,
    private organizationCompanyApiService: OrganizationCompanyApiService,
    private featureOrganizationService: FeatureOrganizationService,
    private uraNewCompanyCustomerService: UraNewCompanyCustomerService,
    private customerService: CustomerService,
    private easyAuthenticationsApiService: EasyAuthenticationsApiService
  ) {}

  ngOnInit(): void {
    this.organization = this.featureOrganizationService.getInputValues()
    if (!this.organization) {
      this.router.navigate([`/ura-company-add`])
    }
    this.customerInput = this.uraNewCompanyCustomerService.getInputValues()
  }

  pageBack(value?: Customer): void {
    this.uraNewCompanyCustomerService.setInputValues(value)
    this.router.navigate([`/ura-new-company-profile`])
  }

  async commit(customer: Customer): Promise<void> {
    if (this.sending) {
      return
    }
    this.sending = true
    // 顧客登録
    const ret1 = await this.customerService.Create([customer])
    if (ret1?.resultCode !== 0) {
      return
    }
    // 会社登録
    const ret2 = await this.organizationCompanyApiService.Create(this.organization, ret1?.data[0].customerCd)
    this.sending = false
    if (ret2?.resultCode !== 0) {
      return
    }
    this.uraNewCompanyCustomerService.clearValues()
    this.featureOrganizationService.clearValues()
    this.easyAuthenticationsApiService
      .CreateTemporaryAccountEmailNotSendFile(ret1?.data[0].customerCd)
      .subscribe((res) => {
        this.messageService.templeteMessage(messageList.M00057)
        this.router.navigate([`/ura-top`])
      })
  }
}
