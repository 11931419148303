<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    minusHeight="30"
    [actionButtons]="actionButtons"
    [cellClasses]="cellClasses"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>

  
  <app-dialog
    *ngIf="dialogOpen"
    [title]="'口座情報'"
    widthSize="large"
    [commitButtonText]="'更新'"
    (cancel)="dialogOpen = false"
    (commit)="commit($event)"
  >
    <app-input-table #inputTable [formItems]="formItems" (keyUpEvent)="setBankData($event)" > 
      <before-rows class="l-table__item">
        <div class="l-table__head">
          <p>
            会社選択
            <span class="c-label--required">*</span>
          </p>
          
        </div>
        <div class="l-table__body">
          <app-input-search-company #inputSearchCompany [selected]="selectedOrganizationCd" [displayOnly]="showDetail" (changeSelect)="changeCompanySelect($event)"></app-input-search-company>
        </div>
      </before-rows>
      <!-- <div class="l-table__item">
        <div class="l-table__head">
          <p>請求先</p>
        </div>
        <div class="t-table__body">
          <select name="" id="">
            <option *ngFor="let invoiceTarget of invoiceTargets">
              {{inputTable.suppliCustomerId}}
            </option>
          </select>
        </div>
      </div>       -->
    </app-input-table>
    <div>
     銀行名： {{bankInfoFromInput?.name}}
    </div>
    <div>
     支店名： {{branchInfoFromInput?.name}}
    </div>
  </app-dialog>

</app-main-contents>
