<div class="l-table">
  <div class="l-table__item">
    <div class="l-table__head">
      <p>備考</p>
    </div>
    <div class="l-table__body">
      <textarea
        class="c-form__textarea"
        [(ngModel)]="value"
        (keyup)="keyup($event)"
        (change)="keyup($event)"
        [rows]="maxRowCount"
      ></textarea>
      <p>{{ dispAlert }}</p>
    </div>
  </div>
</div>
