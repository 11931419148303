import { Component, OnInit } from '@angular/core'
import { MicomelUserAndStatusUi } from 'src/api/micomel-migrations-api/micomel-migrations-api.model'
import { ShimuraGarageMigrationsService } from 'src/api/micomel-migrations-api/micomel-migrations-api.service'
import { sortedItems } from 'src/app/common/common'
import { MessageService } from 'src/app/components/message/message.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
@Component({
  selector: 'app-ura-migration-send-list',
  templateUrl: './ura-migration-send-list.component.html'
})
export class UraMigrationSendListComponent implements OnInit {
  title = '思夢樂Garage申し込み状況'
  data: MicomelUserAndStatusUi[] = []
  columns: Header[] = [
    {
      key: 'companyOrganizationCd',
      label: '',
      hidden: true
    },
    {
      key: 'companyOrganizationId',
      label: '',
      hidden: true
    },
    {
      key: 'customerCd',
      label: '',
      hidden: true
    },
    {
      key: 'parentContractId',
      label: '',
      hidden: true
    },
    {
      key: 'parentActuateId',
      label: '',
      hidden: true
    },
    {
      key: 'contractCompanyName',
      label: '契約企業',
      width: '17%'
    },
    {
      key: 'purchaserName',
      label: '契約者',
      width: '8%'
    },
    // {
    //   key: 'hasBefore',
    //   label: '解約有',
    //   dataType: 'check',
    //   width: '7%'
    // },
    // {
    //   key: 'beforeTotalAmount',
    //   label: '解約',
    //   dataType: 'number',
    //   width: '8%'
    // },
    {
      key: 'afterTotalAmount',
      label: '新規契約',
      dataType: 'number',
      width: '9%'
    },
    // {
    //   key: 'totalAmount',
    //   label: '差額',
    //   dataType: 'number',
    //   width: '8%'
    // },
    {
      key: 'sendMailDatetimeUi',
      label: 'メール送信日時',
      width: '10%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'approvalDatetimeUi',
      label: '申し込み日時',
      width: '10%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス'
    }
  ]
  rowSelection = RowSelection.MULTIPLE
  cellClasses = {}
  actionButtons: GridActionButton[] = [
    {
      label: '申込明細確認',
      actionCd: 'confirm',
      nextPage: ['/ura-shimura-garage-migration-top/{companyOrganizationId}/{parentContractId}/{customerCd}']
    },
    {
      label: 'お申込み依頼を送信',
      actionCd: 'send',
      successMessage: true,
      confirmDialog: true
    }
  ]
  loading = true
  sending = false

  constructor(
    private messageService: MessageService,
    private micomelMigrationsService: ShimuraGarageMigrationsService
  ) {}

  ngOnInit(): void {
    this.getContractCompanyList()
  }
  async getContractCompanyList(): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.micomelMigrationsService.getContractCompanyList()
    this.loading = false
    if (ret?.resultCode !== 0) {
      return
    }
    this.data = sortedItems(ret.data, 'contractCompanyName')
    this.data.forEach((item) => {
      item.actionCds = ['confirm']
      if (item.approvalDatetime === null) {
        item.actionCds.push('send')
      }
    })
  }

  async send(companyOrganizationCds: string[]): Promise<void> {
    this.data = []
    this.sending = true
    const ret = await this.micomelMigrationsService.sendMail(companyOrganizationCds)
    this.sending = false
    if (ret?.resultCode !== 0) {
      return
    }
    this.messageService.success('思夢樂業態Garageの確認依頼を送信しました')
    this.getContractCompanyList()
  }

  actionCommit(event: ActionTargetInfo): void {
    if (this.sending) {
      return
    }
    const shimuraGarageMigrationUserAndStatusIds = []
    event.rows.forEach((row) => {
      shimuraGarageMigrationUserAndStatusIds.push(row.shimuraGarageMigrationUserAndStatusId)
    })
    if (event.actionCd === 'send') {
      this.send(shimuraGarageMigrationUserAndStatusIds)
    }
  }
}
