import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateInvoiceTargetRequest } from 'src/api/invoice-api/invoice-api.interface'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { InvoiceActuate } from 'src/api/invoice-leaf/invoice-leaf-api.interface'
import { InvoiceLeafApiService } from 'src/api/invoice-leaf/invoice-leaf-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { InvoiceUserDialogComponent } from './invoice-user-dialog/invoice-user-dialog.component'

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  title = '請求書参照権限のユーザー管理'
  data: CreateInvoiceTargetRequest[] = []
  columns: Header[] = [
    {
      key: 'invoiceTargetCd',
      label: 'invoiceTargetCd',
      hidden: true
    },
    {
      key: 'organizationCd',
      label: 'organizationCd',
      hidden: true
    },
    {
      key: 'suppliBillingCustomerId',
      label: '請求先ID',
      width: '8%'
    },
    {
      key: 'branchOffice',
      label: '支店名',
      width: '9%'
    },
    {
      key: 'sectionName',
      label: '部署名',
      width: '10%'
    },
    {
      key: 'personName',
      label: '宛先氏名',
      width: '11%'
    },
    {
      key: 'postCode',
      label: '郵便番号',
      width: '8%'
    },
    {
      key: 'prefecture',
      label: '都道府県',
      width: '8%'
    },
    {
      key: 'city',
      label: '市区町村',
      width: '8%'
    },
    {
      key: 'blockName',
      label: '番地',
      width: '12%'
    },
    {
      key: 'telNo',
      label: '電話番号',
      width: '10%'
    },
    {
      key: 'title',
      label: '請求区分',
      width: '12%'
    }
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '参照できるユーザーを確認',
      actionCd: 'detail',
    }
  ]
  loading = true
  sending = false
  actuates :InvoiceActuate[] = []
  cellClasses = {}
  changeInvoiceTarget :CreateInvoiceTargetRequest = null
  @ViewChild('userListDialog', { read: InvoiceUserDialogComponent, static: false })
  public userListDialog: InvoiceUserDialogComponent
  invoiceId = ''

  constructor(
    private authService: AuthService,
    private invoiceService: InvoiceService,
    private invoiceLeafApiService: InvoiceLeafApiService,
  ) { }

  ngOnInit(): void {
    this.getInvoiceTarget()
    this.getActuates()
  }

  // 請求先取得
  async getInvoiceTarget(): Promise<void> {
    const invoiceTargetsRet = await this.invoiceService.GetInvoiceTargetsByCutomer(this.authService.AccountInfo.customerCd)
    if (invoiceTargetsRet?.resultCode !== 0) { return }
    this.data = invoiceTargetsRet.data
    this.data.forEach(item => {
      item.actionCds = ['detail']
    })
    this.loading = false
  }

  // 請求先稼働取得
  async getActuates(): Promise<void> {
    const invoiceActuateRet = await this.invoiceLeafApiService.getActuates(this.authService.AccountInfo.customerCd)
    if (invoiceActuateRet?.resultCode !== 0) { return }
    this.actuates = invoiceActuateRet.data

  }

  actionCommit(event: ActionTargetInfo): void {
    if (event.actionCd === 'detail') {
      const targetRow = event.rows[0]
      const targetActuate = this.actuates.find(x => x.invoiceTargetCd === targetRow.invoiceTargetCd)

      const customerCds: string[] = []
      this.data.forEach(row => {
        customerCds.push(row.customerCd)
      });
      this.invoiceId = targetRow.suppliBillingCustomerId
      this.userListDialog.open(targetActuate.actuateCd, targetRow.organizationCd)
    }
  }
}
