<app-check-server-update></app-check-server-update>
<div *ngIf="activatedRouteData | async as data">
  <app-isbeauth
    *ngIf="!data.isNoLogin"
    [headerType]="data.headerType"
    (endLogin)="endLogin($event)"
  ></app-isbeauth>
  <igx-linear-bar
    *ngIf="loding && !data.isNoLogin"
    type="info"
    class="indeterminate"
    [indeterminate]="true"
    style="position: fixed"
  ></igx-linear-bar>
  <app-header
    #header
    *ngIf="data"
    [isNoLogin]="data.isNoLogin"
    [headerType]="data.headerType"
  ></app-header>
  <app-nav
    *ngIf="dispContent && !data.isNoLogin && data.headerType !== 4"
    [headerType]="data.headerType"
  ></app-nav>
  <main
    (alertAction)="alertAction(notificationList)"
    [class.l-main]="!data.isNoLogin || data.headerType !== 4 "
    [class.l-main--nonlogin]="data.isNoLogin || data.headerType === 4"
    [class.l-main--full]="data.fullWidthContent "
  >
    <app-message></app-message>
    <app-confirm></app-confirm>
    <router-outlet *ngIf="dispContent || data.isNoLogin"></router-outlet>
    <app-is-footer *ngIf="data.isNoLogin"></app-is-footer>
  </main>
  <!-- /main-->
</div>
