import { Injectable } from '@angular/core'
import { AutoMap, DeepCopy } from 'src/app/common/common'
import { SelectedOrganization } from './selected-organization-view.interface'

@Injectable({
  providedIn: 'root'
})
export class SelectedOrganizationViewService {
  // サービスマップのAPIレスポンス
  private keepData: SelectedOrganization

  constructor(
  ) { }

  getSelectedOrganizationData(): SelectedOrganization {
    const target = this.keepData
    return !target ? null : DeepCopy(target)
  }

  setSelectedOrganizationData(setData: SelectedOrganization): void {
    this.keepData = setData
  }


  // 保存済情報をクリア
  clearKeepData(organizationCd: string): void {
    this.keepData = {}
  }
}
