<p class="c-texts--attention h-margin--b16">＊は入力必須項目です</p>
<app-sending *ngIf="sending"></app-sending>

<app-input-table [formItems]="formItems">
  <before-rows
    ><div class="l-table__item">
      <div class="l-table__head">
        <p>契約会社<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <app-input-search-company
          (changeSelect)="updateTargetCompany($event)"
        ></app-input-search-company>
      </div>
    </div>
    <div class="l-table__item border-bottom">
      <div class="l-table__head">
        <p>契約担当者<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <div class="c-form__selectbox" style="width: 463px">
          <select class="c-form__input" [(ngModel)]="selectedCustomerCd">
            <option [selected]="true" value="">選択してください</option>
            <option
              *ngFor="let customer of customers"
              [value]="customer.customerCd"
            >
              {{ customer.organizationFullName }} {{ customer.familyName
              }}{{ customer.firstName }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </before-rows>
  <div class="l-table__item">
    <div class="l-table__head">
      <p>契約開始日<span class="c-label--required">*</span></p>
    </div>
    <div class="l-table__body">
      <igx-date-picker
        [(ngModel)]="contractStartDate"
        displayFormat="yyyy-MM-dd"
        style="margin-right: 20px"
      >
      </igx-date-picker>
    </div>
  </div>
  <div class="l-table__item">
    <div class="l-table__head">
      <p>契約終了日</p>
    </div>
    <div class="l-table__body">
      <igx-date-picker
        [(ngModel)]="contractEndDate"
        displayFormat="yyyy-MM-dd"
        style="margin-right: 20px"
      >
      </igx-date-picker>
    </div>
  </div>
  <div class="l-table__item">
    <div class="l-table__head">
      <p>商品<span class="c-label--required">*</span></p>
    </div>
    <div class="l-table__body">
      <div class="c-form__selectbox" style="width: 463px">
        <select
          class="c-form__input"
          [(ngModel)]="selectedMerchandiseCd"
          (change)="changeMerchandise()"
        >
          <option [selected]="true" value="">選択してください</option>
          <option
            *ngFor="let item of merchandises"
            [value]="item.merchandiseCd"
          >
            {{ item.merchandiseName }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="l-table__item">
    <div class="l-table__head">
      <p>小売</p>
    </div>
    <div class="l-table__body">
      <div class="c-form__selectbox" style="width: 463px">
        <select
          class="c-form__input"
          (change)="changeRetail()"
          [(ngModel)]="selectedRetailTagCd"
          [disabled]="disabledRetailTag"
        >
          <option [selected]="true" value="">選択してください</option>
          <option *ngFor="let tag of retailTags" [value]="tag.tagCd">
            {{ tag.tagText }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="l-table__item">
    <div class="l-table__head">
      <p>業態</p>
    </div>
    <div class="l-table__body">
      <div class="c-form__selectbox" style="width: 463px">
        <select
          class="c-form__input"
          [(ngModel)]="selectedBussinesCategoryTagCd"
          [disabled]="disabledBussinesCategoryTag"
        >
          <option [selected]="true" value="">選択してください</option>
          <option *ngFor="let tag of bussinesCategoryTags" [value]="tag.tagCd">
            {{ tag.tagText }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="l-table__item">
    <div class="l-table__head">
      <p>その他タグ</p>
    </div>
    <div class="l-table__body">
      <app-tag-select-area
        [tags]="otherTags"
        [tagEditMode]="true"
        (update)="updateSelectedTags($event)"
      ></app-tag-select-area>
    </div>
  </div>
</app-input-table>
<app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
