import { Injectable } from '@angular/core'
import { lastValueFrom, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ApiErrorHandlingService } from '../common/api-error-handling.service'
import { ActuateInvocieInfo, InvoiceContractModel, InvoiceTarget, InvoiceTargetMaster, BillinYm, CreateInvoiceTargetRequest, SearchInvoiceTarget, InvitaitonTargetCustomer } from './invoice-api.interface'

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService,
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 請求先設定更新
  async updateContractInvoiceRelations(invoiceTargetCd: string, contractCds: string[], agentInvoiceFlg = false): Promise<ResultInfo<any>> {
    const url = `${this.baseURL}api/ContractInvoiceRelations/InvoiceTarget/${invoiceTargetCd}/Update?agentInvoiceFlg=${agentInvoiceFlg}`
    const ret$ = this.httpRequestService.put<any>(url, contractCds)
    return await lastValueFrom(ret$)
  }

  // 設定済請求先情報の取得
  getActuateInvocieInfoList(
    contractCds: string[]
  ): Observable<ResultInfo<ActuateInvocieInfo>> {
    const url = `${this.baseURL}api/ActuateInvocieInfos/Get`
    return this.httpRequestService.post(url, contractCds)
  }

  // 新規請求先登録・更新
  async invoiceTargetsCreate(payload: CreateInvoiceTargetRequest): Promise<ResultInfoSingle<string>> {
    const url = `${environment.myideaMacroBaseUrl}api/invoice/invoiceTarget/upsert`
    const ret$ = this.httpRequestService.postResultSingle<string>(url, payload)
    return await lastValueFrom(ret$)
  }

  // 会社の組織CDから請求先一覧を取得
  async GetInvoiceTargets(companyOrganizationCd: string, customerCd?: string): Promise<ResultInfo<InvoiceTarget>> {
    let url = `${environment.myideaMacroBaseUrl}api/invoice/invoiceTarget/company/${companyOrganizationCd}`
    if (customerCd) {
      url = `${url}?customerCd=${customerCd}`
    }
    const ret$ = this.httpRequestService.get<InvoiceTarget>(url)
    return await lastValueFrom(ret$)
  }
  
  // 担当者の顧客CDから請求先一覧を取得
  async GetInvoiceTargetsByCutomer(customerCd: string): Promise<ResultInfo<CreateInvoiceTargetRequest>> {
    const url = `${environment.myideaMacroBaseUrl}api/invoice/invoiceTarget/cutomer/${customerCd}`
    const ret$ = this.httpRequestService.get<CreateInvoiceTargetRequest>(url)
    return await lastValueFrom(ret$)
  }

  //   No.202(​/api​/SuppliInvoice​/BillingYearAndMonth)と、
  GetBillingYearAndMonth(): Observable<ResultInfo<BillinYm>> {
    const url = `${this.baseURL}api/SuppliInvoice/BillingYearAndMonth`
    return this.httpRequestService.get(url)
  }

  async GetInvoiceTargetBySuppliCustomerId(suppliCustomerId: string): Promise<ResultInfoSingle<CreateInvoiceTargetRequest>> {
    const url = `${environment.myideaMacroBaseUrl}api/invoice/invoiceTarget/suppliCustomer/${suppliCustomerId}`
    const ret$ = this.httpRequestService.getResultSingle<CreateInvoiceTargetRequest>(url)
    return await lastValueFrom(ret$)
  }

  // /api/Invoices/SearchContracts
  SearchContracts(payload): Observable<ResultInfo<InvoiceContractModel>> {
    const url = `${this.baseURL}api/Invoices/SearchContracts`
    return this.httpRequestService.post(url, payload)
  }
  // No.203(/api/SuppliInvoice/GetSuppliInvoiceCustomerCsvList/{billingYM}?encodeType={sjis or utf8})
  GetSuppliInvoiceCustomerCsvListURL(billingYM: number, encodeType: string): any {
    const url = `${this.baseURL}api/GetSuppliInvoiceCustomerCsvList/${billingYM}?encodeType=${encodeType}`
    return url
  }

  // 請求先マスタの参照
  async searchInvoiceTargets(invoiceTargetCd: string): Promise<ResultInfoSingle<CreateInvoiceTargetRequest>> {
    const url = `${environment.myideaMacroBaseUrl}api/invoice/invoiceTarget/${invoiceTargetCd}`
    const ret$ = this.httpRequestService.getResultSingle<CreateInvoiceTargetRequest>(url)
    return await lastValueFrom(ret$)
  }
  
  // 請求Leafに利用者を追加しメール送信
  async AddUserAndSendMail(invoiceLeafActuateCd: string, customers?: InvitaitonTargetCustomer[]): Promise<ResultInfo<boolean>> {
    const url = `${environment.myideaMacroBaseUrl}api/serviceUser/invoiceLeafActuate/${invoiceLeafActuateCd}/CreateAndSendMail`
    const ret$ = this.httpRequestService.post<boolean>(url, customers)
    return await lastValueFrom(ret$)
  }
}
