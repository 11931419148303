export interface ResultInfo<T> {
  resultCode: number
  resultMessage: string
  data: T[]
}
export interface ResultInfoSingle<T> {
  resultCode: number
  resultMessage: string
  data: T
}

export interface BackendTagMaster {
  tagPurposeId?: string
  tagPurposeCd: string
  tagPurposeText: string
  tagPurposeColorCd?: string
  textTags: TextTag[]
}

export interface TextTag {
  tagId?: string
  tagCd: string
  tagText: string
  tagColorCd?: string
}

export interface Tag {
  tagId?: string
  tagCd: string
  tagText: string
  tagPurpopseId?: string
  tagPurposeCd: string
  tagPurposeText: string
}

export interface Item {
  key: string
  name: string
  value: any
}


