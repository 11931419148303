import { FormInputInfo, SelectMaster } from 'src/app/module/common-parts/input-table/input-table.model'

export const prefectureMaster: SelectMaster[] = [
  { value: '北海道', label: '北海道' },
  { value: '青森県', label: '青森県' },
  { value: '岩手県', label: '岩手県' },
  { value: '宮城県', label: '宮城県' },
  { value: '秋田県', label: '秋田県' },
  { value: '山形県', label: '山形県' },
  { value: '福島県', label: '福島県' },
  { value: '茨城県', label: '茨城県' },
  { value: '栃木県', label: '栃木県' },
  { value: '群馬県', label: '群馬県' },
  { value: '埼玉県', label: '埼玉県' },
  { value: '千葉県', label: '千葉県' },
  { value: '東京都', label: '東京都' },
  { value: '神奈川県', label: '神奈川県' },
  { value: '新潟県', label: '新潟県' },
  { value: '富山県', label: '富山県' },
  { value: '石川県', label: '石川県' },
  { value: '福井県', label: '福井県' },
  { value: '山梨県', label: '山梨県' },
  { value: '長野県', label: '長野県' },
  { value: '岐阜県', label: '岐阜県' },
  { value: '静岡県', label: '静岡県' },
  { value: '愛知県', label: '愛知県' },
  { value: '三重県', label: '三重県' },
  { value: '滋賀県', label: '滋賀県' },
  { value: '京都府', label: '京都府' },
  { value: '大阪府', label: '大阪府' },
  { value: '兵庫県', label: '兵庫県' },
  { value: '奈良県', label: '奈良県' },
  { value: '和歌山県', label: '和歌山県' },
  { value: '鳥取県', label: '鳥取県' },
  { value: '島根県', label: '島根県' },
  { value: '岡山県', label: '岡山県' },
  { value: '広島県', label: '広島県' },
  { value: '山口県', label: '山口県' },
  { value: '徳島県', label: '徳島県' },
  { value: '香川県', label: '香川県' },
  { value: '愛媛県', label: '愛媛県' },
  { value: '高知県', label: '高知県' },
  { value: '福岡県', label: '福岡県' },
  { value: '佐賀県', label: '佐賀県' },
  { value: '長崎県', label: '長崎県' },
  { value: '熊本県', label: '熊本県' },
  { value: '大分県', label: '大分県' },
  { value: '宮崎県', label: '宮崎県' },
  { value: '鹿児島県', label: '鹿児島県' },
  { value: '沖縄県', label: '沖縄県' },

]

// 入力項目 (会社の場合)
export const formCompanyItems: FormInputInfo[] = [
  {
    key: 'organizationCd',
    label: '組織コード',
    readonly: true
  },
  {
    key: 'organizationTypeName',
    label: '組織種別',
    readonly: true
  },
  {
    key: 'organizationNameInfo',
    label: '会社名',
    group: [
      {
        key: 'organizationCategoryCd',
        label: '組織カテゴリー',
        type: 'select',
        event: {
          change: true
        },
        valid: {
          required: true
        }
      },
      {
        key: 'displayPositionCd',
        label: '表記位置',
        type: 'select',
        master: [
        ],
        event: {
          change: true
        },
        valid: {
          required: true
        }
      },
      {
        key: 'organizationName',
        label: '会社名',
        placeholder: 'インターサーブ',
        event: {
          keyUp: true
        },
        valid: {
          required: true
        }
      },
      {
        key: 'organizationFormalName',
        label: '会社正式名称',
        readonly: true
      },
      {
        key: 'organizationNameKana',
        label: '会社名かな',
        placeholder: 'いんたーさーぶ',
        valid: {
          required: true,
          hiragana: true
        }
      },
    ],
  },
  {
    key: 'postCode',
    label: '郵便番号',
    placeholder: '例：1112222',
    valid: {
      required: true,
      numOnly: true,
      maxLength: 7
    },
    caption: 'ハイフンなし'
  },
  {
    key: 'prefecture',
    label: '都道府県',
    type: 'select',
    master: prefectureMaster,
    valid: {
      required: true
    },
  },
  {
    key: 'city',
    label: '市区町村',
    placeholder: '例：渋谷区恵比寿',
    valid: {
      required: true,
    },
  },
  {
    key: 'block',
    label: '番地',
    placeholder: '例：1-1-9',
    valid: {
      required: true,
    },
  },
  {
    key: 'building',
    label: 'ビル建物名',
    placeholder: '例：○○○ビル8階'
  },
  {
    key: 'telephoneNumber',
    label: '電話番号',
    placeholder: '',
    caption: 'ハイフンなし',
    valid: {
      required: true,
      maxLength: 13,
      numOnly: true
    },
  },
]
export const formCompanyItemsNafco: FormInputInfo[] = [
  {
    key: 'organizationCd',
    label: '組織コード',
    readonly: true
  },
  {
    key: 'organizationNameInfo',
    label: '会社名',
    group: [
      {
        key: 'organizationName',
        label: '会社名',
        placeholder: 'インターサーブ',
        event: {
          keyUp: true
        },
        valid: {
          required: true
        }
      },
      {
        key: 'organizationFormalName',
        label: '会社正式名称',
        readonly: true
      },
      {
        key: 'organizationNameKana',
        label: '会社名かな',
        placeholder: 'いんたーさーぶ',
        valid: {
          required: true,
          hiragana: true
        }
      },
    ],
  }
]

// 部署用
export const formBranchItem: FormInputInfo =
{
  key: 'organizationSectionName',
  label: '支店・部署名',
  placeholder: '',
  valid: {
    required: true
  }
}

// 企業名他編集不可用 ※カナのみ編集可能
export const organizationNameInfoNoEdit: FormInputInfo =
{
  key: 'organizationNameInfo',
  label: '会社名',
  readonly: true,
  group: [
    {
      key: 'organizationCategoryName',
      label: '組織カテゴリー',
      type: 'select',
      readonly: true,
      event: {
        change: true
      },
      valid: {
        required: true
      }
    },
    {
      key: 'displayPositionName',
      label: '表記位置',
      readonly: true,
      type: 'select',
      master: [
      ],
      event: {
        change: true
      },
      valid: {
        required: true
      }
    },
    {
      key: 'organizationName',
      label: '会社名',
      placeholder: 'インターサーブ',
      readonly: true,
      event: {
        keyUp: true
      },
      valid: {
        required: true
      }
    },
    {
      key: 'organizationFormalName',
      label: '会社正式名称',
      readonly: true
    },
    {
      key: 'organizationNameKana',
      label: '会社名かな',
      placeholder: 'いんたーさーぶ',
      valid: {
        required: true,
        hiragana: true
      }
    },
  ],
}
