import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { InvitationUser, ReceiveMessage } from 'src/api/frimo-switch-api/frimo-switch-api.model'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { FrimoSwitchService } from 'src/api/frimo-switch-api/frimo-switch-api.service'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { InvitationsApiService } from 'src/api/invitations-api/invitations-api.service'
import { NotificationApiService } from 'src/api/notification-api/notices-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  title = 'お知らせ一覧'
  data: ReceiveMessage[] = []
  columns: Header[] = [
    {
      key: 'messageTextCd',
      label: 'CD',
      hidden: true
    },
    {
      key: 'receiveDatetimeUi',
      label: '受信日',
      style: {
        textAlign: 'center'
      },
      width: '10%'
    },
    {
      key: 'messageText',
      label: 'タイトル',
      width: '55%'
    },
    {
      key: 'senderOrganizationName',
      label: '送信者',
      width: '20%'
    },
    {
      key: 'noticeStatusName',
      label: '状態',
      width: '10%'
    },
    {
      key: 'noticeStatusCd',
      label: 'statusCD',
      hidden: true,
    },
  ]
  rowClickAction = true
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '確認',
      actionCd: 'actionCd1'
    }
  ]
  loading = true

  constructor(
    private authService: AuthService,
    private frimoSwitchService: FrimoSwitchService,
    private invitationsApiService: InvitationsApiService,
    private notificationApiService: NotificationApiService,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getNotificationList()
  }

  actionCommit(event: ActionTargetInfo): void {
    // ページ遷移 現時点ではBefore/Afterの画面のみ
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    if (action.actionCd === 'actionCd1') {
      // if (event.rows[0].noticeStatusCd === '0') {
      //   this.submitReading()
      // }
      // this.router.navigate(['/xfrimo-migration-top'])
      this.messageService.error('メッセージの有効期限が切れました')
    }
  }

  // お知らせ一覧取得
  getNotificationList(): void {
    this.loading = true
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd) { return }
    // 招待が承認されていることが前提
    this.invitationsApiService.GetInvitationReceiveList(customerCd).subscribe((ret: ResultInfo<InvitationUser>) => {
      if (ret?.resultCode === 0) {
        const filter = ret.data.filter(x => x.invitationStatusCd !== '0')
        // お知らせ件数は招待が承認されていたら検索
        if (filter.length) {
          this.notificationApiService.GetNotificationList(customerCd).subscribe((ret2: ResultInfo<ReceiveMessage>) => {
            if (ret2?.resultCode === 0) {
              this.setData(ret2?.data)
              this.loading = false
            }
          })
        }
        else {
          this.setData([])
          this.loading = false
        }
      }
    })
  }

  // 既読にする
  submitReading(): void {
    const customerCd = this.authService.AccountInfo?.customerCd
    if (!customerCd) { return }
    this.notificationApiService.AlreadyReading(customerCd).subscribe((ret: ResultInfo<ReceiveMessage>) => {
    })
  }

  setData(data: ReceiveMessage[]): void {
    data.forEach(item => {
      item.messageText = '契約担当としてFRIMOの移行承認依頼が来ています'
      item.senderOrganizationName = 'インターサーブ'
      item.actionCds = ['actionCd1']
    })
    this.data = data
  }
}
