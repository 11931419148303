import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ServiceMapActionBlock } from '../service-map-view.interface'

@Component({
  selector: 'app-service-map-action-block',
  templateUrl: './service-map-action-block.component.html',
  styleUrls: ['./service-map-action-block.component.scss']
})
export class ServiceMapActionBlockComponent implements OnInit {
  @Input() block: ServiceMapActionBlock
  @Output() clickBlock = new EventEmitter<void>()

  constructor() { }

  ngOnInit(): void {
  }

  click(): void {
    this.clickBlock.emit()
  }
}
