export interface ContractCompany {
  companyOrganizationCd: string
  contractCompanyName: string
  sectionOrganizationCd: string // 今貰っていない
  sectionOrganizationName: string
  purchaserName: string
  telephoneNumber: string
  mailAddress: string
  representativeProductName: string
  sendMailDate: string
  approvalDate: string
  sendUserInvitationMailDate: string
  migrationStatusCd: string
  migrationStatusName: string
  migrationServiceUserStatusCd: string
  migrationServiceUserStatusName: string
  actionCds: string[]
}

export interface MigrationContractBeforeAfter {
  companyOrganizationCd: string
  beforeTotalAmount: number
  afterTotalAmount: number
  organizationName: string
  beforeEstimates: Estimate[]
  afterEstimates: Estimate[]
  defaultBeforePdfPath: string
  defaultAfterPdfPath: string
}

export interface Estimate {
  merchandiseName: string
  businessCategoryName: string
  quantity: number
  chargeCategory: number
  chargeCategoryName: string
  price: string
}

export interface SearchMigrationContractRequest {
  supplierCds?: string[]
  businessCategoryCds?: string[]
}

export interface ServiceMap {
  actuateCd: string
  supplierCd: string
  businessCategoryCd: string
  businessCategoryName: string
  useCompanyName: string
  organizationCd: string
  contractCompanyName: string
  merchandiseCd: string
  merchandiseName: string
  productNo: string
  inquiryNo: string
  invoiceCompanyOrganizationCd: string
  invoiceCompanyOrganizationName: string
  invoiceSectionOrganizationCd: string
  invoiceSectionOrganizationName: string
  invoiceCustomerCd: string
  invoiceCustomerName: string
  suppliBillingCustomerId: string
  children: ServiceMap[]
}

export interface BeforeAfterCondition {
  supplierInfos: SupplierInfo[]
  businessCategories: BusinessCategorie[]
}

export interface SupplierInfo {
  supplierCd: string
}
export interface BusinessCategorie {
  businessCategoryCd: string
  businessCategoryName: string
}

export interface PdfPath {
  beforePdfPath: string
  afterPdfPath: string
}

export interface ServiceMapMacro {
  actuateCd?: string
  parentActuateCds?: string[]
  actuateName?: string // メモとして利用
  supplierCd?: string
  businessCategoryCd?: string
  businessCategoryName?: string
  contractOrganizationCd?: string // 会社の組織CD
  contractCompanyOrganizationName?: string
  contractSectionOrganizationCd?: string // 契約部署の組織CD（会社と同じ場合はnull）
  contractSectionCompanyOrganizationName?: string
  productCd?: string
  productName?: string
  productInquiryCd?: string
  merchandiseCd?: string
  merchandiseName?: string
  merchandiseDisplayName?: string
  contractCd?: string
  contractStartDatetime?: string // 契約日
  purchaserCd?: string
  purchaserName?: string // 契約者
  suppliInquiryNo?: string
}

export interface ServiceMapSubInfo {
  actuateCd: string
  actuateName: string
  contractOrganizationCd: string
  contractCompanyOrganizationName: string
  contractSectionOrganizationCd: string
  contractSectionCompanyOrganizationName: string
  userCompanyies: string[]
  contractCd: string
  contractStartDatetime: string
  contractStartDatetimeUi: string
  purchaserCd: string
  purchaserName: string
  invoiceCompanyOrganizationCd: string
  invoiceCompanyOrganizationName: string
  invoiceSectionOrganizationCd: string
  invoiceSectionOrganizationName: string
  invoiceCustomerCd: string
  invoiceCustomerName: string
  suppliBillingCustomerId: string
  productConnectionFlg: boolean
  useStartDatetime?: Date
  useStartDatetimeUi: string
  actuateStartDatetime?: Date
  actuateStartDatetimeUi: string
  merchandiseCd: string // APIからは返らないがUIでSETして使う
  merchandiseName: string // APIからは返らないがUIでSETして使う
  productInquiryCd: string // APIからは返らないがUIでSETして使う
  productCd: string // APIからは返らないがUIでSETして使う
  notContractCompany?: boolean // APIからは返らないがUIでSETして使う
  canEditMemo?: boolean // APIからは返らないがUIでSETして使う
  memoLabel?: string // メモのラベル
  memoExampleText?: string // メモの例テキスト
}

export interface UpdateMemoRequest {
  actuateCd: string
  actuateName: string
}

export interface WorkGroup {
    organizationId: string,
    organizationCd: string,
    organizationName: string,
    supplierCd: string
}

export interface ContractStatus {
  applicationContractStatusCd: string,
  contractDivitionCd: string,
  organizationContractCd: string,
  contractDivitionName: string,
  merchandiseCd: string,
  merchandiseName: string,
  supplierCd: string,
  contractCd: string,
  actuateCd: string,
  topContractCd: string,
  topActuateCd: string,
  productInquiryCd: string
  companyOrganizationCd: string,
  companyOrganizationName: string,
  sectionOrganizationCd: string,
  sectionOrganizationName: string,
  applicationDatetime: string,
  applicationDatetimeUi: string,
  invoiceSettingStatusCd: string,
  invoiceSettingStatusName: string,
  connecterSettingStatusCd: string,
  connecterSettingStatusName: string,
  actuateStartOrCancelDatetimeUi: string,
  paymentStartOrEndDatetimeUi: string,
  serviceActuateSettingStatusCd: string,
  serviceActuateSettingStatusName: string,
  actuateDateSettingStatusCd: string,
  actuateDateSettingStatusName: string,
  registSuppliDatetime: string,
  registSuppliDatetimeUi: string,
  registableSuppli: string,
  actionCds: string[]
}

export interface ContractStatusSmall {
  companyOrganizationCd: string,
  contractSectionOrganizationCd: string,
  contractCompanyName: string,
  sectionOrganizationName: string,
  companyStatusCd: string,
  companyStatusName: string,
  corporateNumber: string,
  customerCd: string,
  purchaserName: string,
  representativeProductName: string,
  actionCds: string[]
}

export interface ActuateList {
  actuateCd: string,
  customerName: string,
  customerCd: string,
  organizationName: string,
  organizationSectionName: string,
  productName: string,
  actionCds: string[]
}

export interface ContractWorkActuate {
  actuateCd: string
  contractCd: string
  productName: string
  optionName: string
  productInquiryCd: string
}

export interface EstimateDocumentStatus {
  estimateDocumentStatusCd: string
  divisionCd: string
  estimateDocumentCd: string
  estimateDocumentName: string
  estimateDocumentNo: string
  companyOrganizationCd: string
  companyOrganizationName: string
  sectionOrganizationCd: string
  sectionOrganizationName: string
  contractCustomerCd: string
  contractCustomerName: string
  isRegistedAccount: boolean
  totalRunningPrice: number
  createOrganizationCd: string
  createOrganizationName: string
  createCustomerCd: string
  createCustomerName: string
  easyAuthenticationCd: string
  expirationDate: Date
  expirationDateUi: string
  lastSendDatetime: Date
  lastSendDatetimeUi: string
  lastSendOrganizationSectionCd: string
  lastSendOrganizationSectionName: string
  lastSendCustomerCd: string
  lastSendCustomerName: string
  applicationDatetime: Date
  applicationDatetimeUi: string
  isDeleted: boolean
  createdDatetime: Date
  createdDatetimeUi: Date
  actionCds?: string[]
}

export interface MigrationContractOrganization {
  companyOrganizationCd: string
  contractSectionOrganizationCd: string
  contractCompanyName: string
  sectionOrganizationName: string
  customerCd: string
  purchaserName: string
  migrationContractApplicationCd: string
  estimateStatusCd: string
  estimateStatusName: string
  contractApplicationStatusCd: string
  contractApplicationStatusName: string
  contractApplicationDatetime: string
  contractApplicationDatetimeUi: string
  actionCds?: string[]
  sendMailDatetime: string,
  sendMailDatetimeUi: string
}

export interface MigrationEstimateList {
  estimateDocumentCd: string,
  estimateNo: string,
  createdDatetime: string,
  createdDatetimeUi: string,
  expirationDatetime: string,
  expirationDatetimeUi: string,
  contractApplicationDatetime: string,
  contractApplicationDatetimeUi: string,
  contractApplicationStatusCd: string,
  contractApplicationStatusName: string,
  totalRunningPrice: number,
  actionCds?: string[]
}

export interface CheckAboxPlanRequest {
  usingNumber: number
  serviceMerchandiseCd: string
}

export interface AboxPlanMerchandise {
  merchandiseCd: string
  merchandiseName: string
}

export interface EstimateDetailsMigration {
  rowNo: 0,
  estimateDetailsEvidenceCd: string,
  merchandiseId: string,
  merchandiseCd: string,
  merchandiseName: string,
  merchandiseSalesTypeCd: string,
  merchandiseSalesTypeName: string,
  unitQuantity: 0,
  unitPrice: 0,
  totalInitialPrice: 0,
  totalRunningPrice: 0,
  retailTagCd: string,
  retailTagText: string,
  businessCategoryTagCd: string,
  businessCategoryTagText: string,
  comment: string,
  baseContractId: string,
  baseContractCd: string,
  order: 0,
  merchandiseChargeTypeCd: string,
  merchandiseChargeTypeName: string,
  payPerUseFlg: boolean
}

export interface MigrationUpdateValue {
  changeAccountBoxes: ChangeAccountBox[]
  cancelContractCds: string[]
}

export interface ChangeAccountBox {
  targetAboxContractCd: string
  afterMerchandiseCd: string
  afterMerchandiseName?: string
}

export interface ContractMailTargetRequest {
  migrationContractApplicationCd: string
  contractSectionOrganizationCd: string
  contractCustomerCd: string
}
