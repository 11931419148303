import { NavMenueItem } from './nav-menu.interface'

export const navMenuList: NavMenueItem[] = [
  {
    label: 'トップ',
    path: '',
    icon: 'home',
    children: []
  },
  {
    label: 'お知らせ',
    path: '/message-box',
    icon: 'notification',
    children: []
  },
  {
    label: 'マイアカウント',
    path: '/invitation-receive-list',
    icon: 'user',
    children: [
      {
        label: '招待された履歴',
        path: '/invitation-receive-list'
      },
      {
        label: '個人情報の変更',
        path: '/account-change'
      },
      // {
      //   label: '組織確認',
      //   path: '/companyinfo-edit',
      // },
      {
        label: 'ログインID変更',
        path: '/change-login-id'
      },
      {
        label: 'パスワード変更',
        path: '/change-password'
      }
    ]
  },
  {
    label: 'サービス管理',
    path: '/service-map',
    icon: 'service',
    children: [
      {
        label: 'サービスマップ',
        path: '/service-map'
      }
    ]
  },
  {
    label: 'ユーザー管理',
    path: '/invitation-edit',
    icon: 'group',
    children: [
      {
        label: 'ユーザー招待',
        path: '/invitation-edit'
      },
      {
        label: '招待送信履歴',
        path: '/invitation-send-list'
      }
    ]
  }
]

export const navMenuListUra: NavMenueItem[] = [
  {
    label: 'トップ',
    path: '/ura-top',
    icon: 'home',
    children: []
  },
  {
    label: '契約管理',
    path: '/ura-contract-search-list',
    icon: 'user',
    children: [
      {
        label: '検索：契約一覧',
        path: '/ura-contract-search-list'
      },
      {
        label: '検索：サービスマップ',
        path: '/ura-service-map-search'
      },
      {
        label: '検索：利用者一覧',
        path: '/ura-user-search-list'
      },
      {
        label: 'お試し利用一覧',
        path: '/ura-trial-contract-list'
      },
      {
        label: 'インボイス対応：ステータス一覧',
        path: '/ura-invoice-pj-status-list'
      },
      {
        label: '思夢樂Garage申し込み状況',
        path: '/ura-shimura-garage-migration-send-list'
      }
      // {
      //   label: '諸口契約登録',
      //   path: '/ura-sundry-contract',
      // },
      // {
      //   label: '包括契約登録',
      //   path: '/ura-inclusion-contract',
      // },
    ]
  },
  {
    label: '見積管理',
    path: '/ura-estimate-new',
    icon: 'simulation',
    children: [
      {
        label: '新規見積作成',
        path: '/ura-estimate-new'
      },
      {
        label: '見積一覧',
        path: '/ura-estimate-list-new'
      }
    ]
  },
  {
    label: 'サービス管理',
    path: '/contract-entry-list',
    icon: 'service',
    children: [
      {
        label: '設定未登録契約',
        path: '/contract-entry-list'
      },
      {
        label: 'ナフコサービス稼働設定',
        path: '/ura-nafco-actuate-setting'
      }
    ]
  },
  {
    label: '請求管理',
    path: '/ura-confirm-invoice-list',
    icon: 'service',
    children: [
      {
        label: '請求内容確定',
        path: '/ura-confirm-invoice-list'
      },
      {
        label: '口座振替データ送信',
        path: '/ura-send-invoice-for-bank'
      },
      {
        label: '振替口座一覧',
        path: '/ura-payment-method-account-list'
      },
      {
        label: '請求先変更',
        path: '/ura-invoice-change'
      }
    ]
  },
  {
    label: 'その他',
    path: '/ura-company-add',
    icon: 'service',
    children: [
      {
        label: '会社新規登録',
        path: '/ura-company-add'
      },
      {
        label: '会社一覧',
        path: '/ura-company-list'
      },
      {
        label: '企業法人紐づけ',
        path: '/ura-company-check-list'
      },
      {
        label: 'アカウント検索',
        path: '/ura-support-search-account'
      },
      {
        label: '管理者モード',
        path: '/ura-admin'
      },
      {
        label: '各種ファイルダウンロード',
        path: '/ura-csv-download'
      },
      {
        label: '検索：出荷Pack 送信タスク結果一覧',
        path: '/ura-monitoring-pack-send-task'
      },
      {
        label: '検索：メール検索、出荷Pack送信エラー検索、etc...',
        path: '/mail/search',
        externalUrl: '//dev-tools-ui.backend.out.nakamenosakura.com',
        functionKey: 'cacf791f-3602-428c-9ecd-33166db675d0',
        methodType: 2
      },
      {
        label: '商品マスタ',
        path: '/ura-merchandise-list'
      },
      {
        label: '製品マスタ',
        path: '/ura-product-list'
      },
      {
        label: 'ペタボ版 重要なお知らせ編集',
        path: '/ura-petabo-edit'
      }
    ]
  },
  {
    label: '[顧客]組織管理',
    path: '/ura-company-user',
    icon: 'user',
    children: [
      {
        label: '所属顧客追加',
        path: '/ura-company-user'
      },
      {
        label: 'アカウント登録URL発行',
        path: '/ura-create-account-url'
      },
      {
        label: '組織図編集',
        path: '/ura-organization-tree-view'
      },
      {
        label: '組織図編集（新）',
        path: '/create/structure',
        externalUrl: '//organization-ui.backend.out.nakamenosakura.com',
        functionKey: '211d2fcb-7773-4f16-a4e8-a2aa515e7a8d',
        methodType: 1
      },
      {
        label: '顧客の配置',
        path: '/office-position/editor',
        externalUrl: '//organization-ui.backend.out.nakamenosakura.com',
        functionKey: '211d2fcb-7773-4f16-a4e8-a2aa515e7a8d',
        methodType: 1
      }
    ]
  },
  {
    label: '[顧客]見積もり管理',
    path: '/ura-estimate-list',
    icon: 'service',
    children: [
      {
        label: '見積一覧',
        path: '/ura-estimate-list'
      }
    ]
  },
  {
    label: '[顧客]サービス管理',
    path: '/ura-conpany-contracted-organization-list',
    icon: 'service',
    children: [
      {
        label: 'サービスの追加・変更',
        path: '/ura-conpany-contracted-organization-list'
      },
      {
        label: '稼働設定',
        path: '/ura-update-actuate-setting'
      },
      {
        label: 'サービス利用者管理',
        path: '/ura-management-user'
      },
      {
        label: '担当者変更',
        path: '/ura-handover-customer-auth'
      }
    ]
  },
  {
    label: '[顧客]請求管理',
    path: '/ura-company-invoice',
    icon: 'service',
    children: [
      {
        label: '請求情報マスタ確認',
        path: '/ura-company-invoice'
      }
    ]
  },
  {
    label: 'My-idea',
    path: '/',
    icon: 'home',
    children: []
  }
]
