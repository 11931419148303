<app-main-contents>
  <app-title [title]="'企業情報入力'"></app-title>
  <app-organization-profile-input
    *ngIf="formValue"
    [items]="items"
    [organization]="formValue"
  >
  </app-organization-profile-input>
  <app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
</app-main-contents>
