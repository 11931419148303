import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(
  ) { }

  private messageShow = false
  private messageText = ''
  private messageTitle = ''
  callback: () => void

  private messageShowSource = new Subject<boolean>()
  public messageShowSource$ = this.messageShowSource.asObservable()

  get show(): boolean {
    return this.messageShow
  }

  get message(): string {
    return this.messageText
  }

  get title(): string {
    return this.messageTitle
  }

  // 確認モーダル表示、OK処理セット
  confirm(message: string, title: string, callback: () => void): void{
    this.callback = callback
    this.messageText = message
    this.messageTitle = title
    this.setDisp(true)
  }

  commitAction(): void {
    this.callback()
    this.callback = null
    this.setDisp(false)
  }

  setDisp(show: boolean): void  {
    this.messageShow = show
    this.messageShowSource.next(this.messageShow)
  }
}
