import { Component, OnInit, ViewChild } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { BeforeAfterMicomelModel } from 'src/api/micomel-migrations-api/micomel-migrations-api.model'
import { ShimuraGarageMigrationsService } from 'src/api/micomel-migrations-api/micomel-migrations-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { ShimuraGarageAppSettingComponent } from '../extra-views/shimura-garage-migration/shimura-garage-app-setting/shimura-garage-app-setting.component'
@Component({
  selector: 'app-micomel-migration-top',
  templateUrl: './micomel-migration-top.component.html',
  styleUrls: ['./micomel-migration-top.component.scss']
})
export class ShimuraGarageMigrationTopComponent implements OnInit {
  ura = false
  // hasBefore = false
  // companyOrganizationCd = ''
  hasContracts = false
  companyOrganizationId = ''
  parentContractId = ''
  customerCd = ''
  data: BeforeAfterMicomelModel
  sending = false
  fileUrl: SafeResourceUrl = null
  actionButtons: ActionButtonDisp[] = [
    {
      actionCd: 'actionCd1',
      label: '次へ（利用規約確認）',
      actionFunction: () => {
        this.router.navigate([
          `/shimura-garage-migration-policy/${this.companyOrganizationId}/${this.parentContractId}`
        ])
      }
    }
  ]

  // @ViewChild('settingDialog', { static: false })
  // public settingDialog: ShimuraGarageAppSettingComponent
  constructor(
    private domSanitizer: DomSanitizer,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private micomelMigrationsService: ShimuraGarageMigrationsService
  ) {}

  get title(): string {
    return `【Products Garage 】思夢樂事業アプリ 登録のご案内`
  }

  async ngOnInit(): Promise<void> {
    this.companyOrganizationId = this.activatedRoute.snapshot.params.companyOrganizationId
    this.parentContractId = this.activatedRoute.snapshot.params.parentContractId
    this.customerCd = this.activatedRoute.snapshot.params.customerCd
    if (this.customerCd) {
      this.ura = true
      this.SearchBeforAfter()
    }

    const ret = await this.micomelMigrationsService.checkContractCustomer(
      this.ura ? this.customerCd : this.authService.AccountInfo.customerCd
    )
    // uraがtrueの場合はチェックしない
    if (!this.ura) {
      if (ret?.resultCode !== 0 || !ret.data?.length) {
        // topに戻す
        this.router.navigate(['/'])
        return
      }

      if (ret.data[0].contractCompanyOrganizationId !== this.companyOrganizationId) {
        this.router.navigate(['/'])
        return
      }
    }

    this.hasContracts = ret.data.length > 1

    this.SearchBeforAfter()
  }
  garageName = ''
  async SearchBeforAfter(): Promise<void> {
    const ret = await this.micomelMigrationsService.getEstimateData(this.companyOrganizationId, this.parentContractId)

    if (ret?.resultCode !== 0) {
      return
    }
    this.data = ret.data[0]
    if (this.hasContracts) {
      this.garageName = this.data.parentActuateName
    }
    const url = this.micomelMigrationsService.BaseURL + this.data.defaultPdfPath
    this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url)

    // this.hasBefore = this.data.beforeEstimates.length > 0
  }
}
