import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'


// 入力項目
export const customerNameFormItem: FormInputInfo =
{
  key: 'customerName',
  label: 'お名前',
  group: [
    {
      key: 'familyName',
      label: '姓',
      placeholder: '例：山田',
      valid: {
        required: true
      }
    },
    {
      key: 'firstName',
      label: '名',
      placeholder: '例：太郎',
      valid: {
        required: true
      }
    },
    {
      key: 'familyNameKana',
      label: '姓かな',
      placeholder: '例：やまだ',
      valid: {
        required: true
      }
    },
    {
      key: 'firstNameKana',
      label: '名かな',
      placeholder: '例：たろう',
      valid: {
        required: true
      }
    }
  ]
}

export const customerNameNotKanaFormItem: FormInputInfo =
{
  key: 'customerName',
  label: 'お名前',
  group: [
    {
      key: 'familyName',
      label: '姓',
      placeholder: '例：山田',
      valid: {
        required: true
      }
    },
    {
      key: 'firstName',
      label: '名',
      placeholder: '例：太郎',
      valid: {
        required: true
      }
    }
  ]
}

export const mailAddressFormItem: FormInputInfo =
{
  key: 'mailAddress',
  label: 'メールアドレス',
  placeholder: '例：myidea@interserve.co.jp',
  valid: {
    required: true,
    mailAddress: true,
  }
}

export const customerCdFormItem: FormInputInfo =
{
  key: 'customerCd',
  label: '顧客コード',
  readonly: true
}
