import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html'
})
export class InputTextComponent implements OnInit {
  @Input() label = ''
  @Input() subText = ''
  @Input() value = ''
  @Input() maxLength = 99
  @Input() errorMessage = ''
  @Input() placeholder = ''
  @Output() changeValue = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {
  }

  change(): void {
    this.changeValue.emit(this.value)
  }
}
