<app-sending *ngIf="sending"></app-sending>
<app-dialog
  *ngIf="dialogOpen"
  (commit)="update()"
  (cancel)="dialogOpen = false"
  [title]="title"
  widthSize="large"
  commitButtonText="更新"
>
  <div class="l-table">
    <div class="l-table__item">
      <div class="l-table__head">
        <p>サプライヤーコード<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <app-input-form
          [class.no-show]="frimoActuates?.supplierCd"
          [value]="inputFrimoSetting.supplierCd"
          placeholder="サプライヤーコード"
          [required]="true"
          [numOnly]="true"
          [notBlank]="true"
          [maxLength]="4"
          [minLength]="4"
          (updateValue)="updateFormValue('supplierCd', $event)"
          caption="半角数字 ※再更新不可"
        ></app-input-form>
        <div *ngIf="frimoActuates?.supplierCd">
          {{ frimoActuates?.supplierCd }}
        </div>
      </div>
    </div>
    <div class="l-table__item">
      <div class="l-table__head">
        <p>サプライヤー名<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <div [class.no-show]="hideFrimoSetting">
          <ng-container *ngIf="!editSupplierMasterMode">
            {{ inputFrimoSetting.supplierName }}
            <a
              *ngIf="!frimoActuates?.supplierCd"
              style="cursor: pointer"
              (click)="editSupplierMasterMode = true"
              class="change-text"
            >
              ×変更</a
            >
          </ng-container>
          <div [class.no-show]="!editSupplierMasterMode">
            <div style="display: flex">
              <div style="width: 80%">
                <app-input-form
                  [value]="inputFrimoSetting.supplierName"
                  placeholder="サプライヤー名"
                  [required]="true"
                  [notBlank]="true"
                  (updateValue)="updateFormValue('supplierName', $event)"
                  caption="※再更新不可"
                ></app-input-form>
              </div>
              <a
                *ngIf="registedSupplierName"
                style="cursor: pointer; padding-top: 6px"
                (click)="
                  editSupplierMasterMode = false;
                  inputFrimoSetting.supplierName = registedSupplierName
                "
                class="change-text"
              >
                ×戻す</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="l-table__item">
      <div class="l-table__head">
        <p>サーバー分け<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <div *ngIf="!hideFrimoSetting">
          <div *ngIf="!registedServerGroupName">
            <select
              class="c-form__select"
              [required]="required"
              [(ngModel)]="inputFrimoSetting.serverGroupCd"
            >
              <option
                *ngFor="let masterItem of serverSelecter"
                [value]="masterItem.value"
              >
                {{ masterItem.label }}
              </option>
            </select>
          </div>
          <div *ngIf="registedServerGroupName">
            {{ registedServerGroupName }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-input-table [formItems]="formItems"> </app-input-table>
</app-dialog>
