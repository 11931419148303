<app-sending *ngIf="sending"></app-sending>
<app-dialog
  *ngIf="mainDialogOpen"
  (cancel)="mainDialogOpen = false"
  [title]="'請求書参照できるユーザー一覧'"
  [hideCommitButton]="true"
  [hideCancelButton]="true"
  widthSize="large"
>
  <div class="sub-area">
    <div class="sub-text">
      <p>請求先ID：{{ invoiceId }}</p>
    </div>
    <a
      (click)="showAddUserDialog()"
      class="c-btn c-btn--blue c-btn--s"
      style="cursor: pointer"
      ><span>別のユーザーに権限を付与</span></a
    >
  </div>
  <app-base-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [height]="400"
    (updateCheckBox)="updateCheckBox($event)"
    (selectionChange)="selectionChange($event)"
  ></app-base-grid>
  <div class="c-btns">
    <div class="c-btns__item">
      <a
        (click)="deleteConfirm()"
        class="c-btn c-btn--blue"
        style="cursor: pointer"
        [class.is-disable]="!selectedRows.length"
        ><span>選択したユーザーの権限を削除</span></a
      >
    </div>
  </div>
</app-dialog>

<app-dialog
  *ngIf="addUserDialogOpen"
  (cancel)="addUserDialogOpen = false; mainDialogOpen = true"
  (commit)="addUserCommit()"
  [commitButtonText]="'参照できるユーザーを追加'"
  [title]="'対象者を選択して下さい'"
  widthSize="large"
>
<span class="sub-text">追加すると担当者へ確認メールが送信されます</span>
  <div class="l-table">
    <div class="l-table__item l-table__item--s">
      <div class="l-table__head">
        <p>参照できるユーザーのリスト</p>
      </div>
      <div class="l-table__body">
        <app-select-customer
          [organizationCd]="companyCd"
          [singleSelect]="false"
          [exclusionCustomerCds]="exclusionCustomerCds"
          (submit)="selectCustomer($event)"
        ></app-select-customer>
        <div class="create-link">
          <a
            (click)="createDialogOpen = true; addUserDialogOpen = false"
            style="cursor: pointer"
            >>>新規ユーザーをリストに追加する</a
          >
        </div>

        <div class="selected-customer">
          <div
            *ngFor="let selectCustomer of selectCustomers"
            class="selected-customer-row"
          >
            <div>
              {{ selectCustomer.familyName }} {{ selectCustomer?.firstName }}
            </div>
            <div>{{ selectCustomer?.mailAddress }}</div>
            <a
              class="clear-btn"
              (click)="removeSelectCustomer(selectCustomer)"
              style="cursor: pointer"
              >×</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog
  *ngIf="createDialogOpen"
  (cancel)="createDialogOpen = false; addUserDialogOpen = true"
  (commit)="submitNewCustomer()"
  [commitButtonText]="'対象者リストに追加'"
  [title]="'新規ユーザー情報入力'"
  widthSize="large"
>
  <app-input-new-customer [organizationCd]="companyCd"></app-input-new-customer>
</app-dialog>
