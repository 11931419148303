import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-complete-page',
  templateUrl: './complete-page.component.html',
  styleUrls: ['./complete-page.component.scss']
})
export class CompletePageComponent implements OnInit {
  @Input() title: string
  @Input() message: string
  @Output() backAction = new EventEmitter<void>()
  @Output() nextAction = new EventEmitter<void>()

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
