import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BackendOrganizationCorporateV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { Organization } from 'src/app/components/isbeauth/account'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { SelectedChildOrganizationInfo } from 'src/app/module/myidea-parts/organization-tree-view/selected-child-organization.model'

@Component({
  selector: 'app-ura-organization-tree-view',
  templateUrl: './ura-organization-tree-view.component.html',
  styleUrls: ['./ura-organization-tree-view.component.scss']
})
export class UraOrganizationTreeViewComponent implements OnInit {
  companyOrganizationCd: string
  companyOrganizationName: string
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  selectTargetOrganization(event: BackendOrganizationCorporateV2): void {
    this.companyOrganizationCd = event?.organizationCd
    this.companyOrganizationName = event?.organizationName
  }

  jumpEditTreePage(event: SelectedChildOrganizationInfo): void {
    this.router.navigate(['/ura-organization-tree-edit', this.companyOrganizationCd, event.organizationCd ? event.organizationCd : '-1'])
  }

  jumpUserListPage(event: SelectedChildOrganizationInfo): void {
    this.router.navigate(['/ura-organization-user-list', this.companyOrganizationCd, event.organizationCd ? event.organizationCd : '-1'])
  }

}
