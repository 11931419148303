<div [class.no-show]="addMode || selectCustomerName">
  <span class="search-field">
    <input
      (clickOutside)="showCustomerList = false"
      class="c-form__input"
      type="text"
      placeholder="お名前かメールアドレスを入力してください"
      autocomplete="off"
      (keyup)="showList()"
      (click)="showList()"
      (keyup.enter)="submitSearchKeyword()"
      [(ngModel)]="searchKeyword"
    />
  </span>
  <div
    *ngIf="showCustomerList && customerDispList.length"
    class="search-user-result-area"
  >
    <div class="user-list-body">
      <div
        *ngIf="customerListLoading"
        class="user-list"
        style="text-align: center"
      >
        loading...
      </div>
      <div *ngFor="let user of customerDispList" class="user-list">
        <a (click)="selectUser(user)" style="cursor: pointer">
          <div class="list-user-name">
            <span>{{ user.familyName }} {{ user.firstName }}</span>
          </div>
          <div>{{ user.organizationFullName }}</div>
        </a>
      </div>
    </div>
  </div>
</div>

<p *ngIf="selectCustomerName">
  {{ selectCustomerName }}
  <a class="clear-btn" (click)="clearSelect()" style="cursor: pointer">×</a>
</p>

<!-- 追加入力欄 -->
<div [class.no-show]="!addMode">
  <app-input-table [formItems]="formItems"> </app-input-table>
  <div class="c-btns">
    <div class="c-btns__item">
      <a class="c-btn" (click)="returnForm()" style="cursor: pointer">
        <span>キャンセル</span></a
      >
    </div>
    <div class="c-btns__item">
      <a
        class="c-btn c-btn--blue"
        (click)="submitInput()"
        style="cursor: pointer"
        ><span>追加</span></a
      >
    </div>
  </div>
</div>

<!-- 追加入力欄 -->
<div *ngIf="showSuggestionCustomer">
  <p class="c-texts--attention h-margin--b16 h-margin--t16">
    別の会社にアカウントが存在しています
  </p>
  <ng-container *ngIf="canSelectOtherCompanyUser">
    <div class="add-user-list" style="border-bottom: none">
      <div class="list-user-name">
        <div>
          {{ suggestionCustomerInfo.familyName }}
          {{ suggestionCustomerInfo.firstName }}
        </div>
        <div>{{ searchKeyword }}</div>
      </div>
      <div>会社名： {{ suggestionCustomerCompany?.organizationFullName }}</div>
    </div>
    <div class="c-btns">
      <div class="c-btns__item">
        <a
          class="c-btn"
          (click)="showSuggestionCustomer = false"
          style="cursor: pointer"
        >
          <span>キャンセル</span></a
        >
      </div>
      <div class="c-btns__item">
        <a
          class="c-btn c-btn--blue"
          (click)="submitSuggestion()"
          style="cursor: pointer"
          ><span>選択</span></a
        >
      </div>
    </div>
  </ng-container>
</div>
