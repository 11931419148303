import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import moment from 'moment'
import { lastValueFrom } from 'rxjs'
import { ServiceMapSubInfo } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { FrimoMigrationsService } from 'src/api/frimo-migrations-api/frimo-migrations-api.service'
import { ServiceMapApiService } from 'src/api/service-map-api/service-map-api.service'
import { ServiceMapViewBlock } from '../service-map-view.interface'

@Component({
  selector: 'app-service-map-block',
  templateUrl: './service-map-block.component.html',
  styleUrls: ['./service-map-block.component.scss']
})
export class ServiceMapBlockComponent implements OnInit {
  @Input() service: ServiceMapViewBlock
  @Input() togglePosition = 'right'
  @Output() clickService = new EventEmitter<void>()
  @Output() clickIcon = new EventEmitter<void>()
  show = false
  hover = false
  hoverLock = false
  subInfo: ServiceMapSubInfo

  constructor(
    private serviceMapApiService: ServiceMapApiService
  ) { }

  ngOnInit(): void {
  }

  mouseenter(): void {
    if (!this.service.showSubInfo) { return }
    this.hover = true
    setTimeout(() => {
      if (this.hover) {
        this.openSubInfo()
      }
    }, 200)
  }

  openSubInfo(): void {
    this.setSubInfo()
    this.show = true
  }

  mouseLeave(): void {
    this.hover = false
    this.show = false
  }


  click(): void {
    this.clickService.emit()
  }

  async setSubInfo(): Promise<void> {
    if (this.subInfo) { return }

    // const ret = await this.serviceMapApiService
    //   .GetServiceMapSubInfo(this.service.actuateCd).toPromise()
    const ret$ = this.serviceMapApiService.GetServiceMapSubInfo(this.service.actuateCd)
    let ret = await lastValueFrom(ret$)
    
    if (ret?.resultCode !== 0) { return }
    const data = ret.data[0]
    data.merchandiseName = this.service.merchandiseName
    data.merchandiseCd = this.service.merchandiseCd
    data.productInquiryCd = this.service.productInquiryCd
    data.productCd = this.service.productCd
    data.notContractCompany = this.service.notContractCompany
    data.canEditMemo = this.service.canEditMemo
    data.memoLabel = this.service.memoLabel
    data.memoExampleText = this.service.memoExampleText

    this.subInfo = data
  }

  updateSubInfo(block: ServiceMapSubInfo): void {
    // 今はメモの更新のみ
    this.service.actuateName = block.actuateName
  }

  // 連番付きのランチャー表示
  merchandiseName(block: ServiceMapViewBlock): string {
    let name = block.merchandiseDisplayName
    if (block.launcherCount > 0) {
      name += block.launcherCount
    }
    return name
  }
}
