import { Component, OnInit, ViewChild } from '@angular/core'
import { CreateInvoiceTargetRequest } from 'src/api/invoice-api/invoice-api.interface'
import { InvoiceService } from 'src/api/invoice-api/invoice-api.service'
import { InvoiceMigrationContractUi } from 'src/api/invoice-migration-api/invoice-migration-api.interface'
import { InvoiceMigrationApiService } from 'src/api/invoice-migration-api/invoice-migration-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection, TextAlignValue } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { UpdateInvoiceTargetFormComponent } from 'src/app/module/myidea-parts/update-invoice-target-form/update-invoice-target-form.component'

@Component({
  selector: 'app-invoice-contract-confirm-list',
  templateUrl: './invoice-contract-confirm-list.component.html'
})
export class InvoiceContractConfirmListComponent implements OnInit {
  title = '請求先確認'
  data: InvoiceMigrationContractUi[] = []
  invoiceTargetCd = ''
  dialogOpen = false
  contractCds: string[] = []
  columns: Header[] = [
    {
      key: 'id',
      label: 'ID',
      hidden: true
    },
    {
      key: 'invoiceTargetCd',
      label: 'invoiceTargetCd',
      hidden: true
    },
    {
      key: 'contractorCompanyOrganizationName',
      label: '会社名',
    },
    {
      key: 'retailName',
      label: '小売',
      width: '8%'
    },
    {
      key: 'businessCategoryName',
      label: '事業',
      width: '8%'
    },
    {
      key: 'merchandiseName',
      label: 'ご利用中のサービス名',
      width: '25%'
    },
    {
      key: 'supplierCd',
      label: 'SPCD',
      width: '9%'
    },
    {
      key: 'contractPriceUi',
      label: '金額(税抜き)',
      style: {
        textAlign: TextAlignValue.rigth
      },
      width: '12%'
    },
    {
      key: 'contractDateUi',
      label: 'ご契約日',
      width: '10%'
    },
    {
      key: 'statusUi',
      label: '状態',
      groupLabel: "契約担当者様のご確認状況",
      width: '8%'
    },
    {
      key: 'approvalDateUi',
      label: '確認日',
      groupLabel: "契約担当者様のご確認状況",
      width: '10%'
    },
    {
      key: 'confirmStatusUi',
      label: '状態',
      groupLabel: "請求先担当者様のご確認状況",
      width: '8%'
    },
    {
      key: 'payerConfirmDateUi',
      label: '確認日',
      groupLabel: "請求先担当者様のご確認状況",
      width: '10%'
    },
    {
      key: 'invoiceSuppliCustomerId',
      label: '楽楽明細ID',
      groupLabel: '請求先担当者様のご確認状況',
      width: '11%'
    },
    {
      key: 'payerCompanyOrganizationName',
      label: '会社',
      groupLabel: '現在ご登録頂いている請求先の情報'
    },
    {
      key: 'payerCustomerName',
      label: '名前',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '8%'
    },
    {
      key: 'payerMailAddress',
      label: 'メールアドレス',
      groupLabel: '現在ご登録頂いている請求先の情報',
      width: '18%'
     },
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '請求担当者へメール送信',
      actionCd: 'approval'
    },
    {
      label: '請求先詳細確認/変更',
      actionCd: 'detail',
    }
  ]
  loading = true
  sending = false
  showGrid = true
  cellClasses = {
    color_green: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'statusUi' && rowData.approvalDatetime)
        || (columnKey === 'confirmStatusUi' && rowData.payerConfirmDatetime)
    },
    color_red: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'statusUi' && (!rowData.approvalDatetime))
        || (columnKey === 'confirmStatusUi' && (!rowData.payerConfirmDatetime))
    },
  }
  changeMode = false
  changeInvoiceTarget :CreateInvoiceTargetRequest = null
  @ViewChild('form', { read: UpdateInvoiceTargetFormComponent, static: false })
  public form: UpdateInvoiceTargetFormComponent

  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private invoiceMigrationApiService: InvoiceMigrationApiService,
    private invoiceService: InvoiceService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
    this.getContractCompanyList()
  }
  async getContractCompanyList(): Promise<void> {
    this.data = []
    this.loading = true
    const customerCd = this.authService.AccountInfo.customerCd
    const ret = await this.invoiceMigrationApiService.getContractStatus(customerCd)
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    const tmpData = ret.data.filter(x => x.contractPrice !== 0) // 0円商品除外
    tmpData.forEach(item => {
      if (item.approvalDatetime) {
        item.statusUi = '1) 確認済'
        item.actionCds = ['detail']
      } else {
        item.statusUi = '0) 未確認'
        item.actionCds = ['approval', 'detail']
      }
      if (item.payerConfirmDatetime) {
        item.confirmStatusUi = '1) 確認済'
      } else {
        item.confirmStatusUi = '0) 未確認'
      }
      item.contractPriceUi = item.contractPrice === null ? '従量課金' : item.contractPrice.toLocaleString()
    })

    
    if (tmpData.find(x => !!x.title) && !this.columns.find(x => x.key === 'title')) { //請求区分があるもの
      this.columns.push(
        {
          key: 'title',
          label: '請求区分',
          groupLabel: '現在ご登録頂いている請求先の情報',
          width: '12%'
        }
      )
      this.showGrid = false
      setTimeout(() => {
        this.showGrid = true
      }, 100)
    }
    this.data = tmpData
  }

  async approval(contractCds: string[]): Promise<void> {
    this.sending = true
    const ret = await this.invoiceMigrationApiService.approval(contractCds)
    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('請求先を確定しメールを送信しました')
    this.getContractCompanyList()
  }

  actionCommit(event: ActionTargetInfo): void {
    if (this.sending) { return }
    this.contractCds = []
    event.rows.forEach(row => {
      this.contractCds.push(row.contractCd)
    })
    if (event.actionCd === 'approval') {
      this.confirmService.confirm(`請求先担当者に確認メールを送信します。よろしいですか？`, '', async () => {
        this.approval(this.contractCds)
      })
    }
    if (event.actionCd === 'detail') {
      this.invoiceTargetCd = event.rows[0].invoiceTargetCd
      this.dialogOpen = true
      this.changeMode = false
    }
  }

  switchChangeMode(): void {
    this.changeMode = true
  }

  submit(): void {
    const invoiceTarget = this.form.submit()
    if (!invoiceTarget) {
      this.changeInvoiceTarget = null
      return
    }
    this.changeInvoiceTarget = invoiceTarget

    this.confirmService.confirm(`本当に請求先を変更しますか？`, '', async () => {
      if (this.sending) { return }
      this.sending = true

      let beforeInvoiceTargetCd = this.changeInvoiceTarget.invoiceTargetCd
      if (!beforeInvoiceTargetCd) {
        const create = await this.invoiceService.invoiceTargetsCreate(this.changeInvoiceTarget)
        if (create?.resultCode !== 0) { return }
        beforeInvoiceTargetCd = create.data
      }

      const updateRelationRet = await this.invoiceMigrationApiService.updateContractInvoiceRelations(beforeInvoiceTargetCd, this.contractCds)

      this.sending = false
      if (updateRelationRet?.resultCode !== 0) { return }
      this.messageService.success('変更が完了しました')
      this.getContractCompanyList()
      this.dialogOpen = false
    })
  }
}
