import { ServiceMapMacro } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { ServiceMapBlock } from 'src/api/service-map-api/service-map-api.interface'

export interface ServiceMapViewBlock extends ServiceMapMacro {
  isShow?: boolean
  hasAuth?: boolean
  serviceCd?: string
  isLauncher?: boolean // ランチャーかどうか
  launcherCount?: number // ランチャーが複数存在した場合に連番を振る
  blockText?: string
  iconClass?: string
  showSubInfo?: boolean
  classes?: string[]
  notContractCompany?: boolean // 自分の所属会社と契約会社が異なる場合true
  canEditMemo?: boolean // メモの編集可能
  memoLabel?: string // メモのラベル
  memoExampleText?: string // メモの例テキスト
  childHidden?: boolean // 子要素展開表示 (開閉ボタン用)
  children?: ServiceMapViewBlock[]
  actionBlocks?: ServiceMapActionBlock[]
}

export interface ServiceMapActionBlock {
  actionText?: string
  iconClass?: string
  classes?: string[]
}

export interface TargetServiceBlockInfo {
  targetBlock: ServiceMapViewBlock
  parentBlock?: ServiceMapViewBlock
  floorNumber: number // 階層数(1～4)
}
export interface TargetActionBlockInfo {
  targetBlock: ServiceMapActionBlock
  parentBlock?: ServiceMapViewBlock
  floorNumber: number // 階層数(1～4)
}

// セッションとして保持しておきたいデータのセット
// ひとまずorganizationCdをキーとして使う予定で作った
export interface ServiceMapKeepData {
  organizationCd: string
  apiServiceMap?: ServiceMapBlock[] // APIから取得したマップ
  cancelContractCds?: string[] // 解約対象
  // 他にも随時項目増やす
}
