import { Component, EventEmitter, OnInit, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { ActuateSettingItem, FrimoActuates } from 'src/api/actuates-api/actuates-api.interface'
import { ActuatesService } from 'src/api/actuates-api/actuates-api.service'
import { ServerGroupMacro } from 'src/api/server-groups-api/server-groups-api.interface'
import { ServerGroupsApiService } from 'src/api/server-groups-api/server-groups-api.service'
import { SupplierMasterMacro } from 'src/api/supplier-masters-api/supplier-masters-api.interface'
import { SupplierMastersApiService } from 'src/api/supplier-masters-api/supplier-masters-api.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { InputFormComponent } from 'src/app/module/common-parts/input-form/input-form.component'
import { FormReturn } from 'src/app/module/common-parts/input-form/input-form.interface'
import { InputFormService } from 'src/app/module/common-parts/input-form/input-form.service'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo, SelectMaster } from 'src/app/module/common-parts/input-table/input-table.model'

@Component({
  selector: 'app-frimo-actuate-setting-dialog',
  templateUrl: './frimo-actuate-setting-dialog.component.html',
  styleUrls: ['./frimo-actuate-setting-dialog.component.scss']
})
export class FrimoActuateSettingDialogComponent implements OnInit {
  @Input() title = 'Frimo稼働設定'
  @Output() updateed = new EventEmitter<string>() // 更新が終わったContractCdを渡す
  @ViewChildren(InputFormComponent) inputForms!: QueryList<InputFormComponent>
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  actuateCd: string
  actuateSettings: ActuateSettingItem[]
  settingValue: any = {}
  dialogOpen = false
  sending = false
  inputFrimoSetting = {
    supplierCd: '',
    supplierName: '',
    serverGroupCd: ''
  }
  registedSupplierName = ''
  registedServerGroupCd = ''
  editSupplierMasterMode = false
  frimoActuates: FrimoActuates
  formItems: FormInputInfo[] = [
    {
      key: 'webEdiId',
      label: 'WEB-EDI ID',
      type: 'webEdiId',
      valid: {
        required: true,
        notBlank: true
      }
    },
    {
      key: 'webEdiPassword',
      label: 'WEB-EDI パスワード',
      type: 'webEdiPassword',
      valid: {
        required: true,
        notBlank: true
      }
    },
  ]
  serverSelecter: SelectMaster[] = []
  hideFrimoSetting = true
  retailCd = 'shimamura_retail'

  constructor(
    private actuatesService: ActuatesService,
    private messageService: MessageService,
    private supplierMastersApiService: SupplierMastersApiService,
    private serverGroupsApiService: ServerGroupsApiService,
    private inputFormService: InputFormService
  ) { }

  ngOnInit(): void {
    this.getServerMasters()
  }

  get registedServerGroupName(): string {
    return this.serverSelecter?.find(x => x.value === this.registedServerGroupCd)?.label
  }

  async open(actuateCd: string): Promise<void> {
    this.clearFrimoSettingAllForm()
    this.actuateCd = actuateCd
    // const ret = await this.actuatesService.GetFrimoActuates(actuateCd).toPromise()
    const ret$ = this.actuatesService.GetFrimoActuates(actuateCd)
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.frimoActuates = ret.data
    if (this.frimoActuates.supplierCd) {
      this.inputFrimoSetting.supplierCd = this.frimoActuates.supplierCd
      this.reloadFrimoSetting(this.frimoActuates.supplierCd)
    }
    this.formItems.forEach(form => {
      if (form.key === 'supplierCd') {
        if (this.frimoActuates.supplierCd) {
          form.readonly = true
        }
        else {
          form.readonly = false
        }
      }
    })
    this.dialogOpen = true
    setTimeout(() => {
      this.inputTable?.setForm(this.frimoActuates)
    }, 100)
  }

  async getServerMasters(): Promise<void> {
    // const ret = await this.serverGroupsApiService.GetServerMasters().toPromise()
    const ret$ = this.serverGroupsApiService.GetServerMasters()
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.serverSelecter = this.inputFormService.convertForSelectMaster(ret.data, 'serverGroupCd', 'serverGroupName')
  }

  clearFrimoSettingAllForm(): void {
    this.inputFrimoSetting.supplierCd = ''
    this.clearFrimoSettingForm()
  }

  clearFrimoSettingForm(): void {
    this.inputFrimoSetting.supplierName = ''
    this.inputFrimoSetting.serverGroupCd = ''
    this.registedServerGroupCd = ''
    this.registedSupplierName = ''
    this.hideFrimoSetting = true
    this.editSupplierMasterMode = false
    this.inputForms.map(p => p).forEach(inputForm => {
      inputForm.reset()
    })
  }

  async updateFormValue(key: string, returnValue: FormReturn): Promise<void> {
    if (this.inputFrimoSetting[key] === returnValue.value) {
      return
    }

    this.inputFrimoSetting[key] = returnValue.value
    if (key === 'supplierCd') {
      this.clearFrimoSettingForm()
      if (returnValue.isValid) {
        await this.reloadFrimoSetting(this.inputFrimoSetting.supplierCd)
      }
      this.hideFrimoSetting = !returnValue.isValid
      return
    }
  }

  async reloadFrimoSetting(supplierCd: string): Promise<void> {
    this.clearFrimoSettingForm()
    if (!supplierCd) { return }
    const ret2 = await this.supplierMastersApiService.RetailsSupplierMasters(this.retailCd, [supplierCd])
    if (ret2.data[0]) {
      this.registedSupplierName = ret2.data[0]?.supplierName
      this.inputFrimoSetting.supplierName = this.registedSupplierName
    }
    this.editSupplierMasterMode = !this.registedSupplierName ? true : false
    // const ret3 = await this.serverGroupsApiService.GetMyServerGroups('frimo_core_product', supplierCd).toPromise()
    const ret3$ = this.serverGroupsApiService.GetMyServerGroups('frimo_core_product', supplierCd)
    const ret3 = await lastValueFrom(ret3$)

    if (ret3.data[0]) {
      this.registedServerGroupCd = ret3.data[0]?.serverGroupCd
    }
    this.inputFrimoSetting.serverGroupCd = ret3.data[0]?.serverGroupCd || this.serverSelecter[0].value
    this.hideFrimoSetting = false
  }

  async update(): Promise<void> {
    let allValid = true
    this.inputForms.map(p => p).forEach(inputForm => {
      const valid = inputForm.submit()
      if (!valid) {
        allValid = false
      }
    })
    const submitForm = this.inputTable.submit()
    if (!submitForm || !allValid) {
      return
    }
    const supplierCd = this.frimoActuates?.supplierCd || this.inputFrimoSetting.supplierCd
    if (this.sending) { return }
    this.sending = true
    this.frimoActuates.supplierCd = supplierCd
    this.frimoActuates.webEdiId = submitForm.webEdiId
    this.frimoActuates.webEdiPassword = submitForm.webEdiPassword
    // const ret = await this.actuatesService.UpdateWebEdi(this.frimoActuates).toPromise()
    const ret$ = this.actuatesService.UpdateWebEdi(this.frimoActuates)
    const ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) {
      this.messageService.error(`更新できませんでした。${ret?.resultMessage ?? ''}`)
      this.sending = false
      return
    }

    if (!this.registedSupplierName
      || this.registedSupplierName !== this.inputFrimoSetting.supplierName) {
      const supllierInfoInput: SupplierMasterMacro = {
        supplierCd,
        supplierName: this.inputFrimoSetting.supplierName,
        retailCd: this.retailCd
      }
      const ret2 = await this.supplierMastersApiService.UpsertSupplierMasters([supllierInfoInput])
      if (ret2?.resultCode !== 0) { return }
    }
    if (!this.registedServerGroupCd
      || this.registedServerGroupCd !== this.inputFrimoSetting.serverGroupCd) {
      const req: ServerGroupMacro[] = [
        {
          targetCd: supplierCd,
          systemCd: 'frimo_core_product',
          serverGroupCd: this.inputFrimoSetting.serverGroupCd
        },
        {
          targetCd: supplierCd,
          systemCd: 'shipment_pack_product',
          serverGroupCd: this.inputFrimoSetting.serverGroupCd
        }
      ]
      // const ret2 = await this.serverGroupsApiService.RegistServerGroups(req).toPromise()
      const ret2$ = this.serverGroupsApiService.RegistServerGroups(req)
      const ret2 = await lastValueFrom(ret2$)

      if (ret2?.resultCode !== 0) { return }
    }
    this.sending = false
    this.messageService.templeteMessage(messageList.M00043)
    this.updateed.emit(this.actuateCd)
    this.dialogOpen = false
  }
}
