import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { IgxDialogComponent } from '@infragistics/igniteui-angular'
import { lastValueFrom } from 'rxjs'
import { MerchandiseCompositionCd } from 'src/api/estimates-api/estimates-api.interface'
import { MerchandiseForAddContractRequest } from 'src/api/merchandises-api/merchandises-api.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { AutoMap, DeepCopy } from 'src/app/common/common'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { ServiceMapViewBlock, ServiceMapActionBlock, TargetServiceBlockInfo, TargetActionBlockInfo } from 'src/app/module/myidea-parts/service-map-view.interface'
import { ServiceMapViewService } from 'src/app/module/myidea-parts/service-map-view.service'
import { AddOptionQuotationService } from '../service/add-option-quotation.service'
import { AddProductQuotationService } from '../service/add-product-quotation.service'
import { ChangeInfo, AddOptionQuotationSession } from '../service/ura-create-quotation.interface'
@Component({
  selector: 'app-ura-add-contract-service-map',
  templateUrl: './ura-add-contract-service-map.component.html'
})
export class UraAddContractServiceMapComponent implements OnInit {
  title = 'サービスの追加・変更'
  organizationCompanyCd: string
  contractOrganizationCd: string
  changeMerchandises: ChangeInfo[] = []
  loading = true
  serviceMap: ServiceMapViewBlock[] = []
  mapActionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.pageBack()
      },
    },
    {
      label: '見積金額を表示',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]
  addProductActionBlock: ServiceMapActionBlock = {
    actionText: '＋新規サービスを追加する',
    classes: ['connect-block']
  }
  @ViewChild('addContractDialog', { static: true })
  addContractDialog: IgxDialogComponent

  // 追加モーダル商品リスト
  selecterOptions: MerchandiseForAddContractRequest[]
  parentContractCd = '' // 選択中の親のContractCd
  changeMerchandisesForThisDialog: ChangeInfo[] = [] // 現在のモーダル上での変更

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private addOptionQuotationService: AddOptionQuotationService,
    private addProductQuotationService: AddProductQuotationService,
    private serviceMapViewService: ServiceMapViewService,
    private merchandisesService: MerchandisesApiService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCompanyCd = params.get('organizationCompanyCd')
      this.contractOrganizationCd = params.get('contractOrganizationCd')
      const session = this.addOptionQuotationService.Session
      // 保存されている契約組織と異なる場合はクリア
      if (session.contractOrganizationCd !== this.contractOrganizationCd
      ) {
        this.saveSession([])
      }
      this.changeMerchandises = session.addMerchandises
      this.getServiceMapView()
    })
  }

  // 変更内容をserviceに保存
  saveSession(changeMerchandise: ChangeInfo[]): void {
    this.addOptionQuotationService.clearSession()
    const sessionValue: AddOptionQuotationSession = {
      organizationCompanyCd: this.organizationCompanyCd,
      contractOrganizationCd: this.contractOrganizationCd,
      addMerchandises: changeMerchandise
    }
    this.addOptionQuotationService.setSession(sessionValue)
  }

  async getServiceMapView(): Promise<void> {
    this.loading = true
    // サービスマップ取得
    const apiMapData = await this.serviceMapViewService.getServiceMapSearchByOrgFromApi(this.contractOrganizationCd)
    let map = await this.serviceMapViewService.convertBaseServiceMap(apiMapData, this.organizationCompanyCd, this.contractOrganizationCd)
    map = this.addColumnForAdd(map)
    this.serviceMap = await this.serviceMapViewService.filterConnected(map)
    this.loading = false
  }

  // 専用値追加 サービス追加サービスマップ用
  addColumnForAdd(map: ServiceMapViewBlock[]): ServiceMapViewBlock[] {
    const func = (value: TargetServiceBlockInfo) => {
      if (value.targetBlock.notContractCompany) { return }
      // 変更内容を一旦削除
      value.targetBlock.children = value.targetBlock.children?.filter(x => x.contractCd)
      // 変更内容を取得
      const childChangeInfos = this.changeMerchandises?.filter(x =>
        x.merchendiseAfter.parentContractCd === value.targetBlock.contractCd
      )
      childChangeInfos?.forEach(changeInfo => {
        if (changeInfo.merchendiseAfter.merchandiseDetails?.length) {
          let addFlag = true
          // 子要素がある場合
          value.targetBlock.children?.forEach(child => {
            if (child.merchandiseCd?.match('account_box')) {
              addFlag = false
              if (value.targetBlock.children.find(x => x.merchandiseCd.match('account_box'))) {
                child.classes = []
                child.merchandiseDisplayName
                  = changeInfo.merchendiseAfter.merchandiseDetails.find(x => x.contractedQuantity > 0)?.merchandiseDisplayName
                child.classes.push('back-green')
              }
            }
            // ナフコ ラベルオプション
            if (child.merchandiseCd?.match('_label')) {
              addFlag = false
              if (value.targetBlock.children.find(x => x.merchandiseCd.match('_label'))) {
                child.classes = []
                child.merchandiseDisplayName
                  = changeInfo.merchendiseAfter.merchandiseDetails.find(x => x.contractedQuantity > 0)?.merchandiseDisplayName
                child.classes.push('back-green')
              }
            }
          })
          if (addFlag) {
            value.targetBlock.children.push(
              {
                merchandiseDisplayName:
                  changeInfo.merchendiseAfter.merchandiseDetails.find(x => x.contractedQuantity > 0)?.merchandiseDisplayName,
                classes: ['back-green']
              }
            )
          }
        }
        // 数量選択できる商品(出荷Packコネクタなど)の場合
        else if (changeInfo.merchendiseAfter.contractedQuantity > 1) {
          const addQuantity = changeInfo.merchendiseAfter.contractedQuantity - changeInfo.merchendiseBefore.contractedQuantity
          for (let i = 0; i < addQuantity; i++) {
            value.targetBlock.children.push(
              {
                merchandiseDisplayName: changeInfo.merchendiseAfter.merchandiseDisplayName,
                classes: ['back-green']
              }
            )
          }
        }
        else {
          value.targetBlock.children.push(
            {
              merchandiseDisplayName: changeInfo.merchendiseAfter.merchandiseDisplayName,
              classes: ['back-green']
            }
          )
        }
      })
      if (value.floorNumber === 1 || value.floorNumber === 3) {
        value.targetBlock.actionBlocks = [
          {
            actionText: '＋オプションを追加する',
            classes: ['connect-block']
          }
        ]
      }
    }
    this.serviceMapViewService.runForAllServiceFloor(map, func)
    return map
  }

  pageBack(): void {
    // セッション削除
    this.addOptionQuotationService.clearSession()
    this.router.navigate([`/ura-conpany-contracted-organization-list`])
  }

  // 新規サービス見積り
  adminquotation(): void {
    this.addProductQuotationService.clearSession()
    const session = this.addProductQuotationService.Session
    session.organizationCompanyCd = this.organizationCompanyCd
    session.contractOrganizationCd = this.contractOrganizationCd
    this.addProductQuotationService.setSession(session)
    this.router.navigate([`/ura-add-product-retail/${this.organizationCompanyCd}/${this.contractOrganizationCd}/0`])
  }

  // オプション追加モーダル表示
  async openDialog(value: TargetActionBlockInfo): Promise<void> {
    this.selecterOptions = []
    this.changeMerchandisesForThisDialog = []
    this.addContractDialog.open()
    // const ret = await this.merchandisesService
    //   .SearchOptionMerchandises(value.parentBlock.actuateCd).toPromise()
    const ret$ = this.merchandisesService.SearchOptionMerchandises(value.parentBlock.actuateCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }

    this.selecterOptions = AutoMap<MerchandiseForAddContractRequest[]>(ret.data)
    this.parentContractCd = value.parentBlock.contractCd

    // 見積もり保存時に必要な項目をSET
    this.selecterOptions.forEach(option => {
      option.parentContractCd = this.parentContractCd
      let baseContractCd = null
      option.merchandiseDetails?.forEach(optionDetail => {
        value.parentBlock.children?.forEach(mapChild => {
          if (mapChild.merchandiseCd === optionDetail.merchandiseCd) {
            baseContractCd = mapChild.contractCd
          }
        })
      })
      option.merchandiseDetails?.forEach(optionDetail => {
        optionDetail.baseContractCd = baseContractCd
      })
    })

    // 既に変更済の情報があれば反映
    this.selecterOptions.forEach(merchandise => {
      const changeInfo = this.sevedChangeInfo(merchandise.merchandiseCd, this.parentContractCd)
      if (changeInfo) {
        const changeInfoCopy = DeepCopy(this.sevedChangeInfo(merchandise.merchandiseCd, this.parentContractCd))
        this.changeMerchandisesForThisDialog.push(changeInfoCopy)
        merchandise.contractedQuantity = changeInfoCopy.merchendiseAfter.contractedQuantity
        // 子階層も反映 (Aboxはこの階層が存在する)
        merchandise.merchandiseDetails?.forEach(childMerchandise => {
          const matchChild = changeInfoCopy.merchendiseAfter.merchandiseDetails?.find
            (x => x.merchandiseCd === childMerchandise.merchandiseCd
            )
          childMerchandise.contractedQuantity = matchChild.contractedQuantity
        })
      }
    })
  }

  // 対象商品の選択済情報を取得(モーダルでの選択確定済)
  sevedChangeInfo(
    merchandiseCd: string, // オプションが2階層の場合も1階層目のCDを指定
    parentContractCd: string
  ): ChangeInfo {
    return this.changeMerchandises?.find(x =>
      x.merchendiseAfter.parentContractCd === parentContractCd
      && x.merchendiseBefore.merchandiseCd === merchandiseCd
    )
  }

  // 対象商品の選択済情報を取得(モーダル内での一時操作)
  tmpChangeInfo(
    merchandiseCd: string, // オプションが2階層の場合も1階層目のCDを指定
  ): ChangeInfo {
    return this.changeMerchandisesForThisDialog?.find(x =>
      x.merchendiseAfter.parentContractCd === this.parentContractCd
      && x.merchendiseBefore.merchandiseCd === merchandiseCd
    )
  }

  // 子要素選択の形式の商品かどうか
  isSelecter(merchandise: MerchandiseForAddContractRequest): boolean {
    return merchandise?.merchandiseCompositionCd === MerchandiseCompositionCd.SELECT
  }
  // ラジオ選択形式商品かどうか
  isRadioSelecter(merchandise: MerchandiseForAddContractRequest): boolean {
    return this.isSelecter(merchandise) && merchandise.chooseQuantity === 1
  }

  // オプションに対する説明文言
  optionTextsAttention(merchandise: MerchandiseForAddContractRequest): string {
    // 選択
    if (this.isSelecter(merchandise)) {
      const total = this.getChildTotalQuantity(merchandise)
      return `${total}/${merchandise.chooseQuantity * merchandise.contractedQuantity}件選択中`
    }
    return ''
  }

  // 子要素の総数量
  getChildTotalQuantity(merchandise: MerchandiseForAddContractRequest): number {
    let totalQuantity = 0
    merchandise.merchandiseDetails.forEach((detail) => {
      totalQuantity += detail.contractedQuantity
    })
    return totalQuantity
  }

  isDisabledCheckBox(
    thisMerchandise: MerchandiseForAddContractRequest,
    parentMerchandise?: MerchandiseForAddContractRequest
  ): boolean {
    if (thisMerchandise.required) { return true }
    // オプション子階層の場合
    if (parentMerchandise) {
      // ラジオボタンの場合
      if (this.isRadioSelecter(parentMerchandise)) {
        // 選択済の要素をクリックした場合はNG
        if (thisMerchandise.contractedQuantity > 0) { return true }
      }
    }
    else {
      // 最初の数量から減らそうとした場合はNG
      const changeInfo = this.tmpChangeInfo(thisMerchandise.merchandiseCd)
      if (!changeInfo && thisMerchandise.contractedQuantity > 0) {
        return true
      }
      if (changeInfo
        && changeInfo.merchendiseBefore.contractedQuantity === thisMerchandise.contractedQuantity) {
        return true
      }
    }
    return false
  }

  // 選択ON/OFF切り替え
  changeSelected(
    thisMerchandise: MerchandiseForAddContractRequest,
    parentMerchandise?: MerchandiseForAddContractRequest
  ): void {
    const addQuantity = thisMerchandise.contractedQuantity ? -thisMerchandise.contractedQuantity : 1
    this.changeUnitQuantity(addQuantity, thisMerchandise, parentMerchandise)
  }

  // 数量変更
  changeUnitQuantity(
    addQuantity: number,
    thisMerchandise: MerchandiseForAddContractRequest,
    parentMerchandise?: MerchandiseForAddContractRequest,
  ): void {
    const afterQuantity = thisMerchandise.contractedQuantity + addQuantity
    // 必須商品を0以下にしようとした場合はスルー
    if (thisMerchandise.required && afterQuantity <= 0) {
      return
    }
    // 最初の数量から減らそうとした場合はスルー
    const changeInfo = this.tmpChangeInfo(thisMerchandise.merchandiseCd)
    if (changeInfo && changeInfo.merchendiseBefore.contractedQuantity > afterQuantity) {
      return
    }
    // オプション子階層の場合
    if (parentMerchandise) {
      const parentChangeInfo = this.tmpChangeInfo(parentMerchandise.merchandiseCd)
      const backupParentMerchandise = DeepCopy(parentChangeInfo?.merchendiseBefore || parentMerchandise)
      // ラジオボタンの場合
      if (this.isRadioSelecter(parentMerchandise)) {
        // 選択済の要素をクリックした場合はスルー
        if (!afterQuantity) { return }

        parentMerchandise.merchandiseDetails.forEach(child => {
          if (child.merchandiseCd === thisMerchandise.merchandiseCd) {
            child.contractedQuantity = afterQuantity
          }
          else {
            child.contractedQuantity = 0
          }
        })
      }
      // 複数選択の場合
      if (this.isSelecter(parentMerchandise)
        && parentMerchandise.multiple) {
        parentMerchandise.merchandiseDetails.forEach(child => {
          if (child.merchandiseCd === thisMerchandise.merchandiseCd) {
            child.contractedQuantity = afterQuantity
          }
        })
      }
      this.updateChangeInfo(backupParentMerchandise, parentMerchandise)
    }
    else {
      const backupThisMerchandise = DeepCopy(changeInfo?.merchendiseBefore || thisMerchandise)
      thisMerchandise.contractedQuantity = afterQuantity
      this.updateChangeInfo(backupThisMerchandise, thisMerchandise)
    }
  }

  // 変更内容を保存する
  updateChangeInfo(before: MerchandiseForAddContractRequest, after: MerchandiseForAddContractRequest): void {
    // 保存済情報を一旦削除
    this.changeMerchandisesForThisDialog = this.changeMerchandisesForThisDialog.filter(x =>
      !(x.merchendiseAfter.parentContractCd === this.parentContractCd
        && x.merchendiseBefore.merchandiseCd === before.merchandiseCd))

    // 変更前後で数量に差があるか
    let isChange = false
    if (before.contractedQuantity !== after.contractedQuantity) {
      isChange = true
    }
    before.merchandiseDetails?.forEach(beforeChild => {
      const afterChild = after.merchandiseDetails.find(x => x.merchandiseCd === beforeChild.merchandiseCd)
      if (afterChild.contractedQuantity !== beforeChild.contractedQuantity) {
        isChange = true
      }
    })

    // 差があった場合は変更対象に保存
    if (isChange) {
      const newAdd: ChangeInfo = {
        merchendiseBefore: DeepCopy(before),
        merchendiseAfter: DeepCopy(after)
      }
      this.changeMerchandisesForThisDialog.push(newAdd)
    }
  }

  // 変更内容をSessionに保存しサービスマップ表示に反映
  changeMap(): void {
    // 一旦削除
    this.changeMerchandises = this.changeMerchandises.filter(x => x.merchendiseAfter.parentContractCd !== this.parentContractCd)
    // 追加
    this.changeMerchandises = [...this.changeMerchandises, ...this.changeMerchandisesForThisDialog]
    this.saveSession(this.changeMerchandises)

    this.getServiceMapView()
    this.addContractDialog.close()
  }

  // 見積プレビュー画面へ
  pageNext(): void {
    if (!this.changeMerchandises.length) {
      return
    }
    this.router.navigate([`ura-quotation-preview-add-option/${this.organizationCompanyCd}/${this.contractOrganizationCd}`])
  }
}
