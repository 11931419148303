import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { Tag } from 'src/api/common/api-common.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { NewQuotationService } from '../service/new-quotation.service'
import { QuotationSelectedGroup } from '../service/ura-create-quotation.interface'
@Component({
  selector: 'app-ura-new-quotation-retail',
  templateUrl: './ura-new-quotation-retail.component.html',
  styleUrls: ['./ura-new-quotation-retail.component.scss']
})
export class UraNewQuotationRetailComponent implements OnInit {
  title = '新規見積もり作成'
  index: string
  targetOrganizationCustomer: OrganizationCustomer = this.newQuotationService.Session?.targetOrganizationCustomer
  session: QuotationSelectedGroup
  tags: Tag[] = []
  selectedTagCd: string
  loading = true
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        const backIndex = Number(this.index) - 1
        if (this.newQuotationService.Session?.selectedGroups[backIndex]) {
          this.router.navigate([`/ura-new-quotation-merchandise/${backIndex}`])
        }
        else {
          this.router.navigate([`/ura-estimate-new`])
        }
      },
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]
  constructor(
    private activatedRoute: ActivatedRoute,
    private merchandisesService: MerchandisesApiService,
    private router: Router,
    private newQuotationService: NewQuotationService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.index = params.get('index')
      const check = this.newQuotationService.pageInit(this.index, true)
      if (!check) { return }

      this.session = this.newQuotationService.getSelectedGroupSession(this.index)
      this.selectedTagCd = this.session.retail?.tagCd
      this.getRetailTags()
    })
  }

  // 小売情報取得
  async getRetailTags(): Promise<void> {
    // const ret = await this.merchandisesService.RetailTags().toPromise()
    const ret$ = this.merchandisesService.RetailTags()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.tags = ret.data
    if (!this.selectedTagCd) {
      this.selectedTagCd = this.tags[0].tagCd
    }
    this.loading = false
  }

  async pageNext(): Promise<void> {
    if (!this.selectedTagCd) { return }
    if (this.session.retail && this.session.retail.tagCd !== this.selectedTagCd) {
      this.newQuotationService.clearIndexSession(this.index)
      this.session = this.newQuotationService.getSelectedGroupSession(this.index)
    }
    this.session.retail = this.tags.find(x => x.tagCd === this.selectedTagCd)

    const tagData = await this.getBusinessCategoryTags()
    this.session.businessCategoryTagList = tagData
    this.newQuotationService.setSelectedGroupSession(this.session, this.index)
    if (tagData.length) {
      this.router.navigate([`/ura-new-quotation-business-category/${this.index}`])
    } else {
      this.router.navigate([`/ura-new-quotation-service/${this.index}`])
    }
  }

  // 業態情報取得
  async getBusinessCategoryTags(): Promise<Tag[]> {
    const retailTagCd = this.session.retail.tagCd
    // const ret = await this.merchandisesService.SearchBusinessCategoryTags(retailTagCd).toPromise()
    const ret$ = this.merchandisesService.SearchBusinessCategoryTags(retailTagCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return [] }
    return ret.data
  }
}
