<app-sending *ngIf="sending"></app-sending>
<p class="c-texts--attention h-margin--b16">＊は入力必須項目です</p>
<app-input-table [formItems]="formItems">
  <div class="l-table__item">
    <div class="l-table__head">
      <p>所属会社</p>
    </div>
    <div class="l-table__body">
        {{ organizationNames }}
    </div>
  </div>
</app-input-table>
<br />
<app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
