export interface BackendOrganizationCustomer extends BEMacroCustomer {
  authorityTypeName: string
}
export interface Customer {
  customerCd?: string
  familyName: string
  firstName: string
  familyNameKana: string
  firstNameKana: string
  mailAddress: string
}

export interface CustomerUi {
  customerCd?: string
  familyName: string
  firstName: string
  familyNameKana: string
  firstNameKana: string
  mailAddress: string
}

export interface OrganizationWithCustomer {
  organizationCd: string
  organizationName: string
  organizationFomalName: string
  customers: CustomerUi[]
}

export interface BEMacroCustomer extends Customer {
  accountId?: string
}
