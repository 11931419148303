import { OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'

export interface User {
  customerCd: string,
  mailAddress: string,
  fullName: string,
  organizationName: string
}

export interface CompanyCustomer {
  organizationCd: string
  customers: OrganizationCustomer[]
}
