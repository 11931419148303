import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { AutoMap } from 'src/app/common/common'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { ActionButtonDisp } from '../../common-parts/under-btn-disp/under-btn-disp.interface'
import { GridBaseService } from './action-grid.service'
import { BaseGridComponent } from '../base-grid/base-grid.component'
import { ActionTargetInfo, GridActionButton } from './action-grid.component.interface'
import { Header, RowSelection, SelectedRow, UpdateCheckBox } from '../base-grid/base-grid.component.interface'
@Component({
  selector: 'app-action-grid',
  templateUrl: './action-grid.component.html',
  styleUrls: ['./action-grid.component.scss']
})
export class ActionGridComponent implements OnInit, OnChanges {
  constructor(
    private gridBaseService: GridBaseService,
    private confirmService: ConfirmService
  ) { }

  @ViewChild('grid', { static: true })
  public grid: BaseGridComponent
  // データ
  @Input() data: any[]
  // アクションボタンの定義
  @Input() actionButtons: GridActionButton[] = []
  @Input() rowSelection = RowSelection.NONE
  @Input() rowClickAction = false
  @Input() loading = false
  @Input() columns: Header[] = []
  @Input() exportExcelButton = false
  @Input() height: number
  @Input() minusHeight = 0 // マイナスしたい高さをピクセル指定
  @Output() actionCommit = new EventEmitter<ActionTargetInfo>()
  @Output() selectionChange = new EventEmitter<SelectedRow<any>>()
  @Output() updateCheckBox = new EventEmitter<UpdateCheckBox<any>[]>()
  actionTargetInfo: any[]
  actionButtonsDisp: ActionButtonDisp[] = []

  // セルの条件付き書式(外から自由に条件指定)
  @Input() cellClasses = null


  ngOnInit(): void {
    this.setDispButtons()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDispButtons()
  }

  setDispButtons(): void {
    // ボタン表示しない
    if (this.rowClickAction && !this.exportExcelButton) {
      this.actionButtonsDisp = []
      return
    }

    this.actionButtonsDisp = this.gridBaseService.getActionButtonsDisp(this.actionTargetInfo || [], this.actionButtons)
    if (this.exportExcelButton && !this.actionButtonsDisp.find(x => x.actionCd === 'excelDL')) {
      this.actionButtonsDisp.push({
        label: 'Excelエクスポート',
        actionCd: 'excelDL',
        actionFunction: () => {
          this.grid.exportButtonHandler()
        }
      })
    }
  }

  buttonActionCommit(actionCd: string): void {
    if (!actionCd) {
      actionCd = this.actionButtons[0]?.actionCd
    }
    if (actionCd) {
    // 確認モーダル
    const targetAction = this.actionButtons.find(x => x.actionCd === actionCd)
    if (targetAction.confirmDialog) {
      this.confirmService.confirm(`本当に${targetAction.label}しますか？`, '', () => {
        this.commit(actionCd)
      })
      return
    }
    }
    this.commit(actionCd)
  }

  commit(actionCd: string): void {
    if (actionCd) {
      this.gridBaseService.actionClick(actionCd, this.actionButtons, this.actionTargetInfo, this.columns)
    }

    // 行全体をリターンする
    const rows: any[] = []
    this.actionTargetInfo?.forEach(row => {
      const targetRow = AutoMap<any>(row)
      rows.push(targetRow)
    })
    const par: ActionTargetInfo = {
      actionCd,
      rows
    }
    this.actionCommit.emit(par)
  }

  setActionTargetInfo(selectedRows: any[]): void {
    this.actionTargetInfo = selectedRows
    // クリック時アクション実行
    if (this.rowClickAction) {
      this.buttonActionCommit(this.actionButtons[0]?.actionCd)
      return
    }
    this.setDispButtons()
  }

  sendSelectionChange(selectedRows: SelectedRow<any>): void{
    this.selectionChange.emit(selectedRows)
  }
  onUpdateCheckBox(event: UpdateCheckBox<any>[]): void{
    this.updateCheckBox.emit(event)
  }
}
