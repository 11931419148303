import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { BackendTagMaster, Tag } from 'src/api/common/api-common.interface'
import { Product, SelecterSetting, ActuateSetting, BackendProductDatail, ChildrenProduct } from 'src/api/products-api/products-api.interface'
import { ProductsApiService } from 'src/api/products-api/products-api.service'
import { DeepCopy } from 'src/app/common/common'
import { MessageService } from 'src/app/components/message/message.service'
export interface SelecterInfo {
  key: string
  label: string
  type?: string
  selecter: Selecter[]
}
export interface Selecter {
  value: any
  label: string
}

@Component({
  selector: 'app-product-edit-dialog',
  templateUrl: './product-edit-dialog.component.html',
  styleUrls: ['./product-edit-dialog.component.scss']
})
export class ProductEditDialogComponent implements OnInit {
  loading = false
  sending = false
  @Input() products: Product[]
  @Output() addProduct = new EventEmitter<Product>()
  @Output() updateProduct = new EventEmitter<Product>()
  productDetail: BackendProductDatail = null
  selecterSettingEmpty: SelecterSetting = {
    required: true,
    multiple: true
  }
  productDetailEmpty: BackendProductDatail = {
    productCd: '',
    productName: '',
    productAbbreviatedCd: '',
    selecterSetting: DeepCopy(this.selecterSettingEmpty),
    actuateSettings: [],
    childrenProduct: [],
    tags: []
  }
  tagMaster: BackendTagMaster[] = []
  tagEditMode = false
  childEditMode = false
  selectSettingEmpty: SelecterSetting = {
    required: true,
    multiple: true
  }
  searchChildInput = ''
  childSelection: Product[] = []
  childSelectionAndMore = false
  newEditMode = false
  actuateSettingEmpty: ActuateSetting = {
    key: '',
    name: '',
    value: '',
    type: 'Number',
    editable: 0
  }
  selecterInfoList: SelecterInfo[] = [
    {
      key: 'required',
      label: '親製品を購入時に必須',
      selecter: [
        {
          value: true,
          label: '必須'
        },
        {
          value: false,
          label: '任意'
        }
      ]
    },
    {
      key: 'multiple',
      label: '複数購入',
      selecter: [
        {
          value: true,
          label: '可'
        },
        {
          value: false,
          label: '不可'
        }
      ]
    }
  ]
  dialogOpen = false

  constructor(
    private productsApiService: ProductsApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getTagMaster()
  }

  getTagMaster(): void {
    this.productsApiService
      .GetTagMaster()
      .subscribe((ret: any) => {
        this.tagMaster = ret.data
      })
  }

  reset(): void {
    this.productDetail = DeepCopy(this.productDetailEmpty)
    this.newEditMode = false
    this.tagEditMode = false
    this.childEditMode = false
    this.searchChildInput = ''
    this.childSelection = []
    this.childSelectionAndMore = false
  }

  openUpdateDialog(productCd: string): void {
    this.reset()
    this.getProductDetail(productCd)
    this.dialogOpen = true
  }

  openCreateDialog(): void {
    this.reset()
    this.newEditMode = true
    this.dialogOpen = true
  }

  changeSelecter(key, e): void {
    const value = e.valueAccessor.value
    if (this.productDetail.selecterSetting[key] === value) {
      return
    }
    this.productDetail.selecterSetting[key] = value
  }

  selectedLabel(key: string): string {
    const item = this.selecterInfoList.find(x => x.key === key)
    return item.selecter.find(x => x.value === this.productDetail.selecterSetting[key])?.label
  }

  tagAttachment(tagCd: string): boolean {
    const tags = this.productDetail?.tags
    if (!tags) {
      return false
    }
    return tags.find(x => x.tagCd === tagCd) ? true : false
  }

  addActuateSetting(): void {
    const empty = DeepCopy(this.actuateSettingEmpty)
    this.productDetail.actuateSettings.push(empty)
  }

  searchChildProduct(searchKey: string): void {
    if (searchKey === '') {
      this.childSelection = []
      return
    }
    let filter = this.products.filter(x => x.productName.indexOf(searchKey) !== -1)
    this.productDetail.childrenProduct.forEach(child => {
      filter = filter.filter(x => x.productCd !== child.productCd)
    })
    // 自分自身も除外
    filter = filter.filter(x => x.productCd !== this.productDetail.productCd)
    if (filter.length > 10) {
      filter = filter.slice(0, 10)
      this.childSelectionAndMore = true
    }
    else {
      this.childSelectionAndMore = false
    }
    this.childSelection = filter
  }

  childClick(productCd: string): void {
    const child = this.productDetail?.childrenProduct
    if (!child) {
      return
    }
    const selectFlg = child.find(x => x.productCd === productCd) ? true : false
    if (selectFlg) {
      this.productDetail.childrenProduct = this.productDetail.childrenProduct.filter(x => x.productCd !== productCd)
    }
    else {
      const child2 = this.products.find(x => x.productCd === productCd)
      const addChild: ChildrenProduct = {
        productCd: child2.productCd,
        productName: child2.productName
      }
      this.productDetail.childrenProduct.push(addChild)
      this.childSelection = this.childSelection.filter(x => x.productCd !== child2.productCd)
    }
  }

  tagClick(tagCd: string): void {
    const tags = this.productDetail?.tags
    if (!tags) {
      this.productDetail.tags = []
    }
    const selectFlg = tags.find(x => x.tagCd === tagCd) ? true : false
    if (selectFlg) {
      this.productDetail.tags = this.productDetail.tags.filter(x => x.tagCd !== tagCd)
    }
    else {
      const addTag = this.tagInfo(tagCd)
      this.productDetail.tags.push(addTag)
    }
  }

  tagInfo(tagCd: string): Tag {
    if (!this.tagMaster) {
      return null
    }
    for (const purpose of this.tagMaster) {
      const findTag = purpose.textTags.find(x => x.tagCd === tagCd)
      if (findTag) {
        const tag: Tag = {
          tagPurposeCd: purpose.tagPurposeCd,
          tagPurposeText: purpose.tagPurposeText,
          tagCd: findTag.tagCd,
          tagText: findTag.tagText
        }
        return tag
      }
    }
    return null
  }

  async getProductDetail(productCd: string): Promise<void> {
    const ret = await this.productsApiService.GetProductDetail(productCd)
    if (ret?.resultCode !== 0) { return }
    this.productDetail = ret.data
  }

  removeActuateSetting(index: number): void {
    this.productDetail.actuateSettings.splice(index, 1)
  }

  removeChild(productCd: string): void {
    this.productDetail.childrenProduct = this.productDetail.childrenProduct.filter(x => x.productCd !== productCd)
  }

  commit(): void {
    if (this.sending) {
      return
    }
    this.productDetail.actuateSettings.forEach(setting => {
      switch (setting.type) {
        case 'Number':
          setting.value = Number(setting.value) ? Number(setting.value) : null
          break
        case 'String':
          setting.value = setting.value ? setting.value.toString() : null
      }
    })

    if (this.sending) { return }
    this.sending = true
    this.dialogOpen = false
    if (this.newEditMode) {
      this.productsApiService
        .CreateProduct(this.productDetail)
        .subscribe((ret: any) => {
          this.sending = false
          this.messageService.success('処理完了')
          const data: BackendProductDatail = ret.data[0]
          const newData: Product = {
            productCd: data.productCd,
            productName: data.productName
          }
          this.addProduct.emit(newData)
        })
    }
    else {
      this.productsApiService
        .UpdateProduct(this.productDetail)
        .subscribe((ret: any) => {
          this.sending = false
          this.messageService.success('処理完了')
          const data: BackendProductDatail = ret.data[0]
          const newData: Product = {
            productCd: data.productCd,
            productName: data.productName
          }
          this.updateProduct.emit(newData)
        })
    }
  }

  deleteComform(): void {
    this.childEditMode = false
    this.tagEditMode = false
  }
}
