import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { HttpClient } from '@angular/common/http'
import {
  BackendOrganizationCustomerAccount,
  BackendOrganizationV2,
  CustomerCheckRequest,
  CustomerCheckResponse,
  DisplayPosition,
  OrganizationCategory,
  OrganizationCustomer,
  OrganizationFullName,
  OrganizationRelationCustomerCheck,
  OrganizationTreeWithCustomersModel,
  OrganizationType
} from './organizationV2-api.model'
import { BackendOrganization } from '../organization-company-api/organization-company-api.interface'

@Injectable({
  providedIn: 'root'
})
export class OrganizationV2Service {
  baseURL = environment.backendMacroBaseUrl
  myideaMacroBaseURL = environment.myideaMacroBaseUrl

  constructor(private httpRequestService: HttpRequestService, private httpClient: HttpClient) {}

  // 組織情報更新
  OrganizationsUpdate(payload: any, organizationCd: string): Observable<ResultInfo<BackendOrganizationV2>> {
    const url = `${this.baseURL}api/v2/Organizations/${organizationCd}`
    return this.httpRequestService.put(url, payload)
  }

  // 組織検索,
  OrganizationInfo(organizationCd: string): Observable<ResultInfo<BackendOrganization>> {
    const url = `${this.baseURL}api/v2/Organizations/${organizationCd}/OrganizationInfo`
    return this.httpRequestService.get(url)
  }

  // 組織ツリー＋人（組織図用）
  OrganizationTreesWithCustomers(organizationCd: string): Observable<ResultInfo<OrganizationTreeWithCustomersModel>> {
    const url = `${this.baseURL}api/v2/OrganizationTreesWithCustomers/${organizationCd}`
    return this.httpRequestService.get(url)
  }

  // 組織名変更（組織図用）
  PutOrganizationTrees(organizationCd: string, payload): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/v2/OrganizationTrees/${organizationCd}`
    return this.httpRequestService.put(url, payload)
  }

  // 組織追加（組織図用）
  OrganizationTreesChild(organizationCd: string, payload): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/v2/OrganizationTrees/${organizationCd}/Child`
    return this.httpRequestService.post(url, payload)
  }

  // 組織親子紐づけ設定（組織図用）
  PostChildOrganization(parentCd: string, childCd: string, payload): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/v2/OrganizationTrees/${parentCd}/ChildOrganization/${childCd}`
    return this.httpRequestService.post(url, payload)
  }

  // 組織親子紐づけ削除（組織図用）
  DeleteChildOrganization(parentCd: string, childCd: string): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/v2/OrganizationTrees/${parentCd}/ChildOrganization/${childCd}`
    return this.httpRequestService.delete(url, null)
  }

  // 組織カテゴリーマスタ取得
  GetOrganizationCategoryList(): Observable<ResultInfo<OrganizationCategory>> {
    const url = `${this.baseURL}api/v2/OrganizationCategory`
    return this.httpRequestService.get(url)
  }

  // 組織カテゴリー表記位置マスタ取得
  GetOrganizationDisplayPositionList(): Observable<ResultInfo<DisplayPosition>> {
    const url = `${this.baseURL}api/v2/OrganizationCategoryDisplayPosition`
    return this.httpRequestService.get(url)
  }

  // 組織種別マスタ取得
  GetOrganizationTypeList(): Observable<ResultInfo<OrganizationType>> {
    const url = `${this.baseURL}api/v2/OrganizationType`
    return this.httpRequestService.get(url)
  }

  // 組織（部署・WG・役割のみ）に含まれる顧客取得
  GetMebmberList(organizationCd: string): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/v2/Organizations/${organizationCd}/RelationCustomers`
    return this.httpRequestService.get(url)
  }

  // 上位組織（会社）に含まれる顧客取得
  public GetCompanyMebmbersList(organizationCd: string) {
    return this.httpClient.get(`${this.baseURL}api/v2/Organizations/${organizationCd}/CompanyMembers`)
  }

  // 上位組織（会社）に含まれる顧客とその顧客が配属・参加中の組織取得
  public CompanyMembersAndRelatedOrganizations(organizationCd: string) {
    return this.httpClient.get(
      `${this.baseURL}api/v2/Organizations/${organizationCd}/CompanyMembersAndRelatedOrganizations`
    )
  }

  // 組織（部署・WG・役割のみ）にメンバー紐づけ
  public PostOrganizationAndCustomerRelation(organizationCd: string, payload) {
    return this.httpClient.post(`${this.baseURL}api/v2/Organizations/${organizationCd}/Customer`, payload)
  }

  // 組織（部署・WG・役割のみ）のメンバー紐づけ削除
  public DeleteOrganizationAndCustomerRelation(organizationCd: string, payload) {
    // deleteでbodyがあると↓ではうまくいかない
    // return this.httpClient.post(`${baseURL}${V2Organizations}/${organizationCd}/DeleteCustomerRelation`, payload);
    const url = `${this.baseURL}api/v2/Organizations/${organizationCd}/Customer`
    return this.httpClient.request('delete', url, { body: payload })
  }

  // 組織（部署・WG・役割のみ）のメンバー紐づけ変更
  public PostChangeOrganizationAndCustomerRelation(organizationCd: string, newOrganizationCd: string, payload) {
    return this.httpClient.post(
      `${this.baseURL}api/v2/Organizations/${organizationCd}/NewOrganization/${newOrganizationCd}/Customer`,
      payload
    )
  }

  // チェック (下位階層に、配属ユーザがいるか)
  CheckOrganizationCustomerExist(organizationCds: string[]): Observable<ResultInfo<OrganizationRelationCustomerCheck>> {
    const url = `${this.baseURL}api/v2/Organizations/Check/CustomerExist`
    return this.httpRequestService.post(url, organizationCds)
  }

  // チェック (対象顧客が、その組織で「請求担当者」 or 「契約担当社」 or 「サービス利用者」になっていないか)
  CheckCustomers(payload: CustomerCheckRequest[]): Observable<ResultInfo<CustomerCheckResponse>> {
    const url = `${this.baseURL}api/v2/Organizations/Check/Customers`
    return this.httpRequestService.post(url, payload)
  }

  // 所属している組織の部署、役職付きのフル名称リストを取得
  GetOrganizationFullNameList(
    customerCd: string,
    dispRole: boolean = false
  ): Observable<ResultInfo<OrganizationFullName>> {
    const url = `${this.baseURL}api/v2/Customers/${customerCd}/OrganizationFullNameList?dispRole=${dispRole}`
    return this.httpRequestService.get(url)
  }

  // 所属している組織の部署、役職付きのフル名称リストを取得
  GetOrganizationFullName(organizationCd: string, dispRole: boolean = false): Observable<ResultInfo<any>> {
    const url = `${this.baseURL}api/v2/Organizations/${organizationCd}/OrganizationFullName?dispRole=${dispRole}`
    return this.httpRequestService.post(url, null)
  }

  // 顧客情報検索
  GetOrganizationCustomerList(
    organizationCd: string,
    allRelationSearch: boolean = false // 配属（会社）・所属（部署）の重複を無くす＝false　重複して表示する＝true
  ): Observable<ResultInfo<OrganizationCustomer>> {
    const url = `${this.baseURL}api/v2/Organizations/${organizationCd}/CustomerInfos?allRelationSearch=${allRelationSearch}`
    return this.httpRequestService.get(url)
  }

  // 組織内顧客＋アカウント検索
  GetCustomerAccountInfos(
    organizationCd: string = null,
    lowerSearch: boolean = false // 下層組織を含むかどうか
  ): Observable<ResultInfo<BackendOrganizationCustomerAccount>> {
    const url = `${this.myideaMacroBaseURL}api/organization/${organizationCd}/CustomerAccountInfos?lowerSearch=${lowerSearch}`
    return this.httpRequestService.get(url)
  }
}
