<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-progress *ngIf="sending"></app-progress>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>
<app-create-account-url-dialog></app-create-account-url-dialog>