import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { headerType } from 'src/app/components/header/header-division'
import { UraCompanyAddV2Component } from './ura-company-add/ura-company-add.component'
import { UraContractEntryListComponent } from './ura-contract-entry-list/ura-contract-entry-list.component'
import { UraTopComponent } from './ura-top/ura-top.component'
import { UraContractNewListComponent } from './ura-contract-new-list/ura-contract-new-list.component'
import { UraContactUserListComponent } from './ura-contact-user-list/ura-contact-user-list.component'
import { UraAddContractServiceMapComponent } from './ura-add-contract-service-map/ura-add-contract-service-map.component'
import { UraCancelContractServiceMapComponent } from './ura-cancel-contract-service-map/ura-cancel-contract-service-map.component'
import { UraConnectServiceMapComponent } from './ura-connect-service-map/ura-connect-service-map.component'
import { UraConactorLinkComponent } from './ura-conector-link/ura-conector-link.component'
import { UraCancelComponent } from './ura-cancel-page/ura-cancel-page.component'
import { UraNewCompanyProfileComponent } from './ura-new-company-profile/ura-new-company-profile.component'
import { UraSuperAdminComponent } from './ura-super-admin/ura-super-admin.component'
import { UraOrganizationCustomerListComponent } from './ura-organization-customer-list/ura-organization-customer-list.component'
import { UraCompanyInvoiceComponent } from './ura-company-invoice/ura-company-invoice.component'
import { UraCompanyUserComponent } from './ura-company-user/ura-company-user.component'
import { UraEstimateListComponent } from './ura-estimate-list/ura-estimate-list.component'
import { UraEstimateNewComponent } from './ura-estimate-new/ura-estimate-new.component'
import { UraAddNewContractDetailComponent } from './ura-add-new-contract-detail/ura-add-new-contract-detail.component'
import { UraQuotationPreviewNewComponent } from './ura-quotation-preview-new/ura-quotation-preview-new.component'
import { UraEstimateListNewComponent } from './ura-estimate-list-new/ura-estimate-list-new.component'
import { UraAddContractDetailComponent } from './ura-add-contract-detail/ura-add-contract-detail.component'
import { UraSupportSearchAccountComponent } from './ura-support-search-account/ura-support-search-account.component'
import { UraOrganizationTreeEditComponent } from './ura-organization-tree-edit/ura-organization-tree-edit.component'
import { UraOrganizationTreeViewComponent } from './ura-organization-tree-view/ura-organization-tree-view.component'
import { UraContractSearchListComponent } from './ura-contract-search-list/ura-contract-search-list.component'
import { UraServiceMapSearchComponent } from './ura-service-map-search/ura-service-map-search.component'
import { UraUserSearchListComponent } from './ura-user-search-list/ura-user-search-list.component'
import { UraCsvDownloadComponent } from './ura-csv-download/ura-csv-download.component'
import { UraCreateAddQuotationBusinessCategoryComponent } from './ura-create-add-quotation-business-category/ura-create-add-quotation-business-category.component'
import { UraCreateAddQuotationConfirmComponent } from './ura-create-add-quotation-confirm/ura-create-add-quotation-confirm.component'
import { UraCreateAddQuotationMerchandiseComponent } from './ura-create-add-quotation-merchandise/ura-create-add-quotation-merchandise.component'
import { UraCreateAddQuotationRemarksComponent } from './ura-create-add-quotation-remarks/ura-create-add-quotation-remarks.component'
import { UraNewQuotationBusinessCategoryComponent } from './ura-new-quotation-business-category/ura-new-quotation-business-category.component'
import { UraNewQuotationMerchandiseComponent } from './ura-new-quotation-merchandise/ura-new-quotation-merchandise.component'
import { UraNewQuotationRemarksComponent } from './ura-new-quotation-remarks/ura-new-quotation-remarks.component'
import { UraNewQuotationRetailComponent } from './ura-new-quotation-retail/ura-new-quotation-retail.component'
import { UraNewQuotationServiceComponent } from './ura-new-quotation-service/ura-new-quotation-service.component'
import { UraQuotationPreviewAddOptionComponent } from './ura-quotation-preview-add-option/ura-quotation-preview-add-option.component'
import { UraQuotationPreviewAddProductComponent } from './ura-quotation-preview-add-product/ura-quotation-preview-add-product.component'
import { UraInvoiceChangeComponent } from './ura-invoice-change/ura-invoice-change.component'
import { UraMonitoringPackSendTaskComponent } from './ura-monitoring-pack-send-task/ura-monitoring-pack-send-task.component'
import { UraCancelContarctListComponent } from './ura-cancel-contract-list/ura-cancel-contract-list.component'
import { UraMerchandiseListComponent } from './ura-merchandise-list/ura-merchandise-list.component'
import { UraProductListComponent } from './ura-product-list/ura-product-list.component'
import { UraAccountLinkComponent } from './ura-account-link/ura-account-link.component'
import { UraEditCompanyProfileComponent } from './ura-edit-company-profile/ura-edit-company-profile.component'
import { UraCompanyCheckListComponent } from './ura-company-check-list/ura-company-check-list.component'
import { UraSearchRelationCorporateComponent } from './ura-search-relation-corporate/ura-search-relation-corporate.component'
import { UraNafcoActuateSettingComponent } from './ura-nafco-actuate-setting/ura-nafco-actuate-setting.component'
import { UraCreateAddQuotationRetailComponent } from './ura-create-add-quotation-retail/ura-create-add-quotation-retail.component'
import { UraNafcoMigrationActuateSettingComponent } from './ura-nafco-migration-actuate-setting/ura-nafco-migration-actuate-setting.component'
import { UraUpdateActuateSettingComponent } from './ura-update-actuate-setting/ura-update-actuate-setting.component'
import { UraSundryContractComponent } from './ura-sundry-contract/ura-sundry-contract.component'
import { UrainclusionContractComponent } from './ura-inclusion-contract/ura-inclusion-contract.component'
import { UraNewCompanyCustomerComponent } from './ura-new-company-customer/ura-new-company-customer.component'
import { UraConpanyContractedOrganizationListComponent } from './ura-conpany-contracted-organization-list/ura-conpany-contracted-organization-list.component'
import { UraManagementUserComponent } from './ura-management-user/ura-management-user.component'
import { UraTrialContractListComponent } from './ura-trial-contract-list/ura-trial-contract-list.component'
import { UraCompanyListComponent } from './ura-company-list/ura-company-list.component'
import { UraInvoicePjStatusListComponent } from './ura-invoice-pj-status-list/ura-invoice-pj-status-list.component'
import { UraHandoverCustomerAuthComponent } from './ura-handover-customer-auth/ura-handover-customer-auth.component'
import { AuthGuard } from 'src/app/components/isbeauth/auth.guard'
import { UraConfirmInvoiceListComponent } from './ura-confirm-invoice-list/ura-confirm-invoice-list.component'
import { UraSendInvoiceForBankComponent } from './ura-send-invoice-for-bank/ura-send-invoice-for-bank.component'
import { UraPaymentMethodAccountListComponent } from './ura-payment-method-account-list/ura-payment-method-account-list.component'
import { UraMigrationSendListComponent } from './ura-migration-send-list/ura-migration-send-list.component'
import { ShimuraGarageMigrationTopComponent } from '../myidea-v2/micomel-migration-top/micomel-migration-top.component'
import { UraPetaboEditComponent } from './ura-petabo-edit/ura-petabo-edit.component'

const routes: Routes = [
  {
    path: 'ura-top',
    component: UraTopComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'contract-entry-list',
    component: UraContractEntryListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'b46f48f8-4ba9-4bb9-84e8-1770aea76911'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'contract-new-list',
    component: UraContractNewListComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'contact-user-list',
    component: UraContactUserListComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // サービスの追加・変更 サービスマップ
  {
    path: 'ura-add-contract-service-map/:organizationCompanyCd/:contractOrganizationCd',
    component: UraAddContractServiceMapComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-cancel-contract-service-map/:organizationCompanyCd/:contractOrganizationCd',
    component: UraCancelContractServiceMapComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-connect-service-map/:organizationCompanyCd/:contractOrganizationCd',
    component: UraConnectServiceMapComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-company-add', // 会社追加
    component: UraCompanyAddV2Component,
    data: {
      headerType: headerType.uraidea,
      functionKey: '9c1ebacd-4320-4b22-a95f-8eac38c0d2de'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-conector-link/:actuateCd',
    component: UraConactorLinkComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-cancel-confirm/:organizationCompanyCd/:contractOrganizationCd',
    component: UraCancelComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-new-company-profile',
    component: UraNewCompanyProfileComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-new-company-customer',
    component: UraNewCompanyCustomerComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-organization-tree-view',
    component: UraOrganizationTreeViewComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '211d2fcb-7773-4f16-a4e8-a2aa515e7a8d'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-organization-tree-edit/:companyOrganizationCd/:selectOrganizationCd',
    component: UraOrganizationTreeEditComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-organization-user-list/:companyOrganizationCd/:selectOrganizationCd',
    component: UraOrganizationCustomerListComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 管理者モード
  {
    path: 'ura-admin',
    data: {
      headerType: headerType.uraidea,
      functionKey: 'f136654b-2420-4c5e-ae82-fef941c93c70'
    },
    canActivate: [AuthGuard],
    component: UraSuperAdminComponent
  },
  // サポート用 アカウント検索
  {
    path: 'ura-support-search-account',
    data: {
      headerType: headerType.uraidea,
      functionKey: '89f4e8e5-393d-4868-88d1-0fe8b188ed95'
    },
    canActivate: [AuthGuard],
    component: UraSupportSearchAccountComponent
  },
  {
    path: 'ura-company-invoice',
    data: {
      headerType: headerType.uraidea,
      functionKey: '61deecc0-df15-43ec-abac-68e64c8ade44'
    },
    canActivate: [AuthGuard],
    component: UraCompanyInvoiceComponent
  },
  {
    path: 'ura-company-user',
    data: {
      headerType: headerType.uraidea,
      functionKey: '4237ebf9-325e-4a6d-b886-bd765b0033ca'
    },
    canActivate: [AuthGuard],
    component: UraCompanyUserComponent
  },
  {
    path: 'ura-update-actuate-setting',
    data: {
      headerType: headerType.uraidea,
      functionKey: 'a6e27da5-b823-4e44-90a9-ea5636dcb841'
    },
    canActivate: [AuthGuard],
    component: UraUpdateActuateSettingComponent
  },
  {
    path: 'ura-estimate-list',
    component: UraEstimateListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '78a085a8-7f96-4718-b38e-9a260723dd97'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-add-new-contract-detail/:estimateDocumentCd',
    component: UraAddNewContractDetailComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  //
  {
    path: 'ura-quotation-preview-new',
    component: UraQuotationPreviewNewComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-estimate-list-new',
    component: UraEstimateListNewComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'da42bfbb-5c03-446d-b19c-67266473492d'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-add-contract-detail/:estimateDocumentCd',
    component: UraAddContractDetailComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 新規見積もり作成 送信先(契約担当)選択
  {
    path: 'ura-estimate-new',
    component: UraEstimateNewComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '75061c41-f150-4927-9de6-0a7950c5515d'
    },
    canActivate: [AuthGuard]
  },

  // 新規見積もり作成 小売選択
  {
    path: 'ura-new-quotation-retail/:index',
    component: UraNewQuotationRetailComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 新規見積もり作成 業態選択
  {
    path: 'ura-new-quotation-business-category/:index',
    component: UraNewQuotationBusinessCategoryComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 新規見積もり作成 サービス選択
  {
    path: 'ura-new-quotation-service/:index',
    component: UraNewQuotationServiceComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 新規見積もり作成 商品選択
  {
    path: 'ura-new-quotation-merchandise/:index',
    component: UraNewQuotationMerchandiseComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 新規見積もり作成 備考入力
  {
    path: 'ura-new-quotation-remarks',
    component: UraNewQuotationRemarksComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 追加見積もり作成 小売選択
  {
    path: 'ura-add-product-retail/:organizationCompanyCd/:contractOrganizationCd/:index',
    component: UraCreateAddQuotationRetailComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 追加見積もり作成 業態選択
  {
    path: 'ura-add-product-business-category/:organizationCompanyCd/:contractOrganizationCd/:index',
    component: UraCreateAddQuotationBusinessCategoryComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 追加見積もり作成 商品選択
  {
    path: 'ura-add-product-merchandise/:organizationCompanyCd/:contractOrganizationCd/:index',
    component: UraCreateAddQuotationMerchandiseComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 追加見積もり作成 選択商品確認
  {
    path: 'ura-add-product-confirm/:organizationCompanyCd/:contractOrganizationCd',
    component: UraCreateAddQuotationConfirmComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 追加見積もり作成 備考入力
  {
    path: 'ura-add-product-remarks/:organizationCompanyCd/:contractOrganizationCd',
    component: UraCreateAddQuotationRemarksComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 見積もりプレビュー (プロダクト追加)
  {
    path: 'ura-quotation-preview-add-product/:organizationCompanyCd/:contractOrganizationCd',
    component: UraQuotationPreviewAddProductComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 見積もりプレビュー (オプション追加)
  {
    path: 'ura-quotation-preview-add-option/:organizationCompanyCd/:contractOrganizationCd',
    component: UraQuotationPreviewAddOptionComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // 契約管理　契約一覧
  {
    path: 'ura-contract-search-list',
    component: UraContractSearchListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'f49a9585-dee9-4187-a4cd-d8b758509f8c'
    },
    canActivate: [AuthGuard]
  },
  // 契約管理 サービスマップ
  {
    path: 'ura-service-map-search',
    component: UraServiceMapSearchComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'ac5d5784-31c8-4491-87f4-235d45f7f8dc'
    },
    canActivate: [AuthGuard]
  },
  // 契約管理　利用者一覧
  {
    path: 'ura-user-search-list',
    component: UraUserSearchListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '0139963b-5080-4957-97ed-b67529714df4'
    },
    canActivate: [AuthGuard]
  },
  // 各種ファイルダウンロード
  {
    path: 'ura-csv-download',
    component: UraCsvDownloadComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'e93e1db6-ca8b-44cb-8203-7e7a55e9143e'
    },
    canActivate: [AuthGuard]
  },
  // 請求先変更
  {
    path: 'ura-invoice-change',
    component: UraInvoiceChangeComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'e5a90f79-bf3b-414a-8355-00f6d6e810ba'
    },
    canActivate: [AuthGuard]
  },
  // 検索：出荷Pack 送信タスク結果一覧
  {
    path: 'ura-monitoring-pack-send-task',
    component: UraMonitoringPackSendTaskComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'cacf791f-3602-428c-9ecd-33166db675d0'
    },
    canActivate: [AuthGuard]
  },
  // 商品一覧
  {
    path: 'ura-merchandise-list',
    component: UraMerchandiseListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '3a16ea22-5afe-4870-86bb-249164ad0f54'
    },
    canActivate: [AuthGuard]
  },
  // 製品一覧
  {
    path: 'ura-product-list',
    component: UraProductListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'd53cb7dc-49ac-4486-b34d-de7b95d5bd28'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-contract-cancel-list/:companyOrganizationCd/:customerCd',
    component: UraCancelContarctListComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-company-check-list',
    component: UraCompanyCheckListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '729d01ea-6b56-4d7d-bc51-67989d270fdb'
    },
    canActivate: [AuthGuard]
  },
  // 利用者メンテナンス
  {
    path: 'ura-management-user',
    component: UraManagementUserComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'c7b3a135-5fed-4a70-9ca4-044143c49195'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-search-relation-corporate/:organizationCd',
    component: UraSearchRelationCorporateComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-edit-company-profile/:organizationCd',
    component: UraEditCompanyProfileComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // アカウント登録URL発行
  {
    path: 'ura-create-account-url',
    component: UraAccountLinkComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'ba9e5d24-4f10-42a9-897f-e52cb7c6c934'
    },
    canActivate: [AuthGuard]
  },
  // ナフコ専用 サービス稼働設定
  {
    path: 'ura-nafco-actuate-setting',
    component: UraNafcoActuateSettingComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '60c22c59-eb51-409d-bcc2-b8ca2d822544'
    },
    canActivate: [AuthGuard]
  },
  // ナフコ専用 サービス稼働設定
  {
    path: 'ura-nafco-migration-actuate-setting',
    component: UraNafcoMigrationActuateSettingComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-company-check-list',
    component: UraCompanyCheckListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: ''
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-company-list',
    component: UraCompanyListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '036233d6-510a-44c4-8fa3-f7d928eb6bba'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-sundry-contract',
    component: UraSundryContractComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  {
    path: 'ura-inclusion-contract',
    component: UrainclusionContractComponent,
    data: {
      headerType: headerType.uraidea
    }
  },
  // [顧客]サービスの追加・変更
  {
    path: 'ura-conpany-contracted-organization-list',
    component: UraConpanyContractedOrganizationListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'ce2d35ab-bdf3-4997-9bcb-44a15ad59e47'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-trial-contract-list',
    component: UraTrialContractListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '930a5781-b849-494a-80ab-314014750883'
    },
    canActivate: [AuthGuard]
  },
  // 担当者変更
  {
    path: 'ura-handover-customer-auth',
    component: UraHandoverCustomerAuthComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '668b2835-ae18-454a-b938-2d223ba28a4d'
    },
    canActivate: [AuthGuard]
  },
  // インボイス対応
  {
    path: 'ura-invoice-pj-status-list',
    component: UraInvoicePjStatusListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '0b6120e1-99ac-4cbc-8db3-03027f2c8c3d'
    },
    canActivate: [AuthGuard]
  },
  // 請求：請求内容確定
  {
    path: 'ura-confirm-invoice-list',
    component: UraConfirmInvoiceListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '7acfe197-580a-4336-82e6-fb9a58232ce1'
    },
    canActivate: [AuthGuard]
  },
  // 請求：口座振替結果一覧
  {
    path: 'ura-send-invoice-for-bank',
    component: UraSendInvoiceForBankComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '05b535a2-5fe5-4b83-a188-854280f38a22'
    },
    canActivate: [AuthGuard]
  },
  // 請求：振替口座一覧
  {
    path: 'ura-payment-method-account-list',
    component: UraPaymentMethodAccountListComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: '199ea1a4-e786-4292-bb9f-70ea003d072c'
    },
    canActivate: [AuthGuard]
  },
  // ミコメル・しまむら移行
  {
    path: 'ura-shimura-garage-migration-send-list',
    component: UraMigrationSendListComponent,
    data: {
      headerType: headerType.uraidea
      // functionKey: '0547d7e2-01d8-4d28-a823-54f7bea09947'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-shimura-garage-migration-top/:companyOrganizationId/:parentContractId/:customerCd',
    component: ShimuraGarageMigrationTopComponent,
    data: {
      headerType: headerType.uraidea,
      // functionKey: '0547d7e2-01d8-4d28-a823-54f7bea09947'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ura-petabo-edit',
    component: UraPetaboEditComponent,
    data: {
      headerType: headerType.uraidea,
      functionKey: 'd4598f82-95a3-4698-8235-067ab01cc74f'
    },
    canActivate: [AuthGuard]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UraideaV2RoutingModule {}
