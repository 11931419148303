import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { HttpRequestService } from '../common/http-request.service'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { AuthorityTable, CustomerAuthority } from './authorities-api.interface'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class AuthoritiesApiService {
  baseURL = environment.backendMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  get BaseURL(): string {
    return this.baseURL
  }

  // 担当情報
  async GetUserAuthority(
    companyCd: string,
    customerCd: string
  ): Promise<ResultInfoSingle<CustomerAuthority>> {
    const url = `${environment.myideaMacroBaseUrl}api/authority/company/${companyCd}/customer/${customerCd}`
    const ret$ = this.httpRequestService.getResultSingle<CustomerAuthority>(url)
    return await lastValueFrom(ret$)
  }

  // 担当者変更
  async ChangeUserAuthority(
    companyCd: string,
    fromCustomerCd: string,
    toCustomerCd: string
  ): Promise<ResultInfoSingle<boolean>> {
    const url = `${environment.myideaMacroBaseUrl}api/authority/company/${companyCd}/fromCustomer/${fromCustomerCd}/toCustomer/${toCustomerCd}`
    const ret$ = this.httpRequestService.putResultSingle<boolean>(url, null)
    return await lastValueFrom(ret$)
  }

  // 個人の権限データ（UI制御用途）
  public GetMyAuthorityTable(customerCd): Observable<ResultInfo<AuthorityTable>> {
    {
      const url = `${this.baseURL}api/v2/Authorities/Customers/${customerCd}/GetMyAuthorityTable`
      return this.httpRequestService.get(url)
    }
  }


  // 以下、2023/05のUra-idea権限制御対応で追加
  /**
   * 権限(利用画面)取得処理のWBSを実行
   * @param customerCd 
   * @param organizationCd 
   * @returns 結果取得の為の文字列
   */
  async RunGetAuthorityViews(customerCd: string, organizationCd: string): Promise<ResultInfoSingle<string>> {
    const wbsId = "2508be4b-6740-40e1-8ed5-ca6835356793" // 権限制御のWBS
    const param = { customerCd, organizationCd }
    return await this.RunTask(wbsId, param)
  }

  /**
  * タスクを実行
  * @param wbsId 
  * @param param 
  * @returns 結果取得の為の文字列
  */
  private async RunTask(wbsId: string, param: any = {}): Promise<ResultInfoSingle<string>> {
    const url = `${environment.jobControllerBaseUrl}api/jobController/exec/${wbsId}`
    const ret$ = this.httpRequestService.postResultSingle<string>(url, param)
    return await lastValueFrom(ret$)
  }

  /**
   * WBSの結果を取得
   * @param key 結果取得の為の文字列
   * @returns 
   */
  private async GetTaskResult(key: string): Promise<ResultInfoSingle<string>> {
    const url = `${environment.jobControllerBaseUrl}api/jobController/result`
    const body = JSON.parse(key)
    const ret$ = this.httpRequestService.postResultSingle<string>(url, body, false)
    return await lastValueFrom(ret$)
  }

  /**
 * WBSの結果を再起で取得
 * @param key 結果取得の為の文字列
 * @returns 
 */
  async GetTaskResultRecursive(key: string): Promise<string> {
    let count = 0
    let result
    while (!result) {
      count++
      const resultRet = await this.GetTaskResult(key)
      if (resultRet?.resultCode !== 0) {
        // API異常終了の場合
        result = "[]"
      }
      else {
        if (resultRet?.data) {
          const data = JSON.parse(resultRet?.data)
          if (data) {
            result = data
          }
          else {
            result = "[]"
          }
        }
      }
      if (!result) {
        await this.wait(2000)
      }
    }
    return result
  }

  async wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

}
