import { Component, OnInit, Input } from '@angular/core'
import { EstimateDocumentDetailStatus } from 'src/api/estimates-api/estimates-api.interface'

@Component({
  selector: 'app-contract-detail-block-address',
  templateUrl: './contract-detail-block-address.component.html',
  styleUrls: ['./contract-detail-block-address.component.scss']
})
export class ContractDetailAddressComponent implements OnInit {
  @Input() lastSendDatetimeUi: string
  @Input() companyOrganizationName: string
  @Input() sectionOrganizationName: string
  @Input() contractCustomerName: string
  @Input() mailAddress: string
  @Input() remarks: string

  constructor() { }

  ngOnInit(): void {

  }
}
