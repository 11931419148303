import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import {
  CreateAuthenticationResponse,
  EasyAuthentication,
  MigrationLogin,
  NewApplicationStatusList
} from './easy-authentications-api.interface'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class EasyAuthenticationsApiService {
  baseURL = environment.backendMacroBaseUrl
  myideaMacroBaseURL = environment.myideaMacroBaseUrl

  constructor(private httpRequestService: HttpRequestService) {}

  get BaseURL(): string {
    return this.baseURL
  }

  // 仮アカウントログイン
  LoginEasyAuthentications(
    authenticationUriCd: string,
    authenticationCd: string
  ): Observable<ResultInfo<EasyAuthentication>> {
    const url = `${this.BaseURL}api/EasyAuthentications/${authenticationUriCd}/${authenticationCd}/Login`
    return this.httpRequestService.get(url, false)
  }

  // 仮アカウント情報取得
  GetEasyAuthentications(authenticationCd: string): Observable<ResultInfo<EasyAuthentication>> {
    const url = `${this.BaseURL}api/EasyAuthentications/${authenticationCd}`
    return this.httpRequestService.get(url)
  }

  // 移行用簡易認証
  MigrationLogin(authenticationUriCd: string, authenticationCd: string): Observable<ResultInfo<MigrationLogin>> {
    const url = `${this.BaseURL}api/EasyAuthentications/${authenticationUriCd}/${authenticationCd}/MigrationLogin`
    return this.httpRequestService.get(url)
  }

  // 移行用簡易認証画面で出す顧客名
  GetCustomerCd(authenticationUriCd: string): Observable<ResultInfo<string>> {
    const url = `${this.BaseURL}api/EasyAuthentications/${authenticationUriCd}/CustomerCd`
    return this.httpRequestService.get(url)
  }

  // 【Ura-idea用】新規契約申し込み連絡先一覧取得
  GetNewApplicationStatusList(): Observable<ResultInfo<NewApplicationStatusList>> {
    const url = `${this.BaseURL}api/EasyAuthentications/NewApplicationStatusList`
    return this.httpRequestService.get(url)
  }

  // アカウント作成依頼
  CustomerAccountPath(customerCd: string): Observable<ResultInfo<CreateAuthenticationResponse>> {
    const url = `${this.BaseURL}api/EasyAuthentications/Customers/${customerCd}/Create`
    return this.httpRequestService.post(url, null)
  }

  // 認証データの作成とメールpreviewの取得
  PostGetPreviewData(customerCd: string): Observable<ResultInfoSingle<any>> {
    const url = `${this.myideaMacroBaseURL}api/EasyAuthentications/Customers/${customerCd}/Create/Preview`
    return this.httpRequestService.post(url, null)
  }
  // Account作成依頼メールを送信する。ここではタスクに登録するだけ。
  SendCreateAccountRequestMail(authenticationUriCd: string): Observable<ResultInfoSingle<any>> {
    const url = `${this.myideaMacroBaseURL}api/EasyAuthentications/${authenticationUriCd}/SendMail`
    return this.httpRequestService.post(url, null)
  }

  CreateTemporaryAccountEmailNotSendFile(customerCd: string): Observable<ResultInfoSingle<any>> {
    const url = `${this.myideaMacroBaseURL}api/TemporaryAccount/EmailNotSend/Create/Customer/${customerCd}/File`
    return this.httpRequestService.post(url, null)
  }
  DeleteTemporaryAccountEmailNotSendFile(customerCd: string): Observable<ResultInfoSingle<any>> {
    const url = `${this.myideaMacroBaseURL}api/TemporaryAccount/EmailNotSend/Delete/Customer/${customerCd}/File`
    return this.httpRequestService.delete(url, null)
  }
}
