import { Component, OnInit, ViewChild } from '@angular/core'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { ActionTargetInfo, GridActionButton } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { PetaboApiService } from 'src/api/petabo-api/petabo-api.service'
import { Wbs } from 'src/api/petabo-api/petabo-api.interface'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { IndexCoreMyideaApiService } from 'src/api/indexcore-myidea-api/indexcore-myidea-api.service'
import { ActionTransactionSetting } from 'src/api/indexcore-myidea-api/indexcore-myidea-api.interface'

@Component({
  selector: 'app-ura-petabo-edit',
  templateUrl: './ura-petabo-edit.component.html',
  styleUrls: ['./ura-petabo-edit.component.scss']
})
export class UraPetaboEditComponent implements OnInit {
  @ViewChild('inputTableForCreate', { read: InputTableComponent, static: false })
  public inputTableForCreate: InputTableComponent
  @ViewChild('inputTableForUpdate', { read: InputTableComponent, static: false })
  public inputTableForUpdate: InputTableComponent
  title = 'ペタボ版 重要なお知らせ編集'
  petaboFqdn = 'petabo.myidea.notification'
  actionTransactionPrefix = 'myidea-top-clickPetabo-'
  data: Wbs[] = []
  columns: Header[] = [
    {
      key: 'name',
      label: 'タイトル',
      width: '36%'
    },
    {
      key: 'profiles.url',
      label: 'URL',
      width: '36%'
    },
    {
      key: 'profiles.periodFrom',
      label: '掲載開始日時',
      width: '12%'
    },
    {
      key: 'profiles.periodTo',
      label: '掲載終了日時',
      width: '12%'
    }
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    {
      label: '新規登録',
      actionCd: 'create',
      notRowAction: true
    },
    {
      label: '変更',
      actionCd: 'update'
    },
    {
      label: '削除',
      actionCd: 'remove'
    }
  ]
  loading = true
  sending = false
  createDialogOpen = false
  updateDialogOpen = false
  formItems: FormInputInfo[] = [
    {
      key: 'name',
      label: 'タイトル',
      type: 'text',
      valid: {
        required: true
      }
    },
    {
      key: 'url',
      label: 'URL',
      type: 'text'
    },
    {
      key: 'periodFrom',
      label: '掲載開始日時',
      group: [
        {
          key: 'periodDateFrom',
          label: '',
          type: 'date'
        },
        {
          key: 'periodTimeFrom',
          label: '',
          type: 'time'
        }
      ]
    },
    {
      key: 'periodTo',
      label: '掲載終了日時',
      group: [
        {
          key: 'periodDateTo',
          label: '',
          type: 'date'
        },
        {
          key: 'periodTimeTo',
          label: '',
          type: 'time'
        }
      ]
    }
  ]

  selectRowId: string
  updateValue: any

  constructor(
    private messageService: MessageService,
    private petaboApiService: PetaboApiService,
    private indexCoreMyideaApiService: IndexCoreMyideaApiService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.getPetabo()
  }
  async getPetabo(): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.petaboApiService.getPetabo(this.petaboFqdn)
    if (ret?.resultCode !== 0) {
      return
    }
    this.data = ret.data
    this.data.forEach((item) => {
      item.actionCds = ['update', 'remove']
    })
    this.loading = false
  }

  actionCommit(event: ActionTargetInfo): void {
    if (event.actionCd === 'remove') {
      this.confirmService.confirm(`本当に削除しますか？`, '', () => {
        this.removeRows(event.rows)
      })
    }
    if (event.actionCd === 'create') {
      this.createDialogOpen = true
    }
    if (event.actionCd === 'update') {
      this.openUpdateDialog(event.rows[0])
    }
  }

  async removeRows(rows: Wbs[]): Promise<void> {
    if (this.sending) {
      return
    }
    let delIds = rows.map((x) => x.id)
    rows.forEach((row) => {
      this.data = this.data.filter((x) => x.id !== row.id)
    })
    this.sending = true
    const ret = await this.petaboApiService.putPetaboIndexWbs(this.petaboFqdn, this.data)
    if (ret?.resultCode !== 0) {
      return
    }
    this.sending = false
    this.messageService.templeteMessage(messageList.M00070)
    this.getPetabo()
  }

  openUpdateDialog(row: Wbs): void {
    this.selectRowId = row.id
    this.updateValue = {
      name: row.name,
      url: row.profiles.url,
      periodDateFrom: row.profiles?.periodFrom ? row.profiles.periodFrom.split(' ')[0].replace(/\//g, '-') : '',
      periodTimeFrom: row.profiles?.periodFrom
        ? row.profiles.periodFrom.includes(' ')
          ? row.profiles.periodFrom.split(' ')[1]
          : ''
        : '',
      periodDateTo: row.profiles?.periodTo ? row.profiles.periodTo.split(' ')[0].replace(/\//g, '-') : '',
      periodTimeTo: row.profiles?.periodTo
        ? row.profiles.periodTo.includes(' ')
          ? row.profiles.periodTo.split(' ')[1]
          : ''
        : ''
    }
    this.updateDialogOpen = true
  }

  async createRow(): Promise<void> {
    const invoiceTarget = this.inputTableForCreate.submit()
    if (!invoiceTarget) {
      return
    }
    this.confirmService.confirm(`本当に追加しますか？`, '', async () => {
      if (this.sending) {
        return
      }
      let newDataRow: Wbs = {
        wbsType: 'petabo-link',
        name: invoiceTarget.name,
        profiles: {
          url: invoiceTarget.url
        }
      }
      //期間設定がある場合
      let periodF = this.createDateParam(invoiceTarget.periodDateFrom, invoiceTarget.periodTimeFrom)
      let periodT = this.createDateParam(invoiceTarget.periodDateTo, invoiceTarget.periodTimeTo)
      if (periodF.length > 0 || periodT.length > 0) {
        newDataRow.profiles.frontendId = crypto.randomUUID()
        newDataRow.profiles.periodFrom = periodF
        newDataRow.profiles.periodTo = periodT
      }

      newDataRow = this.checkPeriod(newDataRow)
      const newData = [...this.data, newDataRow]
      this.sending = true
      const ret = await this.petaboApiService.putPetaboIndexWbs(this.petaboFqdn, newData)
      if (ret?.resultCode !== 0) {
        return
      }
      await this.getPetabo()

      //期間設定がある場合のみアクショントランザクション設定
      if (newDataRow.profiles?.periodFrom) {
        let retTran = await this.addActionTransaction(this.data, newDataRow)
        if (retTran === false) return
      }
      this.sending = false
      this.createDialogOpen = false
      this.messageService.templeteMessage(messageList.M00070)
    })
  }

  async updateRow(): Promise<void> {
    const invoiceTarget = this.inputTableForUpdate.submit()
    if (!invoiceTarget) {
      return
    }
    this.confirmService.confirm(`本当に変更しますか？`, '', async () => {
      if (this.sending) {
        return
      }
      let updateData: any = {}
      this.data.forEach((item) => {
        if (item.id === this.selectRowId) {
          item.name = invoiceTarget.name
          item.profiles = { url: invoiceTarget.url }

          //期間設定がある場合
          let periodF = this.createDateParam(invoiceTarget.periodDateFrom, invoiceTarget.periodTimeFrom)
          let periodT = this.createDateParam(invoiceTarget.periodDateTo, invoiceTarget.periodTimeTo)
          if (periodF.length > 0 || periodT.length > 0) {
            item.profiles.periodFrom = periodF
            item.profiles.periodTo = periodT
          }
          let checkedData = this.checkPeriod(item)
          item.profiles = checkedData.profiles
          updateData = JSON.parse(JSON.stringify(item))
        }
      })
      this.sending = true
      const ret = await this.petaboApiService.putPetaboIndexWbs(this.petaboFqdn, this.data)
      if (ret?.resultCode !== 0) {
        return
      }
      //アクショントランザクション設定
      let retTran = await this.updateActionTransaction(this.selectRowId, updateData)
      if (retTran === false) return
      this.sending = false
      this.updateDialogOpen = false
      this.messageService.templeteMessage(messageList.M00070)
      this.getPetabo()
    })
  }
  private createDateParam(dateParam: string, timeParam: string): string {
    let period = dateParam
    if (period.length > 0) {
      period = period.replace(/-/g, '/') + ' ' + (timeParam.length === 0 ? '00:00' : timeParam)
    }
    return period
  }
  private checkPeriod(target: any) {
    let ret = JSON.parse(JSON.stringify(target))
    if (ret.profiles.periodFrom !== '' && ret.profiles.periodTo === '') {
      ret.profiles.periodTo = '9999/12/31 00:00'
    } else if (ret.profiles.periodFrom === '' && ret.profiles.periodTo !== '') {
      const today = new Date()
      const formattedDate =
        `${today.getFullYear()}/${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(
          2,
          '0'
        )}` + ' 00:00'
      ret.profiles.periodFrom = formattedDate
    }
    return ret
  }
  private async addActionTransaction(petaboData: any, newData: any) {
    let ret = false
    // petaboId取得
    const index = petaboData.findIndex((x) => x.profiles.frontendId === newData.profiles.frontendId)
    const petaboId = petaboData[index].id

    //アクショントランザクション設定取得
    let getRet = await this.indexCoreMyideaApiService.getFrontendSettings('actions')
    if (getRet) {
      let newAction: ActionTransactionSetting = {
        name: newData.name,
        enable: true,
        startAt: newData.profiles.periodFrom,
        endAt: newData.profiles.periodTo
      }
      let newList = {
        [this.actionTransactionPrefix + petaboId]: newAction
      }
      newList = { ...newList, ...getRet.data }

      let postRet = await this.indexCoreMyideaApiService.postFrontendSettings('actions', newList)
      if (postRet) {
        ret = true
      }
    }
    return ret
  }
  private async updateActionTransaction(petaboId: string, targetData?: any) {
    let ret = false
    //アクショントランザクション設定取得
    let getRet = await this.indexCoreMyideaApiService.getFrontendSettings('actions')
    if (getRet) {
      let newData = JSON.parse(JSON.stringify(getRet.data))
      if (newData[this.actionTransactionPrefix + petaboId]) {
        if (targetData.profiles?.periodFrom) {
          newData[this.actionTransactionPrefix + petaboId].enable = true
          newData[this.actionTransactionPrefix + petaboId].startAt = targetData.profiles.periodFrom
          newData[this.actionTransactionPrefix + petaboId].endAt = targetData.profiles.periodTo
        } else {
          newData[this.actionTransactionPrefix + petaboId].enable = false
        }
      }
      // アクショントランザクションデータが存在しない場合（=期間指定なしだったデータ）
      else {
        let newAction: ActionTransactionSetting = {
          name: targetData.name,
          enable: true,
          startAt: targetData.profiles.periodFrom,
          endAt: targetData.profiles.periodTo
        }
        let newList = {
          [this.actionTransactionPrefix + petaboId]: newAction
        }
        newData = { ...newList, ...newData }
      }
      let postRet = await this.indexCoreMyideaApiService.postFrontendSettings('actions', newData)
      if (postRet) {
        ret = true
      }
    }
    return ret
  }
}
