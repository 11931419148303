import { TextTag } from '../common/api-common.interface'
import { ServiceMapBlock } from '../service-map-api/service-map-api.interface'

// プロダクトコードマスター
export interface ProductCdMaster {
  productInquiryText: string
  productCd: string
}

export interface SearchServiceMapRequest {
  tagCds: string[]
  contractCompanyOrganizationCd?: string
  actuateName?: string
  productCdForInquiry?: string
  productNumber?: number
  supplierCd?: string
}

export interface ContaSearchMacroRequest {
  tagCds: string[]
  companyOrganizationCd?: string
  actuateName?: string
  productCdForInquiry?: string
  productNumber?: number
  productCds: string[]
  supplierCd?: string
  actuateStatus?: string
  applicationDatetimeStart?: string
  applicationDatetimeEnd?: string
  actuateDatetimeStart?: string
  actuateDatetimeEnd?: string
}

export interface ContaSearchUserMacroRequest {
  tagCds: string[]
  businessCategoryCds: string[]
  productCds: string[]
  productCdForInquiry: string
  productNumber: number
  companyOrganizationCd: string
  supplierCds: string[]
  actuateStatus: string
  authorityDivisionCds: string[]
}

export interface ContractListItem {
  contractCd: string
  contractName: string
  contractPrice: number
  contractPriceUi: string
  contractCompanyOrganizationCd: string
  contractCompanyOrganizationName: string
  contractSectionOrganizationCd: string
  contractSectionOrganizationName: string
  invoiceCompanyOrganizationCd: string
  invoiceCompanyOrganizationName: string
  actuateName: string
  serviceTagCd: string
  serviceTagName: string
  retailTagCd: string
  retailTagText: string
  businessCategoryTagCd: string
  businessCategoryTagName: string
  productCd: string
  productInquiryCd: string
  productName: string
  optionName: string
  supplierCd: string
  actuateStatus: string
  actuateStatusUi: string
  applicationDatetime: string
  applicationDatetimeUi: string
  actuateDatetime: string
  actuateDatetimeUi: string
  cancelDatetime: string
  cancelDatetimeUi: string,
  actionCds?: string[]
}

export interface ContractModel extends ContractListItem {
  contractCd: string
  oldInquiryNo: string
  contractDetailId: string
  actuateStartDatetime: string
  actuateStartDatetimeUi: string
}

export interface RetailTagList {
  retailTagCd: string
  retailTagText: string
  businessCategoryTags: TextTag[]
}

export interface AuthorityDivision {
  authorityDivisionCd: string
  authorityDivisionName: string
}
export interface ProductMaster {
  productCd: string
  productName: string
  childrenProduct: ChildrenProduct[]
}

export interface ChildrenProduct {
  productCd: string
  productName: string
}

export interface ProductMasterUI {
  parentProductCd: string
  parentProductName: string
  chilidProductCd: string
  childProductName: string
}

export interface SearchUser {
  contractCompanyOrganizationCd: string
  contractCompanyOrganizationName: string
  contractSectionOrganizationCd: string
  contractSectionOrganizationName: string
  customerCd: string
  customerName: string
  authorityDivisionCds: string[]
  phoneNumber: string
  mailAddress: string
  serviceTagCd: string
  serviceTagName: string
  retailTagCd: string
  retailTagText: string
  businessCategoryTagCd: string
  businessCategoryTagName: string
  productCd: string
  productInquiryCd: string
  productName: string
  supplierCd: string
  actuateStatus: string
  actuateStatusUi: string
  applicationDatetime: Date
  applicationDatetimeUi: string
  actuateDatetime: Date
  actuateDatetimeUi: string
}

export interface SearchUserUI extends SearchUser {
  authorityDivisionCdsAdministrator?: string
  authorityDivisionCdsContractor?: string
  authorityDivisionCdsInvoice?: string
  authorityDivisionCdsSuperuser?: string
  authorityDivisionCdsUser?: string
}


export interface InputProductInquiryCd {
  productCdForInquiry?: string
  productNumber?: number
  isError: boolean
}
export interface ContaServiceMap {
  targetActuateCds: string[]
  mapBlocks: ServiceMapBlock[]
}

