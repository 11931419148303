import { AbstractControl, ValidationErrors, UntypedFormGroup } from '@angular/forms'

const PHONENUMBER_REGEXP = /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/
const NUM_ONLY_REGEXP = /^[0-9]+$/
const ALPHANUMERIC_REGEXP = /^[0-9a-zA-Z]*$/
const ALPHANUMERIC_SYMBOLS_REGEXP = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/
const PASSWORD_REGEXP = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!-/:-@¥[-`{-~]*$/
const HIRAGANA_REGEXP = /^[\u3041-\u3096\u309D\u309E\u3000\u30FC\0-9\０-９\a-z\A-Z\-/:-@\[-\`\{-\~]*$/
const BANK_ACCOUNT_NAME_REGEXP = /^[A-Z0-9ｦｱ-ﾟ.()-]+[\x20]{0,1}[A-Z0-9ｦｱ-ﾟ.()-]+$/
// カナ大文字・英数字半角大文字OK
// ただし、ｰ(のばし棒)はNG
// 記号は ハイフン、ピリオド、カッコのみOK
// 途中に半角スペース1桁が入るのはOK
// 半角カタカナ全量の並び：ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾉﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾋﾛﾜﾝﾞﾟ
// 半角カタカナNG文字：ｧｨｩｪｫｬｭｮｯｰ
const NOT_BLANK = /[\p{C}\p{Z}]/gu

function isEmptyInputValue(val: any): boolean {
  return val == null || val.length === 0
}

export class CustomValidators {

  static phonenumber(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null
    }
    return PHONENUMBER_REGEXP.test(control.value)
      ? null
      : { phonenumber: true }
  }

  // 半角数字のみ
  static numOnly(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null
    }
    return NUM_ONLY_REGEXP.test(control.value) ? null : { numOnly: true }
  }

  // 半角英数字のみ
  static alphanumeric(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null
    }
    return ALPHANUMERIC_REGEXP.test(control.value) ? null : { alphanumeric: true }
  }

  // 半角英数字記号のみ
  static alphanumericSymbols(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null
    }
    return ALPHANUMERIC_SYMBOLS_REGEXP.test(control.value) ? null : { alphanumericSymbols: true }
  }

  // 半角英数字記号のみ、数字とアルファベットを各1文字以上含む
  static password(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null
    }
    return PASSWORD_REGEXP.test(control.value) ? null : { password: true }
  }

  // ひらがな(半角英数字記号含む)のみ
  static hiragana(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null
    }
    return HIRAGANA_REGEXP.test(control.value) ? null : { hiragana: true }
  }
  
  // 振替口座の預金者名
  static bankAccountName(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null
    }
    return BANK_ACCOUNT_NAME_REGEXP.test(control.value) ? null : { bankAccountName: true }
  }

  // スペース、タブ禁止
  static notBlank(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null
    }
    // 他と違って、一致したらエラーなので注意
    return NOT_BLANK.test(control.value) ? { notBlank: true } : null
  }
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string, labelText: string = 'パスワード') {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName]
    const matchingControl = formGroup.controls[matchingControlName]

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: { error: true, label: labelText } })
    } else {
      matchingControl.setErrors(null)
    }
  }
}
