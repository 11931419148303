import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ɵbypassSanitizationTrustHtml } from '@angular/core'
import moment from 'moment'
import { BackendTagMaster, TextTag } from 'src/api/common/api-common.interface'
import { OrganizationContractV4MacroHierarchyRequest } from 'src/api/contractsV4-api/contractsV4-api.interface'
import { ContractsV4Service } from 'src/api/contractsV4-api/contractsV4-api.service'
import { BackendMerchandiseSimple } from 'src/api/merchandises-api/merchandises-api.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { BackendOrganizationV2, OrganizationCustomer } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { ActionButtonDisp } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { UraSearchService } from '../components/ura-search.service'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'app-ura-contract-individual',
  templateUrl: './ura-contract-individual.component.html',
  styleUrls: ['./ura-contract-individual.component.scss'],
})
export class UraContractIndividualComponent implements OnInit {
  @Input() merchandiseDivisionCd: string
  @Input() hideContractName = false
  formItemsBase: FormInputInfo[] = [
    {
      key: 'contractPrice',
      label: '金額',
      placeholder: '例)500000',
      valid: {
        required: true,
        notBlank: true,
        numOnly: true,
        maxLength: 10
      }
    }
  ]
  formContractName: FormInputInfo = {
    key: 'contractName',
    label: '契約名',
    placeholder: '例)しまむらSI',
    valid: {
      required: true,
      notBlank: false
    }
  }

  constructor(
    private organizationV2Service: OrganizationV2Service,
    private merchandisesApiService: MerchandisesApiService,
    private uraSearchService: UraSearchService,
    private messageService: MessageService,
    private contractsV4Service: ContractsV4Service
  ) { }
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  customers: OrganizationCustomer[]
  selectedCustomerCd = ''
  merchandises: BackendMerchandiseSimple[] = []
  selectedMerchandiseCd = ''
  retailTags: TextTag[] = []
  selectedRetailTagCd = ''
  disabledRetailTag = true
  bussinesCategoryTags: TextTag[] = []
  selectedBussinesCategoryTagCd = ''
  disabledBussinesCategoryTag = true
  allTags: BackendTagMaster[] = []
  otherTags: BackendTagMaster[] = []
  selectedOtherTags: string[] = []
  formValue = {}
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '登録',
      actionFunction: () => {
        this.submit()
      }
    }
  ]
  contractStartDate: Date
  contractEndDate: Date
  sending = false

  ngOnInit(): void {
    this.getMerchandiseList()
    this.getTagMaster()
  }

  get formItems(): FormInputInfo[] {
    if (this.hideContractName) {
      return this.formItemsBase
    }
    else {
      return [...this.formItemsBase, this.formContractName]
    }
  }

  async updateTargetCompany(company: BackendOrganizationV2): Promise<void> {
    this.customers = []
    this.selectedCustomerCd = ''
    if (company?.organizationCd) {
      // const ret = await this.organizationV2Service.GetOrganizationCustomerList(company.organizationCd, true).toPromise()
      const ret$ = this.organizationV2Service.GetOrganizationCustomerList(company.organizationCd, true)
      let ret = await lastValueFrom(ret$)

      if (ret?.resultCode !== 0) { return }
      this.customers = ret.data
    }
  }

  get selectedMerchandise(): BackendMerchandiseSimple {
    if (!this.selectedMerchandiseCd) {
      return null
    }
    return this.merchandises.find(x => x.merchandiseCd === this.selectedMerchandiseCd)
  }

  async submit(): Promise<void> {
    const submitForm = this.inputTable.submit()
    if (!submitForm) {
      return
    }

    if (!this.selectedCustomerCd || !this.contractStartDate || !this.selectedMerchandise) {
      this.messageService.warning('必須項目を入力してください。')
      return
    }

    const addTags = [...this.selectedOtherTags]
    if (this.selectedRetailTagCd) {
      addTags.push(this.selectedRetailTagCd)
    }
    if (this.selectedBussinesCategoryTagCd) {
      addTags.push(this.selectedBussinesCategoryTagCd)
    }

    const startDate = moment(this.contractStartDate).format('YYYY-MM-DD') + 'T00:00:00.000'
    const endDate = this.contractEndDate ? moment(this.contractEndDate).format('YYYY-MM-DD') + 'T00:00:00.000' : '9999-12-31T00:00:00.000'
    const req: OrganizationContractV4MacroHierarchyRequest =
    {
      organizationCd: this.customers.find(x => x.customerCd === this.selectedCustomerCd).organizationCd,
      organizationContractStartDatetime: startDate,
      organizationContractEndDatetime: endDate,
      items: [],
      tags: [],
      contracts: [
        {
          merchandiseCd: this.selectedMerchandiseCd,
          contractName: submitForm.contractName,
          contractPrice: submitForm.contractPrice,
          unitQuantity: 1,
          purchaserCd: this.selectedCustomerCd,
          contractStartDatetime: startDate,
          contractEndDatetime: endDate,
          paymentStartDatetime: startDate,
          paymentEndDatetime: endDate,
          tags: addTags,
          items: []
        }
      ]
    }

    if (this.sending) { return }
    this.sending = true
    // const ret = await this.contractsV4Service.CreateContracts([req]).toPromise()
    const ret$ = this.contractsV4Service.CreateContracts([req])
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.clearForm()
    this.messageService.success('登録が完了しました')
    this.sending = false
  }

  public changeRetail(): void {
    this.bussinesCategoryTags = []
    const merchandiseBCTags = this.uraSearchService.getBusinessCategoryList(this.selectedRetailTagCd)
    const contractBCTags = this.allTags.find(x => x.tagPurposeCd === 'business_category')?.textTags ?? []
    contractBCTags.forEach(contTag => {
      if (merchandiseBCTags.find(x => x.value === contTag.tagCd)) {
        this.bussinesCategoryTags.push(contTag)
      }
    })
    this.disabledBussinesCategoryTag = this.bussinesCategoryTags.length ? false : true
  }

  public updateSelectedTags(tags: TextTag[]): void {
    this.selectedOtherTags = []
    tags.forEach(tag => {
      this.selectedOtherTags.push(tag.tagCd)
    })
  }

  async getMerchandiseList(): Promise<void> {
    // const ret = await this.merchandisesApiService.GetMerchandiseList(this.merchandiseDivisionCd).toPromise()
    const ret$ = this.merchandisesApiService.GetMerchandiseList(this.merchandiseDivisionCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.merchandises = ret.data
  }

  async getTagMaster(): Promise<void> {
    // const ret = await this.contractsV4Service.GetContractsTags().toPromise()
    const ret$ = this.contractsV4Service.GetContractsTags()
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.allTags = ret.data
  }

  public changeMerchandise(): void {
    this.clearTags()
    if (!this.selectedMerchandise) {
      return
    }
    this.retailTags = this.allTags.find(x => x.tagPurposeCd === 'retail')?.textTags ?? []
    this.selectedRetailTagCd = this.selectedMerchandise.tags.find(x => x.tagPurposeCd === 'retail')?.tagCd ?? ''
    this.disabledRetailTag = this.selectedRetailTagCd ? true : false
    this.changeRetail()

    this.otherTags = []
    this.allTags.forEach(tag => {
      if (!this.selectedMerchandise.tags.find(x => x.tagPurposeCd === tag.tagPurposeCd)
        && tag.tagPurposeCd !== 'retail' && tag.tagPurposeCd !== 'business_category') {
        this.otherTags.push(tag)
      }
    })
  }

  // フォームクリア
  clearForm(): void {
    this.inputTable.setForm({}, this.formItems)
    this.selectedMerchandiseCd = ''
    this.contractStartDate = null
    this.contractEndDate = null
    this.clearTags()
  }

  clearTags(): void {
    this.selectedRetailTagCd = ''
    this.selectedBussinesCategoryTagCd = ''
    this.retailTags = []
    this.bussinesCategoryTags = []
    this.otherTags = []
  }
}
