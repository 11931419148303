import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ActionButtonDisp, FooterButtonTextAlign } from 'src/app/module/common-parts/under-btn-disp/under-btn-disp.interface'
import { NewQuotationService } from '../service/new-quotation.service'
import { NewQuotationSession } from '../service/ura-create-quotation.interface'

@Component({
  selector: 'app-ura-new-quotation-remarks',
  templateUrl: './ura-new-quotation-remarks.component.html',
  styleUrls: ['./ura-new-quotation-remarks.component.scss']
})
export class UraNewQuotationRemarksComponent implements OnInit {
  title = '新規見積もり作成'
  session: NewQuotationSession
  remarks = ''
  loading = true
  actionButtons: ActionButtonDisp[] = [
    {
      label: '戻る',
      textAlign: FooterButtonTextAlign.LEFT,
      actionFunction: () => {
        this.router.navigate([`/ura-new-quotation-merchandise/${this.session?.selectedGroups?.length - 1}`])
      },
    },
    {
      label: '次へ',
      actionFunction: () => {
        this.pageNext()
      }
    }
  ]
  constructor(
    private router: Router,
    private newQuotationService: NewQuotationService
  ) { }

  ngOnInit(): void {
    const check = this.newQuotationService.pageInit()
    if (!check) { return }

    this.session = this.newQuotationService.Session
    this.remarks = this.session.remarks
  }

  pageNext(): void {
    this.session.remarks = this.remarks
    this.newQuotationService.setSession(this.session)
    this.router.navigate([`/ura-quotation-preview-new`])
  }
}
