<app-sending *ngIf="sending"></app-sending>
<app-dialog
  *ngIf="dialogOpen"
  (commit)="update()"
  (cancel)="dialogOpen = false"
  [title]="'サービス稼働設定'"
  widthSize="small"
  commitButtonText="更新"
>
  <app-input-table [formItems]="formItems"></app-input-table>
</app-dialog>
