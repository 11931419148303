import { Component, OnInit } from '@angular/core'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { UraContractEntryListService } from '../ura-contract-entry-list/ura-contract-entry-list..service'
import { ContaApiService } from 'src/api/conta-api/conta-api.service'
import { UraSearchService } from '../components/ura-search.service'
import { SelectListItem } from '../../../module/myidea-parts/input-selectbox/input-selectbox.interface'
import { AuthorityDivision, ContaSearchUserMacroRequest, ContractListItem, InputProductInquiryCd, ProductMasterUI, SearchUser, SearchUserUI } from 'src/api/conta-api/conta-api.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { Header, TextAlignValue, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { lastValueFrom } from 'rxjs'
@Component({
  selector: 'app-ura-user-search-list',
  templateUrl: './ura-user-search-list.component.html',
  styleUrls: ['./ura-user-search-list.component.scss'],
})
export class UraUserSearchListComponent implements OnInit {
  title = '検索：利用者一覧'
  data: SearchUser[] = []
  dataUI: SearchUserUI[] = []
  columns: Header[] = [
    {
      key: 'productName',
      label: 'プロダクト',
      width: '13%'
    },
    {
      key: 'productInquiryCd',
      label: 'プロダクトコード',
      width: '13%'
    },
    {
      key: 'supplierCd',
      label: 'サプライヤーコード',
      width: '10%'
    },
    {
      key: 'businessCategoryTagName',
      label: '業態',
      width: '10%'
    },
    {
      key: 'contractCompanyCorporateNumber',
      label: '法人番号',
      width: '10%',
      groupLabel: '契約'
    },
    {
      key: 'contractCompanyOrganizationName',
      label: '会社',
      width: '13%',
      groupLabel: '契約'
    },
    {
      key: 'corporateNumber',
      label: '法人番号',
      width: '10%',
      groupLabel: '担当者'
    },
    {
      key: 'companyOrganizationName',
      label: '会社',
      width: '13%',
      groupLabel: '担当者'
    },
    {
      key: 'sectionOrganizationName',
      label: '部署',
      width: '10%',
      groupLabel: '担当者'
    },
    {
      key: 'customerName',
      label: '名前',
      width: '10%',
      groupLabel: '担当者'
    },
    {
      key: 'phoneNumber',
      label: '電話番号',
      width: '8%',
      groupLabel: '担当者'
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス',
      width: '18%',
      groupLabel: '担当者'
    },
    {
      key: 'authorityDivisionCdsContractor',
      label: '契約担当',
      width: '9%',
      style: {
        textAlign: TextAlignValue.center
      },
      groupLabel: '権限'
    },
    {
      key: 'authorityDivisionCdsInvoice',
      label: '請求書宛先',
      width: '9%',
      style: {
        textAlign: TextAlignValue.center
      },
      groupLabel: '権限'
    },
    {
      key: 'authorityDivisionCdsSuperuser',
      label: 'スーパーユーザー',
      width: '9%',
      style: {
        textAlign: TextAlignValue.center
      },
      groupLabel: '権限'
    },
    {
      key: 'authorityDivisionCdsUser',
      label: 'ユーザー',
      width: '9%',
      style: {
        textAlign: TextAlignValue.center
      },
      groupLabel: '権限'
    },
    {
      key: 'actuateStatusUi',
      label: '状態',
      width: '8%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'applicationDatetimeUi',
      label: '設定開始日',
      width: '9%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'actuateDatetimeUi',
      label: '稼働日',
      width: '9%',
      style: {
        textAlign: 'center'
      }
    },
  ]
  rowSelection = RowSelection.NONE
  loading = true

  serviceTagList: SelectListItem[] = []
  retailTagList: SelectListItem[] = []
  bussinesCategoryTagList: SelectListItem[] = []
  actuateStatusList: any[] = []
  authorityDivisionMaster: AuthorityDivision[]

  selectedValueKey: string[]
  productMasterListUI: ProductMasterUI[]

  values: any = {
    serviceTagCd: null,
    retailTagCd: null,
    bussinesCategoryTagCds: [],
    productCds: [],
    actuateName: '',
    productCdForInquiry: '',
    productNumber: '',
    supplierCds: '',
    companyOrganizationCd: '',
  }

  searchedNumber = '-'
  errorRequest = false

  constructor(
    private uraContractEntryListService: UraContractEntryListService,
    private uraSearchService: UraSearchService,
    private contaService: ContaApiService,
    private merchandisesService: MerchandisesApiService
  ) { }

  async ngOnInit(): Promise<void> {
    this.setTagList()
    this.changeRetail(null)
    this.loading = false
    this.authorityDivisionMaster = this.uraContractEntryListService.getAuthorityList()
    this.productMasterListUI = await this.uraContractEntryListService.getProductList()
  }

  // タグ情報取得
  async setTagList(): Promise<void> {
    // const ret = await this.merchandisesService.GetMerchandisesTagMaster().toPromise()
    const ret$ = this.merchandisesService.GetMerchandisesTagMaster()
    let ret = await lastValueFrom(ret$)

    this.serviceTagList = this.uraSearchService.getServiceList(ret.data)
    this.retailTagList = this.uraSearchService.getRetailList(ret.data)
    this.actuateStatusList = this.uraContractEntryListService.getActuateStatus()
  }


  changeValue(key: string, value: any): void {
    this.values[key] = value
  }

  changeProductInquiryCd(event: InputProductInquiryCd): void {
    this.errorRequest = event.isError
    this.values.productCdForInquiry = event.productCdForInquiry
    this.values.productNumber = event.productNumber
  }

  changeRetail(retailTagCd: string): void {
    this.bussinesCategoryTagList = this.uraSearchService.getBusinessCategoryList(retailTagCd)
    this.changeValue('retailTagCd', retailTagCd)
    this.changeValue('bussinesCategoryTagCds', [])
  }

  checkSelectedActuate(selectedStatus): boolean {
    if (this.uraContractEntryListService.getSelectedActuateStatus() === selectedStatus) {
      return true
    } else {
      return false
    }
  }
  changeSelectedActuate(selectedStatus) {
    if (this.uraContractEntryListService.getSelectedActuateStatus() === selectedStatus) {
      this.uraContractEntryListService.selectActuateStatus('')
    } else {
      this.uraContractEntryListService.selectActuateStatus(selectedStatus)
    }
  }

  async search(): Promise<void> {
    this.data = []
    if (this.errorRequest) { return }
    this.loading = true
    const userRet = await this.contaService.SearchUserList(this.setRequest())
    this.loading = false
    if (userRet?.resultCode !== 0) { return }
    this.data = userRet.data
    this.dataUI = userRet.data
    this.dataUI.forEach(data => {
      if (data.authorityDivisionCds.length !== 0) {
        data.authorityDivisionCds.forEach(divisionCd => {
          if (divisionCd === 'xxxxx') { data.authorityDivisionCdsAdministrator = '●' }  // 契約主
          if (divisionCd === 'contractor') { data.authorityDivisionCdsContractor = '●' }  // 契約担当
          if (divisionCd === 'invoice') { data.authorityDivisionCdsInvoice = '●' }  // 請求書宛先
          if (divisionCd === 'superuser') { data.authorityDivisionCdsSuperuser = '●' }  // スーパーユーザー
          if (divisionCd === 'user') { data.authorityDivisionCdsUser = '●' }  // ユーザー
        })
      }
    })
  }
  setRequest(): ContaSearchUserMacroRequest {
    let supplierCdList = []
    if (this.values.supplierCds) {
      supplierCdList = this.values.supplierCds.split(',')
    }
    const tagCds: string[] = []
    if (this.values.serviceTagCd) { tagCds.push(this.values.serviceTagCd) }
    if (this.values.retailTagCd) { tagCds.push(this.values.retailTagCd) }
    const req: ContaSearchUserMacroRequest = {
      tagCds,
      businessCategoryCds: this.values.bussinesCategoryTagCds,
      companyOrganizationCd: this.values.companyOrganizationCd || null,
      productCdForInquiry: this.values.productCdForInquiry || null,
      productNumber: this.values.productNumber || null,
      productCds: this.values.productCds,
      supplierCds: supplierCdList,
      actuateStatus: this.uraContractEntryListService.getSelectedActuateStatus(), // 0:未稼働、1:稼働中、2:解約済
      authorityDivisionCds: this.uraContractEntryListService.getSelectedAuthority()
    }
    return req
  }
  changeAuthority(selectedCd) {
    this.uraContractEntryListService.selectAuthorityList(selectedCd)
  }
  checkSelectedAuthority(selectedCd): boolean {
    // checkSelectedAuthority
    return this.uraContractEntryListService.checkSelectedAuthority(selectedCd)
  }
}
