import { Injectable } from '@angular/core'
import { lastValueFrom, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo, ResultInfoSingle } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { BackendOrganizationCustomer, BEMacroCustomer, Customer, CustomerUi, OrganizationWithCustomer } from './customers-api.interface'

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  baseURL = environment.myideaMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
  ) { }

// 顧客情報参照
  async SearchByCd(customerCd: string): Promise<ResultInfoSingle<CustomerUi>> {
    const url = `${this.baseURL}api/customer/searchByCd/${customerCd}`
    const ret$ = this.httpRequestService.getResultSingle<CustomerUi>(url)
    return await lastValueFrom(ret$)
  }

  // メールアドレスで顧客検索
  async SearchByMailAddress(mailAddress: string): Promise<ResultInfo<CustomerUi>> {
    const url = `${this.baseURL}api/customer/searchByMailAddress`
    const ret$ = this.httpRequestService.post<CustomerUi>(url, [mailAddress])
    return await lastValueFrom(ret$)
  }

  // 検索(組織CD)
  async SearchByOrganizationCds(organizationCds: string[]): Promise<ResultInfo<OrganizationWithCustomer>> {
    const url = `${this.baseURL}api/customer/searchByOrganizationCds`
    const ret$ = this.httpRequestService.post<OrganizationWithCustomer>(url, organizationCds)
    return await lastValueFrom(ret$)
  }

  // 顧客を登録
  async Create(
    payload: CustomerUi[],
    organizationCd?: string
  ): Promise<ResultInfo<CustomerUi>> {
    let url = `${this.baseURL}api/customer/create`
    if (organizationCd) {
      url = `${url}?organizationCd=${organizationCd}`
    }
    const ret$ = this.httpRequestService.post<CustomerUi>(url, payload)
    return await lastValueFrom(ret$)
  }

  // 顧客情報更新
  async Update(payload: CustomerUi): Promise<ResultInfoSingle<CustomerUi>> {
    const url = `${this.baseURL}api/customer/update`
    const ret$ = this.httpRequestService.putResultSingle<CustomerUi>(url, payload)
    return await lastValueFrom(ret$)
  }
}
