import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { LauncherInfo } from './launcher-api.interface'

@Injectable({
  providedIn: 'root'
})
export class LaunchersService {
  baseURL = environment.myideaMacroBaseUrl

  constructor(
    private httpRequestService: HttpRequestService
    ) { }

  // ランチャー(製品リンク)情報取得
  async GetLauncherInfo(
    customerCd: string
  ): Promise<ResultInfo<LauncherInfo>> {
    const url = `${this.baseURL}api/launcher/customers/${customerCd}`
    const ret$ = this.httpRequestService.get<LauncherInfo>(url)
    return await lastValueFrom(ret$)
  }

  // 利用者になっている製品取得(My-ideaの権限制御用)
  async GetUserProductCd(
    customerCd: string
  ): Promise<ResultInfo<string>> {
    const url = `${this.baseURL}api/launcher/userProductCd/customers/${customerCd}`
    const ret$ = this.httpRequestService.get<string>(url)
    return await lastValueFrom(ret$)
  }
}
