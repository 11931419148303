export interface ContractCompany {
  companyOrganizationCd: string
  contractCompanyName: string
  sectionOrganizationCd: string // 今貰っていない
  sectionOrganizationName: string
  purchaserName: string
  telephoneNumber: string
  mailAddress: string
  representativeProductName: string
  sendMailDate: string
  approvalDate: string
  sendUserInvitationMailDate: string
  migrationStatusCd: string
  migrationStatusName: string
  migrationServiceUserStatusCd: string
  migrationServiceUserStatusName: string
  actionCds: string[]
}

export interface InvoiceTarget {
  invoiceTargetCd: string
  companyOrganizationCd: string
  companyOrganizationName: string
  sectionOrganizationCd: string
  sectionOrganizationName: string
  customerCd: string
  customerName: string
  title: string
  suppliCustomerId: number
  actionCds: string[]
}

export interface InvoiceProduct {
  actuateCd: string,
  serviceName: string,
  productInquiryCd: string,
  canUpdate: boolean // falseの場合は更新させない
}

export interface ActuateInvocieInfo {
  contractCd: string
  actuateCd: string
  serviceName: string
  productInquiryCd: string
  companyOrganizationCd: string
  contractSectionOrganizationCd: string
  contractCompanyName: string
  sectionOrganizationName: string
  invoiceCompanyOrganizationCd?: string
  invoiceSectionOrganizationCd?: string
  invoiceCompanyName?: string
  invoiceSectionName?: string
  customerCd?: string
  customerName?: string
  invoiceTargetCd?: string
  title?: string
  suppliCustomerId?: string
  editable: boolean
}

export interface InvoiceContractModel {
  contractCd: string
  contractCompanyOrganizationCd: string
  contractCompanyOrganizationName: string
  contractSectionOrganizationCd: string
  contractSectionOrganizationName: string
  invoiceCompanyOrganizationCd: string
  invoiceCompanyOrganizationName: string
  invoiceTargetCd: string
  suppliCustomerId: number
  serviceTagCd: string
  serviceTagName: string
  retailTagCd: string
  retailTagText: string
  businessCategoryTagCd: string
  businessCategoryTagName: string
  merchandiseCd: string
  merchandiseName: string
  merchandiseDisplayName: string
  productCd: string
  productInquiryCd: string
  productName: string
  optionName: string
  supplierCd: string
}


export interface InvoiceTargetMaster {
  invoiceTargetCd: string
  suppliCustomerId: number
  title: string
  organizationId: string
  organizationCd: string
  organizationFullName: string
  customerId: string
  customerCd: string
  familyName: string
  firstName: string
  customerName: string
}

export interface InvoiceContractModel {
  contractCd: string
  contractCompanyOrganizationCd: string
  contractCompanyOrganizationName: string
  contractSectionOrganizationCd: string
  contractSectionOrganizationName: string
  invoiceCompanyOrganizationCd: string
  invoiceCompanyOrganizationName: string
  invoiceTargetCd: string
  suppliCustomerId: number
  serviceTagCd: string
  serviceTagName: string
  retailTagCd: string
  retailTagText: string
  businessCategoryTagCd: string
  businessCategoryTagName: string
  merchandiseCd: string
  merchandiseName: string
  merchandiseDisplayName: string
  productCd: string
  productInquiryCd: string
  productName: string
  optionName: string
  supplierCd: string
}
export interface BillinYm {
  billingYM: number
  billingYMStr: string
  billingYMName: string
  invoiceCount: number
}

export interface InvoiceContractModelUI extends InvoiceContractModel {
  backColor: boolean
}

export interface CreateInvoiceTargetRequest {
  suppliBillingCustomerId?: number
  customerCd?: string
  organizationCd?: string
  title?: string
  personName?: string
  branchOffice?: string
  sectionName?: string
  prefecture?: string
  city?: string
  blockName?: string
  postCode?: string
  telNo?: string
  faxNo?: string
  invoiceTargetCd?: string
  actionCds?: string[],
  organizationPaymentMethodCd?: string
}

export interface SearchInvoiceTarget {
  companyOrganizationCd: string
  invoiceTargetCd: string
}

// 請求Leafに利用者を追加するときのパラメータ
export interface InvitaitonTargetCustomer {
  targetOrganizationCd: string
  targetCustomerCd?: string // 新規顧客の場合は空
  mailAddress: string
  familyName: string
  firstName: string
}
