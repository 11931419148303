<app-customer-name-disp
  (targetSelected)="selectTargetOrganization($event)"
></app-customer-name-disp>

<app-main-contents *ngIf="selectedTargetOrg?.organizationCd">
  <app-title [title]="title"></app-title>
  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    [height]="430"
  ></app-action-grid>
</app-main-contents>
