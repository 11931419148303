export interface InvoiceActuate {
  actuateCd: string
  invoiceTargetCd: string
  title: string
  suppliCustomerId: number
  personName: string
  paymentTypeCd: string
}

export interface FileProf {
  claimantOrganizationId: string
  year: string
  month: string
  actuateId: string
  fileName: string
  fullPath: string
}

export interface InvoiceData {
  invoiceDataCd: string
  invoiceKeyNumber: string
  deadlineDateUi: string
  suppliCustomerId: number
  totalBill: number
  totalBillUi: string
  companyOrganizaionCd: string
  companyOrganizaionName: string
  customerName: string
  paymentMethodTypeCd: string
  paymentMethodTypeName: string
  paymentTypeCd: string
  paymentTypeName: string
  organizationPaymentMethodCd: string
  organizationPaymentMethodItems: OrganizationPaymentMethodItems
  status: number
  statusUi: string
  actionCds?: string[]
}

export interface SendForBankStatus extends InvoiceData {
  organizationPaymentMethodCd: string
  organizationPaymentMethodItems: OrganizationPaymentMethodItems
  bankStatusCd: number
  bankStatusName: string
}

export interface SendForBankHistory {
  invoiceDataId: string
  actionCd: number
  actionName: string
  resultCd: number
  resultMessage: string
  content: any
  updatedAt: Date
  updatedDatetimeUi: string
}

export interface OrganizationPaymentMethodItems {
  bankNo: string
  bankName: string
  branchNo: string
  accountNo: string
  branchName: string
  accountName: string
  accountType: string
}

export interface PaymentMethodAccount {
  organizationPaymentMethodCd: string
  organizationCd: string
  organizationName: string
  paymentMethodItems: OrganizationPaymentMethodItems
  bankNo: string
  bankName: string
  branchNo: string
  accountNo: string
  branchName: string
  accountName: string
  accountType: string
  accountTypeUi: string
  suppliCustomerIds: string[]
  suppliCustomerIdsUi: string
  actionCds: string[]
}

export interface BankMaster{
  code: string;
  name: string;
  halfWidthKana: string;
}
