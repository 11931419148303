import { Component, OnInit, ViewChild } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { CompanyOrganization } from 'src/api/organization-company-api/organization-company-api.interface'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { AutoMap, JoinText } from 'src/app/common/common'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { CreateAccountUrlDialogComponent } from '../components/create-account-url-dialog/create-account-url-dialog.component'
import { OrganizationAccountUi } from './ura-account-link.interface'
import { UraAccountLinkUrlSendMailPreviewDialogComponent } from './components/ura-account-link-url-send-mail-preview'
import { BackendOrganizationCustomerAccount } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'

@Component({
  selector: 'app-ura-account-link',
  templateUrl: './ura-account-link.component.html',
  styleUrls: ['./ura-account-link.component.scss']
})
export class UraAccountLinkComponent implements OnInit {
  title = 'アカウント登録URL発行'
  loading = false
  customers: OrganizationAccountUi[] = []
  customerColumns: Header[] = [
    {
      key: 'organizationFullName',
      label: '組織名'
    },
    {
      key: 'customerName',
      label: '名前',
      width: '12%'
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス'
    },
    {
      key: 'isSendMailName',
      label: 'メール送信ステータス'
    }
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '登録URL発行',
      actionCd: 'actionCd1'
    },
    {
      label: 'プレビュー表示',
      actionCd: 'actionCd2'
    }
  ]
  cellClasses = {
    color_red: (row: any, columnKey: string): boolean => {
      return columnKey === 'isSendMailName' && row.isSendMail === false
    }
  }

  selectedTargetOrg: CompanyOrganization
  @ViewChild(CreateAccountUrlDialogComponent, { static: false })
  private dialog: CreateAccountUrlDialogComponent

  @ViewChild(UraAccountLinkUrlSendMailPreviewDialogComponent, { static: false })
  private mailDialog: UraAccountLinkUrlSendMailPreviewDialogComponent

  constructor(
    private organizationV2Service: OrganizationV2Service,
    private easyAuthenticationsApiService: EasyAuthenticationsApiService
  ) {}

  ngOnInit(): void {}

  selectTargetOrganization(event: CompanyOrganization): void {
    this.selectedTargetOrg = event
    if (event) this.getCustomerList(this.selectedTargetOrg.organizationCd)
  }

  // 顧客一覧検索
  async getCustomerList(organizationCd: string): Promise<void> {
    if (!organizationCd) {
      return
    }
    const ret = await this.getCustomerAccountInfos(organizationCd)
    if (ret?.resultCode !== 0) {
      return
    }
    const customers: OrganizationAccountUi[] = []
    const noAccountCustomers = ret.data.filter((x) => !x.isFormalAccount)
    const addIsSendMailName = noAccountCustomers.map((i: any) => {
      return {
        ...i,
        isSendMailName: this.convertIsSendMailStatus(i.isSendMail)
      }
    })
    addIsSendMailName.forEach((i) => {
      const row = AutoMap<OrganizationAccountUi>(i)
      row.customerName = JoinText([row.familyName, row.firstName], ' ')
      customers.push(row)
      row.actionCds = []
      row.actionCds.push('actionCd1')
      row.actionCds.push('actionCd2')
    })
    this.customers = customers
  }

  convertIsSendMailStatus(isSendMail: boolean): string {
    return isSendMail ? '送信済' : '未送信'
  }

  async getCustomerAccountInfos(organizationCd: string): Promise<ResultInfo<any>> {
    this.loading = true
    this.customers = []
    // const ret = await this.organizationV2Service.GetCustomerAccountInfos(organizationCd, true).toPromise()
    const ret$ = this.organizationV2Service.GetCustomerAccountInfos(organizationCd, true)
    const ret = await lastValueFrom(ret$)

    this.loading = false
    return ret
  }
  actionCommit(event: ActionTargetInfo): void {
    if (event.actionCd === 'actionCd1') {
      const row = event.rows[0]
      this.dialog.getAccountPath(row.customerCd, row.customerName, row.organizationFullName)
    } else if (event.actionCd === 'actionCd2') {
      const row = event.rows[0]
      this.mailDialog.getPreviewData(row.customerCd)
    }
  }
  async sendMailEmit(customerCd: string): Promise<void> {
    const ret$ = this.easyAuthenticationsApiService.DeleteTemporaryAccountEmailNotSendFile(customerCd)
    const ret = await lastValueFrom(ret$)
    console.log(ret)
    await this.getCustomerList(this.selectedTargetOrg.organizationCd)
  }
}
