import { Component, OnInit } from '@angular/core'
@Component({
  selector: 'app-ura-sundry-contract',
  templateUrl: './ura-sundry-contract.component.html',
  styleUrls: ['./ura-sundry-contract.component.scss'],
})
export class UraSundryContractComponent implements OnInit {
  constructor() { }
  title = '諸口契約登録'
  merchandiseDivisionCd = 'syokuchi'
  ngOnInit(): void {}
}
