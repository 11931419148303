
 <a
 (click)="tagEditMode = !tagEditMode"
 class="c-btn--s edit-tag-btn"
 style="cursor: pointer"
 ><span>{{ tagEditMode ? "編集モードを終了" : "編集" }}</span></a
>
<ng-container *ngIf="!tagEditMode">
 <a class="tag tag-select" *ngFor="let tag of selectedTags">
   {{ tag.tagText }}
 </a>
</ng-container>
<ng-container *ngIf="tagEditMode">
 <div class="" *ngFor="let purposeTag of tags">
   <div class="purpose-tag-label">
     <p>{{ purposeTag.tagPurposeText }}</p>
   </div>
   <div class="">
     <a
       class="tag"
       (click)="tagClick(textTag)"
       style="cursor: pointer"
       [class.tag-select]="selected(textTag.tagCd)"
       *ngFor="let textTag of purposeTag.textTags"
     >
       {{ textTag.tagText }}
     </a>
   </div>
 </div>
</ng-container>