import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { AccountsV3Service } from 'src/api/accountsV3-api/accountsV3-api.service'
import { EasyAuthenticationsApiService } from 'src/api/easy-authentications-api/easy-authentications-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { MessageService } from 'src/app/components/message/message.service'
import { FromInputService } from 'src/app/module/common-parts/input-table/form-input.service'
import { InputTableComponent } from 'src/app/module/common-parts/input-table/input-table.component'
import { FormInputInfo } from 'src/app/module/common-parts/input-table/input-table.model'
import { EasyAuthenticationLoginService } from 'src/app/module/myidea-parts/easy-authentication-login/easy-authentication-login.service'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  easyAuthenticationCd: string
  password: string
  sending = false
  formInput: any
  formItems: FormInputInfo[] = []

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private fromInputService: FromInputService,
    private accountsV3Service: AccountsV3Service,
    private easyAuthenticationsApiService: EasyAuthenticationsApiService,
    private easyAuthenticationLoginService: EasyAuthenticationLoginService,
  ) { }

  ngOnInit(): void {
    const mailAddressForm = this.fromInputService.mailAddressFormReadOnly()
    const passwordForms = this.fromInputService.changePasswordForm()
    this.formInput = {
      mailAddress: '',
      nickname: '',
      nicknameConfirmation: ''
    }
    this.formItems = [mailAddressForm, ...passwordForms]

    this.easyAuthenticationCd = this.easyAuthenticationLoginService.TemporaryAccount.easyAuthenticationCd
  }
  ngAfterViewInit(): void {
    this.getEasyAuthentications()
  }
  async getEasyAuthentications(): Promise<void> {
    // const ret = await this.easyAuthenticationsApiService.GetEasyAuthentications(this.easyAuthenticationCd).toPromise()
    const ret$ = this.easyAuthenticationsApiService.GetEasyAuthentications(this.easyAuthenticationCd)
    let ret = await lastValueFrom(ret$)

    if (ret?.resultCode !== 0) { return }
    this.formInput.mailAddress = ret.data[0].mailAddress
    this.inputTable.setForm(this.formInput)
  }
  async submit(): Promise<void> {
    if (this.sending) { return }
    const submitForm = this.inputTable.submit()

    if (submitForm.password !== submitForm.passwordConfirmation) {
      this.messageService.error('新しいパスワードと新しいパスワード（確認用）が一致しません')
      return
    }
    this.sending = true
    // const ret = await this.accountsV3Service.ResetPassword(this.easyAuthenticationCd, submitForm.password).toPromise()
    const ret$ = this.accountsV3Service.ResetPassword(this.easyAuthenticationCd, submitForm.password)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    if (!ret.data[0]?.accountId) {
      this.messageService.error('処理に失敗しました。再度申請を行って下さい')
      return
    }
    this.authService.setAutoLoginAccountId(ret.data[0].accountId)
    this.messageService.success('パスワードを再設定しました')
    setTimeout(() => {
      this.router.navigate(['/'])
    }, 2000)
  }
}
