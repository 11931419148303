import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { ServiceMapSubInfo } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { TargetActionBlockInfo, TargetServiceBlockInfo, ServiceMapActionBlock, ServiceMapViewBlock } from '../service-map-view.interface'
import { ServiceMapViewService } from '../service-map-view.service'
declare const APP
@Component({
  selector: 'app-service-map-main',
  templateUrl: './service-map-main.component.html',
  styleUrls: ['./service-map-main.component.scss']
})
export class ServiceMapMainComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() serviceMap: ServiceMapViewBlock[] = []
  @Input() loading = false
  @Output() clickService = new EventEmitter<TargetServiceBlockInfo>()
  @Output() clickIcon = new EventEmitter<TargetServiceBlockInfo>()
  @Output() clickActionBlock = new EventEmitter<TargetActionBlockInfo>()
  serviceDivisions: string[] = []
  backServiceMap: ServiceMapViewBlock[] = []
  backConnectedMoreMap: ServiceMapViewBlock[] = []
  backUnconectedMap: ServiceMapViewBlock[] = []
  dispServiceMap: ServiceMapViewBlock[] = []
  dispConnectedMoreMap: ServiceMapViewBlock[] = []
  dispUnconectedMap: ServiceMapViewBlock[] = []
  selectDivisionName = ''
  showDivisionPanel = false
  subInfo: ServiceMapSubInfo
  subInfos: ServiceMapSubInfo[]
  openConectedMoreArea = true
  openPackArea = true

  constructor(
    private serviceMapViewService: ServiceMapViewService
  ) { }

  async ngOnInit(): Promise<void> {
    const allMap = this.serviceMap
    const rules = this.serviceMapViewService.ActuateRelationRules

    // ランチャーが複数ある場合は連番を付ける
    const added: any[] = []
    allMap.forEach(block => {
      if (rules.find(x => x.toProductCd === block.productCd)?.fromMultiple
        && allMap.filter(x => x.productCd === block.productCd).length > 1) {
        let addedProduct = added.find(x => x.productCd === block.productCd)
        if (!addedProduct) {
          added.push({
            productCd: block.productCd,
            count: 0
          })
          addedProduct = added.find(x => x.productCd === block.productCd)
        }
        addedProduct.count += 1
        block.launcherCount = addedProduct.count
      }
    })

    const connectedMap = this.serviceMapViewService.filterConnected(allMap)
    this.backUnconectedMap = this.serviceMapViewService.filterUnconnected(allMap)
    this.backServiceMap = connectedMap.filter(x => !x.isLauncher)
    this.backConnectedMoreMap = connectedMap.filter(x => x.isLauncher)

    // 絞り込みプルダウン情報をセット
    this.serviceDivisions = []
    allMap.forEach((service) => {
      this.serviceDivisions.push(this.serviceMapViewService.divisionName(service))
    })
    this.changeDivision('')
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }

  changeDivision(division: string): void {
    if (!division) {
      this.dispServiceMap = this.backServiceMap
      this.dispConnectedMoreMap = this.backConnectedMoreMap
      this.dispUnconectedMap = this.backUnconectedMap
      this.selectDivisionName = '全て'
    } else if (division === 'unconnected') {
      this.dispServiceMap = []
      this.dispConnectedMoreMap = []
      this.dispUnconectedMap = this.backUnconectedMap
      this.selectDivisionName = '未接続'
    } else if (division === 'connectedMore') {
      this.dispServiceMap = []
      this.dispConnectedMoreMap = this.backConnectedMoreMap
      this.dispUnconectedMap = []
      this.selectDivisionName = 'ランチャー系'
    } else {
      this.dispServiceMap = this.backServiceMap.filter(
        (x) => this.serviceMapViewService.divisionName(x) === division
      )
      this.dispUnconectedMap = []
      this.selectDivisionName = division
    }
  }

  // 全ブロックが開いているか
  get arrowAllOpen(): boolean {
    if (!this.openPackArea || !this.openConectedMoreArea) { return false }
    let open = true
    this.dispServiceMap.forEach(block1 => {
      if (block1.childHidden) {
        open = false
      }
    })
    return open
  }

  // 全て展開する or 全て格納する ボタン押下時
  clickAllArrowButton(): void {
    if (this.arrowAllOpen) {
      // 全て格納
      this.backServiceMap.forEach(block1 => {
        block1.childHidden = true
      })
      this.openConectedMoreArea = false
      this.openPackArea = false
    }
    else {
      // 全て展開
      this.backServiceMap.forEach(block1 => {
        block1.childHidden = false
      })
      this.openConectedMoreArea = true
      this.openPackArea = true
    }
  }

  // 5階層目(ランチャー)を持っているか
  connectedChild(block: ServiceMapViewBlock): ServiceMapViewBlock {
    return this.serviceMap.find(x => x.parentActuateCds.find(x => x === block.actuateCd))
  }
  
  // 接続先別サービス(ミコメル)を持っているか
  connectedOtherService(block: ServiceMapViewBlock): ServiceMapViewBlock {
    return block.children?.find(x => x.serviceCd !== block.serviceCd)
  }

  // 連番付きのランチャー表示
  merchandiseName(block: ServiceMapViewBlock): string {
    let name = block.merchandiseDisplayName
    if (block.launcherCount > 0) {
      name += block.launcherCount
    }
    return name
  }

  clickServiceEvent(targetBlock: ServiceMapViewBlock, parentBlock: ServiceMapViewBlock, floorNumber: number): void {
    const data: TargetServiceBlockInfo = {
      targetBlock, parentBlock, floorNumber
    }
    this.clickService.emit(data)
  }

  clickIconEvent(targetBlock: ServiceMapViewBlock, parentBlock: ServiceMapViewBlock, floorNumber: number): void {
    const data: TargetServiceBlockInfo = {
      targetBlock, parentBlock, floorNumber
    }
    this.clickIcon.emit(data)
  }

  clickActionBlockEvent(targetBlock: ServiceMapActionBlock, parentBlock: ServiceMapViewBlock, floorNumber: number): void {
    const data: TargetActionBlockInfo = {
      targetBlock, parentBlock, floorNumber
    }
    this.clickActionBlock.emit(data)
  }

  ngAfterViewInit(): void {
    APP.controller.reset()
  }


}
