// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
// 配列ソート関数
// item: Arrey
// key: ソートキー
// sorttype: 昇順(true)降順(false)
// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
export function sortedItems(item: any[], key: string, sorttype = true): any[] {
  // export function sortedItems<T>(item: T[], key: string, sorttype = true): T[]{
  item.sort((a, b) => {
    a = a[key]
    b = b[key]
    return (a === b ? 0 : a > b ? 1 : -1) * (sorttype ? 1 : -1)
  })
  return item
}

// --------------------------------------------
// 配列ソート関数 2次元配列
// item: Arrey
// key1: ソートキー
// sorttype1: 昇順(true)降順(false)
// key2: ソートキー
// sorttype2: 昇順(true)降順(false)
// --------------------------------------------
export function sortedItems2(item, key1: string, sorttype1 = true, key2: string, sorttype2 = true) {

  item.sort((a, b) => {
    // Compare 1st key
    if (a[key1] < b[key1]) {
      return -1 * (sorttype1 ? 1 : -1)
    }
    else if (a[key1] > b[key1]) {
      return 1 * (sorttype1 ? 1 : -1)
    }
    else {
      // Compare 2nd key
      if (a[key2] < b[key2]) {
        return -1 * (sorttype2 ? 1 : -1)
      }
      else if (a[key2] > b[key2]) {
        return 1 * (sorttype2 ? 1 : -1)
      }
      else {
        return 0
      }
    }
  })
  return item
}

// AutoMapになってないけど...今はこれで事足りる
export function AutoMap<T>(before: any): T {
  const after: T = JSON.parse(JSON.stringify(before))
  return after
}

export function DeepCopy<T>(before: T): T {
  const after: T = JSON.parse(JSON.stringify(before))
  return after
}

/**
 * string項目を1つに結合する
 * 配列の各要素がnullの場合は除外する
 * @param wordList 結合したい配列
 * @param separat セパレート (例:"-")
 * @returns 結合後の文字列
 */
export function JoinText(wordList: string[], separat: string = ''): string {
  
  // 配列か確認する （違う場合は空文字を返す）
  if (!Array.isArray(wordList)) {
    return ''
  }

  let ret = ''
  let count = 0
  wordList.forEach(word => {
    if (word) {
      if (count !== 0) {
        ret += separat
      }
      ret += word
      count++
    }
  })
  return ret
}

export function GroupBy(array: any[], key: string) {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
}