<app-main-contents>
  <app-sending *ngIf="sending"></app-sending>
  <app-title [title]="title"></app-title>
  <div *ngIf="showPage" class="sub-text">
    <p>
      {{ actuateInfo?.coreProductName
      }}<ng-container *ngIf="actuateName"> ({{ actuateName }})</ng-container>
    </p>
    <p>契約会社：{{ actuateInfo?.coreContractCompanyName }}</p>
    <p>
      登録済みユーザー：{{ data.length }}人　/　契約の上限：{{
        actuateInfo?.accountBoxMaxValue
      }}人
    </p>
  </div>
  <app-action-grid
    *ngIf="showPage"
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    (actionCommit)="actionCommit($event)"
    (updateCheckBox)="updateCheckBox($event)"
  ></app-action-grid>
</app-main-contents>
