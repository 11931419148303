import { Directive, ElementRef, HostListener, Input } from '@angular/core'
import { AuthService } from '../components/isbeauth/auth.service'
import { ActionTransactionSettings, ActionTransactionService } from './action-transaction.services'
import { FrontendCommonMacroApiService } from 'src/api/frontend-common-api/frontend-common-api.service'

export interface ActionTransactionData {
  name: string
  viewCd: string
  commandCd: string
  commandName?: string
  customerId: string
  customerName: string
  companyOrganizationId?: string
  companyOrganizationName?: string
  organizationId?: string
  organizationName?: string
  applicationId?: string
  actuateId?: string
  transactionId?: string
  options?: any
}

@Directive({
  selector: '[ActionTransaction]'
})
export class ActionTransactionDirective {
  constructor(
    private el: ElementRef,
    private actionTransactionService: ActionTransactionService,
    private frontendCommonMacroApiService: FrontendCommonMacroApiService,
    private authService: AuthService
  ) {}

  @Input() public commandCd: string = ''
  @Input() public options: any = {}

  baseSettings: ActionTransactionSettings | undefined
  @HostListener('click', ['$event.target']) async onClick(target: any) {
    if (!this.commandCd) return
    if (this.actionTransactionService.getActionTransactionSetting(this.commandCd) === false) {
      return
    }
    try {
      await this.createTransaction()
    } catch (error) {}
  }

  //アクショントランザクション登録
  private async createTransaction() {
    let payload = this.createPayload()
    await this.frontendCommonMacroApiService.postActionTransactions(this.commandCd, payload)
  }
  //RequestBody作成
  private createPayload() {
    const accountData: any = JSON.parse(JSON.stringify(this.authService.AccountInfo)) //本当は型を変更したい（AccountにorganizationIdやcustomerIdを追加）、影響が分からないので、anyに置き換え
    //選択中組織
    let index = accountData.organizations.findIndex(
      (x) => x.organizationCd === this.authService.AccountInfo.selectedOrganizationCd
    )
    const selectedOrg: any = JSON.parse(JSON.stringify(accountData.organizations[index]))
    //会社組織
    index = accountData.organizations.findIndex((x) => x.organizationTypeCd === 'organization_type-company')
    const companyOrg: any = index === -1 ? undefined : JSON.parse(JSON.stringify(accountData.organizations[index]))

    let ret: ActionTransactionData = {
      name: companyOrg?.organizationName || '',
      viewCd: this.getViewCd(),
      commandCd: this.commandCd,
      commandName: this.actionTransactionService.getCommandName(this.commandCd),
      companyOrganizationId: companyOrg?.organizationId || '00000000-0000-0000-0000-000000000000',
      companyOrganizationName: companyOrg?.organizationName || '',
      organizationId: selectedOrg?.organizationId || '00000000-0000-0000-0000-000000000000',
      organizationName: selectedOrg?.organizationName || '',
      customerId: accountData.customerId || '00000000-0000-0000-0000-000000000000',
      customerName: accountData.familyName + ' ' + accountData.firstName,
      options: this.options
    }
    return ret
  }

  //CommandCdからViewCd抜き出す
  private getViewCd(): string {
    const firstIndex = this.commandCd.indexOf('-')

    if (firstIndex === -1) {
      return this.commandCd // ハイフンが無い場合はそのままの文字列を返す
    } else {
      return this.commandCd.substring(0, firstIndex) // 最初のハイフンまでの文字列を返す
    }
  }
}
