import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'

@Component({
  selector: 'app-create-company-customer',
  templateUrl: './create-company-customer.component.html'
})
export class CreateCompanyCustomerComponent implements OnInit {
  title = '所属メンバーの新規登録'
  organizationCd: string

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.organizationCd = params.get('companyOrganizationCd')
    })
  }
}
