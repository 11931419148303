<div class="back">
  <div class="core">
    <igx-circular-bar
      [animate]="false"
      [indeterminate]="true"
      [textVisibility]="false"
      class="custom-size"
    ></igx-circular-bar>
    <div>処理中です<br />しばらくお待ち下さい</div>
  </div>
</div>
