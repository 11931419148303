export interface Header {
  key: string
  label: string
  sort?: boolean // 廃止する
  notSort?: boolean // ソートをデフォルトとする
  hidden?: boolean
  summary?: boolean
  dataType?: DataType
  width?: string
  style?: CellStyle
  groupLabel?: string
}
export interface PrivateHeader extends Header {
  group?: PrivateHeader[]
}

export interface CellStyle {
  textAlign?: TextAlign
}

type TextAlign = 'rigth' | 'left' | 'center'

export enum TextAlignValue {
  rigth = 'rigth',
  left = 'left',
  center = 'center',
}

export enum RowSelection {
  SHINGLE = 'single',
  MULTIPLE = 'multiple',
  NONE = 'none'
}

type DataType = 'number' | 'string' | 'check' | 'checkBox'

export interface SelectedRow<T> {
  add: T[],
  delete: T[],
  selected: T[]
}

export interface UpdateCheckBox<T> {
  field: string
  value: boolean
  rowData: T
}
