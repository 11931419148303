<app-main-contents>
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>

  <app-select
    [options]="periods"
    [(ngModel)]="period"
    (change)="getList(period)"
  ></app-select>

  <app-action-grid
    [data]="data"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    minusHeight="30"
    [actionButtons]="actionButtons"
    [cellClasses]="cellClasses"
    (actionCommit)="actionCommit($event)"
    [exportExcelButton]="true"
  ></app-action-grid>
</app-main-contents>

<app-dialog
  *ngIf="dialogOpen"
  [title]="'銀行API処理履歴'"
  widthSize="large"
  [hideCommitButton]="true"
  [hideCancelButton]="true"
  (cancel)="dialogOpen = false"
>
  <div *ngIf="!history.length && !loading">履歴はありません</div>
  <div *ngFor="let item of history" class="flex">
    <div>{{ item.updatedDatetimeUi }}</div>
    <div><a style="cursor: pointer"
      (click)="historyDetail = item.content; detailDialogOpen = true"
      >
      [{{ item.actionName }}]{{ item.resultCd }}</a
      >
    </div>
    <div>{{ item.resultMessage }}</div>
  </div>
</app-dialog>

<app-dialog
  class="detail-dialog"
  *ngIf="detailDialogOpen"
  [hideCommitButton]="true"
  [hideCancelButton]="true"
  (cancel)="detailDialogOpen = false"
>
  <pre>{{ historyDetail | json }}</pre>
</app-dialog>
