// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isbeLoginBaseUrl: '//backend-login-ui-v3.backend.out.nakamenosakura.com/',
  isbeLoginScript: 'wwwroot/js/be-login-core.js',
  isbeSelectOrganizationScript: 'wwwroot/js/be-select-organization-core.js',
  isbeSpoofingScript: 'wwwroot/js/spoofing-core.js',
  backendMacroBaseUrl: '//backend-macroapi.backend.out.nakamenosakura.com/',
  //local
  // backendMacroBaseUrl: '//localhost:5001/',
  myideaMacroBaseUrl: '//myidea-macroapi.backend.out.nakamenosakura.com/',
  invoiceLeafBaseUrl: '//myidea-invoiceapi.backend.out.nakamenosakura.com/',
  jobControllerBaseUrl: '//indexjobcontrollerapi.isbe.out.nakamenosakura.com/',
  riyoukiyakuUrl: '//idea-place.jp/wordpress/wp-content/themes/idea-place/assets/pdf/term.pdf',
  uiLinkPath: {
    organization: '//organization-ui.backend.out.nakamenosakura.com',
    devTools: '//dev-tools-ui.backend.out.nakamenosakura.com'
  },
  nameCardBaseUrl: '//backend-namecardleafapi.backend.out.nakamenosakura.com/',
  petaboBaseUrl: '//backend-petaboapi.backend.out.nakamenosakura.com/',
  indexCoreMyideaUrl: '//indexcore-myidea.isbe.out.nakamenosakura.com',
  frontendCommonMacroUrl: '//frontendmacroapi.isbe.out.nakamenosakura.com'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
