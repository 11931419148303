import { Component, OnInit, ViewChild } from '@angular/core'
import { InvoiceMigrationContractUi } from 'src/api/invoice-migration-api/invoice-migration-api.interface'
import { InvoiceMigrationApiService } from 'src/api/invoice-migration-api/invoice-migration-api.service'
import { MessageService } from 'src/app/components/message/message.service'
import { GridActionButton, ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'

@Component({
  selector: 'app-ura-invoice-pj-status-list',
  templateUrl: './ura-invoice-pj-status-list.component.html'
})
export class UraInvoicePjStatusListComponent implements OnInit {
  title = 'インボイス対応：ステータス一覧'
  data: InvoiceMigrationContractUi[] = []
  columns: Header[] = [
    {
      key: 'id',
      label: 'ID',
      hidden: true
    },
    {
      key: 'retailName',
      label: '小売',
      width: '8%'
    },
    {
      key: 'businessCategoryName',
      label: '事業',
      width: '8%'
    },
    {
      key: 'merchandiseName',
      label: '契約商品',
      width: '25%'
    },
    {
      key: 'supplierCd',
      label: 'SPCD',
      width: '7%'
    },
    {
      key: 'contractorCompanyOrganizationName',
      label: '会社',
      groupLabel: '契約者'
    },
    {
      key: 'contractorCustomerName',
      label: '名前',
      groupLabel: '契約者',
      width: '8%'
    },
    {
      key: 'contractorAccount',
      label: 'アカウント',
      groupLabel: '契約者(hide)',
      hidden: true
    },
    {
      key: 'contractorAccountUi',
      label: 'アカウント',
      groupLabel: '契約者',
      width: '8%'
    },
    {
      key: 'contractorMailAddress',
      label: 'メールアドレス',
      groupLabel: '契約者',
      width: '18%'
    },
    {
      key: 'statusUi',
      label: '状態',
      groupLabel: '契約者',
      width: '7%'
    },
    {
      key: 'approvalDateUi',
      label: '請求先確認日',
      groupLabel: '契約者',
      width: '10%'
    },
    {
      key: 'invoiceSuppliCustomerId',
      label: '楽楽明細ID',
      groupLabel: '請求先',
      width: '12%'
    },
    {
      key: 'payerCompanyOrganizationName',
      label: '会社',
      groupLabel: '請求先'
    },
    {
      key: 'payerCustomerName',
      label: '名前',
      groupLabel: '請求先',
      width: '8%'
    },
    {
      key: 'payerAccount',
      label: 'アカウント(hide)',
      groupLabel: '請求先',
      hidden: true
    },
    {
      key: 'payerAccountUi',
      label: 'アカウント',
      groupLabel: '請求先',
      width: '8%'
    },
    {
      key: 'payerMailAddress',
      label: 'メールアドレス',
      groupLabel: '請求先',
      width: '18%'
    },
    {
      key: 'confirmStatusUi',
      label: '状態',
      groupLabel: '請求先',
      width: '7%'
    },
    {
      key: 'payerConfirmDateUi',
      label: '確認日',
      groupLabel: '請求先',
      width: '8%'
    }
  ]
  rowSelection = RowSelection.MULTIPLE
  actionButtons: GridActionButton[] = [
    // {
    //   label: '契約担当者にメールを送信',
    //   actionCd: 'sendMail',
    //   confirmDialog: true
    // },
    {
      label: '請求先を参照',
      actionCd: 'viewDitail',
    }
  ]
  loading = true
  sending = false
  cellClasses = {
    color_green: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'contractorAccountUi' && rowData.contractorAccount)
        || (columnKey === 'payerAccountUi' && rowData.payerAccount)
        || (columnKey === 'statusUi' && rowData.approvalDatetime)
        || (columnKey === 'confirmStatusUi' && rowData.payerConfirmDatetime)
    },
    color_red: (rowData: any, columnKey: string): boolean => {
      return (columnKey === 'contractorAccountUi' && !rowData.contractorAccount)
        || (columnKey === 'payerAccountUi' && !rowData.payerAccount)
        || (columnKey === 'statusUi' && (!rowData.approvalDatetime))
        || (columnKey === 'confirmStatusUi' && (!rowData.payerConfirmDatetime))
    },
  }
  dialogOpen = false
  invoiceTargetCd = ''
  constructor(
    private messageService: MessageService,
    private invoiceMigrationApiService: InvoiceMigrationApiService
  ) { }

  ngOnInit(): void {
    this.getContractCompanyList()
  }
  async getContractCompanyList(): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.invoiceMigrationApiService.getContractStatus()
    this.loading = false
    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.data.forEach(item => {
      item.contractorAccountUi = item.contractorAccount ? '1) 正式' : '0) 仮'
      item.payerAccountUi = item.payerAccount ? '1) 正式' : '0) 仮'
      item.actionCds = ['sendMail', 'viewDitail']
      if (item.approvalDatetime) {
        item.statusUi = '1) 確認済'
      } else {
        item.statusUi = '0) 未確認'
      }
      if (item.payerConfirmDatetime) {
        item.confirmStatusUi = '1) 確認済'
      } else {
        item.confirmStatusUi = '0) 未確認'
      }
    })
    this.data = this.data.filter(x => x.contractPrice !== 0) // 0円商品除外
  }
  async send(contractCds: string[]): Promise<void> {
    this.sending = true
    const ret = await this.invoiceMigrationApiService.sendMailforContractor(contractCds)
    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.success('契約担当者宛てにメールを送信しました')
  }

  actionCommit(event: ActionTargetInfo): void {
    if (this.sending) { return }
    if (event.actionCd === 'sendMail') {
      const contractCds = []
      event.rows.forEach(row => {
        contractCds.push(row.contractCd)
      })
      this.send(contractCds)
    }
    if (event.actionCd === 'viewDitail') {
      this.dialogOpen = true
      this.invoiceTargetCd = event.rows[0].invoiceTargetCd
    }
  }
}
