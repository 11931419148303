<div class="l-main__contents js-fit-contents">
  <h1 class="c-texts--head1">{{ title }}</h1>
  <p class="c-texts--attention h-margin--b16">＊は入力必須項目です</p>
  <div class="l-table">
    <div class="l-table__item">
      <div class="l-table__head">
        <p>契約担当会社<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <app-input-search-company
          (changeSelect)="updateTargetCompany($event)"
        ></app-input-search-company>
      </div>
    </div>
  </div>
  <div class="l-table">
    <div class="l-table__item">
      <div class="l-table__head">
        <p>契約担当者<span class="c-label--required">*</span></p>
      </div>
      <div class="l-table__body">
        <div class="c-form__selectbox" style="width: 463px">
          <select class="c-form__input" [(ngModel)]="selectedCustomerIndex">
            <option [selected]="true" value="">選択してください</option>
            <option
              *ngFor="let list of customerList; let i = index"
              [value]="i"
            >
              {{ list.organizationFullName }} {{ list.familyName
              }}{{ list.firstName }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <app-under-btn-disp [actionButtons]="actionButtonsDisp"></app-under-btn-disp>
</div>
