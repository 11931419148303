<app-main-contents>
  <h1 class="c-texts--head1">{{ title }}</h1>
  <app-service-map-main
    [loading]="loading"
    [serviceMap]="serviceMap"
    (clickIcon)="clickIconEvent($event)"
  >
  </app-service-map-main>
  <app-under-btn-disp
    [actionButtons]="actionButtonsDisp"
  ></app-under-btn-disp>
</app-main-contents>
