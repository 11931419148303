<form *ngIf="myControl" [(formGroup)]="myControl">
  <div class="l-table">
    <ng-content select="before-rows"></ng-content>
    <div *ngFor="let item of convertFormItems" class="l-table__item">
      <div class="l-table__head">
        <p>
          {{ item.label }}
          <span *ngIf="required(item)" class="c-label--required">*</span>
        </p>
      </div>
      <div class="l-table__body">
        <ng-container *ngIf="!item.convertGroup?.length" class="l-table__body">
          <ng-container *ngIf="!item.readonly">
            <ng-container
              *ngTemplateOutlet="
                inputTemplate;
                context: {
                  item: item,
                  child: false
                }
              "
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="item.readonly">
            {{ formValue[item.key] }}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="item.convertGroup?.length">
          <div *ngFor="let groupItem of item.convertGroup" class="l-table__names">
            <div *ngFor="let groupDetail of groupItem" class="l-table__name l-table__name-custom">
              <label [style.width]="groupDetailLabelWidth(groupDetail, item)">{{ groupDetail.label }}</label>
              <ng-container *ngIf="!groupDetail.readonly">
                <ng-container
                  *ngTemplateOutlet="
                    inputTemplate;
                    context: {
                      item: groupDetail,
                      child: true
                    }
                  "
                ></ng-container>
              </ng-container>
              <ng-container *ngIf="groupDetail.readonly">
                {{ formValue[groupDetail.key] }}
              </ng-container>
            </div>
          </div>
          <span
            *ngIf="formGroupError(item.convertGroup)"
            class="c-form__error"
            >{{ formGroupErrorMessage(item.convertGroup) }}</span
          >
        </ng-container>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <ng-template #inputTemplate let-item="item" let-child="child">
    <div
      class="width-100"
      [class.c-form__password]="item.secret"
      [class.is-password-show]="item.secret && item.type === 'text'"
    >
      <input
        *ngIf="item.type !== 'select'"
        class="c-form__input width-100"
        autocomplete="new-password"
        [type]="item.type ? item.type : 'text'"
        [placeholder]="item.placeholder || ''"
        [required]="item.valid?.required"
        [maxlength]="item.valid?.maxLength"
        [value]="item.key"
        [formControlName]="item.key"
        [class.is-error]="formError(item.key)"
        (keyup)="keyUp(item)"
        (change)="change(item)"
        (input)="updateKana($event, item.key)"
      />
      <p
        class="c-form__showpass"
        (click)="
          item.type === 'password'
            ? (item.type = 'text')
            : (item.type = 'password')
        "
      ></p>
    </div>
    <p *ngIf="!child && item.caption" class="c-texts--caption">
      {{ item.caption }}
    </p>
    <div *ngIf="item.type === 'select'" class="c-form__selectbox c-form__small">
      <select
        class="c-form__select"
        [required]="item.valid?.required"
        [value]="item.key"
        [formControlName]="item.key"
        [class.is-error]="formError(item.key)"
        (change)="change(item)"
      >
        <option
          *ngIf="!item.valid?.required"
          value=""
          disabled="disabled"
          selected="selected"
        >
          選択してください
        </option>
        <option *ngFor="let master of item.master" [value]="master.value">
          {{ master.label }}
        </option>
      </select>
    </div>
    <span *ngIf="!child && formError(item.key)" class="c-form__error">{{
      formErrorMessage(item.key)
    }}</span>
  </ng-template>
</form>
