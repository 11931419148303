import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { Customer } from 'src/api/customers-api/customers-api.interface'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { InputTableComponent } from '../../common-parts/input-table/input-table.component'
import { ActionButtonDisp, FooterButtonTextAlign } from '../../common-parts/under-btn-disp/under-btn-disp.interface'
import { customerNameFormItem, mailAddressFormItem } from '../customer.const'

@Component({
  selector: 'app-create-customer-form',
  templateUrl: './create-customer-form.component.html'
})
export class CreateCustomerFormComponent implements OnInit {
  constructor(
    private customerService: CustomerService,
    private messageService: MessageService
  ) { }
  @Input() organizationCd: string
  @Input() formValue: Customer
  @Input() hideBackButton = true
  @Input() regist = true
  @Output() commit = new EventEmitter<Customer>()
  @Output() back = new EventEmitter<Customer>()
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  sending = false

  formItems = [customerNameFormItem, mailAddressFormItem]
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '登録する',
      actionFunction: () => {
        this.submit()
      }
    }
  ]

  ngOnInit(): void {
    if (!this.hideBackButton) {
      this.actionButtonsDisp.push({
        label: '戻る',
        textAlign: FooterButtonTextAlign.LEFT,
        actionFunction: () => {
          this.back.emit(this.inputTable.getValue())
        }
      })
    }
    if (!this.formValue) {
      this.formValue = {
        firstName: '',
        firstNameKana: '',
        familyName: '',
        familyNameKana: '',
        mailAddress: ''
      }
    }
  }

  async submit(): Promise<void> {
    if (this.sending) { return }
    const submitForm = this.inputTable.submit()
    if (!submitForm) {
      return
    }
    // メールアドレス重複チェック ※2024/06/04 メールアドレスのチェックを外す
    // const check = await this.customerService.SearchByMailAddress(submitForm.mailAddress)
    // if (check?.data.length > 0) {
    //   this.messageService.templeteMessage(messageList.M00049)
    //   return
    // }

    if (!this.regist) {
      this.commit.emit(submitForm)
      return
    }
    // 登録
    this.sending = true
    const ret = await this.customerService.Create([submitForm], this.organizationCd)
    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00057)
    this.inputTable.setForm({})
    this.commit.emit(ret.data[0])
  }
}
