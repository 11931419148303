import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ConfirmService } from '../../common-parts/confirm/confirm.service'
import { ActionButtonDisp } from '../../common-parts/under-btn-disp/under-btn-disp.interface'
import { Header } from '../base-grid/base-grid.component.interface'
import { GridActionButton } from './action-grid.component.interface'
@Injectable({
  providedIn: 'root',
})
export class GridBaseService {
  constructor(
    private router: Router,
    private confirmService: ConfirmService
  ) { }

  // ボタンdisable セット
  public getActionButtonsDisp(selectedRows: any[], buttons: GridActionButton[]): ActionButtonDisp[] {
    const buttonsDisp: ActionButtonDisp[] = []
    buttons.forEach(button => {
      const disp: ActionButtonDisp = {
        label: button.label,
        actionCd: button.actionCd,
        disabled: this.actionButtonsDisabled(button.actionCd, buttons, selectedRows)
      }
      buttonsDisp.push(disp)
    })
    return buttonsDisp
  }

  // ボタン判定
  public actionButtonsDisabled(actionCd: string, buttons: GridActionButton[], selectedRows: any[]): boolean {
    const btnSetting = buttons.find(x => x.actionCd === actionCd)
    if (btnSetting?.disabled) {
      return true
    }
    if (btnSetting?.notRowAction) {
      return false // gridと関係ないボタンは押せる
    }
    if (selectedRows.length > 1 && btnSetting.singleSelect) {
      return true
    }
    if (!selectedRows.length) {
      return true
    }
    let disabled = false // 押せる
    // 選択した行の中に一致しているものがあれば押せる
    selectedRows.forEach(row => {
      if (!row.actionCds?.find(x => x === actionCd)) {
        disabled = true  // 押せない
      }
    })
    return disabled
  }

  // アクションボタン押下
  actionClick(actionCd: string, actionButtons: GridActionButton[], actionTargetInfos?: any[],  columns?: Header[]): void {
    const action = actionButtons.find(x => x.actionCd === actionCd)

    // ページ遷移
    if (action.nextPage) {
      this.nextPage(action, columns, actionTargetInfos)
      return
    }

    // 確認モーダル
    if (action.confirmDialog && action.actionFunction) {
      this.confirmService.confirm(`本当に${action.label}しますか？`, '', action.actionFunction)
      return
    }

    // アクション実行
    if (action?.actionFunction) {
      action.actionFunction()
    }
  }

  // ページ遷移
  nextPage(action: GridActionButton, columns?: Header[], actionTargetInfo?: any[]): void {
    let path = action.nextPage[0]
    if (actionTargetInfo) {
      // Pathに{}で括ってある動的な項目があれば置換
      columns.forEach(column => {
        path = path.replace(`{${column.key}}`, actionTargetInfo[0][column.key])
      })
    }
    this.router.navigate([path])
  }
}
