import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title: string
  @Input() message: string
  @Input() hideCommitButton: boolean
  @Input() commitButtonText = 'OK'
  @Input() hideCancelButton = false
  @Input() cancelButtonText = 'キャンセル'
  @Input() widthSize = 'small'
  @Input() hideCloseButton = false
  // 要素の外側をクリックしても、アクティブなままにする
  @Input() persistent = false
  @Output() commit = new EventEmitter<void>()
  @Output() cancel = new EventEmitter<void>()

  constructor() { }

  ngOnInit(): void { }

  onSubmit(): void {
    this.commit.emit()
  }

  onCancel(): void {
    this.cancel.emit()
  }
}
