import { Injectable } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResultInfo } from '../common/api-common.interface'
import { HttpRequestService } from '../common/http-request.service'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class FrontendCommonMacroApiService {
  baseURL = environment.frontendCommonMacroUrl

  constructor(private httpRequestService: HttpRequestService, private httpClient: HttpClient) {}

  //システムエラーを出力する場合はこちら
  // async postActionTransactions(commandCd: string, payload: any): Promise<ResultInfo<any>> {
  //   const url = `${this.baseURL}/api/commands/${commandCd}/transactions`
  //   const ret$ = this.httpRequestService.post(url, payload)
  //   return await lastValueFrom(ret$)
  // }
  async postActionTransactions(commandCd: string, payload: any): Promise<ResultInfo<any>> {
    const url = `${this.baseURL}/api/commands/${commandCd}/transactions`
    const ret$ = this.httpClient.post<ResultInfo<any>>(url, payload)
    return await lastValueFrom(ret$)
  }
}
