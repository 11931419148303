import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class CashService {
  data: any = {}
  constructor() { }

  GetData<T>(key: string): T {
    if (this.data[key] === null) {
      const storage = localStorage.getItem(key)
      if (storage) {
        this.data[key] = JSON.parse(storage)
      }
    }
    return this.data[key]
  }

  SetData(key: string, value: any): void {
    this.data[key] = value
    localStorage.setItem(key, JSON.stringify(value))
  }
}
