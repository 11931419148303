import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { RegisterStatus } from 'src/api/organization-company-api/organization-company-api.interface'
import { OrganizationCompanyApiService } from 'src/api/organization-company-api/organization-company-api.service'
import { AutoMap } from 'src/app/common/common'
import { ActionTargetInfo, GridActionButton } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { InputOrganization } from 'src/app/module/myidea-parts/feature-organization.interface'
import { FeatureOrganizationService } from 'src/app/module/myidea-parts/feature-organization.service'
@Component({
  selector: 'app-ura-company-check-list',
  templateUrl: './ura-company-check-list.component.html',
  styleUrls: ['./ura-company-check-list.component.scss']
})
export class UraCompanyCheckListComponent implements OnInit {
  title = '企業法人紐づけ'
  data: RegisterStatus[] = []
  columns: Header[] = [
    {
      key: 'organizationCd',
      label: 'CD',
      hidden: true
    },
    {
      key: 'organizationName',
      label: '契約企業',
    },
    {
      key: 'registCorporateNumberStatusName',
      label: '法人番号紐づけ',
    },
    {
      key: 'registKanaStatusName',
      label: 'かな登録',
    },
  ]
  rowSelection = RowSelection.SHINGLE
  actionButtons: GridActionButton[] = [
    {
      label: '確認',
      actionCd: 'actionCd1',
    },
  ]
  loading = true
  cellClasses = {
    color_red: (rowData: RegisterStatus, columnKey: string): boolean => {
      if (!rowData[columnKey]) {
        return false
      }
      if (columnKey === 'registCorporateNumberStatusName' && rowData.registCorporateNumberStatusCd === '0') { return true }
      if (columnKey === 'registKanaStatusName' && rowData.registKanaStatusCd === '0') { return true }
      return false
    },
  }

  constructor(
    private featureOrganizationService: FeatureOrganizationService,
    private organizationCompanyApiService: OrganizationCompanyApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getContractCompanyList()
    this.featureOrganizationService.clearValues()
  }
  async getContractCompanyList(): Promise<void> {
    this.data = []
    this.loading = true
    const ret = await this.organizationCompanyApiService.GetRegisterStatus()
    if (ret?.resultCode !== 0) { return }
    this.data = ret.data
    this.data.forEach(item => {
      item.actionCds = ['actionCd1']
    })
    this.loading = false
  }
  actionCommit(event: ActionTargetInfo): void {
    const action = this.actionButtons.find(x => x.actionCd === event.actionCd)
    if (action.actionCd === 'actionCd1') {
      const data = event.rows[0]
      this.featureOrganizationService.clearValues()
      if (data.registCorporateNumberStatusCd === '1') {
        this.router.navigate([`/ura-edit-company-profile/${data.organizationCd}`])
      } else {
        this.featureOrganizationService.setInputValues(AutoMap<InputOrganization>(data))
        this.router.navigate([`/ura-search-relation-corporate/${data.organizationCd}`])
      }
    }
  }
}
