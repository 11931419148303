import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { CustomerUi } from 'src/api/customers-api/customers-api.interface'
import { CustomerService } from 'src/api/customers-api/customers-api.service'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { messageList } from 'src/app/components/message/message-data'
import { MessageService } from 'src/app/components/message/message.service'
import { InputTableComponent } from '../../common-parts/input-table/input-table.component'
import { ActionButtonDisp } from '../../common-parts/under-btn-disp/under-btn-disp.interface'
import { customerCdFormItem, customerNameFormItem, mailAddressFormItem } from '../customer.const'
import { Organization } from 'src/app/components/isbeauth/account'
import { JoinText } from 'src/app/common/common'

@Component({
  selector: 'app-update-customer-form',
  templateUrl: './update-customer-form.component.html',
  styleUrls: ['./update-customer-form.component.scss']
})
export class UpdateCustomerFormComponent implements OnInit {
  constructor(
    private customerService: CustomerService,
    private authService: AuthService,
    private messageService: MessageService,
  ) { }
  @Input() customerCd: string
  @ViewChild(InputTableComponent, { static: false })
  private inputTable: InputTableComponent
  sevedData: CustomerUi
  sending = false
  formItems = [customerCdFormItem, customerNameFormItem, mailAddressFormItem]
  formValue: CustomerUi = null
  actionButtonsDisp: ActionButtonDisp[] = [
    {
      label: '更新する',
      actionFunction: () => {
        this.submit()
      }
    }
  ]
  compaies: Organization[] = this.authService.AccountInfo?.organizations ?? []

  ngOnInit(): void {
    if (!this.customerCd) { return }
    this.setFormDefaultValue()
  }

  get organizationNames(): string {
    let text = ""
    let first = true
    this.compaies.forEach(company => {
      if (first) {
        first = false
        text += company.organizationName
      }
      else {
        text += `、 ${company.organizationName}`
      }
    });
    return text
  }

  async setFormDefaultValue(): Promise<void> {
    const ret = await this.customerService.SearchByCd(this.customerCd)
    if (ret?.resultCode !== 0) { return }
    const data = ret.data
    this.sevedData = data
    this.formValue = data
    this.inputTable.setForm(this.formValue)
  }

  async submit(): Promise<void> {
    if (this.sending) { return }
    if (!this.customerCd) { return }
    const submitForm = this.inputTable.submit()
    if (!submitForm) {
      return
    }
    // メールアドレス重複チェック
    const check = await this.customerService.SearchByMailAddress(submitForm.mailAddress)
    if (check?.resultCode !== 0) {
      this.sending = false
      return
    }
    if (check?.data.length > 0 && !check.data.find(x => x.customerCd === this.customerCd)) {
      this.messageService.templeteMessage(messageList.M00049)
      return
    }
    const customerInfo: CustomerUi =
    {
      customerCd: this.sevedData.customerCd,
      familyName: submitForm.familyName,
      firstName: submitForm.firstName,
      firstNameKana: submitForm.firstNameKana,
      familyNameKana: submitForm.familyNameKana,
      mailAddress: submitForm.mailAddress
    }

    this.sending = true
    const ret = await this.customerService.Update(customerInfo)
    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.messageService.templeteMessage(messageList.M00050)
    const customer = ret.data
    const updateAccountInfo = this.authService.AccountInfo
    updateAccountInfo.firstName = customer.firstName
    updateAccountInfo.familyName = customer.familyName
    this.authService.setAccountInfo(updateAccountInfo)

  }
}

