import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { ServiceMapSubInfo, UpdateMemoRequest } from 'src/api/frimo-migrations-api/frimo-migrations-api.model'
import { ServiceMapApiService } from 'src/api/service-map-api/service-map-api.service'
import { MessageService } from 'src/app/components/message/message.service'

@Component({
  selector: 'app-service-map-sub-info-dialog',
  templateUrl: './service-map-sub-info-dialog.component.html',
  styleUrls: ['./service-map-sub-info-dialog.component.scss']
})
export class ServiceMapSubInfoDialogComponent implements OnInit {
  @Input() service: ServiceMapSubInfo
  @Output() updateSubInfo = new EventEmitter<ServiceMapSubInfo>()
  sending = false
  editMemoMode = false
  memoInput = ''

  constructor(
    private messageService: MessageService,
    private serviceMapApiService: ServiceMapApiService
  ) { }

  ngOnInit(): void {
  }

  editMemo(): void {
    this.memoInput = this.service.actuateName
    this.editMemoMode = true
  }

  async updateMemo(): Promise<void> {
    if (this.sending) { return }
    this.sending = true
    const req: UpdateMemoRequest = {
      actuateCd: this.service.actuateCd,
      actuateName: this.memoInput
    }
    // const ret = await this.serviceMapApiService.UpdateMemo(req).toPromise()
    const ret$ = this.serviceMapApiService.UpdateMemo(req)
    let ret = await lastValueFrom(ret$)

    this.sending = false
    if (ret?.resultCode !== 0) { return }
    this.service.actuateName = this.memoInput
    this.messageService.success('メモを更新しました')
    this.updateSubInfo.emit(this.service)
    this.editMemoMode = false
  }
}
