import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Tag } from 'src/api/common/api-common.interface'

@Component({
  selector: 'app-quotation-selecter',
  templateUrl: './quotation-selecter.component.html',
  styleUrls: ['./quotation-selecter.component.scss']
})
export class QuotationSelecterComponent implements OnInit {
  @Input() tags: Tag[] = []
  @Input() tagCd: string
  @Output() changeCd = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {}
}
