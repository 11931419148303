import { Injectable } from '@angular/core'
import { AccountInfo } from './account'
import { Subject } from 'rxjs'
import { Router } from '@angular/router'
import { headerType } from '../header/header-division'
import { AuthView, AuthorityTable } from 'src/api/authorities-api/authorities-api.interface'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { AuthoritiesApiService } from 'src/api/authorities-api/authorities-api.service'
import { CompanyOrganization } from 'src/api/organization-company-api/organization-company-api.interface'

declare const window

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private autoLoginAccountId: string // 自動ログインさせる際に利用
  private accountInfo: AccountInfo
  private selectedChangeCustomer: CompanyOrganization
  private authorityTable: AuthorityTable[]
  constructor(
    private router: Router,
    private authoritiesApiService: AuthoritiesApiService,
  ) { }

  private accountInfoSource = new Subject<AccountInfo>()
  public accountInfoSource$ = this.accountInfoSource.asObservable()

  private authorityTableSource = new Subject<AuthorityTable[]>()
  public authorityTableSource$ = this.authorityTableSource.asObservable()

  private spoofingRequestSource = new Subject<string>()
  public spoofingRequestSource$ = this.spoofingRequestSource.asObservable()

  private authViewSource = new Subject<AuthView[]>()
  public authViewSource$ = this.authViewSource.asObservable()

  public get AutoLoginAccountId(): string {
    return this.autoLoginAccountId
  }

  public get AccountInfo(): AccountInfo {
    return this.accountInfo
  }

  // 権限
  public get AuthorityTable(): AuthorityTable[] {
    return this.authorityTable
  }

  public get ChangeCustomer(): CompanyOrganization {
    if (this.selectedChangeCustomer) { return this.selectedChangeCustomer }
    const strage = sessionStorage.getItem('ura-target-organization')
    if (strage) { return JSON.parse(strage) }
    return null
  }


  // ページの参照権限を判断し、権限が無ければTOPに飛ばす
  authGuard(routeData: any): void {
    // Ura-ideaの場合
    if (routeData.headerType === headerType.uraidea) {
      // インターサーブアカウント以外を弾く
      const org = this.AccountInfo?.organizations?.find(x => x.organizationCd === '1')
      if (!org) {
        this.closingPage()
      }
    }
  }

  // ページ全体の参照権限が無かった場合のアクション
  public closingPage(): void {
    this.router.navigate(['/'])
  }

  // 自動ログインさせる際に設定
  public setAutoLoginAccountId(autoLoginAccountId: string): void {
    this.autoLoginAccountId = autoLoginAccountId
  }

  public setSelectedChangeCustomer(customer: CompanyOrganization): void {
    this.selectedChangeCustomer = customer
    sessionStorage.setItem('ura-target-organization', JSON.stringify(customer))
  }

  public resetAutoLoginAccountId(): void {
    this.autoLoginAccountId = null
  }

  // 管理者モードでなりすますRequest
  superAdminMode(spoofingTargetAccountId: string): void {
    this.spoofingRequestSource.next(spoofingTargetAccountId)
  }

  // 管理者モードでのなりすまし解除Request
  resetSuperAdminMode(): void {
    this.spoofingRequestSource.next(null)
  }

  logout(): void {
    window.BeLoginCore.logout()
    location.reload()
  }

  // アカウント情報設定 ログイン時
  public setAccountInit(accountInfo: AccountInfo): void {
    this.setAccountInfo(accountInfo)
    this.setAuthorityTable()
  }

  // アカウント情報のみ設定
  public setAccountInfo(accountInfo: AccountInfo): void {
    this.accountInfoSource.next(accountInfo)
    this.accountInfo = accountInfo
  }

  // 選択組織のみ更新
  public updateSelectedOrganizationCd(selectedOrganizationCd: string): void {
    this.accountInfo.selectedOrganizationCd = selectedOrganizationCd
    this.accountInfoSource.next(this.accountInfo)
  }

  public setAuthorityTable(): void {
    this.authoritiesApiService.GetMyAuthorityTable(
      this.accountInfo.customerCd
    ).subscribe((ret: ResultInfo<AuthorityTable>) => {
      if (ret?.resultCode === 0) {
        this.authorityTable = ret.data
        this.authorityTableSource.next(ret.data)
      }
    })
  }


  get AuthViews() {
    return this.authViews
  }

  private authViews: AuthView[] = null
  async setAuthViews(): Promise<void> {
    const authViewRunRet = await this.authoritiesApiService.RunGetAuthorityViews(this.accountInfo.customerCd, this.AccountInfo.selectedOrganizationCd)
    const authViewRet = await this.authoritiesApiService.GetTaskResultRecursive(authViewRunRet.data)
    this.authViews = JSON.parse(authViewRet ?? "[]")
    this.authViewSource.next(this.authViews)
  }

  /**
   * @param  string path
   * @returns boolean - 利用可能な場合true
   */
  checkAuthorityForView(path: string): boolean {
    const routes = this.router.config;
    const thisRoute = routes.find(x => `/${x.path}` === path)
    if (!thisRoute) {return false }
    const functionKey = thisRoute?.data?.functionKey
    if (!functionKey) {return true } // 制限なし画面

    if (!this.isLoadedAuth()) {
      return false
    }

    const auth = this.AuthViews.find(x => x.cellId === functionKey)
    if (auth) {
      return true
    } else {
      return false
    }
  }
  
  isLoadedAuth(): boolean {
    return this.AuthViews !== null
  }

  private reloadInfo: string = null

  get ReloadInfo(): string {
    return this.reloadInfo
  }

  setRelaodInfo(path: string): void {
    this.reloadInfo = path
  }
}
