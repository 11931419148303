<app-dialog
  *ngIf="dialogOpen"
  (cancel)="dialogOpen = false"
  [title]="title"
  [hideCommitButton]="true"
  widthSize="large"
>
  <app-input-table
    *ngIf="formValue"
    [formItems]="formItems"
    [formValue]="formValue"
  >
  </app-input-table>
</app-dialog>
