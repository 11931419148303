<div class="base">
  <!-- <div class="c-modal__close ng-star-inserted">
    <a style="cursor: pointer"><i class="c-icon-close"></i></a>
  </div> -->
  <div class="loading" *ngIf="!service">
    <app-loading></app-loading>
  </div>
  <div class="flex">
    <h3 class="c-texts--head2">
      {{ service?.merchandiseName }}
    </h3>
    <p class="c-texts--s" style="margin-left: 10px">
      {{ service?.productInquiryCd }}
    </p>
  </div>
  <div class="flex" *ngIf="service">
    <ul class="c-texts--table h-margin--t8">
      <!-- <li>
          <h4>契約日</h4>
          <p>{{ contractStartDatetime }}</p>
        </li> -->
      <ng-container *ngIf="!service?.notContractCompany">
        <li>
          <h4>契約会社</h4>
          <p>{{ service?.contractCompanyOrganizationName }}</p>
        </li>
        <li>
          <h4>契約者</h4>
          <p>{{ service?.purchaserName }}</p>
        </li>
      </ng-container>
      <li>
        <h4>利用開始日</h4>
        <p>{{ service?.useStartDatetimeUi }}</p>
      </li>
      <li>
        <h4>契約日</h4>
        <p>{{ service?.contractStartDatetimeUi }}</p>
      </li>
    </ul>
    <ng-container
      *ngIf="
        !service?.notContractCompany ||
        service?.productCd === 'shipment_pack_product'
      "
    >
      <ul class="c-texts--table h-margin--t8" style="margin-left: 10px">
        <ng-container *ngIf="service && !service?.notContractCompany">
          <li>
            <h4>請求先会社</h4>
            <p>{{ service?.invoiceCompanyOrganizationName }}</p>
          </li>
          <li>
            <h4>請求先部署</h4>
            <p>{{ service?.invoiceSectionOrganizationName }}</p>
          </li>
          <li>
            <h4>請求先顧客</h4>
            <p>{{ service?.invoiceCustomerName }}</p>
          </li>
          <li>
            <h4>楽楽明細ID</h4>
            <p>{{ service?.suppliBillingCustomerId }}</p>
          </li>
        </ng-container>
        <li
          [class.no-show]="
            !service?.canEditMemo
          "
          [class.memo]="editMemoMode"
          class="memo-area"
        >
          <h4>{{ service?.memoLabel }}</h4>
          <span [class.no-show]="editMemoMode" class="flex">
            <!-- <div class="ng-star-inserted">
              <a style="cursor: pointer" [class.no-show]="!service?.canEditMemo" (click)="editMemo()"><i class="c-icon-close"></i>設定</a>
            </div> -->
            <p class="disp">{{ service.actuateName }}</p>
            <div>
              <a
                [class.no-show]="!service?.canEditMemo"
                class="c-btn--xs c-btn--block edit-open"
                (click)="editMemo()"
                style="cursor: pointer"
                ><span>設定</span></a
              >
            </div>
          </span>
          <div [class.no-show]="!editMemoMode">
            <p>
              <input
                [(ngModel)]="memoInput"
                class="c-form__input"
                type="text"
                [placeholder]="service?.memoLabel"
              />
            </p>
            <span>{{ service?.memoExampleText }}</span>
            <div>
              <a
                class="c-btn--xs c-btn--block"
                (click)="updateMemo()"
                style="cursor: pointer"
                ><span>更新</span></a
              >
            </div>
          </div>
        </li>
      </ul>
    </ng-container>
  </div>
</div>
