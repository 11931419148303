import { Component, OnInit } from '@angular/core'
import { BackendOrganizationV2 } from 'src/api/organizationsV2-api/organizationV2-api.model'
import { OrganizationV2Service } from 'src/api/organizationsV2-api/organizationV2-api.service'
import { AuthService } from 'src/app/components/isbeauth/auth.service'
import { OrganizationCustomerUi } from './ura-super-admin.interface'
import { AutoMap, JoinText } from 'src/app/common/common'
import { MessageService } from 'src/app/components/message/message.service'
import { OrganizationAccountUi } from '../ura-support-search-account/ura-support-search-account.interface'
import { ConfirmService } from 'src/app/module/common-parts/confirm/confirm.service'
import { ActionTargetInfo } from 'src/app/module/is-grid/action-grid/action-grid.component.interface'
import { Header, RowSelection } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'app-ura-super-admin',
  templateUrl: './ura-super-admin.component.html',
  styleUrls: ['./ura-super-admin.component.scss']
})
export class UraSuperAdminComponent implements OnInit {
  title = '管理者モード (他アカウントでのログイン)'
  customers: OrganizationCustomerUi[] = []
  loading = false
  customerLoading = false
  customerColumns: Header[] = [
    {
      key: 'organizationFullName',
      label: '組織名'
    },
    {
      key: 'customerName',
      label: '名前',
      width: '12%'
    },
    {
      key: 'mailAddress',
      label: 'メールアドレス'
    },
    {
      key: 'nickName',
      label: 'ログインID'
    },
    {
      key: 'isFormalAccount',
      label: '正式登録済',
      dataType: 'check',
      width: '12%'
    },
  ]
  rowSelection = RowSelection.SHINGLE
  showConfirmDialog = false
  dialogMessage = ''
  selectedCustomer: OrganizationAccountUi

  constructor(
    private authService: AuthService,
    private confirmService: ConfirmService,
    private organizationV2Service: OrganizationV2Service,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  // 会社選択時処理
  updateTargetCompany(company: BackendOrganizationV2): void {
    this.getCustomerList(company.organizationCd)
  }

  // 顧客一覧検索
  async getCustomerList(organizationCd: string): Promise<void> {
    if (!organizationCd) { return }
    this.customerLoading = true
    this.customers = []
    // const ret = await this.organizationV2Service.GetCustomerAccountInfos(organizationCd, true).toPromise()
    const ret$ = this.organizationV2Service.GetCustomerAccountInfos(organizationCd, true)
    let ret = await lastValueFrom(ret$)

    this.customerLoading = false
    if (ret?.resultCode !== 0) { return }
    const customers: OrganizationAccountUi[] = []
    ret.data.forEach(i => {
      const row = AutoMap<OrganizationAccountUi>(i)
      row.customerName = JoinText([row.familyName, row.firstName], ' ')
      customers.push(row)
    })
    this.customers = customers
  }

  // 顧客情報検索
  async actionCommit(action: ActionTargetInfo): Promise<void> {
    const customer: OrganizationAccountUi = action.rows[0]
    this.selectedCustomer = customer
    const message = `${JoinText([customer.familyName, customer.firstName], ' ')}さんのアカウントにログインしますか？`
    this.confirmService.confirm(message, '', () => {
      this.loginForTargetUser()
    })
  }

  // 選択している対象顧客でなりすましログイン
  loginForTargetUser(): void {
    if (this.loading) { return }
    this.loading = true
    this.authService.superAdminMode(this.selectedCustomer.accountId)
  }
}
