<app-customer-name-disp
  (targetSelected)="selectTargetOrganization($event)"
></app-customer-name-disp>
<app-main-contents *ngIf="selectedTargetOrg?.organizationCd">
  <app-title [title]="title"></app-title>
  <app-sending *ngIf="sending"></app-sending>
  <app-action-grid
    [data]="invoceTargets"
    [loading]="loading"
    [rowSelection]="rowSelection"
    [columns]="columns"
    [actionButtons]="actionButtons"
    [height]="430"
    (actionCommit)="actionCommit($event)"
  ></app-action-grid>
</app-main-contents>

<app-dialog
  *ngIf="dialogOpen"
  title="請求先情報"
  widthSize="large"
  [commitButtonText]="
    !selectedInvoiceTarget.suppliBillingCustomerId ? '登録' : '更新'
  "
  (commit)="regist()"
  (cancel)="dialogOpen = false"
>
  <app-input-table #inputTable>
    <before-rows class="l-table__item">
      <div class="l-table__item">
        <div class="l-table__head">
          <p>顧客</p>
        </div>
        <div class="l-table__body">
          <div class="c-form__selectbox">
            <select [(ngModel)]="selectedInvoiceTarget.customerCd" class="c-form__select">
              <option selected value="">選択してください</option>
              <option
                *ngFor="let customer of customers"
                [value]="customer.customerCd"
              >
              {{ customer.organizationFullName }}
              {{ customer.familyName }}
              {{ customer.firstName }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </before-rows>

  </app-input-table>
  <div class="table-margin"></div>
  <p>これより以下は、請求書に載る内容を入力して下さい</p>
  <app-input-table>
    <div class="l-table__item">
      <div class="l-table__head">
        <p>楽楽明細ID</p>
      </div>
      <div
        class="l-table__body"
      >
        {{ selectedInvoiceTarget?.suppliBillingCustomerId || '自動採番されます' }}
      </div>
    </div>
  </app-input-table>
  <div class="table-margin"></div>
  <app-input-table #inputTable2 [formItems]="formItems2">
    <before-rows class="l-table__item">
      <div class="l-table__head">
        <p>会社名</p>
      </div>
      <div class="l-table__body">
        {{ dispOrganizationName }}
      </div>
    </before-rows>
  </app-input-table>
  <div class="table-margin"></div>
  <app-input-table #inputTable3 [formItems]="formItems3"> </app-input-table>
  <div class="table-margin"></div>
  <app-input-table #inputTable4 [formItems]="formItems4"> </app-input-table>
  <div class="table-margin"></div>
  <div class="l-table__item">
    <div class="l-table__head">
      <p>口座情報</p>
    </div>
    <div class="l-table__body">
      <select class="c-form__select" name="organizationMethod" [(ngModel)]="selectedPaymentMethodCd" id="">
        <option [value]="null" selected>未選択（紐づけ解除）</option>
        <option [value]="organizationPaymentMethod.organizationPaymentMethodCd" *ngFor="let organizationPaymentMethod of organizationPaymentMethods">
          {{ paymentMethodNameForDisplay(organizationPaymentMethod) }}
        </option>
      </select>
    </div>
  </div>

</app-dialog>
