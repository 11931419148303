import { Component, OnInit } from '@angular/core'
import { ResultInfo } from 'src/api/common/api-common.interface'
import { DateRange } from '@infragistics/igniteui-angular'
import { UraContractEntryListService } from '../ura-contract-entry-list/ura-contract-entry-list..service'
import moment from 'moment'
import { ContaApiService } from 'src/api/conta-api/conta-api.service'
import { UraSearchService } from '../components/ura-search.service'
import { SelectListItem } from '../../../module/myidea-parts/input-selectbox/input-selectbox.interface'
import { ContaSearchMacroRequest, ContractListItem, InputProductInquiryCd, ProductMasterUI } from 'src/api/conta-api/conta-api.interface'
import { MerchandisesApiService } from 'src/api/merchandises-api/merchandises-api.service'
import { Header, RowSelection, TextAlignValue } from 'src/app/module/is-grid/base-grid/base-grid.component.interface'
import { lastValueFrom } from 'rxjs'
@Component({
  selector: 'app-ura-contract-search-list',
  templateUrl: './ura-contract-search-list.component.html',
  styleUrls: ['./ura-contract-search-list.component.scss'],
})
export class UraContractSearchListComponent implements OnInit {
  title = '検索：契約一覧'
  data: ContractListItem[] = []
  columns: Header[] = [
    {
      key: 'contractCompanyCorporateNumber',
      label: '法人番号',
      width: '10%',
      groupLabel: '契約者'
    },
    {
      key: 'contractCompanyFomalName',
      label: '会社名',
      width: '12%',
      groupLabel: '契約者'
    },
    {
      key: 'contractSectionOrganizationName',
      label: '部署名',
      groupLabel: '契約者'
    },
    {
      key: 'invoiceCompanyCorporateNumber',
      label: '法人番号',
      width: '10%',
      groupLabel: '請求'
    },
    {
      key: 'invoiceCompanyFomalName',
      label: '会社名',
      width: '12%',
      groupLabel: '請求'
    },
    {
      key: 'contractSectionOrganizationName',
      label: '部署',
      groupLabel: '請求'
    },
    {
      key: 'actuateName',
      label: '利用拠点名(メモ)',
    },
    {
      key: 'serviceTagName',
      label: 'サービス',
      width: '8%'
    },
    {
      key: 'retailTagText',
      label: '小売',
      width: '7%'
    },
    {
      key: 'businessCategoryTagName',
      label: '事業',
      width: '7%'
    },
    {
      key: 'productInquiryCd',
      label: 'プロダクトコード',
      width: '18%'
    },
    {
      key: 'oldInquiryNo',
      label: '旧お問い合わせNo.',
      width: '8%'
    },
    {
      key: 'merchandiseName',
      label: '商品名',
      width: '20%'
    },
    {
      key: 'contractName',
      label: '契約名',
      width: '10%'
    },
    {
      key: 'contractPriceUi',
      label: '契約金額',
      style: {
        textAlign: TextAlignValue.rigth
      },
      width: '9%'
    },
    {
      key: 'supplierCd',
      label: 'SPCD',
      width: '6%'
    },
    {
      key: 'actuateStatusUi',
      label: '状態',
      width: '7%'
    },
    {
      key: 'applicationDatetimeUi',
      label: '設定開始日',
      width: '9%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'actuateDatetimeUi',
      label: '稼働日',
      width: '9%',
      style: {
        textAlign: 'center'
      }
    },
    {
      key: 'cancelDatetimeUi',
      label: '解約日',
      width: '9%',
      style: {
        textAlign: 'center'
      }
    },
  ]
  rowSelection = RowSelection.NONE
  loading = false

  serviceTagList: SelectListItem[] = []
  retailTagList: SelectListItem[] = []
  bussinesCategoryTagList: SelectListItem[] = []
  actuateStatusList: any[] = []
  actuateDataRange: DateRange
  entryDataRange: DateRange

  values: any = {
    serviceTagCd: null,
    retailTagCd: null,
    bussinesCategoryTagCd: null,
    actuateName: '',
    productCdForInquiry: '',
    productNumber: '',
    supplierCd: '',
    companyOrganizationCd: '',
    productCds: []
  }
  count = 0
  countLoading = false
  neverCountSearch = true
  lastTimeRequest: ContaSearchMacroRequest
  errorRequest = false
  productMasterListUI: ProductMasterUI[]

  constructor(
    private uraContractEntryListService: UraContractEntryListService,
    private uraSearchService: UraSearchService,
    private contaService: ContaApiService,
    private merchandisesService: MerchandisesApiService
  ) { }

  async ngOnInit(): Promise<void> {
    const req = this.setRequest()
    this.lastTimeRequest = req

    this.changeRetail(null)
    this.setTagList()
    this.productMasterListUI = await this.uraContractEntryListService.getProductList()
  }

  // タグ情報取得
  async setTagList(): Promise<void> {
    // const ret = await this.merchandisesService.GetMerchandisesTagMaster().toPromise()
    const ret$ = this.merchandisesService.GetMerchandisesTagMaster()
    let ret = await lastValueFrom(ret$)

    this.serviceTagList = this.uraSearchService.getServiceList(ret.data)
    this.retailTagList = this.uraSearchService.getRetailList(ret.data)
    this.actuateStatusList = this.uraContractEntryListService.getActuateStatus()
  }

  changeValue(key: string, value: string): void {
    this.values[key] = value
    this.getCount()
  }

  changeRetail(retailTagCd: string): void {
    this.bussinesCategoryTagList = this.uraSearchService.getBusinessCategoryList(retailTagCd)
    this.changeValue('retailTagCd', retailTagCd)
    this.changeValue('bussinesCategoryTagCd', null)
  }

  checkSelectedActuate(selectedStatus): boolean {
    if (this.uraContractEntryListService.getSelectedActuateStatus() === selectedStatus) {
      return true
    } else {
      return false
    }
  }

  changeSelectedActuate(selectedStatus): void {
    if (this.uraContractEntryListService.getSelectedActuateStatus() === selectedStatus) {
      this.uraContractEntryListService.selectActuateStatus('')
    } else {
      this.uraContractEntryListService.selectActuateStatus(selectedStatus)
    }
    this.getCount()
  }

  async searchContractList(): Promise<void> {
    if (this.errorRequest) {
      this.data = []
      return
    }
    this.data = []
    const req = this.setRequest()
    this.loading = true

    const contractsRet = await this.contaService.SearchContractList(req)
    this.loading = false
    if (contractsRet?.resultCode !== 0) { return }
    this.data = contractsRet.data
  }

  async getCount(): Promise<void> {
    if (this.errorRequest) {
      this.count = 0
      return
    }
    const req = this.setRequest()
    if (JSON.stringify(this.lastTimeRequest) === JSON.stringify(req)) { return }
    this.countLoading = true
    this.neverCountSearch = false
    this.count = 0
    this.lastTimeRequest = req
    const countRet = await this.contaService.SearchContractCount(req)
    this.countLoading = false
    this.count = countRet.data
  }

  changeProductInquiryCd(event: InputProductInquiryCd): void {
    this.errorRequest = event.isError
    this.values.productCdForInquiry = event.productCdForInquiry
    this.values.productNumber = event.productNumber
    this.getCount()
  }

  setRequest(): ContaSearchMacroRequest {
    const tagCds: string[] = []
    if (this.values.serviceTagCd) { tagCds.push(this.values.serviceTagCd) }
    if (this.values.retailTagCd) { tagCds.push(this.values.retailTagCd) }
    if (this.values.bussinesCategoryTagCd) { tagCds.push(this.values.bussinesCategoryTagCd) }
    const req: ContaSearchMacroRequest = {
      tagCds,
      productCds: this.values.productCds,
      companyOrganizationCd: this.values.companyOrganizationCd || null,
      actuateName: this.values.actuateName || null,
      productCdForInquiry: this.values.productCdForInquiry || null,
      supplierCd: this.values.supplierCd || null,
      productNumber: this.values.productNumber || null,
      actuateStatus: this.uraContractEntryListService.getSelectedActuateStatus(), // 0:未稼働、1:稼働中、2:解約済
      applicationDatetimeStart: this.entryDataRange ? moment(this.entryDataRange.start).format('YYYY-MM-DDT00:00:00') : null,
      applicationDatetimeEnd: this.entryDataRange ? moment(this.entryDataRange.end).format('YYYY-MM-DDT23:59:59') : null,
      actuateDatetimeStart: this.actuateDataRange ? moment(this.actuateDataRange.start).format('YYYY-MM-DDT00:00:00') : null,
      actuateDatetimeEnd: this.actuateDataRange ? moment(this.actuateDataRange.end).format('YYYY-MM-DDT23:59:59') : null,
    }
    return req
  }

}
